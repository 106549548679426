//Import required libraies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Import custom components
import Loader from "../../../../components/Loader/Loader";
import LocalParameterItem from "./LocalParameterItem";
import NestedTestControlContainer from "./NestedTestControl";
// import NestedNational from "./NestedNational";

//Import utils
import { config } from "../../../../config/config";
import {
  makeDefaultResponseJson,
  addRunNameToRunData,
  getStartEndByDayCat,
  modifyLevelDriverData,
  removeAllInData,
  salesMediaFilterPayload,
} from "../../../../utils/utils";
import dayCat from "../../../../../assets/data/dayCat.json";

//Import actions
import { updateUserInfo, updateAlertInfo } from "../../../../redux/actions";

//Import styles
import "./NestedRuns.scss";

// Import custom hooks
import { useNonInitialEffect } from "../../../../hooks/useNonInitialEffect";

//We need to make 3 API calls
//1) Runs - Once for the active config
//2) Run Payload - Everytime user changes activeRun
//3) Level Driver - Everytime user changes activeRun
const initialNestedData = {
  runs: { status: "loading", message: "", data: [] },
  payload: { status: "loading", message: "", data: {} },
  levelDriver: { status: "loading", message: "", data: [] },
};

const initialSelections = {
  pilot_run_id: "",
  nested_fields: "",
  nested_fields_old: [],
  level_name: "",
  run_type: "nested_run",
  config_id: "",
  dayCat: "all",
  configId: "",
  algorithm: "",
};
function NestedRunsConfig(props) {
  const {
    onChange,
    userInputs,
    updateUserInfo,
    disableFlag,
    updateAlertInfo,
    allData,
  } = props;

  //Defining required variables for state initialization
  const initialSelectionsFromConfigUserInputs = userInputs.models.find(
    (model) => model.id === config.hardCoded.autoMlId
  );

  //Defining Required States
  const [nestedData, setNestedData] = useState(initialNestedData);
  const [selections, setSelections] = useState({
    ...initialSelections,
    ...initialSelectionsFromConfigUserInputs.config,
    dayCat: initialSelections.dayCat,
    configId: userInputs.configId,
  });
  //isConfigManual
  const [isConfigManual, setIsConfigManual] = useState(null);

  const [algorithmData, setAlgorithmData] = useState({
    status: "loading",
    message: "",
    data: [],
  });
  const [isAlgoSelected, setAlgoSelected] = useState(false);
  //Defining required variables
  const currRegionType =
    nestedData.payload.data?.region_granularity?.type?.toLowerCase();
  const runIdFinal = nestedData.runs.data.length
    ? selections["pilot_run_id"]
    : config.messages.noRuns;
  const runsDataFinal = nestedData.runs.data.length
    ? nestedData.runs.data
    : [
        {
          id: config.messages.noRuns,
          name: config.messages.noRuns,
        },
      ];
  const errorFlagFinal =
    nestedData.runs.status === "error" ||
    nestedData.payload.status === "error" ||
    nestedData.levelDriver.status;
  const errorMessageFinal =
    nestedData.runs.status === "error"
      ? nestedData.runs.message
      : nestedData.payload.status === "error"
      ? nestedData.payload.message
      : nestedData.levelDriver.status === "error"
      ? nestedData.levelDriver.message
      : "";

  //Defining required change handlers
  const onChangeCurr = (key, value) => {
    setSelections({ ...selections, [key]: value });
    onChange(key, value);
  };

  const updateNestedData = (key, value) => {
    setNestedData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  //Fetch runs data
  useEffect(() => {
    const url = config.api.runUrl;
    const { segment } = salesMediaFilterPayload(userInputs, allData);
    const body = {
      app_id: userInputs.app_id,
      country: userInputs.country,
      brand: userInputs.brand.toUpperCase(),
      // segment:
      // userInputs.segment.toString() === "ALL" ? null : userInputs.segment,
      // segment: removeAllInData(userInputs.segment).length
      //   ? removeAllInData(userInputs.segment)
      //   : null,
      segment:
        userInputs.segment_select_all || userInputs.is_config_manual
          ? null
          : segment,
      search_text: selections.configId,
      offset: 0,
      limit: 100,
      order_by: "created_on",
      category: ["Success"],
      models: [config.hardCoded.autoMlId],
      run_type_key: config.hardCoded.automlRunKey, //key to get initial run details
      run_type_value: config.hardCoded.pilotRunId, //key to get pilot run details
      ...getStartEndByDayCat(disableFlag ? "" : selections.dayCat),
      is_shared: true,
    };
    let status;
    // if (userInputs?.configType !== "add") {
    // const mockyUrl =
    //   "https://run.mocky.io/v3/f5cca9a2-4d50-4078-bf18-a0a84d7432cb";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      // fetch(mockyUrl)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          let newRunData = {
            status: "success",
            message: "",
            data: addRunNameToRunData(json.data),
          };
          updateNestedData("runs", newRunData);
          if (!json?.data?.length) {
            // clear algorithm if there isn't any successful pilot runs
            onChangeCurr("algorithm", "");
          }
        } else {
          updateUserInfo({
            error: json.error,
            errorCode: json.errorCode,
          });
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
          let newRunData = {
            status: "error",
            message: json.error,
            data: [],
          };
          updateNestedData("runs", newRunData);
        }
      });

    return () => {
      updateNestedData("runs", { status: "loading", message: "", data: [] });
    };
    // } else {
    //   updateNestedData("runs", { status: "success", message: "", data: [] });
    // }
    // eslint-disable-next-line
  }, [selections.dayCat]);

  //Fetch Run Payload
  useEffect(() => {
    //If Runs fetch call was a success, fetch run payload data
    if (nestedData.runs.status === "success") {
      if (nestedData.runs.data.length) {
        //If pilot_run_id is not included in the list or is "", don't run
        //If user edits a config with nested run, when it opens, all is selected by default, so the run is present in the runs list
        //and pilot_run_id is the picked from config selections
        //If user changes day cat to, say yesterday, and the pilot_run_id is not present in the new run_list
        //payload and levelDriver call is made for incorrect run id
        let runIds = nestedData.runs.data.map((run) => run.id);
        if (
          selections.pilot_run_id !== "" &&
          runIds.includes(selections.pilot_run_id)
        ) {
          //Fetch run data if pilot_run_id is present
          const url = `${config.api.runUrl}?run_id=${selections.pilot_run_id}`;
          let status;
          fetch(url)
            .then((response) => {
              console.groupCollapsed("requesting", url);
              console.log("REPSONSE -> ", response);
              status = response.status;
              return response.clone().json();
            })
            .then((json) => {
              console.log("JSON -> ", json);
              console.groupEnd();
              if (status === 200) {
                setIsConfigManual(json.data.payload.config?.is_config_manual);
                const payloadConfig = json.data.payload.models.find(
                  (model) => model.id === config.hardCoded.autoMlId
                ).config;
                let newRunPayloadData = {
                  status: "success",
                  message: "",
                  data: payloadConfig,
                };
                updateNestedData("payload", newRunPayloadData);
                if (
                  payloadConfig?.retail_margin_price === 0 ||
                  payloadConfig?.retail_margin_price
                ) {
                  onChange(
                    "retail_margin_price",
                    payloadConfig?.retail_margin_price
                  );
                }
                if (payloadConfig?.sales_coverage) {
                  onChange("sales_coverage", payloadConfig?.sales_coverage);
                } else {
                  onChange("sales_coverage", "-1"); //remove sales_coverage key if type is nested and key is missing in pilot run
                }
              } else {
                updateAlertInfo({
                  open: true,
                  message: json.statusMessage,
                  severity: "error",
                });
                updateUserInfo({
                  error: json.error,
                  errorCode: json.errorCode,
                });
                let newRunPayloadData = {
                  status: "error",
                  message: json.error,
                  data: {},
                };
                updateNestedData("payload", newRunPayloadData);
              }
            });
        }
      } else {
        let newRunPayloadData = {
          status: "error",
          message: "No runs available under this category.",
          data: {},
        };
        updateNestedData("payload", newRunPayloadData);
      }
    }

    //If Runs fetch call was an error, upload runPayload also as error
    if (nestedData.runs.status === "error") {
      let newRunPayloadData = {
        status: "error",
        message: "Fetching run data failed",
        data: {},
      };
      updateNestedData("payload", newRunPayloadData);
    }

    return () => {
      updateNestedData("payload", { status: "loading", message: "", data: {} });
    };
    // eslint-disable-next-line
  }, [nestedData.runs, selections.pilot_run_id]);

  // Fetch algorithm (models for that run)
  useEffect(() => {
    // const mockyUrl =
    //   "https://run.mocky.io/v3/d47b8a4d-3fd4-4c8d-9aff-264353b89e91";
    const controller = new AbortController();
    if (runIdFinal && runIdFinal !== config.messages.noRuns) {
      const url = config.api.automlAlgorithm;
      let status;
      const body = {
        run_id: runIdFinal,
      };
      setAlgorithmData({
        status: "loading",
        message: "",
        data: [],
      });
      fetch(url, {
        signal: controller.signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("json", json);
          console.groupEnd();
          if (status === 200) {
            let newAlgoData = {
              status: "success",
              message: "",
              data: json.data.map((el) => {
                return { name: el, id: el };
              }),
            };
            if (!json.data.includes(selections.algorithm)) {
              onChangeCurr("algorithm", "");
            }
            setAlgorithmData(newAlgoData);
          } else {
            updateUserInfo({
              error: json.error,
              errorCode: json.errorCode,
            });
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "info",
            });
            let newAlgoData = {
              status: "error",
              message: json.error,
              data: [],
            };
            setAlgorithmData(newAlgoData);
          }
        });
    } else {
      let newAlgoData = {
        status: "success",
        message: config.messages.noALgo,
        data: [],
      };
      setAlgorithmData(newAlgoData);
    }
    return () => {
      controller.abort();
    };
  }, [nestedData.runs, selections.pilot_run_id]);
  //  Set algo selection status
  useEffect(() => {
    setAlgoSelected(selections.algorithm ? true : false);
  }, [selections.algorithm]);
  //Fetch level drivers data
  useEffect(() => {
    const controller = new AbortController();
    //If Runs fetch call was a success, fetch run payload data
    if (
      (isAlgoSelected ||
        (userInputs.configType === "run" && !selections.algorithm)) &&
      nestedData.runs.status === "success" &&
      algorithmData.status === "success"
    ) {
      //If pilot_run_id is not included in the list or is "", don't run
      //If user edits a config with nested run, when it opens, all is selected by default, so the run is present in the runs list
      //and pilot_run_id is the picked from config selections
      //If user changes day cat to, say yesterday, and the pilot_run_id is not present in the new run_list
      //payload and levelDriver call is made for incorrect run id
      let runIds = nestedData.runs.data.map((run) => run.id);
      if (
        selections.pilot_run_id !== "" &&
        runIds.includes(selections.pilot_run_id)
      ) {
        const body = {
          run_id: selections.pilot_run_id,
          country: userInputs.country,
          algorithms: algorithmData.data.map((el) => el.id),
        };
        // const mockyNestedUrl =
        //   "https://run.mocky.io/v3/17269985-a2f8-4e6a-9219-3b897833bc77";
        const url = config.api.nestedLevelsDriversUrl;
        let status;
        fetch(url, {
          signal: controller.signal,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              let newLevelDriverData = {
                status: "success",
                message: "",
                data: modifyLevelDriverData(json.data),
                // data: modifyLevelDriverData({}),
              };
              updateNestedData("levelDriver", newLevelDriverData);
            } else {
              updateUserInfo({
                error: json.error,
                errorCode: json.errorCode,
              });
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "info",
              });
              let newLevelDriverData = {
                status: "error",
                message: json.error,
                data: [],
              };
              updateNestedData("levelDriver", newLevelDriverData);
            }
          });
      }
    } else {
      // let newLevelDriverData = {
      //   status: "error",
      //   message: "No runs available under this category.",
      //   data: [],
      // };
      // updateNestedData("levelDriver", newLevelDriverData);
    }

    //If Runs fetch call was an error, upload runPayload also as error
    if (nestedData.runs.status === "error") {
      let newLevelDriverData = {
        status: "error",
        message: "Fetching run data failed",
        data: [],
      };
      updateNestedData("levelDriver", newLevelDriverData);
    }

    return () => {
      controller.abort();
      updateNestedData("levelDriver", {
        status: "loading",
        message: "",
        data: [],
      });
    };
    // eslint-disable-next-line
  }, [nestedData.runs, selections.pilot_run_id, isAlgoSelected, algorithmData]);

  //Update pilot_run_id if runs changes
  useEffect(() => {
    if (nestedData.runs.status === "success") {
      let pilot_run_id = selections.pilot_run_id;
      let isPilotRunIdValid = true;
      if (nestedData.runs.data.length) {
        let runIds = nestedData.runs.data.map((run) => run.id);
        if (!runIds.includes(selections.pilot_run_id)) {
          pilot_run_id = nestedData.runs.data[0].id;
          isPilotRunIdValid = false;
        }
      } else {
        pilot_run_id = "";
        isPilotRunIdValid = false;
      }
      if (!isPilotRunIdValid) {
        onChangeCurr("pilot_run_id", pilot_run_id);
      }
    }
    // eslint-disable-next-line
  }, [nestedData.runs]);

  //In case pilot_run id changes, that means the selections are invalid now
  //So, reset them to [] in case of regional and "" in case of national
  //Once we get all the data, (payload, levelDriver), the child components will take care of setting initial values
  //Don't run it on initial render
  //Run when pilot_run_id or dayCat changes
  useNonInitialEffect(() => {
    onChangeCurr("nested_run_regions", []);
  }, [selections.pilot_run_id, selections.dayCat]);

  // console.groupCollapsed("--------------STATE CHANGED--------------");
  // console.log("USER INPUTS -> ", userInputs);
  // console.log("PROPS -> ", props);
  // console.log("NESTED RUNS -> ", nestedData);
  // console.log("SELECTIONS -> ", selections);
  // console.groupEnd();

  return (
    <div className="nested-runs-conatiner">
      {nestedData.runs.status === "loading" && <Loader />}

      {nestedData.runs.status === "success" && (
        <div className="configs-container-new">
          <LocalParameterItem
            ui_element_type="dropdown"
            value={selections["dayCat"]}
            onChange={onChangeCurr}
            enable={true}
            show={true}
            name="Select Runs For"
            id="dayCat"
            data={dayCat}
            userInputs={userInputs}
          />
          <LocalParameterItem
            ui_element_type="dropdown"
            value={runIdFinal}
            onChange={onChangeCurr}
            enable={nestedData.runs.data.length ? true : false}
            show={true}
            name="Select Pilot Run"
            id="pilot_run_id"
            data={runsDataFinal}
            userInputs={userInputs}
          />
          {userInputs.configType === "run" &&
          !selections.algorithm ? null : algorithmData.status === "loading" ? (
            <Loader />
          ) : (
            <LocalParameterItem
              ui_element_type="dropdown"
              value={selections["algorithm"]}
              onChange={onChangeCurr}
              enable={algorithmData?.data?.length}
              show={true}
              name="Select Algorithm"
              id="algorithm"
              data={algorithmData?.data}
              userInputs={userInputs}
            />
          )}
        </div>
      )}

      {
        // nestedData.payload.status === "loading" ? (
        //   <Loader />
        // ) :
        nestedData?.runs?.data?.length &&
        (nestedData.payload.status === "loading" ||
          nestedData.levelDriver.status === "loading") &&
        isAlgoSelected ? (
          <Loader />
        ) : nestedData.payload.status !== "loading" &&
          nestedData.levelDriver.status !== "loading" &&
          algorithmData.status !== "loading" &&
          !algorithmData?.data?.length &&
          userInputs.configType !== "run" ? (
          <p style={{ display: "flex", justifyContent: "center" }}>
            No algorithm available to select
          </p>
        ) : !isAlgoSelected && algorithmData?.data?.length ? (
          userInputs.configType === "run" ? null : (
            <p style={{ display: "flex", justifyContent: "center" }}>
              Please select an algorithm to proceed with the nested run
            </p>
          )
        ) : null
      }

      {nestedData.payload.status === "success" &&
        nestedData.levelDriver.status === "success" && (
          <>
            {/* {nestedData.runs.data.length > 0 && <p>ALLOWED</p>} */}
            {nestedData.runs.data.length > 0 && (
              <>
                <NestedTestControlContainer
                  {...props}
                  payload={nestedData.payload.data}
                  levelDriver={nestedData.levelDriver.data}
                  selections={selections}
                  onChange={onChangeCurr}
                  currRegionType={currRegionType}
                  updateUserInfo={updateUserInfo}
                  isConfigManual={isConfigManual}
                />
              </>
            )}
          </>
        )}

      {errorFlagFinal && <p className="info-message">{errorMessageFinal}</p>}
    </div>
  );
}

NestedRunsConfig.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = { updateUserInfo, updateAlertInfo };

export default connect(mapStateToProps, mapDispatchToProps)(NestedRunsConfig);
