//Import required libraies
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@mui/material/Slider";
import "../index.css";

/**
 * [SimpleSlider description]
 * @param {[string]} theme [The theme of the component - default: light]
 * @param {[string]} styleVariant [Used to represent colored interface elements for a user - default: primary]
 * @param {[Array<number> | number]} value [The value of the slider. For ranged sliders, provide an array with two values - default: 0]
 * @param {[any]} ...props [Other valid props for MUI Slider]
 * @returns {[object]} [Returns the Slider component]
 */
const SimpleSlider = (props) => {
  const {
    theme = "light",
    styleVariant = "primary",
    value = 0,
    ...remaining
  } = props;

  const useStyles = makeStyles(() => ({
    sliderStyle: {
      color: `var(--${styleVariant}-slider-color-${theme}) !important`,
      "& .MuiSlider-rail": {
        height: 5,
        borderRadius: 4,
      },
      "& .MuiSlider-track": {
        height: 5,
        borderRadius: 4,
      },
      "& .MuiSlider-mark": {
        height: 5,
      },
      "& .MuiSlider-thumb": {
        height: 15,
        width: 15,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        "&:focus, &:hover": {
          boxShadow: "inherit",
        },
      },
      "& .MuiSlider-valueLabel": {
        backgroundColor: "transparent",
        color: `var(--${styleVariant}-slider-color-${theme}) !important`,
      },
    },
  }));
  const classes = useStyles();

  return (
    <Slider className={classes.sliderStyle} value={value} {...remaining} />
  );
};

SimpleSlider.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]),
  styleVariant: PropTypes.oneOf(["primary", "secondary"]),
};

export default SimpleSlider;
