//Import required libraies
import React, { useState, useEffect } from "react";
import { v4 } from "uuid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
//Import custom components

//Import utils
import {
  transformTestControlFromObjectToArray,
  addLabelToTestControlArr,
  modifyLevelsToAddDetails,
  modifyNestedDriversToAddDriverName,
  filterRegionsBasedOnTestControlData,
  arrSameMembers,
  getValidNewRowFromRegionAndLevelDriver,
  addRegionNamePositionToTestControlData,
  getAllValidCombinationsForLevelDriverData,
  isNestedRunRegionsValid,
} from "../../../../utils/utils";
import { config } from "../../../../config/config";

//Import actions

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "100%",
    height: "30px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
      borderRadius: "4px",
    },
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "4px 12px",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
  contained: {
    color: "#46596a",
    backgroundColor: "transparent",
    marginRight: 4,
    //marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: "12px",
    height: 23,
    minWidth: 15,
    border: "1px solid #46596a",
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f1f1f1",
      boxShadow: "none",
    },
  },
  outlined: {
    // borderColor: "#46596a",
    marginRight: 4,
    border: "none",
    // fontSize: 14,
    //padding: 10,
    padding: "0px 5px",
    height: "22px",
    minWidth: "auto",
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));

function NestedTestControlContainer(props) {
  const { selections, onChange, levelDriver, userInputs } = props;
  const [selectionsValid, setSelectionsValid] = useState(false);
  let levelDriverForOldRuns = Object.values(levelDriver).length
    ? Object.values(levelDriver)[0]
    : [];
  const algoLevelDriver =
    userInputs.configType === "run" &&
    !selections.algorithm &&
    levelDriverForOldRuns.length
      ? levelDriverForOldRuns
      : levelDriver[selections.algorithm] || [];
  //Check Selections Validity
  useEffect(() => {
    //Make a function which checks the validity
    //If valid, show the child component
    //If not reset nested_run_regions to empty array

    const allValidCombinations =
      getAllValidCombinationsForLevelDriverData(algoLevelDriver);
    const validFlag = isNestedRunRegionsValid(
      allValidCombinations,
      selections.nested_run_regions
    );
    if (validFlag) {
      setSelectionsValid(true);
    } else {
      //To avoid infinite setState
      if (!arrSameMembers(selections.nested_run_regions, [])) {
        onChange("nested_run_regions", []);
      }
    }
    // eslint-disable-next-line
  }, [selections]);

  return (
    <>
      {selectionsValid && (
        <NestedTestControl {...props} levelDriver={algoLevelDriver} />
      )}
    </>
  );
}

function NestedTestControl(props) {
  const {
    onChange,
    payload,
    levelDriver,
    selections,
    currRegionType,
    isConfigManual,
  } = props;

  var initialUiError = false,
    initialRegionsData = [],
    initialTestControlData = [],
    initialFilteredRegionsData = [];
  try {
    //Defining required variables for state initialization
    initialRegionsData =
      currRegionType === "regional"
        ? [
            ...addLabelToTestControlArr(
              isConfigManual
                ? levelDriver.every(
                    (e) => e.regions?.length && !e.regions?.includes("")
                  )
                  ? levelDriver
                      .filter((e) => e.type === "test")
                      .map((e) => ({
                        ...e,
                        position: parseInt(e.cell_name.split("_")[1]),
                        id: v4(),
                      }))
                  : transformTestControlFromObjectToArray(
                      // config.hardCoded.autoMlIds.includes(activeModel.id)
                      payload.region_granularity?.test_regions?.test,
                      // : payload.region_granularity?.test_control_regions?.test,
                      null,
                      null
                    )
                : transformTestControlFromObjectToArray(
                    // config.hardCoded.autoMlIds.includes(activeModel.id)
                    payload.region_granularity?.test_regions?.test,
                    // : payload.region_granularity?.test_control_regions?.test,
                    null,
                    null
                  ),
              "Test Cell",
              true,
              "test"
            ),
            // ...addLabelToTestControlArr(
            //   transformTestControlFromObjectToArray(
            //     payload.region_granularity?.test_control_regions?.control,
            //     null,
            //     null
            //   ),
            //   "Control Cell",
            //   true,
            //   "control"
            // ),
          ]
        : [
            ...addLabelToTestControlArr(
              transformTestControlFromObjectToArray(
                { 0: { regions: ["ALL"] } },
                null,
                null
              ),
              "ALL",
              false,
              "national"
            ),
          ];
    initialTestControlData = selections.nested_run_regions.length
      ? addRegionNamePositionToTestControlData(
          selections.nested_run_regions,
          initialRegionsData
        )
      : [
          getValidNewRowFromRegionAndLevelDriver(
            initialRegionsData[0],
            levelDriver
          ),
        ];
    initialFilteredRegionsData = filterRegionsBasedOnTestControlData(
      initialRegionsData,
      initialTestControlData
    );
  } catch (error) {
    console.log("UI ERROR", error);
    initialUiError = true;
    initialRegionsData = [];
    initialTestControlData = [];
    initialFilteredRegionsData = [];
  }

  //Defining required states
  const classes = useStyles();
  const [uiError, setUiError] = useState(initialUiError);
  const [regionsData, setRegionsData] = useState(initialRegionsData);
  const [testControlData, setTestControlData] = useState(
    initialTestControlData
  );
  const [filteredRegions, setFilteredRegions] = useState(
    initialFilteredRegionsData
  );

  //Defining required functions
  //Add empty test row
  const addTestControlRow = () => {
    const firstRegionObject = filteredRegions[0];
    const newValidRow = getValidNewRowFromRegionAndLevelDriver(
      firstRegionObject,
      levelDriver
    );
    setTestControlData([...testControlData, newValidRow]);
  };

  //update filteredRegions every time testControl data changes
  useEffect(() => {
    const newFilteredRegions = filterRegionsBasedOnTestControlData(
      regionsData,
      testControlData
    );
    setFilteredRegions(newFilteredRegions);
    // eslint-disable-next-line
  }, [testControlData]);

  //update userInputs every time testControl data changes
  useEffect(() => {
    onChange("nested_run_regions", testControlData);
    // eslint-disable-next-line
  }, [testControlData]);

  // console.groupCollapsed("Test control CHANGED");
  // console.log("regionsData", regionsData);
  // console.log("filteredRegions", filteredRegions);
  // console.log("testControlData -> ", testControlData);
  // console.groupEnd();

  return (
    <>
      {testControlData && !uiError && (
        <div className="base-driver-container">
          <label className="bold">Select Base and Driver:</label>
          <div>
            {testControlData.map((testDataRow, index) => (
              <NestedTestControlRow
                key={testDataRow.id}
                rowIndex={index}
                testDataRow={testDataRow}
                regionsData={regionsData}
                testControlData={testControlData}
                setTestControlData={setTestControlData}
                filteredRegions={filteredRegions}
                uiError={uiError}
                setUiError={setUiError}
                levelDriver={levelDriver}
              />
            ))}
            {filteredRegions.length > 0 && (
              <div className="add-set-button">
                <Button
                  variant="contained"
                  className={classes.contained}
                  onClick={() => {
                    addTestControlRow();
                  }}
                >
                  Add New
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      {uiError && <p className="info-message">Something went wrong</p>}
    </>
  );
}

function NestedTestControlRow(props) {
  const {
    testDataRow,
    levelDriver,
    testControlData,
    setTestControlData,
    filteredRegions,
    regionsData,
    rowIndex,
    uiError,
    setUiError,
  } = props;

  //Each row has to have these four values
  //1) regions
  //2) level_name
  //3) drivers (nested_fields)
  //4) old_drivers (nested_fields_old)
  //5) region position in regions data
  //Rest all can be made from this

  //Sample Row
  // const sampleRowObject = {
  //   type: "Test",
  //   regions: ["SOUTH & SOUTH EAST", "LONDON"],
  //   id: "15f0ebf2-6d9a-4292-a727-c68a3c18258d",
  //   level_name: "1",
  //   nested_fields: "Others",
  //   nested_fields_old: ["COVID19"],
  // };

  //Defining required variables
  const classes = useStyles();

  try {
    //Region
    //Previously it was arrSameMembers(row.regions, testDataRow.regions)
    //Changed to type+position because of MR
    var selectedRegionObject = regionsData.find((row) =>
      arrSameMembers(
        [row.type + row.position],
        [testDataRow.type + testDataRow.position]
      )
    );
    var selectedRegionId = selectedRegionObject.id;
    var filteredRegionsWithoutSelectedObject = filteredRegions.filter(
      (row) => row.id !== selectedRegionId
    );
    var regionDropdownData = [
      ...filteredRegionsWithoutSelectedObject,
      selectedRegionObject,
    ];

    //Level
    //Filter levelDriver with the selected regions
    var activeRegionRow = levelDriver.find((row) =>
      arrSameMembers(row.regions, testDataRow.regions)
    );
    var allLevels = modifyLevelsToAddDetails(
      activeRegionRow.levels
        .map((levelRow) => {
          //Level 0 will always have an empty array
          //Should have been empty array of array from backend
          //Discuss with backend team if that's the right solution
          //To avoid this if else on UI
          if (levelRow.details.length) {
            return levelRow.details.map((detailRow) => ({
              id: v4(),
              levelId: levelRow.id.toString(),
              name: levelRow.name,
              drivers: levelRow.drivers,
              details: detailRow,
              allDriversList: detailRow.map((row) => row.id),
            }));
          } else {
            return [
              {
                id: v4(),
                levelId: levelRow.id.toString(),
                name: levelRow.name,
                drivers: levelRow.drivers,
                details: [],
                allDriversList: [].map((row) => row.id),
              },
            ];
          }
        })
        .flat()
    );
    var selectedLevelObject = allLevels.find((levelRow) =>
      arrSameMembers(levelRow.allDriversList, testDataRow.nested_fields_old)
    );
    var selectedLevelObjectId = selectedLevelObject.id;
    //Driver
    var allDrivers = modifyNestedDriversToAddDriverName(
      selectedLevelObject.drivers.filter(
        (row) => !selectedLevelObject.allDriversList.includes(row.id)
      ),
      selectedRegionObject.regions
    );
    var selectedDriverId = testDataRow.nested_fields;
  } catch (error) {
    console.log("UI ERROR", uiError);
    setUiError(true);
  }

  // console.groupCollapsed("NESTED RUN ROW");
  // console.log("testDataRow", testDataRow);
  // console.log("selectedRegionObject", selectedRegionObject);
  // console.log("regionDropdownData", regionDropdownData);
  // console.log("selectedRegionId", selectedRegionId);
  // console.log("activeRegionRow", activeRegionRow);
  // console.log("allLevels", allLevels);
  // console.log("selectedLevelObject", selectedLevelObject);
  // console.log("selectedLevelObjectId", selectedLevelObjectId);
  // console.log("allDrivers", allDrivers);
  // console.log("selectedDriverId", selectedDriverId);
  // console.groupEnd();

  //Defining required change handlers
  const handleRegionChange = (clickedRegionObject) => {
    const newTestControlData = testControlData.map((row) => {
      if (row.id !== testDataRow.id) {
        return row;
      } else {
        const newValidRow = getValidNewRowFromRegionAndLevelDriver(
          clickedRegionObject,
          levelDriver
        );
        const newRow = {
          ...row,
          name: newValidRow.name,
          regions: newValidRow.regions,
          type: newValidRow.type,
          level_name: newValidRow.level_name,
          nested_fields: newValidRow.nested_fields,
          nested_fields_old: newValidRow.nested_fields_old,
          position: newValidRow.position,
        };
        return newRow;
      }
    });
    setTestControlData(newTestControlData);
  };

  const handleLevelChange = (clickedLevelObject) => {
    const newTestControlData = testControlData.map((row) => {
      if (row.id !== testDataRow.id) {
        return row;
      } else {
        const allAvailableDriverIdsForSelectedLevel = clickedLevelObject.drivers
          .map((row) => row.id)
          .filter(
            (driverId) => !clickedLevelObject.allDriversList.includes(driverId)
          );
        const isCurrSelectedDriverValid =
          allAvailableDriverIdsForSelectedLevel.includes(selectedDriverId);
        const nested_fields = isCurrSelectedDriverValid
          ? selectedDriverId
          : allAvailableDriverIdsForSelectedLevel[0];
        const nested_fields_old = clickedLevelObject.details.map(
          (row) => row.id
        );
        let newRow = {
          ...row,
          level_name: clickedLevelObject.levelId,
          nested_fields,
          nested_fields_old,
        };
        return newRow;
      }
    });
    setTestControlData(newTestControlData);
  };

  const handleDriverChange = (newValue) => {
    const newTestControlData = testControlData.map((row) =>
      row.id !== testDataRow.id
        ? row
        : {
            ...row,
            nested_fields: newValue,
          }
    );
    setTestControlData(newTestControlData);
  };

  const handleRowDelete = () => {
    const newTestControlData = testControlData.filter(
      (row) => row.id !== testDataRow.id
    );
    setTestControlData(newTestControlData);
  };

  return (
    <>
      {!uiError && (
        <div className="nested-test-control-container">
          <div className="nested-region-input">
            <div className="region-level-input regional">
              <div className="input-item row regions">
                <label>Select Region Cell:</label>
                {regionDropdownData.length ? (
                  <Select
                    disabled={false}
                    value={selectedRegionId}
                    onChange={(e) => {
                      const clickedRegionObject = regionDropdownData.find(
                        (row) => row.id === e.target.value
                      );
                      handleRegionChange(clickedRegionObject);
                    }}
                    className={classes.multiSelect}
                  >
                    {regionDropdownData &&
                      regionDropdownData.map((obj) => (
                        <MenuItem
                          key={obj.id}
                          value={obj.id}
                          className={classes.singleMenuItem}
                        >
                          {obj.name}
                        </MenuItem>
                      ))}
                  </Select>
                ) : (
                  <Select
                    value="No region available"
                    disabled={true}
                    className={classes.multiSelect}
                  >
                    <MenuItem className={classes.singleMenuItem} disabled>
                      No region available
                    </MenuItem>
                  </Select>
                )}
              </div>

              <div className="input-item row base-level">
                <label>Select Base Level:</label>
                {allLevels.length ? (
                  <Select
                    disabled={false}
                    value={selectedLevelObjectId}
                    onChange={(e) => {
                      const clickedLevelObject = allLevels.find(
                        (row) => row.id === e.target.value
                      );
                      handleLevelChange(clickedLevelObject);
                    }}
                    className={classes.multiSelect}
                  >
                    {allLevels &&
                      allLevels.map((obj) => (
                        <MenuItem
                          key={obj.id}
                          value={obj.id}
                          className={classes.singleMenuItem}
                        >
                          {obj.name}
                        </MenuItem>
                      ))}
                  </Select>
                ) : (
                  <Select
                    value="No level available"
                    disabled={true}
                    className={classes.multiSelect}
                  >
                    <MenuItem disabled className={classes.singleMenuItem}>
                      No level available
                    </MenuItem>
                  </Select>
                )}
              </div>
            </div>

            <div className="input-item row test-base-driver">
              <label>Select Driver:</label>
              {allDrivers.length ? (
                <Select
                  disabled={false}
                  value={selectedDriverId}
                  onChange={(e) => {
                    handleDriverChange(e.target.value);
                  }}
                  className={classes.multiSelect}
                >
                  {allDrivers &&
                    allDrivers.map((obj) => (
                      <MenuItem
                        key={obj.id}
                        value={obj.id}
                        className={classes.singleMenuItem}
                      >
                        {obj.name}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Select
                  value="No driver available"
                  disabled={true}
                  className={classes.multiSelect}
                >
                  <MenuItem disabled className={classes.singleMenuItem}>
                    No driver available
                  </MenuItem>
                </Select>
              )}
            </div>
          </div>

          <div
            className="test-control-regions-second-row"
            style={{ visibility: rowIndex !== 0 ? "visible" : "hidden" }}
          >
            <div className="delete-button-div">
              <Button
                variant="outlined"
                className={classes.outlined}
                onClick={() => {
                  handleRowDelete();
                }}
              >
                Delete
                <i className="material-icons-outlined delete_icon">
                  delete_forever
                </i>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NestedTestControlContainer;
