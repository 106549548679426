import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { Chip } from "../../uiTheme";
import Popover from "@material-ui/core/Popover";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { formatDateForMerged } from "../../utils/utils";

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    pointerEvents: "auto",
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
      maxWidth: "250px",
      pointerEvents: ({ disableFlag }) => (disableFlag ? "none" : "auto"),
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFormControl-fullWidth": {
      width: ({ width }) => width || 225,
      minWidth: "250px",
      background: "white",
      boxSizing: "border-box",
      borderRadius: "5px",
      marginTop: 5,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri !important",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-option": {
      backgrounColor: "#46596a3b",
      fontFamily: "Hind Siliguri !important",
    },
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
      pointerEvents: "none",
    },
    "& .MuiAutocomplete-inputRoot": {
      minHeight: "48px",
    },
    "& .MuiAutocomplete-endAdornment": {
      pointerEvents: ({ disableFlag }) => (disableFlag ? "none" : "auto"),
    },
  },
  noDataSelect: {
    height: "48px",
  },
  popover: {
    pointerEvents: "none",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    width: "800px",
    margin: "10px",
  },
  root: {
    marginTop: "10px",
    fontFamily: "Hind Siliguri !important",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

const StyledAutoComplete = withStyles((theme) => ({
  tag: {
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
    maxHeight: 200,
    overflowY: "auto",
  },
}))(Autocomplete);

const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function MultiSelectWithCheckBox(props) {
  const {
    handleChange,
    data,
    value,
    id,
    name,
    disable,
    disableFlag,
    width,
    noDataLabel,
  } = props;
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <label className="bold header-text">{name}</label>
      {disableFlag ? (
        <>
          {value ? (
            <StyledAutoComplete
              multiple
              getOptionSelected={(option, value) => {
                if (id === "target_campaigns") {
                  return option.campaignid === value.campaignid;
                }
                return option.id === value;
              }}
              id={id}
              limitTags={2}
              value={value || []}
              disableCloseOnSelect
              disabled={disable}
              // onChange={(e, value) => {
              //   handleChange(id, value);
              // }}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              options={data || []}
              getOptionLabel={(option) => option.name || ""}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  return (
                    <div
                      key={`${
                        id === "target_campaigns"
                          ? `${option?.id + index}`
                          : `${option + index}`
                      }`}
                    >
                      <Chip
                        label={
                          id === "target_campaigns"
                            ? // `${option.dataset_id}_${option.campaign}`
                              `${option.dataset_id}_${option.campaign}_${option.campaignid}`
                            : option
                        }
                        {...getTagProps({ index })}
                        style={{ maxWidth: "160px" }}
                      />
                    </div>
                  );
                })
              }
              renderValue={(selected) => {
                return selected.name.join(", ");
              }}
              size="small"
              className={classes.typographyStyle}
              renderOption={(option, { selected }) => (
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  title={
                    id === "target_campaigns"
                      ? // `${option.dataset_id}_${option.campaign}`
                        `Dataset Name : ${option.dataset_id} | Campaign Name: ${
                          option.campaign
                        } | Campaign ID: ${
                          option.campaignid
                        } | Start Date: ${formatDateForMerged(
                          option.campaign_start_date
                        )} | End Date: ${formatDateForMerged(
                          option.campaign_end_date
                        )}`
                      : option
                  }
                >
                  <StyledCheckbox checked={selected} key={option?.id} />
                  {id === "target_campaigns"
                    ? `Dataset Name : ${option.dataset_id} | Campaign Name: ${
                        option.campaign
                      } | Campaign ID: ${
                        option.campaignid
                      } | Start Date: ${formatDateForMerged(
                        option.campaign_start_date
                      )} | End Date: ${formatDateForMerged(
                        option.campaign_end_date
                      )}`
                    : // `${option.dataset_id}_${option.campaign}`
                      option}
                </div>
              )}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  placeholder=""
                  variant="outlined"
                  size="small"
                  className={classes.typographyStyle}
                />
              )}
            />
          ) : (
            <div
              className="input-item-label-less"
              style={{ width: width, marginTop: 5, marginRight: 0 }}
            >
              <Select
                value={noDataLabel}
                disabled={true}
                className={classes.noDataSelect}
              >
                <MenuItem value={noDataLabel}>{noDataLabel}</MenuItem>
              </Select>
            </div>
          )}
        </>
      ) : data?.length ? (
        <StyledAutoComplete
          multiple
          getOptionSelected={(option, value) => {
            if (id === "target_campaigns") {
              return option.campaignid === value.campaignid;
            }
            return option.id === value;
          }}
          id={id}
          limitTags={2}
          value={value || []}
          disableCloseOnSelect
          disabled={disable}
          onChange={(event, value) => {
            if (id === "dataset_id") {
              handleChange(
                id,
                value?.map((e) => (e?.id ? e.id : e))
              );
            } else {
              handleChange(id, value);
            }
          }}
          options={data || []}
          getOptionLabel={(option) => {
            return option?.name || "";
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              return (
                <div
                  key={`${
                    id === "target_campaigns"
                      ? `${option?.campaignid + index}`
                      : `${option + index}`
                  }`}
                >
                  <Chip
                    label={
                      id === "target_campaigns"
                        ? // `${option.dataset_id}_${option.campaign}`
                          `${option.dataset_id}_${option.campaign}_${option.campaignid}`
                        : option
                    }
                    {...getTagProps({ index })}
                    style={{ maxWidth: "160px" }}
                  />
                </div>
              );
            })
          }
          renderValue={(selected) => {
            return selected.name.join(", ");
          }}
          size="small"
          className={classes.typographyStyle}
          renderOption={(option, { selected }) => (
            <div
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              title={
                id === "target_campaigns"
                  ? // `${option.dataset_id}_${option.campaign}`
                    `Dataset Name : ${option.dataset_id} | Campaign Name: ${
                      option.campaign
                    } | Campaign ID: ${
                      option.campaignid
                    } | Start Date: ${formatDateForMerged(
                      option.campaign_start_date
                    )} | End Date: ${formatDateForMerged(
                      option.campaign_end_date
                    )}`
                  : option
              }
            >
              <StyledCheckbox
                checked={selected}
                key={id === "target_campaigns" ? option?.campaignid : option.id}
              />
              {id === "target_campaigns"
                ? // `Dataset Name : ${option.dataset_id} | Campaign Name: ${
                  //     option.campaign
                  //   } | Campaign ID: ${
                  //     option.campaignid
                  //   } | Start Date: ${formatDateForMerged(
                  //     option.campaign_start_date
                  //   )} | End Date: ${formatDateForMerged(
                  //     option.campaign_end_date
                  //   )}`
                  `${option.dataset_id}_${option.campaign}`
                : option.name}
            </div>
          )}
          renderInput={(params) => (
            <CssTextField
              {...params}
              placeholder=""
              variant="outlined"
              size="small"
              className={classes.typographyStyle}
            />
          )}
        />
      ) : (
        <div
          className="input-item-label-less"
          style={{ width: width, marginTop: 5, marginRight: 0 }}
        >
          <Select
            value={noDataLabel}
            disabled={true}
            className={classes.noDataSelect}
          >
            <MenuItem value={noDataLabel}>{noDataLabel}</MenuItem>
          </Select>
        </div>
      )}
      {value && (
        <Popover
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div
            className="popover-content"
            style={{ minWidth: width, maxWidth: width }}
          >
            {value?.map((option) => (
              <div>
                <p
                  key={option}
                  title={
                    id === "target_campaigns"
                      ? `Dataset Name : ${option.dataset_id} | Campaign Name: ${
                          option.campaign
                        } | Campaign ID: ${
                          option.campaignid
                        } | Start Date: ${formatDateForMerged(
                          option.campaign_start_date
                        )} | End Date: ${formatDateForMerged(
                          option.campaign_end_date
                        )}`
                      : // `${option.dataset_id}_${option.campaign}`
                        option
                  }
                  style={{ wordWrap: "break-word" }}
                >
                  {id === "target_campaigns"
                    ? `${option.dataset_id}_${option.campaign}`
                    : // `Dataset Name : ${option.dataset_id} | Campaign Name: ${
                      //   option.campaign
                      // } | Campaign ID: ${
                      //   option.campaignid
                      // } | Start Date: ${formatDateForMerged(
                      //   option.campaign_start_date
                      // )} | End Date: ${formatDateForMerged(
                      //   option.campaign_end_date
                      // )}`
                      option}
                </p>
              </div>
            ))}
          </div>
        </Popover>
      )}
    </>
  );
}

export default MultiSelectWithCheckBox;
