//Import required libraies
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Import Custom Component
import LocalParameterItem from "./LocalParameterItem";

// Import actions
import {
  updateModelLocalParameters,
  addConfigManualKPI,
  filterSUMediaDatasets,
} from "../../../../redux/actions";

// Import utils
import { config } from "../../../../config/config";
import noDataImage from "../../../../../assets/images/undraw_empty.svg";

// Import styles
import "./ModelConfig.scss";

const ModelConfig = (props) => {
  let {
    match,
    user,
    activeModel,
    activeModelId,
    selectedModel,
    configUserInputs,
    updateModelLocalParameters,
    disableFlag,
    changeFlag,
    activeSelection,
    loading,
    activeApp,
    updateUserInfo,
    allData,
    updateCheckDE,
    addConfigManualKPI,
    datasetsTypesData,
    filterSUMediaDatasets,
  } = props;

  let localParameters = activeModel.local_parameters;
  const [manualKpi, setManualKpi] = useState("");
  //remove the statistical_measure key from the active model
  if (!updateCheckDE) {
    if (activeModelId === "MMT") {
      localParameters = activeModel.local_parameters.filter(
        (e) => !["statistical_measure", "sales_filter"].includes(e.id)
      );
    }
  }

  // to load the manual kpi on opening editable config or changing the model
  useEffect(() => {
    if (config.hardCoded.manualKpiARR.includes(activeModelId)) {
      const manualDataSource = selectedModel?.data_sources.length
        ? selectedModel?.data_sources[0]
        : undefined;
      const selectedKpi = manualDataSource
        ? Object.keys(manualDataSource.kpi_list)[0]
        : "";
      setManualKpi(selectedKpi || "");
    }
    return () => {};
  }, [
    activeModelId,
    selectedModel.config.rbt_run_type,
    configUserInputs?.is_config_manual,
    selectedModel?.config?.manual_kpi,
  ]);
  const filterMediaDataset =
    activeModel?.id === config.hardCoded.salesUpliftId &&
    datasetsTypesData
      ?.filter((data) => data.name === "media")[0]
      ?.data?.map((e) => ({ name: e.name, id: e.id }));

  // useEffect(() => {
  //   if (
  //     activeModel?.id === config.hardCoded.salesUpliftId &&
  //     configUserInputs?.globalRegions?.length &&
  //     configUserInputs?.datasetDetailsData?.status === "success" &&
  //     configUserInputs?.is_config_manual &&
  //     updateCheckDE
  //   ) {
  //     // filterSUMediaDatasets({
  //     //   datasets: filterMediaDataset,
  //     // });
  //   }
  // }, [
  //   filterMediaDataset?.length,
  //   // configUserInputs?.globalRegions,
  //   configUserInputs?.datasetDetailsData?.status,
  // ]);

  return (
    <div className="models-selections-column right">
      {/* <div className="models-selection-title-container">
        <p className="models-selection-title">Model Configuration:</p>
      </div> */}

      {activeSelection === "model config" && (
        <div
          className={`models-selection-content right ${
            localParameters?.length ? "" : "no-modelconfig-container"
          }  `}
        >
          {localParameters?.length ? (
            localParameters
              ?.filter((obj) =>
                configUserInputs.is_config_manual
                  ? selectedModel.config.rbt_run_type !==
                    config.hardCoded.budgetId
                    ? !config.hardCoded.mulipleRunType.includes(obj.id)
                    : !config.hardCoded.mulipleRunType.includes(obj.id) &&
                      obj.id !== "manual_kpi"
                  : !config.hardCoded.mulipleRunType.includes(obj.id) &&
                    obj.id !== "manual_kpi"
              )
              .map((obj) => {
                let value =
                  obj.id === "manual_kpi"
                    ? manualKpi
                    : selectedModel.config[obj.id];
                let onChange = (key, value) => {
                  let data = {
                    modelId: activeModelId,
                    key,
                    value,
                  };
                  if (obj.id === "manual_kpi") {
                    const manualData = {
                      kpi_name: value,
                      modelId: activeModelId,
                    };
                    setManualKpi(value);
                    if (config.hardCoded.rbtAndSuIds.includes(activeModelId)) {
                      updateModelLocalParameters({
                        modelId: activeModelId,
                        key,
                        value,
                      });
                    }
                    addConfigManualKPI(manualData);
                  } else {
                    updateModelLocalParameters(data);
                  }
                };
                return (
                  <div
                    className={`datasets-selections-local-parameters-row ${
                      disableFlag || obj.id === "preferred_sales_dataset"
                        ? obj.id === "cols_to_force_in"
                          ? ""
                          : "disabled-pointer"
                        : ""
                    }`}
                    key={obj.id}
                  >
                    {
                      <LocalParameterItem
                        {...obj}
                        value={value}
                        onChange={onChange}
                        userInputs={configUserInputs}
                        allCampaigns={configUserInputs.campaignsData}
                        userSelectedCampaigns={value}
                        activeModel={activeModel}
                        disableFlag={disableFlag}
                        match={match}
                        changeFlag={changeFlag}
                        loading={loading}
                        activeApp={activeApp}
                        userData={allData}
                        updateCheckDE={updateCheckDE}
                        selectedModel={selectedModel}
                        datasetsTypesData={datasetsTypesData}
                        filterMediaDataset={filterMediaDataset}
                      />
                    }
                  </div>
                );
              })
          ) : (
            // <p className="no-info-center">No parameters available</p>
            <div className="no-info-holder">
              <img src={noDataImage} />
              <p>{config.messages.noConfigs}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  updateModelLocalParameters,
  addConfigManualKPI,
  filterSUMediaDatasets,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelConfig);
