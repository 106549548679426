//Import required libraies
import React from "react";
// import Chip from "@material-ui/core/Chip";
import Chip from "@material-ui/core/Chip";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Checkbox from "@material-ui/core/Checkbox";
import Checkbox from "@material-ui/core/Checkbox";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data
import { config } from "../../../../config/config";

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
      maxWidth: "250px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFormControl-fullWidth": {
      // minWidth: "200px",
      // maxWidth: "900px",
      width: "100%",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri !important",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-option": {
      backgrounColor: "#46596a3b",
      fontFamily: "Hind Siliguri !important",
    },
  },
  root: {
    marginTop: "10px",
    fontFamily: "Hind Siliguri !important",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

const StyledAutoComplete = withStyles((theme) => ({
  tag: {
    // background: "white",
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
}))(Autocomplete);

const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function AutoCompleteSearch(props) {
  let {
    id,
    data,
    name,
    value,
    updateDatasetLocalParameters,
    customClassName,
    userInputs,
    activeModelId,
    selectedDataset,
    activeDataset,
    activeDatasetDates,
    disableFlag,
  } = props;
  const muiClass = useStyles();

  const handleChange = (e, value) => {
    let skuArray;
    if (value.length) {
      if (value[value.length - 1].sku === config.hardCoded.oldAllSelection) {
        skuArray = [{ sku: "ALL" }];
      } else {
        skuArray = value.filter(
          (item) => item.sku !== config.hardCoded.oldAllSelection
        );
      }
    } else {
      skuArray = [{ sku: "ALL" }];
    }
    let data = {
      modelId: activeModelId,
      selectedDataset,
      dataset: selectedDataset ? selectedDataset : activeDataset,
      key: "sku",
      value: skuArray,
      activeDatasetDates,
    };
    updateDatasetLocalParameters(data);
  };

  return (
    <div className={`sku-container ${disableFlag ? "disabled-pointer" : ""}`}>
      <label className="bold header-text">{name}:</label>
      <StyledAutoComplete
        multiple
        getOptionSelected={(option, value) => option.sku === value?.sku}
        id="fixed-tags-demo"
        value={value}
        disableCloseOnSelect
        limitTags={5}
        onChange={(event, value) => {
          handleChange(event, value);
        }}
        options={data || []}
        getOptionLabel={(option) => option.sku || ""}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <div key={index} title={`SKU: ${option?.sku}`}>
              <Chip label={option?.sku} {...getTagProps({ index })} />
            </div>
          ));
        }}
        size="small"
        className={muiClass.typographyStyle}
        renderOption={(option, { selected }) => (
          <>
            <StyledCheckbox checked={selected} styleVariant="secondary" />
            {option.sku}
          </>
        )}
        renderInput={(params) => (
          <CssTextField
            {...params}
            placeholder={disableFlag ? "" : "Choose SKU"}
            variant="outlined"
            size="small"
            className={muiClass.typographyStyle}
          />
        )}
      />
    </div>
  );
}

export default AutoCompleteSearch;
