//Import required libraies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";

//Import custom component
import ModelSelections from "./components/ModelSelections";
import Buttons from "./components/Buttons";
import Loader from "../../../components/Loader/Loader";
import TestControlRegions from "./components/TestControlRegions";
import LocalParameterItem from "./components/LocalParameterItem";
import AddTags from "../AddNewConfig/components/AddTags";
import ConfigSettings from "../AddNewConfig/components/ConfigSettings";
import Modal from "../../../components/Modal/Modal";

// Import custom hooks

// Import action creators
import {
  updateConfigUserInputs,
  updateCountryRegions,
  updateUserInfo,
  updateRegionsData,
  emptyTestControl,
  updateControlRegions,
  updateTestRegions,
  updateWholeConfigUserInputs,
  updateData,
  onChangeDERunConfig,
  addGlobalParams,
  updateAlertInfo,
} from "../../../redux/actions";

//Import data

// Import utils
import {
  makeConfigUserInputs,
  salesMediaFilterFlag,
} from "../../../utils/utils";
import { config } from "../../../config/config";

// Import styles
import "./AddNewConfig.scss";
import "./components/ConfigSetting.scss";
import { isEmpty, isEqual } from "lodash";
import { Button } from "../../../uiTheme";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function AddNewConfig(props) {
  let {
    match,
    user,
    allData,
    configUserInputs,
    updateConfigUserInputs,
    updateCountryRegions,
    updateUserInfo,
    updateRegionsData,
    emptyTestControl,
    updateControlRegions,
    updateTestRegions,
    updateWholeConfigUserInputs,
    updateData,
    disableFlag,
    runData,
    onChangeDERunConfig,
    addGlobalParams,
    updateAlertInfo,
  } = props;

  const [loading, setLoading] = useState(true);
  const [globalParamsOpen, setGlobalParamsOpen] = useState(true);
  const [activeSelection, setActiveSelection] = useState("dataset");
  const [globalParamsObj, setGlobalParamsObj] = useState({});
  const [changeInGlobalParamsFlag, setChangeInGlobalParamsFlag] =
    useState(false);
  const [defaultGlobalParamsObj, setDefaultGlobalParamsObj] = useState({});
  const filterFlag =
    allData.subBrand.status === "success" &&
    allData.segments.status === "success" &&
    allData.subSegment.status === "success" &&
    allData.mediaSubBrand.status === "success" &&
    allData.mediaSegments.status === "success" &&
    allData.mediaSubSegment.status === "success"
      ? true
      : false;
  // loading the default global params
  useEffect(() => {
    if (configUserInputs.country !== "") {
      setDefaultGlobalParamsObj({
        country: configUserInputs.country,
        brand: configUserInputs.brand,
        sub_brand: configUserInputs.sub_brand,
        segment: configUserInputs.segment,
        sub_segment: configUserInputs.sub_segment,
        media_sub_brand: configUserInputs.media_sub_brand,
        media_segment: configUserInputs.media_segment,
        media_sub_segment: configUserInputs.media_sub_segment,
        global_week_date_selection: configUserInputs.global_week_date_selection,
        historic_week_data: configUserInputs.historic_week_data,
        startDate: configUserInputs.startDate,
        endDate: configUserInputs.endDate,
      });
    }
  }, [configUserInputs.models.length]);
  // setting default globalParams obj
  useEffect(() => {
    if (isEmpty(globalParamsObj) && !isEmpty(defaultGlobalParamsObj)) {
      setGlobalParamsObj(defaultGlobalParamsObj);
    }
  });
  // function to get the different keys
  const getObjectDiff = (obj1, obj2) => {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));
    return diff;
  };
  // to get the flag for change in globalParams
  useEffect(() => {
    if (configUserInputs.models.length) {
      const diff = getObjectDiff(defaultGlobalParamsObj, globalParamsObj);
      if (diff.length) {
        setChangeInGlobalParamsFlag(true);
      } else {
        setChangeInGlobalParamsFlag(false);
      }
    }
  }, [
    globalParamsObj.country,
    globalParamsObj.brand,
    globalParamsObj.sub_brand,
    globalParamsObj.segment,
    globalParamsObj.sub_segment,
    globalParamsObj.global_week_date_selection,
    globalParamsObj.historic_week_data,
    globalParamsObj.startDate,
    globalParamsObj.endDate,
    configUserInputs.models.length,
    globalParamsObj.media_sub_brand,
    globalParamsObj.media_segment,
    globalParamsObj.media_sub_segment,
  ]);

  // onChange function for globalParamsObj
  const globalParamsOnChange = (key, value) => {
    if (!isEmpty(globalParamsObj)) {
      setGlobalParamsObj((pre) => {
        return { ...pre, [key]: value };
      });
    }
  };

  //Defining required variables
  let app = config.hardCoded.mroiAppId.toLowerCase();
  let activeApp = allData.apps.data
    ? allData.apps.data.find(
        (obj) => obj.name.toLowerCase() === app.toLowerCase()
      )
    : null;

  const [modalComponent, setModalComponent] = useState({
    component: null,
    openModal: false,
  });

  const handleModalDialogClose = () => {
    setModalComponent((prevState) => {
      return { ...prevState, openModal: false };
    });
  };
  const handleModalDialogOpen = (component) => {
    setModalComponent((prevState) => {
      return {
        ...prevState,
        openModal: true,
        component: component,
      };
    });
  };

  //Update configData
  useEffect(() => {
    let initialConfigData, configUserInputs;
    let config_type = "add";
    let initialConfigDataFallback = allData.apps.data.find(
      (obj) =>
        obj.name.toLowerCase() === config.hardCoded.mroiAppId.toLowerCase()
    ).default_config_template;
    if (disableFlag) {
      configUserInputs = makeConfigUserInputs({
        initialConfigData: {
          selections: runData.payload,
          id: runData.metadata.config_id,
          name: runData.metadata.config_name,
          app_id: runData.app_id,
          metadata: runData?.metadata,
        },
        match,
        disableFlag,
        activeApp,
        allData,
        configType: "run",
        sub_brand_select_all: runData.payload.config.sub_brand_select_all,
        sub_segment_select_all: runData.payload.config.sub_segment_select_all,
        segment_select_all: runData.payload.config.segment_select_all,
        media_sub_brand_select_all:
          runData.payload.config.media_sub_brand_select_all,
        media_sub_segment_select_all:
          runData.payload.config.media_sub_segment_select_all,
        media_segment_select_all:
          runData.payload.config.media_segment_select_all,
      });
    } else {
      //Make selections based on add/edit tab
      if (match.params.configName) {
        initialConfigData =
          match.params.tab === "myconfigurations" ||
          match.params.tab === "recent"
            ? allData.saved_configurations?.find(
                (config) =>
                  config.name.toLowerCase() ===
                  match.params.configName.toLowerCase()
              )
            : match.params.tab === "sharedwithme"
            ? allData.shared_configurations?.find(
                (config) =>
                  config.name.toLowerCase() ===
                  match.params.configName.toLowerCase()
              )
            : allData.starred_configurations.data?.find(
                (config) =>
                  config.name.toLowerCase() ===
                  match.params.configName.toLowerCase()
              );
        config_type = "edit";
      } else {
        initialConfigData = allData.apps.data.find(
          (obj) =>
            obj.name.toLowerCase() === config.hardCoded.mroiAppId.toLowerCase()
        ).default_config_template;
        config_type = "add";
      }
      configUserInputs = makeConfigUserInputs({
        initialConfigData,
        match,
        disableFlag,
        activeApp,
        initialConfigDataFallback,
        allData,
        configType: config_type,
        sub_brand_select_all:
          config_type === "add"
            ? true
            : initialConfigData.selections.config.sub_brand_select_all,
        sub_segment_select_all:
          config_type === "add"
            ? true
            : initialConfigData.selections.config.sub_segment_select_all,
        segment_select_all:
          config_type === "add"
            ? true
            : initialConfigData.selections.config.segment_select_all,
        media_sub_brand_select_all:
          config_type === "add"
            ? true
            : initialConfigData.selections.config.media_sub_brand_select_all,
        media_sub_segment_select_all:
          config_type === "add"
            ? true
            : initialConfigData.selections.config.media_sub_segment_select_all,
        media_segment_select_all:
          config_type === "add"
            ? true
            : initialConfigData.selections.config.media_segment_select_all,
      });
    }
    updateWholeConfigUserInputs(configUserInputs);
    setDefaultGlobalParamsObj({
      country: configUserInputs.country,
      brand: configUserInputs.brand,
      sub_brand: configUserInputs.sub_brand,
      segment: configUserInputs.segment,
      sub_segment: configUserInputs.sub_segment,
      media_sub_brand: configUserInputs.media_sub_brand,
      media_segment: configUserInputs.media_segment,
      media_sub_segment: configUserInputs.media_sub_segment,
      global_week_date_selection: configUserInputs.global_week_date_selection,
      historic_week_data: configUserInputs.historic_week_data,
      startDate: configUserInputs.startDate,
      endDate: configUserInputs.endDate,
    });
    setGlobalParamsObj(defaultGlobalParamsObj);
    setLoading(false);
  }, []);

  const createDefault = () => {
    let initialConfigData, configUserInputs;
    let config_type = "add";
    let initialConfigDataFallback = allData.apps.data.find(
      (obj) =>
        obj.name.toLowerCase() === config.hardCoded.mroiAppId.toLowerCase()
    ).default_config_template;

    initialConfigData = allData.apps.data.find(
      (obj) =>
        obj.name.toLowerCase() === config.hardCoded.mroiAppId.toLowerCase()
    ).default_config_template;
    config_type = "add";

    configUserInputs = makeConfigUserInputs({
      initialConfigData,
      match,
      disableFlag,
      activeApp,
      initialConfigDataFallback,
      allData,
      configType: config_type,
      sub_brand_select_all: true,
      sub_segment_select_all: true,
      segment_select_all: true,
      media_sub_brand_select_all: true,
      media_sub_segment_select_all: true,
      media_segment_select_all: true,
    });
    updateWholeConfigUserInputs(configUserInputs);
    setDefaultGlobalParamsObj({
      country: configUserInputs.country,
      brand: configUserInputs.brand,
      sub_brand: configUserInputs.sub_brand,
      segment: configUserInputs.segment,
      sub_segment: configUserInputs.sub_segment,
      media_sub_brand: configUserInputs.media_sub_brand,
      media_segment: configUserInputs.media_segment,
      media_sub_segment: configUserInputs.media_sub_segment,
      global_week_date_selection: configUserInputs.global_week_date_selection,
      historic_week_data: configUserInputs.historic_week_data,
      startDate: configUserInputs.startDate,
      endDate: configUserInputs.endDate,
    });
    setGlobalParamsObj({
      country: configUserInputs.country,
      brand: configUserInputs.brand,
      sub_brand: configUserInputs.sub_brand,
      segment: configUserInputs.segment,
      sub_segment: configUserInputs.sub_segment,
      media_sub_brand: configUserInputs.media_sub_brand,
      media_segment: configUserInputs.media_segment,
      media_sub_segment: configUserInputs.media_sub_segment,
      global_week_date_selection: configUserInputs.global_week_date_selection,
      historic_week_data: configUserInputs.historic_week_data,
      startDate: configUserInputs.startDate,
      endDate: configUserInputs.endDate,
    });
  };

  // Update segment and config fixed name every time user changes country or brand
  useEffect(() => {
    if (!loading) {
      if (configUserInputs?.is_config_manual) {
        if (configUserInputs.brand) {
          updateConfigUserInputs(
            "configNameFixed",
            `${config.hardCoded.mroiAppId}-${configUserInputs.country}-${configUserInputs.brand}-`
          );
        } else {
          updateConfigUserInputs(
            "configNameFixed",
            `${config.hardCoded.mroiAppId}-${configUserInputs.country}-`
          );
        }
      } else {
        updateConfigUserInputs(
          "configNameFixed",
          `${config.hardCoded.mroiAppId}-${configUserInputs.country}-${configUserInputs.brand}-`
        );
      }
    }
  }, [configUserInputs.country, configUserInputs.brand]);

  //checking the DE run mode for MROI app
  const updateCheckDE = configUserInputs.models.every((e) =>
    e.config.hasOwnProperty("run_model")
  )
    ? configUserInputs.models.every((e) => e.config.run_model === true)
    : true;
  const checkSalesMediaFilters = (params) => {
    let commonString = "Please select at least one";
    let string = "";
    if (!params.sub_brand.length) {
      string = string + " Sales Sub Brand";
    }
    if (!params.segment.length) {
      string = string.length
        ? string + ", Sales Segment"
        : string + " Sales Segment";
    }
    if (!params.sub_segment.length) {
      string = string.length
        ? string + ", Sales Sub Segment"
        : string + " Sales Sub Segment";
    }
    if (
      configUserInputs.models.find(
        (model) => model.run && config.hardCoded.autoMlIds.includes(model.id)
      )
    ) {
      if (!params.media_sub_brand.length) {
        string = string.length
          ? string + ", Media Sub Brand"
          : string + " Media Sub Brand";
      }
      if (!params.media_segment.length) {
        string = string.length
          ? string + ", Media Segment"
          : string + " Media Segment";
      }
      if (!params.media_sub_segment.length) {
        string = string.length
          ? string + ", Media Sub Segment"
          : string + " Media Sub Segment";
      }
    }
    if (string.length) {
      return { valid: false, message: commonString + string };
    } else {
      return { valid: true, message: "" };
    }
  };
  useEffect(() => {
    if (salesMediaFilterFlag(allData)) {
      updateData("salesMediaFilterFlag", true);
    }
  }, [salesMediaFilterFlag(allData)]);

  useEffect(() => {
    return () => {
      updateData("salesMediaFilterFlag", false);
    };
  }, []);
  // useEffect(() => {
  //   if (!isEmpty(globalParamsObj)) {
  //     console.log("!!configUserInputs", configUserInputs);
  //     let newGlobalParamsObj = {
  //       ...globalParamsObj,
  //       ...(configUserInputs.sub_brand_select_all && {
  //         sub_brand: [
  //           config.hardCoded.allSelection,
  //           ...configUserInputs.sub_brand,
  //         ],
  //       }),
  //       ...(configUserInputs.segment_select_all && {
  //         segment: [config.hardCoded.allSelection, ...configUserInputs.segment],
  //       }),
  //       ...(configUserInputs.sub_segment_select_all && {
  //         sub_segment: [
  //           config.hardCoded.allSelection,
  //           ...configUserInputs.sub_segment,
  //         ],
  //       }),
  //       ...(configUserInputs.media_sub_brand_select_all && {
  //         media_sub_brand: [
  //           config.hardCoded.allSelection,
  //           ...configUserInputs.media_sub_brand,
  //         ],
  //       }),
  //       ...(configUserInputs.media_segment_select_all && {
  //         media_segment: [
  //           config.hardCoded.allSelection,
  //           ...configUserInputs.media_segment,
  //         ],
  //       }),
  //       ...(configUserInputs.media_sub_segment_select_all && {
  //         media_sub_segment: [
  //           config.hardCoded.allSelection,
  //           ...configUserInputs.media_sub_segment,
  //         ],
  //       }),
  //     };
  //     console.log("newGlobalParamsObj", newGlobalParamsObj);
  //     // addGlobalParams(newGlobalParamsObj);
  //     // setDefaultGlobalParamsObj(newGlobalParamsObj);
  //     // setChangeInGlobalParamsFlag(false);
  //   }
  // }, [globalParamsObj]);
  return (
    <>
      <div className="config-additional-settings">
        <AddTags
          configUserInputs={configUserInputs}
          updateConfigUserInputs={updateConfigUserInputs}
          updateUserInfo={updateUserInfo}
          updateData={updateData}
          allData={allData}
          activeApp={activeApp}
          disableFlag={disableFlag}
          user={user}
        />
        {!disableFlag &&
          config.hardCoded.nativeAppIdList.includes(activeApp?.id) && (
            <ConfigSettings
              configUserInputs={configUserInputs}
              updateConfigUserInputs={updateConfigUserInputs}
              allData={allData}
              activeApp={activeApp}
              match={match}
              createDefault={createDefault}
              activeSelection={activeSelection}
              setActiveSelection={setActiveSelection}
              onChangeDERunConfig={onChangeDERunConfig}
              updateData={updateData}
            />
          )}
      </div>
      <div className="add-new-config">
        {!loading && !configUserInputs.loading ? (
          <>
            <div className={`${disableFlag ? "no-bottom-panel" : "top-panel"}`}>
              {/* TOGGLE ICON */}
              <div className="toggle-icon">
                <label
                  onClick={() => {
                    setGlobalParamsOpen(!globalParamsOpen);
                  }}
                >
                  {globalParamsOpen ? "Collapse" : "Expand"}
                </label>
                <i
                  className="material-icons global-params-toggle-icon"
                  onClick={() => {
                    setGlobalParamsOpen(!globalParamsOpen);
                  }}
                >
                  {globalParamsOpen
                    ? "keyboard_arrow_up"
                    : "keyboard_arrow_down"}
                </i>
              </div>

              {/* NAME + GLOBAL PARAMETERS */}
              <div
                className="add-config"
                style={{
                  height: globalParamsOpen
                    ? typeof configUserInputs.media_sub_brand_select_all ===
                        "undefined" || configUserInputs.is_config_manual
                      ? "180px"
                      : "230px"
                    : "80px",
                  overflowY: globalParamsOpen ? "auto" : "hidden",
                }}
              >
                <div className="row collapsed-config">
                  <div className="name">
                    <div className="config-name-label-container">
                      <label htmlFor="title" className="title">
                        Configuration Name
                      </label>
                      {configUserInputs?.is_config_manual && (
                        <button className="name-chip">
                          <label>Manual Run</label>
                        </button>
                      )}
                      {configUserInputs?.is_schedule_config && (
                        <button className="name-chip">
                          <label>Schedule Run</label>
                        </button>
                      )}
                      {updateCheckDE === false && (
                        <button className="name-chip">
                          <label>DE Only</label>
                        </button>
                      )}
                    </div>
                    <div className="name-config">
                      <div
                        className="config-name"
                        title={configUserInputs.configNameFixed}
                      >
                        {configUserInputs.configNameFixed}
                      </div>
                      <input
                        type="text"
                        value={configUserInputs.configName}
                        onChange={(e) => {
                          updateConfigUserInputs("configName", e.target.value);
                        }}
                        className={`${disableFlag ? "disabled-pointer" : ""}`}
                      />
                    </div>
                  </div>
                  {!configUserInputs?.is_config_manual && (
                    <div className="date-range">
                      {orderBy(allData.globalParams, ["name"]).map(
                        (obj) =>
                          obj.id === "global_week_date_selection" && (
                            <div key={obj.id}>
                              <LocalParameterItem
                                {...obj}
                                userInputs={configUserInputs}
                                setUserInputs={updateConfigUserInputs}
                                disableFlag={disableFlag}
                                match={match}
                                globalParamsOnChange={globalParamsOnChange}
                                globalParamsObj={globalParamsObj}
                              />
                            </div>
                          )
                      )}
                    </div>
                  )}
                </div>
                {
                  <div className="row globalParameter collapsed-config">
                    {orderBy(allData.globalParams, ["name"]).map(
                      (obj) =>
                        obj.id !== "global_week_date_selection" && (
                          <div key={obj.id} className={`input-item-container`}>
                            <LocalParameterItem
                              {...obj}
                              userInputs={configUserInputs}
                              setUserInputs={updateConfigUserInputs}
                              disableFlag={disableFlag}
                              match={match}
                              globalParamsOnChange={globalParamsOnChange}
                              globalParamsObj={globalParamsObj}
                              defaultGlobalParamsObj={defaultGlobalParamsObj}
                              setGlobalParamsObj={setGlobalParamsObj}
                              setDefaultGlobalParamsObj={
                                setDefaultGlobalParamsObj
                              }
                              updateCheckDE={updateCheckDE}
                            />
                          </div>
                        )
                    )}
                    {
                      <div className={`update-global-params`}>
                        {configUserInputs.configType !== "run" &&
                          !configUserInputs?.is_config_manual && (
                            <>
                              <Button
                                disabled={
                                  configUserInputs.configType === "run"
                                    ? true
                                    : changeInGlobalParamsFlag && filterFlag
                                    ? false
                                    : true
                                }
                                variant="contained"
                                onClick={() => {
                                  let status =
                                    checkSalesMediaFilters(globalParamsObj);
                                  if (status.valid) {
                                    addGlobalParams(globalParamsObj);
                                    setDefaultGlobalParamsObj(globalParamsObj);
                                    setChangeInGlobalParamsFlag(false);
                                    updateConfigUserInputs(
                                      "datasetDetailsFlag",
                                      true
                                    );
                                  } else {
                                    updateAlertInfo({
                                      open: true,
                                      message: status.message,
                                      severity: "info",
                                    });
                                  }
                                }}
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  minWidth: "80px",
                                  background:
                                    configUserInputs.configType === "run"
                                      ? "rgba(0,0,0,0.4)"
                                      : changeInGlobalParamsFlag && filterFlag
                                      ? "#46596a"
                                      : "rgba(0,0,0,0.4)",
                                }}
                              >
                                Submit
                              </Button>
                              {changeInGlobalParamsFlag && (
                                <p className="pending-changes-warning">
                                  Pending Changes
                                </p>
                              )}
                            </>
                          )}
                      </div>
                    }
                  </div>
                }
              </div>

              {/* MODEL SELECTIONS */}
              <div className="user-input colorless">
                <div className="content">
                  {configUserInputs.models.length && allData.models?.length ? (
                    <ModelSelections
                      match={match}
                      loading={loading}
                      disableFlag={disableFlag}
                      allData={allData}
                      activeSelection={activeSelection}
                      setActiveSelection={setActiveSelection}
                      updateCheckDE={updateCheckDE}
                    />
                  ) : (
                    <div className="loader-center">
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* BUTTONS */}
            {!disableFlag && (
              <div className="bottom-panel">
                <div className="user-actions-buttons">
                  <Buttons
                    match={match}
                    changeInGlobalParamsFlag={changeInGlobalParamsFlag}
                  />
                </div>
              </div>
            )}
            {configUserInputs?.is_schedule_config && (
              <Modal
                modalComponent={modalComponent}
                setModalComponent={setModalComponent}
                handleDialogClose={() => {}}
              />
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}

AddNewConfig.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  updateConfigUserInputs,
  updateCountryRegions,
  updateUserInfo,
  updateRegionsData,
  emptyTestControl,
  updateControlRegions,
  updateTestRegions,
  updateWholeConfigUserInputs,
  updateUserInfo,
  updateData,
  onChangeDERunConfig,
  addGlobalParams,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewConfig);
