//Import required libraies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { StyledCheckbox } from "../../../../../styles/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

//Import Custom Component
import {
  addKpi,
  removeKpi,
  addToggleKpi,
  toggleKpi,
  updateKpiVariables,
  resetNewKPI,
} from "../../../../redux/actions";

// Import custom hooks

// Import action creators

//Import utils/data
import { getMandatoryKPIToTop } from "../../../../utils/utils";
import { config } from "../../../../config/config";
import autoMLDatasetMapping from "../../../../../assets/data/autoMLDatasetMapping.json";
import undrawSelection from "../../../../../assets/images/undraw_selection.svg";
import Loader from "../../../../components/Loader/Loader";
// Import styles

// const StyledCheckbox = withStyles((theme) => ({
//   root: {
//     color: "#46596a !important",
//     "& .MuiSvgIcon-root": {
//       width: 18,
//       height: 18,
//     },
//     "&:hover": {
//       backgroundColor: "transparent",
//     },
//   },
// }))(Checkbox);

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "100%",
    height: "30px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "8px 12px !important",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
});
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};
function AutoMLNeilsenDataset(props) {
  let {
    match,
    user,
    activeModel,
    ui_element_type,
    activeModelId,
    kpi,
    activeDatasetId,
    setActiveDatasetId,
    disableFlag,
    toggleDataset,
    activeDatasetForKpi,
    selectedDataset,
    activeDataset,
    addKpi,
    removeKpi,
    addToggleKpi,
    toggleKpi,
    kpiTypesData, // global dataset data
    updateDatasetLocalParameters,
    selectedModelDatasets, // user selected data
    activeDatasetDates,
    updateKpiVariables,
    resetNewKPI,
    configUserInputs,
  } = props;

  const classes = useStyles();

  //check nielsen_noncomp has nielsen_comp_available
  const [nielsenComp, setNielsenComp] = useState(null);
  const [topN, setTopN] = useState(5);

  useEffect(() => {
    if (selectedModelDatasets.length > 0) {
      selectedModelDatasets.forEach((dataset) => {
        if (
          dataset.pipeline_dataset_id === config.hardCoded.neilsenNonCompId &&
          dataset.id === activeDataset.id
        ) {
          setNielsenComp(dataset.nielsen_comp_available);
        }
      });
    } else {
      setNielsenComp(false);
    }
  }, [selectedModelDatasets]);
  //this will add the top_n_competitor value in config when checkbox is selected
  useEffect(() => {
    if (
      selectedModelDatasets.length > 0 &&
      selectedModelDatasets.map((el) => el.id).includes(activeDataset?.id) &&
      activeDataset?.pipeline_dataset_id ===
        config.hardCoded.neilsenNonCompId &&
      nielsenComp
    ) {
      let data = {
        activeDatasetForKpi: activeDatasetForKpi,
        modelId: activeModelId,
        selectedDataset,
        dataset: selectedDataset ? selectedDataset : activeDataset,
        key: "top_n_competitor",
        value:
          selectedDataset?.top_n_competitor ||
          selectedDataset?.top_n_competitor === 0
            ? selectedDataset.top_n_competitor
            : 5,
      };
      setTopN(data.value);
      updateDatasetLocalParameters(data);
    }
  }, [activeDatasetId, nielsenComp]);
  useEffect(() => {
    setTopN(
      selectedDataset &&
        (selectedDataset.top_n_competitor ||
          selectedDataset.top_n_competitor === 0)
        ? selectedDataset.top_n_competitor
        : 5
    );
  }, [activeDatasetId, activeModelId]);
  let newKpiList = [],
    singleSelectedKpiList = [];
  kpiTypesData.forEach(
    (data) =>
      (newKpiList = data?.kpi_list?.filter(
        (item) => config.hardCoded.mandatoryTargetVariable.indexOf(item) == -1
      ))
  );
  kpiTypesData.forEach((data) => (singleSelectedKpiList = data.kpi_list));

  // dropdown name -> display purpose
  let dropDownData = config.hardCoded.autoMlIds.includes(activeModel.id)
    ? autoMLDatasetMapping.autoMlIds
    : autoMLDatasetMapping.SuAndRbtIds;
  let displayKpiForAutoML = dropDownData
    .filter((elem) =>
      singleSelectedKpiList.some(
        (item) => item.toLowerCase() === elem.kpis.toLowerCase()
      )
    )
    .map((elem) => elem.name);

  // 2 parts split-> radioArray, multiArray manages Nielsen/category datasets
  // we got kpi data which user has selected
  let radioArray = [],
    multiArray = [];
  if (selectedDataset && selectedDataset?.kpi_list) {
    radioArray =
      selectedDataset?.kpi_list?.filter(
        (item) => config.hardCoded.mandatoryTargetVariable.indexOf(item) !== -1
      ) || [];
    if (radioArray.length && radioArray[0] === "sales_value") {
      radioArray = radioArray;
    } else {
      radioArray = radioArray.reverse() || [];
    }

    multiArray =
      selectedDataset.kpi_list.filter(
        (item) => config.hardCoded.mandatoryTargetVariable.indexOf(item) == -1
      ) || [];
  }

  //To check which target kpi were selected
  let availableTargetKpi = displayKpiForAutoML.find(
    (val) => radioArray.join(", ") == val
  );

  const averageKpi = selectedDataset?.kpi_variables_list?.find(
    (elem) => elem.id === "average_price"
  );

  useEffect(() => {
    let newVal =
      selectedDataset?.kpi_list?.filter((e) =>
        config.hardCoded.mandatoryTargetVariable.includes(e)
      ).length > 0
        ? selectedDataset?.kpi_list.filter((e) =>
            config.hardCoded.mandatoryTargetVariable.includes(e)
          )
        : displayKpiForAutoML[0]?.split(/\s*,\s*/);
    newVal = newVal ? newVal : [];
    let notSelectedKpiVariableList =
      activeDatasetForKpi.kpi_variables_list.filter((item) =>
        newVal.includes(item.id)
      );
    let newKpiVariableList = [...notSelectedKpiVariableList];
    if (selectedDataset?.kpi_variables_list) {
      newKpiVariableList = [
        ...newKpiVariableList,
        ...selectedDataset?.kpi_variables_list,
      ];
    }
    let data = {
      modelId: activeModelId,
      selectedDataset,
      dataset: selectedDataset ? selectedDataset : activeDataset,
      kpiList: [...newVal, ...multiArray],
      activeModel: activeModel,
      activeDatasetDates,
      kpi_variables_list: newKpiVariableList,
    };
    if (
      selectedModelDatasets.map((el) => el.id).includes(activeDataset?.id) &&
      nielsenComp
    ) {
      addToggleKpi(data);
    }
  }, [nielsenComp]);
  useEffect(() => {
    if (configUserInputs.configType !== "run") {
      let notSelectedKpiVariableList =
        activeDatasetForKpi.kpi_variables_list.filter(
          (item) => item.id === "average_price"
        );
      let newKpiVariableList = [...notSelectedKpiVariableList];
      if (selectedDataset?.kpi_variables_list) {
        newKpiVariableList = [
          ...newKpiVariableList,
          ...selectedDataset?.kpi_variables_list,
        ];
      }
      let data = {
        modelId: activeModelId,
        selectedDataset,
        dataset: selectedDataset ? selectedDataset : activeDataset,
        kpi: "average_price",
        activeModel: activeModel,
        activeDatasetDates: activeDatasetDates,
        kpi_variables_list: newKpiVariableList,
      };
      if (
        selectedDataset?.kpi_list?.length > 0 &&
        !selectedDataset?.kpi_list?.includes("average_price") &&
        newKpiList.includes("average_price")
      ) {
        addKpi(data);
      }
    }
  }, [selectedDataset?.kpi_list]);

  useEffect(() => {
    if (
      configUserInputs.configType !== "run" &&
      (selectedDataset?.kpi_variables_list?.find(
        (e) => e.id === "average_price"
      ) ||
        selectedDataset?.kpi_list?.find((e) => e.id === "average_price"))
    ) {
      let kpiData = {
        dataset: selectedDataset ? selectedDataset : activeDataset,
        modelId: activeModelId,
        key: "mandatory",
        value:
          typeof selectedDataset?.kpi_variables_list?.find(
            (elem) => elem.id === "average_price"
          )?.mandatory !== "undefined"
            ? selectedDataset?.kpi_variables_list?.find(
                (elem) => elem.id === "average_price"
              )?.mandatory
            : true,
        kpi: "average_price",
        kpi_variables_list: selectedDataset?.kpi_variables_list
          ? selectedDataset?.kpi_variables_list
          : activeDatasetForKpi.kpi_variables_list,
      };
      updateKpiVariables(kpiData);
    }
  }, [selectedDataset?.kpi_list, activeModelId]);

  newKpiList = newKpiList.filter((e) => {
    if (
      config.hardCoded.autoMlIds.includes(activeModel.id)
        ? selectedDataset?.kpi_list?.includes("sales_units")
        : selectedDataset?.kpi_list?.includes("sales_units") ||
          selectedDataset?.kpi_list?.includes("sales_value")
    ) {
      if (!config.hardCoded.volume_kpis.includes(e)) {
        return e;
      }
    } else if (selectedDataset?.kpi_list?.includes("sales_volume")) {
      if (!config.hardCoded.units_kpis.includes(e)) {
        return e;
      }
    } else {
      return e;
    }
  });
  const topNMaxMin = activeDataset?.local_parameters?.find(
    (el) => el.id === "top_n_competitor"
  );
  // console.groupCollapsed("AutoMLNeilsenDataset");
  // console.log(`radioArray`, radioArray);
  // console.log(`availableTargetKpi`, availableTargetKpi);
  // console.log("displayKpiForAutoML", displayKpiForAutoML);
  // console.log("kpiTypesData", kpiTypesData);
  // console.log(`selectedDataset`, selectedDataset);
  // console.log("selectedModelDatasets", selectedModelDatasets);
  // console.log("newKpiList", newKpiList);
  // console.groupEnd();

  return (config.hardCoded.nielsenDatasets.includes(activeDatasetId) &&
    configUserInputs.categoryData !== -1) ||
    (!config.hardCoded.nielsenDatasets.includes(activeDatasetId) &&
      configUserInputs?.datasetDetailsData?.status === "success") ||
    configUserInputs.configType === "run" ? (
    <>
      {displayKpiForAutoML?.length ? (
        <>
          {topN < topNMaxMin?.min_value && nielsenComp && (
            <p className="error-message">{`Minimum value should be ${topNMaxMin.min_value}`}</p>
          )}
          <div
            className={`input-item row target-variable ${
              disableFlag ? "disabled-pointer" : ""
            } `}
          >
            <label>Select Target Variable:</label>
            <Select
              className={classes.multiSelect}
              MenuProps={MenuProps}
              disabled={false}
              displayEmpty
              value={availableTargetKpi ? availableTargetKpi : ""}
              renderValue={() =>
                availableTargetKpi ? availableTargetKpi : "Choose a variable"
              }
              onChange={(e) => {
                let newVal = e.target.value?.split(/\s*,\s*/);
                let notSelectedKpiVariableList =
                  activeDatasetForKpi.kpi_variables_list.filter((item) =>
                    newVal.includes(item.id)
                  );
                let newKpiVariableList = [...notSelectedKpiVariableList];
                if (selectedDataset?.kpi_variables_list) {
                  newKpiVariableList = [
                    ...newKpiVariableList,
                    ...selectedDataset?.kpi_variables_list,
                  ];
                }

                let data = {
                  modelId: activeModelId,
                  selectedDataset,
                  dataset: selectedDataset ? selectedDataset : activeDataset,
                  kpiList: [...newVal, ...multiArray].filter((e) =>
                    config.hardCoded.resetAllExclude.includes(e)
                  ),

                  activeModel: activeModel,
                  activeDatasetDates,
                  kpi_variables_list: newKpiVariableList
                    .map((e) =>
                      e.id === "average_price" ? { ...e, mandatory: true } : e
                    )
                    .filter((e) =>
                      config.hardCoded.resetAllExclude.includes(e.id)
                    ),
                };

                addToggleKpi(data);
              }}
            >
              <MenuItem value="" disabled className={classes.singleMenuItem}>
                Please select option
              </MenuItem>
              {displayKpiForAutoML.map((obj, index) => (
                <MenuItem
                  key={index}
                  value={obj}
                  className={classes.singleMenuItem}
                >
                  {obj}
                </MenuItem>
              ))}
            </Select>
          </div>
        </>
      ) : null}
      {selectedDataset?.kpi_list?.length > 0 ? (
        newKpiList?.length ? (
          <div
            className={`kpi-selections-container ${
              disableFlag ? "disabled-pointer" : ""
            }`}
            style={{ pointerEvents: "auto" }}
          >
            <label className="bold">Select Input Variables:</label>
            <div className="input-variables-container">
              {newKpiList.map((value) => {
                let mandatoryKpi =
                  activeDatasetForKpi.mandatory_kpi_list.includes(value);
                let checkedFlag = selectedDataset
                  ? selectedDataset.kpi_list.includes(value)
                  : false;
                let averagePriceKpi = selectedDataset?.kpi_variables_list.find(
                  (kpi) => kpi.id === "average_price"
                );
                let modelKpiFlag = config.hardCoded.autoMlIds.includes(
                  activeModel.id
                )
                  ? config.hardCoded.avgPriceDependentKpiList.includes(value)
                  : config.hardCoded.avgPriceDependentKpiListForSuAndRbt.includes(
                      value
                    );
                let mandatoryFlag = averagePriceKpi?.mandatory && modelKpiFlag;
                return (
                  <div key={value}>
                    <div
                      className={`kpi-checkbox-container ${
                        mandatoryFlag ? "disabled-pointer new-kpi" : ""
                      }
                    ${mandatoryKpi ? "mandatory-kpi" : ""}
                    `}
                      style={{
                        pointerEvents:
                          disableFlag || mandatoryFlag ? "none" : "all",
                      }}
                      onClick={() => {
                        if (value !== "average_price") {
                          let notSelectedKpiVariableList =
                            activeDatasetForKpi.kpi_variables_list.filter(
                              (item) => item.id === value
                            );
                          let newKpiVariableList = [
                            ...notSelectedKpiVariableList,
                          ];
                          if (selectedDataset?.kpi_variables_list) {
                            newKpiVariableList = [
                              ...newKpiVariableList,
                              ...selectedDataset?.kpi_variables_list,
                            ];
                          }
                          let data = {
                            modelId: activeModelId,
                            selectedDataset,
                            dataset: selectedDataset
                              ? selectedDataset
                              : activeDataset,
                            kpi: value,
                            activeModel: activeModel,
                            activeDatasetDates: activeDatasetDates,
                            kpi_variables_list: newKpiVariableList,
                          };
                          checkedFlag ? removeKpi(data) : addKpi(data);
                        }
                      }}
                    >
                      <i
                        className={`kpi-checkbox-icon
                      ${
                        mandatoryKpi
                          ? "material-icons active"
                          : checkedFlag
                          ? "material-icons active"
                          : "material-icons-outlined"
                      }`}
                      >
                        {mandatoryKpi
                          ? "check_box"
                          : checkedFlag
                          ? "check_box"
                          : "check_box_outline_blank"}
                      </i>
                      <label>{value}</label>
                    </div>
                    {selectedDataset?.kpi_list?.length
                      ? activeDatasetForKpi.kpi_variables_list
                          .filter((data) => data.id === value)
                          .map((elem) => {
                            return (
                              <div
                                className="kpi-parameters-container"
                                key={elem.id}
                              >
                                {selectedDataset?.kpi_list.includes(elem.id) ? (
                                  <div className="kpi-parameters">
                                    {elem.kpi_parameters.map((data) => {
                                      return (
                                        <div
                                          key={data.id}
                                          className={`${
                                            disableFlag
                                              ? "disabled-pointer"
                                              : ""
                                          }`}
                                        >
                                          <label>{data.id}:</label>
                                          <Select
                                            className={classes.multiSelect}
                                            MenuProps={MenuProps}
                                            value={
                                              typeof selectedDataset?.kpi_variables_list?.find(
                                                (item) => item.id === elem.id
                                              )?.[data.id] !== "undefined" &&
                                              selectedDataset?.kpi_variables_list?.find(
                                                (item) => item.id === elem.id
                                              )?.[data.id] !== ""
                                                ? selectedDataset?.kpi_variables_list?.find(
                                                    (item) =>
                                                      item.id === elem.id
                                                  )?.[data.id]
                                                : data.default === "" ||
                                                  selectedDataset?.kpi_variables_list?.find(
                                                    (item) =>
                                                      item.id === elem.id
                                                  )?.[data.id] === ""
                                                ? "(blank)"
                                                : `${data.default}`
                                            }
                                            onChange={(e) => {
                                              let kpiData = {
                                                dataset: selectedDataset
                                                  ? selectedDataset
                                                  : activeDataset,
                                                modelId: activeModelId,
                                                key: data.id,
                                                value:
                                                  e.target.value === "(blank)"
                                                    ? ""
                                                    : e.target.value,
                                                kpi: elem.id,
                                                kpi_variables_list:
                                                  selectedDataset?.kpi_variables_list
                                                    ? selectedDataset?.kpi_variables_list
                                                    : activeDatasetForKpi.kpi_variables_list,
                                              };
                                              if (
                                                elem.id === "average_price" &&
                                                data.id === "mandatory" &&
                                                e.target.value
                                              ) {
                                                resetNewKPI(kpiData);
                                              } else {
                                                updateKpiVariables(kpiData);
                                              }
                                            }}
                                            disabled={
                                              elem.id === "average_price" &&
                                              data.id !== "mandatory"
                                                ? true
                                                : false
                                            }
                                          >
                                            {data.possible_values.map(
                                              (obj, index) => (
                                                <MenuItem
                                                  key={index}
                                                  value={
                                                    obj === "" ? "(blank)" : obj
                                                  }
                                                  className={
                                                    classes.singleMenuItem
                                                  }
                                                >
                                                  {obj === ""
                                                    ? "(blank)"
                                                    : `${obj}`}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <> </>
                                )}
                              </div>
                            );
                          })
                      : null}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="no-kpi-container">
            <div className="no-kpi-image">
              <img
                src={undrawSelection}
                width="200"
                height="200"
                alt=""
                draggable="false"
              ></img>
            </div>
            <p className="info-message">
              {`${
                !newKpiList?.length
                  ? "No Input Variable KPIs Available for Selection"
                  : displayKpiForAutoML?.length
                  ? "Please select a target variable to see the available input variables"
                  : "No KPIs Available for Selection"
              }`}
            </p>
          </div>
        )
      ) : (
        <div className="no-kpi-container">
          <div className="no-kpi-image">
            <img
              src={undrawSelection}
              width="200"
              height="200"
              alt=""
              draggable="false"
            ></img>
          </div>
          <p className="info-message">
            {`${
              displayKpiForAutoML?.length
                ? "Please select a target variable to see the available input variables"
                : "No KPIs Available for Selection"
            }`}
          </p>
        </div>
      )}

      {config.hardCoded.competitorArr.includes(
        activeDataset?.pipeline_dataset_id
      ) &&
        config.hardCoded.autoMlIds.includes(activeModel.id) && (
          <div
            className={`nielsen-comp-checkbox ${
              disableFlag ? "disabled-pointer" : ""
            }`}
          >
            <StyledCheckbox
              disableRipple
              checked={nielsenComp ? nielsenComp : false}
              onChange={(e) => {
                let data = {
                  activeDatasetForKpi: activeDatasetForKpi,
                  modelId: activeModelId,
                  selectedDataset,
                  dataset: selectedDataset ? selectedDataset : activeDataset,
                  key: "nielsen_comp_available",
                  value: !nielsenComp,
                };
                updateDatasetLocalParameters(data);
              }}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            {/* <label>Competitor Data</label> */}
            <span
              className={`checkbox-category ${
                nielsenComp ? "" : "no-category"
              }`}
            >
              Top{" "}
              <>
                <div
                  className={`top-n-competitor ${
                    nielsenComp ? "" : "no-category disabled-pointer"
                  }`}
                  style={{ width: "30px !important" }}
                >
                  <input
                    className={`${
                      topN < topNMaxMin?.min_value && nielsenComp
                        ? "error-top-n"
                        : ""
                    }`}
                    type="number"
                    onWheel={() => document.activeElement.blur()}
                    min={topNMaxMin?.min_value || 1}
                    max={Number.MAX_VALUE}
                    value={selectedDataset?.top_n_competitor}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let data = {
                        activeDatasetForKpi: activeDatasetForKpi,
                        modelId: activeModelId,
                        selectedDataset,
                        dataset: selectedDataset
                          ? {
                              ...selectedDataset,
                              minValue: topNMaxMin?.min_value,
                            }
                          : activeDataset,
                        key: "top_n_competitor",
                        value: +e.target.value,
                      };
                      setTopN(+e.target.value);
                      updateDatasetLocalParameters(data);
                    }}
                  />
                </div>
              </>
              Competitors
            </span>
          </div>
        )}
    </>
  ) : (
    <Loader />
  );
}

AutoMLNeilsenDataset.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  addKpi,
  removeKpi,
  addToggleKpi,
  toggleKpi,
  updateKpiVariables,
  resetNewKPI,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoMLNeilsenDataset);
