// Import required libraries
import React, { useEffect } from "react";
import useState from "react-usestateref";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
} from "@material-ui/core";
import { IconButton, Paper } from "@mui/material";
// import PopOver from "../../components/PopOver/PopOver";
// import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import { useHistory } from "react-router-dom";

// Import data & utils
import useTable from "../../utils/useTable";
import {
  flattenObject,
  timeDiffCalc,
  formatDate,
  makeRunNameFromRunNew,
  makeDefaultResponseJson,
  timeDifference,
  copyValueToClipboard,
} from "../../utils/utils";
import { config } from "../../config/config";

//import styles
import { muiCommonStyles } from "../../../styles/styles";

// Import action creators
import {
  updateMultiSelections,
  updateSelections,
  updateAlertInfo,
  reloadRunData,
  updateDialogInfo,
  updateRunData,
  updateSharedRunsData,
  updateRunExpandInfo,
} from "../../redux/actions";

//Import styles
import "./Table.scss";
import PopOverForRun from "../PopOverForRun/PopOverForRun";

const StyledTableCell = withStyles((theme) => ({
  body: {
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    borderBottom: "0px",
  },
}))(TableCell);
const InnerStyledTableCell = withStyles((theme) => ({
  body: {
    pointerEvents: "None",
    color: "#46596a",
    backgroundColor: "white !important",
    fontFamily: "Hind Siliguri",
    borderBottom: "0px",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  // for thin scroll bar
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.1em",
    },
    "*::-webkit-scrollbar-track": {},
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  listIcon: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    maxWidth: "500px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
  },
  tableRow: {
    backgroundColor: "white !important",
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    "&:hover": {
      backgroundColor: "#4eafb342 !important",
    },
  },
  starLoading: {
    marginRight: "5px",
    cursor: "pointer",
  },
  innerTableRow: {
    backgroundColor: "#46596a0d !important",
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    "&:hover": {
      backgroundColor: "#4eafb342 !important",
    },
  },
  innerName: {
    maxWidth: "500px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    borderTopLeftRadius: "6px",
    borderBottomLeftRadius: "6px",
  },
}));

function TableViewRunV2(props) {
  let {
    user,
    data,
    allData,
    paginationFlag,
    match,
    updateMultiSelections,
    updateDialogInfo,
    updateSelections,
    updateRunData,
    updateAlertInfo,
    updateRunExpandInfo,
    val,
  } = props;
  let app = config.hardCoded.mroiAppId.toLowerCase();
  const muiClass = muiCommonStyles();
  let activeApp = config.hardCoded.mroiAppId;
  let headCells = [];
  const tabCheck = ["shared_with_me_runs", "starred"].includes(val);
  const ownerColumn = tabCheck
    ? [
        {
          id: "",
          label: "Triggered By",
          width: "16%",
          disableSorting: "disable",
        },
      ]
    : [];
  headCells = [
    {
      id: "icon",
      label: "",
      width: "1%",
      disableSorting: "disable",
    },
    {
      id: "config_name",
      label: "Name",
      width: "29%",
      disableSorting: "disable",
    },
    {
      id: "run_type",
      label: "Run",
      width: tabCheck ? "13%" : "21%",
      disableSorting: "disable",
      align: "center",
    },
    ...ownerColumn,
    {
      id: "last_run_start_time",
      label: "Triggered On",
      width: tabCheck ? "16%" : "21%",
      disableSorting: "disable",
    },
    {
      id: "duration",
      label: "Duration",
      width: tabCheck ? "15%" : "18%",
      disableSorting: "disable",
    },
    {
      id: "",
      label: "",
      width: "10%",
      disableSorting: "disable",
    },
  ];
  const classes = useStyles();
  const [records, setRecords] = useState(data.map((row) => flattenObject(row)));
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [runLoading, setRunLoading, runLoadingRef] = useState([]);
  const history = useHistory();

  const setStarredValues = (runId) => {
    setRunLoading((prevValue) => {
      return [...prevValue, runId];
    });
  };

  const removeStarredValues = (runId) => {
    let sttValues = [...runLoadingRef.current];
    sttValues = sttValues.filter((item) => item !== runId); // remove
    setRunLoading([...sttValues]);
  };

  useEffect(() => {
    setRecords(data.map((row) => flattenObject(row)));
  }, [data]);

  const handleNo = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const updateRunAsStarred = (runId, runToBeStarred) => {
    // calling the APi here
    let url = `${config.api.bookmarkUrl}`;
    let body = {
      app_id: activeApp?.id,
      id: runId,
      type: "run",
    };
    let status;
    setStarredValues(runId);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        if (status === 200) {
          let allRunData = allData.runData;
          let allDataForViewRuns = allRunData;
          //   allRunData?.forEach((obj) => {
          //     allDataForViewRuns = [...allDataForViewRuns, ...obj.data];
          //   });
          console.log("runId, runToBeStarred", runId, runToBeStarred);
          if (runToBeStarred.created_by === user.preferred_username) {
            allDataForViewRuns.map((singleConfigObj) => {
              singleConfigObj.runs.map((singleRunObj) => {
                if (singleRunObj?.id === runId) {
                  if (singleRunObj?.hasOwnProperty("isStarred")) {
                    singleRunObj.isStarred = !singleRunObj?.isStarred;
                  } else {
                    singleRunObj.isStarred = true;
                  }
                }
              });
            });
            updateRunData(allDataForViewRuns);
          } else {
            let allSharedRunsDataObject = allDataForViewRuns
              ?.map((el) => el.runs?.find((obj) => obj.id === runId))
              .find((el) => el);
            if (allSharedRunsDataObject) {
              allSharedRunsDataObject.shared_with.find((singleUsr) => {
                if (singleUsr.preferred_username === user.preferred_username) {
                  singleUsr.isStarred = !singleUsr.isStarred;
                }
              });
            }
            updateRunData(allDataForViewRuns);
          }
          removeStarredValues(runId);
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "success",
          });
          removeStarredValues(runId);
        }
      });
  };

  const addViewRunsData = (runId) => {
    if (allData.runData) {
      //Fetch run data if runId is present
      if (runId) {
        let url = `${config.api.runUrl}?run_id=${runId}&app_id=${config.hardCoded.mroiAppId}`;
        // let url = `${config.api.runIdUrl}?run_id=${runId}`;
        let status;
        fetch(url)
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              updateSelections("activeRunPayload", json.data);
            } else {
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "success",
              });
            }
          });
      }
    }
  };
  const ExpandableTableRow = ({
    children,
    expandComponent,
    id,
    isExpanded,
    ...otherProps
  }) => {
    return (
      <>
        <TableRow {...otherProps}>
          <StyledTableCell padding="checkbox">
            <IconButton
              onClick={() => {
                updateRunExpandInfo(id);
              }}
            >
              {isExpanded ? (
                <span class="material-icons-outlined">keyboard_arrow_up</span>
              ) : (
                <span class="material-icons-outlined">keyboard_arrow_down</span>
              )}
            </IconButton>
          </StyledTableCell>
          {children}
        </TableRow>
        {isExpanded && <>{expandComponent}</>}
      </>
    );
  };

  const innerRow = (runs, config) => {
    return (
      <>
        {runs.map((item, i) => (
          <TableRow key={i} className={classes.innerTableRow}>
            <InnerStyledTableCell padding="checkbox"></InnerStyledTableCell>
            <StyledTableCell
              className={classes.innerName}
              onClick={() => {
                updateSelections("activeRunId", item.id);
                let activeRun = makeRunNameFromRunNew(item);
                addViewRunsData(item.id);
                history.push(`/home/viewruns/${activeRun}`);
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                {item.cancelLoading ? (
                  <div className={classes.starLoading}>
                    <CircularProgress color="secondary" size="1rem" />
                  </div>
                ) : (
                  <div
                    className={`run-icon ${item.status}`}
                    onClick={(e) => {
                      copyValueToClipboard(item.id);
                      updateAlertInfo({
                        open: true,
                        message: `AIDE/RUN ID "${item.id}" is copied to clipboard.`,
                        severity: "success",
                      });
                      e.stopPropagation();
                    }}
                    title={`Click to copy AIDE/RUN ID "${item.id}"`}
                  ></div>
                )}
                {runLoading?.includes(item.id) ? (
                  <div className={classes.starLoading}>
                    <CircularProgress color="secondary" size="1rem" />
                  </div>
                ) : (
                  <>
                    {item.created_by === user.preferred_username ? (
                      <>
                        <i
                          className="material-icons-outlined favourite-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            updateRunAsStarred(item.id, item);
                          }}
                        >
                          {item?.isStarred ? "star" : "star_border"}
                        </i>
                      </>
                    ) : (
                      <>
                        <i
                          className="material-icons-outlined favourite-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            updateRunAsStarred(item.id, item);
                          }}
                        >
                          {item?.shared_with?.find((singleUser) => {
                            return (
                              singleUser.preferred_username ===
                              user.preferred_username
                            );
                          })?.isStarred
                            ? "star"
                            : "star_border"}
                        </i>
                      </>
                    )}
                  </>
                )}
                <div className="config-name" title={item.name}>
                  {item.run_name}
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell
              onClick={() => {
                updateSelections("activeRunId", item.id);
                let activeRun = makeRunNameFromRunNew(item);
                addViewRunsData(item.id);
                history.push(`/home/viewruns/${activeRun}`);
              }}
            ></StyledTableCell>
            {tabCheck && (
              <StyledTableCell
                onClick={() => {
                  updateSelections("activeRunId", item.id);
                  let activeRun = makeRunNameFromRunNew(item);
                  addViewRunsData(item.id);
                  history.push(`/home/viewruns/${activeRun}`);
                }}
              >
                {item.created_by}
              </StyledTableCell>
            )}
            <StyledTableCell
              onClick={() => {
                updateSelections("activeRunId", item.id);
                let activeRun = makeRunNameFromRunNew(item);
                addViewRunsData(item.id);
                history.push(`/home/viewruns/${activeRun}`);
              }}
            >
              {timeDifference(item.created_on) < 7 ? (
                <TimeAgo date={new Date(item.created_on)} />
              ) : (
                formatDate(item.created_on)
              )}
            </StyledTableCell>
            <StyledTableCell
              onClick={() => {
                updateSelections("activeRunId", item.id);
                let activeRun = makeRunNameFromRunNew(item);
                addViewRunsData(item.id);
                history.push(`/home/viewruns/${activeRun}`);
              }}
            >
              {timeDiffCalc(item.end_time, item.start_time)}
            </StyledTableCell>
            <StyledTableCell>
              <PopOverForRun
                user={user}
                match={match}
                run={item}
                config={config}
              />
            </StyledTableCell>
          </TableRow>
        ))}
      </>
    );
  };
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn, user, updateMultiSelections);

  return (
    <>
      <Paper
        sx={{
          height: "70vh",
          width: "100%",
          overflow: "auto",
          border: "none",
          boxShadow: "none",
        }}
      >
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <>
                <ExpandableTableRow
                  className={classes.tableRow}
                  hover
                  key={item.config_id}
                  id={item.config_id}
                  isExpanded={item.isExpanded}
                  expandComponent={innerRow(item.runs, item)}
                >
                  <StyledTableCell
                    className={classes.name}
                    onClick={() => {
                      updateRunExpandInfo(item.config_id);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="config-name" title={item.name}>
                        {item.config_name}
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      updateRunExpandInfo(item.config_id);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {item?.run_type?.toLowerCase() === "automated"
                        ? "Automated"
                        : item?.run_type?.toLowerCase() === "manual"
                        ? "Manual"
                        : "DE Only"}
                    </div>
                  </StyledTableCell>
                  {tabCheck && (
                    <StyledTableCell
                      onClick={() => {
                        updateRunExpandInfo(item.config_id);
                      }}
                    ></StyledTableCell>
                  )}
                  <StyledTableCell
                    onClick={() => {
                      updateRunExpandInfo(item.config_id);
                    }}
                  >
                    {timeDifference(item.created_on) < 7 ? (
                      <TimeAgo date={new Date(item.created_on)} />
                    ) : (
                      formatDate(item.created_on)
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      updateRunExpandInfo(item.config_id);
                    }}
                  >
                    {timeDiffCalc(item.end_time, item.start_time)}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      updateRunExpandInfo(item.config_id);
                    }}
                  ></StyledTableCell>
                </ExpandableTableRow>
              </>
            ))}
          </TableBody>
        </TblContainer>
      </Paper>
      <div style={{ height: "40px" }}>
        {records.length > 10 && <TblPagination />}
      </div>
    </>
  );
}

TableViewRunV2.propTypes = {
  data: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  paginationFlag: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateMultiSelections,
  updateAlertInfo,
  updateDialogInfo,
  updateSelections,
  reloadRunData,
  updateRunData,
  updateSharedRunsData,
  updateRunExpandInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableViewRunV2);
