import { makeStyles, MenuItem, Select } from "@material-ui/core";
import { connect } from "react-redux";
import { config } from "../../../../config/config";
import {
  addMergeKpi,
  removeMergeKpi,
  updateKpiVariables,
} from "../../../../redux/actions";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "100%",
    height: "30px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "4px 12px",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
});
function MergeDatasetKpiSelection(props) {
  const {
    activeDataset,
    intersectedKpi,
    intersectedMandatoryKpi,
    disableFlag,
    activeModelId,
    activeModel,
    activeDatasetId,
    addMergeKpi,
    removeMergeKpi,
    data,
    configUserInputs,
    activeDatasetForKpi,
    modifiedMergedKpiVariableList,
    mergeDatasetInfo,
    localParamsList,
    updateKpiVariables,
  } = props;
  const classes = useStyles();
  let selectedDataset = activeModel?.data_sources?.find(
    (dataset) => dataset.id === activeDatasetId
  );
  return (
    <>
      <label className="bold">Select Other Variables:</label>
      <div className="input-variables-container">
        {intersectedKpi?.map((value) => {
          let mandatoryKpi = intersectedMandatoryKpi?.includes(value);
          let checkedFlag = selectedDataset
            ? selectedDataset.kpi_list.includes(value)
            : false;
          return (
            <div key={value}>
              <div
                key={value}
                className={`kpi-checkbox-container ${
                  disableFlag ? "disabled-pointer" : ""
                } 
                    ${mandatoryKpi ? "mandatory-kpi" : ""}
                                      `}
                onClick={() => {
                  let dataset_list = [];
                  for (let item of activeDataset.dataset_list) {
                    let dataset_list_found = mergeDatasetInfo.find(
                      (elem) =>
                        elem.id === item &&
                        elem.channel === activeDataset.channel
                    );
                    if (dataset_list_found)
                      dataset_list.push(dataset_list_found);
                  }
                  dataset_list = dataset_list.map((elem) => ({
                    id: elem.id,
                    name: elem.name,
                    pipeline_dataset_id: elem.pipeline_dataset_id,
                    source: elem.source,
                    type: elem.type,
                  }));
                  let data = {
                    modelId: activeModelId,
                    selectedDataset,
                    dataset_list: dataset_list,
                    dataset: selectedDataset
                      ? selectedDataset
                      : {
                          ...activeDataset,
                          local_parameters: localParamsList,
                        },
                    kpi: value,
                    activeModel: activeModel,
                    kpi_variables_list: selectedDataset
                      ? selectedDataset.kpi_variables_list
                      : modifiedMergedKpiVariableList,
                    typeOfDataset: "MEDIA",
                    // needs to be changed once we have sales/category/macro datasets
                  };
                  if (intersectedMandatoryKpi.length > 0) {
                    data = {
                      ...data,
                      intersectedMandatoryKpi: intersectedMandatoryKpi,
                    };
                  }

                  checkedFlag ? removeMergeKpi(data) : addMergeKpi(data);
                }}
              >
                <i
                  className={`kpi-checkbox-icon
                      ${
                        mandatoryKpi
                          ? "material-icons active"
                          : checkedFlag
                          ? "material-icons active"
                          : "material-icons-outlined"
                      }`}
                >
                  {mandatoryKpi && checkedFlag
                    ? "check_box"
                    : checkedFlag
                    ? "check_box"
                    : "check_box_outline_blank"}
                </i>
                <label>
                  {value}
                  {mandatoryKpi && (
                    <span
                      style={{
                        marginLeft: "4px",
                        backgroundColor: "#4eafb3",
                        color: "white",
                        fontSize: "8px",
                        padding: "1px 4px",
                        borderRadius: "10px",
                        fontWeight: "700",
                      }}
                    >
                      Mandatory
                    </span>
                  )}
                </label>
              </div>
              {selectedDataset?.kpi_list?.length ? (
                modifiedMergedKpiVariableList
                  .filter((data) => data.id === value)
                  .map((elem) => {
                    return (
                      <div key={elem.id} className="kpi-parameters-container">
                        {selectedDataset?.kpi_list.includes(elem.id) &&
                        activeModelId !== config.hardCoded.mmtId ? (
                          <div className="kpi-parameters">
                            {elem.kpi_parameters.map((data) => (
                              <div
                                key={data.id}
                                className={`${
                                  disableFlag ? "disabled-pointer" : ""
                                }`}
                              >
                                <label>{data.id}:</label>
                                <Select
                                  className={classes.multiSelect}
                                  MenuProps={MenuProps}
                                  value={
                                    typeof selectedDataset?.kpi_variables_list?.find(
                                      (item) => item.id === elem.id
                                    )?.[data.id] !== "undefined" &&
                                    selectedDataset?.kpi_variables_list?.find(
                                      (item) => item.id === elem.id
                                    )?.[data.id] !== ""
                                      ? selectedDataset?.kpi_variables_list?.find(
                                          (item) => item.id === elem.id
                                        )?.[data.id]
                                      : data.default === "" ||
                                        selectedDataset?.kpi_variables_list?.find(
                                          (item) => item.id === elem.id
                                        )?.[data.id] === ""
                                      ? "(blank)"
                                      : `${data.default}`
                                  }
                                  onChange={(e) => {
                                    let kpiData = {
                                      dataset: selectedDataset
                                        ? selectedDataset
                                        : activeDataset,
                                      modelId: activeModelId,
                                      key: data.id,
                                      value:
                                        e.target.value === "(blank)"
                                          ? ""
                                          : e.target.value,
                                      kpi: elem.id,
                                      kpi_variables_list:
                                        selectedDataset?.kpi_variables_list
                                          ? selectedDataset?.kpi_variables_list
                                          : activeDatasetForKpi.kpi_variables_list,
                                    };
                                    updateKpiVariables(kpiData);
                                  }}
                                >
                                  {data.possible_values.map((obj, index) => (
                                    <MenuItem
                                      key={index}
                                      value={obj === "" ? "(blank)" : obj}
                                      className={classes.singleMenuItem}
                                    >
                                      {obj === "" ? "(blank)" : `${obj}`}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <> </>
                        )}
                      </div>
                    );
                  })
              ) : (
                <div></div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
  data: state.data,
});
const mapDispatchToProps = {
  addMergeKpi,
  removeMergeKpi,
  updateKpiVariables,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MergeDatasetKpiSelection);
