import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import Chip from "@material-ui/core/Chip";
import { Chip } from "../../../../uiTheme";

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    backgroundColor: "#f3f3f3",
    //padding: "10px",
    borderRadius: "4px",
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      // border: "1px solid #dedede",
      // background: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFormControl-fullWidth": {
      minWidth: "250px",
      background: "white",
      border: "1px solid rgba(0, 0, 0, 0.05)",
      boxSizing: "border-box",
      borderRadius: "5px",
      padding: "5px 10px",
      height: "30px",
      // maxWidth: "900px",
      // width: "100%",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri !important",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-option": {
      backgrounColor: "#46596a3b",
      fontFamily: "Hind Siliguri !important",
    },
  },
  root: {
    marginTop: "5px",
    fontFamily: "Hind Siliguri !important",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    // "& > * + *": {
    //   marginTop: theme.spacing(3),
    // },
  },
  chipStyling: {
    marginTop: "0px",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    border: "1px solid #dedede",
    color: "white !important",
    height: "28px",
    borderRadius: "4px",
    width: "fit-content",
    margin: "0px 5px",
    background: "#4EAFB3",
    "&:focus": {
      background: "#4EAFB3",
    },
    "& .MuiChip-deleteIcon": {
      // width: "20px",
      fontSize: 20,
      border: "none",
      color: "white",
    },
  },
}));

const StyledAutoComplete = withStyles((theme) => ({
  endAdornment: {
    display: "none",
  },
  tag: {
    // background: "white",
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
}))(Autocomplete);

export default function MultiSelect(props) {
  let { name, data, value, onChange } = props;
  const classes = useStyles();

  const handleChange = (e, newValue) => {
    //updateUserInputs(accessor, value);
    // setAccessor(value);
    onChange("regions", newValue);
  };

  const delValue = (regValue) => {
    let valArr = value.filter((singleValue) => singleValue !== regValue);
    onChange("regions", valArr);
  };

  return (
    <div className={classes.root}>
      <StyledAutoComplete
        multiple
        id={`tags-standard-${name}`}
        size="small"
        style={{ fontFamily: "Hind Siliguri !important" }}
        limitTags={5}
        renderTags={() => null}
        disableClearable
        className={classes.typographyStyle}
        options={data
          ?.sort()
          ?.filter((value, index) => data.indexOf(value) === index)}
        getOptionLabel={(option) => option || ""}
        value={value}
        onChange={(e, value) => {
          handleChange(e, value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            //InputProps={{ style: { fontFamily:'Hind Siliguri' } }}
            variant="standard"
            size="small"
            placeholder="Type a region"
            className={classes.typographyStyle}
          />
        )}
      />
      <div className="chips-model-region">
        {value.map((reg) => (
          <Chip
            key={reg}
            label={reg}
            onDelete={() => delValue(reg)}
            // className={classes.chipStyling}
            deleteIcon={<i className="material-icons-outlined">clear</i>}
            style={{ margin: "0px 5px", height: "28px" }}
          />
        ))}
      </div>
    </div>
  );
}
