//Import required libraies
import React from "react";

//Import Custom Component
import LocalParameterItem from "./LocalParameterItem";

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data

function AdStockPreview(props) {
  let { id, name, value, onChange } = props;

  return (
    <div>
      <LocalParameterItem
        value={value}
        name={name}
        ui_element_type="checkbox"
        onChange={onChange}
        id={id}
      />
    </div>
  );
}

export default AdStockPreview;
