//Import required libraies
import React, { useState } from "react";
import { connect } from "react-redux";
//Import custom libraries
import Button from "@material-ui/core/Button";

//Import custom components
import { config } from "../../config/config";

//Import utils, data

//Import actions
import { updateConfigUserInputs, updateAlertInfo } from "../../redux/actions";
import { validConfigName } from "../../utils/utils";
//Import styles
// import { muiCommonStyles, MenuProps } from "../../../styles/styles";
import "./AddRunName.scss";
import { useEffect } from "react";

function AddRunName(props) {
  const {
    handleSaveRun,
    configUserInputs,
    allData,
    updateConfigUserInputs,
    handleDialogClose,
    total_runs,
    updateAlertInfo,
  } = props;

  useEffect(() => {
    updateConfigUserInputs(
      "configFixedRunName",
      `v${total_runs ? total_runs + 1 : 1}-`
    );
    return () => {};
  }, []);
  return (
    <>
      <div className="runName-config-dialog-container">
        <div className="runName-input-field-container">
          <label>Run Name:</label>
          <div className="input-text-box">
            <div className="name-config">
              <div
                className="config-name"
                title={configUserInputs?.configFixedRunName}
              >
                {configUserInputs?.configFixedRunName}
              </div>
              <input
                type="text"
                value={configUserInputs?.run_name || ""}
                onChange={(e) => {
                  updateConfigUserInputs("run_name", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="run-name-warning">
            {!validConfigName(configUserInputs.run_name) &&
              config.messages.invalidRunName}
          </div>
        </div>
        <div className="popup-btn">
          <Button
            disableRipple
            variant="outlined"
            onClick={() => {
              updateConfigUserInputs("run_name", "");
              handleDialogClose();
            }}
            style={{
              height: "23px",
              padding: "10px",
              fontSize: "11px",
              minWidth: "15px",
              boxSizing: "border-box",
              marginRight: "19px",
              color: "rgb(70, 89, 106)",
              border: "1px solid #46596a",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            disableRipple
            disabled={
              !validConfigName(configUserInputs.run_name) ||
              !configUserInputs.run_name
            }
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              // let status = validConfigName(configUserInputs.run_name);
              // if (!status) {
              //   updateAlertInfo({
              //     open: true,
              //     message: config.messages.invalidRunName,
              //     severity: "info",
              //   });
              // } else {
              handleSaveRun(configUserInputs, allData);
              handleDialogClose();
              updateConfigUserInputs("run_name", "");
              // }
            }}
            style={{
              height: "23px",
              padding: "10px",
              fontSize: "11px",
              minWidth: "15px",
              boxSizing: "border-box",
              marginRight: "4px",
              background: "rgb(70, 89, 106)",
              color: "white",
              textTransform: "none",
              opacity: `${
                !configUserInputs.run_name ||
                !validConfigName(configUserInputs.run_name)
                  ? ".5"
                  : 1
              }`,
            }}
          >
            Run
          </Button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  configUserInputs: state.configUserInputs,
  allData: state.data,
});

const mapDispatchToProps = {
  updateConfigUserInputs,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRunName);
