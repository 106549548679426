//Import required libraies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useRouteMatch, Route, useHistory } from "react-router-dom";

//Import Custom Component
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

//Import tabs

//Import images
import noAccessImage from "../../../assets/images/undraw_secure_login.svg";

// Import action creators
import {
  updateData,
  reloadRunData,
  updateUserInfo,
  updateRunData,
  updateSelections,
  updateWholeConfigUserInputs,
  refreshData,
  resetFilters,
  updateAlertInfo,
} from "../../redux/actions";

//Import Custom Hooks

//Import utils/data
import { config } from "../../config/config";
import {
  makeDefaultResponseJson,
  getStartEndByDayCat,
  checkForAllData,
} from "../../utils/utils";
import { configUserInputs as initialConfigUserInputs } from "../../redux/stateData";
import Loader from "../../components/Loader/Loader";

function AIDEAppContainer(props) {
  let {
    user,
    updateData,
    allData,
    updateUserInfo,
    updateAlertInfo,
    updateRunData,
    changeFlag,
    updateWholeConfigUserInputs,
    refreshData,
    children,
    resetFilters,
  } = props;
  const match = useRouteMatch();
  const [pageReady, setPageReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reloadAllData, setReloadAllData] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  let app = config.hardCoded.mroiAppId.toLowerCase();
  let activeApp = allData.apps.data
    ? allData.apps.data.find(
        (obj) => obj.name.toLowerCase() === app.toLowerCase()
      )
    : null;
  //Update runData every 10 minutes
  useEffect(() => {
    const runDataIntervalId = setInterval(() => {
      refreshData();
    }, config.runDataInterval);
    return () => clearInterval(runDataIntervalId);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (
      allData.saved_configurations &&
      allData.apps.data &&
      allData.globalParams &&
      allData.models &&
      allData.datasets &&
      allData.runData &&
      allData.appAccessDetails &&
      allData.shared_configurations
    ) {
      let validFlag = checkForAllData(
        activeApp,
        allData.saved_configurations,
        allData.globalParams,
        allData.models,
        allData.datasets,
        allData.runData,
        allData.appAccessDetails,
        allData.shared_configurations
      );
      if (validFlag) {
        // setReloadAllData(false); //flag is false, fetch all data
      } else {
        setReloadAllData(true); //flag is true, no need to fetch data
      }
    } else {
      setReloadAllData(true);
    }
  }, []);

  // Get apps data
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (allData?.apps?.data?.length <= 0) {
      let url = `${config.api.appsUrl}?vertical=${config.hardCoded.defaultVertical}&app_id=${config.hardCoded.mroiAppId}`;
      let status;
      fetch(url, { signal })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            let allAppsName = json.data?.map((appName) =>
              appName.name?.toLowerCase()
            );
            if (!allAppsName.includes(app)) {
              updateAlertInfo({
                open: true,
                message: config.messages.noAppsAccess,
                severity: "info",
              });
              history.push(`/home/`);
            }
            updateData("apps", {
              status: "success",
              data: json.data,
              message: "",
            });
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
    return () => {
      controller.abort();
    };
  }, []);

  // Get User Access data
  useEffect(() => {
    if (activeApp && !pageReady && reloadAllData) {
      updateData("appAccessDetails", null);
      let url = config.api.appAccessDetailsUrl;
      let status;
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ app_id: activeApp?.id }),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("appAccessDetails", json.data);
            if (
              match.params.tab === "i&adashboards" &&
              !(
                json.data.feature_list.view_approve_dashboard ||
                json.data.feature_list.edit_approve_dashboard
              )
            ) {
              const location = "/home/";
              history.push(location);
            }
          } else {
            setError(json.statusMessage);
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
    return () => {};
  }, [allData.apps.data, reloadAllData]);

  // Get saved_configurations data
  useEffect(() => {
    if (activeApp && !pageReady && reloadAllData && allData.appAccessDetails) {
      updateData("saved_configurations", null);
      let url = `${config.api.configsUrl}?app_id=${activeApp?.id}`;
      let status;
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            if (allData.appAccessDetails?.feature_list?.schedule_run) {
              let responseData = json.data;
              let url = `${config.api.scheduleConfigurl}?app_id=${activeApp?.id}`;
              let status;
              fetch(url)
                .then((response) => {
                  console.groupCollapsed("requesting", url);
                  console.log("REPSONSE -> ", response);
                  status = response.status;
                  return response.clone().json();
                })
                .then((json) => {
                  console.log("JSON -> ", json);
                  console.groupEnd();
                  if (status === 200) {
                    let updatedSavedConfigurations = responseData.map(
                      (elem) => ({
                        ...elem,
                        ...(json?.data?.find(
                          (obj) => obj.config_id === elem.id
                        ) && {
                          schedule_details: json.data.find(
                            (obj) => obj.config_id === elem.id
                          ),
                        }),
                      })
                    );
                    updateData(
                      "saved_configurations",
                      updatedSavedConfigurations
                    );
                  } else {
                    updateAlertInfo({
                      open: true,
                      message: json.statusMessage,
                      severity: "error",
                    });
                  }
                });
            } else {
              updateData("saved_configurations", json.data);
            }
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
    return () => {};
  }, [allData.apps.data, reloadAllData, allData.appAccessDetails]);

  // Get shared configs data
  useEffect(() => {
    if (activeApp && !pageReady && reloadAllData) {
      updateData("shared_configurations", null);
      let url = `${config.api.sharedConfigsUrl}?app_id=${activeApp?.id}`;
      let status;
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("shared_configurations", json.data);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
    return () => {};
  }, [allData.apps.data, reloadAllData]);

  //reload starred_configurations data
  useEffect(() => {
    if (activeApp && !pageReady && reloadAllData) {
      let url = `${config.api.bookmarkUrl}?app_id=${activeApp.id}`;
      updateData("starred_configurations", {
        status: "loading",
        message: "",
        data: [],
      });
      // setConfigsLoading(true);
      let status;
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            if (allData.appAccessDetails?.feature_list?.schedule_run) {
              let responseData = json.data;
              let url = `${config.api.scheduleConfigurl}?app_id=${activeApp.id}`;
              let status;
              fetch(url)
                .then((response) => {
                  console.groupCollapsed("requesting", url);
                  console.log("REPSONSE -> ", response);
                  status = response.status;
                  return response.clone().json();
                })
                .then((json) => {
                  console.log("JSON -> ", json);
                  console.groupEnd();
                  if (status === 200) {
                    let updatedSavedConfigurations = responseData?.map(
                      (elem) => ({
                        ...elem,
                        ...(json?.data?.find(
                          (obj) => obj.config_id === elem.id
                        ) && {
                          schedule_details: json.data.find(
                            (obj) => obj.config_id === elem.id
                          ),
                        }),
                      })
                    );
                    updateData("starred_configurations", {
                      status: "success",
                      message: "",
                      data: updatedSavedConfigurations,
                    });
                    // setConfigsLoading(false);
                  } else {
                    updateAlertInfo({
                      open: true,
                      message: json.statusMessage,
                      severity: "error",
                    });
                    // setConfigsLoading(false);
                  }
                });
            } else {
              updateData("starred_configurations", {
                status: "success",
                message: "",
                data: json.data,
              });
              // setConfigsLoading(false);
            }
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
            // setConfigsLoading(false);
          }
        });
    }
    return () => {};
  }, [allData.apps.data, reloadAllData]);
  // Get Run data
  useEffect(() => {
    if (activeApp && !pageReady && reloadAllData) {
      updateRunData(null);
      let userRunFilters = user.selections.runFilters;
      let body = {
        app_id: activeApp?.id,
        offset: 0,
        limit: config.runsLimit,
        order_by: userRunFilters.orderBy,
        search_text: userRunFilters.searchText || null,
        country: null,
        brand: null,
        segment: null,
        models: [],
        category: config.hardCoded.viewRunsCategory,
        ...getStartEndByDayCat(userRunFilters.dayCat),
        is_shared: true,
      };
      // let url = config.api.runUrl;
      let url = config.api.v2RunsUrl;
      let status;
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateRunData(json.data);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  }, [allData.apps.data, reloadAllData]);

  // Get global parameters data
  useEffect(() => {
    if (activeApp && !pageReady && reloadAllData) {
      updateData("globalParams", null);
      let url = `${config.api.globalParamsUrl}?app_id=${activeApp?.id}`;
      let status;
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("globalParams", json.data);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  }, [allData.apps.data, reloadAllData]);

  // Get global models data
  useEffect(() => {
    if (activeApp && !pageReady && reloadAllData) {
      updateData("models", null);
      let url = `${config.api.modelsUrl}?app_id=${activeApp?.id}`;
      let status;
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("models", json.data);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  }, [allData.apps.data, reloadAllData]);

  // Get global datasets data
  useEffect(() => {
    if (activeApp && !pageReady && reloadAllData) {
      updateData("datasets", null);
      let url = `${config.api.datasetsUrl}?app_id=${activeApp?.id}`;
      let status;
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("datasets", json.data);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  }, [allData.apps.data, reloadAllData]);

  // Get global datasets data with dataset Details according to country and brand
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (activeApp && !pageReady && reloadAllData) {
      updateData("datasetDetails", null);
      let datasetDetailsBody = {
        brand: initialConfigUserInputs.brand,
        country: initialConfigUserInputs.country,
        app_id: activeApp?.id,
        segment: initialConfigUserInputs.segment,
      };
      datasetDetailsBody = {
        ...datasetDetailsBody,
      };
      let url = `${config.api.datasetDetailsUrl}`;
      let status;
      fetch(url, {
        signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetDetailsBody),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("datasetDetails", json.data);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        })
        .catch((e) => {
          console.warn(`Fetch 2 error: ${e.message}`);
        });
    }
    return () => {
      controller.abort();
    };
  }, [allData.apps.data, reloadAllData]);

  //Update runData if changeFlag changes
  useEffect(() => {
    if (activeApp) {
      // updateRunData(null);
      // let url = config.api.runUrl;
      let url = config.api.v2RunsUrl;
      let userRunFilters = user.selections.runFilters;
      let body = {
        app_id: activeApp?.id,
        offset: 0,
        limit: config.runsLimit,
        order_by: userRunFilters.orderBy,
        search_text: userRunFilters.searchText || null,
        country: null,
        brand: null,
        segment: null,
        models: [],
        category: config.hardCoded.viewRunsCategory,
        ...getStartEndByDayCat(userRunFilters.dayCat),
        is_shared: true,
      };
      let status;
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateRunData(json.data);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  }, [changeFlag.refreshData]);

  useEffect(() => {
    // console.log("allData.saved_configurations", allData.saved_configurations);
    // console.log("allData.globalParams", allData.globalParams);
    // console.log("allData.models", allData.models);
    // console.log("allData.datasets", allData.datasets);
    // console.log("allData.runData", allData.runData);
    // console.log("pageReady", pageReady);
    if (!pageReady && !loading) {
      if (
        allData.saved_configurations &&
        allData.apps.data &&
        allData.globalParams &&
        allData.models &&
        allData.datasets &&
        allData.runData &&
        allData.appAccessDetails &&
        allData.datasetDetails &&
        allData.shared_configurations
      ) {
        //Reset ConfigUserInputs
        updateWholeConfigUserInputs(initialConfigUserInputs);
        let flag = checkForAllData(
          activeApp,
          allData.saved_configurations,
          allData.globalParams,
          allData.models,
          allData.datasets,
          allData.runData,
          allData.appAccessDetails,
          allData.shared_configurations
        );
        if (flag) {
          setPageReady(true);
        }
      }
    }
  }, [
    allData.apps.data,
    allData.saved_configurations,
    allData.globalParams,
    allData.models,
    allData.datasets,
    allData.runData,
    allData.appAccessDetails,
    allData.datasetDetails,
    allData.shared_configurations,
  ]);

  useEffect(() => {
    resetFilters();
  }, [match.params.tab]);

  return (
    <Route>
      <LayoutTopSideBottom match={match}>
        <Breadcrumbs match={match} />
        {error ? (
          <div className="work-in-progress-container">
            <div className="wip-image">
              <img src={noAccessImage} alt="no access available" />
            </div>
            <div>
              <p className="work-in-progress-text">{error}</p>
            </div>
          </div>
        ) : pageReady && !loading ? (
          children
        ) : (
          <Loader />
        )}
      </LayoutTopSideBottom>
      )
    </Route>
  );
}

AIDEAppContainer.propTypes = {
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  changeFlag: state.changeFlag,
});

const mapDispatchToProps = {
  updateData,
  reloadRunData,
  updateUserInfo,
  updateRunData,
  updateSelections,
  updateWholeConfigUserInputs,
  refreshData,
  resetFilters,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AIDEAppContainer);
