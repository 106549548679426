//Import required libraies
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  makeStyles,
  withStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Radio from "@material-ui/core/Radio";

//Import Custom Component
import Loader from "../../../../components/Loader/Loader";
import ModelRegion from "./ModelRegion";
import IncludeExcludeRegions from "./IncludeExcludeRegions";

// Import custom hooks

// Import action creators
import {
  updateUserInfo,
  updateAlertInfo,
  updateConfigUserInputs,
  updateSalesMediaRegions,
} from "../../../../redux/actions";

// Import styles

// Import utils
import { config } from "../../../../config/config";
import {
  makeDefaultResponseJson,
  getRegionsData,
  salesMediaFilterPayload,
  datasetIds,
} from "../../../../utils/utils";

//Import data
import regionData from "../../../../../assets/data/region.json";
import regionOldData from "../../../../../assets/data/region_old.json";
import regionsNameMapping from "../../../../../assets/data/regionsNameMapping.json";
import { useFirstRender } from "../../../../hooks/useFirstRender";
import { configUserInputs } from "../../../../redux/stateData";

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "190px",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a",
    backgroundColor: "white",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    color: "#46596a",
    width: "auto",
    "&:hover": {
      backgroundColor: "#4EAFB3",
    },
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const BlueRadio = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: "#4eafb3",
    "&$checked": {
      color: "#4eafb3",
    },
    "&$disabled": {
      color: "#46596a",
      opacity: 0.5,
    },
    "& .MuiSvgIcon-root": {
      height: 15,
      weight: 15,
    },
  },
  checked: {},
  disabled: {
    color: "#46596a",
    opacity: 0.5,
  },
})((props) => <Radio color="default" {...props} />);

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
  },
});

function Region(props) {
  let {
    id,
    name,
    enable,
    data,
    value,
    minValue,
    maxValue,
    onChange,
    activeModel,
    customClassName,
    userInputs,
    disableFlag,
    match,
    allData,
    updateAlertInfo,
    updateConfigUserInputs,
    updateSalesMediaRegions,
  } = props;

  let regionsArray = !userInputs.is_config_manual
    ? userInputs?.globalRegions?.map(function (val) {
        return val.id.toLowerCase();
      })
    : regionData.map(function (val) {
        return val.id.toLowerCase();
      });

  let regionsData;
  const [loading, setLoading] = useState(true);
  const [modelRegionData, setModelRegionData] = useState(false);
  const [modelRegionSelectionData, setModelRegionSelectionData] =
    useState(false);
  const classes = useStyles();
  const isFirstRender = useFirstRender();
  const fetchSalesMediaRegions = (url, body, controller, key, modelId) => {
    if (key === "validMediaRegions") {
      updateConfigUserInputs("mediaRegions", "loading");
    } else {
      updateConfigUserInputs("salesRegions", "loading");
    }
    let status;
    fetch(url, {
      method: "POST",
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("RESPONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.groupEnd();
        if (status === 200) {
          if (key === "validMediaRegions") {
            updateConfigUserInputs("mediaRegions", "success");
            updateSalesMediaRegions({ regionKey: key, modelId, json });
            // updateConfigUserInputs(key, obj);
          } else {
            updateConfigUserInputs("salesRegions", "success");
            // updateConfigUserInputs(key, json);
            updateSalesMediaRegions({ regionKey: key, modelId, json });
          }
        } else {
          if (key === "validMediaRegions") {
            updateConfigUserInputs("mediaRegions", "failed");
          } else {
            updateConfigUserInputs("salesRegions", "failed");
          }
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };
  let regionValue = userInputs.models.find(
    (model) => model.id === activeModel.id
  ).config;
  let modelDataSources = userInputs.models.find(
    (model) => model.id === activeModel.id
  ).data_sources;
  //filter datasets based on the country
  let filteredDatasets = allData.datasets.filter(
    (dataset) =>
      dataset.countries.includes(userInputs.country) &&
      (dataset.type === config.hardCoded.datasetSalesType ||
        dataset.type === config.hardCoded.datasetNeilsenType ||
        dataset.type === config.hardCoded.datasetMediaType)
  );
  let datasets = filteredDatasets.filter((dataset) =>
    activeModel.dataset_ids.some(
      (datasetOfActiveModel) => datasetOfActiveModel.id === dataset.id
    )
  );
  //check the regional region granularity available
  let checkRegionGranularity = !userInputs.is_config_manual
    ? regionsArray?.find((e) => e === "regional")
    : false;

  //update countryRegions data when user updates country
  useEffect(() => {
    const controller = new AbortController();
    if (userInputs.configType === "run" && !userInputs?.is_config_manual) {
      setLoading(false);
    } else {
      if (
        !isFirstRender.current &&
        userInputs?.datasetDetailsData?.status === "success"
      ) {
        setModelRegionData(-1);
        //Make fetch call for countryRegions data
        let body = {};
        body.countries = [userInputs.country];
        body.app_id = activeModel.app_id;
        if (userInputs?.configType !== "run") {
          let salesDatasetSelected = modelDataSources.find(
            (item) =>
              item.type === config.hardCoded.datasetSalesType ||
              item.type === config.hardCoded.datasetNeilsenType
          );
          if (salesDatasetSelected) {
            let datasetArray = [];
            datasetArray.push(salesDatasetSelected.id);
            body.dataset_id = datasetArray;
            body.category = [];
            body.brand = [userInputs.brand];
            if (
              salesDatasetSelected.category &&
              salesDatasetSelected.category.length &&
              salesDatasetSelected.category instanceof Array
            ) {
              body.category = salesDatasetSelected.category;
            } else if (typeof salesDatasetSelected.category === "string") {
              body.category.push(salesDatasetSelected.category);
            }
          } else {
            let datasetIdsArray = datasetIds(
              userInputs,
              datasets,
              "sales",
              activeModel.id
            );
            // datasets
            //   .filter((dataset) =>
            //     userInputs?.datasetDetailsData?.data.find(
            //       (el) => el.dataset_id === dataset.id
            //     )
            //   )
            //   .map((dataset) => dataset.id);
            body.dataset_id = [...datasetIdsArray];
            body.category = [];
            body.brand = [userInputs.brand];
          }
        }
        let url = config.api.globalRegionsUrl;
        let status;
        updateConfigUserInputs("globalRegionsStatus", "loading");
        fetch(url, {
          method: "POST",
          signal: controller.signal,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              if (userInputs.is_config_manual) {
                updateConfigUserInputs("globalRegions", json.data[0].dma);
                setModelRegionData(json.data);
              } else {
                let modifiedData = Object.entries(json.data)
                  .filter(([key, value]) => key !== "country")
                  .map(([key, value]) => {
                    return { id: key, ...value };
                  })
                  .sort((a, b) => {
                    let region_typeA = a.region_type.toLowerCase(),
                      region_typeB = b.region_type.toLowerCase();

                    if (region_typeA < region_typeB) {
                      return -1;
                    }
                    if (region_typeA > region_typeA) {
                      return 1;
                    }
                    return 0;
                  });
                // updateConfigUserInputs("globalRegions", json.data[0].dma);
                updateConfigUserInputs("globalRegions", modifiedData);
                updateConfigUserInputs("mmtGlobalRegions", modifiedData);
                setModelRegionData(modifiedData);
              }
              const {
                sub_segment: sales_sub_segment,
                sub_brand: sales_sub_brand,
                segment: sales_segment,
                media_segment,
                media_sub_brand,
                media_sub_segment,
              } = salesMediaFilterPayload(userInputs, allData, true);
              // const url = config.api.salesRegionsUrl;
              // "https://run.mocky.io/v3/8fed61f2-9946-47bb-8e41-5efc1ff06862";
              if (
                [
                  ...config.hardCoded.autoMlIds,
                  config.hardCoded.salesUpliftId,
                ].includes(activeModel.id)
              ) {
                fetchSalesMediaRegions(
                  config.api.salesRegionsUrl,
                  {
                    ...body,
                    sales_sub_brand: sales_sub_brand,
                    sales_segment: sales_segment,
                    sales_sub_segment: sales_sub_segment,
                  },
                  controller,
                  "validSalesRegions",
                  activeModel.id
                );
                fetchSalesMediaRegions(
                  config.api.mediaRegionsUrl,
                  {
                    ...body,
                    media_segment: media_segment,
                    media_sub_brand: media_sub_brand,
                    media_sub_segment: media_sub_segment,
                    dataset_id: datasetIds(
                      userInputs,
                      datasets,
                      "media",
                      activeModel.id
                    ),
                  },
                  controller,
                  "validMediaRegions",
                  activeModel.id
                );
              }
              setLoading(false);
              updateConfigUserInputs("globalRegionsStatus", "success");
            } else {
              updateConfigUserInputs("globalRegionsStatus", "failed");
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "error",
              });
            }
          });
      }
    }
    return () => {
      controller.abort();
      updateConfigUserInputs("globalRegionsStatus", "success");
    };
  }, [loading, userInputs?.datasetDetailsData?.status]);

  //Update regions data if countryRegions changes
  useEffect(() => {
    if (!loading) {
      if (
        !regionValue.regionType ||
        (userInputs?.configType !== "run" &&
          !regionsArray.includes(regionValue.regionType))
      ) {
        if (userInputs.is_config_manual) {
          onChange("regionType", "dma");
        } else {
          if (userInputs?.globalRegions?.length) {
            if (!checkRegionGranularity) {
              onChange("regionType", regionsArray[0]);
            } else {
              onChange("regionType", "regional");
            }
          } else {
            onChange("regionType", "");
          }
        }
      }
      if (
        modelRegionData !== -1 &&
        regionValue.regionType &&
        regionValue.regionType.toLowerCase() !== "national"
      ) {
        regionsData = getRegionsData(
          modelRegionData,
          userInputs.country,
          userInputs?.configType === "run" ||
            regionsArray.includes(regionValue.regionType)
            ? regionValue.regionType
            : checkRegionGranularity
            ? "regional"
            : !userInputs.is_config_manual
            ? regionsArray[0]
            : "dma",
          userInputs?.is_config_manual
        );
        if (
          regionValue.regionSelectedType === "selected" &&
          userInputs?.configType !== "run"
        ) {
          let regionsValues = regionValue.regions.filter((value) =>
            regionsData?.includes(value)
          );
          onChange("regions", regionsValues);
        }
      }
      setModelRegionSelectionData(regionsData);
    }
  }, [modelRegionData, regionValue.regionType]);

  useEffect(() => {
    if (userInputs.configType === "run") {
      setModelRegionSelectionData(regionValue?.regions);
    }
  }, []);

  return !userInputs.is_config_manual && loading ? (
    <Loader />
  ) : (
    <div className="content region-local region-selection">
      <div
        className={`input-item top-margin test-control-global ${
          disableFlag ? "disabled-pointer" : ""
        }`}
      >
        <label>Select Region Granularity:</label>
        <div className="test-control-global-select">
          <Select
            value={
              userInputs.is_config_manual
                ? userInputs?.configType === "run"
                  ? regionsNameMapping[regionValue?.regionType]?.name
                  : regionsArray.includes(regionValue?.regionType)
                  ? regionsNameMapping[regionValue?.regionType]?.name
                  : "DMAs"
                : regionValue?.regionType?.toLowerCase()
            }
            onChange={(e) => {
              onChange("regionType", e.target.value);
            }}
            className={classes.multiSelect}
            MenuProps={MenuProps}
          >
            {userInputs.is_config_manual ? (
              userInputs?.configType !== "run" ? (
                regionData.map((obj) => (
                  <MenuItem
                    value={obj.id.toLowerCase()}
                    key={obj.id.toLowerCase()}
                    className={classes.singleMenuItem}
                  >
                    {obj.name}
                  </MenuItem>
                ))
              ) : (
                regionOldData.map(
                  (
                    obj // change it to globalData
                  ) => (
                    <MenuItem
                      value={obj.id.toLowerCase()}
                      key={obj.id.toLowerCase()}
                      className={classes.singleMenuItem}
                    >
                      {obj.name}
                    </MenuItem>
                  )
                )
              )
            ) : userInputs?.configType === "run" ? (
              [
                {
                  id: regionValue?.regionType,
                  name: `${
                    regionValue?.regionType[0]?.toUpperCase() +
                    regionValue?.regionType?.substring(1)
                  }`,
                },
              ].map((obj) => (
                <MenuItem
                  value={obj.id.toLowerCase()}
                  key={obj.id.toLowerCase()}
                  className={classes.singleMenuItem}
                >
                  {obj.name}
                </MenuItem>
              ))
            ) : userInputs.globalRegions?.length ? (
              userInputs.globalRegions?.map((obj) => (
                <MenuItem
                  value={obj.id.toLowerCase()}
                  key={obj.id.toLowerCase()}
                  className={classes.singleMenuItem}
                >
                  {obj.name}
                </MenuItem>
              ))
            ) : (
              <p className="no-data">No options</p>
            )}
          </Select>
          <div className="all-add-checkboxes">
            <div className="region-radio-container">
              <div className="radio-icon">
                <ThemeProvider theme={theme}>
                  <BlueRadio
                    value="all"
                    checked={regionValue.regionSelectedType === "all"}
                    onChange={(e) => {
                      onChange("regionSelectedType", e.target.value);
                    }}
                    name="all"
                    size="small"
                    disabled={false}
                    disableRipple
                  />
                </ThemeProvider>
                <label className="radio-label">
                  {!userInputs.is_config_manual
                    ? "All Regions"
                    : userInputs?.configType === "run"
                    ? regionsNameMapping[regionValue?.regionType]?.name
                    : regionsArray.includes(regionValue?.regionType)
                    ? regionsNameMapping[regionValue?.regionType]?.name
                    : "DMAs"}
                </label>
              </div>
              <div className="radio-icon">
                <ThemeProvider theme={theme}>
                  <BlueRadio
                    value="selected"
                    checked={regionValue.regionSelectedType === "selected"}
                    onChange={(e) => {
                      onChange("regionSelectedType", e.target.value);
                      onChange("regions", []);
                    }}
                    name="selected"
                    size="small"
                    disabled={false}
                    disableRipple
                  />
                </ThemeProvider>
                <label className="radio-label">
                  {!userInputs.is_config_manual
                    ? "Selected Regions"
                    : userInputs?.configType === "run"
                    ? regionsNameMapping[regionValue?.regionType]?.name
                    : regionsArray.includes(regionValue?.regionType)
                    ? regionsNameMapping[regionValue?.regionType]?.name
                    : "DMAs"}
                </label>
              </div>
            </div>
          </div>
          <div>
            {disableFlag &&
            regionValue.regionType &&
            regionValue.regionType.toLowerCase() !== "national" &&
            modelRegionSelectionData &&
            regionValue.regionSelectedType === "selected" ? (
              <IncludeExcludeRegions
                value={regionValue.regionFilterType}
                onChange={onChange}
              />
            ) : modelRegionData === -1 ? (
              <Loader />
            ) : modelRegionSelectionData &&
              regionValue.regionSelectedType === "selected" ? (
              <IncludeExcludeRegions
                value={regionValue.regionFilterType}
                onChange={onChange}
              />
            ) : null}
          </div>
        </div>
      </div>
      {/* <IncludeExcludeRegions
        value={regionValue.regionFilterType}
        onChange={onChange}
      /> */}
      {disableFlag &&
      regionValue.regionType &&
      regionValue.regionType.toLowerCase() !== "national" &&
      modelRegionSelectionData &&
      regionValue.regionSelectedType === "selected" ? (
        <ModelRegion
          disableFlag={disableFlag}
          userInputs={userInputs}
          regionValue={regionValue}
          onChange={onChange}
          modelRegionSelectionData={modelRegionSelectionData}
        />
      ) : modelRegionData === -1 ? (
        <Loader />
      ) : modelRegionSelectionData &&
        regionValue.regionSelectedType === "selected" ? (
        <ModelRegion
          disableFlag={disableFlag}
          userInputs={userInputs}
          regionValue={regionValue}
          onChange={onChange}
          modelRegionSelectionData={modelRegionSelectionData}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateAlertInfo,
  updateConfigUserInputs,
  updateSalesMediaRegions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Region);
