// Import Required Libraries
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Import Pages
import Login from "../screens/Login/Login";
import Error from "../screens/Error/Error";
import Unauthorised from "../screens/Unauthorised/Unauthorised";
import UnauthorizedAideApp from "../screens/UnauthorizedAideApp.js";
import PageNotFound from "../screens/PageNotFound/PageNotFound";
import AIDEApp from "../screens/AIDEApp/AIDEApp";
import AIDEConfig from "../screens/AIDEConfig/AIDEConfig";
import AIDERun from "../screens/AIDERun/AIDERun";
import AdminPanelContainer from "../screens/AdminPanel/AdminPanelContainer";
import AdminEntityList from "../screens/AdminPanel/EntityList";
import AdminEntityDetails from "../screens/AdminPanel/EntityDetails";

// Import Custom Components
import ProtectedRoute from "./ProtectedRoute";
import UnauthorizedRoute from "./UnauthorizedRoute";

// Import data
function Routes() {
  return (
    <Switch>
      <Redirect from="/" to="/home" exact />
      <ProtectedRoute path="/error" component={Error} exact />
      <ProtectedRoute path="/home" component={AIDEApp} exact />
      <ProtectedRoute
        path="/home/admin-panel"
        component={AdminPanelContainer}
        exact
      />
      <ProtectedRoute
        path="/home/admin-panel/:adminEntity"
        component={AdminEntityList}
        exact
      />
      <ProtectedRoute
        path="/home/admin-panel/:adminEntity/:entityName"
        component={AdminEntityDetails}
        exact
      />
      <ProtectedRoute path="/home/:tab" component={AIDEApp} exact />
      <ProtectedRoute
        path="/home/viewruns/:runName"
        component={AIDERun}
        exact
      />
      <ProtectedRoute
        path="/home/:tab/:configName"
        component={AIDEConfig}
        exact
      />
      <Route path="/login" component={Login} exact />
      <Route path="/businessdashboard" component={UnauthorizedAideApp} exact />
      <UnauthorizedRoute
        path="/businessdashboard/:tab"
        component={UnauthorizedAideApp}
        exact
      />
      <Route path="/unauthorised" component={Unauthorised} exact />
      <Route component={PageNotFound} />
    </Switch>
  );
}

export default Routes;
