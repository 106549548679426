// Import required libraies
import React, { useEffect, useState } from "react";
import { Popover, Badge } from "../../uiTheme";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TimeAgo from "react-timeago";
import { NavLink } from "react-router-dom";
// import { Badge } from "../uiTheme";
import { makeStyles } from "@material-ui/core/styles";

// Import custom components
import Loader from "../../components/Loader/Loader";

//Import utils/data
import {
  makeDefaultResponseJson,
  generateMessageNotification,
  makeRunNameFromRunNew,
} from "../../utils/utils";

// Import action creators
import {
  updateData,
  updateSelections,
  updateUserInfo,
  updateAlertInfo,
} from "../../redux/actions";

// Import config
import { config } from "../../config/config";

//Import styles
import "./Notifications.scss";

const useStyles = makeStyles((theme) => ({
  bellIconStyle: {
    "& .MuiBadge-dot": {
      backgroundColor: "#F10000 !important",
      top: 3,
      right: 6,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: 3,
      right: 6,
    },
  },
  root: {
    padding: 0,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

function NotificationRow(props) {
  let { item, updateNotification, updateSelections } = props;
  return (
    <div className={`notification-container ${item.notification_status}`}>
      <div className="notification-message-row">
        <div className={`run-icon ${item.payload.notification_type}`}></div>
        <p className="notification-message">
          {generateMessageNotification(item.payload)}
        </p>
      </div>
      <div className="notification-sub-container">
        <p className="notification-time-message">
          <TimeAgo date={new Date(+item.created_time)} />
        </p>
        <NavLink
          to={`/home/viewruns/${makeRunNameFromRunNew(item.payload)}`}
          className="nav-run-item disabled-style"
        >
          <p
            className="notification-run-message"
            onClick={() => {
              updateNotification([item.notification_id]);
              updateSelections("activeRunId", item.payload.run_id);
            }}
          >
            View Run
          </p>
        </NavLink>
      </div>
      <hr className="notification-separator" />
    </div>
  );
}

function Notifications(props) {
  let {
    notificationData,
    updateSelections,
    updateData,
    allData,
    updateAlertInfo,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Defining required variables
  let app = config.hardCoded.mroiAppId.toLowerCase();
  let activeApp = allData.apps.data
    ? allData.apps.data.find(
        (obj) => obj.name.toLowerCase() === app.toLowerCase()
      )
    : null;

  // Get Notification data
  const fetchData = () => {
    if (activeApp) {
      let url = `${config.api.notificationUrl}?app_id=${activeApp?.id}`;
      let status;
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) updateData("notificationData", json.data);
          else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
    return () => {};
  };

  //fetch notification data in every 10 mins
  useEffect(() => {
    if (activeApp) {
      fetchData();
      const intervalId = setInterval(() => {
        fetchData();
      }, config.fetchNotificationDuration);
      return () => clearInterval(intervalId);
    }
  }, [activeApp]);

  const updateNotification = (arrId) => {
    handleClose();
    let body = arrId;
    let url = config.api.notificationUrl;
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        fetchData();
        if (status === 200) updateData("notificationData", notificationData);
        else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };
  let invisible = notificationData
    ? notificationData.some((i) => i.notification_status === "unread")
      ? false
      : true
    : true;

  return (
    <div className="notification-container">
      <Badge
        //color="primary"
        variant="dot"
        invisible={invisible}
        className={classes.bellIconStyle}
      >
        <i
          className={`material-icons-outlined header-menu-icon notification`}
          onClick={handleClick}
        >
          notifications
        </i>
      </Badge>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="notification-header-container">
          <p className="notification-header">notifications</p>
          {notificationData ? (
            notificationData.some(
              (i) => i.notification_status === "unread"
            ) && (
              <button
                className="notification-button"
                onClick={() => {
                  updateNotification(
                    notificationData
                      .filter((i) => i.notification_status === "unread")
                      .map((i) => i.notification_id)
                  );
                }}
              >
                Mark all as read
              </button>
            )
          ) : (
            <Loader />
          )}
        </div>
        <div className="notification-message-container">
          {notificationData ? (
            notificationData.length > 0 ? (
              notificationData.map((item) => (
                <NotificationRow
                  item={item}
                  key={item.notification_id}
                  updateNotification={updateNotification}
                  updateSelections={updateSelections}
                />
              ))
            ) : (
              <div className="notification-container">
                <p className="no-notification-message">No notifications</p>
              </div>
            )
          ) : (
            <Loader />
          )}
        </div>
      </Popover>
    </div>
  );
}

Notifications.propTypes = {
  user: PropTypes.object.isRequired,
  notificationData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  user: state.user,
  notificationData: state.data.notificationData,
  allData: state.data,
});

const mapDispatchToProps = {
  updateUserInfo,
  updateData,
  updateSelections,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
