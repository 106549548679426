// Import required libraries
import React, { useState, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import { Drawer, Dialog } from "../uiTheme";
import { Drawer, Dialog } from "@material-ui/core";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";

// Import custom components
import Loader from "../Loader/Loader";
import Accordion from "./Accordion";
// Import styles
import "./Sidenav.scss";

// Import action creators
import {
  updateData,
  updateAlertInfo,
  updateUserInfo,
  toggleSidenav,
} from "../../redux/actions";

// Import data & utils
import {
  makeDefaultResponseJson,
  arrangeFolderAndFiles,
  sortCallBackFunc,
} from "../../utils/utils";

//Import config
import { config } from "../../config/config";

const useStyles = makeStyles(() => ({
  dialog: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    "& .MuiDialog-paperWidthSm": {
      minWidth: "800px",
      //minHeight: "300px",
      height: "700px",
      maxHeight: "685px",
    },
  },
  list: {
    width: 500,
  },
  typographyStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    color: "#46596a",
    minWidth: 400,
    maxWidth: 600,
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));

function SidenavBottom(props) {
  const { activeApp, user, updateAlertInfo, updateUserInfo, allData } = props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [appResourceData, setAppResourceData] = useState({
    status: "loading",
    message: "",
    data: [],
  });

  // State of search bar in resources
  const [value, setValue] = useState("");
  //files array
  const [fileArr, setFileArr] = useState([]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchResourceData = () => {
    if (activeApp) {
      let url = `${config.api.appResorcesUrl}?app_id=${activeApp?.id}`;
      let status;
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            setAppResourceData({
              status: "success",
              message: "",
              data: json.data,
            });
          } else {
            setAppResourceData({
              status: "error",
              message: json.statusMessage,
              data: json.data || [],
            });
          }
        });
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (open) {
      fetchResourceData();
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    setValue("");
  };

  // Search bar requisites
  const handleFilter = (e) => {
    setValue(e.target.value);
  };

  //adding the label in each folder
  let filesArr = [];
  const newData = (data, index) => {
    const finalData = data.map((el) => {
      let elMod;
      if (el?.data?.length > 0) {
        elMod = {
          ...el,
          label: index,
          data: newData(el.data, index + 1),
        };
      } else {
        elMod = { ...el, label: index };
        filesArr.push(el);
      }

      return elMod;
    });
    return finalData;
  };

  useEffect(() => {
    if (appResourceData?.data) {
      setFileArr(filesArr);
    }
  }, [appResourceData?.data]);

  let filteredData = useMemo(() => {
    if (value) {
      return fileArr
        .filter((appItem) =>
          appItem.name.toLowerCase().includes(value.toLowerCase())
        )
        .sort(sortCallBackFunc);
    } else {
      return (
        appResourceData?.data &&
        arrangeFolderAndFiles(newData(appResourceData?.data, 1))
      );
    }
  }, [value, appResourceData?.data]);

  const resourceListDrawer = (anchor) => {
    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
      >
        <div className="list">
          <div className="faq-text-cross-container">
            <p className="faq-text">Resources</p>
            <i
              className="material-icons-outlined drawer-cancel-icon"
              onClick={toggleDrawer(anchor, false)}
            >
              cancel
            </i>
          </div>
          <div className="aide-resources-search">
            <div className="input-search">
              <i className={`material-icons-outlined search-icon `}>search</i>
              <input
                type="text"
                id="search"
                name="search"
                value={value}
                className="input-label"
                onChange={(e) => handleFilter(e)}
                placeholder="Search for Resources"
                autoComplete="off"
              />
              <i
                className={`material-icons-outlined cancel-icon ${
                  value !== "" ? "visible" : ""
                }`}
                onClick={() => setValue("")}
              >
                clear
              </i>
            </div>
          </div>
          <div className="list-item-container">
            {appResourceData.status === "loading" && <Loader />}
            {appResourceData.status === "success" &&
              (filteredData.length ? (
                <Accordion
                  data={filteredData}
                  updateUserInfo={updateUserInfo}
                  updateAlertInfo={updateAlertInfo}
                  arrangeFolderAndFiles={arrangeFolderAndFiles}
                />
              ) : (
                <p className="no-comments">No Data available</p>
              ))}
            {appResourceData.status === "error" && (
              <p className="no-comments">{appResourceData.message}</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="sidenav-bottom-container">
        {user.role === config.hardCoded.adminRole && (
          <nav className="admin-panel-container">
            <div>
              <NavLink
                to={`/home/admin-panel`}
                className={`nav-item disabled-style `}
                title={"admin-panel"}
              >
                <i className={`material-icons-outlined nav-icon`}>person</i>
                <p
                  className={`${
                    user.screen.isSidenavBarExpanded ? "visible" : "hidden"
                  } nav-text`}
                >
                  Admin Panel
                </p>
              </NavLink>
            </div>
          </nav>
        )}
        <div
          className="raise-issue-div"
          onClick={() =>
            window.open(
              config.api.raiseATicketUrl
            )
          }
        >
          <i className={`material-icons-outlined nav-icon`}>report</i>
          <p
            className={`${
              user.screen.isSidenavBarExpanded ? "visible" : "hidden"
            } nav-text`}
          >
            Raise A Ticket
          </p>
        </div>
        <div className="resources-div" onClick={toggleDrawer("right", true)}>
          <i className={`material-icons-outlined nav-icon`}>help_outline</i>
          <p
            className={`${
              user.screen.isSidenavBarExpanded ? "visible" : "hidden"
            } nav-text`}
          >
            Resources
          </p>
        </div>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          // variant="persistent"
        >
          {resourceListDrawer("right")}
        </Drawer>
      </div>
    </>
  );
}

SidenavBottom.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  allData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateData,
  updateAlertInfo,
  updateUserInfo,
  toggleSidenav,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidenavBottom);
