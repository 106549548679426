//Import required libraies
import React, { useEffect, useState } from "react";
import { v4 } from "uuid";

//Import utils
import { config } from "../../../../config/config";
import { getWeekBasedOnHistoricWeek } from "../../../../utils/utils";

// Import custom components
import LocalParameterItem from "./LocalParameterItem";

function TestControlMultiSetPopup(props) {
  let {
    name,
    data,
    value: selectedValue,
    modelConfigId,
    onChange,
    enable,
    show,
    userInputs,
    customClassName,
    testRegions,
    setTestRegions,
    updateCheckDE,
    style,
  } = props;

  let initialData = {};

  selectedValue.length
    ? selectedValue.map((val) => (initialData = val))
    : data.forEach((element) => {
        if (element.ui_element_type === "inputbox_number") {
          initialData[element.id] = parseFloat(element["minValue"]) || 0.0;
        } else if (element.ui_element_type === "inputbox_text") {
          initialData[element.id] = element["value"] || "";
        } else {
          initialData[element.id] =
            element["min"] === "null"
              ? userInputs.global_week_date_selection ===
                config.hardCoded.historicWeek
                ? getWeekBasedOnHistoricWeek(userInputs.historic_week_data).max
                : userInputs.endDate
              : element["min"];
        }
      });
  const [currData, setCurrData] = useState(initialData);

  // console.groupCollapsed("STATE CHANGED");
  // console.log("DATA -> ", data);
  // console.log("CURR DATA -> ", currData);
  // console.log("VALUE -> ", selectedValue);
  // console.groupEnd();

  const onChangeCurr = (key, value) => {
    setCurrData({ ...currData, [key]: value });
  };

  useEffect(() => {
    onChange("test_group_parameters", [{ ...currData }]);
  }, [currData]);

  return updateCheckDE ? (
    <div>
      {/* <p>{name}</p> */}
      <div
        className={`regions-multiset-popup-row ${
          customClassName ? customClassName : ""
        } ${modelConfigId}`}
        style={style ? { ...style } : {}}
      >
        {data.map((item) => (
          <LocalParameterItem
            key={item.id}
            {...item}
            value={currData[item.id]}
            onChange={onChangeCurr}
            enable={enable}
            show={show}
            userInputs={userInputs}
          />
        ))}
      </div>
    </div>
  ) : null;
}

export default TestControlMultiSetPopup;
