//Import required libraies
import React from "react";
import { Redirect } from "react-router-dom";
import RunAnalysis from "../../components/RunAnalysis";

//Import Custom Component
import WorkInProgress from "../../components/WorkInProgress/WorkInProgress";
import UnauthorizedAIDEAppContainer from "../UnauthorizedAIDEAppContainer";
// import NLPAndGoogleDashboard from "../../components/GoogleDashBoard";

//Import Custom Hooks

//Import utils/data
function UnauthorizedAideApp(props) {
  let { match } = props;

  const tabMapping = {
    runanalysis: {
      component: <RunAnalysis match={match} isAuthorized={false} />,
    },
  };

  return (
    <UnauthorizedAIDEAppContainer>
      {match.params.tab ? (
        <>
          {tabMapping[match.params.tab] ? (
            tabMapping[match.params.tab].component
          ) : (
            <WorkInProgress />
          )}
        </>
      ) : (
        <Redirect
          to={
            match.url.endsWith("/")
              ? `${match.url}businessdashboard`
              : `${match.url}/businessdashboard`
          }
        />
      )}
    </UnauthorizedAIDEAppContainer>
  );
}

export default UnauthorizedAideApp;
