// Import required libraries
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RegionGranularitySelection from "./RegionGranularitySelection";
// import Button from "@material-ui/core/Button";
import { Button } from "../../../../uiTheme";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";
import orderBy from "lodash/orderBy";

//Import Custom Component
import MultiSelect from "./MultiSelect";
import TestControlMultiSetPopup from "./TestControlMultiSetPopup";
import TestControlCampaign from "./TestControlCampaign";
import UploadFilesRegions from "./UploadFilesRegions";
import KpiDetails from "./KpiDetails";

// Import utils, data
import {
  makeDefaultResponseJson,
  removeDupFromArray,
} from "../../../../utils/utils";

// Import data
import allCampaignObjectData from "../../../../../assets/data/allCampaignObjectData.json";
import DatasetCampaign from "./DatasetCampaign";

// Import action creators
import { updateModelLocalParameters } from "../../../../redux/actions";

//Import styles
import "./TestControlSet.scss";
import { config } from "../../../../config/config";
import Loader from "../../../../components/Loader/Loader";
const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    color: "#46596a",
    width: 400,
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
    },
  },
  contained: {
    color: "#46596a",
    backgroundColor: "transparent",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: "12px",
    height: 23,
    minWidth: 15,
    border: "1px solid #46596a",
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f1f1f1",
      boxShadow: "none",
    },
  },
  outlined: {
    // borderColor: "#46596a",
    marginRight: 4,
    border: "none",
    // fontSize: 14,
    //padding: 10,
    padding: "0px 5px",
    height: "22px",
    minWidth: "auto",
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  chipStyle: {
    marginTop: "20px",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    border: "1px solid #dedede",
    color: "#46596a !important",
    height: "25px",
    marginRight: "5px",
    background: "white",
    "& .MuiChip-deleteIcon": {
      width: "16px",
    },
  },
  popover: {
    pointerEvents: "none",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "150px",
  },
}));

function TestControlUploadFile(props) {
  const {
    activeModel,
    regionsData,
    setRegionsData,
    testRegions,
    setTestRegions,
    controlRegions,
    setControlRegions,
    displayData,
    disableFlag,
    userInputs,
    testControlRegions,
    value,
    testControlSet,
    initialTestRegions,
    initialControlRegions,
    customId,
    updateUserInfo,
    updateAlertInfo,
    setUserInputs,
    updateConfigUserInputs,
    selRegionGranularity,
    configUserInputs,
    updateCheckDE,
    datasetsTypesData,
    updateModelLocalParameters,
  } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [controlCellStatus, setControlCellStatus] = useState("");
  const [controlModelData, setControlModelData] = useState([]);
  const [deleteCellOperation, setDeleteCellOperation] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  //update regionsData if testData or controlData changes
  useEffect(() => {
    let allTestSelectedRegions = testRegions.map(
      (testDataLocalRow) => testDataLocalRow.regions
    );
    let allControlSelectedRegions = controlRegions.map(
      (controlDataLocalRow) => controlDataLocalRow.regions
    );
    let allSelectedRegions = [
      ...allTestSelectedRegions.flat(),
      ...allControlSelectedRegions.flat(),
    ];
    let newRegionsDataLocal = regionsData?.filter(
      (region) => !allSelectedRegions.includes(region)
    );
    setRegionsData(newRegionsDataLocal);
  }, [testRegions, controlRegions]);

  useEffect(() => {
    if (activeModel.id === config.hardCoded.diffDiffId) {
      let testPath = [];
      testRegions.forEach((elem) => {
        if (elem?.files?.input?.[0]) {
          testPath.push(elem?.files?.input[0]);
        }
      });
      if (testPath?.length) getControlRegion([...testPath]);
    }
    return () => {};
  }, [activeModel]);

  //Add empty test row
  const addTestLocalRow = () => {
    if (testRegions[testRegions.length - 1]?.files.hasOwnProperty("input")) {
      setError(null);
      let newTestData = initialTestRegions[0]; //setting with inital values
      setTestRegions([...testRegions, newTestData]);
    } else {
      setError("Please select input file to upload");
    }
  };

  const getControlRegion = (inputTestPath) => {
    setControlCellStatus("loading");
    const url = config.api.controlRegionUrl;
    let status;
    const body = {
      app_id: config.hardCoded.mroiAppId,
      model_id: activeModel.id,
      paths: inputTestPath,
    };
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          setControlCellStatus("success");
          setControlModelData(json.data.controlCells);
          if (controlRegions[0].regions.length) {
            let updatedRegions = json.data.controlCells.filter((elem) =>
              controlRegions[0].regions.some((item) => item === elem)
            );
            setControlRegions([
              { ...controlRegions[0], regions: updatedRegions },
            ]);
          }
        } else {
          setControlCellStatus("error");
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const deleteTestFileRow = (testDataRow) => {
    setDeleteCellOperation("loading");
    let url = `${config.api.uploadValidateFilesUrl}`;
    const deleteBody = {
      app_id: "MROI",
      path: testDataRow?.files?.input?.[0] || "",
      temp_uuid: userInputs.temp_uuid || "",
      mode: userInputs?.configType,
      config_id: userInputs?.configId || "",
    };
    let status;
    fetch(url, {
      method: "DELETE",
      body: JSON.stringify(deleteBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          setDeleteCellOperation("success");
          updateConfigUserInputs(
            "temp_uuid",
            json.temp_uuid ? json.temp_uuid : userInputs?.temp_uuid
          );
          if (activeModel.id === config.hardCoded.diffDiffId) {
            let testPath = [];
            testRegions.forEach((elem) => {
              if (elem?.files?.input?.[0]) {
                testPath.push(elem?.files?.input[0]);
              }
            });
            testPath = testPath.filter(
              (elem) => elem !== testDataRow?.files?.input?.[0]
            );
            if (testPath.length) getControlRegion([...testPath]);
          }
        } else {
          setDeleteCellOperation("error");
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const filterMediaDataset =
    activeModel.id === config.hardCoded.salesUpliftId &&
    datasetsTypesData
      ?.filter((data) => data.name === "media")[0]
      ?.data?.map((e) => ({ name: e.name, id: e.id }));

  useEffect(() => {
    if (config.hardCoded.rbtAndSuIds.includes(activeModel.id)) {
      if (deleteCellOperation === "success") {
        let modelData = userInputs.models.find(
          (model) => model.id === activeModel.id
        );
        let testCellsData =
          modelData.config.region_granularity.test_dark_regions.test;
        let columnData = [];
        testCellsData.forEach((config) => {
          if (config?.files?.column_details) {
            columnData.push(config?.files?.column_details);
          }
        });
        columnData = removeDupFromArray(columnData.flat());
        configUserInputs.models.forEach((model) => {
          if (
            activeModel.id === model.id &&
            model.run &&
            configUserInputs.is_config_manual
            // &&
            // configUserInputs.configType === "edit"
          ) {
            let data = {
              modelId: model.id,
              key: "cols_to_force_in",
              value: model.config.cols_to_force_in.filter((element) =>
                columnData.includes(element)
              ),
            };
            updateModelLocalParameters(data);
          }
        });
      }
    }
  }, [deleteCellOperation]);

  return (
    <div className="test-control-form">
      <div className="multi-test-control-container-row">
        <div className="test-control">
          <p className="text-title">Test Regions:</p>
          {testRegions.map((testDataRow, index) => {
            let onChange = (key, value) => {
              // ? setTestRegions(newTestDataLocal)
              // : setTestRegions(initialTestRegions);
              setTestRegions((preTestRegion) => {
                let newTestDataLocal;
                newTestDataLocal = preTestRegion.map((testDataLocalRow) => {
                  if (testDataLocalRow.id !== testDataRow.id) {
                    return testDataLocalRow;
                  } else if (
                    key === "dataset_id" ||
                    key === "target_campaigns"
                  ) {
                    if (key === "dataset_id" && !value?.length) {
                      delete testDataLocalRow["dataset_id"];
                      delete testDataLocalRow["target_campaigns"];
                    }
                    if (key === "target_campaigns" && !value?.length) {
                      delete testDataLocalRow["target_campaigns"];
                    }
                    return { ...testDataLocalRow, [key]: value };
                  } else {
                    return {
                      ...testDataLocalRow,
                      [key]: value,
                    };
                  }
                });
                return newTestDataLocal.length
                  ? newTestDataLocal
                  : initialTestRegions;
              });
            };
            let handleDelete = () => {
              if (testRegions.length == 1) {
                setError("Cannot delete last test selection");
              } else if (testRegions.length > 1) {
                let newTestDataLocal = testRegions.filter(
                  (testDataLocalRow) => testDataLocalRow.id !== testDataRow.id
                );
                setTestRegions(newTestDataLocal);
                deleteTestFileRow(testDataRow);
              }
            };
            return (
              <div
                className={`multi-test-control-container test-control-regions ${customId}`}
                key={testDataRow.id}
              >
                <div className="test-autocomplete-input">
                  <p className="header-text bold">
                    {config.hardCoded.testAndControlCellModels.includes(
                      customId
                    ) && index === 0
                      ? `Test Cell ${index}/ Control Cell:`
                      : `Test Cell ${index}:`}
                  </p>
                  <UploadFilesRegions
                    testRegions={testRegions}
                    userInputs={userInputs}
                    onChangeFile={onChange}
                    activeModel={activeModel}
                    value={testDataRow}
                    index={index}
                    getControlRegion={getControlRegion}
                    updateConfigUserInputs={updateConfigUserInputs}
                    setError={setError}
                    selRegionGranularity={selRegionGranularity}
                    deleteCellOperation={deleteCellOperation}
                    configUserInputs={configUserInputs}
                  />
                  {displayData.multiset_popup.show && (
                    <TestControlMultiSetPopup
                      {...props}
                      key={displayData.multiset_popup.id}
                      type={displayData.multiset_popup.type}
                      name={displayData.multiset_popup.name}
                      id={displayData.multiset_popup.id}
                      data={displayData.multiset_popup.data}
                      show={displayData.multiset_popup.show}
                      enable={displayData.multiset_popup.enable}
                      userInputs={userInputs}
                      value={testDataRow.test_group_parameters}
                      onChange={onChange}
                      style={{ justifyContent: "flex-start" }}
                    />
                  )}
                  {displayData.campaigns.show && (
                    <TestControlCampaign
                      key={displayData.multiset_popup.id}
                      type={displayData.multiset_popup.type}
                      name={displayData.multiset_popup.name}
                      id={displayData.multiset_popup.id}
                      data={displayData.multiset_popup.data}
                      show={displayData.multiset_popup.show}
                      enable={displayData.multiset_popup.enable}
                      userInputs={userInputs}
                      userSelectedCampaigns={testDataRow.campaigns}
                      allCampaigns={[...allCampaignObjectData]}
                      onChange={onChange}
                    />
                  )}
                  {activeModel.id === config.hardCoded.salesUpliftId &&
                  updateCheckDE ? (
                    userInputs?.datasetDetailsData?.status === "loading" ? (
                      <Loader />
                    ) : filterMediaDataset && userInputs?.brand ? (
                      <div style={{ display: "flex" }}>
                        <DatasetCampaign
                          filterMediaDataset={filterMediaDataset}
                          {...props}
                          onChange={onChange}
                          testDataRow={testDataRow}
                        />
                      </div>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <DatasetCampaign
                          filterMediaDataset={[]}
                          {...props}
                          onChange={onChange}
                          testDataRow={testDataRow}
                        />
                      </div>
                    )
                  ) : null}
                </div>
                <div className="test-control-regions-second-row">
                  <div className="delete-button-div">
                    <Button
                      variant="outlined"
                      // className={classes.outlined}
                      onClick={() => {
                        handleDelete();
                      }}
                      style={{
                        border: "none",
                        height: "22px",
                        padding: "0px 5px",
                        fontSize: "12px",
                        minWidth: "auto",
                        fontWeight: "bold",
                        marginRight: "4px",
                        textTransform: "capitalize",
                      }}
                    >
                      Delete
                      <i
                        className="material-icons-outlined delete_icon"
                        style={{ fontSize: "12px" }}
                      >
                        delete_forever
                      </i>
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="popup-btn-test-control">
          <Button
            variant="outlined"
            // className={classes.outlined}
            onClick={() => {
              addTestLocalRow();
            }}
            style={{ fontWeight: "bold", border: "none", fontSize: "12px" }}
          >
            Add Test Cell
          </Button>
        </div>
        {!config.hardCoded.uploadFileExcludedModel.includes(activeModel.id) && (
          <div className="test-control">
            <p className="text-title">
              {customId === config.hardCoded.regBudId ||
              customId === config.hardCoded.salesUpliftId
                ? "Dark Regions:"
                : " Control Regions:"}
            </p>
            {controlRegions.map((controlDataRow, index) => {
              let data = orderBy([...controlModelData]);
              let onChangeRegion = (key, value) => {
                let newControlDataLocal;
                if (value.length) {
                  newControlDataLocal = controlRegions.map(
                    (controlDataLocalRow) =>
                      controlDataLocalRow.id !== controlDataRow.id
                        ? controlDataLocalRow
                        : {
                            ...controlDataLocalRow,
                            [key]: value,
                          }
                  );
                } else {
                  newControlDataLocal = controlRegions.filter(
                    (controlDataLocalRow) =>
                      controlDataLocalRow.id !== controlDataRow.id
                  );
                }
                newControlDataLocal.length
                  ? setControlRegions(newControlDataLocal)
                  : setControlRegions(initialControlRegions);
              };
              let onChange = (key, value) => {
                let newControlDataLocal;
                newControlDataLocal = controlRegions.map(
                  (controlDataLocalRow) =>
                    controlDataLocalRow.id !== controlDataRow.id
                      ? controlDataLocalRow
                      : {
                          ...controlDataLocalRow,
                          [key]: value,
                        }
                );
                newControlDataLocal.length
                  ? setControlRegions(newControlDataLocal)
                  : setControlRegions(initialControlRegions);
              };
              return (
                <div
                  className="multi-test-control-container test-control-regions"
                  key={controlDataRow.id}
                >
                  <div className="control-autocomplete-input">
                    <div>
                      <p className="header-text bold">
                        {customId === config.hardCoded.regBudId ||
                        customId === config.hardCoded.salesUpliftId
                          ? "Dark Cell:"
                          : "Control Cell:"}
                      </p>
                      {(customId === config.hardCoded.regBudId ||
                        customId === config.hardCoded.salesUpliftId) && (
                        <>
                          <i
                            className="material-icons-outlined info-icon"
                            aria-owns={open ? "mouse-over-popover" : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                            style={{ pointerEvents: "auto" }}
                          >
                            info
                          </i>
                          <Popover
                            id="mouse-over-popover"
                            className={classes.popover}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <p className={classes.typography}>
                              {config.messages.darkCellMessage}
                            </p>
                          </Popover>
                        </>
                      )}
                    </div>
                    {activeModel.id === config.hardCoded.diffDiffId && (
                      <>
                        {controlCellStatus === "loading" && <Loader />}
                        {controlCellStatus === "success" && (
                          <MultiSelect
                            name={controlDataRow.id}
                            data={data}
                            value={controlDataRow.regions}
                            onChange={onChangeRegion}
                          />
                        )}
                        {controlCellStatus === "error" && <></>}
                      </>
                    )}
                    {config.hardCoded.autoMlIds.includes(activeModel.id) && (
                      <>
                        <UploadFilesRegions
                          userInputs={userInputs}
                          onChangeFile={onChange}
                          activeModel={activeModel}
                          value={controlDataRow}
                          index={index}
                          updateConfigUserInputs={updateConfigUserInputs}
                          setError={setError}
                          selRegionGranularity={selRegionGranularity}
                          deleteCellOperation={deleteCellOperation}
                          configUserInputs={configUserInputs}
                        />
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  updateModelLocalParameters,
};

export default connect(null, mapDispatchToProps)(TestControlUploadFile);
