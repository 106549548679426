// Import required libraries
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import styles
import "./App.scss";

// Import routes
import Routes from "./routes/Routes";

// Import action creators
import { updateUserInfo } from "./redux/actions";

// Import utils
import { config } from "../js/config/config";

// Import Configuration
import config_local from "../configuration/local_config.json";

//Import custom components
import Loader from "./components/Loader/Loader";

function App(props) {
  let { user, updateUserInfo } = props;
  const history = useHistory();
  const [authResolved, setAuthResolved] = useState(false);

  // Get user Role
  const getUserRole = (userData) => {
    let url = `${config.api.appUserRoleUrl}?app_handle=${config.hardCoded.mroiAppId}&logged_in_user=${userData.preferred_username}`;
    if (config_local.LOCAL) {
      fetch(url, {
        credentials: "include",
        method: "GET",
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 401) {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          } else {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          }
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (json.status.statusCode === "ums-200") {
            updateUserInfo({
              name: json.data.name,
              email: json.data.email
                ? json.data.email
                : json.data.preferred_username,
              preferred_username: json.data.preferred_username.includes("@")
                ? json.data.preferred_username.split("@")[0]
                : json.data.preferred_username,
              isAuth: true,
              error: null,
              status: null,
              role: json.data.role,
            });
            const location = window.location.pathname;
            setAuthResolved(true);
            history.push(location);
          } else if (json.errorCode) {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
            setAuthResolved(true);
          }
        });
    } else {
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 401) {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          } else {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          }
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (json.status.statusCode === "ums-200") {
            updateUserInfo({
              name: json.data.name,
              email: json.data.email
                ? json.data.email
                : json.data.preferred_username,
              preferred_username: json.data.preferred_username.includes("@")
                ? json.data.preferred_username.split("@")[0]
                : json.data.preferred_username,
              isAuth: true,
              error: null,
              status: null,
              role: json.data.role,
            });
            const location = window.location.pathname;
            setAuthResolved(true);
            history.push(location);
          } else if (json.errorCode) {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
            setAuthResolved(true);
          }
        });
    }
  };

  // Get userInfo
  const getUserInfo = () => {
    let url =
      config.api.userInfoUrl + window.location.host + window.location.pathname;
    if (config_local.LOCAL) {
      fetch(url, {
        credentials: "include",
        method: "GET",
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 401) {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          } else {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          }
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (json.status.statusCode === "auth-200") {
            getUserRole(json.data);
          }
          if (json.status.statusCode === "auth-403") {
            setAuthResolved(true);
            const location = "/unauthorised";
            history.push(location);
          }
          if (json.errorCode === 401) {
            updateUserInfo({
              name: "",
              email: "",
              preferred_username: "",
              isAuth: false,
              error: "UNAUTHORISED",
            });
            window.localStorage.removeItem(config.localStorageReduxStoreName);
            setAuthResolved(true);
          } else if (json.errorCode) {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
            setAuthResolved(true);
          }
        });
    } else {
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 401) {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          } else {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          }
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (json.status.statusCode === "auth-200") {
            getUserRole(json.data);
          }
          if (json.status.statusCode === "auth-403") {
            setAuthResolved(true);
            const location = "/unauthorised";
            history.push(location);
          }
          if (json.errorCode === 401) {
            updateUserInfo({
              name: "",
              email: "",
              preferred_username: "",
              isAuth: false,
              error: "UNAUTHORISED",
            });
            window.localStorage.removeItem(config.localStorageReduxStoreName);
            setAuthResolved(true);
          } else if (json.errorCode) {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
            setAuthResolved(true);
          }
        });
    }
  };

  // Check for Authorisation
  const checkforAuthorisation = () => {
    let url =
      config.api.authoriseUrl + window.location.host + window.location.pathname;
    if (config_local.LOCAL) {
      fetch(url, {
        credentials: "include",
        method: "GET",
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 403) {
            setAuthResolved(true);
            const location = "/unauthorised";
            history.push(location);
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          } else {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          }
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (json.status.statusCode === "auth-200") {
            getUserInfo();
          }
          if (json.status.statusCode === "auth-403") {
            setAuthResolved(true);
            const location = "/unauthorised";
            history.push(location);
          }
          if (json.errorCode) {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
            setAuthResolved(true);
          }
        });
    } else {
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          if (response.status === 200) {
            if (window.location.href.includes("runanalysis")) {
              const location = "/home";
              history.push(location);
            }
            return response.json();
          } else if (response.status === 403) {
            setAuthResolved(true);
            // const location = "/businessdashboard";
            const location = "/unauthorised";
            history.push(location);
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          } else {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          }
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (json.status.statusCode === "auth-200") {
            getUserInfo();
          }
          if (json.status.statusCode === "auth-403") {
            setAuthResolved(true);
            const location = "/unauthorised";
            history.push(location);
          }
          if (json.errorCode) {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
            setAuthResolved(true);
          }
        });
    }
  };

  // User authentication
  useEffect(() => {
    let url = config.api.authUrl;
    let status;
    if (config_local.LOCAL) {
      fetch(url, {
        credentials: "include",
        method: "GET",
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          if (response.status === 200) {
            return response.clone().json();
          } else if (response.status === 401) {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          } else {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          }
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            checkforAuthorisation();
          }
          if (status === 401) {
            updateUserInfo({
              name: "",
              email: "",
              preferred_username: "",
              isAuth: false,
              error: "UNAUTHORISED",
            });
            window.localStorage.removeItem(config.localStorageReduxStoreName);
            setAuthResolved(true);
          } else if (json.errorCode) {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
            setAuthResolved(true);
          }
        });
    } else {
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          if (response.status === 200) {
            return response.clone().json();
          } else if (response.status === 401) {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          } else {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              error,
              errorCode,
              status: {},
            };
          }
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            checkforAuthorisation();
          }
          if (status === 401) {
            updateUserInfo({
              name: "",
              email: "",
              preferred_username: "",
              isAuth: false,
              error: "UNAUTHORISED",
            });
            window.localStorage.removeItem(config.localStorageReduxStoreName);
            setAuthResolved(true);
          } else if (json.errorCode) {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
            setAuthResolved(true);
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  // Unauthorised user
  useEffect(() => {
    if (user.error === "UNAUTHORISED") {
      updateUserInfo({ isAuth: false });
      window.localStorage.removeItem(config.localStorageReduxStoreName);
      window.location.href =
        config.api.signInUrl + window.location.host + window.location.pathname;
    }
    // eslint-disable-next-line
  }, [user.error]);

  return (
    <>
      {authResolved ? (
        <Routes />
      ) : (
        <Loader
          fullPage={true}
          message={config.messages.authenticatingUserMessage}
        />
      )}
    </>
  );
}

App.propTypes = {
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = { updateUserInfo };

export default connect(mapStateToProps, mapDispatchToProps)(App);
