//Import required libraies
import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Popover from "@material-ui/core/Popover";
// import Chip from "@material-ui/core/Chip";
import { Chip } from "../../../../uiTheme";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
//Import Custom Component
import LocalParameterItem from "./LocalParameterItem";
import Loader from "../../../../components/Loader/Loader";

// Import custom hooks
import { useFirstRender } from "../../../../hooks/useFirstRender";
import { useNonInitialEffect } from "../../../../hooks/useNonInitialEffect";

// Import action creators

// Import styles

// Import utils & data
import { config } from "../../../../config/config";
import { formatDateForMerged } from "../../../../utils/utils";

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    pointerEvents: "auto",
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
      maxWidth: "250px",
      pointerEvents: ({ disableFlag }) => (disableFlag ? "none" : "auto"),
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFormControl-fullWidth": {
      // width: ({ width }) => width || 225,
      // minWidth: "250px",
      // background: "white",
      // boxSizing: "border-box",
      // borderRadius: "5px",
      // marginTop: 5,
      width: "100%",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri !important",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-option": {
      backgrounColor: "#46596a3b",
      fontFamily: "Hind Siliguri !important",
    },
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
      pointerEvents: "none",
    },
    "& .MuiAutocomplete-inputRoot": {
      minHeight: "48px",
    },
    "& .MuiAutocomplete-endAdornment": {
      pointerEvents: ({ disableFlag }) => (disableFlag ? "none" : "auto"),
    },
  },
  noDataSelect: {
    height: "48px",
  },
  popover: {
    pointerEvents: "none",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    width: "400px",
    margin: "10px",
  },
  root: {
    marginTop: "10px",
    fontFamily: "Hind Siliguri !important",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

const StyledAutoComplete = withStyles((theme) => ({
  tag: {
    // background: "white",
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
}))(Autocomplete);

const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function CampaignGranularity(props) {
  let { onChange, userInputs, activeDataset, disableFlag, width } = props;
  const classes = useStyles();
  const firstRender = useFirstRender();
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  let mediaDataset = userInputs.models
    .find((model) => config.hardCoded.autoMlIds.includes(model.id))
    .data_sources.find((dataset) => dataset.id === activeDataset.id);

  //check group_non_target_as_others value
  const [campaignFlag, setCampaignFlag] = useState(null);
  const [targetCampaigns, setTargetCampaigns] = useState(null);

  useEffect(() => {
    // if (firstRender) {
    mediaDataset?.target_campaigns
      ? setTargetCampaigns(mediaDataset.target_campaigns)
      : setTargetCampaigns([]);
    // }
    mediaDataset?.group_non_target_as_others
      ? setCampaignFlag(mediaDataset.group_non_target_as_others)
      : setCampaignFlag(false);
  }, [mediaDataset]);

  useNonInitialEffect(() => {
    onChange("target_campaigns", targetCampaigns);
  }, [targetCampaigns]);

  const campaignsData =
    userInputs?.campaignsData.status === "Successful" &&
    userInputs?.campaignsData?.data?.filter(
      (e) => e.dataset_id === activeDataset.id
    );

  return (
    <>
      {disableFlag ? (
        <>
          <label className="bold header-text">Select Target Campaigns:</label>
          {targetCampaigns?.length ? (
            <StyledAutoComplete
              multiple
              getOptionSelected={(option, value) =>
                option.campaignid === value.campaignid
              }
              id="fixed-tags-demo"
              value={targetCampaigns ? targetCampaigns : []}
              disableCloseOnSelect
              onChange={(event, value) => {
                if (!disableFlag) {
                  setTargetCampaigns(value);
                }
              }}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              options={targetCampaigns}
              getOptionLabel={(option) => option.campaign || ""}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <div
                    key={option.campaignid}
                    title={`Campaign Name: ${option.campaign} | Campaign ID: ${
                      option.campaignid
                    } | Start Date: ${formatDateForMerged(
                      option.campaign_start_date
                    )} | End Date: ${formatDateForMerged(
                      option.campaign_end_date
                    )}`}
                  >
                    <Chip label={option.campaign} {...getTagProps({ index })} />
                  </div>
                ))
              }
              style={{
                maxWidth: "100%",
                width: "500px",
                minWidth: 220,
                border: "1px solid #dedede",
                borderRadius: 4,
                marginRight: 15,
                paddingLeft: 5,
                marginLeft: -5,
              }}
              size="small"
              className={classes.typographyStyle}
              renderOption={(option, { selected }) => (
                <>
                  <StyledCheckbox checked={selected} />
                  {option.campaign}
                </>
              )}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  placeholder=""
                  variant="outlined"
                  size="small"
                  className={classes.typographyStyle}
                />
              )}
            />
          ) : (
            <div className="input-item-label-less">
              <Select value="No campaigns" disabled={true}>
                <MenuItem value="No campaigns">No campaigns</MenuItem>
              </Select>
            </div>
          )}
        </>
      ) : userInputs.campaignsData.status === "loading" ? (
        <Loader />
      ) : (
        <>
          <label className="bold header-text">Select Target Campaigns:</label>
          {campaignsData.length ? (
            <StyledAutoComplete
              multiple
              getOptionSelected={(option, value) =>
                option.campaignid === value.campaignid
              }
              id="fixed-tags-demo"
              value={targetCampaigns ? targetCampaigns : []}
              disableCloseOnSelect
              onChange={(event, value) => {
                setTargetCampaigns(value);
              }}
              options={campaignsData}
              getOptionLabel={(option) => option.campaign || ""}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <div
                    key={option.campaignid}
                    title={`Campaign Name: ${option.campaign} | Campaign ID: ${
                      option.campaignid
                    } | Start Date: ${formatDateForMerged(
                      option.campaign_start_date
                    )} | End Date: ${formatDateForMerged(
                      option.campaign_end_date
                    )}`}
                  >
                    <Chip label={option.campaign} {...getTagProps({ index })} />
                  </div>
                ))
              }
              style={{
                maxWidth: "100%",
                width: "500px",
                minWidth: 220,
                border: "1px solid #dedede",
                borderRadius: 4,
                marginRight: 15,
                paddingLeft: 5,
                marginLeft: -5,
              }}
              size="small"
              className={classes.typographyStyle}
              renderOption={(option, { selected }) => (
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  title={`Campaign Name: ${option.campaign} | Campaign ID: ${
                    option.campaignid
                  } | Start Date: ${formatDateForMerged(
                    option.campaign_start_date
                  )} | End Date: ${formatDateForMerged(
                    option.campaign_end_date
                  )}`}
                >
                  <StyledCheckbox checked={selected} />
                  {option.campaign}
                </div>
              )}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  placeholder="Choose Campaign"
                  variant="outlined"
                  size="small"
                  className={classes.typographyStyle}
                />
              )}
            />
          ) : (
            <div className="input-item-label-less">
              <Select value="No campaigns" disabled={true}>
                <MenuItem value="No campaigns">No campaigns</MenuItem>
              </Select>
            </div>
          )}
        </>
      )}

      <LocalParameterItem
        value={campaignFlag ? campaignFlag : false}
        name="Group Non-Target Campaigns with 'Others'"
        ui_element_type="checkbox"
        onChange={onChange}
        id="group_non_target_as_others"
        userInputs={userInputs}
      />
      {userInputs.configType !== "run" && (
        <p
          style={{
            color: "red",
            fontSize: "11px",
          }}
        >
          *Please select pilot start date and end date before selecting
          campaigns
        </p>
      )}
      {targetCampaigns && (
        <Popover
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div
            className="popover-content"
            style={{ minWidth: width, maxWidth: width }}
          >
            {targetCampaigns?.map((option) => (
              <div>
                <p
                  key={option}
                  title={`Dataset Name : ${
                    option.dataset_id
                  } | Campaign Name: ${option.campaign} | Campaign ID: ${
                    option.campaignid
                  } | Start Date: ${formatDateForMerged(
                    option.campaign_start_date
                  )} | End Date: ${formatDateForMerged(
                    option.campaign_end_date
                  )}`}
                  style={{ wordWrap: "break-word" }}
                >
                  {`${option.dataset_id}_${option.campaign}`}
                </p>
              </div>
            ))}
          </div>
        </Popover>
      )}
    </>
  );
}

export default CampaignGranularity;
