import React, { useState, useMemo, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "../../uiTheme";

//Import Custom Component
import Loader from "../Loader/Loader";
// Import styles
import "./DashboardCountryBrandSelections.scss";
import DatePicker from "../../uiTheme";

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px !important",
    fontWeight: "100",
    fontFamily: "Hind Siliguri !important",
    minWidth: "200px",
    width: "100%",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a !important",
    backgroundColor: "white",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px !important",
    },
  },
  menuItem: {
    fontSize: "12px !important",
    fontFamily: "Hind Siliguri !important",
    padding: "0px !important",
  },
  singleMenuItem: {
    fontSize: "13px !important",
    fontFamily: "Hind Siliguri !important",
    padding: "8px 12px !important",
    color: "#46596a !important",
    width: "auto",
    '&[aria-selected="true"]': {
      color: `#46596a !important`,
      backgroundColor: `rgba(0, 0, 0, 0.08) !important`,
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      textDecoration: "none",
    },
  },
  listItem: {
    "& span.MuiTypography-body1": {
      fontSize: "12px",
      color: "#46596a",
      fontFamily: "Hind Siliguri",
      paddingRight: "5px",
    },
  },
  popover: {
    pointerEvents: "none",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    width: "800px",
    margin: "10px",
  },
  typographyHeader: {
    fontSize: 12,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 5,
    backgroundColor: "#ebeff1",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: "#aaa",
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

function DashboardCountryBrandSelection(props) {
  const {
    handlerForGo,
    countryBrandData,
    country,
    setCountry,
    brand,
    setBrand,
    pilotDate,
    setPilotDate,
    setUnit,
    allData,
  } = props;
  const { loading, data } = countryBrandData;
  const [disable, setDisable] = useState(true);

  const brands = useMemo(() => {
    let brandData = data?.find((e) => e?.name === country)?.brands;
    if (country !== "ALL") {
      let getBrandFromReports = allData?.data?.reduce((acc, curr) => {
        if (
          (country === "ALL" || curr.country === country) &&
          !acc.includes(curr.brand)
        ) {
          acc.push(curr.brand);
        }
        return acc;
      }, []);
      return brandData?.filter(
        (e) => e === "ALL" || getBrandFromReports.includes(e)
      );
    }
    return brandData;
  }, [country]);

  let pilotArr = useMemo(() => {
    let pilotData = data?.find(
      (e) =>
        (country === "ALL" || e?.name === country) &&
        (brand === "ALL" || e.brands.includes(brand))
    )?.pilot_start_dates;
    if (brand !== "ALL") {
      let getBrandFromReports = allData?.data?.reduce((acc, curr) => {
        if (
          (country === "ALL" || curr.country === country) &&
          (brand === "ALL" || curr.brand === brand) &&
          !acc.includes(curr.pilot_start_date)
        ) {
          acc.push(curr.pilot_start_date);
        }
        return acc;
      }, []);
      return pilotData?.filter(
        (e) => e === "ALL" || getBrandFromReports.includes(e)
      );
    }
    return pilotData;
  }, [country, brand, allData?.data]);

  useEffect(() => {
    if (!brands?.includes(brand)) {
      setBrand("ALL");
    }
    if (pilotArr?.length && !pilotArr?.includes(pilotDate)) {
      setPilotDate("ALL");
    }
  }, [country, brand]);

  const classes = useStyles();

  return (
    <div className="country-brand-container" style={{ height: "83px" }}>
      <div className={`input-item country-section`}>
        <label>Country:</label>
        <Select
          value={country}
          onChange={(e) => {
            setCountry(e.target.value);
            if (country) {
              setDisable(false);
            }
          }}
          className={classes.multiSelect}
          MenuProps={MenuProps}
          displayEmpty
          renderValue={
            country
              ? undefined
              : () => <Placeholder>Select an option</Placeholder>
          }
          data-testid="select-country"
        >
          {data?.map((obj) => (
            <MenuItem
              value={obj.name}
              key={obj.name}
              className={classes.singleMenuItem}
            >
              {obj.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={`input-item`}>
        <label>Brand:</label>
        <Select
          value={brand}
          onChange={(e) => {
            setBrand(e.target.value);
            if (brand) {
              setDisable(false);
            }
          }}
          className={classes.multiSelect}
          MenuProps={MenuProps}
          displayEmpty
          renderValue={
            brand
              ? undefined
              : () => <Placeholder>Select an option</Placeholder>
          }
        >
          {brands ? (
            brands?.map((elem) => (
              <MenuItem
                value={elem}
                key={elem}
                className={classes.singleMenuItem}
              >
                {elem}
              </MenuItem>
            ))
          ) : (
            <p className="no-data">No option available</p>
          )}
        </Select>
      </div>
      <div className={`input-item`}>
        <label>Pilot Start Date:</label>
        <Select
          value={pilotDate}
          onChange={(e) => {
            setPilotDate(e.target.value);
            if (pilotDate) {
              setDisable(false);
            }
          }}
          className={classes.multiSelect}
          MenuProps={MenuProps}
          displayEmpty
          renderValue={
            pilotDate
              ? undefined
              : () => <Placeholder>Select an option</Placeholder>
          }
        >
          {pilotArr ? (
            pilotArr?.map((elem) => (
              <MenuItem
                value={elem}
                key={elem}
                className={classes.singleMenuItem}
              >
                {elem}
              </MenuItem>
            ))
          ) : (
            <p className="no-data">No option available</p>
          )}
        </Select>
      </div>
      <div className="go-button">
        <Button
          onClick={() => {
            handlerForGo();
            setDisable(true);
          }}
          disabled={!country || !brand ? true : disable ? true : false}
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            minWidth: "80px",
            background:
              !country || !brand
                ? "rgba(0,0,0,0.4)"
                : disable
                ? "rgba(0,0,0,0.4)"
                : "#46596a",
          }}
        >
          Go
        </Button>
      </div>
    </div>
  );
}

export default DashboardCountryBrandSelection;
