//Import required libraies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

//Import Custom Component
import TableRow from "../../../components/TableRow/TableRow";
import FiltersNew from "../../../components/Filters/FilterNew";
import AppRow from "../../../components/AppRow/AppRow";
import Loader from "../../../components/Loader/Loader";
import noDataImage from "../../../../assets/images/undraw_empty.svg";

//Import utils & data
import {
  splitConfigsByCategory,
  filterMyConfigs,
  makeDefaultResponseJson,
  getStartEndByDayCat,
  checkConfigurationCountryAndBrand,
} from "../../../utils/utils";
import { config } from "../../../config/config";

// Import action creators
import {
  updateData,
  updateSelections,
  updateUserInfo,
  updateRunData,
  updateAlertInfo,
} from "../../../redux/actions";

//Import hooks
import { useIsMountedRef } from "../../../hooks/useIsMountedRef";
import { useFirstRender } from "../../../hooks/useFirstRender";

//Import style
import "./SharedConfigurations.scss";

function SharedConfigurationsSwitchIcons(props) {
  let { user, updateSelections } = props;
  return (
    <div className="myconfig-switch-icons">
      <i
        className={`material-icons myconfig-icons ${
          user.selections.appView === "table" ? "active" : ""
        }`}
        onClick={() => updateSelections("appView", "table")}
      >
        reorder
      </i>
      <i
        className={`material-icons myconfig-icons ${
          user.selections.appView === "grid" ? "active" : ""
        }`}
        onClick={() => updateSelections("appView", "grid")}
      >
        grid_on
      </i>
    </div>
  );
}

function SharedConfigurations(props) {
  let {
    match,
    user,
    allData,
    updateSelections,
    updateData,
    updateUserInfo,
    changeFlag,
    updateRunData,
    updateAlertInfo,
  } = props;
  const [configs, setConfigs] = useState(false);
  const [configsLoading, setConfigsLoading] = useState(false);
  const isFirstRender = useFirstRender();
  const history = useHistory();

  let app = config.hardCoded.mroiAppId.toLowerCase();
  let activeApp =
    allData.apps.data.length > 0
      ? allData.apps.data.find(
          (obj) => obj.name.toLowerCase() === app.toLowerCase()
        )
      : null;

  // Get apps data
  useEffect(() => {
    if (!allData.apps.data) {
      let url = `${config.api.appsUrl}?vertical=${config.hardCoded.defaultVertical}&app_id=${activeApp?.id}`;
      let status;
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            let allAppsName = json.data?.map((appName) =>
              appName.name?.toLowerCase()
            );
            if (!allAppsName.includes(activeApp?.id)) {
              updateAlertInfo({
                open: true,
                message: config.messages.noAppsAccess,
                severity: "info",
              });
              history.push(`/home/`);
            }
            updateData("apps", {
              status: "success",
              data: json.data,
              message: "",
            });
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
    return () => {};
  }, []);

  //reload shared_configurations data
  useEffect(() => {
    if (activeApp && !isFirstRender.current) {
      let url = `${config.api.sharedConfigsUrl}?app_id=${activeApp?.id}`;
      setConfigsLoading(true);
      let status;
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("shared_configurations", json.data);
            setConfigsLoading(false);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
            setConfigsLoading(false);
          }
        });
    }
    return () => {};
  }, [changeFlag.reloadConfigs]);

  // Change table if shared_configurations changes
  useEffect(() => {
    if (allData.shared_configurations) {
      let filtered_shared_configurations = filterMyConfigs(
        allData.shared_configurations,
        "name",
        user.selections,
        true
      );

      let configArr = splitConfigsByCategory({
        category: match.params.tab,
        arr: filtered_shared_configurations,
      });

      setConfigs({ filtered_shared_configurations, configArr });
    }
  }, [allData.shared_configurations, user.selections, match.params.tab]);

  const prepareRowObj = (obj) => {
    obj = checkConfigurationCountryAndBrand(allData, obj);
  };

  return (
    <>
      <FiltersNew match={match} activeApp={activeApp} />
      {!configs ? (
        <Loader />
      ) : configs.filtered_shared_configurations.length ? (
        <>
          {/* <SharedConfigurationsSwitchIcons
            user={user}
            updateSelections={updateSelections}
          /> */}
          {configsLoading && <Loader />}
          <div
            className={`my-configs-container ${
              configsLoading ? "disabled-pointer" : ""
            }`}
          >
            {user.selections.appView == "table"
              ? configs.configArr.map((obj) => {
                  prepareRowObj(obj);
                  return (
                    <TableRow
                      key={obj.key}
                      title={obj.title}
                      match={match}
                      data={obj.data}
                    />
                  );
                })
              : configs.configArr.map((obj) => {
                  prepareRowObj(obj);
                  return (
                    <AppRow
                      key={obj.key}
                      title={obj.title}
                      match={match}
                      data={obj.data}
                      popOverFlag={true}
                    />
                  );
                })}
          </div>
        </>
      ) : (
        <div className="no-configs">
          <img src={noDataImage} />
          <p className="no-data">
            <b>{config.messages.noConfigs}</b>
          </p>
        </div>
      )}
    </>
  );
}

SharedConfigurations.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  saved_configurations: PropTypes.array,
  updateData: PropTypes.func,
  updateSelections: PropTypes.func,
  updateRunData: PropTypes.func,
  shared_configurations: PropTypes.array,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  changeFlag: state.changeFlag,
});

const mapDispatchToProps = {
  updateData,
  updateSelections,
  updateUserInfo,
  updateRunData,
  updateAlertInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SharedConfigurations);
