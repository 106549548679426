//Import required libraies
import React, { useState, useEffect } from "react";

//Import Custom Component
import LocalParameterItem from "./LocalParameterItem";

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data
import { config } from "../../../../config/config";

function ChannelGranularity(props) {
  let { onChange, userInputs, activeDataset, activeModel } = props;

  let mediaDataset = userInputs.models
    .find(
      (model) =>
        config.hardCoded.autoMlIds.includes(model.id) &&
        activeModel.id === model.id
    )
    .data_sources.find((dataset) => dataset.id === activeDataset.id);

  //check group_as_others value
  const [channelFlag, setChannelFlag] = useState(null);

  useEffect(() => {
    mediaDataset?.group_as_others
      ? setChannelFlag(mediaDataset.group_as_others)
      : setChannelFlag(false);
  }, [mediaDataset]);

  return (
    <LocalParameterItem
      value={channelFlag ? channelFlag : false}
      name="Group as Others"
      ui_element_type="checkbox"
      onChange={onChange}
      id="group_as_others"
    />
  );
}

export default ChannelGranularity;
