//Import required libraies
import React from "react";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

//Import Custom Component
import ChannelGranularity from "./ChannelGranularity";
import CampaignGranularity from "./CampaignGranularity";

// Import utils
import { config } from "../../../../config/config";

// Import custom hooks

// Import action creators

// Import styles
import "./RadioButtons.scss";

const BlueRadio = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: "#4eafb3",
    "&$checked": {
      color: "#4eafb3",
    },
    "&$disabled": {
      color: "#4eafb3",
      opacity: 0.5,
    },
    "& .MuiSvgIcon-root": {
      height: 15,
      weight: 15,
    },
  },
  checked: {},
  disabled: {
    color: "#4eafb3",
    opacity: 0.5,
  },
})((props) => <Radio color="default" {...props} />);

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
  },
});

function DatasetGranularity(props) {
  let {
    data,
    name,
    id,
    customClassName,
    value,
    onChange,
    enable,
    granularityFlag,
    activeModel,
    granularityForMergeDataset,
  } = props;

  return (
    <>
      <div
        className={`input-item row ${customClassName ? customClassName : ""} ${
          activeModel.id === config.hardCoded.autoMlBudgetId
            ? "disabled-pointer"
            : ""
        } ${id}`}
      >
        <label>{name}:</label>
        <div className="radio-container">
          {data.map((obj) => {
            return (
              <div
                className="radio-icon"
                key={obj.id}
                title={
                  granularityForMergeDataset && obj.id === "campaign"
                    ? "This option is not available for merged datasets"
                    : ""
                }
                style={{
                  pointerEvents: `${
                    granularityForMergeDataset && obj.id === "campaign"
                      ? "auto"
                      : ""
                  }`,
                }}
              >
                <ThemeProvider theme={theme}>
                  <BlueRadio
                    value={obj.id}
                    checked={
                      value?.length
                        ? obj.id.toLowerCase() === value.toLowerCase()
                        : false
                    }
                    onChange={(e) => {
                      onChange(id, e.target.value);
                    }}
                    name={id}
                    size="small"
                    disabled={
                      granularityForMergeDataset && obj.id === "campaign"
                        ? true
                        : !enable
                    }
                    disableRipple
                  />
                </ThemeProvider>
                <label className="radio-label">{obj.name}</label>
              </div>
            );
          })}
        </div>
      </div>
      {granularityFlag &&
        (value === "campaign" ? (
          <div className="dataset-campaign">
            <CampaignGranularity {...props} />
          </div>
        ) : (
          <div className="dataset-channel">
            <ChannelGranularity {...props} />
          </div>
        ))}
    </>
  );
}

export default DatasetGranularity;
