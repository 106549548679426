// import { createStore, applyMiddleware, compose } from "redux";
// import logger from "redux-logger";
// import thunk from "redux-thunk";
// import rootReducer from "./reducers/index";
// import { stateData } from "./stateData";

// const initialState = stateData;
// const composeEnhancer =
//   process.env.NODE_ENV === "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//         trace: true,
//         traceLimit: 25,
//       }) ?? compose
//     : compose;
// // const store = applyMiddleware(logger)(createStore)(rootReducer, initialState);
// const store = createStore(
//   rootReducer,
//   initialState,
//   composeEnhancer(applyMiddleware(logger, thunk))
// );

// export default store;

import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import rootReducer from "./reducers/index";
import { stateData } from "./stateData";

const initialState = stateData;

const store = applyMiddleware(logger)(createStore)(rootReducer, initialState);

export default store;
