// Import required libraries
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import RegionGranularitySelection from "./RegionGranularitySelection";
import TestControlSet from "./TestControlSet";
import { v4 } from "uuid";

// Import cuntom component
import Loader from "../../../../components/Loader/Loader";
import TestControlUploadFile from "./TestControlUploadFile";
import UploadFilesRegions from "./UploadFilesRegions";

// Import action creators
import {
  updateConfigUserInputs,
  updateAlertInfo,
  updateSalesMediaRegions,
} from "../../../../redux/actions";

// Import data
import regionData from "../../../../../assets/data/region.json";
import regionGranularity from "../../../../../assets/data/regionGranularity.json";

//Import utils
import { config } from "../../../../config/config";
import {
  makeDefaultResponseJson,
  getRegionsData,
  filterSalesDatasetRegions,
  salesMediaFilterPayload,
  datasetIds,
} from "../../../../utils/utils";

//Import styles
import "./RegionTestControlContainer.scss";

function RegionTestControlContainer(props) {
  const {
    id,
    value,
    activeModel,
    loading,
    onChange: updateConfig,
    userInputs,
    setUserInputs,
    activeApp,
    updateUserInfo,
    disableFlag,
    allData,
    updateAlertInfo,
    updateConfigUserInputs,
    updateCheckDE,
    datasetsTypesData,
    filterMediaDataset,
    updateSalesMediaRegions,
  } = props;

  let regionsArray = userInputs.is_config_manual
    ? regionData.map(function (val) {
        return val.id.toLowerCase();
      })
    : userInputs?.globalRegions
        ?.map(function (val) {
          return val.id.toLowerCase();
        })
        .sort();

  const [countryRegions, setCountryRegions] = useState(null); //data with country, city, dma, state
  const [regionsData, setRegionsData] = useState(null); // Global regions data
  const [testRegionsData, setTestRegionsData] = useState(null); // global and filtred test data
  const [controlRegionsData, setControlRegionsData] = useState(null); // global and filtered control
  const [pageReady, setPageReady] = useState(true);
  const [dataReady, setDataReady] = useState(false);

  let initialSelRegionGranularity;
  if (
    (userInputs?.configType === "run" &&
      !regionsArray?.includes(value.region_type)) ||
    regionsArray?.includes(value.region_type) ||
    value?.region_type?.toLowerCase() === "national"
  ) {
    initialSelRegionGranularity = value.region_type;
  } else if (!userInputs.is_config_manual) {
    if (userInputs?.globalRegions?.length) {
      initialSelRegionGranularity =
        userInputs?.globalRegions[0]?.id?.toLowerCase();
    } else if (!userInputs.globalRegions) {
      initialSelRegionGranularity = value.region_type;
    } else {
      initialSelRegionGranularity = "";
    }
  } else if (userInputs.is_config_manual) {
    initialSelRegionGranularity = "dma";
  }
  const fetchSalesMediaRegions = (url, body, controller, key, modelId) => {
    if (key === "validMediaRegions") {
      updateConfigUserInputs("mediaRegions", "loading");
    } else {
      updateConfigUserInputs("salesRegions", "loading");
    }
    let status;
    fetch(url, {
      method: "POST", //!need to be changed to POST
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      //!need to uncommented
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("RESPONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.groupEnd();
        if (status === 200) {
          if (key === "validMediaRegions") {
            updateConfigUserInputs("mediaRegions", "success");
            updateSalesMediaRegions({ regionKey: key, modelId, json });
            // updateConfigUserInputs(key, obj);
          } else {
            updateConfigUserInputs("salesRegions", "success");
            // updateConfigUserInputs(key, json);
            updateSalesMediaRegions({ regionKey: key, modelId, json });
          }
        } else {
          if (key === "validMediaRegions") {
            updateConfigUserInputs("mediaRegions", "failed");
          } else {
            updateConfigUserInputs("salesRegions", "failed");
          }
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };
  const [selRegionGranularity, setSelRegionGranularity] = useState(
    initialSelRegionGranularity
  );

  //user selected test
  const [testRegions, setTestRegions] = useState(
    config.hardCoded.autoMlIds.includes(activeModel.id)
      ? value?.test_regions?.test
      : config.hardCoded.rbtAndSuIds.includes(activeModel.id)
      ? value.test_dark_regions.test
      : value.test_control_regions.test
  );
  // user selected control
  const [controlRegions, setControlRegions] = useState(
    config.hardCoded.rbtAndSuIds.includes(activeModel.id)
      ? value?.test_dark_regions?.dark
      : value?.test_control_regions?.control
  );

  let activeModelConfig = userInputs.models.find(
    (model) => model.id === activeModel.id
  ).config;

  let modelDataSources = userInputs.models.find(
    (model) => model.id === activeModel.id
  ).data_sources;
  //filter datasets based on the country
  let filteredDatasets = allData.datasets.filter(
    (dataset) =>
      dataset.countries.includes(userInputs.country) &&
      (dataset.type === config.hardCoded.datasetSalesType ||
        dataset.type === config.hardCoded.datasetNeilsenType ||
        dataset.type === config.hardCoded.datasetMediaType)
  );
  let datasets = filteredDatasets.filter((dataset) =>
    activeModel.dataset_ids.some(
      (datasetOfActiveModel) => datasetOfActiveModel.id === dataset.id
    )
  );

  //Adding empty row if test/control regions is empty
  let initialTestRegions = [
      {
        id: v4(),
        regions: [],
        ...(userInputs?.is_config_manual && { files: {} }),
        type: "test",
        ...(activeModel.id === config.hardCoded.salesUpliftId && {
          test_group_parameters: [],
        }),
        ...(activeModel.id === config.hardCoded.diffDiffId && {
          campaigns: [],
        }),
      },
    ],
    initialControlRegions = [
      {
        id: v4(),
        regions: [],
        ...(userInputs?.is_config_manual && { files: {} }),
        type: config.hardCoded.rbtAndSuIds.includes(activeModel.id)
          ? "dark"
          : "control",
      },
    ];

  let testRegionsFlat = [],
    controlRegionsFlat = [];

  useEffect(() => {
    if (testRegions instanceof Array) {
      setTestRegions(
        config.hardCoded.autoMlIds.includes(activeModel.id)
          ? value.test_regions.test
          : config.hardCoded.rbtAndSuIds.includes(activeModel.id)
          ? value.test_dark_regions.test
          : value.test_control_regions.test
      );
      testRegionsFlat = testRegions.map((i) => i.regions).flat(1);
    } else {
      setTestRegions([]);
    }
    if (controlRegions instanceof Array) {
      setControlRegions(
        config.hardCoded.rbtAndSuIds.includes(activeModel.id)
          ? value?.test_dark_regions?.control
          : value?.test_control_regions?.control
      );
      controlRegionsFlat = controlRegions.map((i) => i.regions).flat(1);
    } else {
      setControlRegions([]);
    }
  }, []);

  //If region is not available then update with default value
  useEffect(() => {
    if (!selRegionGranularity) {
      if (!userInputs?.is_config_manual && userInputs?.globalRegions?.length) {
        setSelRegionGranularity(userInputs?.globalRegions[0]?.id.toLowerCase());
      } else if (userInputs?.is_config_manual) {
        setSelRegionGranularity(
          regionGranularity[
            activeModel.id
          ].region_granularity[0].id.toLowerCase()
        );
      }
    }
  }, []);

  useEffect(() => {
    if (
      // selRegionGranularity &&
      selRegionGranularity?.toLowerCase() === "national"
    ) {
      updateConfig("region_granularity", {
        ...(config.hardCoded.autoMlIds.includes(activeModel.id)
          ? {
              test_regions: {
                test: {},
                // control: {},
                ...(userInputs?.is_config_manual && {
                  files: value.test_regions.files,
                }),
              },
            }
          : config.hardCoded.rbtAndSuIds.includes(activeModel.id)
          ? {
              test_dark_regions: {
                test: {},
                dark: {},
                ...(userInputs?.is_config_manual && {
                  files: value.test_dark_regions.files,
                }),
              },
            }
          : {
              test_control_regions: {
                test: {},
                control: {},
                ...(userInputs?.is_config_manual && {
                  files: value.test_control_regions.files,
                }),
              },
            }),
        // test_control_regions: {
        //   test: {},
        //   control: {},
        //   ...(userInputs?.is_config_manual && {
        //     files: value.test_control_regions.files,
        //   }),
        // },
        region_type: selRegionGranularity,
        type: "National",
      });
    } else {
      updateConfig("region_granularity", {
        ...(config.hardCoded.autoMlIds.includes(activeModel.id)
          ? {
              test_regions: {
                test: testRegions,
                // control: controlRegions,
              },
            }
          : config.hardCoded.rbtAndSuIds.includes(activeModel.id)
          ? {
              test_dark_regions: {
                test: testRegions,
                dark: controlRegions,
              },
            }
          : {
              test_control_regions: {
                test: testRegions,
                control: controlRegions,
              },
            }),
        // test_control_regions: {
        //   test: testRegions,
        //   control: controlRegions,
        // },
        region_type: selRegionGranularity,
        type:
          // selRegionGranularity &&
          selRegionGranularity?.toLowerCase() === "national"
            ? "National"
            : "Regional",
      });
    }
  }, [testRegions, controlRegions, selRegionGranularity]);

  useEffect(() => {
    if (!testRegions?.length) {
      initialTestRegions = initialTestRegions;
    } else {
      initialTestRegions = [...testRegions];
    }
    if (!controlRegions?.length) {
      initialControlRegions = initialControlRegions;
    } else {
      initialControlRegions = [...controlRegions];
    }
    setTestRegions(initialTestRegions);
    setControlRegions(initialControlRegions);
  }, []);

  //update countryRegions data when user updates country
  useEffect(() => {
    const controller = new AbortController();
    if (userInputs.configType === "run" && !userInputs?.is_config_manual) {
      setRegionsData(null);
      setDataReady(false);
      setControlRegionsData(null);
      setTestRegionsData(null);
      setCountryRegions([]);
      setPageReady(false);
    } else {
      if (
        userInputs?.datasetDetailsData?.status === "success" &&
        !userInputs?.is_config_manual
      ) {
        setRegionsData(null);
        setDataReady(false);
        setControlRegionsData(null);
        setTestRegionsData(null);
        if (!loading) {
          setCountryRegions(-1);
          let body = {};
          body.countries = [userInputs.country];
          body.app_id = activeModel.app_id;
          if (userInputs?.configType !== "run") {
            let salesDatasetSelected = modelDataSources.find(
              (item) =>
                item.type === config.hardCoded.datasetSalesType ||
                item.type === config.hardCoded.datasetNeilsenType
            );
            if (salesDatasetSelected) {
              let datasetArray = [];
              datasetArray.push(salesDatasetSelected.id);
              body.dataset_id = datasetArray;
              body.category = [];
              body.brand = [userInputs.brand];
              if (
                salesDatasetSelected.category &&
                salesDatasetSelected.category.length &&
                salesDatasetSelected.category instanceof Array
              ) {
                body.category = salesDatasetSelected.category;
              } else if (typeof salesDatasetSelected.category === "string") {
                body.category.push(salesDatasetSelected.category);
              }
            } else {
              let datasetIdsArray = datasetIds(
                userInputs,
                datasets,
                "sales",
                activeModel.id
              );
              // datasets
              //   .filter((dataset) =>
              //     userInputs?.datasetDetailsData?.data.find(
              //       (el) => el.dataset_id === dataset.id
              //     )
              //   )
              //   .map((dataset) => dataset.id);
              body.dataset_id = [...datasetIdsArray];
              body.category = [];
              body.brand = [userInputs.brand];
            }
          }
          let url = config.api.globalRegionsUrl;
          let status;
          updateConfigUserInputs("globalRegionsStatus", "loading");
          fetch(url, {
            method: "POST",
            signal: controller.signal,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          })
            .then((response) => {
              console.groupCollapsed("requesting", url);
              console.log("REPSONSE -> ", response);
              status = response.status;
              return response.clone().json();
            })
            .then((json) => {
              console.log("JSON -> ", json);
              console.groupEnd();
              if (status === 200) {
                let modifiedData = Object.entries(json.data)
                  .filter(([key, value]) => key !== "country")
                  .map(([key, value]) => {
                    return { id: key, ...value };
                  })
                  .sort((a, b) => {
                    let region_typeA = a.region_type.toLowerCase(),
                      region_typeB = b.region_type.toLowerCase();

                    if (region_typeA < region_typeB) {
                      return -1;
                    }
                    if (region_typeA > region_typeA) {
                      return 1;
                    }
                    return 0;
                  });
                //check the regional region granularity available
                let checkRegionGranularity = modifiedData?.find(
                  (e) => e.region_type === "regional"
                );

                if (activeModel.id === config.hardCoded.autoMlId) {
                  // updateConfigUserInputs("globalRegions", json.data[0].dma);
                  modifiedData = [
                    {
                      id: "National",
                      name: "National",
                      region_type: "national",
                    },
                    ...modifiedData,
                  ].sort((a, b) => {
                    let region_typeA = a.region_type.toLowerCase(),
                      region_typeB = b.region_type.toLowerCase();

                    if (region_typeA < region_typeB) {
                      return -1;
                    }
                    if (region_typeA > region_typeA) {
                      return 1;
                    }
                    return 0;
                  });
                }

                if (
                  userInputs.configType !== "run" &&
                  !userInputs.is_config_manual
                ) {
                  if (
                    modifiedData?.length &&
                    !modifiedData?.find(
                      (e) => e?.region_type === selRegionGranularity
                    )
                  ) {
                    if (
                      checkRegionGranularity &&
                      activeModel.id !== config.hardCoded.autoMlId
                    ) {
                      setSelRegionGranularity("regional");
                    } else {
                      setSelRegionGranularity(modifiedData[0]?.region_type);
                    }
                  } else if (!modifiedData?.length) {
                    setSelRegionGranularity("");
                  }
                }

                updateConfigUserInputs("globalRegions", modifiedData);
                setCountryRegions(modifiedData);
                setPageReady(false);
                const {
                  sub_segment: sales_sub_segment,
                  sub_brand: sales_sub_brand,
                  segment: sales_segment,
                  media_segment,
                  media_sub_brand,
                  media_sub_segment,
                } = salesMediaFilterPayload(userInputs, allData, true);
                // const url = config.api.salesRegionsUrl;
                if (
                  [
                    ...config.hardCoded.autoMlIds,
                    config.hardCoded.salesUpliftId,
                  ].includes(activeModel.id)
                ) {
                  fetchSalesMediaRegions(
                    config.api.salesRegionsUrl,
                    {
                      ...body,
                      sales_sub_brand: sales_sub_brand,
                      sales_segment: sales_segment,
                      sales_sub_segment: sales_sub_segment,
                    },
                    controller,
                    "validSalesRegions",
                    activeModel.id
                  );
                  fetchSalesMediaRegions(
                    config.api.mediaRegionsUrl,
                    {
                      ...body,
                      media_segment: media_segment,
                      media_sub_brand: media_sub_brand,
                      media_sub_segment: media_sub_segment,
                      dataset_id: datasetIds(
                        userInputs,
                        datasets,
                        "media",
                        activeModel.id
                      ),
                    },
                    controller,
                    "validMediaRegions",
                    activeModel.id
                  );
                }
                updateConfigUserInputs("globalRegionsStatus", "success");
              } else {
                updateConfigUserInputs("globalRegionsStatus", "failed");
                updateAlertInfo({
                  open: true,
                  message: json.statusMessage,
                  severity: "error",
                });
              }
            });
        }
      }
    }
    return () => {
      controller.abort();
      updateConfigUserInputs("globalRegionsStatus", "success");
      updateConfigUserInputs("mediaRegions", "success");
      updateConfigUserInputs("salesRegions", "success");
    };
  }, [userInputs?.datasetDetailsData?.status]);

  useEffect(() => {
    if (userInputs?.is_config_manual) {
      setRegionsData(null);
      setDataReady(false);
      setControlRegionsData(null);
      setTestRegionsData(null);
      setCountryRegions([]);
      setPageReady(false);
    }
  }, [userInputs.country, userInputs.brand]);

  //Update regions data if countryRegions changes
  useEffect(() => {
    if (
      !pageReady &&
      regionsData !== -1 &&
      testRegions !== -1 &&
      controlRegions !== -1
    ) {
      let regionsLocalData;
      if (
        countryRegions !== -1 &&
        selRegionGranularity?.toLowerCase() !== "national"
      ) {
        regionsLocalData = getRegionsData(
          countryRegions,
          userInputs.country,
          selRegionGranularity,
          userInputs?.is_config_manual
        );
      }
      if (regionsLocalData) {
        setRegionsData(regionsLocalData);
        setControlRegionsData(
          regionsLocalData.filter((val) => !testRegionsFlat?.includes(val))
        );
        setTestRegionsData(
          regionsLocalData.filter((val) => !controlRegionsFlat?.includes(val))
        );
      } else {
        setRegionsData([]);
      }
      if (userInputs?.configType !== "run" && !userInputs?.is_config_manual) {
        let resultantRegionsFiltered = filterSalesDatasetRegions(
          countryRegions,
          selRegionGranularity,
          activeModelConfig,
          activeModel.id,
          userInputs?.is_config_manual
        );
        updateConfig("region_granularity", { ...resultantRegionsFiltered });
      }
    }
  }, [pageReady, countryRegions, selRegionGranularity]);

  //Empty control and test regions if user changes regionGranType or country
  useEffect(() => {
    if (!pageReady && !userInputs.is_config_manual) {
      setTestRegions(initialTestRegions);
      setControlRegions(initialControlRegions);
    }
  }, [selRegionGranularity, userInputs.country]);

  //Update controlRegions data if user changes test regions
  useEffect(() => {
    if (!pageReady && regionsData !== -1 && testRegions !== -1) {
      setControlRegionsData(
        regionsData?.filter((val) => !testRegionsFlat?.includes(val))
      );
    }
  }, [testRegions]);

  //Update testRegions data if user changes control regions
  useEffect(() => {
    if (!pageReady && regionsData !== -1 && controlRegions !== -1) {
      setTestRegionsData(
        regionsData?.filter((val) => !controlRegionsFlat?.includes(val))
      );
    }
  }, [controlRegions]);

  //all data available then pageReady
  useEffect(() => {
    if (regionsData && testRegions !== -1 && controlRegions !== -1) {
      setDataReady(true);
    }
  }, [regionsData, testRegions, controlRegions]);

  useEffect(() => {
    if (
      !userInputs?.is_config_manual &&
      updateCheckDE &&
      activeModel?.id === config.hardCoded.salesUpliftId &&
      userInputs?.datasetDetailsData?.status === "success"
    ) {
      if (!disableFlag) {
        setTestRegions((pre) => {
          return pre?.map((e) => {
            let checkData = e?.dataset_id?.filter((e) =>
              filterMediaDataset?.find((elm) => elm.id === e)
            );
            let updateData = {
              ...e,
              dataset_id: e?.dataset_id?.filter((e) =>
                filterMediaDataset?.find((elm) => elm.id === e)
              ),
            };
            if (checkData?.length === 0) {
              delete updateData["target_campaigns"];
            }
            return updateData;
          });
        });
      }
    }
  }, [filterMediaDataset?.length, userInputs?.datasetDetailsData?.status]);

  useEffect(() => {
    if (
      userInputs?.is_config_manual &&
      updateCheckDE &&
      activeModel?.id === config.hardCoded.salesUpliftId &&
      userInputs?.datasetDetailsData?.status === "success"
    ) {
      if (!disableFlag) {
        const initialDatasets = allData?.datasets
          .filter(
            (dataset) =>
              dataset.countries.includes(userInputs.country) &&
              dataset.type === "MEDIA"
          )
          .filter((activeDatasetnew) =>
            activeModel?.dataset_ids?.some(
              (datasetOfActiveModel) =>
                datasetOfActiveModel.id === activeDatasetnew.id
            )
          );
        const filterMediaDataset = userInputs.datasetDetailsData.data
          .filter((elm) => initialDatasets.find((e) => e.id === elm.dataset_id))
          .map((e) => ({ name: e.dataset_name, id: e.dataset_id }));
        setTestRegions((pre) => {
          return pre?.map((e) => {
            let checkData = e?.dataset_id?.filter((e) =>
              filterMediaDataset?.find((elm) => elm.id === e)
            );
            let updateData = {
              ...e,
              dataset_id: e?.dataset_id?.filter((e) =>
                filterMediaDataset?.find((elm) => elm.id === e)
              ),
            };
            if (checkData?.length === 0) {
              delete updateData["target_campaigns"];
            }
            return updateData;
          });
        });
      }
    }
  }, [userInputs?.datasetDetailsData?.status]);

  // console.groupCollapsed("Test control CHANGED");
  // console.log("Country Regions -> ", countryRegions);
  // console.log("regionsData -> ", regionsData);
  // console.log("testRegionsData -> ", testRegionsData);
  // console.log("controlRegionsData -> ", controlRegionsData);
  // console.log("testRegions -> ", testRegions);
  // console.log("controlRegions -> ", controlRegions);
  // console.groupEnd();

  return (
    <>
      {!dataReady &&
      !userInputs.is_config_manual &&
      [userInputs.mediaRegions, userInputs.salesRegions].some(
        (el) => el === "loading"
      ) ? (
        <Loader />
      ) : (
        <RegionGranularitySelection
          {...props}
          value={selRegionGranularity}
          onChange={setSelRegionGranularity}
          data={
            userInputs.is_config_manual
              ? userInputs?.configType !== "run"
                ? regionGranularity[activeModel.id].region_granularity
                : regionGranularity[activeModel.id].region_granularity_old
              : userInputs?.configType === "run"
              ? [
                  {
                    id: selRegionGranularity,
                    name: `${
                      selRegionGranularity[0].toUpperCase() +
                      selRegionGranularity.substring(1)
                    }`,
                  },
                ]
              : userInputs?.globalRegions?.filter((e) => e.id !== "country")
          }
        />
      )}
      {userInputs?.is_config_manual ? (
        // selRegionGranularity &&
        selRegionGranularity?.toLowerCase() !== "national" ? (
          dataReady ? (
            <TestControlUploadFile
              {...props}
              regionsData={regionsData}
              setRegionsData={setRegionsData}
              testRegionsData={testRegionsData}
              setTestRegionsData={setTestRegionsData}
              controlRegionsData={controlRegionsData}
              setControlRegionsData={setControlRegionsData}
              testRegions={testRegions}
              controlRegions={controlRegions}
              setTestRegions={setTestRegions}
              setControlRegions={setControlRegions}
              displayData={regionGranularity[activeModel.id].settings}
              customId={activeModel.id}
              disableFlag={disableFlag}
              userInputs={userInputs}
              initialTestRegions={initialTestRegions}
              initialControlRegions={initialControlRegions}
              updateUserInfo={updateUserInfo}
              updateAlertInfo={updateAlertInfo}
              updateConfigUserInputs={updateConfigUserInputs}
              selRegionGranularity={selRegionGranularity}
              updateCheckDE={updateCheckDE}
            />
          ) : (
            <Loader />
          )
        ) : (
          <UploadFilesRegions
            {...props}
            updateConfigUserInputs={updateConfigUserInputs}
            selRegionGranularity={selRegionGranularity}
            index={0}
            updateConfig={updateConfig}
            regionGranularityValue={value}
            updateCheckDE={updateCheckDE}
            value={
              config.hardCoded.autoMlIds.includes(activeModel.id)
                ? value?.test_regions
                : config.hardCoded.rbtAndSuIds.includes(activeModel.id)
                ? value.test_dark_regions
                : value.test_control_regions
            }
          />
        )
      ) : (
        // selRegionGranularity &&
        selRegionGranularity?.toLowerCase() !== "national" &&
        (dataReady &&
        [userInputs.mediaRegions, userInputs.salesRegions].every(
          (el) => el !== "loading"
        ) ? (
          <TestControlSet
            {...props}
            regionsData={regionsData}
            setRegionsData={setRegionsData}
            testRegionsData={testRegionsData}
            setTestRegionsData={setTestRegionsData}
            controlRegionsData={controlRegionsData}
            setControlRegionsData={setControlRegionsData}
            testRegions={testRegions}
            controlRegions={controlRegions}
            setTestRegions={setTestRegions}
            setControlRegions={setControlRegions}
            displayData={regionGranularity[activeModel.id].settings}
            customId={activeModel.id}
            disableFlag={disableFlag}
            userInputs={userInputs}
            initialTestRegions={initialTestRegions}
            initialControlRegions={initialControlRegions}
            updateCheckDE={updateCheckDE}
          />
        ) : (
          <Loader />
        ))
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateConfigUserInputs,
  updateAlertInfo,
  updateSalesMediaRegions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionTestControlContainer);
