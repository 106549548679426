// Import required libraries
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Dialog, Button } from "../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

// Import custom components
import Loader from "../Loader/Loader";
import SidenavBottom from "./SidenavBottom";

// Import styles
import "./Sidenav.scss";

// Import action creators
import {
  updateData,
  updateAlertInfo,
  updateUserInfo,
  updateDialogInfo,
  toggleSidenav,
} from "../../redux/actions";

// Import data & utils
import { makeSidebarData, makeDefaultResponseJson } from "../../utils/utils";
import belowDividerSidenavData from "../../../assets/data/belowDividerSidenavData.json";
import { config } from "../../config/config";

const useStyles = makeStyles(() => ({
  list: {
    width: 500,
  },
  typographyStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    color: "#46596a",
    minWidth: 400,
    maxWidth: 600,
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));

const alertTheme = createMuiTheme({
  palette: {
    success: {
      main: "#f0f2f4",
      contrastText: "#46596a",
    },
    info: {
      main: "#f0f2f4",
      contrastText: "#46596a",
    },
    error: {
      main: "#f0f2f4",
      contrastText: "#46596a",
    },
  },
  "& .MuiAlert-filledSuccess": {
    color: "#46596a",
    //font-weight: 1000,
    backgroundColor: "#f0f2f4",
    width: "800px",
  },
});

function Alert(props) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={alertTheme}>
      <MuiAlert
        className={classes.typographyStyle}
        elevation={6}
        variant="filled"
        {...props}
      />
    </ThemeProvider>
  );
}

function Sidenav(props) {
  const {
    match,
    user,
    allData,
    updateData,
    updateAlertInfo,
    updateUserInfo,
    toggleSidenav,
    isAuthorized,
    adminSetting,
    appAccessDetails,
  } = props;
  const classes = useStyles();
  let app = config.hardCoded.mroiAppId.toLowerCase();
  let activeApp = allData.apps.data
    ? allData.apps.data.find(
        (obj) => obj.name.toLowerCase() === app.toLowerCase()
      )
    : null;
  const [viewEditPermission, setViewEditPermission] = useState({
    view: false,
    edit: false,
  });
  let functionsForSidenav = makeSidebarData();

  const handleClose = () => {
    updateAlertInfo({ ...user.alertInfo, open: false });
  };

  const handleDialogClose = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const handleMenuClose = () => {
    toggleSidenav();
  };

  useEffect(() => {
    if (appAccessDetails) {
      setViewEditPermission({
        view: appAccessDetails?.view_approve_dashboard,
        edit: appAccessDetails?.edit_approve_dashboard,
      });
    }
  }, [
    appAccessDetails?.edit_approve_dashboard,
    appAccessDetails?.view_approve_dashboard,
    appAccessDetails,
  ]);
  return (
    <>
      <aside
        className={`${user.theme} ${
          user.screen.isSidenavBarExpanded
            ? "sidenav-expanded"
            : "sidenav-collapsed"
        }`}
      >
        <nav>
          {!functionsForSidenav ? (
            <Loader />
          ) : functionsForSidenav ? (
            <div>
              {
                <div>
                  <div
                    className="nav-item-menu disabled-style"
                    onClick={handleMenuClose}
                  >
                    <i className={`material-icons-outlined nav-icon-menu`}>
                      reorder
                    </i>
                    <p
                      className={`${
                        user.screen.isSidenavBarExpanded ? "visible" : "hidden"
                      } nav-text-menu`}
                    >
                      Menu
                      {/* <b>Menu</b> */}
                    </p>
                  </div>
                  {isAuthorized &&
                    functionsForSidenav.map((obj) => (
                      <NavLink
                        to={`/home/${obj.name}`}
                        className={`nav-item disabled-style ${
                          obj.specialStyling ? "special" : ""
                        }`}
                        key={obj.key}
                        title={obj.label}
                      >
                        <i className={`${obj.materialUiClassName} nav-icon`}>
                          {obj.materialUiIconName}
                        </i>
                        <p
                          className={`${
                            user.screen.isSidenavBarExpanded
                              ? "visible"
                              : "hidden"
                          } nav-text`}
                        >
                          {obj.label}
                        </p>
                      </NavLink>
                    ))}
                  {
                    <>
                      {(viewEditPermission.edit || viewEditPermission.view) && (
                        <>
                          {isAuthorized && <Divider />}
                          {belowDividerSidenavData.map((row) => {
                            return (
                              <NavLink
                                to={`/${
                                  isAuthorized ? "home" : "runanalysis"
                                }/${row.name}`}
                                className={`nav-item disabled-style`}
                                title={row.label}
                                key={row.name}
                              >
                                <i className="material-icons-outlined nav-icon">
                                  {row.materialUiIconName}
                                </i>
                                <p
                                  className={`${
                                    user.screen.isSidenavBarExpanded
                                      ? "visible"
                                      : "hidden"
                                  } nav-text`}
                                >
                                  {row.label}
                                </p>
                              </NavLink>
                            );
                          })}
                        </>
                      )}
                    </>
                  }
                </div>
              }
            </div>
          ) : (
            <></>
          )}
        </nav>
        <SidenavBottom activeApp={activeApp} />
      </aside>
      <ThemeProvider theme={alertTheme}>
        <Snackbar
          open={user.alertInfo.open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={config.autoHideDuration}
          //onClose={handleClose}
          style={{ maxWidth: "800px", minWidth: "400px" }}
        >
          <Alert
            onClose={handleClose}
            icon={false}
            severity="success"
            style={{
              maxWidth: "800px",
              minWidth: "400px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {user.alertInfo.message.split("\n").map((i, key) => {
              return <div key={key}>{i}</div>;
            })}
          </Alert>
        </Snackbar>
      </ThemeProvider>
      <Dialog
        onClose={handleDialogClose}
        aria-labelledby="simple-dialog-title"
        open={user.dialogInfo.open}
      >
        <div className="dialog-popup-title">
          {user.dialogInfo.message.split("\n").map((i, key) => {
            return <div key={key}>{i}</div>;
          })}
        </div>
        <div className="dialog-popup-btn">
          <Button
            variant="outlined"
            // className={classes.outlined}
            onClick={() => {
              user.dialogInfo.handleNo();
            }}
            style={{
              height: "23px",
              padding: "10px",
              fontSize: "11px",
              minWidth: "15px",
              boxSizing: "border-box",
              marginRight: "4px",
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            // className={classes.contained}
            onClick={() => {
              user.dialogInfo.handleYes();
            }}
            style={{
              height: "23px",
              padding: "10px",
              fontSize: "11px",
              minWidth: "15px",
              boxSizing: "border-box",
              marginRight: "4px",
            }}
          >
            Yes
          </Button>
        </div>
      </Dialog>
    </>
  );
}

Sidenav.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  allData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  appAccessDetails: state?.data?.appAccessDetails?.feature_list,
});

const mapDispatchToProps = {
  updateData,
  updateAlertInfo,
  updateUserInfo,
  toggleSidenav,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
