import Loader from "../../../../components/Loader/Loader";
import { config } from "../../../../config/config";
import { pick } from "lodash";
import MergeDatasetKpiSelection from "./MergeDatasetKpiSelection";
import {
  convertDateForDataSetFormat,
  formatDateForMerged,
  getDatasetId,
  intersect,
} from "../../../../utils/utils";
import {
  clearAll,
  clearAllForMerged,
  selectAllForMerged,
  updateMergeDatasetLocalParameters,
} from "../../../../redux/actions";
import noDataImage from "../../../../../assets/images/undraw_empty.svg";
import LocalParameterItem from "./LocalParameterItem";
import { connect } from "react-redux";
import moment from "moment";

function MergedDatasetKPI(props) {
  const {
    activeModelId,
    activeDatasetId,
    activeModel,
    configUserInputs,
    match,
    setActiveDatasetId,
    disableFlag,
    data,
    activeDatasetForKpi,
    selectAllForMerged,
    clearAllForMerged,
    updateMergeDatasetLocalParameters,
  } = props;

  const activeModelFromConfig = configUserInputs?.models.find(
    (model) => model.id === activeModelId
  );
  const selectedDataset = activeModelFromConfig.data_sources?.find(
    (dataset) => dataset.id === activeDatasetId
  );
  const activeMergedDataset = activeModelFromConfig?.merged_data_sources?.find(
    (dataset) => dataset.id === activeDatasetId
  );
  let localParamsList = data.datasets.find((dataset) =>
    activeMergedDataset?.dataset_list?.includes(dataset.id)
  );
  localParamsList = localParamsList?.local_parameters;
  const mergedKpiList = activeModel?.dataset_ids
    ?.filter((dataset) =>
      activeMergedDataset?.dataset_list?.includes(dataset.id)
    )
    .map((el) => el.kpi_list);
  const mergedKpiVariablesList = activeModel?.dataset_ids
    ?.filter((dataset) =>
      activeMergedDataset?.dataset_list?.includes(dataset.id)
    )
    .map((el) => el.kpi_variables_list);
  const mergedMandatoryKpi = activeModel?.dataset_ids
    ?.filter((dataset) =>
      activeMergedDataset?.dataset_list?.includes(dataset.id)
    )
    .map((el) => el.mandatory_kpi_list);
  const intersectedKpi = intersect(...mergedKpiList);
  const intersectedMandatoryKpi = intersect(...mergedMandatoryKpi);
  const intersectedMergedKpiVariableList = pick(
    mergedKpiVariablesList[0],
    ...intersectedKpi
  );
  let modifiedMergedKpiVariableList = [];

  for (const property in intersectedMergedKpiVariableList) {
    modifiedMergedKpiVariableList.push({
      id: property,
      kpi_parameters: intersectedMergedKpiVariableList[property],
    });
  }
  modifiedMergedKpiVariableList = modifiedMergedKpiVariableList.map((el) => {
    const arr1 = [];
    const params = el.kpi_parameters;
    for (const property in params) {
      arr1.push({ id: property, ...params[property] });
    }

    return { ...el, kpi_parameters: arr1 };
  });
  let dateArr = configUserInputs?.datasetDetailsData?.data?.filter((dataset) =>
    activeMergedDataset?.dataset_list?.includes(dataset.dataset_id)
  );

  dateArr = dateArr?.map((dataset) => {
    return {
      start_date: dataset.start_date,
      end_date: dataset.end_date,
    };
  });
  let epochStart = [];
  let epochEnd = [];
  let start_date = dateArr?.map((dataset) => {
    let dateString = dataset.start_date;
    let date = dateString.substring(0, 2);
    let month = dateString.substring(2, 4);
    let year = dateString.substring(4);
    date = new Date(`${year}-${month}-${date}`);
    let timestamp = date.getTime();
    epochStart.push(timestamp);
    return dataset.start_date;
  });
  let end_date = dateArr?.map((dataset) => {
    let dateString = dataset.end_date;
    let date = dateString.substring(0, 2);
    let month = dateString.substring(2, 4);
    let year = dateString.substring(4);
    date = new Date(`${year}-${month}-${date}`);
    let timestamp = date.getTime();
    epochEnd.push(timestamp);
    return dataset.end_date;
  });
  let mergeDatasetInfo = data.datasets.filter((elem) => {
    return activeMergedDataset?.dataset_list.filter((item) => elem.id === item);
  });

  return (
    <div
      className={`datasets-selections-column right ${
        configUserInputs.configType === "run" ? "disabled-pointer" : ""
      }`}
    >
      {
        <div className="active-dataset-information">
          <div className="end-box">
            <span className="name">{activeMergedDataset?.name}</span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {configUserInputs.datasetDetailsData?.status === "loading" &&
                !disableFlag && <Loader />}
              {configUserInputs.datasetDetailsData?.status === "success" &&
                !disableFlag && (
                  <>
                    <span className="start-date">
                      Start Date:<span>&nbsp;</span>
                      {start_date.length
                        ? formatDateForMerged(Math.min(...epochStart))
                        : " - "}
                    </span>
                    <span className="end-date">
                      End Date:<span>&nbsp;</span>
                      {end_date.length
                        ? formatDateForMerged(Math.max(...epochEnd))
                        : " - "}
                    </span>
                  </>
                )}
            </div>

            {/* {configUserInputs.datasetDetailsData?.status === "error" &&
              !disableFlag && (
                <p className="info-message">
                  {configUserInputs.datasetDetailsData?.message}
                </p>
              )} */}
          </div>
        </div>
      }
      <div className="dataset-selections">
        <div className="datasets-selections-kpi-row">
          <div className="select-kpi-text">
            {<p className="header-text">Select KPIs</p>}

            {
              <div className={`kpi-action-texts header-text`}>
                <p
                  onClick={() => {
                    let dataset_list = [];
                    for (let item of activeMergedDataset.dataset_list) {
                      let dataset_list_found = mergeDatasetInfo.find(
                        (elem) =>
                          elem.id === item &&
                          elem.channel === activeMergedDataset.channel
                      );
                      if (dataset_list_found)
                        dataset_list.push(dataset_list_found);
                    }
                    dataset_list = dataset_list.map((elem) => ({
                      id: elem.id,
                      name: elem.name,
                      pipeline_dataset_id: elem.pipeline_dataset_id,
                      source: elem.source,
                      type: elem.type,
                    }));
                    let data = {
                      modelId: activeModelId,
                      selectedDataset,
                      dataset_list: dataset_list,
                      dataset: selectedDataset
                        ? selectedDataset
                        : {
                            ...activeMergedDataset,
                            local_parameters: localParamsList,
                          },
                      activeModel: activeModel,
                      kpi_variables_list: modifiedMergedKpiVariableList,
                      kpiList: intersectedKpi,
                      typeOfDataset: "MEDIA", // needs to be changed once we have sales/category/macro datasets
                    };
                    selectAllForMerged(data);
                  }}
                >
                  Select All
                </p>
                <p
                  onClick={() => {
                    let data = {
                      modelId: activeModelId,
                      selectedDataset,
                      dataset: selectedDataset,
                      kpiList: [],
                      activeModel: activeModel,
                    };
                    clearAllForMerged(data);
                  }}
                >
                  Clear All
                </p>
              </div>
            }
          </div>
          <div className="kpi-container">
            {configUserInputs?.datasetDetailsData?.status === "success" ||
            configUserInputs.configType === "run" ? (
              <div className="kpi-selections-container">
                <MergeDatasetKpiSelection
                  intersectedKpi={intersectedKpi}
                  intersectedMandatoryKpi={intersectedMandatoryKpi}
                  disableFlag={disableFlag}
                  activeModelId={activeModelId}
                  activeModel={activeModelFromConfig}
                  activeDataset={activeMergedDataset}
                  activeDatasetId={activeDatasetId}
                  activeDatasetForKpi={activeDatasetForKpi}
                  modifiedMergedKpiVariableList={modifiedMergedKpiVariableList}
                  mergeDatasetInfo={mergeDatasetInfo}
                  localParamsList={localParamsList}
                />
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <div className="datasets-selections-local-parameters-item">
          <p className="dataset-parameter-text">Select Dataset Parameters</p>
          <div className="dataset-parameter-container">
            <>
              {localParamsList?.length ? (
                localParamsList?.map((obj) => {
                  let dataset_list = [];
                  for (let item of activeMergedDataset.dataset_list) {
                    let dataset_list_found = mergeDatasetInfo.find(
                      (elem) =>
                        elem.id === item &&
                        elem.channel === activeMergedDataset.channel
                    );
                    if (dataset_list_found)
                      dataset_list.push(dataset_list_found);
                  }
                  dataset_list = dataset_list.map((elem) => ({
                    id: elem.id,
                    name: elem.name,
                    pipeline_dataset_id: elem.pipeline_dataset_id,
                    source: elem.source,
                    type: elem.type,
                  }));
                  let value = selectedDataset
                    ? selectedDataset[obj.id]
                    : undefined;
                  let onChange = (key, value) => {
                    let data = {
                      modelId: activeModelId,
                      selectedDataset,
                      dataset: selectedDataset
                        ? selectedDataset
                        : activeMergedDataset,
                      key,
                      value,
                      dataset_list: dataset_list,
                      typeOfDataset: "MEDIA", // needs to be changed once we have sales/category/macro datasets
                    };
                    updateMergeDatasetLocalParameters(data);
                  };

                  return (
                    <>
                      <div
                        className={`datasets-selections-local-parameters-row `}
                        key={obj.id}
                      >
                        <LocalParameterItem
                          {...obj}
                          value={value}
                          userInputs={configUserInputs}
                          disableFlag={disableFlag}
                          match={match}
                          activeModel={activeModel}
                          activeDataset={activeMergedDataset}
                          activeDatasetId={activeDatasetId}
                          onChange={onChange}
                          selectedDataset={selectedDataset}
                          activeModelId={activeModelId}
                          granularityForMergeDataset={true}
                        />
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="no-data-holder">
                  <img src={noDataImage} />
                  {config.messages.nodatasetParameters}
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
  data: state.data,
  changeFlag: state.changeFlag,
});

const mapDispatchToProps = {
  updateMergeDatasetLocalParameters,
  selectAllForMerged,
  clearAllForMerged,
  clearAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(MergedDatasetKPI);
