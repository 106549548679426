//Import required libraies
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

//Import Custom Component
import {
  addKpi,
  removeKpi,
  toggleKpi,
  updateKpiVariables,
} from "../../../../redux/actions";

// Import custom hooks

// Import action creators

//Import utils
import { getMandatoryKPIToTop } from "../../../../utils/utils";

// Import styles

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "100%",
    height: "30px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    //paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    //textAlign: "center",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      // height: "25px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "8px 12px !important",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
});
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function KpiType(props) {
  let {
    match,
    user,
    activeModel,
    ui_element_type,
    activeModelId,
    kpi,
    activeDatasetId,
    setActiveDatasetId,
    disableFlag,
    toggleDataset,
    activeDatasetForKpi,
    selectedDataset,
    activeDataset,
    addKpi,
    removeKpi,
    toggleKpi,
    activeDatasetDates,
    updateKpiVariables,
  } = props;

  const classes = useStyles();

  let Component;
  switch (ui_element_type) {
    case "radio":
      Component = getMandatoryKPIToTop(
        activeDatasetForKpi.kpi_list,
        activeDatasetForKpi.mandatory_kpi_list
      ).map((value) => {
        let mandatoryKpi =
          activeDatasetForKpi.mandatory_kpi_list.includes(value);
        let checkedFlag =
          selectedDataset && selectedDataset.kpi_list
            ? selectedDataset.kpi_list.includes(value)
            : false;
        return (
          <div
            key={value}
            className={`${disableFlag ? "disabled-pointer" : ""}`}
          >
            <div
              key={value}
              className={`kpi-checkbox-container ${
                disableFlag ? "disabled-pointer" : ""
              } 
                            ${mandatoryKpi ? "mandatory-kpi" : ""}
                            `}
              onClick={() => {
                let data = {
                  modelId: activeModelId,
                  selectedDataset,
                  dataset: selectedDataset ? selectedDataset : activeDataset,
                  kpi: value,
                  activeModel: activeModel,
                  activeDatasetDates: activeDatasetDates,
                };
                toggleKpi(data);
                // checkedFlag ? removeKpi(data) : addKpi(data);
              }}
            >
              <i
                className={`kpi-checkbox-icon
                              ${
                                mandatoryKpi
                                  ? "material-icons active"
                                  : checkedFlag
                                  ? "material-icons active"
                                  : "material-icons-outlined"
                              }
                              `}
              >
                {mandatoryKpi
                  ? "check_circle"
                  : checkedFlag
                  ? "check_circle"
                  : "check_circle_outline"}
              </i>
              <label>{value}</label>
            </div>
          </div>
        );
      });
      break;
    case "multi":
      Component = getMandatoryKPIToTop(
        activeDatasetForKpi.kpi_list,
        activeDatasetForKpi.mandatory_kpi_list
      ).map((value) => {
        let mandatoryKpi =
          activeDatasetForKpi.mandatory_kpi_list.includes(value);
        let checkedFlag = selectedDataset
          ? selectedDataset.kpi_list.includes(value)
          : false;
        return (
          <div key={value}>
            <div
              key={value}
              className={`kpi-checkbox-container ${
                disableFlag ? "disabled-pointer" : ""
              } 
              ${mandatoryKpi ? "mandatory-kpi" : ""}
                                `}
              onClick={() => {
                let notSelectedKpiVariableList =
                  activeDatasetForKpi.kpi_variables_list.filter(
                    (item) => item.id === value
                  );
                let newKpiVariableList = [...notSelectedKpiVariableList];
                if (selectedDataset?.kpi_variables_list) {
                  newKpiVariableList = [
                    ...newKpiVariableList,
                    ...selectedDataset?.kpi_variables_list,
                  ];
                }
                let data = {
                  modelId: activeModelId,
                  selectedDataset,
                  dataset: selectedDataset ? selectedDataset : activeDataset,
                  kpi: value,
                  activeModel: activeModel,
                  kpi_variables_list: newKpiVariableList,
                };
                checkedFlag ? removeKpi(data) : addKpi(data);
              }}
            >
              <i
                className={`kpi-checkbox-icon
                ${
                  checkedFlag
                    ? "material-icons active"
                    : "material-icons-outlined"
                }`}
              >
                {checkedFlag ? "check_box" : "check_box_outline_blank"}
              </i>
              <label style={{ color: "#46596a" }}>
                {value}
                {mandatoryKpi && (
                  <span
                    style={{
                      marginLeft: "4px",
                      backgroundColor: "#4eafb3",
                      color: "white",
                      fontSize: "8px",
                      padding: "1px 4px",
                      borderRadius: "10px",
                      fontWeight: "700",
                    }}
                  >
                    Mandatory
                  </span>
                )}
              </label>
            </div>
            {selectedDataset?.kpi_list?.length ? (
              activeDatasetForKpi.kpi_variables_list
                .filter((data) => data.id === value)
                .map((elem) => {
                  return (
                    <div key={elem.id} className="kpi-parameters-container">
                      {selectedDataset?.kpi_list.includes(elem.id) ? (
                        <div className="kpi-parameters">
                          {elem.kpi_parameters.map((data) => (
                            <div
                              key={data.id}
                              className={`${
                                disableFlag ? "disabled-pointer" : ""
                              }`}
                            >
                              <label>{data.id}:</label>
                              <Select
                                className={classes.multiSelect}
                                MenuProps={MenuProps}
                                value={
                                  typeof selectedDataset?.kpi_variables_list?.find(
                                    (item) => item.id === elem.id
                                  )?.[data.id] !== "undefined" &&
                                  selectedDataset?.kpi_variables_list?.find(
                                    (item) => item.id === elem.id
                                  )?.[data.id] !== ""
                                    ? selectedDataset?.kpi_variables_list?.find(
                                        (item) => item.id === elem.id
                                      )?.[data.id]
                                    : data.default === "" ||
                                      selectedDataset?.kpi_variables_list?.find(
                                        (item) => item.id === elem.id
                                      )?.[data.id] === ""
                                    ? "(blank)"
                                    : `${data.default}`
                                }
                                onChange={(e) => {
                                  let kpiData = {
                                    dataset: selectedDataset
                                      ? selectedDataset
                                      : activeDataset,
                                    modelId: activeModelId,
                                    key: data.id,
                                    value:
                                      e.target.value === "(blank)"
                                        ? ""
                                        : e.target.value,
                                    kpi: elem.id,
                                    kpi_variables_list:
                                      selectedDataset?.kpi_variables_list
                                        ? selectedDataset?.kpi_variables_list
                                        : activeDatasetForKpi.kpi_variables_list,
                                  };
                                  updateKpiVariables(kpiData);
                                }}
                              >
                                {data.possible_values.map((obj, index) => (
                                  <MenuItem
                                    key={index}
                                    value={obj === "" ? "(blank)" : obj}
                                    className={classes.singleMenuItem}
                                  >
                                    {obj === "" ? "(blank)" : `${obj}`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <> </>
                      )}
                    </div>
                  );
                })
            ) : (
              <div></div>
            )}
          </div>
        );
      });
      break;
    default:
      return null;
  }

  return Component;
}

KpiType.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  addKpi,
  removeKpi,
  toggleKpi,
  updateKpiVariables,
};

export default connect(mapStateToProps, mapDispatchToProps)(KpiType);
