// Import required libraries
import React, { useEffect } from "react";
import useState from "react-usestateref";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import capitalize from "lodash.capitalize";
import {
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import PopOver from "../../components/PopOver/PopOver";
import TimeAgo from "react-timeago";
import { useHistory } from "react-router-dom";
import { config as appConfig } from "../../config/config";

// Import data & utils
import useTable from "../../utils/useTable";
import {
  flattenObject,
  formatDate,
  formatDateForMTADataRange,
  timeDifference,
  makeDefaultResponseJson,
} from "../../utils/utils";
import config from "../../../assets/images/run_normal.svg";

// Import action creators
import {
  updateMultiSelections,
  updateAlertInfo,
  updateData,
  updateUserInfo,
} from "../../redux/actions";

//Import styles
import "./Table.scss";

const StyledTableCell = withStyles((theme) => ({
  body: {
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    borderBottom: "0px",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  listIcon: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    maxWidth: "500px",
    minWidth: "100px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    //color: "#46596a",
  },
  tableRow: {
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "white !important",
      "&:hover": {
        backgroundColor: "#4eafb342 !important",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(70, 89, 106, 0.07) !important",
      "&:hover": {
        backgroundColor: "#4eafb342 !important",
      },
    },
  },
  starLoading: {
    marginRight: "5px",
    cursor: "pointer",
  },
}));
function Table(props) {
  let {
    user,
    data,
    paginationFlag,
    match,
    updateMultiSelections,
    updateAlertInfo,
    allData,
    updateData,
    updateUserInfo,
  } = props;
  let app = appConfig.hardCoded.mroiAppId.toLowerCase();
  let activeApp = allData.apps.data
    ? allData.apps.data.find(
        (obj) => obj.name.toLowerCase() === app.toLowerCase()
      )
    : null;
  let headCells = [];

  if (match.params.tab === "sharedwithme") {
    headCells = [
      { id: "name", label: "Name" },
      { id: "owned_by", label: "Owned By" },
      { id: "created_on", label: "Created On" },
      { id: "modified_on", label: "Last Modified On" },
      { id: "total_runs", label: "Total Runs" },
      { id: "", label: "" },
    ];
  } else {
    headCells = [
      { id: "name", label: "Name" },
      { id: "created_on", label: "Created On" },
      { id: "modified_on", label: "Last Modified On" },
      { id: "total_runs", label: "Total Runs" },
      { id: "last_successful_run", label: "Last Successful Run" },
      { id: "", label: "" },
    ];
  }

  const classes = useStyles();
  const [records, setRecords] = useState(data.map((row) => flattenObject(row)));
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [configStarLoading, setConfigStarLoading, configStarLoadingRef] =
    useState([]);
  const [modalComponent, setModalComponent] = useState({
    component: null,
    openModal: false,
    handleCancel: () => {
      console.log("handleCancel");
    },
    handleDone: () => {
      console.log("handleDone");
    },
    brand: "",
  });

  const fullScreen = {
    position: "fixed",
    top: "50px",
    left: "0",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "calc(100vh - 75px)",
    border: "none",
    margin: "0",
    padding: "0",
    overflow: "hidden",
    zIndex: "99",
  };

  const handleDialogOpen = (brand, component) => {
    setModalComponent((prevState) => {
      return {
        ...prevState,
        openModal: true,
        component: component,
        brand: brand,
      };
    });
  };

  const handleDialogClose = () => {
    setModalComponent((prevState) => {
      return { ...prevState, openModal: false, brand: "" };
    });
  };
  const history = useHistory();

  useEffect(() => {
    setRecords(data.map((row) => flattenObject(row)));
  }, [data]);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn, user, updateMultiSelections);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getIframeUrl = (brand) => {
    let activeModel = allData?.models?.find((model) => model.id === "MTA");
    let iframeUrl =
      activeModel?.powerBiReportForModel?.iframeUrlForRun +
      "&filter=" +
      activeModel?.powerBiReportForModel?.filterNameAndCond +
      " '" +
      capitalize(brand?.toLowerCase()) +
      "'";
    return iframeUrl;
  };

  const updateSelectionsAndStarred = (configId, configToBeStarred) => {
    // calling the APi here
    let url = `${appConfig.api.bookmarkUrl}`;
    let body = {
      app_id: configToBeStarred.app_id,
      id: configId,
      type: "config",
    };
    let status;
    let favLoadingArr = [...configStarLoading];
    favLoadingArr.push(configId);
    setConfigStarLoading([...favLoadingArr]);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        if (status === 200) {
          if (match.params.tab === "sharedwithme") {
            let allSharedConfigurations = allData.shared_configurations;
            let allSharedConfigurationsObject = allSharedConfigurations.find(
              (obj) => obj.id === configId
            );
            if (allSharedConfigurationsObject) {
              allSharedConfigurationsObject.metadata.shared_with.find(
                (singleUsr) => {
                  if (
                    singleUsr.preferred_username === user.preferred_username
                  ) {
                    singleUsr.isStarred = !singleUsr.isStarred;
                  }
                }
              );
            }
            updateData("shared_configurations", allSharedConfigurations);
          } else if (match.params.tab === "starred") {
            let allStarredConfigurations = allData.starred_configurations.data;
            updateData("starred_configurations", {
              status: "loading",
              message: "",
              data: allStarredConfigurations,
            });
            allStarredConfigurations.splice(
              allStarredConfigurations.findIndex(
                (delObj) => delObj.id === configId
              ),
              1
            );
            updateData("starred_configurations", {
              status: "success",
              message: "",
              data: allStarredConfigurations,
            });
          } else {
            let allSavedConfigurations = allData.saved_configurations;
            let allSavedConfigurationsObject = allSavedConfigurations.find(
              (obj) => obj.id === configId
            );
            if (allSavedConfigurationsObject) {
              allSavedConfigurationsObject.metadata.isStarred =
                !allSavedConfigurationsObject.metadata.isStarred;
            }
            updateData("saved_configurations", allSavedConfigurations);
          }
          let favLoadingArr = [...configStarLoadingRef.current];
          favLoadingArr = favLoadingArr.filter((item) => item !== configId);
          setConfigStarLoading([...favLoadingArr]);
        } else {
          updateAlertInfo({
            open: true,
            message:
              "Configuration " +
              configToBeStarred.name +
              "cannot be starred. Please try again Later",
            severity: "info",
          });
          let favLoadingArr = [...configStarLoadingRef.current];
          favLoadingArr = favLoadingArr.filter((item) => item !== configId);
          setConfigStarLoading([...favLoadingArr]);
        }
      });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const navigateToDetails = ({ openRecordError, name }) => {
    if (openRecordError && openRecordError.trim()) {
      updateAlertInfo({
        open: true,
        message: openRecordError,
        severity: "info",
      });
      return;
    }
    history.push(`/home/${match.params.tab}/${name}`);
  };

  return (
    <>
      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingAndSorting().map((item) => (
            <TableRow key={item.id} className={classes.tableRow} hover>
              <StyledTableCell
                className={classes.name}
                onClick={() => navigateToDetails(item)}
              >
                <span className={classes.listIcon}>
                  {item.hasOwnProperty("schedule_details") ? (
                    <i className="material-icons-outlined scheduled-config-icon">
                      alarm_on
                    </i>
                  ) : (
                    <img src={config} className="rows-icons-style"></img>
                  )}
                  {configStarLoading.includes(item.id) ? (
                    <div className={classes.starLoading}>
                      <CircularProgress color="secondary" size="1rem" />
                    </div>
                  ) : (
                    <>
                      {match.params.tab === "sharedwithme" ? (
                        <>
                          <i
                            className="material-icons-outlined favourite-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              updateSelectionsAndStarred(item.id, item);
                            }}
                          >
                            {item?.shared_with?.find((singleUser) => {
                              return (
                                singleUser.preferred_username ===
                                user.preferred_username
                              );
                            })?.isStarred
                              ? "star"
                              : "star_border"}
                          </i>
                        </>
                      ) : match.params.tab === "starred" ? (
                        <>
                          {item?.isShared ? (
                            <>
                              <>
                                <i
                                  className="material-icons-outlined favourite-icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateSelectionsAndStarred(item.id, item);
                                  }}
                                >
                                  {item?.shared_with?.find((singleUser) => {
                                    return (
                                      singleUser.preferred_username ===
                                      user.preferred_username
                                    );
                                  })?.isStarred
                                    ? "star"
                                    : "star_border"}
                                </i>
                              </>
                            </>
                          ) : (
                            <>
                              <i
                                className="material-icons-outlined favourite-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateSelectionsAndStarred(item.id, item);
                                }}
                              >
                                {item?.isStarred ? "star" : "star_border"}
                              </i>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <i
                            className="material-icons-outlined favourite-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              updateSelectionsAndStarred(item.id, item);
                            }}
                          >
                            {item?.isStarred ? "star" : "star_border"}
                          </i>
                        </>
                      )}
                    </>
                  )}
                  <div className="config-name" title={item.name}>
                    {item.name}
                  </div>
                </span>
              </StyledTableCell>
              {match.params.tab === "sharedwithme" && (
                <StyledTableCell onClick={() => navigateToDetails(item)}>
                  {item.owned_by}
                </StyledTableCell>
              )}
              <StyledTableCell onClick={() => navigateToDetails(item)}>
                {timeDifference(item.created_on) < 7 ? (
                  <TimeAgo date={new Date(item.created_on)} />
                ) : (
                  formatDate(item.created_on)
                )}
              </StyledTableCell>

              <StyledTableCell onClick={() => navigateToDetails(item)}>
                {timeDifference(item.modified_on) < 7 ? (
                  <TimeAgo date={new Date(item.modified_on)} />
                ) : (
                  formatDate(item.modified_on)
                )}
              </StyledTableCell>

              <StyledTableCell onClick={() => navigateToDetails(item)}>
                {item.total_runs}
              </StyledTableCell>
              {match.params.tab !== "sharedwithme" && (
                <StyledTableCell onClick={() => navigateToDetails(item)}>
                  {item?.last_successful_run ? (
                    timeDifference(item.last_successful_run) < 7 ? (
                      <TimeAgo date={new Date(item?.last_successful_run)} />
                    ) : (
                      formatDate(item.last_successful_run)
                    )
                  ) : (
                    "-"
                  )}
                </StyledTableCell>
              )}
              <StyledTableCell>
                <PopOver
                  user={user}
                  match={match}
                  configName={item.name}
                  config={item}
                />
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
        {modalComponent.openModal ? (
          <div className="power-bi-container-for-MTA">
            <div className={`${"power-bi-container-new"}`}>
              <iframe
                width="100%"
                height="100%"
                src={getIframeUrl(modalComponent.brand)}
                frameBorder="0"
                allowFullScreen={true}
                style={fullScreen}
              ></iframe>
              <button
                style={{
                  position: "absolute",
                  float: "right",
                  right: "10px",
                  bottom: "30px",
                  zIndex: "100",
                }}
                title={"Collapse Screen"}
                onClick={(e) => handleDialogClose()}
              >
                <span className="material-icons-outlined">
                  {"fullscreen_exit"}
                </span>
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </TblContainer>
      {paginationFlag && <TblPagination />}
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  paginationFlag: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateMultiSelections,
  updateAlertInfo,
  updateData,
  updateUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
