//Import required libraies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import {
  updateAlertInfo,
  updateConfigUserInputs,
} from "../../../../redux/actions";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles
import "./SingleTreeSelect.scss";
import { config } from "../../../../config/config";

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px !important",
    fontWeight: "100",
    fontFamily: "Hind Siliguri !important",
    minWidth: "180px",
    maxWidth: "180px",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a !important",
    backgroundColor: "white",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px !important",
    },
  },
  menuItem: {
    fontSize: "12px !important",
    fontFamily: "Hind Siliguri !important",
    padding: "0px !important",
  },
  singleMenuItem: {
    fontSize: "13px !important",
    fontFamily: "Hind Siliguri !important",
    padding: "8px 12px !important",
    color: "#46596a !important",
    // width: "auto",
    width: "170px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    '&[aria-selected="true"]': {
      color: `#46596a !important`,
      backgroundColor: `rgba(0, 0, 0, 0.08) !important`,
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      textDecoration: "none",
    },
  },
  listItem: {
    "& span.MuiTypography-body1": {
      fontSize: "12px",
      color: "#46596a",
      fontFamily: "Hind Siliguri",
      paddingRight: "5px",
    },
  },
  popover: {
    pointerEvents: "none",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    width: "800px",
    margin: "10px",
  },
  typographyHeader: {
    fontSize: 12,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 5,
    backgroundColor: "#ebeff1",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function SingleDropDown(props) {
  let {
    data,
    userInputs,
    setUserInputs,
    disableFlag,
    updateAlertInfo,
    globalParamsOnChange,
    globalParamsObj,
    updateConfigUserInputs,
  } = props;
  const [showError, setShowError] = useState(true);

  let title = "country",
    key = "country";
  if (data.length && data[0].labelCat) {
    title = data[0].labelCat;
    key = data[0].labelCat;
  } else {
    if (showError) {
      setShowError(false);
      updateAlertInfo({
        open: true,
        message: "Permission error in fetching countries list!",
        severity: "info",
      });
    }
  }
  let optionsData = data.filter(
    (obj) =>
      obj["name"] ===
      (userInputs.app_id === config.hardCoded.mroiAppId &&
      !userInputs.is_config_manual
        ? globalParamsObj[key]
        : userInputs[key])
  );

  const classes = useStyles();

  if (
    data.length > 0 &&
    optionsData.length === 0
    // &&
    // !userInputs.is_config_manual
  ) {
    let defaultVal = data[0]["name"];
    if (
      userInputs.app_id === config.hardCoded.mroiAppId &&
      !userInputs.is_config_manual
    ) {
      globalParamsOnChange(key, defaultVal);
    } else {
      // setUserInputs(key, defaultVal);
      if (userInputs.configType === "add") {
        updateConfigUserInputs(key, defaultVal);
      }
    }
  }

  let childrenFlag = optionsData.length
    ? optionsData[0].children && optionsData[0].children.length
      ? true
      : false
    : false;

  // this flag indicates for Manual runs brand should not be dispalyed
  const displayRecordFlag =
    userInputs.is_config_manual && key === "brand" ? true : false;

  //to update the country if there is a difference in selected country and available countries
  useEffect(() => {
    let countryFlag = false;
    data.map((country) => {
      if (country.name === userInputs.country) {
        countryFlag = true;
      }
    });
    if (!countryFlag && key === "country" && userInputs.is_config_manual) {
      setUserInputs(key, data[0].name);
    }
  }, [userInputs.is_config_manual]);

  return (
    <>
      {/* {!displayRecordFlag && ( */}
      <div className={`input-item ${disableFlag ? "disabled-pointer" : ""}`}>
        <label>Select {title}</label>
        <Select
          value={
            userInputs.app_id === config.hardCoded.mroiAppId &&
            !userInputs.is_config_manual
              ? globalParamsObj[key]
                ? globalParamsObj[key]
                : ""
              : userInputs[key]
              ? userInputs[key]
              : ""
          }
          onChange={(e) => {
            if (
              userInputs.app_id === config.hardCoded.mroiAppId &&
              !userInputs.is_config_manual
            ) {
              globalParamsOnChange(key, e.target.value);
            } else {
              // setUserInputs(key, e.target.value);
              const filterData = data.filter(
                (elm) => elm.name === e.target.value
              );
              // updateConfigUserInputs(key, e.target.value);
              if (
                key === "country" &&
                userInputs.configType === "edit" &&
                userInputs.is_config_manual
              ) {
                if (
                  userInputs.brand !== "" &&
                  !filterData[0].children.find(
                    (e) => e.name === userInputs.brand
                  )
                ) {
                  let defaultVal = filterData[0].children[0]["name"];
                  updateConfigUserInputs(key, e.target.value);
                  updateConfigUserInputs("brand", defaultVal);
                } else {
                  updateConfigUserInputs(key, e.target.value);
                }
              } else {
                updateConfigUserInputs(key, e.target.value);
              }
            }
          }}
          className={classes.multiSelect}
          MenuProps={MenuProps}
        >
          {data.map((obj) => (
            <MenuItem
              value={obj.name}
              key={obj.name}
              className={classes.singleMenuItem}
            >
              {obj.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      {/* )} */}
      {childrenFlag && (
        <SingleDropDown
          data={optionsData[0].children}
          userInputs={userInputs}
          setUserInputs={setUserInputs}
          disableFlag={disableFlag}
          updateAlertInfo={updateAlertInfo}
          globalParamsOnChange={globalParamsOnChange}
          globalParamsObj={globalParamsObj}
          updateConfigUserInputs={updateConfigUserInputs}
        />
      )}
    </>
  );
}

function SingleTreeSelect(props) {
  let {
    user,
    data,
    userInputs,
    setUserInputs,
    disableFlag,
    updateAlertInfo,
    globalParamsOnChange,
    globalParamsObj,
    updateConfigUserInputs,
  } = props;

  return (
    <SingleDropDown
      data={data}
      userInputs={userInputs}
      setUserInputs={setUserInputs}
      disableFlag={disableFlag}
      updateAlertInfo={updateAlertInfo}
      globalParamsOnChange={globalParamsOnChange}
      globalParamsObj={globalParamsObj}
      updateConfigUserInputs={updateConfigUserInputs}
    />
  );
}

SingleTreeSelect.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateAlertInfo,
  updateConfigUserInputs,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleTreeSelect);
