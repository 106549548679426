//Import required libraies
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Chip from "@material-ui/core/Chip";
import { Chip, Popover } from "../../../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";
// import { Popover } from "../uiTheme";

//Import Custom Component
import TestControlFormButton from "./TestControlFormButton";

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data
import { config } from "../../../../config/config";

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    color: "#46596a",
    width: 400,
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
    },
  },
  chipStyle: {
    marginTop: "0px",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    border: "1px solid #dedede",
    color: "#46596a !important",
    height: "25px",
    marginRight: "5px",
    background: "white",
    "& .MuiChip-deleteIcon": {
      width: "16px",
    },
  },
  popover: {
    pointerEvents: "none",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    width: "800px",
    margin: "10px",
  },
}));

function TestControlDisplayRow(props) {
  let { id, name, data, type, index } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <span
      aria-owns={open ? id : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Chip
        label={type + "-" + parseFloat(index + 1)}
        // className={classes.chipStyle}
        style={{ margin: "0px 5px" }}
      />
      <Popover
        id={id}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="popover-content">
          {data.map((value) => (
            <p key={value}>{value}</p>
          ))}
        </div>
      </Popover>
    </span>
  );
}

function TestControlChip(props) {
  let {
    id,
    name,
    data,
    type,
    index,
    configUserInputs,
    updateConfigUserInputs,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  let testRegions = configUserInputs["testRegions"];
  let controlRegions = configUserInputs["controlRegions"];

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (index, type) => {
    let newTestDataLocal;
    if (type === "test") {
      newTestDataLocal = testRegions.filter(
        (testDataLocalRow) => testDataLocalRow.id !== id
      );
      updateConfigUserInputs("testRegions", newTestDataLocal);
    }
    if (type === "control") {
      newTestDataLocal = controlRegions.filter(
        (testDataLocalRow) => testDataLocalRow.id !== id
      );
      updateConfigUserInputs("controlRegions", newTestDataLocal);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <span
      aria-owns={open ? id : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Chip
        label={type + "-" + parseFloat(index + 1)}
        // className={classes.chipStyle}
        onDelete={() => handleDelete(index, type)}
        style={{ margin: "0px 5px" }}
      />
      <Popover
        id={id}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="popover-content">
          {data.map((value) => (
            <p key={value}>{value}</p>
          ))}
        </div>
      </Popover>
    </span>
  );
}

function TestControlRegions(props) {
  let { disableFlag, configUserInputs, updateConfigUserInputs } = props;

  let testRegions = configUserInputs["testRegions"];
  let controlRegions = configUserInputs["controlRegions"];
  let regionsData = configUserInputs["regionsData"];

  return (
    <div className="multiple-test-control-container">
      <div className="test-control-row">
        <div className="test-control">
          <p className="title">Test:</p>
          <div className="multi-select-container">
            {disableFlag ? (
              testRegions.map((row, index) => (
                <TestControlDisplayRow key={row.id} {...row} index={index} />
              ))
            ) : testRegions.length ? (
              testRegions.map((row, index) => (
                <TestControlChip
                  key={row.id}
                  {...row}
                  index={index}
                  configUserInputs={configUserInputs}
                  updateConfigUserInputs={updateConfigUserInputs}
                />
              ))
            ) : (
              <p>{config.messages.noRegionsAvailable}</p>
            )}
          </div>
        </div>
        <div className="test-control">
          <p className="title">Control:</p>
          <div className="multi-select-container">
            {disableFlag ? (
              controlRegions.map((row, index) => (
                <TestControlDisplayRow key={row.id} {...row} index={index} />
              ))
            ) : controlRegions.length ? (
              controlRegions.map((row, index) => (
                <TestControlChip
                  key={row.id}
                  {...row}
                  index={index}
                  configUserInputs={configUserInputs}
                  updateConfigUserInputs={updateConfigUserInputs}
                />
              ))
            ) : (
              <p>{config.messages.noRegionsAvailable}</p>
            )}
          </div>
        </div>
      </div>
      <div className="test-control-button">
        {!disableFlag && (
          <TestControlFormButton
            regionsData={regionsData}
            testRegions={testRegions}
            controlRegions={controlRegions}
            updateConfigUserInputs={updateConfigUserInputs}
          />
        )}
      </div>
    </div>
  );
}

export default TestControlRegions;
