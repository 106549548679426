//Import required libraies
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";
import orderBy from "lodash/orderBy";

//Import Custom Component
import MultiSelect from "./MultiSelect";

// Import custom hooks

// Import action creators

// Import styles
import "./TestControlForm.scss";

// Import utils

//Import data

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    color: "#46596a",
    width: 400,
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
    },
  },
  contained: {
    color: "#46596a",
    backgroundColor: "transparent",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: "12px",
    height: 23,
    minWidth: 15,
    border: "1px solid #46596a",
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f1f1f1",
      boxShadow: "none",
    },
  },
  outlined: {
    borderColor: "#46596a",
    marginRight: 4,
    fontSize: 11,
    //padding: 10,
    padding: "0px 5px",
    height: "23px",
    minWidth: "auto",
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  chipStyle: {
    marginTop: "20px",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    border: "1px solid #dedede",
    color: "#46596a !important",
    height: "25px",
    marginRight: "5px",
    background: "white",
    "& .MuiChip-deleteIcon": {
      width: "16px",
    },
  },
}));

function TestControlForm(props) {
  let {
    regionsData,
    testRegions,
    controlRegions,
    handleFormClose,
    updateConfigUserInputs,
  } = props;
  const classes = useStyles();

  //Adding empty row if test/control regions is empty
  let initialTestRegions, initialControlRegions;
  if (!testRegions.length) {
    initialTestRegions = [
      {
        id: v4(),
        data: [],
        type: "test",
      },
    ];
  } else {
    initialTestRegions = [...testRegions];
  }
  if (!controlRegions.length) {
    initialControlRegions = [
      {
        id: v4(),
        data: [],
        type: "control",
      },
    ];
  } else {
    initialControlRegions = [...controlRegions];
  }

  const [testDataLocal, setTestDataLocal] = useState(initialTestRegions);
  const [controlDataLocal, setControlDataLocal] = useState(
    initialControlRegions
  );
  const [regionsDataLocal, setRegionsDataLocal] = useState(regionsData);
  const [error, setError] = useState(null);

  // console.groupCollapsed("Test control CHANGED");
  // console.log("regionsDataGlobal -> ", regionsData);
  // console.log("testDataGlobal -> ", testRegions);
  // console.log("controlDataGlobal -> ", controlRegions);
  // console.log("regionsDataLocal -> ", regionsDataLocal);
  // console.log("testDataLocal ->", testDataLocal);
  // console.log("controlDataLocal -> ", controlDataLocal);
  // console.groupEnd();

  //update regionsDataLocal if testDataLocal or controlDataLocal changes
  useEffect(() => {
    regionsData = regionsData;
    let allTestSelectedRegions = testDataLocal.map(
      (testDataLocalRow) => testDataLocalRow.data
    );
    let allControlSelectedRegions = controlDataLocal.map(
      (controlDataLocalRow) => controlDataLocalRow.data
    );
    let allSelectedRegions = [
      ...allTestSelectedRegions.flat(),
      ...allControlSelectedRegions.flat(),
    ];
    let newRegionsDataLocal = regionsData.filter(
      (region) => !allSelectedRegions.includes(region)
    );
    setRegionsDataLocal(newRegionsDataLocal);
  }, [testDataLocal, controlDataLocal]);

  //Add empty test row
  const addTestLocalRow = () => {
    if (testDataLocal[testDataLocal.length - 1].data.length > 0) {
      setError(null);
      let newTestData = {
        id: v4(),
        data: [],
        type: "test",
      };
      setTestDataLocal([...testDataLocal, newTestData]);
    } else {
      setError("Please select at least one test set");
    }
  };

  //Add empty control row
  const addControlLocalRow = () => {
    if (controlDataLocal[controlDataLocal.length - 1].data.length > 0) {
      setError(null);
      let newControlData = {
        id: v4(),
        data: [],
        type: "control",
      };
      setControlDataLocal([...controlDataLocal, newControlData]);
    } else {
      setError("Please select at least one control set");
    }
  };

  //update configUserInputs
  const addTestControlToConfigUserInputs = () => {
    let validTestDataLocal = testDataLocal.filter((row) => row.data.length);
    let validControlDataLocal = controlDataLocal.filter(
      (row) => row.data.length
    );
    if (!validTestDataLocal.length) {
      setError("Please select at least one test set");
    } else if (!validControlDataLocal.length) {
      setError("Please select at least one control set");
    } else {
      handleFormClose();
      updateConfigUserInputs("testRegions", validTestDataLocal);
      updateConfigUserInputs("controlRegions", validControlDataLocal);
    }
  };

  return (
    <div className="test-control-form">
      <p className="popup-title">Test and Region</p>
      <div className="multi-test-control-container-row">
        <div className="test-control">
          <p className="title">Test:</p>
          {testDataLocal.map((testDataRow, index) => {
            let data = orderBy([...regionsDataLocal, ...testDataRow.data]);
            let onChange = (value) => {
              let newTestDataLocal;
              if (value.length) {
                newTestDataLocal = testDataLocal.map((testDataLocalRow) =>
                  testDataLocalRow.id !== testDataRow.id
                    ? testDataLocalRow
                    : {
                        ...testDataLocalRow,
                        data: value,
                      }
                );
              } else {
                newTestDataLocal = testDataLocal.filter(
                  (testDataLocalRow) => testDataLocalRow.id !== testDataRow.id
                );
              }
              setTestDataLocal(newTestDataLocal);
            };
            let handleDelete = () => {
              if (
                testDataLocal[index].data.length > 0 &&
                testDataLocal.length > 1
              ) {
                let newTestDataLocal = testDataLocal.filter(
                  (testDataLocalRow) => testDataLocalRow.id !== testDataRow.id
                );
                setTestDataLocal(newTestDataLocal);
              } else {
                setError("Cannot delete last test selection");
              }
            };
            return (
              <div
                className="multi-test-control-container"
                key={testDataRow.id}
              >
                <div className="autocomplete-input">
                  <MultiSelect
                    name={testDataRow.id}
                    data={data}
                    value={testDataRow.data}
                    onChange={onChange}
                  />
                </div>
                {/* {testDataRow.data.length > 0 &&
                  index !== testDataLocal.length - 1 && ( */}
                <div className="delete-button-div">
                  <Button
                    variant="outlined"
                    className={classes.outlined}
                    onClick={() => {
                      handleDelete();
                    }}
                  >
                    Delete
                  </Button>
                </div>
                {/* )} */}
                {index === testDataLocal.length - 1 && (
                  <div className="popup-btn-test-control">
                    <Button
                      variant="contained"
                      className={classes.contained}
                      onClick={() => {
                        addTestLocalRow();
                      }}
                    >
                      Add Set
                    </Button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="test-control">
          <p className="title">Control:</p>
          {controlDataLocal.map((controlDataRow, index) => {
            let data = orderBy([...regionsDataLocal, ...controlDataRow.data]);
            let onChange = (value) => {
              let newControlDataLocal;
              if (value.length) {
                newControlDataLocal = controlDataLocal.map(
                  (controlDataLocalRow) =>
                    controlDataLocalRow.id !== controlDataRow.id
                      ? controlDataLocalRow
                      : {
                          ...controlDataLocalRow,
                          data: value,
                        }
                );
              } else {
                newControlDataLocal = controlDataLocal.filter(
                  (controlDataLocalRow) =>
                    controlDataLocalRow.id !== controlDataRow.id
                );
              }
              setControlDataLocal(newControlDataLocal);
            };
            let handleDelete = () => {
              if (
                controlDataLocal[index].data.length > 0 &&
                controlDataLocal.length > 1
              ) {
                let newControlDataLocal = controlDataLocal.filter(
                  (controlDataLocalRow) =>
                    controlDataLocalRow.id !== controlDataRow.id
                );
                setControlDataLocal(newControlDataLocal);
              } else {
                setError("Cannot delete last control selection");
              }
            };
            return (
              <div
                className="multi-test-control-container"
                key={controlDataRow.id}
              >
                <div className="autocomplete-input">
                  <MultiSelect
                    name={controlDataRow.id}
                    data={data}
                    value={controlDataRow.data}
                    onChange={onChange}
                  />
                  {/* <button
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  Delete
                </button> */}
                </div>
                <div className="delete-button-div">
                  <Button
                    variant="outlined"
                    className={classes.outlined}
                    onClick={() => {
                      handleDelete();
                    }}
                  >
                    Delete
                  </Button>
                </div>

                {index === controlDataLocal.length - 1 && (
                  <div className="popup-btn-test-control">
                    <Button
                      variant="contained"
                      className={classes.contained}
                      onClick={() => {
                        addControlLocalRow();
                      }}
                    >
                      Add Set
                    </Button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {error && <p className="error">{error}</p>}
      <div className="popup-btn">
        <Button
          variant="outlined"
          className={classes.outlined}
          onClick={() => {
            handleFormClose();
          }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          className={classes.contained}
          onClick={() => {
            addTestControlToConfigUserInputs();
          }}
        >
          Apply Changes
        </Button>
      </div>
    </div>
  );
}

export default TestControlForm;
