// Import required libraries
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import capitalize from "lodash.capitalize";

// Import data
import { config } from "../../config/config";

// import components
import Message from "../Message/Message";

//Import styles
import "./PowerBiReportForRun.scss";

function PowerBiReportForRun(props) {
  let {
    status,
    dagMessage,
    activeModel,
    activeRunPayloadEntity,
    user,
    view,
    configUserInputs,
    expanded,
    setExpanded,
    setCopyUrl,
  } = props;
  let iframeUrl = "";
  // this if block is for test&Learn and business feature in which only country and brand are applied as filters
  if (
    activeModel?.powerBiReportForModel?.iframeUrlForRun ||
    activeModel?.powerBiReportForBusiness?.iframeUrlForRun
  ) {
    if (view === "test_and_learn_chart" || view === "business_chart") {
      iframeUrl =
        view === "business_chart"
          ? activeModel?.powerBiReportForBusiness?.iframeUrlForRun
          : activeModel?.powerBiReportForModel?.iframeUrlForRun;
      // this switch case is for different fields in mmt models,
      // for eg in mmt the field name is "Country" but in other models it is "country"
      const tableName =
        view === "business_chart"
          ? activeModel?.powerBiReportForBusiness?.tableName
          : activeModel?.powerBiReportForModel?.tableName;
      switch (activeModel?.id) {
        case config.hardCoded.mmtId:
          iframeUrl =
            iframeUrl +
            "&filter=" +
            tableName +
            `/Country eq '${configUserInputs.country}'`;
          if (configUserInputs.brand !== "") {
            iframeUrl =
              iframeUrl +
              " and " +
              tableName +
              `/Brand eq '${configUserInputs.brand}'`;
          }
          break;
        default:
          iframeUrl =
            iframeUrl +
            "&filter=" +
            tableName +
            `/country eq '${configUserInputs.country}'`;
          if (configUserInputs.brand !== "") {
            iframeUrl =
              iframeUrl +
              " and " +
              tableName +
              `/brand eq '${configUserInputs.brand}'`;
          }
          break;
      }
    }
    // this else block is for view dashboard feature
    else {
      switch (activeModel?.id) {
        case config.hardCoded.diffDiffId:
          iframeUrl =
            activeModel?.powerBiReportForModel?.iframeUrlForRun +
            "&filter=" +
            activeModel?.powerBiReportForModel?.filterNameAndCond +
            " '" +
            activeRunPayloadEntity?.id +
            "'";
          break;
        case config.hardCoded.salesUpliftId:
          iframeUrl =
            activeModel?.powerBiReportForModel?.iframeUrlForRun +
            "&filter=" +
            activeModel?.powerBiReportForModel?.filterNameAndCond +
            " '" +
            activeRunPayloadEntity?.id +
            "'";
          break;
        case config.hardCoded.mmtId:
          iframeUrl =
            activeModel?.powerBiReportForModel?.iframeUrlForRun +
            "&filter=" +
            activeModel?.powerBiReportForModel?.filterNameAndCond
              .map((el) => el + ` '${activeRunPayloadEntity?.id}'`)
              .join(" and ");
          break;
        default:
          // iframeUrl = activeModel?.powerBiReportForModel?.iframeUrlForRun;
          iframeUrl =
            activeModel?.powerBiReportForModel?.iframeUrlForRun +
            "&filter=" +
            activeModel?.powerBiReportForModel?.filterNameAndCond +
            " '" +
            activeRunPayloadEntity?.id +
            "'";
          break;
      }
    }
  }

  useEffect(() => {
    if (view === "business_chart") {
      setCopyUrl(iframeUrl);
    }
  }, [view]);

  return (
    <>
      {(
        view === "business_chart"
          ? activeModel?.powerBiReportForBusiness?.iframeUrlForRun
          : activeModel?.powerBiReportForModel?.iframeUrlForRun
      ) ? (
        <div className="power-bi-container">
          <div
            className={`${
              expanded
                ? user.screen.isSidenavBarExpanded
                  ? "zoom-icon-full-div-for-sidenav-expanded"
                  : "zoom-icon-full-div"
                : "zoom-icon-div"
            }`}
          >
            {expanded ? (
              <p className="zoom-icon-full-text">
                Report For {activeRunPayloadEntity?.metadata?.config_name} ||{" "}
                {activeRunPayloadEntity?.id}
              </p>
            ) : (
              <></>
            )}
            <i
              className={`material-icons-outlined ${
                expanded ? "zoom-icon-full-screen" : "zoom-icon"
              }`}
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {expanded ? "close" : ""}
            </i>
          </div>
          <div
            className={`${
              expanded
                ? user.screen.isSidenavBarExpanded
                  ? "power-bi-container-expanded-for-sideNav-expanded"
                  : "power-bi-container-expanded"
                : "power-bi-container-1"
            }`}
          >
            <iframe
              width="100%"
              height="100%"
              src={iframeUrl}
              frameBorder="0"
              allowFullScreen={true}
            ></iframe>
          </div>
        </div>
      ) : (
        <Message status={status} dagMessage={dagMessage} />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(PowerBiReportForRun);
