//Import required libraies
import React, { useEffect } from "react";

//Import custom components
import LocalParameterItem from "./LocalParameterItem";

//Import utils and data
import { config } from "../../../../config/config";
import { removeDupFromArray } from "../../../../utils/utils";

//Import actions

//Import styles

function ManualVolumnField(props) {
  let { id, name, userInputs, value, onChange, changeFlag } = props;

  let modelData = userInputs.models.find(
    (model) => model.id === config.hardCoded.diffDiffId
  );
  let selectedTestCellsData =
    modelData.config.region_granularity.test_control_regions.test;
  let kpiData = [];
  selectedTestCellsData.forEach((config) => {
    if (config?.files?.volume_details) {
      kpiData.push(config?.files?.volume_details);
    }
  });
  kpiData = removeDupFromArray(kpiData.flat());

  // transform array strings to array of objects for LocalparamItem
  kpiData = kpiData.map((kpi) => ({ id: kpi, name: kpi }));

  useEffect(() => {
    if (!kpiData.find((elem) => elem.id === value)) {
      onChange(id, "");
    }
    return () => {};
  }, [modelData.config.region_granularity.test_control_regions.test]);

  return (
    <LocalParameterItem
      ui_element_type="dropdown"
      value={kpiData.length ? value : config.messages.noOptions}
      onChange={onChange}
      enable={kpiData.length ? true : false}
      show={true}
      name={name}
      id={id}
      data={
        kpiData.length
          ? kpiData
          : [
              {
                id: config.messages.noOptions,
                name: config.messages.noOptions,
              },
            ]
      }
      userInputs={userInputs}
    />
  );
}

export default ManualVolumnField;
