//Import required libraies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import moment from "moment";

//Import Custom Component
import LocalParameterItem from "./LocalParameterItem";
import DatasetType from "./DatasetType";
import KpiType from "./KpiType";
import AutoMLNeilsenDataset from "./AutoMLNeilsenDataset";
import MMTSalesDatasetKpiSelection from "./MMTSalesDatasetKpiSelection";
import noDataImage from "../../../../../assets/images/undraw_empty.svg";
import Loader from "../../../../components/Loader/Loader";
import MergedDatasetKPI from "./MergeDatasetKPI";
import MergedDataset from "./MergeDataset";
// Import custom hooks

// Import action creators
import {
  addDataset,
  removeDataset,
  addKpi,
  removeKpi,
  selectAll,
  clearAll,
  updateDatasetLocalParameters,
  addToggleKpi,
  onChangeCategory,
  deleteMergeDataset,
  updateCategoryKpi,
} from "../../../../redux/actions";

//Import utils
import {
  sortDataset,
  splitDatasetsIntoTypes,
  getMandatoryKPIToTop,
  transformKpi,
  getSortedData,
  convertDateForDataSetFormat,
  numberOfWeeks,
} from "../../../../utils/utils";
import { config } from "../../../../config/config";
// import { StyledCheckbox } from "../../../../../styles/styles";
import { Checkbox, Button, Tooltip } from "../../../../uiTheme";
import Modal from "../../../../components/Modal/Modal";
import { useNonInitialEffect } from "../../../../hooks/useNonInitialEffect";

// Import styles

function Dataset(props) {
  let {
    activeModel,
    match,
    user,
    datasets,
    activeDataset,
    activeModelId,
    activeDatasetId,
    setActiveDatasetId,
    activeAppID,
    selectedDataset,
    selectedDatasetsIds,
    configUserInputs,
    updateDatasetLocalParameters,
    disableFlag,
    activeSelection,
    selectAll,
    clearAll,
    selectedModelDatasets,
    activeDatasetDates,
    activeModelsDatasetFromModelsData,
    onChangeCategory,
    deleteMergeDataset,
    selectedModel,
    data,
    changeFlag,
    updateCategoryKpi,
    datasetsTypesData,
  } = props;

  const [open, setOpen] = useState({});
  const [salesDatasetStatus, setSalesDatasetStatus] = useState("loading");
  const [activeDatasetForKpi, setActiveDatasetForKpi] = useState(null);
  const [competitor, setCompetitor] = useState(null);
  const [topN, setTopN] = useState(5);
  const [power, setPower] = useState([]);
  const [showMergedPopover, setMergedPopover] = useState(false);
  const [mergedDS, setMergedDS] = useState({});
  const [modalComponent, setModalComponent] = useState({
    component: null,
    openModal: false,
    handleCancel: () => {},
    handleDone: () => {},
  });
  const selectedModelDatasetsIds = selectedModelDatasets.map((el) => el.id);
  // this will update the activeDatasetForKPI in which KPIs will be the subset of models collection KPIs
  const gettingActiveDatasetForKPI = (kpi_list, activeKpiListData) => {
    return {
      ...activeKpiListData,
      kpi_list:
        kpi_list?.filter((e) => activeKpiListData?.kpi_list?.includes(e))
          .length > 0
          ? kpi_list?.filter((e) => activeKpiListData?.kpi_list?.includes(e))
          : activeKpiListData?.kpi_list,
      kpi_variables_list:
        activeKpiListData?.kpi_variables_list?.filter((e) =>
          kpi_list?.includes(e.id)
        ).length > 0
          ? activeKpiListData?.kpi_variables_list?.filter((e) =>
              kpi_list?.includes(e.id)
            )
          : activeKpiListData?.kpi_variables_list,
    };
  };
  // to load the power reports
  useEffect(() => {
    let url = config.api.dataQualityReports;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setPower(json.data);
      });
  }, []);
  //This useEffect will update the competitor check box value as per the conditions
  useEffect(() => {
    if (selectedModelDatasets.length > 0) {
      const requiredDataset = selectedModelDatasets.find((dataset) => {
        return (
          dataset.pipeline_dataset_id === activeDataset?.pipeline_dataset_id &&
          config.hardCoded.autoMlIds.some(
            (elem) => elem.toLowerCase() === activeModelId.toLowerCase()
          ) &&
          config.hardCoded.topNCompetitor.includes(
            activeDataset?.pipeline_dataset_id
          )
        );
      });
      if (requiredDataset) {
        setCompetitor(requiredDataset.competitor_available);
      } else {
        setCompetitor(false);
      }
    } else {
      setCompetitor(false);
    }
  }, [selectedModelDatasets, activeDatasetId]);

  //this useEffect will update the checkbox status to true as a kpi is selected
  useEffect(() => {
    if (
      config.hardCoded.autoMlIds.some(
        (elem) => elem.toLowerCase() === activeModelId.toLowerCase()
      ) &&
      selectedDataset?.pipeline_dataset_id === "TV" &&
      selectedDataset?.kpi_list?.length > 0
    ) {
      let data = {
        activeDatasetForKpi: activeDatasetForKpi,
        modelId: activeModelId,
        selectedDataset,
        dataset: selectedDataset ? selectedDataset : activeDataset,
        key: "competitor_available",
        value: true,
      };
      updateDatasetLocalParameters(data);
    }
  }, [activeModelId, activeDatasetId, selectedDataset?.kpi_list]);
  //this will add the top_n_competitor value in config when checkbox is selected
  useEffect(() => {
    if (
      config.hardCoded.autoMlIds.some(
        (elem) => elem.toLowerCase() === activeModelId.toLowerCase()
      ) &&
      config.hardCoded.competitorArr.includes(
        activeDataset?.pipeline_dataset_id
      ) &&
      competitor &&
      config.hardCoded.neilsenNonCompId !== activeDataset?.pipeline_dataset_id
    ) {
      let data = {
        activeDatasetForKpi: activeDatasetForKpi,
        modelId: activeModelId,
        selectedDataset,
        dataset: selectedDataset ? selectedDataset : activeDataset,
        key: "top_n_competitor",
        value:
          selectedDataset?.top_n_competitor ||
          selectedDataset?.top_n_competitor === 0
            ? selectedDataset.top_n_competitor
            : 5,
      };
      setTopN(data.value);
      updateDatasetLocalParameters(data);
    }
  }, [activeDatasetId, competitor]);

  useEffect(() => {
    setTopN(
      selectedDataset &&
        (selectedDataset.top_n_competitor ||
          selectedDataset.top_n_competitor === 0)
        ? selectedDataset.top_n_competitor
        : 5
    );
  }, [activeDatasetId, activeModelId]);
  // this will give the selected category in the nielsen dataset(initially will be undefined)
  const categoryCheck = configUserInputs.models
    .find((el) => el.id === activeModelId)
    ?.data_sources.find((el) =>
      config.hardCoded.nielsenDatasets.includes(el.id)
    )?.category;
  const getActiveDatasetForKpi = () => {
    let activeKpiListData = activeModelsDatasetFromModelsData?.find(
      (dataset) => dataset.id === activeDatasetId
    );

    // this will modify the KPIs list(Target variables/Sales Variable) shown on UI with
    // the KPIs list of 1st category in categoryData from configUserInput when no category is selected
    if (
      config.hardCoded.nielsenDatasets.includes(activeDatasetId) &&
      configUserInputs.categoryData !== -1 &&
      !categoryCheck?.length
    ) {
      const defaultKPIObj = configUserInputs?.categoryData[0];
      if (defaultKPIObj) {
        let kpi_list =
          configUserInputs.country === defaultKPIObj.country_name &&
          configUserInputs.brand === defaultKPIObj.brand_name
            ? defaultKPIObj.kpi_list
            : [];
        if (kpi_list && configUserInputs.configType !== "run") {
          activeKpiListData = gettingActiveDatasetForKPI(
            kpi_list,
            activeKpiListData
          );
        }
      }
    }
    // this will populate the KPIs through category collection for nielsen
    else if (
      categoryCheck?.length &&
      config.hardCoded.nielsenDatasets.includes(activeDatasetId)
    ) {
      let kpiListData = () => {
        let filterData = configUserInputs.categoryData.filter(
          (elem) =>
            categoryCheck?.includes(elem.id) &&
            configUserInputs.country === elem.country_name &&
            configUserInputs.brand === elem.brand_name
        );
        let updateFilterData = filterData.reduce((acc, curr) => {
          curr?.kpi_list?.forEach((element) => {
            if (!acc.includes(element)) {
              acc.push(element);
            }
          });
          return acc;
        }, []);
        return updateFilterData;
      };
      let kpi_list = configUserInputs.categoryData !== -1 ? kpiListData() : [];
      if (kpi_list && configUserInputs.configType !== "run") {
        activeKpiListData = gettingActiveDatasetForKPI(
          kpi_list,
          activeKpiListData
        );
      }
    }

    // this will populate the KPIs through datasetDetails collection for datasets(other than nielsen)
    else if (
      configUserInputs?.datasetDetailsData?.data?.length > 0 &&
      configUserInputs.datasetDetailsData.status === "success"
    ) {
      const kpi_list = configUserInputs.datasetDetailsData.data.find(
        (elem) =>
          elem.dataset_id === activeDatasetId &&
          configUserInputs.country === elem.country_name &&
          (configUserInputs.brand === elem.brand_name ||
            elem.brand_name === null)
      )?.kpi_list;
      if (kpi_list && configUserInputs.configType !== "run") {
        activeKpiListData = gettingActiveDatasetForKPI(
          kpi_list,
          activeKpiListData
        );
      }
    }
    return activeKpiListData;
  };
  useEffect(() => {
    let activeKpiListData = getActiveDatasetForKpi();
    setActiveDatasetForKpi(activeKpiListData);
  }, [
    activeDatasetId,
    configUserInputs.datasetDetailsData,
    categoryCheck?.length,
    configUserInputs.categoryData,
    activeModelId,
  ]);

  //kpi list updated when the category changes
  useNonInitialEffect(() => {
    const checkDatasetType = selectedDataset
      ? selectedDataset?.type
      : activeDataset?.type;
    if (checkDatasetType === config.hardCoded.datasetNeilsenType) {
      let activeKpiListData = getActiveDatasetForKpi();
      let data = {
        modelId: activeModelId,
        selectedDataset,
        dataset: selectedDataset ? selectedDataset : activeDataset,
        activeDatasetForKpi: activeKpiListData,
      };
      updateCategoryKpi(data);
    }
  }, [categoryCheck?.length]);

  useEffect(() => {
    datasetsTypesData?.map((dataTypeData) => {
      let dataSetOpened = open;
      dataSetOpened[dataTypeData.name] = true;
      setOpen({ ...dataSetOpened });
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (
      configUserInputs.datasetDetailsData?.status === "loading" ||
      configUserInputs?.skuData?.status === "loading"
    ) {
      setSalesDatasetStatus("loading");
    } else if (
      configUserInputs.datasetDetailsData?.status === "success" &&
      configUserInputs?.skuData?.status === "success"
    ) {
      setSalesDatasetStatus("success");
    } else if (disableFlag) {
      setSalesDatasetStatus("success");
    } else {
      setSalesDatasetStatus("error");
    }
    return () => {};
  }, [
    configUserInputs.datasetDetailsData?.status,
    configUserInputs?.skuData?.status,
    selectedDataset,
  ]);

  // // merge data source
  // if (selectedModel?.merged_data_sources) {
  //   let mergedDatasets = selectedModel?.merged_data_sources
  //     .map((elem) => [...elem.dataset_list])
  //     .flat();
  //   mergedDatasets = [...new Set(mergedDatasets)];
  //   datasets = datasets.filter((elem) => !mergedDatasets.includes(elem.id));
  // }

  // // merge data source
  // useEffect(() => {
  //   setActiveDatasetId(datasets[0]?.id);
  //   return () => {};
  // }, [changeFlag.mergeDataset]);
  let kpiTypesData = [];
  if (activeDatasetForKpi) {
    kpiTypesData = transformKpi(datasets, activeDatasetForKpi, activeModelId);
  }

  const validPeriodicityForDataset =
    activeDatasetDates &&
    activeDatasetDates?.dataset_id === activeDatasetId &&
    activeDatasetDates?.periodicity;

  //Hard coded stuff
  let datasetsDisplayFlag;
  if (
    configUserInputs.models.filter((model) =>
      config.hardCoded.autoMlIds.includes(model.id)
    ).length &&
    config.hardCoded.autoMlIds.includes(activeModelId)
  ) {
    datasetsDisplayFlag =
      configUserInputs.models
        .find((model) => config.hardCoded.autoMlIds.includes(model.id))
        .config.run_type?.toLowerCase() === config.hardCoded.nestedRunId
        ? false
        : true;
  } else if (
    configUserInputs.models.filter(
      (model) => model.id === config.hardCoded.communityClusteringId
    ).length &&
    activeModelId === config.hardCoded.communityClusteringId
  ) {
    datasetsDisplayFlag =
      configUserInputs.models
        .find((model) => model.id === config.hardCoded.communityClusteringId)
        .config.community_clustering_run_type?.toLowerCase() ===
      config.hardCoded.targetRunId
        ? false
        : true;
  } else if (
    configUserInputs.models.filter(
      (model) => model.id === config.hardCoded.propensityId
    ).length &&
    activeModelId === config.hardCoded.propensityId
  ) {
    datasetsDisplayFlag =
      configUserInputs.models
        .find((model) => model.id === config.hardCoded.propensityId)
        .config.propensity_modeling_run_type?.toLowerCase() ===
      config.hardCoded.targetRunId
        ? false
        : true;
  } else if (
    configUserInputs.models.filter(
      (model) => model.id === config.hardCoded.regBudId
    ).length &&
    activeModelId === config.hardCoded.regBudId
  ) {
    datasetsDisplayFlag =
      configUserInputs.models
        .find((model) => model.id === config.hardCoded.regBudId)
        .config.rbt_run_type?.toLowerCase() === config.hardCoded.budgetId
        ? false
        : true;
  } else {
    datasetsDisplayFlag = true;
  }
  const topNMaxMin = activeDataset?.local_parameters?.find(
    (el) => el.id === "top_n_competitor"
  );
  const fieldsName = {
    COUNTRY: "COUNTRY",
    BRAND: "BRAND",
    SUB_BRAND: "SUB_BRAND",
    SEGMENT: "SEGMENT",
    SUB_SEGMENT: "SUB_SEGMENT",
    DATE: "DATE",
  };
  const filterParam = (report_link) =>
    report_link.includes("reportEmbed") ? "&filter=" : "?filter=";
  const biUrl = () =>
    power.reduce((acc, obj) => {
      let url;
      let strArr = (arr) =>
        `(${arr.map((el) => `'${el}'`).join(",")})`.replace("&", "%26");
      if (
        activeDataset &&
        activeDataset.source === "LOCAL" &&
        activeDataset.type === "MEDIA" &&
        obj.source === "LOCAL" &&
        obj.type === "MEDIA"
      ) {
        url =
          obj.report_link +
          filterParam(obj.report_link) +
          obj.table_name +
          `/SOURCE eq '${activeDataset.name}' and ` +
          obj.table_name +
          `/COUNTRY eq '${configUserInputs.country}' and ` +
          obj.table_name +
          `/BRAND eq '${configUserInputs.brand}' and`;
        if (!configUserInputs.sub_brand_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SUB_BRAND in ${strArr(configUserInputs.sub_brand)} and`;
        }
        if (!configUserInputs.segment_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SEGMENT in ${strArr(configUserInputs.segment)} and`;
        }
        if (!configUserInputs.sub_segment_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SUB_SEGMENT in ${strArr(configUserInputs.sub_segment)} and`;
        }
        if (configUserInputs.global_week_date_selection === "date_range") {
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${configUserInputs.startDate} and ` +
            obj.table_name +
            `/DATE le ${configUserInputs.endDate}`;
        } else if (
          configUserInputs.global_week_date_selection === "historic_week_data"
        ) {
          let startDate = moment()
            .subtract(configUserInputs.historic_week_data * 7, "days")
            .format("YYYY-MM-DD");
          let endDate = moment().format("YYYY-MM-DD");
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${startDate} and ` +
            obj.table_name +
            `/DATE le ${endDate}`;
        }
        acc = url;
      } else if (
        activeDataset &&
        activeDataset.source === "LOCAL" &&
        activeDataset.type === "MACRO" &&
        obj.source === "LOCAL" &&
        obj.type === "MACRO"
      ) {
        // kpi_dv360/BRANDS eq ‘AIRWICK’
        url =
          obj.report_link +
          filterParam(obj.report_link) +
          obj.table_name +
          `/SOURCE eq '${activeDataset.name}' and ` +
          obj.table_name +
          `/COUNTRY eq '${configUserInputs.country}' and`;
        if (configUserInputs.global_week_date_selection === "date_range") {
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${configUserInputs.startDate} and ` +
            obj.table_name +
            `/DATE le ${configUserInputs.endDate}`;
        } else if (
          configUserInputs.global_week_date_selection === "historic_week_data"
        ) {
          let startDate = moment()
            .subtract(configUserInputs.historic_week_data * 7, "days")
            .format("YYYY-MM-DD");
          let endDate = moment().format("YYYY-MM-DD");
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${startDate} and ` +
            obj.table_name +
            `/DATE le ${endDate}`;
        }

        acc = url;
      } else if (
        activeDataset &&
        activeDataset.source === "LOCAL" &&
        activeDataset.type === "CATEGORY" &&
        obj.source === "LOCAL" &&
        obj.type === "CATEGORY"
      ) {
        // kpi_dv360/BRANDS eq ‘AIRWICK’
        url =
          obj.report_link +
          filterParam(obj.report_link) +
          obj.table_name +
          `/COUNTRY eq '${configUserInputs.country}' and ` +
          obj.table_name +
          `/BRAND eq '${configUserInputs.brand}' and`;
        if (!configUserInputs.sub_brand_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SUB_BRAND in ${strArr(configUserInputs.sub_brand)} and`;
        }
        if (!configUserInputs.segment_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SEGMENT in ${strArr(configUserInputs.segment)} and`;
        }
        if (!configUserInputs.sub_segment_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SUB_SEGMENT in ${strArr(configUserInputs.sub_segment)} and`;
        }
        if (configUserInputs.global_week_date_selection === "date_range") {
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${configUserInputs.startDate} and ` +
            obj.table_name +
            `/DATE le ${configUserInputs.endDate}`;
        } else if (
          configUserInputs.global_week_date_selection === "historic_week_data"
        ) {
          let startDate = moment()
            .subtract(configUserInputs.historic_week_data * 7, "days")
            .format("YYYY-MM-DD");
          let endDate = moment().format("YYYY-MM-DD");
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${startDate} and ` +
            obj.table_name +
            `/DATE le ${endDate}`;
        }

        acc = url;
      } else if (
        activeDataset &&
        activeDataset.source === "LOCAL" &&
        activeDataset.type === "SALES" &&
        obj.source === "LOCAL" &&
        obj.type === "SALES"
      ) {
        // kpi_dv360/BRANDS eq ‘AIRWICK’
        url =
          obj.report_link +
          filterParam(obj.report_link) +
          obj.table_name +
          `/SOURCE eq '${activeDataset.name}' and ` +
          obj.table_name +
          `/COUNTRY eq '${configUserInputs.country}' and ` +
          obj.table_name +
          `/BRAND eq '${configUserInputs.brand}' and`;
        if (!configUserInputs.sub_brand_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SUB_BRAND in ${strArr(configUserInputs.sub_brand)} and`;
        }
        if (!configUserInputs.segment_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SEGMENT in ${strArr(configUserInputs.segment)} and`;
        }
        if (!configUserInputs.sub_segment_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SUB_SEGMENT in ${strArr(configUserInputs.sub_segment)} and`;
        }
        if (configUserInputs.global_week_date_selection === "date_range") {
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${configUserInputs.startDate} and ` +
            obj.table_name +
            `/DATE le ${configUserInputs.endDate}`;
        } else if (
          configUserInputs.global_week_date_selection === "historic_week_data"
        ) {
          let startDate = moment()
            .subtract(configUserInputs.historic_week_data * 7, "days")
            .format("YYYY-MM-DD");
          let endDate = moment().format("YYYY-MM-DD");
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${startDate} and ` +
            obj.table_name +
            `/DATE le ${endDate}`;
        }
        acc = url;
      } else if (
        activeDataset &&
        activeDataset.source === "PLATFORM" &&
        activeDataset.type === "MEDIA" &&
        obj.dataset_id === activeDataset.id
      ) {
        url =
          obj.report_link +
          filterParam(obj.report_link) +
          obj.table_name +
          `/COUNTRY eq '${configUserInputs.country}' and ` +
          obj.table_name +
          `/BRAND eq '${configUserInputs.brand}' and`;
        if (!configUserInputs.sub_brand_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SUB_BRAND in ${strArr(configUserInputs.sub_brand)} and`;
        }
        if (!configUserInputs.segment_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SEGMENT in ${strArr(configUserInputs.segment)} and`;
        }
        if (!configUserInputs.sub_segment_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SUB_SEGMENT in ${strArr(configUserInputs.sub_segment)} and`;
        }
        if (configUserInputs.global_week_date_selection === "date_range") {
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${configUserInputs.startDate} and ` +
            obj.table_name +
            `/DATE le ${configUserInputs.endDate}`;
        } else if (
          configUserInputs.global_week_date_selection === "historic_week_data"
        ) {
          let startDate = moment()
            .subtract(configUserInputs.historic_week_data * 7, "days")
            .format("YYYY-MM-DD");
          let endDate = moment().format("YYYY-MM-DD");
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${startDate} and ` +
            obj.table_name +
            `/DATE le ${endDate}`;
        }

        acc = url;
      } else if (
        activeDataset &&
        activeDataset.source === "PLATFORM" &&
        activeDataset.type === "SALES" &&
        obj.dataset_id === activeDataset.id
      ) {
        url =
          obj.report_link +
          filterParam(obj.report_link) +
          obj.table_name +
          `/COUNTRY eq '${configUserInputs.country}' and ` +
          obj.table_name +
          `/BRAND eq '${configUserInputs.brand}' and`;
        if (!configUserInputs.sub_brand_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SUB_BRAND in ${strArr(configUserInputs.sub_brand)} and`;
        }
        if (!configUserInputs.segment_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SEGMENT in ${strArr(configUserInputs.segment)} and`;
        }
        if (!configUserInputs.sub_segment_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SUB_SEGMENT in ${strArr(configUserInputs.sub_segment)} and`;
        }
        if (configUserInputs.global_week_date_selection === "date_range") {
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${configUserInputs.startDate} and ` +
            obj.table_name +
            `/DATE le ${configUserInputs.endDate}`;
        } else if (
          configUserInputs.global_week_date_selection === "historic_week_data"
        ) {
          let startDate = moment()
            .subtract(configUserInputs.historic_week_data * 7, "days")
            .format("YYYY-MM-DD");
          let endDate = moment().format("YYYY-MM-DD");
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${startDate} and ` +
            obj.table_name +
            `/DATE le ${endDate}`;
        }

        acc = url;
      } else if (
        activeDataset &&
        activeDataset.source === "PLATFORM" &&
        activeDataset.type === "MACRO" &&
        obj.dataset_id === activeDataset.id
      ) {
        url =
          obj.report_link +
          filterParam(obj.report_link) +
          obj.table_name +
          `/COUNTRY eq '${configUserInputs.country}' and`;
        if (configUserInputs.global_week_date_selection === "date_range") {
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${configUserInputs.startDate} and ` +
            obj.table_name +
            `/DATE le ${configUserInputs.endDate}`;
        } else if (
          configUserInputs.global_week_date_selection === "historic_week_data"
        ) {
          let startDate = moment()
            .subtract(configUserInputs.historic_week_data * 7, "days")
            .format("YYYY-MM-DD");
          let endDate = moment().format("YYYY-MM-DD");
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${startDate} and ` +
            obj.table_name +
            `/DATE le ${endDate}`;
        }

        acc = url;
      } else if (
        activeDataset &&
        activeDataset.source === "PLATFORM" &&
        activeDataset.type === "CATEGORY" &&
        obj.dataset_id === activeDataset.id
      ) {
        url =
          obj.report_link +
          filterParam(obj.report_link) +
          obj.table_name +
          `/COUNTRY eq '${configUserInputs.country}' and ` +
          obj.table_name +
          `/BRAND eq '${configUserInputs.brand}' and`;
        if (!configUserInputs.sub_brand_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SUB_BRAND in ${strArr(configUserInputs.sub_brand)} and`;
        }
        if (!configUserInputs.segment_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SEGMENT in ${strArr(configUserInputs.segment)} and`;
        }
        if (!configUserInputs.sub_segment_select_all) {
          url =
            url +
            " " +
            obj.table_name +
            `/SUB_SEGMENT in ${strArr(configUserInputs.sub_segment)} and`;
        }
        if (configUserInputs.global_week_date_selection === "date_range") {
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${configUserInputs.startDate} and ` +
            obj.table_name +
            `/DATE le ${configUserInputs.endDate}`;
        } else if (
          configUserInputs.global_week_date_selection === "historic_week_data"
        ) {
          let startDate = moment()
            .subtract(configUserInputs.historic_week_data * 7, "days")
            .format("YYYY-MM-DD");
          let endDate = moment().format("YYYY-MM-DD");
          url =
            url +
            " " +
            obj.table_name +
            `/DATE ge ${startDate} and ` +
            obj.table_name +
            `/DATE le ${endDate}`;
        }
        if (
          !selectedModelDatasetsIds.includes(config.hardCoded.localNielsen) &&
          categoryCheck?.length &&
          !categoryCheck.includes("aide-400")
        ) {
          url =
            url +
            " and " +
            obj.table_name +
            `/SOURCE_CATEGORY in ${strArr(categoryCheck)}`;
        }
        acc = url;
      }
      return acc;
    }, "");

  const handleDialogClose = () => {
    setModalComponent((prevState) => {
      return { ...prevState, openModal: false };
    });
  };
  const handleDialogOpen = (component) => {
    setModalComponent((prevState) => {
      return {
        ...prevState,
        openModal: true,
        component: component,
      };
    });
  };

  const filterSuDatasets =
    activeModelId === config.hardCoded.salesUpliftId
      ? datasets?.filter((e) => e?.type !== "MEDIA")
      : datasets;

  return (
    <div className="models-selections-column left">
      {/* <div className="models-selection-title-container">
        <p className="models-selection-title">Datasets:</p>
      </div> */}

      {activeSelection === "dataset" && (
        <div
          className={`models-selection-content ${
            !datasetsDisplayFlag ? "no-datasets-container" : ""
          }
          ${!filterSuDatasets.length ? "no-datasets-container" : ""}`}
        >
          {!datasetsDisplayFlag ? (
            <div className="no-datasets-holder">
              <img src={noDataImage} />
              {config.messages.noDatasets}
            </div>
          ) : // <div className="no-datasets-holder">
          //   <i className="material-icons-round no-datasets-icon">
          //     warning_amber
          //   </i>
          //   <p className="no-data">{config.messages.noDatasets}</p>
          // </div>
          filterSuDatasets.length ? (
            <div className="datasets-selections-container">
              <div className="datasets-selections-column left">
                {configUserInputs?.datasetDetailsData?.data.length ? (
                  datasetsTypesData
                    .filter((data) =>
                      activeModelId === config.hardCoded.salesUpliftId
                        ? data.name !== "media"
                        : data
                    )
                    .map((datasetType) => {
                      let datasetTypeDatasetsIds = datasetType.data.map(
                        (dataset) => dataset.id
                      );
                      let selectedDatasetsIdsTyped = selectedDatasetsIds.filter(
                        (selectedDatasetId) =>
                          datasetTypeDatasetsIds.includes(selectedDatasetId)
                      );
                      let sortedDatasets = datasetType.data.length
                        ? sortDataset(
                            datasetType.data,
                            datasetType.checkedSortFlag
                              ? selectedDatasetsIdsTyped
                              : [],
                            ["name"]
                          )
                        : [];
                      return (
                        <div
                          className="datasets-selections-column-type"
                          key={datasetType.id}
                        >
                          {datasetType.data.length ||
                          datasetType.name === "merged" ? (
                            <div
                              style={{
                                display: "flex",
                                color: "#EB367F",
                                alignItems: "center",
                                fontSize: "13px",
                              }}
                              onClick={() => {
                                let openType = open;
                                openType[`${datasetType.name}`] =
                                  !openType[`${datasetType.name}`];
                                setOpen({ ...openType });
                              }}
                            >
                              <p className="datasets-selections-column-type-title header-text">
                                {datasetType.name === "MACRO"
                                  ? "Macro"
                                  : datasetType.name}
                              </p>
                              <i className="material-icons sidenav-category-icon">
                                {open[datasetType.name]
                                  ? "expand_more"
                                  : "expand_less"}
                              </i>
                              {datasetType.name === "merged" &&
                                configUserInputs.configType !== "run" && (
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setMergedDS({});
                                      setMergedPopover(true);
                                    }}
                                    size="small"
                                    style={{
                                      background: "rgb(235, 54, 127)",
                                      height: "16px",
                                      fontSize: "11px",
                                      color: "white",
                                      fontWeight: "600",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Add New
                                  </Button>
                                )}
                            </div>
                          ) : null}
                          <Collapse
                            in={open[datasetType.name]}
                            timeout="auto"
                            unmountOnExit
                          >
                            {datasetType.data.length ? (
                              sortedDatasets.map((dataset, i) => {
                                let checkedFlag =
                                  selectedDatasetsIdsTyped.includes(
                                    dataset.id
                                  ) ||
                                  configUserInputs.models
                                    .find((model) => model.id === activeModelId)
                                    .data_sources.find(
                                      (data) => data.id === dataset.id
                                    );
                                return (
                                  <div
                                    className={`${
                                      datasetType.name === "merged"
                                        ? "dataset-type-container"
                                        : ""
                                    } ${
                                      dataset.id === activeDatasetId
                                        ? "active-merged-dataset"
                                        : ""
                                    }`}
                                    key={i}
                                  >
                                    <DatasetType
                                      activeModel={activeModel}
                                      key={dataset.id}
                                      match={match}
                                      checkedFlag={checkedFlag}
                                      dataset={dataset}
                                      ui_element_type={
                                        datasetType.ui_element_type
                                      }
                                      activeDatasetId={activeDatasetId}
                                      setActiveDatasetId={setActiveDatasetId}
                                      disableFlag={disableFlag}
                                      activeModelId={activeModelId}
                                      datasetType={datasetType}
                                    />
                                    {datasetType.name === "merged" &&
                                    configUserInputs.configType !== "run" ? (
                                      <div style={{ display: "contents" }}>
                                        <i
                                          className="material-icons-outlined merged-icons"
                                          onClick={() => {
                                            setMergedPopover(true);
                                            setMergedDS(dataset);
                                            setActiveDatasetId(dataset?.id);
                                          }}
                                        >
                                          drive_file_rename_outline
                                        </i>
                                        <i
                                          className="material-icons-outlined merged-icons"
                                          onClick={() => {
                                            handleDialogOpen(
                                              <div
                                                style={{
                                                  width: "600px",
                                                  color: "#46596a",
                                                }}
                                              >
                                                <div>
                                                  <p
                                                    style={{
                                                      color:
                                                        "rgb(235, 54, 127)",
                                                      fontSize: 14,
                                                      fontWeight: 600,
                                                      margin: 0,
                                                    }}
                                                  >{`Delete "${dataset.name}"`}</p>
                                                  <p>
                                                    Are you sure you want to
                                                    delete this merged dataset?
                                                  </p>
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                  }}
                                                >
                                                  <Button
                                                    onClick={() =>
                                                      handleDialogClose()
                                                    }
                                                    // className={classes.outlined}
                                                    variant="outlined"
                                                    styleVariant="secondary"
                                                    sx={{
                                                      height: 23,
                                                      margin: "0px 4px 0px 0px",
                                                    }}
                                                  >
                                                    Cancel
                                                  </Button>
                                                  <Button
                                                    onClick={() => {
                                                      setActiveDatasetId(
                                                        sortedDatasets[0].id
                                                      );
                                                      deleteMergeDataset({
                                                        modelId: activeModelId,
                                                        value: dataset.id,
                                                      });
                                                      handleDialogClose();
                                                    }}
                                                    // className={classes.contained}
                                                    variant="contained"
                                                    styleVariant="secondary"
                                                    sx={{
                                                      height: 23,
                                                      margin: "0px 4px 0px 0px",
                                                    }}
                                                  >
                                                    Delete
                                                  </Button>
                                                </div>
                                              </div>
                                            );
                                          }}
                                        >
                                          delete_forever
                                        </i>
                                      </div>
                                    ) : (
                                      datasetType.name === "merged" &&
                                      configUserInputs.configType === "run" && (
                                        <div style={{ display: "contents" }}>
                                          <i
                                            className="material-icons-outlined merged-icons"
                                            onClick={() => {
                                              setMergedPopover(true);
                                              setMergedDS(dataset);
                                            }}
                                          >
                                            visibility
                                          </i>
                                        </div>
                                      )
                                    )}
                                  </div>
                                );
                              })
                            ) : datasetType.name === "merged" ? (
                              <p style={{ margin: "0 5px", fontSize: 11 }}>
                                No Datasets Available
                              </p>
                            ) : null}
                          </Collapse>
                        </div>
                      );
                    })
                ) : (
                  <Loader />
                )}
              </div>

              {activeDataset && (
                <div className="datasets-selections-column right">
                  {activeDataset && (
                    <div className="active-dataset-information">
                      <div className="end-box">
                        <span className="name">{activeDataset.name}</span>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className="report-link-container">
                            <a
                              href={biUrl()}
                              target="_blank"
                              className="report-link"
                            >
                              Data quality reports
                            </a>
                            <span className="material-icons-outlined">
                              open_in_new
                            </span>
                          </span>
                          <span className="divider"></span>

                          {configUserInputs.datasetDetailsData?.status ===
                            "loading" &&
                            !disableFlag && <Loader />}
                          {configUserInputs.datasetDetailsData?.status ===
                            "success" &&
                            !disableFlag && (
                              <>
                                <span className="start-date">
                                  Start Date:<span>&nbsp;</span>
                                  {activeDatasetDates &&
                                  activeDatasetDates?.hasOwnProperty(
                                    "start_date"
                                  ) &&
                                  activeDatasetDates?.start_date
                                    ? convertDateForDataSetFormat(
                                        activeDatasetDates.start_date
                                      )
                                    : " - "}
                                </span>
                                <span className="end-date">
                                  End Date:<span>&nbsp;</span>
                                  {activeDatasetDates &&
                                  activeDatasetDates?.hasOwnProperty(
                                    "end_date"
                                  ) &&
                                  activeDatasetDates?.end_date &&
                                  typeof activeDatasetDates.end_date ===
                                    "string"
                                    ? convertDateForDataSetFormat(
                                        activeDatasetDates.end_date
                                      )
                                    : " - "}
                                </span>
                              </>
                            )}

                          {configUserInputs.datasetDetailsData?.status ===
                            "success" &&
                            !disableFlag &&
                            config.hardCoded.radioDatasetList.includes(
                              activeDataset.type
                            ) &&
                            configUserInputs.global_week_date_selection ===
                              config.hardCoded.historicWeek && (
                              <>
                                <span className="divider"></span>{" "}
                                <span className="weeks-diff">
                                  {numberOfWeeks(activeDatasetDates?.end_date)}{" "}
                                  {numberOfWeeks(activeDatasetDates?.end_date) >
                                  1
                                    ? "Weeks"
                                    : "Week"}
                                  <Tooltip
                                    title={config.messages.weeksDiffIconMessage}
                                    placement="bottom-end"
                                    arrow
                                  >
                                    {" "}
                                    <i
                                      className="material-icons-outlined info-icon"
                                      style={{
                                        position: "relative",
                                        top: "1.5px",
                                      }}
                                    >
                                      {" "}
                                      info{" "}
                                    </i>
                                  </Tooltip>
                                </span>
                              </>
                            )}
                        </div>

                        {configUserInputs.datasetDetailsData?.status ===
                          "error" &&
                          !disableFlag && (
                            <p className="info-message">
                              {configUserInputs.datasetDetailsData?.message}
                            </p>
                          )}
                      </div>
                    </div>
                  )}

                  <div className="dataset-selections">
                    <div className="datasets-selections-kpi-row">
                      <div className="select-kpi-text">
                        {activeDataset && (
                          <p className="header-text">
                            Select KPIs
                            {/* {`${
                            activeDataset ? " for " + activeDataset.name : ""
                          }`} */}
                          </p>
                        )}
                        {activeDataset?.pipeline_dataset_id !==
                          config.hardCoded.neilsenNonCompId &&
                          config.hardCoded.competitorArr.includes(
                            activeDataset?.pipeline_dataset_id
                          ) &&
                          config.hardCoded.autoMlIds.some(
                            (elem) =>
                              elem.toLowerCase() === activeModelId.toLowerCase()
                          ) && (
                            <div className="top-n">
                              <div className="competitor-checkbox-container">
                                <div
                                  className={`competitor-checkbox ${
                                    disableFlag ? "disabled-pointer" : ""
                                  }`}
                                >
                                  <Checkbox
                                    disableRipple
                                    checked={competitor ? competitor : false}
                                    onChange={(e) => {
                                      let data = {
                                        activeDatasetForKpi:
                                          activeDatasetForKpi,
                                        modelId: activeModelId,
                                        selectedDataset,
                                        dataset: selectedDataset
                                          ? selectedDataset
                                          : activeDataset,
                                        key: "competitor_available",
                                        value: !competitor,
                                      };
                                      updateDatasetLocalParameters(data);
                                    }}
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                  {/* <label>Competitor Data</label> */}
                                  <span
                                    className={`checkbox-category ${
                                      competitor ? "" : "no-category"
                                    }`}
                                  >
                                    Top{" "}
                                    <>
                                      <div
                                        className={`top-n-competitor ${
                                          competitor
                                            ? ""
                                            : "no-category disabled-pointer"
                                        }`}
                                        style={{ width: "30px !important" }}
                                      >
                                        <input
                                          className={`${
                                            topN < topNMaxMin?.min_value &&
                                            competitor
                                              ? "error-top-n"
                                              : ""
                                          }`}
                                          type="number"
                                          onWheel={() =>
                                            document.activeElement.blur()
                                          }
                                          min={topNMaxMin?.min_value || 1}
                                          max={Number.MAX_VALUE}
                                          value={
                                            selectedDataset?.top_n_competitor
                                          }
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => {
                                            let data = {
                                              activeDatasetForKpi:
                                                activeDatasetForKpi,
                                              modelId: activeModelId,
                                              selectedDataset,
                                              dataset: selectedDataset
                                                ? {
                                                    ...selectedDataset,
                                                    minValue:
                                                      topNMaxMin?.min_value,
                                                  }
                                                : activeDataset,
                                              key: "top_n_competitor",
                                              value: +e.target.value,
                                            };
                                            setTopN(+e.target.value);
                                            updateDatasetLocalParameters(data);
                                          }}
                                        />
                                      </div>
                                    </>
                                    Competitors
                                  </span>
                                </div>
                              </div>
                              {topN < topNMaxMin?.min_value && competitor && (
                                <p className="error-message">{`Minimum value should be ${topNMaxMin.min_value}`}</p>
                              )}
                            </div>
                          )}

                        {activeDataset &&
                          !disableFlag &&
                          config.hardCoded.selectClearAllDatasetList.some(
                            (item) =>
                              item.toLocaleLowerCase() ===
                              activeDataset.type.toLowerCase()
                          ) && (
                            <div
                              className={`kpi-action-texts header-text ${
                                activeDataset?.pipeline_dataset_id !==
                                  config.hardCoded.neilsenNonCompId &&
                                config.hardCoded.competitorArr.includes(
                                  activeDataset?.pipeline_dataset_id
                                ) &&
                                config.hardCoded.autoMlIds.some(
                                  (elem) =>
                                    elem.toLowerCase() ===
                                    activeModelId.toLowerCase()
                                )
                                  ? "checkbox-present"
                                  : "checkbox-absent"
                              }`}
                            >
                              <p
                                onClick={() => {
                                  let data = {
                                    modelId: activeModelId,
                                    selectedDataset,
                                    dataset: activeDataset,
                                    kpiList: activeDatasetForKpi.kpi_list,
                                    activeModel: activeModel,
                                    kpi_variables_list:
                                      activeDatasetForKpi.kpi_variables_list,
                                  };
                                  selectAll(data);
                                }}
                              >
                                Select All
                              </p>
                              <p
                                onClick={() => {
                                  let data = {
                                    modelId: activeModelId,
                                    selectedDataset,
                                    dataset: activeDataset,
                                    kpiList: [],
                                    activeModel: activeModel,
                                  };
                                  clearAll(data);
                                }}
                              >
                                Clear All
                              </p>
                            </div>
                          )}
                      </div>
                      <div className="kpi-container">
                        {(config.hardCoded.nielsenDatasets.includes(
                          activeDatasetId
                        ) &&
                          configUserInputs.categoryData !== -1) ||
                        (!config.hardCoded.nielsenDatasets.includes(
                          activeDatasetId
                        ) &&
                          configUserInputs?.datasetDetailsData?.status ===
                            "success") ||
                        configUserInputs.configType === "run" ? (
                          activeDataset &&
                          config.hardCoded.automlCustomDataset.some(
                            (item) =>
                              item.toLowerCase() ===
                              activeDataset.type.toLowerCase()
                          ) &&
                          config.hardCoded.autoMlRbtAndSUIds.includes(
                            activeModelId
                          ) ? (
                            activeDatasetForKpi && (
                              <AutoMLNeilsenDataset
                                activeModel={activeModel}
                                key="automl-sales-category-dataset"
                                match={match}
                                activeDatasetId={activeDatasetId}
                                setActiveDatasetId={setActiveDatasetId}
                                disableFlag={disableFlag}
                                activeModelId={activeModelId}
                                activeDatasetForKpi={activeDatasetForKpi}
                                selectedDataset={selectedDataset}
                                activeDataset={activeDataset}
                                kpiTypesData={kpiTypesData}
                                updateDatasetLocalParameters={
                                  updateDatasetLocalParameters
                                }
                                selectedModelDatasets={selectedModelDatasets}
                                activeDatasetDates={activeDatasetDates}
                                configUserInputs
                              />
                            )
                          ) : config.hardCoded.mmlIds.includes(
                              activeModelId
                            ) ? (
                            activeDatasetForKpi && (
                              <MMTSalesDatasetKpiSelection
                                activeModel={activeModel}
                                key="mmt-sales-dataset-kpi-selections"
                                match={match}
                                activeDatasetId={activeDatasetId}
                                setActiveDatasetId={setActiveDatasetId}
                                disableFlag={disableFlag}
                                activeModelId={activeModelId}
                                activeDatasetForKpi={activeDatasetForKpi}
                                selectedDataset={selectedDataset}
                                activeDataset={activeDataset}
                                kpiTypesData={kpiTypesData}
                                selectedModelDatasets={selectedModelDatasets}
                                activeDatasetDates={activeDatasetDates}
                              />
                            )
                          ) : (
                            <div className="kpi-selections-container">
                              {activeDatasetForKpi &&
                                kpiTypesData.map((value) => {
                                  return (
                                    <KpiType
                                      activeModel={activeModel}
                                      key={value.id}
                                      match={match}
                                      kpi={value}
                                      activeDatasetId={activeDatasetId}
                                      setActiveDatasetId={setActiveDatasetId}
                                      disableFlag={disableFlag}
                                      activeModelId={activeModelId}
                                      activeDatasetForKpi={activeDatasetForKpi}
                                      selectedDataset={selectedDataset}
                                      activeDataset={activeDataset}
                                      ui_element_type={value.type}
                                      activeDatasetDates={activeDatasetDates}
                                    />
                                  );
                                })}
                            </div>
                          )
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </div>
                    <div className="datasets-selections-local-parameters-item">
                      <p className="dataset-parameter-text">
                        Select Dataset Parameters
                      </p>
                      <div className="dataset-parameter-container">
                        {activeDataset?.type ===
                        config.hardCoded.datasetSalesType ? (
                          <>
                            {salesDatasetStatus === "loading" && <Loader />}
                            {salesDatasetStatus === "success" && (
                              <>
                                {selectedDataset?.periodicity ? (
                                  <LocalParameterItem
                                    ui_element_type="dropdown"
                                    value={selectedDataset?.periodicity}
                                    enable={false}
                                    show={true}
                                    name="Periodicity"
                                    id="periodicity"
                                    data={[
                                      {
                                        id: selectedDataset?.periodicity,
                                        name: selectedDataset?.periodicity,
                                      },
                                    ]}
                                    userInputs={configUserInputs}
                                  />
                                ) : validPeriodicityForDataset ? (
                                  <LocalParameterItem
                                    ui_element_type="dropdown"
                                    value={
                                      selectedDataset?.periodicity
                                        ? selectedDataset?.periodicity
                                        : ""
                                    }
                                    enable={false}
                                    show={true}
                                    name="Periodicity"
                                    id="periodicity"
                                    data={[
                                      {
                                        id: activeDatasetDates?.periodicity,
                                        name: activeDatasetDates?.periodicity,
                                      },
                                    ]}
                                    userInputs={configUserInputs}
                                  />
                                ) : (
                                  <LocalParameterItem
                                    ui_element_type="dropdown"
                                    value={config.messages.noPeriodicity}
                                    enable={false}
                                    show={true}
                                    name="Periodicity"
                                    id="periodicity"
                                    data={[
                                      {
                                        id: config.messages.noPeriodicity,
                                        name: config.messages.noPeriodicity,
                                      },
                                    ]}
                                    userInputs={configUserInputs}
                                  />
                                )}
                                {!disableFlag && (
                                  <LocalParameterItem
                                    ui_element_type="autocomplete_search"
                                    value={selectedDataset?.sku || []}
                                    disableFlag={disableFlag}
                                    show={true}
                                    name="Select SKU"
                                    id="sku"
                                    data={configUserInputs?.skuData?.data}
                                    userInputs={configUserInputs}
                                    activeModelId={activeModelId}
                                    selectedDataset={selectedDataset}
                                    activeDataset={activeDataset}
                                    activeDatasetDates={activeDatasetDates}
                                    updateDatasetLocalParameters={
                                      updateDatasetLocalParameters
                                    }
                                  />
                                )}
                              </>
                            )}
                            {salesDatasetStatus === "error" && (
                              <p className="info-message">
                                {config.messages.somethingWentWrong}
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            {activeDataset &&
                            activeDataset.local_parameters.length ? (
                              activeDataset.local_parameters.map((obj) => {
                                let value = selectedDataset
                                  ? selectedDataset[obj.id]
                                  : undefined;
                                let onChange = (key, value) => {
                                  let data = {
                                    modelId: activeModelId,
                                    selectedDataset,
                                    dataset: selectedDataset
                                      ? selectedDataset
                                      : activeDataset,
                                    key,
                                    value,
                                  };
                                  config.hardCoded.nielsenDatasets.includes(
                                    activeDatasetId
                                  )
                                    ? onChangeCategory({
                                        ...data,
                                        skuData:
                                          configUserInputs?.skuData?.data,
                                      })
                                    : updateDatasetLocalParameters(data);
                                };

                                const categoryStatus = (statusCode) =>
                                  configUserInputs?.categoryData !== -1 &&
                                  configUserInputs?.categoryData.find(
                                    (el) => el.id === statusCode
                                  );
                                return (
                                  <>
                                    {obj.id === "category" &&
                                      activeDatasetId ===
                                        config.hardCoded.neilsenNonCompId && (
                                        <div className="category-error-message">
                                          {categoryStatus("aide-400")
                                            ? config.messages.statusMessage400
                                            : categoryStatus("aide-400a")
                                            ? config.messages.statusMessage400a
                                            : ""}
                                        </div>
                                      )}
                                    <div
                                      className={`datasets-selections-local-parameters-row ${
                                        disableFlag ? "disabled-pointer" : ""
                                      } ${
                                        categoryStatus("aide-400") &&
                                        activeDatasetId ===
                                          config.hardCoded.neilsenNonCompId
                                          ? "no-category"
                                          : ""
                                      }`}
                                      key={obj.id}
                                    >
                                      <LocalParameterItem
                                        {...obj}
                                        value={
                                          value !== "aide-400"
                                            ? value
                                            : "No Categories"
                                        }
                                        onChange={onChange}
                                        userInputs={configUserInputs}
                                        disableFlag={disableFlag}
                                        match={match}
                                        activeModel={activeModel}
                                        activeDataset={activeDataset}
                                        activeDatasetId={activeDatasetId}
                                        updateDatasetLocalParameters={
                                          updateDatasetLocalParameters
                                        }
                                        selectedDataset={selectedDataset}
                                        activeModelId={activeModelId}
                                        selectedModelDatasets={
                                          selectedModelDatasets
                                        }
                                      />
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <div className="no-data-holder">
                                <img src={noDataImage} />
                                {config.messages.nodatasetParameters}
                              </div>
                              // <div className="no-data-holder">
                              //   <i className="material-icons-round no-datasets-icon">
                              //     warning_amber
                              //   </i>
                              //   <p className="no-data">{config.messages.noConfigs}</p>
                              // </div>
                            )}
                          </>
                        )}
                        {configUserInputs?.skuData?.status === "loading" &&
                          activeDataset?.type ===
                            config.hardCoded.datasetNeilsenType && <Loader />}
                        {disableFlag ? (
                          selectedDataset?.sku ? (
                            <LocalParameterItem
                              ui_element_type="autocomplete_search"
                              value={selectedDataset?.sku || []}
                              disableFlag={disableFlag}
                              show={true}
                              name="Select SKU"
                              id="sku"
                              data={selectedDataset?.sku}
                              userInputs={configUserInputs}
                              activeModelId={activeModelId}
                              selectedDataset={selectedDataset}
                              activeDataset={activeDataset}
                              activeDatasetDates={activeDatasetDates}
                              updateDatasetLocalParameters={
                                updateDatasetLocalParameters
                              }
                            />
                          ) : null
                        ) : (
                          configUserInputs?.skuData?.status === "success" &&
                          activeDataset?.type ===
                            config.hardCoded.datasetNeilsenType &&
                          configUserInputs.categoryData !== -1 && (
                            <>
                              {disableFlag ? (
                                selectedDataset?.sku ? (
                                  <LocalParameterItem
                                    ui_element_type="autocomplete_search"
                                    value={selectedDataset?.sku || []}
                                    disableFlag={disableFlag}
                                    show={true}
                                    name="Select SKU"
                                    id="sku"
                                    data={selectedDataset?.sku}
                                    userInputs={configUserInputs}
                                    activeModelId={activeModelId}
                                    selectedDataset={selectedDataset}
                                    activeDataset={activeDataset}
                                    activeDatasetDates={activeDatasetDates}
                                    updateDatasetLocalParameters={
                                      updateDatasetLocalParameters
                                    }
                                  />
                                ) : null
                              ) : (
                                <LocalParameterItem
                                  ui_element_type="autocomplete_search"
                                  value={selectedDataset?.sku || []}
                                  disableFlag={disableFlag}
                                  show={true}
                                  name="Select SKU"
                                  id="sku"
                                  data={configUserInputs?.skuData?.data}
                                  userInputs={configUserInputs}
                                  activeModelId={activeModelId}
                                  selectedDataset={selectedDataset}
                                  activeDataset={activeDataset}
                                  activeDatasetDates={activeDatasetDates}
                                  updateDatasetLocalParameters={
                                    updateDatasetLocalParameters
                                  }
                                />
                              )}
                            </>
                          )
                        )}
                        {configUserInputs?.skuData?.status === "error" && (
                          <p className="info-message">
                            {config.messages.somethingWentWrong}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!activeDataset && (
                <MergedDatasetKPI
                  configUserInputs={configUserInputs}
                  activeDatasetId={activeDatasetId}
                  activeModel={activeModel}
                  activeModelId={activeModelId}
                  match={match}
                  setActiveDatasetId={setActiveDatasetId}
                  disableFlag={disableFlag}
                  data={data}
                  activeDatasetForKpi={activeDatasetForKpi}
                />
              )}
            </div>
          ) : (
            <div className="no-datasets-holder">
              <img src={noDataImage} />
              {config.messages.noDatasets}
            </div>
            // <div className="no-data-holder">
            //   <i className="material-icons-round no-datasets-icon">
            //     warning_amber
            //   </i>
            //   <p className="no-data">{config.messages.noDatasets}</p>
            // </div>
          )}
        </div>
      )}
      {showMergedPopover && (
        <MergedDataset
          open={showMergedPopover}
          activeAppID={activeAppID}
          activeModelId={activeModelId}
          setMergedPopover={setMergedPopover}
          configUserInputs
          mergedDS={mergedDS}
          datasetsTypesData={datasetsTypesData}
          selectedModel={selectedModel}
          disableFlag={disableFlag}
        />
      )}
      <Modal
        modalComponent={modalComponent}
        setModalComponent={setModalComponent}
        handleDialogClose={handleDialogClose}
      />
    </div>
  );
}

Dataset.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
  changeFlag: state.changeFlag,
});

const mapDispatchToProps = {
  addDataset,
  removeDataset,
  addKpi,
  removeKpi,
  updateDatasetLocalParameters,
  selectAll,
  clearAll,
  onChangeCategory,
  deleteMergeDataset,
  updateCategoryKpi,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dataset);
