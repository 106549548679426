//Import required libraies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dialog } from "../../../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";

//Import Custom Component
import CustomFileInput from "./CustomFileInput";
import Loader from "../../../../components/Loader/Loader";
import KpiDetails from "./KpiDetails";
import KpiTypeForManualMMT from "./KpiTypeForManualMMT";

// Import custom hooks

// Import action creators
import {
  updateUserInfo,
  updateAlertInfo,
  updateModelLocalParameters,
} from "../../../../redux/actions";

// Import utils
import { isEqualArray } from "../../../../utils/utils";

//Import data
import runSuccess from "../../../../../assets/images/run-success.svg";
import runError from "../../../../../assets/images/run-error.svg";
import uploadFile from "../../../../../assets/images/upload-file.svg";
import addUploadFile from "../../../../../assets/images/addUpload-file.svg";
import { config } from "../../../../config/config";

// Import styles
import { muiCommonStyles } from "../../../../../styles/styles";
import CollinearVariable from "./CollinearVariable";

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
      maxWidth: "250px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFormControl-fullWidth": {
      // minWidth: "200px",
      // maxWidth: "900px",
      width: "100%",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri !important",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-option": {
      backgrounColor: "#46596a3b",
      fontFamily: "Hind Siliguri !important",
    },
  },
  root: {
    marginTop: "10px",
    fontFamily: "Hind Siliguri !important",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

function UploadFilesRegions(props) {
  const {
    testRegions,
    activeModel,
    userInputs,
    onChange,
    onChangeFile,
    updateAlertInfo,
    value,
    index,
    getControlRegion,
    updateConfigUserInputs,
    selRegionGranularity,
    updateConfig,
    regionGranularityValue,
    setError,
    deleteCellOperation,
    configUserInputs,
    updateModelLocalParameters,
  } = props;
  const classes = useStyles();
  const modelConfig = userInputs.models.find(
    (model) => model.id === activeModel.id
  ).config;

  const [uploadState, setUploadState] = useState("");
  const [fileName, setFileName] = useState(
    modelConfig?.region_filters?.files?.input?.[0] || ""
  );
  const [kpiDetailsAvailable, setKpiDetailsAvailable] = useState(
    value?.files?.kpi_details &&
      Object.keys(value?.files?.kpi_details).length !== 0
      ? "success"
      : ""
  );

  let initialActiveRegionsInfo = {};
  if (activeModel.id === config.hardCoded.mmtId) {
    initialActiveRegionsInfo = {
      path: modelConfig?.files?.input?.[0] || "",
      temp_uuid: userInputs?.temp_uuid || "",
    };
  } else {
    initialActiveRegionsInfo = {
      path: value?.files?.input?.[0] || "",
      temp_uuid: userInputs?.temp_uuid || "",
    };
  }
  const [activeRegionInfo, setActiveRegionInfo] = useState(
    initialActiveRegionsInfo
  );
  const [uiError, setUiError] = useState("");
  const muiClass = muiCommonStyles();
  const mmtConfigData =
    userInputs.models.find((model) => model.id === config.hardCoded.mmtId)
      .config || {};

  useEffect(() => {
    if (activeRegionInfo.path) {
      const fileName = activeRegionInfo.path.split("/");
      setUploadState("success");
      setFileName(fileName[fileName.length - 1]);
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (activeModel.id === config.hardCoded.mmtId) {
      if (
        !mmtConfigData?.files?.minimum_datapoint_details?.find(
          (elem) => elem.id === mmtConfigData?.minimum_datapoint_column
        )
      ) {
        onChange("minimum_datapoint_column", "");
      }
    }
    return () => {};
  }, [mmtConfigData.files?.minimum_datapoint_details]);

  const uploadFileValidate = (formData, regionIndex, fileName) => {
    // api for post the file selected file in data base
    const url = config.api.uploadValidateFilesUrl;
    // const url = "https://run.mocky.io/v3/d9c94a17-3659-4095-9ebd-42843944b43a";

    let status;
    fetch(url, {
      method: activeRegionInfo?.path ? "PUT" : "POST",
      body: formData,
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        if ([200, 202].includes(status)) {
          setKpiDetailsAvailable("loading");
          if (
            selRegionGranularity?.toLowerCase() !== "national" &&
            selRegionGranularity
          ) {
            setError(null);
          }
          setFileName(fileName);
          if (activeModel.id === config.hardCoded.mmtId) {
            onChange("files", {
              input: [json.path],
              minimum_datapoint_details: json.minimum_datapoint_check.map(
                (elem) => ({ id: elem, name: elem })
              ),
            });
            updateConfigUserInputs("region_count", json.region_count);
          } else if (selRegionGranularity?.toLowerCase() === "national") {
            updateConfig("region_granularity", {
              ...regionGranularityValue,
              ...(config.hardCoded.autoMlIds.includes(activeModel.id)
                ? {
                    test_regions: {
                      ...regionGranularityValue.test_regions,
                      files: {
                        input: [json.path],
                        ...(config.hardCoded.autoMlIds.includes(
                          activeModel.id
                        ) && {
                          kpi_details: json.kpi_details,
                          seasonality_flag: json.seasonality_flag,
                          collinear_drivers: json.collinear_drivers,
                        }),
                      },
                    },
                  }
                : config.hardCoded.rbtAndSuIds.includes(activeModel.id)
                ? {
                    test_dark_regions: {
                      ...regionGranularityValue.test_dark_regions,
                      files: {
                        input: [json.path],
                        ...(config.hardCoded.autoMlIds.includes(
                          activeModel.id
                        ) && {
                          kpi_details: json.kpi_details,
                        }),
                      },
                    },
                  }
                : {
                    test_control_regions: {
                      ...regionGranularityValue.test_control_regions,
                      files: {
                        input: [json.path],
                        ...(config.hardCoded.autoMlIds.includes(
                          activeModel.id
                        ) && {
                          kpi_details: json.kpi_details,
                        }),
                      },
                    },
                  }),
              // test_control_regions: {
              //   ...regionGranularityValue.test_control_regions,
              //   files: {
              //     input: [json.path],
              //     ...(config.hardCoded.autoMlIds.includes(activeModel.id) && {
              //       kpi_details: json.kpi_details,
              //     }),
              //   },
              // },
            });
          } else {
            let isVolumeDetailsCorrect = true;
            let isColumnDetailCorrect = true;
            // getting control cells information for diff in diff model
            if (activeModel.id === config.hardCoded.diffDiffId) {
              let testPath = [];
              testRegions.forEach((elem, index) => {
                if (elem?.files?.input?.[0] && index !== regionIndex) {
                  testPath.push(elem?.files?.input?.[0]);
                }
                if (elem?.files?.input?.[0] && index !== regionIndex) {
                  isVolumeDetailsCorrect = isEqualArray(
                    elem?.files?.volume_details?.sort(),
                    json?.volume_details.sort()
                  );
                  if (!isVolumeDetailsCorrect) {
                    updateAlertInfo({
                      open: true,
                      message:
                        "Found mismatch in volume details for Diff in Diff model",
                      severity: "error",
                    });
                  }
                }
              });
              getControlRegion([...testPath, json.path]);
            }
            if (config.hardCoded.rbtAndSuIds.includes(activeModel.id)) {
              let testPath = [];
              testRegions.forEach((elem, index) => {
                if (elem?.files?.input?.[0] && index !== regionIndex) {
                  testPath.push(elem?.files?.input?.[0]);
                }
                // if (elem?.files?.input?.[0] && index !== regionIndex) {
                //   isColumnDetailCorrect = isEqualArray(
                //     elem?.files?.column_details?.sort(),
                //     json?.column_details.sort()
                //   );
                //   if (!isColumnDetailCorrect) {
                //     updateAlertInfo({
                //       open: true,
                //       message: `Found mismatch in column details for ${activeModel.id} model`,
                //       severity: "error",
                //     });
                //   }
                // }
              });
              const filterValue = modelConfig?.cols_to_force_in.filter((e) =>
                json?.column_details.includes(e)
              );
              updateModelLocalParameters({
                modelId: activeModel.id,
                key: "cols_to_force_in",
                value:
                  !modelConfig?.cols_to_force_in.includes("dark_sales") &&
                  json?.column_details.includes("dark_sales")
                    ? [...filterValue, "dark_sales"]
                    : filterValue,
              });
            }
            onChangeFile("files", {
              input: [json.path],
              ...(config.hardCoded.autoMlIds.includes(activeModel.id) && {
                kpi_details: json.kpi_details,
                seasonality_flag: json.seasonality_flag,
                collinear_drivers: json.collinear_drivers,
              }),
              ...(config.hardCoded.diffDiffId === activeModel.id && {
                volume_details: json.volume_details || [],
              }),
              ...(config.hardCoded.rbtAndSuIds.includes(activeModel.id) && {
                column_details: json.column_details || [],
              }),
            });
          }
          setActiveRegionInfo({
            ...activeRegionInfo,
            path: json.path,
          });
          updateConfigUserInputs(
            "temp_uuid",
            json.temp_uuid ? json.temp_uuid : userInputs?.temp_uuid
          );
          setUploadState("success");
          setKpiDetailsAvailable("success");
          if (status === 202) {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "warning",
            });
          }
        } else {
          setUploadState("error");
          setUiError(json.statusMessage);
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
        console.groupEnd();
      });
  };

  const handleFileChange = (e, option) => {
    setUiError("");
    const formData = new FormData();

    let regionIndex = 0;
    if (activeModel.id !== config.hardCoded.mmtId) {
      regionIndex = index;
    }

    formData.set("app_id", userInputs?.app_id);
    formData.set("temp_uuid", userInputs?.temp_uuid || "");
    formData.set("model_id", activeModel?.id);
    formData.set("mode", userInputs?.configType);
    formData.set("config_id", userInputs?.configId || "");
    if (activeModel.id === config.hardCoded.autoMlId) {
      let regionType =
        selRegionGranularity?.toLowerCase() === "national"
          ? "national"
          : "regional";
      formData.set("region_type", regionType);
    }
    if (
      value?.type === "test" ||
      activeModel.id === config.hardCoded.mmtId ||
      selRegionGranularity?.toLowerCase() === "national"
    ) {
      formData.set("test_cell_no", regionIndex);
    }
    if (value?.type === "dark") {
      formData.set("dark_cell_no", regionIndex);
    }
    if (value?.type === "control") {
      formData.set("control_cell_no", regionIndex);
    }
    formData.set("path", activeRegionInfo?.path);
    formData.set("type_of_file", "input");

    if (option === "select") {
      if (/\s/.test(e.target?.files?.[0]?.name)) {
        updateAlertInfo({
          open: true,
          message: config.messages.fileNameSpacesMessage,
          severity: "error",
        });
      } else {
        setUploadState("loading");
        formData.append("selectedAttachments", e.target.files?.[0]);
        uploadFileValidate(formData, regionIndex, e.target?.files?.[0]?.name);
      }
    }
    if (option === "drop") {
      if (/\s/.test(e.dataTransfer.files?.[0]?.name)) {
        updateAlertInfo({
          open: true,
          message: config.messages.fileNameSpacesMessage,
          severity: "error",
        });
      } else {
        setUploadState("loading");
        setFileName(e.dataTransfer.files?.[0]?.name);
        formData.append("selectedAttachments", e.dataTransfer.files?.[0]);
        uploadFileValidate(formData, regionIndex, e.target?.files?.[0]?.name);
      }
    }
  };

  const isFileUploadDisabled = () => {
    if (uploadState === "loading") {
      return true;
    }
    return false;
  };

  const downLoadFile = () => {
    const url = config.api.downloadUrl;
    let status;
    const body = {
      app_id: config.hardCoded.mroiAppId,
      type:
        configUserInputs?.configType === "run"
          ? "manual_runs"
          : "manual_configs",
      path: activeRegionInfo?.path,
    };
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          let a = document.createElement("a");
          a.href = json?.data?.location;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  return (
    <>
      <div className="commonMargin">
        <div style={{ display: "flex" }}>
          <p className="sub-heading">Upload your file here</p>
          <span style={{ padding: "0px 5px" }}> | </span>
          <p className="sub-heading">
            Minimum Data Points: {activeModel.minimum_data_points}
          </p>
        </div>
        <CustomFileInput
          accept={activeModel.id === "MMT" ? ".csv, .xlsx" : ".csv"}
          onChange={handleFileChange}
          uploadState={uploadState}
          fileName={fileName}
          disabled={isFileUploadDisabled()}
          setUiError={setUiError}
          setUploadState={setUploadState}
          id={value?.id}
          updateAlertInfo={updateAlertInfo}
          configUserInputs={configUserInputs}
          downLoadFile={downLoadFile}
        >
          <div className={`${muiClass.uploadFileClass}`}>
            {uploadState !== "loading" &&
              uploadState !== "success" &&
              !fileName &&
              uploadState !== "error" && (
                <div className="uploadImageContainer">
                  <div className="imageContainer">
                    <img src={uploadFile} alt="" draggable="false" />
                    <p>Drag and Drop</p>
                  </div>
                  <div className="orContainer">or</div>
                  <div className="imageContainer">
                    <img src={addUploadFile} alt="" draggable="false" />
                    <p>Upload from local</p>
                  </div>
                </div>
              )}

            {uploadState === "loading" && (
              <>
                <div className="loaderImageContainer">
                  <img src={addUploadFile} alt="" draggable="false" />
                  <p
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={downLoadFile}
                  >
                    {fileName}
                  </p>
                </div>
                <div className="loaderBox">
                  {/* <UploadLoader /> */}
                  <p className="loadingText">
                    Please wait while we validate the uploaded file.
                  </p>
                </div>
              </>
            )}
            {uploadState === "error" && (
              <>
                <div className="loaderImageContainer">
                  <img src={addUploadFile} alt="" draggable="false" />
                  <p
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={downLoadFile}
                  >
                    {fileName}
                  </p>
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    color: "red",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={runError} alt="" draggable="false" />
                  <p
                    style={{
                      fontSize: "10px",
                      marginRight: "10px",
                      color: "red",
                    }}
                  >
                    {uiError}
                  </p>
                </div>
              </>
            )}
            {uploadState === "success" && (
              <>
                <div className="loaderImageContainer">
                  <img src={addUploadFile} alt="" draggable="false" />
                  <p
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={downLoadFile}
                  >
                    {fileName}
                  </p>
                </div>
                <div className="uploadedContainer">
                  <img
                    src={runSuccess}
                    title="Validated Successfully"
                    alt="success"
                    draggable="false"
                  />
                  <p>Validated Successfully</p>
                </div>
              </>
            )}
          </div>
        </CustomFileInput>
      </div>
      {activeModel.id === config.hardCoded.mmtId && (
        <KpiTypeForManualMMT
          mmtConfigData={mmtConfigData}
          onChange={onChange}
          userInputs={userInputs}
        />
      )}
      {kpiDetailsAvailable === "success" &&
        config.hardCoded.autoMlIds.includes(activeModel.id) && (
          <KpiDetails
            row={value}
            value={value?.files?.kpi_details || {}}
            onChange={onChangeFile}
            updateConfig={updateConfig}
            selRegionGranularity={selRegionGranularity}
            regionGranularityValue={regionGranularityValue}
            activeModel={activeModel}
          />
        )}
      {kpiDetailsAvailable === "success" &&
        config.hardCoded.autoMlIds.includes(activeModel.id) && (
          <CollinearVariable
            activeModel={activeModel}
            classes={classes}
            row={value}
            onChange={onChangeFile}
            updateConfig={updateConfig}
            selRegionGranularity={selRegionGranularity}
            regionGranularityValue={regionGranularityValue}
            configUserInputs={configUserInputs}
          />
        )}
      {kpiDetailsAvailable === "loading" &&
        config.hardCoded.autoMlIds.includes(activeModel.id) && <Loader />}
      <Dialog
        className={muiClass.dialog}
        disableEscapeKeyDown
        open={uploadState === "loading" || deleteCellOperation === "loading"}
        onBackdropClick={() => {}}
      >
        {(uploadState === "loading" || deleteCellOperation === "loading") && (
          <div
            style={{
              width: "350px",
              height: "75px",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
            <p style={{ margin: "0px", color: "#46596a" }}>
              Please wait while we validate the changes.
            </p>
          </div>
        )}
      </Dialog>
    </>
  );
}

const mapDispatchToProps = {
  updateAlertInfo,
  updateUserInfo,
  updateModelLocalParameters,
};

export default connect(null, mapDispatchToProps)(UploadFilesRegions);
