import LocalParameterItem from "./LocalParameterItem";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import {
  addManualKpi,
  deleteManualKpi,
  resetManualKpi,
} from "../../../../redux/actions";
import { config } from "../../../../config/config";
import { Chip } from "@material-ui/core";
import { useNonInitialEffect } from "../../../../hooks/useNonInitialEffect";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "190px",
    minWidth: "180px",
    // maxWidth: "180px",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a !important",
    backgroundColor: "white",
    alignItems: "center",
    display: "flex",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  disabled: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "190px",
    minWidth: "180px",
    // maxWidth: "180px",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a !important",
    backgroundColor: "#f3f3f3",
    alignItems: "center",
    display: "flex",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  menuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "0 10px",
  },
  listItem: {
    "& span.MuiTypography-body1": {
      fontSize: "12px",
      color: "#46596a",
      fontFamily: "Hind Siliguri",
      paddingRight: "5px",
    },
  },
  chipStyling: {
    marginTop: "0px",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    border: "1px solid #dedede",
    color: "white !important",
    height: "28px",
    borderRadius: "4px",
    width: "fit-content",
    margin: "0px 5px",
    background: "#4EAFB3",
    "&:focus": {
      background: "#4EAFB3",
    },
    "& .MuiChip-deleteIcon": {
      // width: "20px",
      fontSize: 20,
      border: "none",
      color: "white",
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function KpiTypeForManualMMT(props) {
  const {
    mmtConfigData,
    onChange,
    userInputs,
    addManualKpi,
    deleteManualKpi,
    resetManualKpi,
  } = props;
  const classes = useStyles();
  const [primaryKpi, setPrimaryKpi] = useState("");
  const [secondaryKpis, setSecondaryKpis] = useState([]);
  const [informativeKpis, setInformativeKpis] = useState([]);
  const fileLink = isEmpty(mmtConfigData?.files)
    ? ""
    : mmtConfigData?.files?.input[0];
  const dropDownData = mmtConfigData?.files?.minimum_datapoint_details || [];
  const primaryDropdownData = dropDownData.filter(
    (data) =>
      !secondaryKpis.includes(data.id) && !informativeKpis.includes(data.id)
  );
  const multipleChoiceDropdown = dropDownData.filter(
    (data) =>
      data.id !== mmtConfigData?.minimum_datapoint_column &&
      !informativeKpis.includes(data.id) &&
      !secondaryKpis.includes(data.id)
  );
  const mmtModel = userInputs.models.find(
    (model) => model.id === config.hardCoded.mmtId
  );
  const dataSource = mmtModel.data_sources;
  // to load the primary, secondary & informative kpi
  useEffect(() => {
    if (dataSource.length) {
      const manualDatasetKpiList = dataSource[0].kpi_list;
      const secondaryArr = [];
      const informativeArr = [];
      for (const key in manualDatasetKpiList) {
        if (
          manualDatasetKpiList[key].kpi_type ===
          config.hardCoded.kpiTypeSecondary
        ) {
          secondaryArr.push(key);
        }
        if (
          manualDatasetKpiList[key].kpi_type ===
          config.hardCoded.kpiTypeInformative
        ) {
          informativeArr.push(key);
        }
        if (
          manualDatasetKpiList[key].kpi_type === config.hardCoded.kpiTypePrimary
        ) {
          if (key !== "undefined") {
            setPrimaryKpi(key || "");
          }
        }
      }
      setSecondaryKpis(secondaryArr);
      setInformativeKpis(informativeArr);
    }
  }, []);
  //Resetting the kpi when new file is uploaded
  useNonInitialEffect(() => {
    setPrimaryKpi("");
    setSecondaryKpis([]);
    setInformativeKpis([]);
    resetManualKpi({ modelId: config.hardCoded.mmtId });
  }, [fileLink]);
  const delValue = (key, value) => {
    if (key === config.hardCoded.kpiTypeSecondary) {
      const filterSecondaryKpi = secondaryKpis.filter((kpi) => kpi !== value);
      setSecondaryKpis(filterSecondaryKpi);
    }
    if (key === config.hardCoded.kpiTypeInformative) {
      const filterInformativeKpi = informativeKpis.filter(
        (kpi) => kpi !== value
      );
      setInformativeKpis(filterInformativeKpi);
    }
    deleteManualKpi({ manualKpi: value });
  };
  return (
    <div className="kpi-type-for-mmt-container">
      <p>KPIs from the uploaded file</p>
      <div className="kpi-type-dropdown-container">
        <LocalParameterItem
          ui_element_type="dropdown"
          value={mmtConfigData?.minimum_datapoint_column || primaryKpi}
          onChange={(e, value) => {
            onChange(e, value);
            addManualKpi({
              kpi_type: config.hardCoded.kpiTypePrimary,
              manualKpi: value,
            });
          }}
          enable={
            userInputs.configType === "run"
              ? primaryKpi.length
              : dropDownData?.length
            // ? true
            // : false
          }
          show={true}
          name="Primary*"
          id={"minimum_datapoint_column"}
          data={
            userInputs.configType === "run"
              ? [{ id: primaryKpi, name: primaryKpi }]
              : primaryDropdownData
          }
          userInputs={userInputs}
        />
        {/* here */}
        <div className="multiple-kpi-selection-dropdown">
          <label>Secondary:</label>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            // value={dropDownData}
            value={secondaryKpis}
            onChange={(e, value) => {
              addManualKpi({
                kpi_type: config.hardCoded.kpiTypeSecondary,
                manualKpi: e.target.value.at(-1),
              });
              setSecondaryKpis([...e.target.value]);
            }}
            disabled={
              userInputs.configType === "run"
                ? !primaryKpi.length
                : !mmtConfigData?.minimum_datapoint_column
            }
            className={
              userInputs.configType === "run"
                ? primaryKpi.length
                  ? classes.multiSelect
                  : classes.disabled
                : mmtConfigData?.minimum_datapoint_column
                ? classes.multiSelect
                : classes.disabled
            }
            renderValue={(selected) => {
              return selected.join(", ");
            }}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {multipleChoiceDropdown?.map((item) => (
              <MenuItem
                key={item.name}
                value={item.name}
                className={classes.menuItem}
              >
                <ListItemText
                  primary={item.name}
                  className={classes.listItem}
                />
              </MenuItem>
            ))}
          </Select>
          <div>
            {secondaryKpis.map((reg) => (
              <Chip
                key={reg}
                label={reg}
                onDelete={() =>
                  delValue(config.hardCoded.kpiTypeSecondary, reg)
                }
                className={classes.chipStyling}
                deleteIcon={<i className="material-icons-outlined">clear</i>}
              />
            ))}
          </div>
        </div>
        <div className="multiple-kpi-selection-dropdown">
          <label>Informative:</label>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={informativeKpis}
            onChange={(e) => {
              addManualKpi({
                kpi_type: config.hardCoded.kpiTypeInformative,
                manualKpi: e.target.value.at(-1),
              });
              setInformativeKpis([...e.target.value]);
            }}
            disabled={
              userInputs.configType === "run"
                ? !primaryKpi.length
                : !mmtConfigData?.minimum_datapoint_column
            }
            className={
              userInputs.configType === "run"
                ? primaryKpi.length
                  ? classes.multiSelect
                  : classes.disabled
                : mmtConfigData?.minimum_datapoint_column
                ? classes.multiSelect
                : classes.disabled
            }
            renderValue={(selected) => {
              return selected.join(", ");
            }}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {multipleChoiceDropdown?.map((item) => (
              <MenuItem
                key={item.name}
                value={item.name}
                className={classes.menuItem}
              >
                <ListItemText
                  primary={item.name}
                  className={classes.listItem}
                />
              </MenuItem>
            ))}
          </Select>
          <div>
            {informativeKpis.map((reg) => (
              <Chip
                key={reg}
                label={reg}
                onDelete={() =>
                  delValue(config.hardCoded.kpiTypeInformative, reg)
                }
                className={classes.chipStyling}
                deleteIcon={<i className="material-icons-outlined">clear</i>}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  addManualKpi,
  deleteManualKpi,
  resetManualKpi,
};
export default connect(null, mapDispatchToProps)(KpiTypeForManualMMT);
