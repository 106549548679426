//Import required libraies
import React from "react";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

//Import Custom Component
import Loader from "../../../../components/Loader/Loader";

//Import utils
import { config } from "../../../../config/config";
import { getCurrentDate, convertEpochToDate } from "../../../../utils/utils";

// Import styles

const BlueRadio = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: "#4eafb3",
    "&$checked": {
      color: "#4eafb3",
    },
    "&$disabled": {
      color: "#46596a",
      opacity: 0.5,
    },
    "& .MuiSvgIcon-root": {
      height: 15,
      weight: 15,
    },
  },
  checked: {},
  disabled: {
    color: "#46596a",
    opacity: 0.5,
  },
})((props) => <Radio color="default" {...props} />);

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
  },
});

const GlobalWeekDateSelection = (props) => {
  let {
    data,
    customClassName,
    userInputs,
    id,
    setUserInputs,
    enable,
    disableFlag,
    globalParamsOnChange,
    globalParamsObj,
  } = props;

  let displayValue =
    userInputs.app_id === config.hardCoded.mroiAppId &&
    !userInputs.is_config_manual
      ? globalParamsObj[id]
      : userInputs[id];

  return (
    <>
      {data.length === -1 ? (
        <Loader />
      ) : (
        <>
          <div className={`${disableFlag ? "disabled-pointer" : ""}`}>
            <div
              className={`input-item row ${
                customClassName ? customClassName : ""
              } ${id}`}
            >
              {/* <p className="date-range-text">Date Range</p> */}
              <div className="radio-container">
                {data.map((obj) => {
                  return (
                    <div className="radio-icon" key={obj.id}>
                      <ThemeProvider theme={theme}>
                        <BlueRadio
                          value={obj.id}
                          checked={
                            displayValue
                              ? obj.id.toLowerCase() ===
                                displayValue.toLowerCase()
                              : false
                          }
                          onChange={(e) => {
                            if (
                              userInputs.app_id ===
                                config.hardCoded.mroiAppId &&
                              !userInputs.is_config_manual
                            ) {
                              globalParamsOnChange(id, e.target.value);
                            } else {
                              setUserInputs(id, e.target.value);
                            }
                          }}
                          name={id}
                          size="small"
                          disabled={enable}
                          disableRipple
                        />
                      </ThemeProvider>
                      <label className="radio-label bold testTransform">
                        {obj.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={`${id}`}>
              {config.hardCoded.historicWeek === displayValue ? (
                <>
                  <div className="input-item">
                    <input
                      type="number"
                      onWheel={() => document.activeElement.blur()}
                      min="1"
                      // value={userInputs.historic_week_data}
                      value={
                        userInputs.app_id === config.hardCoded.mroiAppId &&
                        !userInputs.is_config_manual
                          ? globalParamsObj.historic_week_data
                          : userInputs.historic_week_data
                      }
                      onChange={(e) => {
                        if (
                          userInputs.app_id === config.hardCoded.mroiAppId &&
                          !userInputs.is_config_manual
                        ) {
                          globalParamsOnChange(displayValue, +e.target.value);
                        } else {
                          setUserInputs(displayValue, e.target.value);
                          setUserInputs("startDate", "");
                          setUserInputs("endDate", "");
                        }
                        // setUserInputs(displayValue, e.target.value);
                        // setUserInputs("startDate", "");
                        // setUserInputs("endDate", "");
                      }}
                      id="date-range-input-start"
                    />
                  </div>
                </>
              ) : config.hardCoded.dateRange === displayValue ? (
                <>
                  <div className="date-range">
                    <div className="input-item">
                      <input
                        type="date"
                        min={convertEpochToDate(1514764800000, "YYYY-MM-DD")}
                        max={getCurrentDate()}
                        onKeyDown={(e) => e.preventDefault()}
                        value={
                          userInputs.app_id === config.hardCoded.mroiAppId &&
                          !userInputs.is_config_manual
                            ? globalParamsObj.startDate
                            : userInputs.startDate
                        }
                        onChange={(e) => {
                          if (
                            userInputs.app_id === config.hardCoded.mroiAppId &&
                            !userInputs.is_config_manual
                          ) {
                            globalParamsOnChange("startDate", e.target.value);
                          } else {
                            setUserInputs(config.hardCoded.historicWeek, 0);
                            setUserInputs("startDate", e.target.value);
                          }
                        }}
                        id="date-range-input-start"
                      />
                    </div>
                    <div className="input-item">
                      <input
                        type="date"
                        min={convertEpochToDate(1514764800000, "YYYY-MM-DD")}
                        max={getCurrentDate()}
                        onKeyDown={(e) => e.preventDefault()}
                        value={
                          userInputs.app_id === config.hardCoded.mroiAppId &&
                          !userInputs.is_config_manual
                            ? globalParamsObj.endDate
                            : userInputs.endDate
                        }
                        onChange={(e) => {
                          if (
                            userInputs.app_id === config.hardCoded.mroiAppId &&
                            !userInputs.is_config_manual
                          ) {
                            globalParamsOnChange("endDate", e.target.value);
                          } else {
                            setUserInputs(config.hardCoded.historicWeek, 0);
                            setUserInputs("endDate", e.target.value);
                          }
                          // setUserInputs(config.hardCoded.historicWeek, 0);
                          // setUserInputs("endDate", e.target.value);
                        }}
                        id="date-range-input-end"
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GlobalWeekDateSelection;
