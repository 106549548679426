//Import required libraies
import React, { useEffect, useState } from "react";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data
import { config } from "../../../../config/config";

function InputTextValidation(props) {
  let {
    id,
    name,
    enable,
    value,
    minValue,
    maxValue,
    onChange,
    customClassName,
    disabledForUploadAudience,
  } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    if (value === "") {
      if (id === "pagePath") setError(true);
    } else {
      setError(false);
    }
  }, [value]);

  return (
    <div
      className={`input-item ${customClassName ? customClassName : ""} ${id}`}
    >
      <label title={name}>{name}:</label>
      <input
        type="text"
        disabled={!enable || disabledForUploadAudience}
        value={value ? value : ""}
        onChange={(e) => {
          onChange(id, e.target.value);
        }}
      />
      {error && <p className="error">{config.inputTextValidationError}</p>}
    </div>
  );
}

export default InputTextValidation;
