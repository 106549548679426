//Import required libraies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

//Import Custom Component
import {
  addKpi,
  removeKpi,
  addToggleKpi,
  toggleKpi,
} from "../../../../redux/actions";

//Import utils/data
// import { getMandatoryKPIToTop } from "../../../../utils/utils";
import { config } from "../../../../config/config";
import mmtSalesDatasetMapping from "../../../../../assets/data/mmtSalesDatasetMapping.json";

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "100%",
    height: "30px",
    borderRadius: "5px",
    color: "#46596a",
    backgroundColor: "white",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "8px 12px !important",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
    },
  },
});
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function MMTSalesDatasetKpiSelection(props) {
  let {
    match,
    user,
    activeModel,
    ui_element_type,
    activeModelId,
    kpi,
    activeDatasetId,
    setActiveDatasetId,
    disableFlag,
    toggleDataset,
    activeDatasetForKpi,
    selectedDataset,
    activeDataset,
    addKpi,
    removeKpi,
    addToggleKpi,
    toggleKpi,
    kpiTypesData, // global dataset data
    selectedModelDatasets, // user selected data
    activeDatasetDates,
  } = props;

  const classes = useStyles();

  let newKpiList, singleSelectedKpiList;
  kpiTypesData.forEach(
    (data) =>
      (newKpiList = data?.kpi_list?.filter(
        (item) => config.hardCoded.mandatoryTargetVariable.indexOf(item) == -1
      ))
  );
  kpiTypesData.forEach((data) => (singleSelectedKpiList = data.kpi_list));

  // dropdown name -> display purpose
  let displayKpiForMMT = mmtSalesDatasetMapping
    .filter((elem) =>
      singleSelectedKpiList?.some(
        (item) => item.toLowerCase() === elem.kpis.toLowerCase()
      )
    )
    ?.map((elem) => elem.name);

  // 2 parts split-> radioArray, multiArray manages Nielsen/category datasets
  // we got kpi data which user has selected
  let radioArray = [],
    multiArray = [];
  if (selectedDataset && selectedDataset.kpi_list) {
    radioArray = selectedDataset.kpi_list.filter(
      (item) => config.hardCoded.mandatoryTargetVariable.indexOf(item) !== -1
    );
    multiArray = selectedDataset.kpi_list.filter(
      (item) => config.hardCoded.mandatoryTargetVariable.indexOf(item) == -1
    );
  }

  //To check which target kpi were selected
  let availableTargetKpi = displayKpiForMMT.find(
    (val) => radioArray.join(", ") == val
  );

  // console.groupCollapsed("MMTSalesDataset");
  // console.log("displayKpiForMMT", displayKpiForMMT);
  // console.log("kpiTypesData", kpiTypesData);
  // console.log("newKpiList", newKpiList);
  // console.groupEnd();

  return (
    <>
      {displayKpiForMMT?.length ? (
        <div
          className={`input-item row target-variable ${
            disableFlag ? "disabled-pointer" : ""
          } `}
        >
          <label>Select Sales Variable:</label>
          <Select
            className={classes.multiSelect}
            MenuProps={MenuProps}
            disabled={false}
            value={availableTargetKpi ? availableTargetKpi : ""}
            onChange={(e) => {
              let newVal = e.target.value?.split(/\s*,\s*/);
              let notSelectedKpiVariableList =
                activeDatasetForKpi.kpi_variables_list.filter((item) =>
                  newVal.includes(item.id)
                );
              let newKpiVariableList = [...notSelectedKpiVariableList];
              if (selectedDataset?.kpi_variables_list) {
                newKpiVariableList = [
                  ...newKpiVariableList,
                  ...selectedDataset?.kpi_variables_list,
                ];
              }
              let data = {
                modelId: activeModelId,
                selectedDataset,
                dataset: selectedDataset ? selectedDataset : activeDataset,
                kpiList: [...newVal, ...multiArray],
                activeModel: activeModel,
                activeDatasetDates,
                kpi_variables_list: newKpiVariableList,
              };
              addToggleKpi(data);
            }}
          >
            <MenuItem value="" disabled className={classes.singleMenuItem}>
              Please select option
            </MenuItem>
            {displayKpiForMMT.map((obj, index) => (
              <MenuItem
                key={index}
                value={obj}
                className={classes.singleMenuItem}
              >
                {obj}
              </MenuItem>
            ))}
          </Select>
        </div>
      ) : null}
      {newKpiList?.length ? (
        <div className="kpi-selections-container">
          <label className="bold">Select Other Variables:</label>
          <div className="input-variables-container">
            {newKpiList.map((value) => {
              let mandatoryKpi =
                activeDatasetForKpi.mandatory_kpi_list.includes(value);
              let checkedFlag = selectedDataset
                ? selectedDataset.kpi_list.includes(value)
                : false;
              return (
                <div key={value}>
                  <div
                    key={value}
                    className={`kpi-checkbox-container ${
                      disableFlag ? "disabled-pointer" : ""
                    } 
                    ${mandatoryKpi ? "mandatory-kpi" : ""}
                                      `}
                    onClick={() => {
                      let notSelectedKpiVariableList =
                        activeDatasetForKpi?.kpi_variables_list.filter(
                          (item) => item.id === value
                        );
                      let newKpiVariableList = [...notSelectedKpiVariableList];
                      if (selectedDataset?.kpi_variables_list) {
                        newKpiVariableList = [
                          ...newKpiVariableList,
                          ...selectedDataset?.kpi_variables_list,
                        ];
                      }
                      let data = {
                        modelId: activeModelId,
                        selectedDataset,
                        dataset: selectedDataset
                          ? selectedDataset
                          : activeDataset,
                        kpi: value,
                        activeModel: activeModel,
                        activeDatasetDates: activeDatasetDates,
                        kpi_variables_list: newKpiVariableList,
                      };
                      checkedFlag ? removeKpi(data) : addKpi(data);
                    }}
                  >
                    <i
                      className={`kpi-checkbox-icon
                      ${
                        mandatoryKpi
                          ? "material-icons active"
                          : checkedFlag
                          ? "material-icons active"
                          : "material-icons-outlined"
                      }`}
                    >
                      {mandatoryKpi
                        ? "check_box"
                        : checkedFlag
                        ? "check_box"
                        : "check_box_outline_blank"}
                    </i>
                    <label>{value}</label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
}

MMTSalesDatasetKpiSelection.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  addKpi,
  removeKpi,
  addToggleKpi,
  toggleKpi,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MMTSalesDatasetKpiSelection);
