// Import required libraries
import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
import { Button } from "../../uiTheme";
// import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

// Import styles
import "../ShareConfiguration/ShareConfigs.scss";

// Import utils
// import { config as appConfig } from "../../config/config";
import { getInitialsFromEmail } from "../../utils/utils";

// Import action creators
// import {
//   updateAlertInfo,
//   updateUserInfo,
//   updateSelections,
//   updateDialogInfo,
//   reloadRunData,
//   refreshData,
//   reloadConfigs,
// } from "../../redux/actions";

const useStyles = makeStyles({
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    fontSize: 11,
    padding: 5,
    height: 25,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
});

function ShareConfigCardComponent(props) {
  let { email, user_name, action, removeUserFromList } = props;
  const classes = useStyles();

  const changeAccessSelection = (val) => {
    removeUserFromList(email, user_name);
  };

  return (
    <>
      <div className="single-share-card">
        <div
          className="share-card-left"
          data-letters={getInitialsFromEmail(email, "@")}
        >
          <div className="share-card-email">
            <label className="primary-name">{user_name}</label>
            <label className="secondary-name">{email}</label>
          </div>
        </div>
        {typeof action === "string" ? (
          <div className="share-card-action-native">{action}</div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="outlined"
              // className={classes.outlined}
              style={{
                height: "25px",
                padding: "5px",
                fontSize: "11px",
                minWidth: "15px",
                borderColor: "#46596a",
                backgroundColor: "transparent",
              }}
              onClick={(e) => changeAccessSelection()}
            >
              Remove
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareConfigCardComponent);
