//Import required libraies
import React, { useEffect } from "react";

//Import custom components
import LocalParameterItem from "./LocalParameterItem";

//Import utils and data
import { config } from "../../../../config/config";
import { removeDupFromArray } from "../../../../utils/utils";

//Import actions

//Import styles

function VolumnField(props) {
  let { id, name, userInputs, value, onChange, changeFlag } = props;

  let modelData = userInputs.models.find(
    (model) => model.id === config.hardCoded.diffDiffId
  );
  let selectedDatasets = modelData.data_sources;
  let kpiData = removeDupFromArray(
    selectedDatasets.map((dataset) => dataset.kpi_list).flat()
  );

  useEffect(() => {
    if (kpiData.length) {
      kpiData.forEach((kpi) => {
        onChange(id, kpi.id);
      });
    } else {
      onChange(id, "");
    }
  }, [changeFlag.selectedKpi]);

  // transform array strings to array of objects for LocalparamItem
  kpiData = kpiData.map((kpi) => ({ id: kpi, name: kpi }));

  return (
    <LocalParameterItem
      ui_element_type="dropdown"
      value={kpiData.length ? value : config.messages.noOptions}
      onChange={onChange}
      enable={kpiData.length ? true : false}
      show={true}
      name={name}
      id={id}
      data={
        kpiData.length
          ? kpiData
          : [
              {
                id: config.messages.noOptions,
                name: config.messages.noOptions,
              },
            ]
      }
      userInputs={userInputs}
    />
  );
}

export default VolumnField;
