//Import required libraies
import React, { useEffect, useState } from "react";
import Radio from "@material-ui/core/Radio";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Slider, Popover } from "../../../../uiTheme";
// import  Popover  from "@material-ui/core/Popover";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles
import "./SalesFilters.scss";
import { config } from "../../../../config/config";

// Import utils

//Import data

const useStyles = makeStyles((theme) => ({
  // sliderStyle: {
  //   width: "90%",
  //   // color: "#4eafb3",
  //   // "& .MuiSlider-rail": {
  //   //   height: 5,
  //   //   borderRadius: 4,
  //   // },
  //   // "& .MuiSlider-track": {
  //   //   height: 5,
  //   //   borderRadius: 4,
  //   // },
  //   // "& .MuiSlider-mark": {
  //   //   height: 5,
  //   // },
  //   "& .MuiSlider-thumb": {
  //     // height: 15,
  //     // width: 15,
  //     // backgroundColor: "#fff",
  //     // border: "2px solid currentColor",
  //     marginTop: -5,
  //     marginLeft: -12,
  //     // "&:focus, &:hover": {
  //     //   boxShadow: "inherit",
  //     // },
  //   },
  //   "& .MuiSlider-valueLabel": {
  //     top: "-22px",
  //     // "& *": {
  //     //   background: "transparent",
  //     //   color: "#4eafb3",
  //     // },
  //   },
  // },
  // popover: {
  //   pointerEvents: "none",
  //   fontFamily: "Hind Siliguri",
  //   fontSize: 12,
  //   width: "800px",
  //   margin: "10px",
  // },
  typography: {
    fontSize: 12,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
}));

function SalesFilters(props) {
  let { id, name, onChange, activeModel, userInputs, disableFlag, match } =
    props;

  let activeModelConfig = userInputs.models.find(
    (model) => model.id === activeModel.id
  ).config;

  const [value, setValue] = useState([
    activeModelConfig.sales_filter_min,
    activeModelConfig.sales_filter_max,
  ]);
  const [error, setError] = useState(null);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (Math.abs(newValue[0] - newValue[1]) > 50) {
      setError(true);
      onChange("sales_filter_min", newValue[0]);
      onChange("sales_filter_max", newValue[1]);
    } else {
      setError(false);
      onChange("sales_filter_min", newValue[0]);
      onChange("sales_filter_max", newValue[1]);
    }
  };

  return (
    <div className="content sales-params">
      <div
        className={`input-item top-margin ${
          disableFlag ? "disabled-pointer" : ""
        }`}
      >
        <label>{name}:</label>
        <div className="sales-radio-container">
          <Slider
            key={`range-slider`}
            value={value}
            //getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            //valueLabelDisplay="auto"
            step={10}
            min={0}
            max={100}
            onChange={handleChange}
            // className={classes.sliderStyle}
            valueLabelDisplay="on"
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            styleVariant="primary"
            theme="light"
          />
          <Popover
            id="mouse-over-popover"
            // className={classes.popover}
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <p className={classes.typography}>
              {config.messages.salesRangeMessage}
            </p>
          </Popover>
        </div>
        {/* show error message if user selectes range that exceeds 50 */}
        {/* {error && <p className="error">{config.salesRangeError}</p>} */}
      </div>
    </div>
  );
}

export default SalesFilters;
