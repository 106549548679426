//Import required libraies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//Import Custom Component
import Loader from "../../../components/Loader/Loader";

//Import tabs
import AddNewConfig from "./AddNewConfig";

// Import action creators
import {
  updateData,
  updateUserInfo,
  updateWholeConfigUserInputs,
  updateAlertInfo,
} from "../../../redux/actions";

//Import Custom Hooks

//Import utils/data

function AddNewConfigContainer(props) {
  let { match, disableFlag, runData } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {!loading ? (
        <AddNewConfig
          match={match}
          disableFlag={disableFlag}
          runData={runData}
        />
      ) : (
        <Loader />
      )}
    </>
  );
}

AddNewConfigContainer.propTypes = {
  match: PropTypes.object,
  allData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  updateData,
  updateUserInfo,
  updateWholeConfigUserInputs,
  updateAlertInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewConfigContainer);
