// Import required libraries
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

// Import custom components
import Loader from "../../../components/Loader/Loader";
import ViewrunsFilters from "../../AIDERun/ViewRunsSideDiv/ViewrunsFilters";
import ViewrunsCat from "../../AIDERun/ViewRunsSideDiv/ViewrunsCat";
import ViewRunsFiltersNew from "./ViewRunsFiltersNew";
import TableRowViewRun from "../../../components/TableRow/TableRowViewRun";
// import TableViewRun from "../../../components/Table/TableViewRun";
import noData from "../../../../assets/images/undraw_No_data.svg";
import FiltersNew from "../../../components/Filters/FilterNew";

// Import utils & data
import {
  makeDefaultResponseJson,
  getStartEndByDayCat,
} from "../../../utils/utils";
import { config } from "../../../config/config";

// Import actions
import {
  reloadRunData,
  updateRunData,
  updateSelections,
  updateUserInfo,
  updateData,
  updateSharedRunsData,
  updateAlertInfo,
} from "../../../redux/actions";

// Import styles
import "../../AIDERun/ViewRunsSideDiv/ViewRunsSideDiv.scss";
import "../AddNewConfig/components/ModelSelections.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AntTabs = withStyles({
  root: {
    borderBottom: "0px solid #e8e8e8",
    marginBottom: "12px",
    height: "40px",
    fontWeight: "700",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#eb367f",
    // width: "40% !important",
    "& > span": {
      width: "40% !important",
      backgroundColor: "EB367F",
    },
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 15,
    fontFamily: "Hind Siliguri",
    width: "fit-content !important",
    fontWeight: "700",
    // marginRight: theme.spacing(2),
    "&:hover": {
      color: "#eb367f",
      opacity: 1,
    },
    "&$selected": {
      color: "#eb367f",
      fontWeight: "700",
    },
    "&:focus": {
      color: "#eb367f",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(2),
  },
  demo1: {
    backgroundColor: "white",
  },
}));
function ViewRunsContainerNew(props) {
  let {
    match,
    user,
    allData,
    updateUserInfo,
    updateRunData,
    changeFlag,
    reloadRunData,
    updateData,
    updateSelections,
    updateSharedRunsData,
    updateAlertInfo,
  } = props;
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [loaderForSharedRuns, setLoaderForSharedRuns] = useState(false);
  const [allSharedRuns, setAllSharedRuns] = useState([]);

  const handleChangeTabs = (newValue) => {
    setValue(newValue);
  };

  let app = config.hardCoded.mroiAppId.toLowerCase();
  let activeApp =
    allData.apps.data.length > 0
      ? allData.apps.data.find(
          (obj) => obj.name.toLowerCase() === app.toLowerCase()
        )
      : null;

  const handleChange = (panel) => (event, newExpanded) => {
    updateSelections("viewrunCat", newExpanded ? panel : false);
  };

  const renderViewRunTableData = (val) => {
    let totalData =
      allData && allData.runData && allData.runData.length > 0
        ? allData.runData
        : [];
    let allDataForViewRun = [];
    totalData?.forEach((obj) => {
      // if (obj.created_by === user.preferred_username) {
      allDataForViewRun = [...allDataForViewRun, ...obj.data];
      // }
    });
    if (val == "All" && allDataForViewRun.length) {
      let dataForAllTab = allDataForViewRun.filter(
        (singleRunObj) => singleRunObj.created_by === user.preferred_username
      );
      return <TableRowViewRun title={""} match={match} data={dataForAllTab} />;
    }
    let allDataForStarredRuns = allDataForViewRun?.filter((singleRun) => {
      if (singleRun.created_by === user.preferred_username)
        return singleRun?.isStarred;
      else {
        return singleRun?.metadata?.shared_with?.find(
          (userForSingleRun) =>
            userForSingleRun.preferred_username === user.preferred_username
        )?.isStarred;
      }
    });
    if (val === "starred") {
      return (
        <TableRowViewRun
          title={""}
          match={match}
          data={allDataForStarredRuns}
        />
      );
    }
    let dataForSharedRuns = allDataForViewRun?.filter((obj) => {
      return obj.created_by !== user.preferred_username;
    });
    if (val === "shared_with_me_runs") {
      return (
        <TableRowViewRun title={""} match={match} data={dataForSharedRuns} />
      );
    }
    let allIsScheduledRuns = allDataForViewRun.filter(
      (obj) =>
        obj?.is_scheduled_run && obj.created_by === user.preferred_username
    );
    if (val === "is_scheduled_run") {
      return (
        <TableRowViewRun title={""} match={match} data={allIsScheduledRuns} />
      );
    }
    let reslData = totalData?.filter((obj) => {
      return obj.id === val;
    });
    let dataToPass = reslData[0]?.data?.filter(
      (singleResObj) => singleResObj.created_by === user.preferred_username
    );
    return (
      <TableRowViewRun
        title={reslData?.title}
        match={match}
        data={dataToPass}
      />
    );
  };

  //cleanup func when component unmounts
  useEffect(() => {
    setLoading(false);
    return () => {
      updateSelections("runFilters", {
        searchText: "",
        startTime: "",
        endTime: "",
        orderBy: "created_on",
        dayCat: "all",
        viewRunsTags: [],
      });
    };
  }, []);

  //Update runData if user changes filters
  useEffect(() => {
    if (activeApp) {
      updateRunData(null);
      let url = config.api.runUrl;
      let userRunFilters = user.selections.runFilters;
      let body = {
        app_id: activeApp?.id,
        offset: 0,
        limit: config.runsLimit,
        order_by: userRunFilters.orderBy,
        search_text: userRunFilters.searchText || null,
        country: null,
        brand: null,
        segment: null,
        models: [],
        tags: userRunFilters.viewRunsTags || [],
        category: config.hardCoded.viewRunsCategory,
        ...getStartEndByDayCat(userRunFilters.dayCat),
        is_shared: true,
      };
      let status;
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateRunData(json.data);
            updateSelections("tableOrderBy", "created_on");
            //if activeRunId doesn't exist in the data, redirect to viewruns page
            let runIdList = json.data.map((run) => run.id);
            if (user.selections.activeRunPayload) {
              if (!runIdList.includes(user.selections.activeRunPayload.id)) {
                history.push(`/home/viewruns`);
              }
            }
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  }, [user.selections.runFilters, changeFlag.runData]);

  const tabNames = [
    { name: "All", enable: true },
    { name: "Currently Running", enable: true },
    { name: "Queued", enable: true },
    {
      name: "Previous Runs",
      enable: true,
    },
    { name: "Starred Runs", enable: true },
    { name: "Shared With Me", enable: true },
    {
      name: "Scheduled Runs",
      enable: allData.appAccessDetails?.feature_list?.schedule_run,
    },
  ];

  return (
    <div className="view-runs-sidenav-container">
      {/* <ViewRunsFiltersNew match={match} /> */}
      <ViewrunsFilters match={match} activeApp={activeApp} />
      {allData && allData.runData && allData.runData.length ? (
        <div className={classes.root}>
          <div className={classes.demo1}>
            <div className="models-menu">
              {tabNames.map((tabName, index) => (
                <div
                  key={tabName.name}
                  className={`models-item ${
                    tabName.enable ? "schedule-enabled" : "schedule-disabled"
                  }`}
                  onClick={() => {
                    handleChangeTabs(index);
                  }}
                >
                  <div
                    className={`models-item-div ${
                      index === value ? "active" : ""
                    }`}
                  >
                    <p>{tabName.name}</p>
                  </div>
                </div>
              ))}
            </div>
            <TabPanel value={value} index={0}>
              {renderViewRunTableData("All")}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {renderViewRunTableData("running")}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {renderViewRunTableData("queued")}
            </TabPanel>
            <TabPanel value={value} index={3}>
              {renderViewRunTableData("previous_runs")}
            </TabPanel>
            <TabPanel value={value} index={4}>
              {renderViewRunTableData("starred")}
            </TabPanel>
            <TabPanel value={value} index={5}>
              {renderViewRunTableData("shared_with_me_runs")}
              {/* {loaderForSharedRuns ? (
                <Loader />
              ) : allData &&
                allData.sharedRunsData &&
                allData.sharedRunsData.length ? (
                <TableRowViewRun
                  title={""}
                  match={match}
                  data={allData?.sharedRunsData}
                />
              ) : (
                <div className="no-runs">
                  <img src={noData} />
                  <b>{config.messages.noRuns}</b>
                </div>
              )} */}
            </TabPanel>
            <TabPanel value={value} index={6}>
              {renderViewRunTableData("is_scheduled_run")}
            </TabPanel>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

ViewRunsContainerNew.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  changeFlag: state.changeFlag,
});

const mapDispatchToProps = {
  updateUserInfo,
  updateRunData,
  reloadRunData,
  updateSelections,
  updateData,
  updateSharedRunsData,
  updateAlertInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewRunsContainerNew);
