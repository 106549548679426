import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//import custom components
import DashboardCountryBrandSelection from "../DashboardCountryBrandSelections";
import Loader from "../Loader/Loader";
import RunAnalysisTable from "./RunAnalysisTable";
import noDataImage from "../../../assets/images/undraw_empty.svg";

//import css file
import "./RunAnalysis.scss";
import PowerBiReport from "./PowerBiReport";
import { config } from "../../config/config";
import { updateAlertInfo, updateUserInfo } from "../../redux/actions";

const initialState = {
  data: [],
  loading: false,
  status: "",
  message: "",
};
const initialCommentState = {
  open: false,
  validate_state: "",
  comment: "",
  loading: false,
};
function RunAnalysis(props) {
  const { isAuthorized, businessDashboard, user, updateAlertInfo } = props;
  const [data, setData] = useState(initialState);
  const [filterDataReports, SetFilterDataReports] = useState([]);
  const [countryBrandData, SetCountryBrandData] = useState({
    data: [],
    loading: true,
  });
  const [brand, setBrand] = useState("");
  const [country, setCountry] = useState("");
  const [pilotDate, setPilotDate] = useState("");
  let [expanded, setExpanded] = useState(false);
  const [rowElem, setRowElem] = useState(null);
  const [comment, setComment] = useState(initialCommentState);
  const [validator_info, setValidator_info] = useState([]);
  const [requestor_info, setRequestor_info] = useState({});
  const [loading, setLoading] = useState(true);
  //handler for go functionality
  const handlerForGo = () => {
    let filteredData =
      country !== "ALL"
        ? data.data.filter(
            (item) => item.country.toLowerCase() === country.toLowerCase()
          )
        : data.data;
    filteredData =
      brand !== "ALL"
        ? filteredData.filter(
            (item) => item.brand.toLowerCase() === brand.toLowerCase()
          )
        : filteredData;
    filteredData =
      pilotDate !== "ALL"
        ? filteredData.filter((item) => {
            return (
              item.pilot_start_date.toLowerCase() === pilotDate.toLowerCase()
            );
          })
        : filteredData;
    SetFilterDataReports(filteredData);
  };

  //handler for fetching the country brand and report data
  const fetchData = (url, commentFlag) => {
    let status;
    fetch(url)
      .then((response) => {
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        setData({ ...data, loading: false });
        if (status === 200) {
          setComment({ ...comment, loading: false });
          setData({
            ...data,
            data: json.data.report_list,
            loading: false,
            status: status,
          });
          if (commentFlag) {
            console.log("rowElem", rowElem);
            const obj = json.data.report_list.find(
              (e) =>
                e.run_id === rowElem.run_id && e.model_id === rowElem.model_id
            );
            setRowElem({
              ...obj,
              run_id: rowElem.run_id,
              model_id: rowElem.model_id,
              country: rowElem.country,
              brand: rowElem.brand,
              pilot_start_date: rowElem.pilot_start_date,
            });
          }

          SetFilterDataReports(json?.data?.report_list);
          let arr = [];
          if (json?.data?.country_brand) {
            for (const iterator of Object.entries(json?.data?.country_brand)) {
              arr.push({ name: iterator[0], ...iterator[1] });
            }
          }
          const brandArr = [];
          const pilotDatesArr = [];
          arr?.map((e) => {
            brandArr?.push(...e?.brands);
            pilotDatesArr?.push(...e?.pilot_start_dates);
            return null;
          });
          let uniqueBrandArr = [...new Set(brandArr)];
          let uniquePilotArr = [...new Set(pilotDatesArr)];
          const newArr = [
            {
              name: "ALL",
              brands: uniqueBrandArr,
              pilot_start_dates: uniquePilotArr,
            },
            ...arr?.sort(),
          ];

          const finalArr = newArr.map((e) => {
            const sortDates = e?.pilot_start_dates?.sort(
              (a, b) => new Date(a) - new Date(b)
            );
            return {
              ...e,
              brands: ["ALL", ...e.brands?.sort()],
              pilot_start_dates: [
                "ALL",
                ...sortDates.reduce((acc, curr) => {
                  if (!acc.includes(curr)) {
                    acc.push(curr);
                  }
                  return acc;
                }, []),
              ],
            };
          });
          SetCountryBrandData((pre) => {
            return { ...pre, data: finalArr, loading: false };
          });
          setCountry("ALL");
          setBrand("ALL");
          setPilotDate(finalArr[0].pilot_start_dates[0]);
        } else {
          setData({ ...data, status: status, message: json.statusMessage });
        }
      });
  };

  useEffect(() => {
    setData({ ...initialState, loading: true });
    const url = config.api.validateRun;
    // const url = "https://run.mocky.io/v3/d1da3ea2-5610-4384-bceb-91570349e3e0";
    // const url = "https://run.mocky.io/v3/b145cfb0-d69e-4d22-bb0c-c3abbe6e57e7";
    fetchData(url);
  }, []);

  const updateRunAsStarred = (message) => {
    const body = {
      app_id: "MROI",
      model: rowElem.model_id,
      run_id: rowElem.run_id,
    };
    let url = config.api.optimalRunUrl;
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          const validateUrl = config.api.validateRun;
          fetchData(validateUrl, true);
          updateAlertInfo({
            open: true,
            message: message,
            severity: "success",
          });
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const handlePostComment = () => {
    setComment((pre) => {
      return { ...pre, loading: !pre.loader };
    });
    updateAlertInfo({
      open: true,
      message: "Updating the dashboard...",
      severity: "success",
    });
    let status;
    const url = config.api.validateRun;
    const body = {
      model_id: rowElem.model_id,
      validator: user.preferred_username,
      validate_state: comment?.validate_state,
      comment: `${comment?.comment}`,
      run_id: rowElem.run_id,
    };

    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        if (status === 200) {
          if (comment.validate_state === "Approved") {
            updateRunAsStarred(json.status_message);
          } else {
            fetchData(url, true);
          }
          setLoading(true);
          fetch(config.api.viewDashboard, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              run_id: rowElem.run_id,
              model_id: rowElem.model_id,
            }),
          })
            .then((response) => response.json())
            .then((json) => {
              setRequestor_info({
                requested_by: json.data.requested_by,
                requestor_info: json.data.requestor_info,
              });
              setValidator_info(json.data.validator_info);
              setLoading(false);
            });
        } else {
        }
        updateAlertInfo({
          open: true,
          message: json.status_message,
          severity: "error",
        });
      });
  };

  return (
    <div>
      {data.loading ? (
        <Loader />
      ) : data.status === 200 ? (
        <>
          <DashboardCountryBrandSelection
            handlerForGo={handlerForGo}
            countryBrandData={countryBrandData}
            brand={brand}
            country={country}
            setCountry={setCountry}
            setBrand={setBrand}
            pilotDate={pilotDate}
            setPilotDate={setPilotDate}
            allData={data}
          />
          {filterDataReports?.length ? (
            <RunAnalysisTable
              records={filterDataReports}
              businessDashboard={businessDashboard}
              setExpanded={setExpanded}
              expanded={expanded}
              rowElem={rowElem}
              setRowElem={setRowElem}
              setComment={setComment}
              comment={comment}
              initialCommentState={initialCommentState}
            />
          ) : (
            <div className="error-container">
              <img src={noDataImage} />
              <p>There are no dashboards to be validated yet</p>
            </div>
          )}
          {expanded && (
            <PowerBiReport
              expanded={expanded}
              setExpanded={setExpanded}
              comment={comment}
              setComment={setComment}
              rowElem={rowElem}
              handlePostComment={handlePostComment}
              initialCommentState={initialCommentState}
              loading={loading}
              setLoading={setLoading}
              validator_info={validator_info}
              setValidator_info={setValidator_info}
              requestor_info={requestor_info}
              setRequestor_info={setRequestor_info}
              fetchData={fetchData}
            />
          )}
        </>
      ) : (
        <div className="error-container">
          <img src={noDataImage} />
          <p>{data.message}</p>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});
const mapDispatchToProps = {
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(RunAnalysis);
