//Import required libraies
import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "200px",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a !important",
    backgroundColor: "white",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  singleMenuItem: {
    fontSize: "12px !important",
    fontFamily: "Hind Siliguri !important",
    padding: "8px 12px !important",
    color: "#46596a !important",
    width: "auto",
    '&[aria-selected="true"]': {
      color: `#46596a !important`,
      backgroundColor: `rgba(0, 0, 0, 0.08) !important`,
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      textDecoration: "none",
    },
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function Algorithm(props) {
  let { id, data, name, enable, value, onChange, customClassName } = props;
  const classes = useStyles();

  return (
    <div
      className={`input-item ${customClassName ? customClassName : ""} ${id}`}
    >
      {name && <label className="bold header-text">{name}</label>}
      <Select
        displayEmpty
        disabled={!enable}
        value={value ? value : ""}
        onChange={(e) => {
          onChange(id, e.target.value);
        }}
        className={classes.multiSelect}
        MenuProps={MenuProps}
      >
        {data &&
          data.map((obj) => (
            <MenuItem
              key={obj.id}
              value={obj.id}
              className={classes.singleMenuItem}
            >
              {obj.name}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}

export default Algorithm;
