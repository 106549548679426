//Import required libraies
import React from "react";

//Import custom components
import LocalParameterItem from "./LocalParameterItem";

//Import utils and data
import { config } from "../../../../config/config";

//Import actions

//Import styles

function PreferredSalesDataset(props) {
  let { id, name, userInputs, value, onChange, changeFlag, show } = props;

  let selectedDatasets = userInputs.models
    .find((model) => model.id === config.hardCoded.mmtId)
    .data_sources.filter(
      (item) =>
        item.type === config.hardCoded.datasetSalesType ||
        item.type === config.hardCoded.datasetNeilsenType
    )
    .map((value) => ({ id: value.pipeline_dataset_id, name: value.name }));

  // transform array strings to array of objects for LocalparamItem
  selectedDatasets = selectedDatasets.map((kpi) => ({
    id: kpi.id,
    name: kpi.name,
  }));

  return (
    <>
      <LocalParameterItem
        ui_element_type="dropdown"
        value={selectedDatasets.length ? value : config.messages.noOptions}
        onChange={onChange}
        enable={selectedDatasets.length ? true : false}
        show={show}
        name={name}
        id={id}
        data={
          selectedDatasets.length
            ? selectedDatasets
            : [
                {
                  id: config.messages.noOptions,
                  name: config.messages.noOptions,
                },
              ]
        }
        userInputs={userInputs}
      />
    </>
  );
}

export default PreferredSalesDataset;
