import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Input from "@material-ui/core/Input";
import { config } from "../../../../config/config";
import { removeDupFromArray } from "../../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    //width: "190px",
    minWidth: "200px",
    maxWidth: "200px",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a !important",
    backgroundColor: "white",
    alignItems: "center",
    display: "flex",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "& .Mui-disabled": {
      backgroundColor: "#fff",
    },
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  menuItem: {
    fontSize: "12px !important",
    fontFamily: "Hind Siliguri !important",
    color: "#46596a !important",
    '&[aria-selected="true"]': {
      color: `#46596a !important`,
      backgroundColor: `rgba(0, 0, 0, 0.08) !important`,
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      textDecoration: "none",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "0px 12px",
    color: "#46596a",
    width: "auto",
  },
  listItem: {
    "& span.MuiTypography-body1": {
      fontSize: "12px",
      color: "#46596a",
      fontFamily: "Hind Siliguri",
      paddingRight: "5px",
    },
  },
  popover: {
    pointerEvents: "none",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    width: "800px",
    margin: "10px",
  },
  typographyHeader: {
    fontSize: 12,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 5,
    backgroundColor: "#ebeff1",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
  contained: {
    color: "white",
    backgroundColor: "#25b1e6",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

const StyledCheckboxDisable = withStyles((theme) => ({
  root: {
    // color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function Enforced(props) {
  const { id, name, value, onChange, activeModel, userInputs, disableFlag } =
    props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const classes = useStyles();
  let modelData = userInputs.models.find(
    (model) => model.id === activeModel.id
  );

  let selectedTestCellsData =
    modelData.config.region_granularity.test_dark_regions.test;

  let columnData = [];
  selectedTestCellsData.forEach((config) => {
    if (config?.files?.column_details) {
      columnData.push(config?.files?.column_details);
    }
  });

  columnData = removeDupFromArray(columnData.flat());

  useEffect(() => {
    if (!value?.length && columnData.includes("dark_sales")) {
      onChange(id, ["dark_sales"]);
    }
  }, [columnData]);

  const handleChange = (id, e) => {
    onChange(id, e);
  };

  return (
    <div className="input-item multiple-kpi-selection-dropdown">
      <label className="label">{name}</label>
      {disableFlag ? (
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={value?.length ? value : ""}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className={classes.multiSelect}
          aria-haspopup="true"
          renderValue={(selected) => {
            return selected.join(", ");
          }}
          input={<Input />}
          MenuProps={MenuProps}
          disabled
        />
      ) : (
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={value.length ? value : [config.messages.noOptions]}
          onChange={(e) => {
            if (
              (!columnData.includes("dark_sales") &&
                e.target.value.includes("dark_sales/store")) ||
              e.target.value.includes("dark_sales")
            ) {
              handleChange(id, e.target.value);
            }
          }}
          className={classes.multiSelect}
          renderValue={(selected) => {
            return selected.join(", ");
          }}
          input={<Input />}
          MenuProps={MenuProps}
          disabled={!value.length}
        >
          {columnData
            ? columnData?.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  className={classes.menuItem}
                  style={{
                    pointerEvents: `${
                      (!columnData.includes("dark_sales") &&
                        item === "dark_sales/store") ||
                      item === "dark_sales"
                        ? "none"
                        : "auto"
                    }`,
                  }}
                >
                  {(!columnData.includes("dark_sales") &&
                    item === "dark_sales/store") ||
                  item === "dark_sales" ? (
                    <StyledCheckboxDisable
                      disabled
                      checked={value?.indexOf(item) > -1}
                    />
                  ) : (
                    <StyledCheckbox checked={value?.indexOf(item) > -1} />
                  )}
                  <ListItemText primary={item} className={classes.listItem} />
                </MenuItem>
              ))
            : [config.messages.noOptions]}
        </Select>
      )}
      {value && (
        <Popover
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="popover-content" style={{ minWidth: "170px" }}>
            {value?.map((e) => (
              <p key={e}>{e}</p>
            ))}
          </div>
        </Popover>
      )}
    </div>
  );
}

export default Enforced;
