//Import required libraies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Popover from "@material-ui/core/Popover";
import orderBy from "lodash/orderBy";

//Import Custom Component
import Loader from "../../../../components/Loader/Loader";
import SingleTreeSelect from "./SingleTreeSelect";

//Import data & utils
import { config } from "../../../../config/config";
import {
  addAllInData,
  populateFiltersData,
  addAllNodeAndSortSingleTree,
  removeAllInData,
  createFilterData,
  salesMediaFilterFlag,
  isEqualArray,
} from "../../../../utils/utils";
import CountryBrandSegment from "../../../../../assets/data/CountryBrandSegment.json";

// Import action creators
import {
  updateData,
  updateSelections,
  updateUserInfo,
  updateConfigUserInputs,
} from "../../../../redux/actions";

// Import styles
import "./CountryBrandSegmentHierarchy.scss";
import { Tooltip } from "../../../../uiTheme";

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    //width: "190px",
    minWidth: "180px",
    maxWidth: "180px",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a !important",
    backgroundColor: "white",
    alignItems: "center",
    display: "flex",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  menuItem: {
    fontSize: "12px !important",
    fontFamily: "Hind Siliguri !important",
    color: "#46596a !important",
    padding: "6px 0px",
    '&[aria-selected="true"]': {
      color: `#46596a !important`,
      backgroundColor: `rgba(0, 0, 0, 0.08) !important`,
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      textDecoration: "none",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "0px 12px",
    color: "#46596a",
    width: "auto",
  },
  listItem: {
    "& span.MuiTypography-body1": {
      fontSize: "12px",
      color: "#46596a",
      fontFamily: "Hind Siliguri",
      paddingRight: "5px",
      width: "96px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  popover: {
    // pointerEvents: "none",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    width: "800px",
    margin: "10px",
  },
  typographyHeader: {
    fontSize: 12,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 5,
    backgroundColor: "#ebeff1",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
  contained: {
    color: "white",
    backgroundColor: "#25b1e6",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function MultiSelectDropdown(props) {
  const { userInputs, obj, globalParamsOnChange, globalParamsObj } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Select
        multiple
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        className={classes.multiSelect}
        value={globalParamsObj[obj.id]}
        renderValue={(selected) => {
          return selected.join(", ");
        }}
        aria-owns={open ? obj.id : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
        disabled
        input={<Input />}
        MenuProps={MenuProps}
      ></Select>
      <Popover
        id={obj.id}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="popover-content" style={{ maxHeight: "58vh" }}>
          {userInputs[obj.flag] && (
            <p
              style={{
                width: "280px",
                color: " #eb367f",
                fontSize: "10px",
                margin: "0 0 5px 0",
                fontWeight: 600,
                textTransform: "capitalize",
              }}
            >
              Note: User Selected all {obj.label}
            </p>
          )}
          {globalParamsObj[obj.id].map((value) =>
            value !== config.hardCoded.allSelection ? (
              <p style={{ margin: "0 0 5px 0", width: "280px" }} key={value}>
                {value}
              </p>
            ) : null
          )}
          {/* {globalParamsObj[obj.id]
            .filter((el) => el !== config.hardCoded.allSelection)
            .join(", ")} */}
        </div>
      </Popover>
    </>
  );
}

const CountryBrandSegmentHierarchy = (props) => {
  let {
    data,
    segments,
    subBrand,
    subSegment,
    allData,
    userInputs,
    setUserInputs,
    disableFlag,
    updateUserInfo,
    updateData,
    globalParamsOnChange,
    globalParamsObj,
    mediaSegments,
    mediaSubBrand,
    mediaSubSegment,
    updateCheckDE,
  } = props;

  const [hierarchyData, setHierarchyData] = useState(null);

  const classes = useStyles();

  const handleClick = (id, e) => {
    const targetArr =
      id === "sub_brand"
        ? subBrand.data.map((el) => el.name)
        : id === "sub_segment"
        ? subSegment.data.map((el) => el.name)
        : id === "segment"
        ? segments.data.map((el) => el.name)
        : id === "media_sub_brand"
        ? mediaSubBrand.data.map((el) => el.name)
        : id === "media_sub_segment"
        ? mediaSubSegment.data.map((el) => el.name)
        : mediaSegments.data.map((el) => el.name);
    if (
      e === config.hardCoded.allSelection &&
      globalParamsObj[id].includes(e)
    ) {
      globalParamsOnChange(id, []);
    } else if (
      e === config.hardCoded.allSelection &&
      !globalParamsObj[id].includes(e)
    ) {
      globalParamsOnChange(id, targetArr);
    } else {
      if (globalParamsObj[id].includes(e)) {
        globalParamsOnChange(
          id,
          globalParamsObj[id].filter(
            (el) => ![e, config.hardCoded.allSelection].includes(el)
          )
        );
      } else {
        if (
          targetArr.length ===
          [config.hardCoded.allSelection, ...globalParamsObj[id], e].length
        ) {
          globalParamsOnChange(id, [
            config.hardCoded.allSelection,
            ...globalParamsObj[id],
            e,
          ]);
        } else {
          globalParamsOnChange(id, [...globalParamsObj[id], e]);
        }
      }
    }
  };
  const autoMlModelsEnableFlag = userInputs.models.find(
    (model) => model.run && config.hardCoded.autoMlIds.includes(model.id)
  );
  //clean up function
  useEffect(() => {
    return () => {
      updateData("subBrand", { status: "loading", message: "", data: [] });
      updateData("subSegment", { status: "loading", message: "", data: [] });
      updateData("segments", { status: "loading", message: "", data: [] });
      updateData("mediaSubBrand", { status: "loading", message: "", data: [] });
      updateData("mediaSubSegment", {
        status: "loading",
        message: "",
        data: [],
      });
      updateData("mediaSegments", { status: "loading", message: "", data: [] });
    };
  }, []);

  const subBrandFetch = (controller) => {
    if (
      globalParamsObj.country &&
      globalParamsObj.brand &&
      !userInputs?.is_config_manual
    ) {
      updateData("subBrand", { ...subBrand, status: "loading", data: [] });

      let body = {
        app_id: userInputs.app_id,
        countries: [globalParamsObj.country],
        brands: [globalParamsObj.brand],
      };
      let url = config.api.subBrandsUrl;
      let status;
      fetch(url, {
        method: "POST",
        signal: controller.signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("subBrand", {
              ...subBrand,
              status: "success",
              data: addAllInData(orderBy(json.data, ["name"], ["asc"])),
            });
            if (userInputs.sub_brand_select_all) {
              globalParamsOnChange(
                "sub_brand",
                addAllInData(orderBy(json.data, ["name"], ["asc"])).map(
                  (el) => el.name
                )
              );
            }
          } else {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
          }
        });
    }
  };
  const mediaSubBrandFetch = (controller) => {
    if (
      globalParamsObj.country &&
      globalParamsObj.brand &&
      !userInputs?.is_config_manual
    ) {
      updateData("mediaSubBrand", {
        ...mediaSubBrand,
        status: "loading",
        data: [],
      });

      let body = {
        app_id: userInputs.app_id,
        countries: [globalParamsObj.country],
        brands: [globalParamsObj.brand],
      };
      let url = config.api.mediaSubBrandsUrl;
      let status;
      fetch(url, {
        method: "POST",
        signal: controller.signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("mediaSubBrand", {
              ...mediaSubBrand,
              status: "success",
              data: addAllInData(orderBy(json.data, ["name"], ["asc"])),
            });
            if (userInputs.media_sub_brand_select_all) {
              globalParamsOnChange(
                "media_sub_brand",
                addAllInData(orderBy(json.data, ["name"], ["asc"])).map(
                  (el) => el.name
                )
              );
            }
          } else {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
          }
        });
    }
  };
  //get sub_brand data if country or brand changes
  useEffect(() => {
    const controller = new AbortController();
    subBrandFetch(controller);
    return () => {
      controller.abort();
    };
  }, [globalParamsObj.country, globalParamsObj.brand]);

  //get sales sub_brand data if country or brand changes
  useEffect(() => {
    const controller = new AbortController();
    mediaSubBrandFetch(controller);
    return () => {
      controller.abort();
    };
  }, [globalParamsObj.country, globalParamsObj.brand]);
  useEffect(() => {
    if (subBrand.status === "success" && !disableFlag) {
      let filterData = globalParamsObj.sub_brand.reduce((acc, curr) => {
        if (subBrand?.data?.find((elm) => elm.id === curr)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      filterData.filter((el) =>
        userInputs.sub_brand_select_all
          ? el
          : el !== config.hardCoded.allSelection
      );
      if (globalParamsObj?.sub_brand?.length !== filterData?.length) {
        globalParamsOnChange(
          "sub_brand",
          filterData?.length
            ? filterData?.length === 1 &&
              filterData.includes(config.hardCoded.allSelection)
              ? [...createFilterData(subBrand)]
              : filterData
            : [...createFilterData(subBrand)]
        );
      }
    }
  }, [subBrand]);

  useEffect(() => {
    if (mediaSubBrand.status === "success" && !disableFlag) {
      let filterData = globalParamsObj.media_sub_brand.reduce((acc, curr) => {
        if (mediaSubBrand?.data?.find((elm) => elm.id === curr)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      filterData.filter((el) =>
        userInputs.media_sub_brand_select_all
          ? el
          : el !== config.hardCoded.allSelection
      );
      if (globalParamsObj?.media_sub_brand?.length !== filterData?.length) {
        globalParamsOnChange(
          "media_sub_brand",
          filterData?.length
            ? filterData?.length === 1 &&
              filterData.includes(config.hardCoded.allSelection)
              ? [...createFilterData(mediaSubBrand)]
              : filterData
            : [...createFilterData(mediaSubBrand)]
        );
      }
    }
  }, [mediaSubBrand]);
  const segmentFetch = (controller) => {
    if (
      globalParamsObj.country &&
      globalParamsObj.brand &&
      globalParamsObj.sub_brand &&
      !userInputs?.is_config_manual
    ) {
      updateData("segments", { ...segments, status: "loading", data: [] });

      let body = {
        app_id: userInputs.app_id,
        countries: [globalParamsObj.country],
        brands: [globalParamsObj.brand],
      };
      let url = config.api.segmentUrl;
      let status;
      fetch(url, {
        signal: controller.signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("segments", {
              ...segments,
              status: "success",
              data: addAllInData(orderBy(json.data, ["name"], ["asc"])),
            });
            if (userInputs.segment_select_all) {
              globalParamsOnChange(
                "segment",
                addAllInData(orderBy(json.data, ["name"], ["asc"])).map(
                  (el) => el.name
                )
              );
            }
          } else {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
          }
        });
    }
  };
  //get segment data if country or brand changes from configUserInputs
  useEffect(() => {
    const controller = new AbortController();
    segmentFetch(controller);
    return () => {
      controller.abort();
    };
  }, [globalParamsObj.country, globalParamsObj.brand]);

  const mediaSegmentFetch = (controller) => {
    if (
      globalParamsObj.country &&
      globalParamsObj.brand &&
      globalParamsObj.media_sub_brand &&
      !userInputs?.is_config_manual
    ) {
      updateData("mediaSegments", {
        ...mediaSegments,
        status: "loading",
        data: [],
      });

      let body = {
        app_id: userInputs.app_id,
        countries: [globalParamsObj.country],
        brands: [globalParamsObj.brand],
      };
      let url = config.api.mediaSegmentsUrl;
      let status;
      fetch(url, {
        signal: controller.signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("mediaSegments", {
              ...mediaSegments,
              status: "success",
              data: addAllInData(orderBy(json.data, ["name"], ["asc"])),
            });
            if (userInputs.media_segment_select_all) {
              globalParamsOnChange(
                "media_segment",
                addAllInData(orderBy(json.data, ["name"], ["asc"])).map(
                  (el) => el.name
                )
              );
            }
          } else {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
          }
        });
    }
  };
  //get media segment data if country or brand changes from configUserInputs
  useEffect(() => {
    const controller = new AbortController();
    mediaSegmentFetch(controller);
    return () => {
      controller.abort();
    };
  }, [globalParamsObj.country, globalParamsObj.brand]);
  useEffect(() => {
    if (segments.status === "success" && !disableFlag) {
      let filterData = globalParamsObj.segment.reduce((acc, curr) => {
        if (segments?.data?.find((elm) => elm.id === curr)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      filterData.filter((el) =>
        userInputs.segment_select_all
          ? el
          : el !== config.hardCoded.allSelection
      );
      if (globalParamsObj?.segment?.length !== filterData?.length) {
        globalParamsOnChange(
          "segment",
          filterData?.length
            ? filterData?.length === 1 &&
              filterData.includes(config.hardCoded.allSelection)
              ? [...createFilterData(segments)]
              : filterData
            : [...createFilterData(segments)]
        );
      }
    }
  }, [segments]);

  useEffect(() => {
    if (mediaSegments.status === "success" && !disableFlag) {
      let filterData = globalParamsObj.media_segment.reduce((acc, curr) => {
        if (mediaSegments?.data?.find((elm) => elm.id === curr)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      filterData.filter((el) =>
        userInputs.media_segment_select_all
          ? el
          : el !== config.hardCoded.allSelection
      );
      if (globalParamsObj?.media_segment?.length !== filterData?.length) {
        globalParamsOnChange(
          "media_segment",
          filterData?.length
            ? filterData?.length === 1 &&
              filterData.includes(config.hardCoded.allSelection)
              ? [...createFilterData(mediaSegments)]
              : filterData
            : [...createFilterData(mediaSegments)]
        );
      }
    }
  }, [mediaSegments]);

  const subSegmentFetch = (controller) => {
    if (
      globalParamsObj.country &&
      globalParamsObj.brand &&
      globalParamsObj.sub_brand &&
      globalParamsObj.segment &&
      !userInputs?.is_config_manual
    ) {
      updateData("subSegment", { ...subSegment, status: "loading", data: [] });

      let body = {
        app_id: userInputs.app_id,
        countries: [globalParamsObj.country],
        brands: [globalParamsObj.brand],
        segments: removeAllInData(globalParamsObj.segment),
      };
      let url = config.api.subSegmentsUrl;
      let status;
      fetch(url, {
        signal: controller.signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("subSegment", {
              ...subSegment,
              status: "success",
              data: addAllInData(orderBy(json.data, ["name"], ["asc"])),
            });
            if (userInputs.sub_segment_select_all) {
              globalParamsOnChange(
                "sub_segment",
                addAllInData(orderBy(json.data, ["name"], ["asc"])).map(
                  (el) => el.name
                )
              );
            }
          } else {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
          }
        });
    }
  };
  //get sub_segment data if country or brand or segment changes
  useEffect(() => {
    const controller = new AbortController();
    subSegmentFetch(controller);
    return () => {
      controller.abort();
    };
  }, [globalParamsObj.country, globalParamsObj.brand, globalParamsObj.segment]);

  const mediaSubSegmentFetch = (controller) => {
    if (
      globalParamsObj.country &&
      globalParamsObj.brand &&
      globalParamsObj.sub_brand &&
      globalParamsObj.media_segment &&
      !userInputs?.is_config_manual
    ) {
      updateData("mediaSubSegment", {
        ...mediaSubSegment,
        status: "loading",
        data: [],
      });

      let body = {
        app_id: userInputs.app_id,
        countries: [globalParamsObj.country],
        brands: [globalParamsObj.brand],
        segments: removeAllInData(globalParamsObj.media_segment),
      };
      let url = config.api.mediaSubSegmentsUrl;
      let status;
      fetch(url, {
        signal: controller.signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateData("mediaSubSegment", {
              ...mediaSubSegment,
              status: "success",
              data: addAllInData(orderBy(json.data, ["name"], ["asc"])),
            });
            if (userInputs.media_sub_segment_select_all) {
              globalParamsOnChange(
                "media_sub_segment",
                addAllInData(orderBy(json.data, ["name"], ["asc"])).map(
                  (el) => el.name
                )
              );
            }
          } else {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
          }
        });
    }
  };
  //get sales sub_segment data if country or brand or segment changes
  useEffect(() => {
    const controller = new AbortController();
    mediaSubSegmentFetch(controller);
    return () => {
      controller.abort();
    };
  }, [
    globalParamsObj.country,
    globalParamsObj.brand,
    globalParamsObj.media_segment,
  ]);
  useEffect(() => {
    if (subSegment.status === "success" && !disableFlag) {
      let filterData = globalParamsObj.sub_segment.reduce((acc, curr) => {
        if (subSegment?.data?.find((elm) => elm.id === curr)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      filterData = filterData.filter((el) =>
        userInputs.sub_segment_select_all
          ? el
          : el !== config.hardCoded.allSelection
      );
      if (!userInputs.sub_segment_select_all) {
        filterData =
          filterData.length ===
          removeAllInData(createFilterData(subSegment)).length
            ? [config.hardCoded.allSelection, ...filterData]
            : filterData;
      }
      if (globalParamsObj?.sub_segment?.length !== filterData?.length) {
        globalParamsOnChange(
          "sub_segment",
          filterData?.length
            ? filterData.includes(config.hardCoded.allSelection)
              ? [...createFilterData(subSegment)]
              : filterData
            : [...createFilterData(subSegment)]
        );
      }
    }
  }, [subSegment]);

  useEffect(() => {
    if (mediaSubSegment.status === "success" && !disableFlag) {
      let filterData = globalParamsObj.media_sub_segment.reduce((acc, curr) => {
        if (mediaSubSegment?.data?.find((elm) => elm.id === curr)) {
          acc.push(curr);
        }
        return acc;
      }, []);

      filterData = filterData.filter((el) =>
        userInputs.media_sub_segment_select_all
          ? el
          : el !== config.hardCoded.allSelection
      );
      if (!userInputs.media_sub_segment_select_all) {
        filterData =
          filterData.length ===
          removeAllInData(createFilterData(mediaSubSegment)).length
            ? [config.hardCoded.allSelection, ...filterData]
            : filterData;
      }
      if (
        globalParamsObj?.media_sub_segment?.length !== filterData?.length ||
        !isEqualArray(globalParamsObj?.media_sub_segment, filterData)
      ) {
        globalParamsOnChange(
          "media_sub_segment",
          filterData?.length
            ? filterData.includes(config.hardCoded.allSelection)
              ? [...createFilterData(mediaSubSegment)]
              : filterData
            : [...createFilterData(mediaSubSegment)]
        );
      }
    }
  }, [mediaSubSegment]);
  useEffect(() => {
    if (
      subBrand.status === "success" &&
      segments.status === "success" &&
      subSegment.status === "success" &&
      mediaSubBrand.status === "success" &&
      mediaSegments.status === "success" &&
      mediaSubSegment.status === "success"
    ) {
      let popFiltersData = populateFiltersData(CountryBrandSegment, {
        subBrand: subBrand.data,
        subSegment: subSegment.data,
        segments: segments.data,
        mediaSubBrand: mediaSubBrand.data,
        mediaSubSegment: mediaSubSegment.data,
        mediaSegments: mediaSegments.data,
      });
      setHierarchyData(popFiltersData);
    }
  }, [
    subBrand,
    segments,
    subSegment,
    mediaSegments,
    mediaSubBrand,
    mediaSubSegment,
  ]);

  // To update sales and media filters when user navigate between different models
  useEffect(() => {
    const controller = new AbortController();
    if (hierarchyData) {
      const checkObj = {
        sub_brand: subBrandFetch,
        segment: segmentFetch,
        sub_segment: subSegmentFetch,
        media_sub_brand: mediaSubBrandFetch,
        media_segment: mediaSegmentFetch,
        media_sub_segment: mediaSubSegmentFetch,
      };
      const invalidArr = hierarchyData.filter(
        (el) =>
          userInputs[el.id].includes(config.hardCoded.allSelection) &&
          userInputs[el.id].length === 1
      );

      if (invalidArr.length) {
        invalidArr.map((el) => {
          if (
            allData[el.name].status === "success" &&
            globalParamsObj[el.id].includes(config.hardCoded.allSelection) &&
            globalParamsObj[el.id].length === 1 &&
            allData[el.name].data.length !== 1
          ) {
            checkObj[el.id](controller);
          }
        });
      }
    }
    return () => {
      controller.abort();
    };
  }, [userInputs.is_config_manual, updateCheckDE, hierarchyData]);
  return (
    <>
      {disableFlag ? (
        <>
          <div
            className={`input-item country-brand-dropdown ${
              disableFlag ? "disabled-pointer" : ""
            }`}
          >
            <label>Select Country</label>
            <input
              type="text"
              id="country"
              name="country"
              // value={userInputs["country"]}
              value={globalParamsObj["country"]}
              onChange={(e) => {
                if (
                  userInputs.app_id === config.hardCoded.mroiAppId &&
                  !userInputs.is_config_manual
                ) {
                  globalParamsOnChange("country", e.target.value);
                } else {
                  setUserInputs("country", e.target.value);
                }
              }}
              className="disabled-input"
            />
          </div>
          {userInputs.brand && (
            <div
              className={`input-item country-brand-dropdown ${
                disableFlag ? "disabled-pointer" : ""
              }`}
            >
              <label>Select Brand</label>
              <input
                type="text"
                id="brand"
                name="brand"
                value={globalParamsObj["brand"]}
                onChange={(e) => {
                  if (
                    userInputs.app_id === config.hardCoded.mroiAppId &&
                    !userInputs.is_config_manual
                  ) {
                    globalParamsOnChange("brand", e.target.value);
                  } else {
                    setUserInputs("brand", e.target.value);
                  }
                }}
                className="disabled-input"
              />
            </div>
          )}
        </>
      ) : (
        <SingleTreeSelect
          data={addAllNodeAndSortSingleTree(data, {
            segment: false,
          })}
          userInputs={userInputs}
          setUserInputs={setUserInputs}
          globalParamsOnChange={globalParamsOnChange}
          globalParamsObj={globalParamsObj}
          disableFlag={disableFlag}
        />
      )}

      <div
        className="sales-filters"
        style={{
          display: "flex",
          pointerEvents: salesMediaFilterFlag(allData) ? "all" : "none",
          opacity: salesMediaFilterFlag(allData) ? 1 : 0.5,
        }}
      >
        {" "}
        {!userInputs?.is_config_manual ? (
          hierarchyData ? (
            hierarchyData.map((obj) => {
              return (
                obj.apps.includes(userInputs.app_id) &&
                !obj.name.includes("media") &&
                (allData[obj.name].status === "success" ? (
                  <div className="input-item" key={obj.label}>
                    <>
                      <label>{obj.label}</label>
                      {disableFlag ? (
                        <>
                          <MultiSelectDropdown
                            obj={obj}
                            userInputs={userInputs}
                            globalParamsOnChange={globalParamsOnChange}
                            globalParamsObj={globalParamsObj}
                          />
                        </>
                      ) : (
                        <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          multiple
                          value={globalParamsObj[obj.id]}
                          onChange={(e) => {
                            // handleChange(obj.id, e);
                          }}
                          className={classes.multiSelect}
                          renderValue={(selected) => {
                            return selected.join(", ");
                          }}
                          input={<Input />}
                          MenuProps={MenuProps}
                        >
                          {obj?.data?.map((item) => (
                            <MenuItem
                              key={item.name}
                              value={item.name}
                              className={classes.menuItem}
                              onClick={() => {
                                handleClick(obj.id, item.id);
                              }}
                              title={item.name}
                            >
                              <StyledCheckbox
                                checked={
                                  globalParamsObj[obj.id].indexOf(item.name) >
                                  -1
                                }
                              />
                              <ListItemText
                                primary={item.name}
                                className={classes.listItem}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </>
                  </div>
                ) : allData[obj.name].status === "loading" ? (
                  <div className="loader-div" key={obj.label}>
                    <Loader />
                  </div>
                ) : (
                  <p className="error">{allData[obj.name].message}</p>
                ))
              );
            })
          ) : (
            <Loader />
          )
        ) : null}
      </div>
      {typeof userInputs.media_sub_brand_select_all !== "undefined" && (
        <div
          style={{
            width: "975px",
            display: "flex",
            justifyContent: "end",
            pointerEvents: salesMediaFilterFlag(allData) ? "all" : "none",
            opacity: salesMediaFilterFlag(allData) ? 1 : 0.5,
          }}
        >
          {!userInputs.is_config_manual && (
            <div
              style={{
                display: "flex",
                alignItems: "end",
                marginRight: "15px",
              }}
            >
              <Tooltip title={config.messages.allParameter} placement="left">
                {" "}
                <i
                  className="material-icons-outlined"
                  style={{
                    fontSize: "20px !important",
                    color: "#4eafb3",
                    position: "relative",
                    top: "3px",
                  }}
                >
                  {" "}
                  info{" "}
                </i>
              </Tooltip>
            </div>
          )}
          <div
            className="media-filters"
            style={{
              display: "flex",
              paddingTop: "10px",
              // pointerEvents: "none",
              // pointerEvents: salesMediaFilterFlag(allData) ? "all" : "none",
              // opacity: salesMediaFilterFlag(allData) ? 1 : 0.5,
              pointerEvents:
                autoMlModelsEnableFlag && salesMediaFilterFlag(allData)
                  ? "all"
                  : "none",
              opacity:
                autoMlModelsEnableFlag && salesMediaFilterFlag(allData)
                  ? 1
                  : 0.5,
            }}
          >
            {!userInputs?.is_config_manual ? (
              hierarchyData ? (
                hierarchyData.map((obj, i) => {
                  return (
                    obj.apps.includes(userInputs.app_id) &&
                    obj.name.includes("media") &&
                    (allData[obj.name].status === "success" ? (
                      <div
                        className="input-item"
                        style={{
                          flexGrow: i === 5 ? 0.5 : 1,
                        }}
                        key={obj.label}
                      >
                        <>
                          <label>{obj.label}</label>
                          {disableFlag ? (
                            <>
                              <MultiSelectDropdown
                                obj={obj}
                                userInputs={userInputs}
                                globalParamsOnChange={globalParamsOnChange}
                                globalParamsObj={globalParamsObj}
                              />
                            </>
                          ) : (
                            <Select
                              labelId="demo-mutiple-checkbox-label"
                              id="demo-mutiple-checkbox"
                              multiple
                              value={globalParamsObj[obj.id]}
                              onChange={(e) => {
                                // handleChange(obj.id, e);
                              }}
                              className={classes.multiSelect}
                              renderValue={(selected) => {
                                return selected.join(", ");
                              }}
                              input={<Input />}
                              MenuProps={MenuProps}
                              // style={{ maxWidth: "230px" }}
                            >
                              {obj?.data?.map((item) => (
                                <MenuItem
                                  key={item.name}
                                  value={item.name}
                                  className={classes.menuItem}
                                  onClick={() => {
                                    handleClick(obj.id, item.id);
                                  }}
                                  title={item.name}
                                >
                                  <StyledCheckbox
                                    checked={
                                      globalParamsObj[obj.id].indexOf(
                                        item.name
                                      ) > -1
                                    }
                                  />
                                  <ListItemText
                                    primary={item.name}
                                    className={classes.listItem}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </>
                      </div>
                    ) : allData[obj.name].status === "loading" ? (
                      <div
                        className="loader-div"
                        style={{ width: "180px", marginRight: "15px" }}
                        key={obj.label}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <p className="error">{allData[obj.name].message}</p>
                    ))
                  );
                })
              ) : (
                <Loader />
              )
            ) : null}
          </div>
        </div>
      )}
      {typeof userInputs.media_sub_brand_select_all === "undefined" &&
        !userInputs.is_config_manual && (
          <div
            style={{
              display: "flex",
              alignItems: "end",
            }}
          >
            <Tooltip
              title={config.messages.allParameter}
              placement="bottom-start"
            >
              {" "}
              <i
                className="material-icons-outlined"
                style={{
                  fontSize: "20px !important",
                  color: "#4eafb3",
                  position: "relative",
                  top: "3px",
                }}
              >
                {" "}
                info{" "}
              </i>
            </Tooltip>
          </div>
        )}
    </>
  );
};

CountryBrandSegmentHierarchy.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  segment: PropTypes.array,
  updateData: PropTypes.func,
  updateSelections: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  segments: state.data.segments,
  subBrand: state.data.subBrand,
  subSegment: state.data.subSegment,
  mediaSegments: state.data.mediaSegments,
  mediaSubBrand: state.data.mediaSubBrand,
  mediaSubSegment: state.data.mediaSubSegment,
  allData: state.data,
});

const mapDispatchToProps = {
  updateData,
  updateSelections, //actions are being dispatched using these functions,
  updateUserInfo,
  updateConfigUserInputs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryBrandSegmentHierarchy);
