// Import required libraries
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "../../../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";
import AddRunName from "../../../../components/AddRunName/AddRunName";

// Import custom component
import ScheduleConfigContainer from "../../../../components/ScheduleConfig/ScheduleConfigContainer";
import Modal from "../../../../components/Modal/Modal";

// Import utils & data
import { config } from "../../../../config/config";
import {
  makeConfigTemplate,
  makeManualConfigTemplate,
  validateConfig,
  validateManualConfig,
  validateDatasetWeeksSync,
  makeDefaultResponseJson,
} from "../../../../utils/utils";

// Import actions
import {
  updateAlertInfo,
  updateUserInfo,
  updateDialogInfo,
  refreshData,
} from "../../../../redux/actions";

function Buttons(props) {
  const {
    match,
    user,
    app,
    configUserInputs,
    allData,
    updateAlertInfo,
    updateUserInfo,
    updateDialogInfo,
    refreshData,
    changeInGlobalParamsFlag,
  } = props;
  const history = useHistory();
  const [disableButtons, setDisableButtons] = useState(false);
  const globalRegionsFlag = () => {
    const salesRegionsModel = configUserInputs.models.find(
      (model) =>
        model.run &&
        [config.hardCoded.autoMlId, config.hardCoded.salesUpliftId].includes(
          model.id
        )
    )
      ? configUserInputs.salesRegions
      : "success";
    const mediaRegionsModel = configUserInputs.models.find(
      (model) =>
        model.run &&
        [config.hardCoded.autoMlId, config.hardCoded.autoMlBudgetId].includes(
          model.id
        )
    )
      ? configUserInputs.mediaRegions
      : "success";
    const categoryData = configUserInputs.models.find(
      (model) =>
        model.run &&
        model.data_sources.find(
          (dataset) => dataset.type === config.hardCoded.datasetNeilsenType
        )
    )
      ? configUserInputs?.categoryData !== -1
        ? "success"
        : "failed"
      : "success";
    const targetArr = [
      configUserInputs.globalRegionsStatus,
      salesRegionsModel,
      mediaRegionsModel,
      configUserInputs?.datasetDetailsData?.status,
      // categoryData,
    ];
    return targetArr.every((el) => el === "success");
  };
  const useStyles = makeStyles((theme) => ({
    toastTheme: {
      backgroundColor: "#f0f2f4",
      color: "#46596a",
      fontSize: "12px",
      fontWeight: "bold",
    },
    contained: {
      color: "white",
      backgroundColor: "#46596A",
      fontFamily: "Hind Siliguri",
      fontSize: "12px",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    outlined: {
      borderColor: "#46596A",
      color: "#46596A",
      fontFamily: "Hind Siliguri",
      fontSize: "12px",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    root: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      pointerEvents: disableButtons ? "none" : "auto",
      // position: "fixed",
      // bottom: "45px",
      // right: "35px",
      "& .MuiButton-root:hover": {
        backgroundColor: "transparent",
      },
      "& .MuiButton-contained:hover": {
        backgroundColor: "#46596A",
        boxShadow: "none",
      },
      "& .MuiButton-contained": {
        boxShadow: "none",
      },
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
  }));
  let appName = config.hardCoded.mroiAppId.toLowerCase();
  let activeApp =
    allData.apps.data.length > 0
      ? allData.apps.data.find(
          (obj) => obj.name.toLowerCase() === appName.toLowerCase()
        )
      : null;
  const classes = useStyles();
  const [modalComponent, setModalComponent] = useState({
    component: null,
    openModal: false,
    handleCancel: () => {
      console.log("handleCancel");
    },
    handleDone: () => {
      console.log("handleDone");
    },
  });

  const handleDialogClose = () => {
    setModalComponent((prevState) => {
      return { ...prevState, openModal: false };
    });
  };

  const handleDialogOpen = (component) => {
    setModalComponent((prevState) => {
      return {
        ...prevState,
        openModal: true,
        component: component,
      };
    });
  };

  const handleDelete = () => {
    setDisableButtons(true);
    updateDialogInfo({ ...user.dialogInfo, open: false });
    console.groupCollapsed("DELETE REQUEST SENT...");
    updateAlertInfo({
      open: true,
      message: config.messages.configDeleteRequest,
      severity: "info",
    });
    let url = `${config.api.configUrl}?config_id=${configUserInputs.configId}&app_id=${config.hardCoded.mroiAppId}`;
    let status;
    fetch(url, {
      method: "DELETE",
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: config.configSuccessDeleteMessage,
            severity: "success",
          });
          history.push(
            match.params.tab === "addnewconfig"
              ? `/home/myconfigurations/`
              : `/home/${match.params.tab}/`
          );
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const handleNo = () => {
    setDisableButtons(false);
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const saveConfig = () => {
    setDisableButtons(true);
    updateDialogInfo({ ...user.dialogInfo, open: false });
    let { message } = validateConfig(configUserInputs, allData);
    let finalConfig = makeConfigTemplate(configUserInputs, allData);
    let url = config.api.configUrl;
    let status;
    updateAlertInfo({ open: true, message, severity: "info" });
    fetch(url, {
      method: match.params.configName ? "PUT" : "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalConfig),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: match.params.configName
              ? config.messages.configSuccessUpdate
              : config.messages.configSuccessSave,
            severity: "success",
          });
          history.push(
            match.params.tab === "addnewconfig"
              ? `/home/myconfigurations/`
              : `/home/${match.params.tab}/`
          );
        } else {
          setDisableButtons(false);
          // if (status === 500) {
          //   updateAlertInfo({
          //     open: true,
          //     message: config.messages.duplicateName,
          //     severity: "success",
          //   });
          // } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
          // }
        }
      });
  };

  const saveManualConfig = () => {
    setDisableButtons(true);
    updateDialogInfo({ ...user.dialogInfo, open: false });
    let { message } = validateManualConfig(configUserInputs, allData);
    let finalConfig = makeManualConfigTemplate(configUserInputs, allData);
    let url = config.api.configUrl;
    let status;
    updateAlertInfo({ open: true, message, severity: "info" });
    fetch(url, {
      method: match.params.configName ? "PUT" : "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalConfig),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: match.params.configName
              ? config.messages.configSuccessUpdate
              : config.messages.configSuccessSave,
            severity: "success",
          });
          history.push(
            match.params.tab === "addnewconfig"
              ? `/home/myconfigurations/`
              : `/home/${match.params.tab}/`
          );
        } else {
          setDisableButtons(false);
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const saveAndRunManualConfig = (configUserInputs, allData) => {
    setDisableButtons(true);
    updateDialogInfo({ ...user.dialogInfo, open: false });
    let { message } = validateManualConfig(configUserInputs, allData);
    let finalConfig = makeManualConfigTemplate(configUserInputs, allData);
    let url = config.api.configUrl;
    let status;
    updateAlertInfo({ open: true, message, severity: "info" });
    fetch(url, {
      method: match.params.configName ? "PUT" : "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalConfig),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: match.params.configName
              ? config.messages.configSuccessUpdate + " Requesting run..."
              : config.messages.configSuccessSave + " Requesting run...",
            severity: "success",
          });
          let id = match.params.configName
            ? configUserInputs.configId
            : json.data.id;
          let url = config.api.configRunUrl;
          let status;
          fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id,
              app_id: config.hardCoded.mroiAppId,
              run_name: `${configUserInputs?.configFixedRunName}${configUserInputs?.run_name}`,
            }),
          })
            .then((response) => {
              console.groupCollapsed("requesting", url);
              console.log("REPSONSE -> ", response);
              status = response.status;
              return response.clone().json();
            })
            .then((json) => {
              if (status === 200) {
                setDisableButtons(true);
                refreshData();
                updateAlertInfo({
                  open: true,
                  message: json.statusMessage,
                  severity: "success",
                });
                history.push(
                  match.params.tab === "addnewconfig"
                    ? `/home/myconfigurations/`
                    : `/home/${match.params.tab}/`
                );
              } else {
                updateAlertInfo({
                  open: true,
                  message: json.statusMessage,
                  severity: "error",
                });
                history.push(
                  match.params.tab === "addnewconfig"
                    ? `/home/myconfigurations/`
                    : `/home/${match.params.tab}/`
                );
              }
            });
        } else {
          setDisableButtons(false);
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const saveAndRunConfig = (configUserInputs, allData) => {
    setDisableButtons(true);
    updateDialogInfo({ ...user.dialogInfo, open: false });
    let { message } = validateConfig(configUserInputs, allData);
    let finalConfig = makeConfigTemplate(configUserInputs, allData);
    let url = config.api.configUrl;
    let status;
    updateAlertInfo({ open: true, message, severity: "info" });
    fetch(url, {
      method: match.params.configName ? "PUT" : "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(finalConfig),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: match.params.configName
              ? config.messages.configSuccessUpdate + " Requesting run..."
              : config.messages.configSuccessSave + " Requesting run...",
            severity: "success",
          });
          let id = match.params.configName
            ? configUserInputs.configId
            : json.data.id;
          let url = config.api.configRunUrl;
          let status;
          fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id,
              app_id: config.hardCoded.mroiAppId,
              run_name: `${configUserInputs?.configFixedRunName}${configUserInputs?.run_name}`,
            }),
          })
            .then((response) => {
              console.groupCollapsed("requesting", url);
              console.log("REPSONSE -> ", response);
              status = response.status;
              return response.clone().json();
            })
            .then((json) => {
              if (status === 200) {
                setDisableButtons(true);
                refreshData();
                updateAlertInfo({
                  open: true,
                  message: json.statusMessage,
                  severity: "success",
                });
                history.push(
                  match.params.tab === "addnewconfig"
                    ? `/home/myconfigurations/`
                    : `/home/${match.params.tab}/`
                );
              } else {
                updateAlertInfo({
                  open: true,
                  message: json.statusMessage,
                  severity: "error",
                });
                history.push(
                  match.params.tab === "addnewconfig"
                    ? `/home/myconfigurations/`
                    : `/home/${match.params.tab}/`
                );
              }
            });
        } else {
          setDisableButtons(false);
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const handleManualConfigSaveUpdate = (configUserInputs, allData) => {
    console.groupCollapsed("MANUAL CONFIG SAVE/UPDATE REQUESTED...");
    console.log("MANUAL CONFIG NAME -> ", match.params.configName);
    let { status, message } = validateManualConfig(configUserInputs, allData);
    console.log("STATUS -> ", status);
    console.log("MESSAGE -> ", message);
    let finalConfig = makeManualConfigTemplate(configUserInputs, allData);
    console.log("FINAL MANUAL CONFIG -> ", finalConfig);

    if (status === "fail") {
      updateAlertInfo({ open: true, message, severity: "error" });
    }

    if (status === "pass") {
      saveManualConfig();
    }
    console.groupEnd();
  };

  const handleScheduleSaveConfig = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
    let { status, message } = validateConfig(configUserInputs, allData);
    let finalConfig = makeConfigTemplate(configUserInputs, allData);
    let scheduleConfigDetails =
      allData.saved_configurations.find(
        (elem) => elem.id === configUserInputs.configId
      )?.schedule_details || {};

    // console.log("STATUS -> ", status);
    // console.log("MESSAGE -> ", message);
    status === "pass" &&
      handleDialogOpen(
        <ScheduleConfigContainer
          activeApp={activeApp}
          match={match}
          handleDialogClose={handleDialogClose}
          config={{
            ...(Object.keys(scheduleConfigDetails).length !== 0 && {
              schedule_details: scheduleConfigDetails,
            }),
            //todo to be changed
            id: match.params.configName ? configUserInputs.configId : "",
            name: finalConfig.name,
          }}
          finalConfig={finalConfig}
        />
      );
  };

  const handleSaveUpdate = (configUserInputs, allData) => {
    console.groupCollapsed("SAVE/UPDATE REQUESTED...");
    console.log("CONFIG NAME -> ", match.params.configName);
    let { status, message, partialDataset } = validateConfig(
      configUserInputs,
      allData
    );
    // console.log("STATUS -> ", status);
    // console.log("MESSAGE -> ", message);
    // console.log("PARTIAL DATASET -> ", partialDataset);
    let finalConfig = makeConfigTemplate(configUserInputs, allData);
    console.log("FINAL CONFIG -> ", finalConfig);

    if (status === "fail") {
      updateAlertInfo({ open: true, message, severity: "error" });
    }

    if (status === "pass") {
      if (!partialDataset?.length) {
        saveConfig();
      } else {
        let displayMessage = "";
        partialDataset.forEach(
          (elem, index) =>
            (displayMessage += index + 1 + ": " + elem.message + "\n")
        );
        updateDialogInfo({
          message: displayMessage + "\n Do you want to continue?",
          open: true,
          handleYes: saveConfig,
          handleNo: handleNo,
        });
      }
    }
    console.groupEnd();
  };

  const handleManualSaveUpdateRun = (configUserInputs, allData) => {
    console.groupCollapsed("REQUEST SENT");
    console.log("MANUAL CONFIG NAME -> ", match.params.configName);
    let { status, message } = validateManualConfig(configUserInputs, allData);
    console.log("STATUS -> ", status);
    console.log("MESSAGE -> ", message);
    let finalConfig = makeManualConfigTemplate(configUserInputs, allData);
    console.log("FINAL MANUAL CONFIG -> ", finalConfig);

    if (status === "fail") {
      updateAlertInfo({ open: true, message, severity: "error" });
    }

    if (status === "pass") {
      saveAndRunManualConfig(configUserInputs, allData);
    }
    console.groupEnd();
  };

  const handleSaveUpdateRun = (configUserInputs, allData) => {
    // console.log("run_name", configUserInputs.run_name);
    console.groupCollapsed("REQUEST SENT");
    console.log("CONFIG NAME -> ", match.params.configName);
    let { status, message, partialDataset } = validateConfig(
      configUserInputs,
      allData
    );
    // console.log("STATUS -> ", status);
    // console.log("MESSAGE -> ", message);
    // console.log("PARTIAL DATASET -> ", partialDataset);
    let finalConfig = makeConfigTemplate(configUserInputs, allData);
    console.log("CONFIG -> ", finalConfig);

    if (status === "fail") {
      updateAlertInfo({ open: true, message, severity: "error" });
    }

    if (status === "pass") {
      let { statusWeek, messageWeek } = validateDatasetWeeksSync(
        configUserInputs.models,
        allData.datasets,
        parseFloat(configUserInputs.historic_week_data)
      );
      console.log("STATUS WEEK -> ", statusWeek);
      console.log("MESSAGE WEEK -> ", messageWeek);
      if (!partialDataset?.length) {
        configUserInputs?.is_schedule_config
          ? handleScheduleSaveConfig()
          : saveAndRunConfig(configUserInputs, allData);
      } else {
        let displayMessage = "";
        partialDataset.forEach(
          (elem, index) =>
            (displayMessage += index + 1 + ": " + elem.message + "\n")
        );
        updateDialogInfo({
          message: displayMessage + "\n Do you want to continue?",
          open: true,
          handleYes: configUserInputs?.is_schedule_config
            ? handleScheduleSaveConfig
            : () => saveAndRunConfig(configUserInputs, allData),
          handleNo: handleNo,
        });
      }
    }
    console.groupEnd();
  };

  const validateConfigSelections = () => {
    if (
      configUserInputs.is_config_manual &&
      configUserInputs?.app_id === config.hardCoded.mroiAppId
    ) {
      let { status, message } = validateManualConfig(configUserInputs, allData);
      if (status === "fail") {
        updateAlertInfo({ open: true, message, severity: "error" });
        return false;
      } else {
        return true;
      }
    } else {
      let { status, message } = validateConfig(configUserInputs, allData);
      if (status === "fail") {
        updateAlertInfo({ open: true, message, severity: "error" });
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <div className={classes.root}>
      <Link
        className="disabled-style"
        to={
          match.params.tab === "addnewconfig"
            ? `/home/myconfigurations/`
            : `/home/${match.params.tab}/`
        }
      >
        <Button
          variant="outlined"
          style={{
            marginLeft: "16px",
            fontSize: "12px",
            fontWeight: "bold",
            minWidth: "80px",
          }}
        >
          Cancel
        </Button>
      </Link>

      {match.params.configName && (
        <Button
          disableRipple
          onClick={() => {
            updateDialogInfo({
              message: config.messages.confirmDelete,
              open: true,
              handleYes: handleDelete,
              handleNo: handleNo,
            });
          }}
          disabled={false}
          variant="outlined"
          style={{
            marginLeft: "16px",
            fontSize: "12px",
            fontWeight: "bold",
            minWidth: "80px",
          }}
        >
          Delete
        </Button>
      )}
      <Button
        disableRipple
        disabled={
          configUserInputs?.is_config_manual
            ? false
            : !changeInGlobalParamsFlag && globalRegionsFlag()
            ? false
            : true
        }
        variant="outlined"
        onClick={() => {
          if (
            configUserInputs.is_config_manual &&
            configUserInputs?.app_id === config.hardCoded.mroiAppId
          ) {
            if (validateConfigSelections()) {
              handleDialogOpen(
                <AddRunName
                  handleSaveRun={handleManualSaveUpdateRun}
                  handleDialogClose={handleDialogClose}
                  total_runs={configUserInputs?.metadata?.total_runs}
                />
              );
            }
          } else {
            if (validateConfigSelections()) {
              handleDialogOpen(
                <AddRunName
                  handleSaveRun={handleSaveUpdateRun}
                  handleDialogClose={handleDialogClose}
                  total_runs={configUserInputs?.metadata?.total_runs}
                />
              );
            }
          }
        }}
        style={{
          marginLeft: "16px",
          fontSize: "12px",
          fontWeight: "bold",
          minWidth: "80px",
          background: configUserInputs?.is_config_manual
            ? "#fff"
            : !changeInGlobalParamsFlag && globalRegionsFlag()
            ? "#fff"
            : "rgba(0,0,0,0.4)",
          color: configUserInputs?.is_config_manual
            ? "#46596a"
            : !changeInGlobalParamsFlag && globalRegionsFlag()
            ? "#46596a"
            : "#fff",
        }}
      >
        {match.params.configName
          ? configUserInputs?.is_schedule_config
            ? "Update & Schedule"
            : "Update & Run"
          : configUserInputs?.is_schedule_config
          ? "Save & Schedule"
          : "Save & Run"}
      </Button>
      <Button
        disableRipple
        disabled={
          configUserInputs?.is_config_manual
            ? false
            : !changeInGlobalParamsFlag && globalRegionsFlag()
            ? false
            : true
        }
        variant="contained"
        onClick={() => {
          configUserInputs.is_config_manual &&
          configUserInputs?.app_id === config.hardCoded.mroiAppId
            ? handleManualConfigSaveUpdate(configUserInputs, allData)
            : handleSaveUpdate(configUserInputs, allData);
        }}
        style={{
          marginLeft: "16px",
          fontSize: "12px",
          fontWeight: "bold",
          minWidth: "80px",
          background: configUserInputs?.is_config_manual
            ? "#46596a"
            : !changeInGlobalParamsFlag && globalRegionsFlag()
            ? "#46596a"
            : "rgba(0,0,0,0.4)",
        }}
      >
        {match.params.configName ? "Update" : "Save"}
      </Button>
      {/* )} */}

      <Modal
        modalComponent={modalComponent}
        setModalComponent={setModalComponent}
        handleDialogClose={handleDialogClose}
      />
    </div>
  );
}

Buttons.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
  allData: state.data,
});

const mapDispatchToProps = {
  updateAlertInfo,
  updateUserInfo,
  updateDialogInfo,
  refreshData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);
