// Import required libraries
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// import Button from "@material-ui/core/Button";
import { Button } from "../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

// Import utils
import { makeDefaultResponseJson } from "../../utils/utils";

// Import action creators
import {
  updateAlertInfo,
  updateUserInfo,
  updateSelections,
  updateDialogInfo,
  reloadRunData,
  refreshData,
} from "../../redux/actions";
import { config } from "../../config/config";

// Import styles
import "./SaveConfigForRun.scss";

const dialogUseStyles = makeStyles((theme) => ({
  dialog: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "850px",
      height: "auto",
      maxHeight: "700px",
    },
  },
  doneButton: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    borderRadius: "4px",
    padding: 10,
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    border: "1px solid #46596a",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#46596a",
    },
  },
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));

function SaveConfigForRun(props) {
  let {
    user,
    match,
    name,
    run: selectedRun,
    config: selectedConfig,
    handleDialogClose,
    updateAlertInfo,
    activeApp,
  } = props;
  const dialogClass = dialogUseStyles();
  const history = useHistory();
  const [newConfigName, setNewConfigName] = useState("");
  const configNameFixed = selectedConfig.brand
    ? `${config.hardCoded.mroiAppId}-${selectedConfig.country}-${selectedConfig.brand}-`
    : `${config.hardCoded.mroiAppId}-${selectedConfig.country}-`;

  const handleSaveConfigForRun = () => {
    if (newConfigName) {
      updateAlertInfo({
        open: true,
        message: config.messages.RequestingToSaveConfigForRun,
        severity: "info",
      });
      let body = {
        run_id: selectedRun.id,
        app_id: activeApp?.id,
        app_name: activeApp?.name,
        config_name: `${configNameFixed}${newConfigName.trim()}`,
      };
      let url = config.api.saveConfigForSharedRun;
      let status;
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateAlertInfo({
              open: true,
              message: config.messages.configCreatedSuccessfullyForRun,
              severity: "success",
            });
            handleDialogClose();
            reloadRunData();
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  };

  return (
    <>
      <div className="clone-run-dialog-container">
        <p>
          Save <span className="dialog-run-id">{selectedRun.id}</span> To
          Configuration
        </p>
        <div className="content">
          <p className="save-run-config-name" title={configNameFixed}>
            {configNameFixed}
          </p>
          <div className="config-name-input-container">
            <input
              type="text"
              id="save-run-config"
              name="save-run-config"
              value={newConfigName}
              className="input-label"
              onChange={(e) => {
                setNewConfigName(e.target.value);
              }}
              placeholder="Please enter configuration name"
            />
            <i
              className={`material-icons-outlined cancel-icon ${
                newConfigName ? "visible" : ""
              }`}
              onClick={() => {
                setNewConfigName("");
              }}
            >
              clear
            </i>
          </div>
        </div>
        <div className="popup-btn">
          <Button
            variant="outlined"
            // className={dialogClass.outlined}
            onClick={handleDialogClose}
            style={{
              height: "23px",
              padding: "10px",
              fontSize: "11px",
              minWidth: "15px",
              boxSizing: "border-box",
              marginRight: "4px",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            // className={dialogClass.doneButton}
            onClick={() => {
              handleSaveConfigForRun();
            }}
            style={{
              height: "23px",
              padding: "10px",
              fontSize: "11px",
              minWidth: "15px",
              boxSizing: "border-box",
              marginRight: "4px",
            }}
          >
            Done
          </Button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateAlertInfo,
  updateUserInfo,
  updateSelections,
  updateDialogInfo,
  reloadRunData,
  refreshData,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveConfigForRun);
