//Import required libraies
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

//Import Custom Component
import {
  addDataset,
  removeDataset,
  toggleDataset,
} from "../../../../redux/actions";

// Import custom hooks

// Import action creators

//Import utils
import { convertDateToFormat } from "../../../../utils/utils";
import { config } from "../../../../config/config";

// Import styles

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  typographyHeader: {
    fontSize: 12,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 5,
    backgroundColor: "#ebeff1",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
  },
}));

function DatasetType(props) {
  let {
    match,
    user,
    activeModel,
    ui_element_type,
    dataset,
    checkedFlag,
    activeModelId,
    activeDatasetId,
    setActiveDatasetId,
    addDataset,
    removeDataset,
    disableFlag,
    toggleDataset,
    datasetType,
  } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let Component;
  switch (ui_element_type) {
    case "radio_button":
      Component = (
        <>
          <div
            key={dataset.id}
            className={`dataset-checkbox-container ${
              dataset.id === activeDatasetId ? "active" : ""
            } ${datasetType.name === "merged" ? "merged-dataset" : ""}`}
            onClick={() => {
              setActiveDatasetId(dataset.id);
              let data = {
                modelId: activeModelId,
                dataset: dataset,
                flag: !checkedFlag,
              };
            }}
          >
            <i
              className={`dataset-checkbox-icon ${
                checkedFlag && "material-icons active"
              } ${disableFlag ? "disabled-pointer" : ""}`}
              // onClick={() => {
              //   let data = {
              //     modelId: activeModelId,
              //     dataset: dataset,
              //     flag: !checkedFlag,
              //     activeModel: activeModel,
              //   };
              //   toggleDataset(data);
              // }}
            >
              {checkedFlag ? "check_circle" : ""}
            </i>
            <label title={dataset.name}>{dataset.name}</label>
            {dataset.source === config.hardCoded.localSource && (
              <label
                onClick={() => {
                  setActiveDatasetId(dataset.id);
                  let data = {
                    modelId: activeModelId,
                    dataset: dataset,
                    flag: !checkedFlag,
                  };
                }}
                title={dataset.source}
                className="dataset-source-tag"
              >
                {dataset.source}
              </label>
            )}
          </div>
        </>
      );
      break;
    case "checkbox":
      Component = (
        <div
          key={dataset.id}
          className={`dataset-checkbox-container ${
            dataset.id === activeDatasetId ? "active" : ""
          } ${datasetType.name === "merged" ? "merged-dataset" : ""}`}
          onClick={() => {
            setActiveDatasetId(dataset.id);
          }}
        >
          <i
            className={`dataset-checkbox-icon ${
              checkedFlag ? "material-icons active" : "material-icons-outlined"
            } ${disableFlag ? "disabled-pointer" : ""}`}
            // onClick={() => {
            //   let data = {
            //     modelId: activeModelId,
            //     dataset: dataset,
            //     activeModel: activeModel,
            //   };
            //   checkedFlag ? removeDataset(data) : addDataset(data);
            // }}
          >
            {checkedFlag ? "check_box" : ""}
          </i>
          <label title={dataset.name}>{dataset.name}</label>
          {dataset.source === config.hardCoded.localSource && (
            <label
              onClick={() => {
                setActiveDatasetId(dataset.id);
                let data = {
                  modelId: activeModelId,
                  dataset: dataset,
                  flag: !checkedFlag,
                };
              }}
              className="dataset-source-tag"
              title={dataset.source}
            >
              {dataset.source}
            </label>
          )}
        </div>
      );
      break;
    default:
      return null;
  }

  return Component;
}

DatasetType.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = {
  addDataset,
  removeDataset,
  toggleDataset,
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetType);
