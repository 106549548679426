//Import required libraies
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Dialog } from "../../../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";

//Import Custom Component
import TestControlForm from "./TestControlForm";

// Import custom hooks

// Import action creators

// Import styles
import "./TestControlForm.scss";

// Import utils

//Import data

const useStyles = makeStyles((theme) => ({
  dialog: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "850px",
      //minHeight: "300px",
      height: "auto",
      maxHeight: "500px",
    },
  },
  outlined: {
    position: "absolute",
    bottom: "7px",
    right: "0px",
    marginTop: "20px",
    borderColor: "#46596a",
    padding: "0px 5px",
    height: "23px",
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function TestControlFormButton(props) {
  let { regionsData, testRegions, controlRegions, updateConfigUserInputs } =
    props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className={classes.outlined}
        variant="outlined"
        onClick={() => {
          handleOpen();
        }}
      >
        {testRegions.length || controlRegions.length
          ? "Edit regions"
          : "Add regions"}
      </Button>
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        {open && (
          <TestControlForm
            regionsData={regionsData}
            testRegions={testRegions}
            controlRegions={controlRegions}
            handleFormClose={handleClose}
            updateConfigUserInputs={updateConfigUserInputs}
          />
        )}
      </Dialog>
    </>
  );
}

export default TestControlFormButton;
