import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "../../uiTheme";
import Loader from "../Loader/Loader";
import CustomCheckbox from "../../screens/AIDEApp/AddNewConfig/components/CustomCheckbox";
import { Radio } from "../../uiTheme";
import { CssTextField } from "../../../styles/styles";
import { config } from "../../config/config";
import { Divider } from "@material-ui/core";
import moment from "moment";
import { formatDate, timeDifference } from "../../utils/utils";
import TimeAgo from "react-timeago";

function Comment(props) {
  const {
    comment,
    setComment,
    rowElem,
    appAccessDetails,
    handlePostComment,
    classes,
    data,
    loading,
    setLoading,
    validator_info,
    setValidator_info,
    requestor_info,
    setRequestor_info,
  } = props;
  // const [validator_info, setValidator_info] = useState([]);
  // const [requestor_info, setRequestor_info] = useState({});
  // const [loading, setLoading] = useState(true);
  const validator_info_fix = validator_info?.length
    ? validator_info[validator_info.length - 1].state
    : "New";
  useEffect(() => {
    // let url = "https://run.mocky.io/v3/a15289a3-e683-4dc6-a927-14053a0ac5b8";
    let url = config.api.viewDashboard;
    setLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        run_id: rowElem.run_id,
        model_id: rowElem.model_id,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setRequestor_info({
          requested_by: json.data.requested_by,
          requestor_info: json.data.requestor_info,
        });
        setValidator_info(json.data.validator_info);
        setLoading(false);
      });
  }, []);

  return (
    <div
      className="comment-container"
      style={{
        overflow: "auto",
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <h3>Approve and Comments</h3>
          {comment.loading && (
            <>
              <div>
                <Loader />
              </div>
            </>
          )}
          {!comment.loading && (
            <>
              <div
                style={{
                  pointerEvents: `${
                    // rowElem?.validate_state === "New" &&
                    validator_info_fix === "New" &&
                    appAccessDetails?.edit_approve_dashboard
                      ? "auto"
                      : "none"
                  }`,
                }}
              >
                <div className="radio-container">
                  <Radio
                    id={"approved"}
                    name={"Approved"}
                    value={"Approved"}
                    onChange={() => {
                      setComment((pre) => ({
                        ...pre,
                        validate_state: "Approved",
                      }));
                    }}
                    checked={
                      comment.validate_state === "Approved" ||
                      // rowElem.validate_state === "Approved"
                      validator_info_fix === "Approved"
                        ? true
                        : false
                    }
                    styleVariant={"secondary"}
                  />
                  <label className="radio-label">Approve </label>
                </div>
                <div className="radio-container">
                  <Radio
                    id={"failed"}
                    name={"Fail"}
                    value={"Failed"}
                    onChange={() => {
                      setComment((pre) => ({
                        ...pre,
                        validate_state: "Failed",
                      }));
                    }}
                    checked={
                      comment.validate_state === "Failed" ||
                      // rowElem.validate_state === "Failed"
                      validator_info_fix === "Failed"
                        ? true
                        : false
                    }
                    styleVariant={"secondary"}
                  />
                  <label className="radio-label">Fail </label>
                </div>
              </div>
              {
                // rowElem?.validate_state === "New"
                validator_info_fix === "New" && (
                  <div
                    style={{
                      padding: "0 13px",
                      marginTop: 30,
                      pointerEvents: `${
                        appAccessDetails?.edit_approve_dashboard
                          ? "auto"
                          : "none"
                      }`,
                    }}
                  >
                    <CssTextField
                      placeholder="Add a comment..."
                      className={classes.typographyStyle}
                      defaultValue={""}
                      value={`${comment.comment}`}
                      multiline
                      onChange={(event) => {
                        setComment((pre) => ({
                          ...pre,
                          comment: `${event.target.value}`,
                        }));
                      }}
                      spellCheck={false}
                    />
                    <div
                      className=""
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        margin: "10px 0px",
                      }}
                    >
                      {!data.appAccessDetails.feature_list
                        .edit_approve_dashboard && (
                        <p
                          style={{
                            color: "#eb367f",
                            fontSize: "10px",
                            margin: "0 0.8rem 0 0",
                          }}
                        >
                          You don't have permission to edit this dashboard
                        </p>
                      )}
                      <Button
                        disabled={
                          comment.comment?.length && comment.validate_state
                            ? false
                            : true
                        }
                        style={{
                          opacity: `${
                            comment.comment?.length && comment.validate_state
                              ? 1
                              : 0.7
                          }`,
                        }}
                        onClick={handlePostComment}
                      >
                        Post
                      </Button>
                    </div>
                  </div>
                )
              }
              <div>
                {validator_info?.length ? (
                  <div className="" style={{ marginTop: 30, marginBottom: 15 }}>
                    <h3 style={{ marginBottom: 0 }}>I&A Team:</h3>
                    {/* {rowElem.validator_comment.map((elm) => {
                    let val = Object.entries(elm).flat();
                    return (
                      <>
                        <name
                          style={{
                            fontSize: "14px",
                            color: "#6c6f72",
                            fontWeight: 600,
                            marginLeft: "13px",
                          }}
                        >
                          {val[0]}:
                        </name>
                        <p
                          className=""
                          style={{
                            fontSize: 13,
                            color: "#46596a",
                            padding: "0 13px",
                            margin: 0,
                          }}
                        >
                          {val[1].split("\n").map((el) => (
                            <>
                              {el}
                              <br />
                            </>
                          ))}
                        </p>
                      </>
                    );
                  })} */}
                    {validator_info?.length &&
                      validator_info.map((elm) => {
                        return (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                              }}
                            >
                              <name
                                style={{
                                  fontSize: "14px",
                                  color: "#6c6f72",
                                  fontWeight: 600,
                                  marginLeft: "13px",
                                }}
                              >
                                {elm.validated_by}:
                              </name>
                              <p
                                style={{
                                  fontSize: 12,
                                  color: "#46596a",
                                  padding: "0 13px",
                                  margin: 0,
                                }}
                              >
                                {
                                  // timeDifference(elm.validated_on) < 7 ? (
                                  //   //  moment(elm.validated_on).fromNow()
                                  //   <TimeAgo
                                  //     date={new Date(elm.validated_on)}
                                  //   />
                                  // ) : (
                                  formatDate(elm.validated_on, "MMM-DD-YYYY")
                                  // )

                                  // moment(item.requested_on).fromNow()}
                                }
                                {/* {moment(elm.validated_on).fromNow()} */}
                              </p>
                            </div>
                            <p
                              className=""
                              style={{
                                fontSize: 13,
                                color: "#46596a",
                                padding: "0 13px",
                                margin: 0,
                              }}
                            >
                              {elm.validator_comment.split("\n").map((el) => (
                                <>
                                  {el}
                                  <br />
                                </>
                              ))}
                            </p>
                          </>
                        );
                      })}
                  </div>
                ) : (
                  ""
                )}
                <Divider />
                {requestor_info?.requestor_info?.length &&
                  requestor_info?.requestor_info.map((item) => {
                    return (
                      <div className="" style={{ marginTop: 15 }}>
                        <h3 style={{ marginBottom: 0 }}>Data Scientist:</h3>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                          }}
                        >
                          <name
                            style={{
                              fontSize: "14px",
                              color: "#6c6f72",
                              fontWeight: 600,
                              marginLeft: "13px",
                            }}
                          >
                            {requestor_info.requested_by}:
                          </name>
                          <p
                            style={{
                              fontSize: 12,
                              color: "#46596a",
                              margin: 0,
                              padding: "0 13px",
                            }}
                          >
                            {
                              // timeDifference(item.requested_on) < 7 ? (
                              //   //  moment(item.requested_on).fromNow()
                              //   <TimeAgo date={new Date(item.requested_on)} />
                              // ) : (
                              formatDate(item.requested_on, "MMM-DD-YYYY")
                              // )

                              // moment(item.requested_on).fromNow()}
                            }
                          </p>
                        </div>
                        <p
                          style={{
                            fontSize: 13,
                            color: "#46596a",
                            padding: "0 13px",
                            margin: 0,
                          }}
                        >
                          {item.requestor_comment.split("\n").map((el) => (
                            <>
                              {el}
                              <br />
                            </>
                          ))}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  appAccessDetails: state?.data?.appAccessDetails?.feature_list,
});

export default connect(mapStateToProps, null)(Comment);
