// Import required libraries
import React, { useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

// Import custom components
import Loader from "../../../components/Loader/Loader";
import ViewrunsRow from "./ViewrunsRow";

// Import utils & data
import {
  makeDefaultResponseJson,
  convertDateToEpoch,
  getStartEndByDayCat,
} from "../../../utils/utils";
import { config } from "../../../config/config";

// Import actions
import {
  updateUserInfo,
  updateRunData,
  updateAlertInfo,
} from "../../../redux/actions";

// Import styles
import "./ViewRunsSideDiv.scss";

// Styling material ui components
const Accordion = withStyles({
  root: {
    //border: "1px solid rgba(0, 0, 0, .125)",
    fontFamily: "Hind Siliguri",
    color: "#46596a",
    fontSize: "13px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    //backgroundColor: "rgba(0, 0, 0, .03)",
    //borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    color: "#46596a",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    //padding: theme.spacing(2),
    display: "block",
    padding: "0px",
    maxHeight: 250,
    overflowY: "auto",
  },
}))(MuiAccordionDetails);

// Defining custom components
const ViewrunsCat = (props) => {
  let {
    match,
    user,
    runCat,
    handleChange,
    activeRunCat,
    allData,
    updateUserInfo,
    updateRunData,
    updateAlertInfo,
  } = props;
  const [loading, setLoading] = useState(false);
  const [showMoreData, setShowMoreData] = useState(true);

  let app = config.hardCoded.mroiAppId.toLowerCase();
  let activeApp =
    allData.apps.data.length > 0
      ? allData.apps.data.find(
          (obj) => obj.name.toLowerCase() === app.toLowerCase()
        )
      : null;

  //Load More Data Function
  const loadMoreRuns = () => {
    if (activeApp && allData.runData) {
      setLoading(true);
      let offset = 0;
      let prevRunCat = allData.runData.find(
        (runCat) => runCat.id === config.hardCoded.runPrevRunsId
      );
      if (prevRunCat) {
        offset = prevRunCat.data.length;
      }
      let userRunFilters = user.selections.runFilters;
      let body = {
        app_id: activeApp?.id,
        offset,
        limit: config.runsLimit,
        order_by: userRunFilters.orderBy,
        search_text: userRunFilters.searchText || null,
        start_time: getStartEndByDayCat(user.selections.runFilters.dayCat)
          .start_time,
        end_time: getStartEndByDayCat(user.selections.runFilters.dayCat)
          .end_time,
        country: null,
        brand: null,
        segment: null,
        models: [],
        category: ["Success", "Failed"],
      };
      let url = config.api.runUrl;
      let status;
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          setLoading(false);
          if (status === 200) {
            json.data.length ? setShowMoreData(true) : setShowMoreData(false);
            let previousRunData = allData.runData.find(
              (item) => item.id === "previous_runs"
            );
            updateRunData([...previousRunData.data, ...json.data]);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  };

  return (
    <div className="view-runs-category">
      <Accordion
        square
        expanded={activeRunCat === runCat.id}
        onChange={handleChange(runCat.id)}
      >
        <AccordionSummary
          expandIcon={<i className="material-icons">expand_more</i>}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          {runCat.name.toUpperCase()}
        </AccordionSummary>
        <AccordionDetails>
          {runCat.data.length ? (
            runCat.data.map((run) => (
              <ViewrunsRow
                key={run.id}
                runCat={runCat}
                run={run}
                match={match}
              />
            ))
          ) : (
            <p className="no-data">{config.messages.noRuns}</p>
          )}
          {!showMoreData && <p className="no-data">{config.messages.noRuns}</p>}
          {runCat.id === config.hardCoded.runPrevRunsId ? (
            loading ? (
              <Loader />
            ) : runCat.data.length && showMoreData ? (
              <div className="run-show-more">
                <span
                  onClick={() => {
                    loadMoreRuns();
                  }}
                >
                  Show More
                </span>
              </div>
            ) : null
          ) : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

ViewrunsCat.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateUserInfo,
  updateRunData,
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewrunsCat);
