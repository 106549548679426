import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MultiSelectWithCheckBox from "../../../../components/MultiSelectWithCheckBox/MultiSelectWithCheckBox";

//import config
import { config } from "../../../../config/config";

import {
  getStartOrEndDate,
  checkDatesOverlapping,
  removeAllInData,
  salesMediaFilterPayload,
} from "../../../../utils/utils";

//import action
import { updateAlertInfo } from "../../../../redux/actions";
import Loader from "../../../../components/Loader/Loader";
import moment from "moment";
const initialState = { loading: false, data: null, errorMessage: null };
function DatasetCampaign(props) {
  const {
    filterMediaDataset,
    disableFlag,
    userInputs,
    activeApp,
    testDataRow,
    onChange,
    updateAlertInfo,
    activeModel,
    allData,
  } = props;
  // Update campaigns data if user changes country,brand, datasets, activeModel
  const [campaignData, setCampaignData] = useState({
    ...initialState,
  });
  const selectedModel = userInputs.models.find(
    (model) => activeModel.id === model.id
  );
  let start_date = "";
  let end_date = "";
  const [filterCampaignData, setFilterCampaignData] = useState(null);
  const { dataset_id, target_campaigns } = testDataRow;
  start_date = testDataRow.test_group_parameters[0]?.pilot_start;
  end_date = moment(start_date, "YYYY-MM-DD")
    .add(testDataRow.test_group_parameters[0]?.weeks_pilot, "weeks")
    .format("YYYY-MM-DD");
  const filterCampaignBasedOnDateRange = (data) => {
    const { start_date, end_date } = getStartOrEndDate(
      testDataRow.test_group_parameters[0].pilot_start,
      testDataRow.test_group_parameters[0].weeks_pilot
    );
    const filterDataset = data.filter((elem) => {
      let checkForValidCampaign = checkDatesOverlapping(
        start_date,
        end_date,
        elem.campaign_start_date,
        elem.campaign_end_date,
        "YYYY-MM-DD"
      );
      const { type, status } = checkForValidCampaign;
      if (type || status) {
        return true;
      } else {
        return false;
      }
    });
    return filterDataset;
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    if (dataset_id?.length && !disableFlag && userInputs?.brand) {
      setCampaignData((preState) => {
        return { ...preState, loading: true, data: null };
      });
      // setFilterCampaignData(null);
      const { media_segment, media_sub_brand, media_sub_segment } =
        salesMediaFilterPayload(userInputs, allData);
      let body = {
        dataset_ids: dataset_id,
        country: userInputs.country,
        app_id: activeApp?.id,
        brand: userInputs.brand,
        media_segment: media_segment,
        media_sub_brand: media_sub_brand,
        media_sub_segment: media_sub_segment,
        pilot_start_date: start_date,
        pilot_end_date: end_date,
      };
      let url = config.api.campaignsUrl;
      let status;
      fetch(url, {
        signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            // const filterDataset = filterCampaignBasedOnDateRange(json?.data);
            const filterDataset = json?.data;
            if (target_campaigns?.length) {
              const filterCampData = target_campaigns?.reduce((acc, curr) => {
                if (
                  filterDataset.find((e) => e.campaignid === curr.campaignid)
                ) {
                  acc.push(curr);
                }
                return acc;
              }, []);
              onChange("target_campaigns", filterCampData);
            }
            setFilterCampaignData(filterDataset);
            setCampaignData((preState) => {
              return { ...preState, loading: false, data: json.data };
            });
          } else {
            setCampaignData((preState) => {
              return {
                ...preState,
                loading: false,
                data: null,
                message: json.statusMessage,
              };
            });
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        })
        .catch((e) => {
          console.warn(`Fetch 2 error: ${e.message}`);
        });
    } else if (
      !dataset_id?.length &&
      !disableFlag &&
      userInputs.is_config_manual
    ) {
      setCampaignData({ ...campaignData, loading: false, data: null });
      setFilterCampaignData(null);
    }
    return () => {
      setCampaignData({ ...campaignData, loading: false });

      controller.abort();
    };
  }, [dataset_id?.length, start_date, end_date]);

  useEffect(() => {
    if (
      campaignData?.data?.length &&
      userInputs?.datasetDetailsData?.data?.length &&
      userInputs?.brand &&
      !userInputs.is_config_manual
    ) {
      // const filterDataset = filterCampaignBasedOnDateRange(campaignData?.data);
      const filterDataset = campaignData?.data;
      setFilterCampaignData(filterDataset);
      const filterCampData = target_campaigns?.reduce((acc, curr) => {
        if (filterDataset.find((e) => e.campaignid === curr.campaignid)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      onChange("target_campaigns", filterCampData);
    }
  }, [
    testDataRow.test_group_parameters[0]?.pilot_start,
    testDataRow.test_group_parameters[0]?.weeks_pilot,
  ]);

  useEffect(() => {
    if (
      campaignData?.data?.length &&
      userInputs?.datasetDetailsData?.data?.length &&
      userInputs?.brand &&
      userInputs.is_config_manual
    ) {
      // const filterDataset = filterCampaignBasedOnDateRange(campaignData?.data);
      const filterDataset = campaignData?.data;
      setFilterCampaignData(filterDataset);
      const filterCampData = target_campaigns?.reduce((acc, curr) => {
        if (filterDataset.find((e) => e.campaignid === curr.campaignid)) {
          acc.push(curr);
        }
        return acc;
      }, []);
      onChange("target_campaigns", filterCampData);
    }
  }, [
    testDataRow.test_group_parameters[0]?.pilot_start,
    testDataRow.test_group_parameters[0]?.weeks_pilot,
    campaignData?.data,
  ]);

  return (
    <>
      <div>
        <MultiSelectWithCheckBox
          name={"Select Datasets:"}
          data={!disableFlag ? filterMediaDataset : dataset_id}
          width={"465px"}
          handleChange={onChange}
          id={"dataset_id"}
          value={dataset_id}
          disableFlag={disableFlag}
          noDataLabel={"No Datasets"}
          disable={
            disableFlag ? false : filterMediaDataset?.length ? false : true
          }
        />
      </div>
      <div style={{ marginLeft: "15px" }}>
        {campaignData.loading ? (
          <Loader />
        ) : (
          <MultiSelectWithCheckBox
            name={"Select Campaigns:"}
            data={!disableFlag ? filterCampaignData : campaignData?.data}
            width={"465px"}
            disable={disableFlag ? false : campaignData?.data ? false : true}
            handleChange={onChange}
            id={"target_campaigns"}
            value={target_campaigns}
            disableFlag={disableFlag}
            noDataLabel={"No Campaigns"}
          />
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  allData: state.data,
});

const mapDispatchToProps = {
  updateAlertInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetCampaign);
