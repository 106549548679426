import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";

function CustomFileInput(props) {
  const {
    accept = "",
    style = {},
    // label = "",
    onChange = () => {},
    disabled,
    children = <p>Default File Upload</p>,
    uploadState,
    setUiError,
    id,
    updateAlertInfo,
    configUserInputs,
    downLoadFile,
  } = props;
  const initialId = id ? id : v4();
  const fileId = useRef(initialId);

  const useStyles = makeStyles(() => ({
    root: {
      marginRight: style?.margin?.right || "0px",
      marginLeft: style?.margin?.left || "0px",
      position: "relative",
    },
    inputFile: {
      opacity: "0",
      width: "0.1px",
      height: "0.1px",
      position: "absolute",
      cursor: "pointer",
      "&:disabled": {
        cursor: "default",
      },
    },
    inputFileSelected: {
      opacity: "0",
      width: "0.1px",
      height: "0.1px",
      position: "absolute",
      "&:disabled": {
        cursor: "default",
      },
    },
    replace: {
      backgroundColor: "#465A69",
      borderRadius: "5px",
      padding: "7px 15px",
      color: "white",
      fontWeight: "500",
      fontSize: "12px",
      position: "absolute",
      cursor: "pointer",
      right: 13,
      bottom: 11,
      margin: 0,
    },
    label: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "max-content",
      width: "100%",
      boxSizing: "border-box",
      border: "1px dashed rgba(0, 0, 0, 0.3)",
      borderRadius: "3px",
      padding: "20px 20px",
      backgroundColor: "transparent",
      cursor: "pointer",
      "&:disabled": {
        cursor: "default",
      },
    },
    selectedLabel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "max-content",
      width: "100%",
      boxSizing: "border-box",
      border: "1px dashed rgba(0, 0, 0, 0.3)",
      borderRadius: "3px",
      padding: "20px 20px",
      backgroundColor: "transparent",
      "&:disabled": {
        cursor: "default",
      },
    },
  }));

  const classes = useStyles();

  const handleChange = (e) => {
    setUiError("");
    let fileInputField = document.getElementById(fileId.current);

    let fileSize = 0;
    for (const file of fileInputField.files) {
      fileSize += +file.size;
    }

    if (fileSize === 0) {
      updateAlertInfo({
        open: true,
        message: "Please select a non blank file",
        severity: "error",
      });
    } else if (fileSize >= 5242880) {
      updateAlertInfo({
        open: true,
        message: "Allowed maximum size is 5MB",
        severity: "error",
      });
    } else {
      onChange(e, "select");
    }
  };

  return (
    <div className={classes.root}>
      {uploadState !== "success" && uploadState !== "error" && (
        <label
          disabled={disabled}
          htmlFor={fileId.current}
          className={classes.label}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            onChange(e, "drop");
          }}
        >
          <input
            type="file"
            id={fileId.current}
            className={classes.inputFile}
            accept={accept}
            onChange={(e) => handleChange(e)}
            disabled={disabled}
          />
          {children}
        </label>
      )}
      {(uploadState === "success" || uploadState === "error") && (
        <>
          <div
            className={classes.selectedLabel}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={(e) => {
              e.preventDefault();
              onChange(e, "drop");
            }}
            disabled={disabled}
          >
            {children}
            <div>
              {configUserInputs?.configType !== "run" && (
                <label htmlFor={fileId.current} disabled={disabled}>
                  <input
                    type="file"
                    id={fileId.current}
                    className={classes.inputFileSelected}
                    accept={accept}
                    onChange={(e) => handleChange(e)}
                    disabled={disabled}
                  />
                  <p className={classes.replace}>Replace and Upload</p>
                </label>
              )}
              {configUserInputs?.configType === "run" && (
                <label disabled={disabled}>
                  <p
                    className={classes.replace}
                    style={{ pointerEvents: "auto" }}
                    onClick={downLoadFile}
                  >
                    Download
                  </p>
                </label>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CustomFileInput;
