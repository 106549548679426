//Import required libraies
import React, { useState } from "react";
import Radio from "@material-ui/core/Radio";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles
import "./IncludeExcludeRegions.scss";

const BlueRadio = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: "#4eafb3",
    "&$checked": {
      color: "#4eafb3",
    },
    "&$disabled": {
      color: "#46596a",
      opacity: 0.5,
    },
    "& .MuiSvgIcon-root": {
      height: 15,
      weight: 15,
    },
  },
  checked: {},
  disabled: {
    color: "#46596a",
    opacity: 0.5,
  },
})((props) => <Radio color="default" {...props} />);

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
  },
});

function IncludeExcludeRegions(props) {
  let { onChange, value } = props;

  return (
    <div className="region-radio-container">
      <div className="radio-icon">
        <ThemeProvider theme={theme}>
          <BlueRadio
            value="include"
            checked={value === "include"}
            onChange={(e) => {
              onChange("regionFilterType", e.target.value);
            }}
            name="include"
            size="small"
            disabled={false}
            disableRipple
          />
        </ThemeProvider>
        <label className="radio-label">Include</label>
      </div>
      <div className="radio-icon">
        <ThemeProvider theme={theme}>
          <BlueRadio
            value="exclude"
            checked={value === "exclude"}
            onChange={(e) => {
              onChange("regionFilterType", e.target.value);
            }}
            name="exclude"
            size="small"
            disabled={false}
            disableRipple
          />
        </ThemeProvider>
        <label className="radio-label">Exclude</label>
      </div>
    </div>
  );
}

export default IncludeExcludeRegions;
