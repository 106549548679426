import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
//import custom dataset
import Modal from "../../../../components/Modal/Modal";
import Loader from "../../../../components/Loader/Loader";

//import style and config
import { config } from "../../../../config/config";
import { addMergeDataset, editMergeDataset } from "../../../../redux/actions";
import "./MergeDataset.scss";

import chooseChannel from "../../../../../assets/images/choose_channel.svg";

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    //width: "190px",
    minWidth: "200px",
    maxWidth: "300px",
    width: "270px",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a !important",
    backgroundColor: "white",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  menuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "0px",
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "0px 12px",
    color: "#46596a",
    width: "auto",
    width: "auto!important",
    overflow: "hidden!important",
    fontSize: "13px!important",
    boxSizing: "border-box!important",
    minHeight: "35px!important",
    fontFamily: "Hind Siliguri!important",
    fontWeight: "400!important",
    lineHeight: "15px!important",
    paddingTop: "6px!important",
    whiteSpace: "nowrap!important",
    letterSpacing: ".00938em!important",
    paddingBottom: "6px!important",
  },
  listItem: {
    "& span.MuiTypography-body1": {
      fontSize: "12px",
      color: "#46596a",
      fontFamily: "Hind Siliguri",
      paddingRight: "5px",
    },
  },
  popover: {
    pointerEvents: "none",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    width: "800px",
    margin: "10px",
  },
  typographyHeader: {
    fontSize: 12,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 5,
    backgroundColor: "#ebeff1",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function MergedDataset(props) {
  const {
    open,
    activeAppID,
    setMergedPopover,
    activeModelId,
    addMergeDataset,
    editMergeDataset,
    configUserInputs,
    mergedDS,
    datasetsTypesData,
    selectedModel,
    disableFlag,
    allData,
  } = props;
  const classes = useStyles();
  const [channelData, setChannelData] = useState(null);
  const [channel, setChannel] = useState("");
  const [selectedDataset, setSelectedDataset] = useState([]);
  const [mergeDatasetName, setMergeDatasetName] = useState("");
  const [modalComponent, setModalComponent] = useState({
    component: null,
    openModal: false,
    handleCancel: () => {},
    handleDone: () => {},
  });
  const activeModel = configUserInputs.models.find(
    (model) => model.id === activeModelId
  );
  useEffect(() => {
    if (!isEmpty(mergedDS)) {
      setChannel(mergedDS.channel);
      setSelectedDataset(mergedDS.dataset_list);
      setMergeDatasetName(mergedDS.name);
    }
  }, []);

  const [error, setError] = useState({
    datasetValidation: "",
    name: "",
  });

  const handleDialogClose = () => {
    setModalComponent((prevState) => {
      return { ...prevState, openModal: false };
    });
    setMergedPopover(false);
  };
  const uniqueNameCheck = activeModel?.merged_data_sources?.find(
    (dataset) => dataset.name === mergeDatasetName
  )?.name;
  const handleSubmit = () => {
    if (isEmpty(mergedDS)) {
      if (mergeDatasetName) {
        const value = {
          id: activeModel.merged_data_sources?.length
            ? `MERGED_DATASET_${
                activeModel.merged_data_sources?.length < 10 ? 0 : ""
              }${activeModel.merged_data_sources?.length + 1}`
            : "MERGED_DATASET_01",
          pipeline_dataset_id: activeModel.merged_data_sources?.length
            ? `MERGED_DATASET_${
                activeModel.merged_data_sources?.length < 10 ? 0 : ""
              }${activeModel.merged_data_sources?.length + 1}`
            : "MERGED_DATASET_01",
          name: mergeDatasetName,
          dataset_list: selectedDataset,
          channel: channel,
        };
        let data = {
          modelId: activeModelId,
          value,
        };
        addMergeDataset(data);
        handleDialogClose();
        setMergedPopover(false);
      } else {
        setError({ ...error, name: "Please enter a merged dataset name" });
      }
    } else {
      if (mergeDatasetName) {
        const value = {
          ...mergedDS,
          name: mergeDatasetName,
          dataset_list: selectedDataset,
          channel: channel,
        };
        let data = {
          modelId: activeModelId,
          value,
        };
        editMergeDataset(data);
        handleDialogClose();
        setMergedPopover(false);
      } else {
        setError({ ...error, name: "Please enter a merged dataset name" });
      }
    }
  };

  //validation for the merge dataset name
  useEffect(() => {
    if (isEmpty(mergedDS)) {
      if (uniqueNameCheck) {
        setError({
          ...error,
          name: "This name is already in use by other merged dataset.",
        });
      } else {
        setError({
          ...error,
          name: "",
        });
      }
    } else {
      if (uniqueNameCheck === mergedDS.name) {
        setError({
          ...error,
          name: "",
        });
      } else if (uniqueNameCheck) {
        setError({
          ...error,
          name: "This name is already in use by other merged dataset.",
        });
      } else {
        setError({
          ...error,
          name: "",
        });
      }
    }
  }, [mergeDatasetName]);

  //validation for the merge dataset
  useEffect(() => {
    if (selectedDataset?.length > 5 && channel) {
      setError({
        ...error,
        datasetValidation: "Cannot select more than five datasets to merge.",
      });
    } else if (
      selectedDataset?.length &&
      selectedDataset?.length < 2 &&
      channel
    ) {
      setError({
        ...error,
        datasetValidation: "Cannot select less than two datasets to merge.",
      });
    } else {
      setError({ ...error, datasetValidation: "" });
    }
  }, [selectedDataset]);

  useEffect(() => {
    const url = `${
      config.api.mergeDataset
    }?app_id=${activeAppID}&type=${"MEDIA"}`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        let uiChannelData = {};
        let datasets = datasetsTypesData?.find(
          (data) => data.name === "media"
        ).data;
        datasets = datasets?.map((dataset) => ({
          ...dataset,
        }));
        let newDatasetList;
        for (const [key, value] of Object.entries(json.data)) {
          let finalArr = datasets.filter((val) => value.includes(val.id));
          if (key === mergedDS.channel) {
            newDatasetList = mergedDS.dataset_list.map((elem) => {
              return {
                ...allData?.datasets?.find((val) => val.id === elem),
              };
            });
            finalArr = [...finalArr, ...newDatasetList];
          }
          uiChannelData = { ...uiChannelData, [key]: finalArr };
        }
        setChannelData(uiChannelData);
      });
  }, []);

  useEffect(() => {
    setModalComponent((prevState) => {
      return {
        ...prevState,
        openModal: true,
        component: component,
      };
    });
  }, [channelData, channel, selectedDataset, mergeDatasetName, error]);

  const component = (
    <div
      style={{
        width: "600px",
        height: "400px",
        pointerEvents: disableFlag ? "none" : "",
      }}
    >
      <p style={{ color: "#eb367f", fontWeight: "600", margin: 0 }}>
        {`${
          disableFlag ? "" : !isEmpty(mergedDS) ? "Edit" : "Add"
        } Merged Dataset`}
      </p>
      {channelData ? (
        <section className="merge-dataset-section">
          <div className="merge-dataset-container">
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label className="merge-dataset-input-label">
                    Select Channel name
                  </label>
                  <Select
                    value={channel}
                    onChange={(e) => {
                      setChannel(e.target.value);
                      setSelectedDataset([]);
                    }}
                    className={classes.multiSelect}
                    MenuProps={MenuProps}
                    label="Select a channel"
                    displayEmpty
                  >
                    {Object.keys(channelData)?.map((value) => (
                      <MenuItem
                        value={value}
                        key={value}
                        className={classes.singleMenuItem}
                      >
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {channel && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 30,
                    }}
                  >
                    <label className="merge-dataset-input-label">
                      Merged Dataset Name
                    </label>
                    <input
                      type="text"
                      placeholder="Please enter a merged dataset name"
                      className="merge-dataset-name"
                      value={mergeDatasetName}
                      onChange={(e) => {
                        setError({ ...error, name: "" });
                        setMergeDatasetName(e.target.value);
                      }}
                      required
                    />
                  </div>
                )}
              </div>
              <div style={{ padding: "10px 0" }}>
                {channel ? (
                  <div
                    className={`${
                      channelData && channel && channelData[channel]?.length
                    }?"datasets-selections-container":""`}
                  >
                    {channelData[channel].length &&
                    activeModelId !== config.hardCoded.salesUpliftId ? (
                      channelData[channel]?.map((e, i) => {
                        const checked = selectedDataset?.includes(e.id);
                        return (
                          <div className="checkbox-selection-row" key={i}>
                            <i
                              className={`kpi-checkbox-icon
                ${
                  checked ? "material-icons active" : "material-icons-outlined"
                }`}
                              onClick={() => {
                                let data;
                                if (
                                  selectedDataset?.length &&
                                  selectedDataset?.includes(e.id)
                                ) {
                                  data = selectedDataset?.filter(
                                    (dataset) => dataset !== e.id
                                  );
                                } else {
                                  data = [...selectedDataset, e.id];
                                }
                                setSelectedDataset(data);
                              }}
                            >
                              {checked
                                ? "check_box"
                                : "check_box_outline_blank"}
                            </i>
                            <label className="checkbox-label">{e.name}</label>
                            {e.source === config.hardCoded.localSource && (
                              <label
                                className="merge-dataset-source-tag"
                                title={e.source}
                              >
                                {e.source}
                              </label>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#eb367f",
                          fontSize: 12,
                          flexDirection: "column",
                        }}
                      >
                        <img src={chooseChannel} height={200} width={200} />
                        <p>No Dataset Available to select.</p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#eb367f",
                      fontSize: 12,
                      flexDirection: "column",
                    }}
                  >
                    <img src={chooseChannel} height={200} width={200} />
                    <p>Choose a channel to begin.</p>
                  </div>
                )}
              </div>
            </div>
            <section
              className="button-section"
              style={{
                display: "flex",
                justifyContent: `${
                  error.datasetValidation || error.name
                    ? "space-between"
                    : "flex-end"
                }`,
                alignItems: "center",
              }}
            >
              {(error.datasetValidation || error.name) && (
                <p className="error-message" style={{ margin: "0px" }}>
                  {error.datasetValidation
                    ? error.datasetValidation
                    : error.name}
                </p>
              )}
              <div>
                {!disableFlag && (
                  <>
                    <button
                      className="primary-button"
                      onClick={handleDialogClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="secondary-button"
                      onClick={handleSubmit}
                      disabled={
                        error.datasetValidation ||
                        error.name ||
                        selectedDataset?.length < 1 ||
                        (!mergeDatasetName && !uniqueNameCheck)
                          ? true
                          : false
                      }
                      style={{
                        opacity: `${
                          error.datasetValidation ||
                          error.name ||
                          selectedDataset?.length < 1 ||
                          (!mergeDatasetName && !uniqueNameCheck)
                            ? 0.5
                            : 1
                        }`,
                        cursor: `${
                          error.datasetValidation ||
                          error.name ||
                          selectedDataset?.length < 1 ||
                          (!mergeDatasetName && !uniqueNameCheck)
                            ? "auto"
                            : "pointer"
                        }`,
                      }}
                    >
                      {`${!isEmpty(mergedDS) ? "Update" : "Add"} Dataset`}
                    </button>
                  </>
                )}
                {disableFlag && (
                  <button
                    style={{ pointerEvents: "auto" }}
                    className="secondary-button"
                    onClick={handleDialogClose}
                  >
                    Close
                  </button>
                )}
              </div>
            </section>
          </div>
        </section>
      ) : (
        <Loader />
      )}
    </div>
  );

  // merged_data_sources
  // {
  //       "id": "MERGED_DATASET_01",
  //       "name": "ONLINE_SEARCH",
  //       "pipeline_dataset_id": "MERGED_DATASET_01",
  //       "dataset_list": [
  //         "FB",
  //         "FACEBOOK"
  //       ]
  //     }

  return (
    <>
      <div>
        <Modal
          modalComponent={modalComponent}
          setModalComponent={setModalComponent}
          handleDialogClose={handleDialogClose}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  configUserInputs: state.configUserInputs,
  allData: state.data,
});

const mapDispatchToProps = {
  addMergeDataset,
  editMergeDataset,
};

export default connect(mapStateToProps, mapDispatchToProps)(MergedDataset);
