//Import required libraies
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Dialog, Chip } from "../../../../uiTheme";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Chip from "@material-ui/core/Chip";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

//Import Custom Component
import MultiSelect from "./MultiSelect";
import IncludeExcludeRegions from "./IncludeExcludeRegions";

// Import custom hooks

// Import action creators

// Import styles
import "./ModelRegion.scss";

// Import utils & data
import { config } from "../../../../config/config";

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    backgroundColor: "#f3f3f3",
    padding: "5px",
    //borderRadius: "4px",
    height: "28px",
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFormControl-fullWidth": {
      minWidth: "250px",
      width: "250px",
      // maxWidth: "900px",
      // width: "100%",
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri !important",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-option": {
      backgrounColor: "#46596a3b",
      fontFamily: "Hind Siliguri !important",
    },
  },
  root: {
    fontFamily: "Hind Siliguri !important",
    // width: "100%",
    display: "flex",
    alignItems: "center",
    // "& > * + *": {
    //   marginTop: theme.spacing(3),
    // },
  },
  chipStyling: {
    marginTop: "0px",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    border: "1px solid #dedede",
    color: "white !important",
    height: "28px",
    borderRadius: "4px",
    width: "fit-content",
    margin: "5px",
    background: "#4EAFB3",
    "&:focus": {
      background: "#4EAFB3",
    },
    "& .MuiChip-deleteIcon": {
      // width: "20px",
      fontSize: 20,
      border: "none",
      color: "white",
    },
  },
}));

const StyledAutoComplete = withStyles((theme) => ({
  endAdornment: {
    display: "none",
  },
  tag: {
    // background: "white",
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
}))(Autocomplete);

const useChipStyle = makeStyles((theme) => ({
  chipStyle: {
    display: "flex",
    alignItems: "center",
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      border: "1px solid #dedede",
      fontSize: 14,
      color: "white !important",
      height: "30px",
      borderRadius: "3px",
      width: "10%",
      margin: "0px 5px 5px 0",
      background: "#4EAFB3",
      "& .MuiChip-deleteIcon": {
        fontSize: 20,
        border: "none",
        color: "white",
      },
    },
  },
}));

const useDialogStyle = makeStyles((theme) => ({
  dialogStyle: {
    fontFamily: "Hind Siliguri",
    "& .MuiTypography-h6": {
      fontSize: 14,
      color: "#46596a !important",
    },
    "& .MuiDialogContent-root": {
      fontSize: 12,
      color: "#46596a !important",
      opacity: 0.8,
    },
    "& .MuiButton-textPrimary": {
      color: "#46596a",
    },
    "& .MuiButton-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 14,
    },
    "& .MuiDialogContent-dividers": {
      padding: "0px 24px",
    },
  },
}));

function ScrollDialog(props) {
  let { data, dialogOpen, setDialogOpen } = props;
  const classes = useDialogStyle();

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <div className={classes.dialogStyle}>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        className={classes.dialogStyle}
      >
        <DialogTitle id="scroll-dialog-title">All Selected Regions</DialogTitle>
        <DialogContent dividers={true}>
          {data ? (
            data.map((regions, index) => <p key={index}>{regions}</p>)
          ) : (
            <p className="no-data">No data</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function TestControlDisplayRow(props) {
  let { data, setDialogOpen, setData } = props;
  let dataSubset = data?.slice(0, 15);
  let moreFlag = dataSubset?.length !== data?.length;
  const classes = useChipStyle();

  return (
    <>
      <div className={classes.chipStyle}>
        {dataSubset?.map((value, index) => (
          <Chip key={index} label={value} />
        ))}
        {moreFlag && (
          <span
            className="show-more"
            style={{ cursor: "pointer", color: "#46596a" }}
            onClick={() => {
              setData(data);
              setDialogOpen(true);
            }}
          >
            Show more
          </span>
        )}
      </div>
    </>
  );
}

function ModelRegion(props) {
  let {
    disableFlag,
    userInputs,
    onChange,
    regionValue,
    modelRegionSelectionData,
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  //let { accessor, userInputs, updateUserInputs, data } = props;
  const classes = useStyles();

  const handleChange = (e, value) => {
    onChange("regions", value);
  };

  const delRegion = (regValue) => {
    let regionsArr = regionValue.regions.filter(
      (singleRegion) => singleRegion !== regValue
    );
    // onChange((value) => regionValue.regions.filter((v) => v.title !== title));
    onChange("regions", regionsArr);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  //Empty control and region if user changes regionGranType or country
  useEffect(() => {
    if (!loading) {
      regionValue.regions = [];
    }
  }, [regionValue.regionType, userInputs.country]);

  return (
    <div className="test-control-container">
      <div className="test-control">
        {/* <p className="title">Regions:</p> */}
        <div className="multi-select-container">
          {disableFlag ? (
            <TestControlDisplayRow
              data={regionValue.regions}
              setData={setData}
              setDialogOpen={setDialogOpen}
            />
          ) : (
            <div className={classes.root}>
              <MultiSelect
                name="region"
                data={modelRegionSelectionData}
                value={regionValue.regions}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      </div>
      {disableFlag && (
        <ScrollDialog
          data={regionValue.regions}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
        />
      )}
      {/* <IncludeExcludeRegions
        value={regionValue.regionFilterType}
        onChange={onChange}
      /> */}
    </div>
  );
}

export default ModelRegion;
