// Import required libraries
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// import Button from "@material-ui/core/Button";
import { Button } from "../../uiTheme";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

// Import utils
import { makeDefaultResponseJson } from "../../utils/utils";

// Import action creators
import {
  updateAlertInfo,
  updateUserInfo,
  updateSelections,
  updateDialogInfo,
  reloadRunData,
  refreshData,
  reloadConfigs,
} from "../../redux/actions";
import { config } from "../../config/config";

// Import styles
import "./CloneConfiguration.scss";

const dialogUseStyles = makeStyles((theme) => ({
  dialog: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "850px",
      //minHeight: "300px",
      height: "auto",
      maxHeight: "700px",
    },
  },
  contained: {
    color: "white",
    backgroundColor: "#46596A",
    marginRight: 10,
    borderRadius: "5px",
    padding: 15,
    fontSize: 14,
    height: 40,
    minWidth: 100,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    border: "1.5px solid #46596A",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#46596A",
    },
  },
  doneButton: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    borderRadius: "4px",
    padding: 10,
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    border: "1px solid #46596a",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#46596a",
    },
  },
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  cancelBtn: {
    color: "#46596A",
    backgroundColor: "#FFF",
    marginRight: 10,
    borderRadius: "3px",
    padding: 15,
    fontSize: 14,
    height: 40,
    minWidth: 100,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    border: "1.5px solid #46596A",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#46596A",
      color: "#FFF",
    },
  },
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      color: "#46596a",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

function CloneConfiguration(props) {
  let {
    user,
    match,
    name,
    config: configUserInputs,
    handleDialogClose,
    reloadConfigs,
    updateAlertInfo,
  } = props;
  const dialogClass = dialogUseStyles();
  const history = useHistory();
  const [newConfigName, setNewConfigName] = useState("");

  const configNameFixed = configUserInputs.selections.config.brand
    ? `${config.hardCoded.mroiAppId}-${configUserInputs.selections.config.country}-${configUserInputs.selections.config.brand}-`
    : `${config.hardCoded.mroiAppId}-${configUserInputs.selections.config.country}-`;

  const handleCloneConfig = () => {
    handleDialogClose();
    if (newConfigName) {
      updateAlertInfo({
        open: true,
        message: config.messages.RequestingToCloneConfig,
        severity: "info",
      });
      let configUserInputsData = configUserInputs.selections;
      const updateCheckDE = configUserInputsData.models.every((e) =>
        e.config.hasOwnProperty("run_model")
      )
        ? configUserInputsData.models.every((e) => e.config.run_model === true)
        : true;

      if (updateCheckDE) {
        let models = configUserInputsData.models.map((e) => {
          if (
            e.id === config.hardCoded.salesUpliftId &&
            !e?.config?.algorithm
          ) {
            return { ...e, config: { ...e.config, algorithm: "default" } };
          } else {
            return e;
          }
        });
        configUserInputsData = { ...configUserInputsData, models: [...models] };
      }

      let body = {
        app_id: configUserInputs.selections.config.app_id,
        app_name: configUserInputs.selections.config.app_name,
        metadata: {
          isStarred: false,
          tags: configUserInputs?.tags,
          run_type: configUserInputs.run_type,
        },
        name: `${configNameFixed}${newConfigName.trim()}`,
        selections: configUserInputsData,
        operation: "clone",
        old_config_id: configUserInputs.id,
      };
      let url = config.api.configUrl;
      let status;
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateAlertInfo({
              open: true,
              message: match.params.configName
                ? config.messages.configSuccessUpdate
                : config.messages.configSuccessSave,
              severity: "success",
            });
            reloadConfigs();
            history.push(
              match.params.tab === "addnewconfig"
                ? `/home/myconfigurations/`
                : `/home/${match.params.tab}/`
            );
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
          }
        });
    }
  };

  return (
    <>
      <div className="clone-dialog-container">
        <p>
          Clone{" "}
          <span className="dialog-config-name">{configUserInputs.name}</span>{" "}
          Configuration
        </p>
        <div className="content">
          <p className="config-name" title={configNameFixed}>
            {configNameFixed}
          </p>
          <div className="config-name-input-container">
            <input
              type="text"
              id="clone-config"
              name="clone-config"
              value={newConfigName}
              className="input-label"
              onChange={(e) => {
                setNewConfigName(e.target.value);
              }}
              placeholder="Please enter new configuration name"
            />
            <i
              className={`material-icons-outlined cancel-icon ${
                newConfigName ? "visible" : ""
              }`}
              onClick={() => {
                setNewConfigName("");
              }}
            >
              clear
            </i>
          </div>
        </div>
        <div className="popup-btn">
          <Button
            variant="outlined"
            // className={dialogClass.outlined}
            onClick={handleDialogClose}
            style={{
              height: "23px",
              padding: "10px",
              fontSize: "11px",
              minWidth: "15px",
              boxSizing: "border-box",
              marginRight: "4px",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            // className={dialogClass.doneButton}
            onClick={() => {
              handleCloneConfig();
            }}
            style={{
              height: "23px",
              padding: "10px",
              fontSize: "11px",
              minWidth: "15px",
              boxSizing: "border-box",
              marginRight: "4px",
            }}
          >
            Done
          </Button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  saved_configurations: state.data.saved_configurations,
});

const mapDispatchToProps = {
  updateAlertInfo,
  updateUserInfo,
  updateSelections,
  updateDialogInfo,
  reloadRunData,
  refreshData,
  reloadConfigs,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloneConfiguration);
