// Import required libraries
import { connect } from "react-redux";
//Import styles
import "./PowerBiReport.scss";

import { makeStyles } from "@material-ui/core";

import Comment from "./Comment";
import { toggleSidenav } from "../../redux/actions";
import { config } from "../../config/config";

const useStyle = makeStyles(() => ({
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
      maxWidth: "250px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      color: "#46596a",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

function PowerBiReport(props) {
  let {
    user,
    expanded,
    setExpanded,
    comment,
    setComment,
    rowElem,
    data,
    toggleSidenav,
    initialCommentState,
    fetchData,
  } = props;

  const classes = useStyle();
  const activeModel = data.models.find(
    (model) => model.id === rowElem.model_id
  );

  let iframeUrl = `${activeModel.powerBiReportForModel.iframeUrlForRun}&filter=${activeModel.powerBiReportForModel.filterNameAndCond} '${rowElem.run_id}'`;

  return (
    <>
      <div className="power-bi-container">
        <div
          className={`${
            expanded
              ? user.screen.isSidenavBarExpanded
                ? "zoom-icon-full-div-for-sidenav-expanded"
                : "zoom-icon-full-div"
              : "zoom-icon-div"
          }`}
        >
          {expanded ? (
            <p className="zoom-icon-full-text">
              Report For {rowElem.country}-{rowElem.brand}-
              {rowElem.pilot_start_date} || {rowElem.run_id}
            </p>
          ) : (
            <></>
          )}
          <span style={{ display: "flex", alignItems: "center" }}>
            <span
              class="material-icons-outlined zoom-icon-full-screen"
              style={{ marginRight: "20px" }}
              onClick={() => {
                setComment((pre) => ({ ...pre, open: !pre.open }));
              }}
            >
              chat
            </span>
            <i
              className={`material-icons-outlined ${
                expanded ? "zoom-icon-full-screen" : "zoom-icon"
              }`}
              onClick={() => {
                const url = config.api.validateRun;
                fetchData(url);
                if (!user.screen.isSidenavBarExpanded) {
                  toggleSidenav();
                }
                setExpanded(!expanded);
                setComment((pre) => ({
                  ...initialCommentState,
                }));
              }}
            >
              {expanded ? "close" : ""}
            </i>
          </span>
        </div>
        <div
          className={`${
            expanded
              ? user.screen.isSidenavBarExpanded
                ? "power-bi-container-expanded-for-sideNav-expanded"
                : "power-bi-container-expanded"
              : "power-bi-container-1"
          }`}
          style={{
            display: "flex",
            zIndex: 2,
            height: "calc(100vh - 105px)",
            background: "#f9f9f9",
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src={iframeUrl}
            frameBorder="0"
            allowFullScreen={true}
            title={"I&A"}
          ></iframe>
          {comment.open && <Comment {...props} classes={classes} />}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.data,
  appAccessDetails: state?.data?.feature_list,
});
const mapDispatchToProps = {
  toggleSidenav,
};
export default connect(mapStateToProps, mapDispatchToProps)(PowerBiReport);
