import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Badge } from "@mui/material";
import { CssTextField } from "../../../../../styles/styles";
import optimalRun1 from "../../../../../assets/images/optimalRun1.svg";
import { config } from "../../../../config/config";
import {
  updateAlertInfo,
  updateSelections,
  updateValidationState,
} from "../../../../redux/actions";
import { connect } from "react-redux";
import Loader from "../../../../components/Loader/Loader";

function RequestValidation(props) {
  const {
    selectedModel,
    classes,
    handleModalDialogOpen,
    handleModalDialogClose,
    configUserInputs,
    user,
    setDatasetsLoading,
    updateAlertInfo,
    updateSelections,
    updateValidationState,
    nested_pilot_start_date,
  } = props;
  const [comment, setComment] = useState("");
  const commentValue = useRef(comment);

  useEffect(() => {
    commentValue.current = comment;
  }, [comment]);

  const handleChange = (event) => {
    setComment(event.target.value);
  };
  const handleSubmit = () => {
    // submitValidationRequest();
    let pilot_start_date;
    if (selectedModel.id === config.hardCoded.autoMlId) {
      pilot_start_date = selectedModel.config.pilot_start_date;
      if (selectedModel.config.run_type === "nested") {
        pilot_start_date = nested_pilot_start_date;
      }
    } else {
      if (selectedModel.id === config.hardCoded.salesUpliftId) {
        pilot_start_date =
          selectedModel.config.region_granularity.test_dark_regions.test["0"]
            .test_group_parameters[0].pilot_start;
      }
    }
    if (commentValue.current && pilot_start_date && configUserInputs.brand) {
      const body = {
        country: configUserInputs.country,
        brand: configUserInputs.brand,
        pilot_start_date: pilot_start_date,
        preferred_user_name: user.preferred_username,
        email: user.email,
        run_id: user.selections.activeRunPayload.id,
        comment: `${commentValue.current}`,
        model: selectedModel.id,
      };
      const url = config.api.validateRun;
      let status;
      updateAlertInfo({
        open: true,
        message: "Validating the run...",
        severity: "success",
      });
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            handleModalDialogClose();
            updateAlertInfo({
              open: true,
              message: json.status_message,
              severity: "success",
            });
            updateValidationState({
              selectedModel: selectedModel,
            });
          } else {
            handleModalDialogClose();
            updateAlertInfo({
              open: true,
              message: json.status_message,
              severity: "error",
            });
          }
        });
    } else if (
      commentValue.current &&
      (!pilot_start_date || !configUserInputs.brand)
    ) {
      updateAlertInfo({
        open: true,
        message: config.messages.requestValidationError,
        severity: "error",
      });
    } else {
      updateAlertInfo({
        open: true,
        message: "Please enter a comment",
        severity: "error",
      });
    }
  };
  const sendValidationRequest = () => {
    handleModalDialogOpen(
      <div className="optimal-popup-container" statelessTemplates={[]}>
        <div className="optimal-popup-text">
          Add a comment for the I&A Team
          <br />
        </div>
        <CssTextField
          // variant="standard"
          // size="normal"
          placeholder="Add a comment..."
          className={classes.typographyStyle}
          style={{ height: "150px", overflow: "auto" }}
          defaultValue={comment}
          multiline
          onChange={handleChange}
          spellCheck={false}
        />
        <div className="button-container">
          <button
            onClick={() => {
              setComment("");
              handleModalDialogClose();
            }}
            className="primary-button"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="secondary-button"
            // style={{
            //   backgroundColor: commentValue.current
            //     ? "#46596a"
            //     : "rgba(0,0,0,0.5)",
            //   cursor: commentValue.current ? "pointer" : "auto",
            // }}
            // disabled={!commentValue.current}
          >
            Submit
          </button>
        </div>
      </div>
    );
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Badge
        // variant="dot"
        invisible={!selectedModel?.validate_state}
        className={classes.bellIconStyle}
        style={{
          pointerEvents: selectedModel.validate_state ? "none" : "auto",
        }}
        badgeContent={
          selectedModel?.validate_state === "Approved" ? (
            <span class="material-icons-outlined optimal-run-success">
              check_circle
            </span>
          ) : selectedModel?.validate_state === "Failed" ? (
            <span className="material-icons-outlined optimal-run-failed">
              cancel
            </span>
          ) : (
            <span class="material-icons-outlined optimal-run-pending">
              pending
            </span>
          )
        }
      >
        <Button
          disableRipple
          variant="outlined"
          disabled={selectedModel.validate_state}
          onClick={() => {
            sendValidationRequest();
          }}
          className={classes.filledButton}
          style={{
            pointerEvents: selectedModel.validate_state ? "none" : "auto",
          }}
        >
          <img src={optimalRun1} alt="validate" width={25} height={25} />
        </Button>
      </Badge>
      <p
        className="models-selection-title"
        onClick={() => {
          sendValidationRequest();
        }}
        style={{
          pointerEvents: selectedModel.validate_state ? "none" : "auto",
        }}
      >
        {selectedModel?.validate_state === "Approved"
          ? "Approved Run"
          : selectedModel?.validate_state === "Failed"
          ? "Failed Run"
          : selectedModel?.validate_state === "New"
          ? "Run under review"
          : "Validate Run"}
      </p>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateSelections,
  updateAlertInfo,
  updateValidationState,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestValidation);
