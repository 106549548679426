//Import required libraies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";

//Import Custom Component

// Import custom hooks

// Import action creators

//Import utils
import { config } from "../../../../config/config";

// Import styles
import "./SingleTreeSelect.scss";

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    //width: "190px",
    minWidth: "300px",
    maxWidth: "300px",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a !important",
    backgroundColor: "white",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  menuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "0px",
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "0px 12px",
    color: "#46596a",
    width: "auto",
  },
  listItem: {
    "& span.MuiTypography-body1": {
      fontSize: "12px",
      color: "#46596a",
      fontFamily: "Hind Siliguri",
      paddingRight: "5px",
    },
  },
  popover: {
    pointerEvents: "none",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    width: "800px",
    margin: "10px",
  },
  typographyHeader: {
    fontSize: 12,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 5,
    backgroundColor: "#ebeff1",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "#46596a !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function TestControlDisplayRow(props) {
  let { id, name, data, type, index } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return <p>text</p>;
}

function TreeSelect(props) {
  let { data, userInputs, setUserInputs, disableFlag } = props;
  let key = data[0].labelCat;
  let type = data[0].type;
  let visible = data[0].visible;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let optionsData = [];
  let titleMapping = {
    country: "country",
    brand: "brand",
    segment: "segment",
    sub_brand: "sub brand",
    sub_segment: "sub segment",
  };

  if (type === "single") {
    optionsData = data.filter((obj) => obj["name"] === userInputs[key]);
  } else {
    data?.forEach((obj) => {
      userInputs[key]?.forEach((item) => {
        if (item === obj["name"]) {
          optionsData.push(obj);
        }
      });
    });
    if (optionsData.length !== userInputs[key]?.length) {
      optionsData = [];
    }
  }

  if (data.length > 0 && optionsData.length === 0 && type === "single") {
    let defaultVal = data[0]["name"];
    setUserInputs(key, defaultVal);
  } else if (data.length > 0 && optionsData.length === 0 && type === "multi") {
    let defaultVal = data[0]["name"];
    setUserInputs(key, [defaultVal]);
  }

  let childrenFlag = optionsData.length
    ? optionsData[0].children
      ? true
      : false
    : false;

  const handleChange = (e) => {
    if (
      e.target.value[e.target.value.length - 1] ===
      config.hardCoded.allSelection
    ) {
      let segArray = [config.hardCoded.allSelection];
      setUserInputs(key, segArray);
    } else {
      let segArray = e.target.value.filter(
        (item) => item !== config.hardCoded.allSelection
      );
      setUserInputs(key, segArray);
    }
  };

  return (
    <>
      <div className="input-item">
        {type === "multi" && visible && (
          <>
            <label>{titleMapping[key]}</label>
            {disableFlag ? (
              <>
                <Select
                  multiple
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  className={classes.multiSelect}
                  value={userInputs[key]}
                  renderValue={(selected) => {
                    return selected.join(", ");
                  }}
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  disabled
                  input={<Input />}
                  MenuProps={MenuProps}
                ></Select>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div className="popover-content">
                    {userInputs[key].map((value) => (
                      <p key={value}>{value}</p>
                    ))}
                  </div>
                </Popover>
              </>
            ) : (
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={userInputs[key]}
                onChange={(e) => {
                  handleChange(e);
                }}
                className={classes.multiSelect}
                renderValue={(selected) => {
                  return selected.join(", ");
                }}
                input={<Input />}
                MenuProps={MenuProps}
              >
                {data.map((item) => (
                  <MenuItem
                    key={item.name}
                    value={item.name}
                    className={classes.menuItem}
                  >
                    <StyledCheckbox
                      checked={userInputs[key].indexOf(item.name) > -1}
                    />
                    <ListItemText
                      primary={item.name}
                      className={classes.listItem}
                    />
                  </MenuItem>
                ))}
              </Select>
            )}
          </>
        )}
        {type === "single" && visible && (
          <div
            className={`single-dropdown ${
              disableFlag ? "disabled-pointer" : ""
            }`}
          >
            <label>{titleMapping[key]}</label>
            <Select
              value={userInputs[key] ? userInputs[key] : ""}
              onChange={(e) => {
                setUserInputs(key, e.target.value);
              }}
              className={classes.multiSelect}
              MenuProps={MenuProps}
            >
              {data.map((obj) => (
                <MenuItem
                  value={obj.name}
                  key={obj.name}
                  className={classes.singleMenuItem}
                >
                  {obj.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
      </div>
      {childrenFlag && (
        <TreeSelect
          data={optionsData[0].children}
          userInputs={userInputs}
          setUserInputs={setUserInputs}
          disableFlag={disableFlag}
        />
      )}
    </>
  );
}

function MultiTreeSelect(props) {
  let { user, data, userInputs, setUserInputs, disableFlag } = props;

  return (
    <TreeSelect
      data={data}
      userInputs={userInputs}
      setUserInputs={setUserInputs}
      disableFlag={disableFlag}
    />
  );
}

MultiTreeSelect.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MultiTreeSelect);
