// Import required libraries
import React, { useEffect, useState } from "react";
// import Button from "@material-ui/core/Button";
import { Button, Loader } from "../../../../uiTheme";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import { v4 } from "uuid";
import orderBy from "lodash/orderBy";

//Import Custom Component
import MultiSelect from "./MultiSelect";
import TestControlMultiSetPopup from "./TestControlMultiSetPopup";
import TestControlCampaign from "./TestControlCampaign";

// Import data
import allCampaignObjectData from "../../../../../assets/data/allCampaignObjectData.json";

//Import styles
import "./TestControlSet.scss";
import { config } from "../../../../config/config";
import DatasetCampaign from "./DatasetCampaign";
import { filterSUMediaDatasets } from "../../../../redux/actions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    color: "#46596a",
    width: 400,
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
    },
  },
  contained: {
    color: "#46596a",
    backgroundColor: "transparent",
    marginRight: 4,
    marginTop: "28px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: "12px",
    height: 23,
    minWidth: 15,
    border: "1px solid #46596a",
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f1f1f1",
      boxShadow: "none",
    },
  },
  outlined: {
    // borderColor: "#46596a",
    marginRight: 4,
    border: "none",
    // fontSize: 14,
    //padding: 10,
    padding: "0px 5px",
    height: "22px",
    minWidth: "auto",
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  chipStyle: {
    marginTop: "20px",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    border: "1px solid #dedede",
    color: "#46596a !important",
    height: "25px",
    marginRight: "5px",
    background: "white",
    "& .MuiChip-deleteIcon": {
      width: "16px",
    },
  },
  popover: {
    pointerEvents: "none",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "150px",
  },
}));

function TestControlSet(props) {
  const {
    regionsData,
    setRegionsData,
    testRegions,
    setTestRegions,
    controlRegions,
    setControlRegions,
    displayData,
    disableFlag,
    userInputs,
    testControlRegions,
    value,
    testControlSet,
    initialTestRegions,
    initialControlRegions,
    customId,
    updateCheckDE,
    datasetsTypesData,
    activeModel,
    filterSUMediaDatasets,
  } = props;

  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const [error, setError] = useState(null);

  //Add empty test row
  const addTestLocalRow = () => {
    if (testRegions[testRegions.length - 1].regions.length > 0) {
      setError(null);
      let newTestData = initialTestRegions[0]; //setting with inital values
      setTestRegions([...testRegions, newTestData]);
    } else {
      setError("Please select at least one test set");
    }
  };

  const filterMediaDataset =
    activeModel.id === config.hardCoded.salesUpliftId &&
    datasetsTypesData
      .filter((data) => data.name === "media")[0]
      .data.map((e) => ({ name: e.name, id: e.id }));
  // console.groupCollapsed("Test control CHANGED");
  // console.log("regionsData -> ", regionsData);
  // console.log("testRegionsData -> ", testRegionsData);
  // console.log("controlRegionsData -> ", controlRegionsData);
  // console.log("testRegions -> ", testRegions);
  // console.log("controlRegions -> ", controlRegions);
  // console.groupEnd();

  const classes = useStyles();

  useEffect(() => {
    setLoading(false);
  }, []);

  //update regionsData if testData or controlData changes
  useEffect(() => {
    // if (!loading) {
    let allTestSelectedRegions = testRegions.map(
      (testDataLocalRow) => testDataLocalRow.regions
    );
    let allControlSelectedRegions = controlRegions.map(
      (controlDataLocalRow) => controlDataLocalRow.regions
    );
    let allSelectedRegions = [
      ...allTestSelectedRegions.flat(),
      ...allControlSelectedRegions.flat(),
    ];
    let newRegionsDataLocal = regionsData.filter(
      (region) => !allSelectedRegions.includes(region)
    );
    setRegionsData(newRegionsDataLocal);
    // }
  }, [testRegions, controlRegions]);

  return (
    <div className="test-control-form">
      {/* <p className="popup-title">Test and Region</p> */}
      <div className="multi-test-control-container-row">
        <div className="test-control">
          <p className="text-title">Test Regions:</p>
          {testRegions.map((testDataRow, index) => {
            let data = orderBy([...regionsData, ...testDataRow.regions]);
            let onChange = (key, value) => {
              setRegionsData((preData) => [...preData, ...testDataRow.regions]);
              setTestRegions((preTestRegions) => {
                let newTestDataLocal;
                if (value?.length) {
                  newTestDataLocal = preTestRegions.map((testDataLocalRow) =>
                    testDataLocalRow.id !== testDataRow.id
                      ? testDataLocalRow
                      : {
                          ...testDataLocalRow,
                          [key]: value,
                        }
                  );
                } else if (key === "dataset_id" || key === "target_campaigns") {
                  newTestDataLocal = preTestRegions.map((testDataLocalRow) => {
                    if (testDataLocalRow.id !== testDataRow.id) {
                      return testDataLocalRow;
                    } else {
                      delete testDataLocalRow[key];
                      if (key === "dataset_id") {
                        delete testDataLocalRow["target_campaigns"];
                      }
                      return testDataLocalRow;
                    }
                  });
                } else {
                  newTestDataLocal = preTestRegions.filter(
                    (testDataLocalRow) => testDataLocalRow.id !== testDataRow.id
                  );
                }
                return newTestDataLocal.length
                  ? newTestDataLocal
                  : initialTestRegions;
              });
            };
            let handleDelete = () => {
              if (testRegions.length == 1) {
                setError("Cannot delete last test selection");
              } else if (testRegions.length > 1) {
                setRegionsData((preData) => [
                  ...preData,
                  ...testDataRow.regions,
                ]);
                setTestRegions((preTestRegion) => {
                  let newTestDataLocal = preTestRegion.filter(
                    (testDataLocalRow) => testDataLocalRow.id !== testDataRow.id
                  );
                  return newTestDataLocal;
                });
              }
            };
            return (
              <div
                className={`multi-test-control-container test-control-regions ${customId}`}
                key={testDataRow.id}
              >
                <div className="test-autocomplete-input">
                  <p className="header-text bold">
                    {
                      // (
                      // customId === config.hardCoded.regBudId ||
                      // customId === config.hardCoded.salesUpliftId) &&
                      config.hardCoded.testAndControlCellModels.includes(
                        customId
                      ) && index === 0
                        ? `Test Cell ${index}/ Control Cell:`
                        : `Test Cell ${index}:`
                    }
                  </p>
                  <MultiSelect
                    name={testDataRow.id}
                    data={data}
                    value={testDataRow.regions}
                    onChange={onChange}
                  />
                  {displayData.multiset_popup.show && (
                    <TestControlMultiSetPopup
                      {...props}
                      key={displayData.multiset_popup.id}
                      type={displayData.multiset_popup.type}
                      name={displayData.multiset_popup.name}
                      id={displayData.multiset_popup.id}
                      data={displayData.multiset_popup.data}
                      show={displayData.multiset_popup.show}
                      enable={displayData.multiset_popup.enable}
                      userInputs={userInputs}
                      value={testDataRow.test_group_parameters}
                      onChange={onChange}
                      updateCheckDE={updateCheckDE}
                      style={{ justifyContent: "flex-start" }}
                    />
                  )}
                  {displayData.campaigns.show && (
                    <TestControlCampaign
                      key={displayData.multiset_popup.id}
                      type={displayData.multiset_popup.type}
                      name={displayData.multiset_popup.name}
                      id={displayData.multiset_popup.id}
                      data={displayData.multiset_popup.data}
                      show={displayData.multiset_popup.show}
                      enable={displayData.multiset_popup.enable}
                      userInputs={userInputs}
                      userSelectedCampaigns={testDataRow.campaigns}
                      allCampaigns={[...allCampaignObjectData]}
                      onChange={onChange}
                      updateCheckDE={updateCheckDE}
                    />
                  )}
                  {activeModel.id === config.hardCoded.salesUpliftId &&
                  !userInputs.is_config_manual &&
                  updateCheckDE ? (
                    userInputs?.datasetDetailsData?.status === "loading" ? (
                      <Loader />
                    ) : (
                      filterMediaDataset && (
                        <div style={{ display: "flex" }}>
                          <DatasetCampaign
                            filterMediaDataset={filterMediaDataset}
                            {...props}
                            onChange={onChange}
                            testDataRow={testDataRow}
                          />
                        </div>
                      )
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="test-control-regions-second-row">
                  <div className="delete-button-div">
                    <Button
                      variant="outlined"
                      // className={classes.outlined}
                      onClick={() => {
                        handleDelete();
                      }}
                      style={{
                        border: "none",
                        height: "22px",
                        padding: "0px 5px",
                        fontSize: "12px",
                        minWidth: "auto",
                        fontWeight: "bold",
                        marginRight: "4px",
                        textTransform: "capitalize",
                      }}
                    >
                      Delete
                      <i
                        className="material-icons-outlined delete_icon"
                        style={{ fontSize: "12px" }}
                      >
                        delete_forever
                      </i>
                    </Button>
                  </div>
                  {/* )} */}
                  {/* {index === testRegions.length - 1 && (
                    <div className="popup-btn-test-control">
                      <Button
                        variant="contained"
                        className={classes.contained}
                        onClick={() => {
                          addTestLocalRow();
                        }}
                      >
                        Add Set
                      </Button>
                    </div>
                  )} */}
                </div>
              </div>
            );
          })}
        </div>
        <div className="popup-btn-test-control">
          <Button
            variant="outlined"
            // className={classes.outlined}
            onClick={() => {
              addTestLocalRow();
            }}
            style={{ fontWeight: "bold", border: "none", fontSize: "12px" }}
          >
            Add Test Cell
          </Button>
        </div>
        {!config.hardCoded.autoMlIds.includes(customId) && (
          <div className="test-control">
            <p className="text-title">
              {customId === config.hardCoded.regBudId ||
              customId === config.hardCoded.salesUpliftId
                ? "Dark Regions:"
                : " Control Regions:"}
            </p>
            {controlRegions.map((controlDataRow, index) => {
              let data = orderBy([...regionsData, ...controlDataRow.regions]);
              let onChange = (key, value) => {
                let newControlDataLocal;
                if (value.length) {
                  newControlDataLocal = controlRegions.map(
                    (controlDataLocalRow) =>
                      controlDataLocalRow.id !== controlDataRow.id
                        ? controlDataLocalRow
                        : {
                            ...controlDataLocalRow,
                            [key]: value,
                          }
                  );
                } else {
                  newControlDataLocal = controlRegions.filter(
                    (controlDataLocalRow) =>
                      controlDataLocalRow.id !== controlDataRow.id
                  );
                }
                setRegionsData([...regionsData, ...controlDataRow.regions]);
                newControlDataLocal.length
                  ? setControlRegions(newControlDataLocal)
                  : setControlRegions(initialControlRegions);
              };
              return (
                <div
                  className="multi-test-control-container test-control-regions"
                  key={controlDataRow.id}
                >
                  <div className="control-autocomplete-input">
                    <div style={{ display: "flex" }}>
                      <p className="header-text bold">
                        {customId === config.hardCoded.regBudId ||
                        customId === config.hardCoded.salesUpliftId
                          ? "Dark Cell:"
                          : "Control Cell:"}
                      </p>
                      {(customId === config.hardCoded.regBudId ||
                        customId === config.hardCoded.salesUpliftId) && (
                        <>
                          <i
                            className="material-icons-outlined info-icon"
                            aria-owns={open ? "mouse-over-popover" : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                            style={{ pointerEvents: "auto" }}
                          >
                            info
                          </i>
                          <Popover
                            id="mouse-over-popover"
                            className={classes.popover}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <p className={classes.typography}>
                              {config.messages.darkCellMessage}
                            </p>
                          </Popover>
                        </>
                      )}
                    </div>
                    <MultiSelect
                      name={controlDataRow.id}
                      data={data}
                      value={controlDataRow.regions}
                      onChange={onChange}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  //allData: state.data,
  changeFlag: state.changeFlag,
  configUserInputs: state.configUserInputs,
  models: state.configUserInputs.models,
});

const mapDispatchToProps = {
  filterSUMediaDatasets,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestControlSet);
