// Import required libraries
import React, { useEffect } from "react";
import useState from "react-usestateref";
import { connect } from "react-redux";
import {
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import TimeAgo from "react-timeago";

// Import data & utils
import useTable from "../../utils/useTable";

import { config as appConfig } from "../../config/config";

// Import action creators
import {
  toggleSidenav,
  updateAlertInfo,
  updateMultiSelections,
} from "../../redux/actions";
import moment from "moment";
import { Paper } from "@mui/material";
import { formatDate, timeDifference } from "../../utils/utils";

const InnerStyledTableCell = withStyles((theme) => ({
  body: {
    pointerEvents: "None",
    color: "#46596a",
    backgroundColor: "white !important",
    fontFamily: "Hind Siliguri",
    borderBottom: "0px",
  },
}))(TableCell);
const StyledTableCell = withStyles((theme) => ({
  body: {
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    borderBottom: "0px",
    padding: "12px !important",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  // for thin scroll bar
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.1em",
    },
    "*::-webkit-scrollbar-track": {},
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  listIcon: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    maxWidth: "500px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
  },
  tableRow: {
    backgroundColor: "white !important",
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    "&:hover": {
      backgroundColor: "#4eafb342 !important",
    },
  },
  starLoading: {
    marginRight: "5px",
    cursor: "pointer",
  },
  innerTableRow: {
    backgroundColor: "#46596a0d !important",
    padding: "9px !important",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    "&:hover": {
      backgroundColor: "#4eafb342 !important",
    },
  },
  innerName: {
    maxWidth: "500px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px !important",
    lineHeight: "23px",
    borderTopLeftRadius: "6px",
    borderBottomLeftRadius: "6px",
  },
}));

function RunAnalysisTable(props) {
  let {
    records,
    user,
    updateMultiSelections,
    nlpReport,
    updateAlertInfo,
    businessDashboard,
    setExpanded,
    setRowElem,
    toggleSidenav,
    setComment,
    initialCommentState,
  } = props;
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [dashboardRecords, setDashboardRecords] = useState(records);
  let headCells = [
    {
      width: "5%",
    },
    {
      id: "country",
      label: "Country-Brand-Pilot Start Date",
      width: "25%",
      // align: "center",
      disableSorting: "disable",
    },
    // {
    //   id: "brand",
    //   label: "Brand",
    //   width: "15%",
    //   align: "center",
    //   disableSorting: "disable",
    // },
    // {
    //   id: "pilot_start_date",
    //   label: "Pilot Start Date",
    //   width: "15%",
    //   align: "center",
    //   disableSorting: "disable",
    // },
    {
      id: "requested_by",
      label: "Requested By",
      width: "12.5%",
      align: "center",
      disableSorting: "disable",
    },
    // timestamp
    {
      id: "requested_on",
      label: "Requested On",
      width: "12.5%",
      align: "center",
      disableSorting: "disable",
    },
    {
      id: "modified_by",
      label: "Modified By",
      width: "12.5%",
      align: "center",
      disableSorting: "disable",
    },
    {
      id: "modified_on",
      label: "Modified On",
      width: "12.5%",
      align: "center",
      disableSorting: "disable",
    },
    {
      id: "status",
      label: "Status",
      width: "10%",
      align: "center",
      disableSorting: "disable",
    },
    {
      id: "url",
      label: "Links",
      width: "10%",
      disableSorting: "disable",
      align: "center",
    },
  ];
  const classes = useStyles();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const downloadRun = (url, body) => {
    let status;
    updateAlertInfo({
      open: true,
      message: `Downloading the output file`,
      severity: "success",
    });
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          setDownloadLoading(false);
          let a = document.createElement("a");
          a.href = json.data.location;
          a.click();
          updateAlertInfo({
            open: false,
            message: ``,
            severity: "success",
          });
        } else {
          setDownloadLoading(false);
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };
  const validationDashboardCheck = true;
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(
      dashboardRecords,
      headCells,
      filterFn,
      user,
      updateMultiSelections,
      validationDashboardCheck
    );
  const updateDashboardExpandInfo = (id) => {
    const newRecords = dashboardRecords.map((item) =>
      item.brand + item.country + item.pilot_start_date === id
        ? { ...item, isExpanded: !item.isExpanded }
        : item
    );
    setDashboardRecords(newRecords);
  };
  const ExpandableTableRow = ({
    children,
    expandComponent,
    id,
    isExpanded,
    ...otherProps
  }) => {
    return (
      <>
        <TableRow {...otherProps}>
          <StyledTableCell padding="checkbox">
            <IconButton
              onClick={() => {
                updateDashboardExpandInfo(id);
              }}
            >
              {isExpanded ? (
                <span class="material-icons-outlined">keyboard_arrow_up</span>
              ) : (
                <span class="material-icons-outlined">keyboard_arrow_down</span>
              )}
            </IconButton>
          </StyledTableCell>
          {children}
        </TableRow>
        {isExpanded && <>{expandComponent}</>}
      </>
    );
  };
  const innerRow = (runs, config) => {
    return (
      <>
        {runs.map((item, i) => (
          // <>hello</>
          <TableRow
            key={i}
            className={classes.innerTableRow}
            onClick={() => {
              if (user.screen.isSidenavBarExpanded) {
                toggleSidenav();
              }
              setRowElem({
                ...config,
                model_id: item.model_id,
                validate_state: item.validate_state,
                run_id: item.run_id,
              });
              setExpanded((pre) => !pre);
              setComment((pre) => ({ ...initialCommentState, open: true }));
            }}
          >
            <InnerStyledTableCell padding="checkbox"></InnerStyledTableCell>
            <StyledTableCell
              className={classes.innerName}
              onClick={() => {
                // updateSelections("activeRunId", item.id);
                // let activeRun = makeRunNameFromRunNew(item);
                // addViewRunsData(item.id);
                // history.push(`/home/viewruns/${activeRun}`);
              }}
            >
              {item.dashboard_name}
            </StyledTableCell>
            <StyledTableCell
              onClick={() => {
                // updateSelections("activeRunId", item.id);
                // let activeRun = makeRunNameFromRunNew(item);
                // addViewRunsData(item.id);
                // history.push(`/home/viewruns/${activeRun}`);
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {item.requested_by}
              </div>
            </StyledTableCell>

            <StyledTableCell
              onClick={() => {
                // updateSelections("activeRunId", item.id);
                // let activeRun = makeRunNameFromRunNew(item);
                // addViewRunsData(item.id);
                // history.push(`/home/viewruns/${activeRun}`);
              }}
            >
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* {
                timeDifference(item.requested_on) < 7 ? (
                  <TimeAgo date={new Date(item.requested_on)} />
                ) : (
                  formatDate(item.requested_on, "MMM-DD-YYYY")
                )
                } */}
                {formatDate(item.requested_on, "MMM-DD-YYYY")}
              </div>
            </StyledTableCell>

            <StyledTableCell
            // onClick={() => {
            //   updateSelections("activeRunId", item.id);
            //   let activeRun = makeRunNameFromRunNew(item);
            //   addViewRunsData(item.id);
            //   history.push(`/home/viewruns/${activeRun}`);
            // }}
            >
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {item.modified_by ? item.modified_by : "-"}
              </div>
            </StyledTableCell>
            <StyledTableCell
              onClick={() => {
                // updateSelections("activeRunId", item.id);
                // let activeRun = makeRunNameFromRunNew(item);
                // addViewRunsData(item.id);
                // history.push(`/home/viewruns/${activeRun}`);
              }}
            >
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {item.modified_on
                  ? // timeDifference(item.modified_on) < 7 ? (
                    //   <TimeAgo date={new Date(item.modified_on)} />
                    // ) : (
                    formatDate(item.modified_on, "MMM-DD-YYYY")
                  : // )
                    // formatDate(item.modified_on, "MMM-DD-YYYY")
                    "-"}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {item.validate_state}
              </div>
            </StyledTableCell>
            <StyledTableCell align="center">
              <i
                className="material-icons-outlined zoom-icon"
                style={{
                  fontSize: "16px",
                  color: `${
                    item.validate_state === "Failed"
                      ? "#fd6320"
                      : item.validate_state === "Approved"
                      ? "#179aa7"
                      : "#f5c405"
                  }`,
                }}
              >
                bar_chart
              </i>
              <i
                className="material-icons-outlined"
                style={{
                  fontSize: "18px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!downloadLoading) {
                    downloadRun(appConfig.api.downloadRunUrl, {
                      run_id: item.run_id,
                      type: appConfig.hardCoded.downloadOutputType,
                      app_id: appConfig.hardCoded.mroiAppId,
                    });
                  }
                }}
              >
                file_download
              </i>
            </StyledTableCell>
          </TableRow>
        ))}
      </>
    );
  };
  useEffect(() => {
    setDashboardRecords(records);
  }, [records]);
  return (
    <>
      <Paper
        sx={{
          height: "66vh",
          overflow: "auto",
          border: "none",
          boxShadow: "none",
        }}
      >
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <>
                <ExpandableTableRow
                  className={classes.tableRow}
                  hover
                  key={item.brand + item.country + item.pilot_start_date}
                  id={item.brand + item.country + item.pilot_start_date}
                  isExpanded={!item.isExpanded}
                  expandComponent={innerRow(item.reports, item)}
                >
                  <StyledTableCell
                    className={classes.name}
                    onClick={() => {
                      updateDashboardExpandInfo(
                        item.brand + item.country + item.pilot_start_date
                      );
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="config-name" title={item.name}>
                        {item.country +
                          "-" +
                          item.brand +
                          "-" +
                          item.pilot_start_date}
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      updateDashboardExpandInfo(
                        item.brand + item.country + item.pilot_start_date
                      );
                    }}
                  >
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {item?.run_type?.toLowerCase() === "automated"
                        ? "Automated"
                        : item?.run_type?.toLowerCase() === "manual"
                        ? "Manual"
                        : "DE Only"}
                    </div> */}
                  </StyledTableCell>

                  <StyledTableCell
                    onClick={() => {
                      updateDashboardExpandInfo(
                        item.brand + item.country + item.pilot_start_date
                      );
                    }}
                  >
                    {/* {formatDate(item.created_on)} */}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      updateDashboardExpandInfo(
                        item.brand + item.country + item.pilot_start_date
                      );
                    }}
                  ></StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      updateDashboardExpandInfo(
                        item.brand + item.country + item.pilot_start_date
                      );
                    }}
                  />
                  <StyledTableCell
                    onClick={() => {
                      updateDashboardExpandInfo(
                        item.brand + item.country + item.pilot_start_date
                      );
                    }}
                  />
                  <StyledTableCell
                    onClick={() => {
                      updateDashboardExpandInfo(
                        item.brand + item.country + item.pilot_start_date
                      );
                    }}
                  />
                </ExpandableTableRow>
              </>
            ))}
          </TableBody>
        </TblContainer>
        {/* <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow
                key={item.country + index}
                className={classes.tableRow}
                hover
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (user.screen.isSidenavBarExpanded) {
                    toggleSidenav();
                  }
                  setRowElem(item);
                  setExpanded((pre) => !pre);
                  setComment((pre) => ({ ...initialCommentState, open: true }));
                }}
              >
                <StyledTableCell
                // align="center"
                >
                  {item.country}
                </StyledTableCell>
                <StyledTableCell align="center">{item.brand}</StyledTableCell>
                {!businessDashboard && (
                  <StyledTableCell align="center">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {item.pilot_start_date}
                    </div>
                  </StyledTableCell>
                )}
                <StyledTableCell align="center">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {item.preferred_user_name}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {formatDate(item.timestamp)}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {item.validate_state}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <i
                    className="material-icons-outlined zoom-icon"
                    style={{
                      fontSize: "16px",
                      color: `${
                        item.validate_state === "Failed"
                          ? "#fd6320"
                          : item.validate_state === "Approved"
                          ? "#179aa7"
                          : "#f5c405"
                      }`,
                    }}
                  >
                    bar_chart
                  </i>
                  <i
                    className="material-icons-outlined"
                    style={{
                      fontSize: "18px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!downloadLoading) {
                        downloadRun(config.api.downloadRunUrl, {
                          run_id: item.run_id,
                          type: config.hardCoded.downloadOutputType,
                          app_id: config.hardCoded.mroiAppId,
                        });
                      }
                    }}
                  >
                    file_download
                  </i>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer> */}
      </Paper>
      <div style={{ height: "40px" }}>
        {records.length > 10 && <TblPagination />}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = {
  updateMultiSelections,
  updateAlertInfo,
  toggleSidenav,
};

export default connect(mapStateToProps, mapDispatchToProps)(RunAnalysisTable);
