//Import required libraies
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
//Import Custom Component
import Loader from "../../../../components/Loader/Loader";
import InputNumberValidation from "./InputNumberValidation";
import Campaign from "./Campaign";
import MultisetPopup from "./MultisetPopup";
import NestedRunsConfig from "./NestedRunsConfig";
import RadioButtons from "./RadioButtons";
import VolumnField from "./VolumnField";
import CalendarPickerValidation from "./CalendarPickerValidation";
import MultiTreeSelect from "./MultiTreeSelect";
import InputTextValidation from "./InputTextValidation";
import Regions from "./Regions";
import AdStockParameters from "./AdStockParameters";
import AdStockPreview from "./AdStockPreview";
import GroupAllTargets from "./GroupAllTargets";
import SalesFilters from "./SalesFilters";
import BudgetOnly from "./BudgetOnly";
import PreferredSalesDataset from "./PreferredSalesDataset";
import GlobalWeekDateSelection from "./GlobalWeekDateSelection";
import CountryBrandSegmentHierarchy from "./CountryBrandSegmentHierarchy";
import RegionTestControlContainer from "./RegionTestControlContainer";
import CustomCheckbox from "./CustomCheckbox";
import DatasetGranularity from "./DatasetGranularity";
import PilotDates from "./PilotDates";
import AutoMlBudgetPilotDates from "./AutoMlBudgetPilotDates";
import AutoCompleteSearch from "./AutoCompleteSearch";
import UploadFilesRegions from "./UploadFilesRegions";
import ManualVolumnField from "./ManualVolumnField";
import RetailMarginPrice from "./RetailMarginPrice";
import SalesCoverage from "./SalesCoverage";
import Enforced from "./Enforced";
import Algorithm from "./Algorithm";
// Import custom hooks

// Import action creators
import {
  updateConfigUserInputs,
  updateUserInfo,
} from "../../../../redux/actions";

//Import utils
import { config } from "../../../../config/config";
import { addAllNodeAndSort, checkForAccess } from "../../../../utils/utils";

// Import styles
import { StyledCheckbox } from "../../../../../styles/styles";
//Import data
import allCampaignObjectData from "../../../../../assets/data/allCampaignObjectData.json";
import MultiTreeSelectSettings from "../../../../../assets/data/MultiTreeSelectSettings.json";

function LocalParameterItem(props) {
  let {
    id,
    name,
    data,
    ui_element_type,
    enable,
    minValue,
    maxValue,
    defaultValue,
    value,
    onChange,
    userInputs,
    allCampaigns,
    userSelectedCampaigns,
    activeModel,
    show,
    customClassName,
    match,
    setUserInputs,
    updateUserInfo,
    changeFlag,
    disableFlag,
    loading,
    activeApp,
    updateDatasetLocalParameters,
    selectedDataset,
    activeModelId,
    selectedModelDatasets,
    userData,
    updateConfigUserInputs,
    updateCheckDE,
    configUserInputs,
    selectedModel,
    granularityForMergeDataset,
    showTooltip,
    countries,
  } = props;
  let Component;

  const useStyles = makeStyles({
    multiSelect: {
      fontSize: "12px",
      fontFamily: "Hind Siliguri",
      width: "100%",
      height: "30px",
      borderRadius: "5px",
      color: "#46596a",
      backgroundColor: "white",
      boxSizing: "border-box",
      //paddingLeft: "8px",
      display: "flex",
      alignItems: "center",
      //textAlign: "center",
      border: "1px solid #dedede",
      "& .Mui-disabled": {
        backgroundColor: "#f3f3f3",
        borderRadius: "4px",
      },
      "&:before": {
        borderBottom: "0px",
      },
      "&:after": {
        borderBottom: "0px",
      },
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "0px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "6px 6px",
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
      },
      "& .MuiSelect-select.MuiSelect-select": {
        paddingLeft: "8px",
        // height: "25px",
      },
    },
    singleMenuItem: {
      fontSize: "12px",
      fontFamily: "Hind Siliguri",
      padding: "4px 12px",
      color: "#46596a",
      width: "auto",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      "&:hover": {
        backgroundColor: "#4EAFB3",
        color: "white",
      },
    },
    listItem: {
      "& span.MuiTypography-body1": {
        fontSize: "12px",
        color: "#46596a",
        fontFamily: "Hind Siliguri",
        paddingRight: "5px",
      },
    },
  });

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 150,
        width: "auto",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    variant: "menu",
    getContentAnchorEl: null,
  };

  const classes = useStyles();

  switch (ui_element_type) {
    case "inputbox_text":
      //Update configUserInputs if it's a new parameter addition
      // if (value === "NEW_PARAMETER") {
      //   onChange(id, "");
      // }
      Component = <InputTextValidation {...props} />;
      break;
    case "inputbox_number":
      //Update configUserInputs if it's a new parameter addition; breaking because of backend API not returning max value
      // if (value === "NEW_PARAMETER") {
      //   onChange(id, parseFloat(minValue));
      // }
      Component =
        !updateCheckDE &&
        id === "no_of_similar_regions" &&
        activeModel.id === config.hardCoded.mmtId ? null : id ===
            "number_of_cells" && activeModel.id === config.hardCoded.mmtId ? (
          match.path === "/home/viewruns/:runName" ? (
            Array.isArray(configUserInputs.models) &&
            configUserInputs.models.some(
              (model) =>
                model.id === "MMT" &&
                model?.config?.hasOwnProperty("number_of_cells")
            ) ? (
              <InputNumberValidation {...props} />
            ) : null
          ) : (
            <InputNumberValidation {...props} />
          )
        ) : (
          <InputNumberValidation {...props} />
        );
      break;
    case "radio_button":
      //Update configUserInputs if it's a new parameter addition
      // if (value === "NEW_PARAMETER") {
      //   onChange(id, data[0].id);
      // }
      Component = (
        <RadioButtons
          data={data}
          name={name}
          id={id}
          enable={enable}
          show={show}
          value={value}
          onChange={onChange}
          customClassName={customClassName}
        />
      );
      break;
    case "calender_picker":
      Component = <CalendarPickerValidation {...props} />;
      break;
    case "dropdown":
      //Update configUserInputs if it's a new parameter addition
      // if (value === "NEW_PARAMETER") {
      //   onChange(id, data[0].id);
      // }
      Component = (
        <div
          className={`input-item row ${
            customClassName ? customClassName : ""
          } ${show ? "visible" : "hidden"} ${id}`}
        >
          {name && <label>{name}:</label>}
          <div
            className={`single-select-dropdown ${
              id === "manual_kpi" ? "manual_kpi_dropdown" : ""
            }`}
          >
            <Select
              disabled={!enable}
              value={value ? value : ""}
              onChange={(e) => {
                onChange(id, e.target.value);
              }}
              className={classes.multiSelect}
              MenuProps={MenuProps}
            >
              {data &&
                data.map((obj) => (
                  <MenuItem
                    key={obj.id + (obj.regions ? obj.regions : "")}
                    value={obj.id}
                    className={classes.singleMenuItem}
                  >
                    {obj.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
      );
      break;
    case "multiple_select_dropdown":
      Component = (
        <p>
          {ui_element_type}, {name}
        </p>
      );
      break;
    case "multiset_popup":
      Component = updateCheckDE ? (
        <MultisetPopup
          name={name}
          data={data}
          value={value}
          onChange={onChange}
          modelConfigId={id}
          userInputs={userInputs}
          enable={enable}
          show={show}
          customClassName={customClassName}
          disableFlag={disableFlag}
        />
      ) : null;
      break;
    case "tree_single_select":
      Component = (
        <MultiTreeSelect
          data={addAllNodeAndSort({
            data: data,
            settings: MultiTreeSelectSettings[config.hardCoded.mroiAppId],
            orderType: "asc",
          })}
          userInputs={userInputs}
          setUserInputs={setUserInputs}
          disableFlag={disableFlag}
        />
      );
      break;
    case "checkbox":
      Component = <CustomCheckbox {...props} />;
      break;
    case "autocomplete_search": //passing a parameter to autocomplete for single or multiselect?
      Component = <AutoCompleteSearch {...props} multiSelect={true} />;
      break;
    case "custom":
      switch (id) {
        case "country_brand_segment_hierarchy":
          Component = <CountryBrandSegmentHierarchy {...props} />;
          break;
        case "regions":
          Component = userInputs?.is_config_manual ? (
            <UploadFilesRegions
              {...props}
              updateConfigUserInputs={updateConfigUserInputs}
            />
          ) : (
            <Regions {...props} />
          );
          break;
        case "global_week_date_selection":
          Component = <GlobalWeekDateSelection {...props} />;
          break;
        case "volume_field":
          Component = userInputs?.is_config_manual ? (
            <ManualVolumnField {...props} />
          ) : updateCheckDE ? (
            <VolumnField {...props} />
          ) : null;
          break;
        case "preferred_sales_dataset":
          Component = <PreferredSalesDataset {...props} />;
          break;
        case "category":
          Component = disableFlag ? (
            <div
              className={`input-item row ${
                customClassName ? customClassName : ""
              } ${id}`}
            >
              <label>{name}:</label>
              <input
                type="text"
                id="category"
                name="category"
                value={value}
                onChange={(e) => {
                  onChange(id, e.target.value);
                }}
                className="disabled-input"
              />
            </div>
          ) : userInputs.categoryData === -1 ? (
            <Loader />
          ) : userInputs.categoryData.length ? (
            <div
              className={`input-item row ${
                customClassName ? customClassName : ""
              } ${id}`}
            >
              <label>{name}:</label>
              <Select
                id="demo-mutiple-checkbox"
                multiple
                disabled={!enable}
                value={value ? value : []}
                onChange={(e) => {
                  onChange(id, e.target.value);
                }}
                className={classes.multiSelect}
                renderValue={(selected) => {
                  return Array.isArray(selected) ? selected?.join(", ") : "";
                }}
                input={<Input />}
                MenuProps={MenuProps}
              >
                {userInputs.categoryData.map((obj) => (
                  <MenuItem
                    className={classes.menuItem}
                    key={obj.id}
                    value={obj.id}
                  >
                    <StyledCheckbox
                      checked={(value && value.includes(obj.name)) || false}
                    />
                    <ListItemText
                      primary={obj.name}
                      className={classes.listItem}
                    />
                  </MenuItem>
                ))}
              </Select>
            </div>
          ) : (
            <div
              className={`input-item row ${
                customClassName ? customClassName : ""
              } ${id}`}
            >
              <label>{name}:</label>
              <Select
                className={classes.multiSelect}
                MenuProps={MenuProps}
                disabled={true}
                value="No categories"
              >
                <MenuItem value="No categories">
                  {config.messages.noCategories}
                </MenuItem>
                ))
              </Select>
            </div>
          );
          break;
        case "custom_nested_run":
          Component =
            userInputs.models
              .find((model) => model.id === config.hardCoded.autoMlId)
              .config.run_type.toLowerCase() === config.hardCoded.nestedRunId &&
            activeModel.id === config.hardCoded.autoMlId ? (
              <NestedRunsConfig {...props} />
            ) : null;
          break;
        case "custom_budget_run":
          Component =
            userInputs.models
              .find((model) => model.id === config.hardCoded.regBudId)
              .config.rbt_run_type?.toLowerCase() ===
              config.hardCoded.budgetId &&
            activeModel.id === config.hardCoded.regBudId ? (
              <BudgetOnly {...props} />
            ) : null;
          break;
        case "sales_filter":
          Component = <SalesFilters {...props} />;
          break;
        case "campaigns":
          Component =
            allCampaigns === -1 ? (
              <Loader />
            ) : userInputs.models
                .find((model) => model.id === config.hardCoded.autoMlId)
                .config.run_type.toLowerCase() ===
                config.hardCoded.pilotRunId &&
              activeModel.id === config.hardCoded.autoMlId ? (
              <div className="no-data-holder">
                <i className="material-icons-round no-datasets-icon">
                  warning_amber
                </i>
                <p className="no-data">{config.messages.noConfigs}</p>
              </div>
            ) : // <Campaign
            //   // allCampaigns={allCampaigns}
            //   allCampaigns={[...allCampaignObjectData]} // hardcoded
            //   userSelectedCampaigns={userSelectedCampaigns}
            //   onChange={onChange}
            //   modelConfigId={id}
            //   customClassName={customClassName}
            //   userInputs={userInputs}
            // />
            userInputs.models
                .find((model) => model.id === config.hardCoded.autoMlId)
                .config.run_type.toLowerCase() ===
                config.hardCoded.nestedRunId &&
              activeModel.id ===
                config.hardCoded.autoMlId ? null : activeModel.id ===
              config.hardCoded.diffDiffId ? (
              <Campaign
                // allCampaigns={[...allCampaigns, ...allCampaignObjectData]}
                allCampaigns={[...allCampaignObjectData]} // hardcoded
                userSelectedCampaigns={userSelectedCampaigns}
                onChange={onChange}
                modelConfigId={id}
                customClassName={customClassName}
                userInputs={userInputs}
              />
            ) : (
              <Campaign
                // allCampaigns={allCampaigns}
                allCampaigns={[...allCampaignObjectData]} // hardcoded
                userSelectedCampaigns={userSelectedCampaigns}
                onChange={onChange}
                modelConfigId={id}
                customClassName={customClassName}
                userInputs={userInputs}
              />
            );
          break;
        case "pilot_dates":
          Component =
            userInputs.models
              .find((model) => model.id === config.hardCoded.autoMlId)
              .config.run_type.toLowerCase() === config.hardCoded.pilotRunId &&
            activeModel.id === config.hardCoded.autoMlId ? (
              <PilotDates {...props} />
            ) : userInputs.models
                .find((model) => model.id === config.hardCoded.autoMlId)
                .config.run_type.toLowerCase() ===
                config.hardCoded.adstockRunId &&
              activeModel.id === config.hardCoded.autoMlId ? (
              <PilotDates {...props} />
            ) : userInputs.models.find(
                (model) => model.id === config.hardCoded.autoMlBudgetId
              ) && activeModel.id === config.hardCoded.autoMlBudgetId ? (
              <AutoMlBudgetPilotDates {...props} />
            ) : null;

          break;
        case "adstock_preview":
          Component =
            userInputs.models
              .find((model) => model.id === config.hardCoded.autoMlId)
              .config.run_type.toLowerCase() === config.hardCoded.pilotRunId &&
            activeModel.id === config.hardCoded.autoMlId ? (
              <AdStockPreview {...props} />
            ) : null;
          break;
        case "group_all_targets":
          Component =
            userInputs.models
              .find((model) => model.id === config.hardCoded.autoMlId)
              .config.run_type.toLowerCase() === config.hardCoded.pilotRunId &&
            activeModel.id === config.hardCoded.autoMlId ? (
              <GroupAllTargets {...props} />
            ) : userInputs.models
                .find((model) => model.id === config.hardCoded.autoMlId)
                .config.run_type.toLowerCase() ===
                config.hardCoded.adstockRunId &&
              activeModel.id === config.hardCoded.autoMlId ? (
              <GroupAllTargets {...props} />
            ) : null;
          break;
        case "adstock_parameters":
          Component =
            userInputs.models
              .find((model) => model.id === config.hardCoded.autoMlId)
              .config.run_type.toLowerCase() === config.hardCoded.pilotRunId &&
            activeModel.id === config.hardCoded.autoMlId ? (
              <AdStockParameters {...props} />
            ) : userInputs.models
                .find((model) => model.id === config.hardCoded.autoMlId)
                .config.run_type.toLowerCase() ===
                config.hardCoded.adstockRunId &&
              activeModel.id === config.hardCoded.autoMlId ? (
              <AdStockParameters {...props} />
            ) : userInputs.models.find(
                (model) => model.id === config.hardCoded.autoMlBudgetId
              ) && activeModel.id === config.hardCoded.autoMlBudgetId ? (
              <AdStockParameters {...props} />
            ) : null;
          break;
        case "region_granularity":
          Component =
            userInputs.models
              .find((model) => model.id === config.hardCoded.regBudId)
              .config.rbt_run_type.toLowerCase() ===
              config.hardCoded.rbtRunId &&
            activeModel.id === config.hardCoded.regBudId ? (
              <RegionTestControlContainer {...props} />
            ) : userInputs.models.find(
                (model) => model.id === config.hardCoded.salesUpliftId
              ) && activeModel.id === config.hardCoded.salesUpliftId ? (
              <RegionTestControlContainer {...props} />
            ) : userInputs.models.find(
                (model) => model.id === config.hardCoded.diffDiffId
              ) && activeModel.id === config.hardCoded.diffDiffId ? (
              <RegionTestControlContainer {...props} />
            ) : userInputs.models
                .find((model) => model.id === config.hardCoded.autoMlId)
                .config.run_type.toLowerCase() ===
                config.hardCoded.pilotRunId &&
              activeModel.id === config.hardCoded.autoMlId ? (
              <RegionTestControlContainer {...props} />
            ) : userInputs.models
                .find((model) => model.id === config.hardCoded.autoMlId)
                .config.run_type.toLowerCase() ===
                config.hardCoded.adstockRunId &&
              activeModel.id === config.hardCoded.autoMlId ? (
              <RegionTestControlContainer {...props} />
            ) : userInputs.models.find(
                (model) => model.id === config.hardCoded.autoMlBudgetId
              ) && activeModel.id === config.hardCoded.autoMlBudgetId ? (
              <RegionTestControlContainer {...props} />
            ) : null;
          break;
        case "channel_granularity":
          Component =
            userInputs.models
              .find((model) => model.id === config.hardCoded.autoMlId)
              .config.run_type.toLowerCase() === config.hardCoded.pilotRunId &&
            activeModel.id === config.hardCoded.autoMlId ? (
              <DatasetGranularity
                {...props}
                allCampaigns={allCampaigns}
                granularityFlag={true}
              />
            ) : userInputs.models
                .find((model) => model.id === config.hardCoded.autoMlId)
                .config.run_type.toLowerCase() ===
                config.hardCoded.adstockRunId &&
              activeModel.id === config.hardCoded.autoMlId ? (
              <DatasetGranularity
                {...props}
                allCampaigns={allCampaigns}
                granularityFlag={true}
              />
            ) : userInputs.models.find(
                (model) => model.id === config.hardCoded.autoMlBudgetId
              ) && activeModel.id === config.hardCoded.autoMlBudgetId ? (
              <DatasetGranularity
                {...props}
                allCampaigns={allCampaigns}
                granularityFlag={true}
              />
            ) : (
              <DatasetGranularity
                {...props}
                allCampaigns={allCampaigns}
                granularityFlag={false}
              />
            );
          break;
        case "run_type":
          // data Tempering
          let autoMLRunTypeData = data;
          if (userInputs?.is_config_manual) {
            autoMLRunTypeData = data.filter(
              (elem) => elem.id.toLowerCase() !== "adstock"
            );
          }
          if (userInputs?.configType === "add") {
            autoMLRunTypeData?.map((obj) => {
              if (obj.id === "nested") {
                obj.disabled = true;
              } else {
                obj.disabled = false;
              }
            });
          } else {
            autoMLRunTypeData?.map((obj) => {
              obj.disabled = false;
            });
          }
          Component = (
            <RadioButtons
              data={autoMLRunTypeData}
              name={name}
              id={id}
              enable={enable}
              show={show}
              value={value}
              onChange={onChange}
              customClassName={customClassName}
            />
          );
          break;
        case "rbt_run_type":
          // data Tempering
          let rbtRunTypeData = data;
          if (userInputs?.is_config_manual) {
            rbtRunTypeData?.map((obj) => {
              obj.disabled = false;
            });
          } else {
            rbtRunTypeData?.map((obj) => {
              obj.disabled = false;
            });
          }
          Component = (
            <RadioButtons
              data={rbtRunTypeData}
              name={name}
              id={id}
              enable={enable}
              show={show}
              value={value}
              onChange={onChange}
              customClassName={customClassName}
            />
          );
          break;
        case "top_n_competitor":
          return null;
        case "retail_margin_price":
          Component = selectedModel?.config?.run_type !==
            config.hardCoded.nestedRunId &&
            updateCheckDE && <RetailMarginPrice {...props} />;
          break;
        case "sales_coverage":
          Component = selectedModel?.config?.run_type !==
            config.hardCoded.nestedRunId &&
            updateCheckDE && <SalesCoverage {...props} />;
          break;
        case "corr_upper_limit":
          Component = updateCheckDE ? (
            countries?.includes(configUserInputs?.country) &&
            configUserInputs.configType !== "run" ? (
              <InputNumberValidation
                {...props}
                step={0.01}
                onKeyPress={(event) => {
                  if (!/^([0-9]|\.)/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            ) : configUserInputs.configType === "run" && value ? (
              <InputNumberValidation
                {...props}
                step={0.01}
                onKeyPress={(event) => {
                  if (!/^([0-9]|\.)/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            ) : null
          ) : null;

          break;
        case "seasonality":
          Component = updateCheckDE &&
            selectedModel?.config?.run_type !== config.hardCoded.nestedRunId &&
            [
              config.hardCoded.autoMlBudgetId,
              config.hardCoded.autoMlId,
            ].includes(selectedModel.id) && <CustomCheckbox {...props} />;
          break;
        case "cols_to_force_in":
          Component = config.hardCoded.rbtAndSuIds.includes(activeModel.id) &&
            userInputs?.is_config_manual &&
            selectedModel.config.rbt_run_type !== config.hardCoded.budgetId && (
              <Enforced {...props} />
            );
          break;
        case "algorithm":
          Component = updateCheckDE && <Algorithm {...props} />;
          break;
        default:
          Component = (
            <p>
              {ui_element_type}, {name}
            </p>
          );
      }
      break;
    default:
      Component = (
        <p>
          {ui_element_type}, {name}
        </p>
      );
  }

  return Component;
}

LocalParameterItem.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
});

const mapDispatchToProps = { updateConfigUserInputs, updateUserInfo };

export default connect(mapStateToProps, mapDispatchToProps)(LocalParameterItem);
