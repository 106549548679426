// Import required libraries
import React, { useEffect, useState, useRef } from "react";
// import Button from "@material-ui/core/Button";
import { Button } from "../../uiTheme";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import orderBy from "lodash/orderBy";

// Import styles
import "./ShareConfigs.scss";

// Import utils
import { config as appConfig } from "../../config/config";
import {
  makeDefaultResponseJson,
  findNameForShareEmail,
} from "../../utils/utils";
import ShareConfigCardComponent from "./ShareConfigCardComponent";
import Loader from "../../components/Loader/Loader";

// Import action creators
import {
  updateAlertInfo,
  updateUserInfo,
  updateSelections,
  updateDialogInfo,
  reloadRunData,
  refreshData,
  reloadConfigs,
} from "../../redux/actions";

const CssTextField = withStyles({
  root: {
    width: "100%",
    backgroundColor: "#f3f3f3",
    padding: "10px",
    borderRadius: "4px",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
})(TextField);

const StyledAutoComplete = withStyles((theme) => ({
  endAdornment: {
    display: "none",
  },
  tag: {
    background: "#465a69",
    color: "#46596a !important",
    fontFamily: "Hind Siliguri !important",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
}))(Autocomplete);

const dialogUseStyles = makeStyles((theme) => ({
  dialog: {
    fontFamily: "Hind Siliguri",
    fontSize: 14,
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "850px",
      //minHeight: "300px",
      height: "auto",
      maxHeight: "700px",
    },
  },
  contained: {
    color: "white",
    backgroundColor: "#46596A",
    marginRight: 10,
    borderRadius: "5px",
    padding: 15,
    fontSize: 14,
    height: 40,
    minWidth: 100,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    border: "1.5px solid #46596A",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#46596A",
    },
  },
  doneButton: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    borderRadius: "4px",
    padding: 10,
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    border: "1px solid #46596a",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#46596a",
    },
  },
  outlined: {
    borderColor: "#46596a",
    backgroundColor: "transparent",
    color: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  cancelBtn: {
    color: "#46596A",
    backgroundColor: "#FFF",
    marginRight: 10,
    borderRadius: "3px",
    padding: 15,
    fontSize: 14,
    height: 40,
    minWidth: 100,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    border: "1.5px solid #46596A",
    boxSizing: "border-box",
    "&:hover": {
      backgroundColor: "#46596A",
      color: "#FFF",
    },
  },
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      color: "#46596a",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

function ShareConfigModelContent(props) {
  let {
    user,
    match,
    name,
    configName,
    updateAlertInfo,
    updateUserInfo,
    updateSelections,
    updateDialogInfo,
    reloadRunData,
    refreshData,
    reloadConfigs,
    config,
    handleDialogClose,
  } = props;
  const dialogClass = dialogUseStyles();
  const [shareWithCardValue, setShareWithCardValue] = useState([]);
  const [usersData, setUsersData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alreadySharedWithUsers, setAlreadyShareWithUsers] = useState(
    config.shared_with
  );
  const [alreadySharedWithUsersForAPI, setAlreadyShareWithUsersForAPI] =
    React.useState([]);
  const [errorForNoOp, setErrorForNoOp] = useState(false);
  const scrollToBottomRef = useRef(null);
  const autoC = useRef(null);

  const handleDialogCloseFromProps = () => {
    handleDialogClose();
  };

  const handleNo = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const removeUserFromList = (email, name) => {
    let prevArr = [...alreadySharedWithUsers];
    let newArr = prevArr.filter((prevArrEle) => {
      return prevArrEle.preferred_username !== email;
    });
    setAlreadyShareWithUsers(newArr);
    let apiArr = [...alreadySharedWithUsersForAPI];
    let newObj = {};
    newObj.preferred_username = email;
    newObj.name = name;
    newObj.access_list = [];
    newObj.operation = "remove";
    apiArr.push(newObj);
    setAlreadyShareWithUsersForAPI(apiArr);
  };

  const removeUserFromListForUI = (email, name) => {
    let prevArr = [...shareWithCardValue];
    let newArr = prevArr.filter((prevArrEle) => {
      return prevArrEle.preferred_username !== email;
    });
    setShareWithCardValue(newArr);
    setUsersData(
      orderBy(
        [...usersData, { name: name, preferred_username: email }],
        ["name"],
        ["asc"]
      )
    );
  };

  const updatedUsersData = orderBy(
    usersData
      ?.filter((userName) => {
        return alreadySharedWithUsers.every((sharedWithName) => {
          return (
            sharedWithName.preferred_username !== userName.preferred_username &&
            sharedWithName.name !== userName.name &&
            userName.preferred_username !== config.owned_by
          );
        });
      })
      ?.filter((usrName) => {
        return usrName.preferred_username !== config.owned_by;
      }),
    ["name"],
    ["asc"]
  );

  useEffect(() => {
    let url = appConfig.api.listUsersUrl;
    let status;
    setIsLoading(true);
    fetch(url)
      .then((response) => {
        console.groupCollapsed("REQUESTING", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          setUsersData(json.data);
          setIsLoading(false);
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "info",
          });
        }
      });
  }, []);

  const getAddArray = () => {
    let arr = [];
    for (let i in shareWithCardValue) {
      let obj = {};
      obj.preferred_username = shareWithCardValue[i].preferred_username;
      obj.name = shareWithCardValue[i].name;
      obj.access_list = ["Edit & Run"];
      obj.operation = "add";
      obj.share_runs = shareWithCardValue[i].share_runs;
      arr.push(obj);
    }
    return arr;
  };

  const setUsersToShareRunsWith = (value, email, userName) => {
    let allUsersChanged = alreadySharedWithUsers.map((el) => {
      if (el.preferred_username === email) {
        return { ...el, share_runs: value };
      } else {
        return el;
      }
    });

    setAlreadyShareWithUsers([...allUsersChanged]);
    let apiArr = [...alreadySharedWithUsersForAPI];
    let newObj = {};
    newObj.preferred_username = email;
    newObj.name = userName;
    newObj.access_list = ["Edit & Run"];
    newObj.operation = "add";
    newObj.share_runs = value;
    apiArr.push(newObj);
    setAlreadyShareWithUsersForAPI(apiArr);
  };

  const setUsersToShareRunsWithNewAddition = (value, email, userName) => {
    let allUsersAdded = shareWithCardValue.map((el) => {
      if (el.preferred_username === email) {
        return { ...el, share_runs: value };
      } else {
        return el;
      }
    });
    setShareWithCardValue([...allUsersAdded]);
  };

  const callApiForSharingData = () => {
    let body = {
      config_id: config.id,
      user_access: [],
      app_id: appConfig.hardCoded.mroiAppId,
    };
    let addArray = getAddArray();
    body.user_access = [...addArray, ...alreadySharedWithUsersForAPI];
    let idsRemoved = alreadySharedWithUsersForAPI?.map(
      (obj) => obj.preferred_username
    );
    let idsAdded = addArray?.map(
      (addArrayObj) => addArrayObj.preferred_username
    );
    const idsToBeRemoved = idsRemoved.filter((value) =>
      idsAdded.includes(value)
    );
    body.user_access = body.user_access?.filter(
      (ob) => !idsToBeRemoved.includes(ob.preferred_username)
    );
    if (!body.user_access || body.user_access.length <= 0) {
      // setErrorForNoOp(true);
      handleDialogCloseFromProps();
      return;
    } else {
      setErrorForNoOp(false);
    }
    updateAlertInfo({
      open: true,
      message: appConfig.messages.RequestingToShareConfig,
      severity: "info",
    });
    let url = appConfig.api.shareConfigUrl;
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("REQUESTING", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          handleDialogCloseFromProps();
          reloadConfigs();
          updateAlertInfo({
            open: true,
            message: appConfig.messages.configSharingSuccess,
            severity: "success",
          });
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  return (
    <>
      <div className="share-dialog-container">
        <p>
          Share <span className="dialog-config-name">{config.name} </span>
          Configuration with Others
        </p>
        {isLoading ? (
          <Loader />
        ) : usersData && usersData.length ? (
          <>
            <div className="requested-by">
              <StyledAutoComplete
                id={`share-select-user`}
                size="small"
                style={{
                  fontFamily: "Hind Siliguri !important",
                  width: "98%",
                }}
                className={dialogClass.typographyStyle}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderTags={() => null}
                options={updatedUsersData}
                ref={autoC}
                getOptionLabel={(option) => option.name}
                value={null}
                onChange={(event, newValue) => {
                  setUsersData(
                    usersData.filter(
                      (obj) =>
                        obj.preferred_username != newValue.preferred_username
                    )
                  );
                  if (
                    newValue &&
                    newValue.name &&
                    newValue.preferred_username
                  ) {
                    newValue.share_runs = false;
                    setShareWithCardValue([...shareWithCardValue, newValue]);
                    setErrorForNoOp(false);
                    setTimeout(() => {
                      scrollToBottomRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                      });
                    }, 500);
                  }
                  const ele = autoC.current.getElementsByClassName(
                    "MuiAutocomplete-clearIndicator"
                  )[0];
                  if (ele) ele.click();
                }}
                noOptionsText={"No Users Found"}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    variant="standard"
                    size="small"
                    placeholder="Type Name Here"
                    className={dialogClass.typographyStyle}
                  />
                )}
              />
            </div>
            <div className="share-cards">
              {config && config.owned_by ? (
                <ShareConfigCardComponent
                  email={config.owned_by}
                  user_name={findNameForShareEmail(usersData, config.owned_by)}
                  action="Owner"
                />
              ) : (
                <div>No Owner Found</div>
              )}
              {alreadySharedWithUsers && alreadySharedWithUsers.length
                ? alreadySharedWithUsers.map((sharedWith) => {
                    return (
                      <ShareConfigCardComponent
                        key={sharedWith.name}
                        email={sharedWith.preferred_username}
                        user_name={sharedWith.name}
                        removeUserFromList={removeUserFromList}
                        setUsersToShareRunsWith={setUsersToShareRunsWith}
                        sharedRunsValue={sharedWith?.share_runs}
                      />
                    );
                  })
                : false}
              {shareWithCardValue && shareWithCardValue.length
                ? shareWithCardValue.map((card) => {
                    return (
                      <ShareConfigCardComponent
                        key={card.name}
                        email={
                          card.preferred_username ? card.preferred_username : ""
                        }
                        user_name={card.name ? card.name : ""}
                        removeUserFromList={removeUserFromListForUI}
                        setUsersToShareRunsWith={
                          setUsersToShareRunsWithNewAddition
                        }
                        sharedRunsValue={card.share_runs}
                      />
                    );
                  })
                : false}
              <div ref={scrollToBottomRef} />
            </div>
            {errorForNoOp ? (
              <div style={{ color: "red" }}>
                {appConfig.messages.noOperationForShareConfig}
              </div>
            ) : (
              false
            )}
            <div className="popup-btn">
              <Button
                variant="outlined"
                // className={dialogClass.outlined}
                onClick={() => {
                  handleDialogCloseFromProps();
                }}
                style={{
                  height: "23px",
                  padding: "10px",
                  fontSize: "11px",
                  minWidth: "15px",
                  boxSizing: "border-box",
                  marginRight: "4px",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                // className={dialogClass.doneButton}
                onClick={() => {
                  callApiForSharingData();
                }}
                style={{
                  height: "23px",
                  padding: "10px",
                  fontSize: "11px",
                  minWidth: "15px",
                  boxSizing: "border-box",
                  marginRight: "4px",
                }}
              >
                Done
              </Button>
            </div>
          </>
        ) : (
          <div>No Data Found</div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  saved_configurations: state.data.saved_configurations,
});

const mapDispatchToProps = {
  updateAlertInfo,
  updateUserInfo,
  updateSelections,
  updateDialogInfo,
  reloadRunData,
  refreshData,
  reloadConfigs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareConfigModelContent);
