//Import required libraies
import React from "react";

//Import utils
import { config } from "../../../../config/config";
import {
  endAfterStart,
  pickMinMaxDate,
  getWeekBasedOnHistoricWeek,
  getDatesFromWeek,
  checkDatesInRange,
  getDaysDiff,
} from "../../../../utils/utils";

//Import styles
import "./PilotDates.scss";
import { DatePicker } from "../../../../uiTheme";

function PilotDates(props) {
  const {
    allCampaigns,
    userSelectedCampaigns,
    onChange,
    modelConfigId,
    userInputs,
    activeModel,
  } = props;

  let activeModelConfig = userInputs.models.find(
    (model) => model.id === activeModel.id
  ).config;

  const pilotDates = {
    pilot_start_date: activeModelConfig.pilot_start_date,
    pilot_end_date: activeModelConfig.pilot_end_date,
  };

  let historicWeekDate = { min: userInputs.startDate, max: userInputs.endDate };

  if (userInputs.is_config_manual) {
    historicWeekDate = { min: "", max: "" };
  } else {
    if (userInputs.global_week_date_selection === config.hardCoded.historicWeek)
      historicWeekDate = getDatesFromWeek(userInputs.historic_week_data);
    if (userInputs.global_week_date_selection === config.hardCoded.dateRange) {
      historicWeekDate = { min: userInputs.startDate, max: userInputs.endDate };
    }
  }

  return (
    <>
      <div className="pilot-dates-container">
        <div className="input-item-label-less">
          <label>Pilot Start Date:</label>
          {/* <input
            type="date"
            min={historicWeekDate.min}
            value={pilotDates.pilot_start_date}
            max={historicWeekDate.max}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => {
              onChange("pilot_start_date", e.target.value);
            }}
          /> */}
          <DatePicker
            min={historicWeekDate.min}
            value={pilotDates.pilot_start_date}
            max={historicWeekDate.max}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => {
              onChange("pilot_start_date", e.target.value);
            }}
          />
        </div>

        <div className="input-item-label-less">
          <label>Pilot End Date:</label>
          {/* <input
            type="date"
            min={historicWeekDate.min}
            value={pilotDates.pilot_end_date}
            max={historicWeekDate.max}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => {
              onChange("pilot_end_date", e.target.value);
            }}
          /> */}
          <DatePicker
            min={historicWeekDate.min}
            value={pilotDates.pilot_end_date}
            max={historicWeekDate.max}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => {
              onChange("pilot_end_date", e.target.value);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default PilotDates;
