//Import required libraies
import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

//Import Custom Component
import LocalParameterItem from "./LocalParameterItem";

// Import custom hooks

// Import action creators

// Import styles
import "./AdStockParameters.scss";

// Import utils & data
import { config } from "../../../../config/config";

function AdStockParameters(props) {
  let {
    id,
    data,
    name,
    enable,
    value,
    onChange,
    customClassName,
    userInputs,
    show,
    activeDataset,
    updateDatasetLocalParameters,
    selectedDataset,
    activeModelId,
    selectedModelDatasets,
  } = props;

  let initialData = {},
    displayObjTV = {
      sl_limit: { minVal: 0, maxVal: 6 },
      su_limit: { minVal: 0, maxVal: 6 },
      limit: { minVal: 0, maxVal: 1 },
    },
    tvFlag = false;

  let adstockDataset = userInputs.models
    .find(
      (model) =>
        config.hardCoded.autoMlIds.includes(model.id) &&
        model.id === activeModelId
    )
    .data_sources.find((dataset) => dataset.id === activeDataset.id);

  if (activeDataset.pipeline_dataset_id === config.hardCoded.tvId) {
    initialData.sl_limit = 2;
    initialData.su_limit = 4;
    initialData.limit = 1;
    initialData.saturation_flag = "false";
    tvFlag = true;
  } else {
    data.forEach((element) => {
      if (element.ui_element_type === "inputbox_number") {
        initialData[element.id] = parseFloat(element["value"]) || 0.0;
      }
      if (element.ui_element_type === "radio_button") {
        initialData[element.id] = "false";
      }
    });
  }

  const [currData, setCurrData] = useState(initialData);
  const [loading, setLoading] = useState(true);

  //check nielsen_noncomp has nielsen_comp_available
  const [adstockFlag, setAdstockFlag] = useState(null);

  useEffect(() => {
    adstockDataset?.enable_adstock
      ? setAdstockFlag(adstockDataset.enable_adstock)
      : setAdstockFlag(false);
  }, [adstockDataset]);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading) {
      if (adstockDataset) {
        setCurrData({
          sl_limit: adstockDataset.sl_limit,
          su_limit: adstockDataset.su_limit,
          saturation_flag: adstockDataset.saturation_flag,
          limit: adstockDataset.limit,
        });
      } else {
        setCurrData({
          sl_limit: "",
          su_limit: "",
          saturation_flag: "",
          limit: "",
        });
      }
    }
  }, [activeDataset]);

  const onChangeCurr = (key, value) => {
    setCurrData({ ...currData, [key]: value });
    onChange(key, value);
  };

  // console.groupCollapsed("STATE CHANGED");
  // console.log("DATA -> ", data);
  // console.log("INITIAL DATA ->", initialData);
  // console.log("CURR DATA -> ", currData);
  // console.log("VALUE -> ", value);
  // console.groupEnd();

  return (
    <div>
      <LocalParameterItem
        value={adstockFlag ? adstockFlag : false}
        name={name}
        ui_element_type="checkbox"
        onChange={onChange}
        id="enable_adstock"
      />
      {adstockFlag && (
        <div
          className={`multiset-popup-data-row ${
            customClassName ? customClassName : ""
          } ${id}`}
        >
          {data.map((item) =>
            config.hardCoded.adstockMappingForDisplay.includes(item.id) ? (
              <div
                className={`input-item ${
                  customClassName ? customClassName : ""
                } ${item.id}`}
                key={item.id}
              >
                <label className="header-text" title={item.name}>
                  {item.name}:
                </label>
                <div className="input-item-box">
                  <input
                    type="number"
                    onWheel={() => document.activeElement.blur()}
                    disabled={!enable}
                    value={
                      adstockDataset
                        ? adstockDataset[item.id]
                          ? isNaN(adstockDataset[item.id])
                            ? ""
                            : adstockDataset[item.id]
                          : isNaN(currData[item.id])
                          ? ""
                          : currData[item.id]
                        : ""
                    }
                    min={tvFlag ? displayObjTV[item.id].minVal : item.minValue}
                    max={tvFlag ? displayObjTV[item.id].maxVal : item.maxValue}
                    onChange={(e) => {
                      onChangeCurr(item.id, parseFloat(e.target.value));
                    }}
                  />
                  <span>
                    <label className="input-info">
                      min:{" "}
                      {tvFlag ? displayObjTV[item.id].minVal : item.minValue} |{" "}
                    </label>
                    <label className="input-info">
                      Max:{" "}
                      {tvFlag ? displayObjTV[item.id].maxVal : item.maxValue}
                    </label>
                  </span>
                </div>
              </div>
            ) : item.id === "limit" ? (
              currData["saturation_flag"] === "true" ? (
                <div
                  className={`input-item ${
                    customClassName ? customClassName : ""
                  } ${item.id}`}
                  key={item.id}
                >
                  <label title={item.name}>{item.name}:</label>
                  <div className="input-item-box">
                    <input
                      type="number"
                      onWheel={() => document.activeElement.blur()}
                      disabled={!enable}
                      value={
                        adstockDataset
                          ? adstockDataset[item.id]
                            ? isNaN(adstockDataset[item.id])
                              ? ""
                              : adstockDataset[item.id]
                            : isNaN(currData[item.id])
                            ? ""
                            : currData[item.id]
                          : ""
                      }
                      min={
                        tvFlag ? displayObjTV[item.id].minVal : item.minValue
                      }
                      max={
                        tvFlag ? displayObjTV[item.id].maxVal : item.maxValue
                      }
                      onChange={(e) => {
                        onChangeCurr(item.id, parseFloat(e.target.value));
                      }}
                    />
                    <span>
                      <label className="input-info">
                        min:{" "}
                        {tvFlag ? displayObjTV[item.id].minVal : item.minValue}{" "}
                        |{" "}
                      </label>
                      <label className="input-info">
                        Max:{" "}
                        {tvFlag ? displayObjTV[item.id].maxVal : item.maxValue}
                      </label>
                    </span>
                  </div>
                </div>
              ) : null
            ) : (
              <LocalParameterItem
                key={item.id}
                {...item}
                onChange={onChangeCurr}
                value={
                  adstockDataset ? adstockDataset[item.id] : currData[item.id]
                }
                enable={enable}
                show={show}
                userInputs={userInputs}
              />
            )
          )}
        </div>
      )}
    </div>
  );
}

export default AdStockParameters;
