//Import required libraies
import React, { useState, useEffect, useRef } from "react";
import { v4 } from "uuid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { DatePicker } from "../../../../uiTheme";

//Import utils
import { config } from "../../../../config/config";
import {
  endAfterStart,
  pickMinMaxDate,
  getWeekBasedOnHistoricWeek,
  getDaysDiff,
} from "../../../../utils/utils";

//Import styles
import "./Campaign.scss";

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "580px",
    height: "26px",
    borderRadius: "3px",
    color: "#46596a",
    backgroundColor: "white",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    padding: "0px 12px",
    color: "#46596a",
    width: "auto",
  },
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      color: "#46596a",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    marginTop: "30px",
    padding: "0px 5px",
    borderRadius: "3px",
    paddingTop: "2px",
    fontSize: 11,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Hind Siliguri",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#46596a",
      boxShadow: "none",
    },
  },
  root: {
    padding: 0,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const StyledAutoComplete = withStyles((theme) => ({
  tag: {
    background: "white",
    color: "#46596a",
  },
  paper: {
    fontFamily: "Hind Siliguri",
    fontSize: 13,
    color: "#46596a",
  },
}))(Autocomplete);

function CampaignRow(props) {
  let {
    campaign,
    campaign_end_date,
    campaign_start_date,
    pre_campaign_start_date,
    pre_campaign_end_date,
    campaign_min_date,
    campaign_max_date,
    campaignid,
    modelConfigId,
    userSelectedCampaigns,
    onChange,
    campaignEnabledUi,
    historicWeekDate,
  } = props;
  const [minMaxDate, setMinMaxDate] = useState({
    min: campaign_min_date,
    max: campaign_max_date,
  });
  const [campaignRowData, setCampaignRowData] = useState({
    campaign,
    campaign_end_date,
    campaign_start_date,
    pre_campaign_start_date,
    pre_campaign_end_date,
    campaign_min_date,
    campaign_max_date,
    campaignid,
    campaignEnabledUi:
      campaignEnabledUi === undefined ? false : campaignEnabledUi,
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (!loading) {
      updateCampaign(campaignRowData, true);
    }
  }, [campaignRowData.campaignEnabledUi]);

  let deleteCampaign = () => {
    let filteredUserSelectedCampaigns = userSelectedCampaigns.filter(
      (item) => item.campaign !== campaignRowData.campaign
    );
    onChange(modelConfigId, filteredUserSelectedCampaigns);
  };

  let updateCampaign = (campaignRowData, campaignEnabledUi = false) => {
    let updUserSelectedCampaigns = userSelectedCampaigns.map((campaign) =>
      campaign.campaign != campaignRowData.campaign
        ? { ...campaign }
        : { ...campaignRowData, campaignEnabledUi }
    );
    onChange(modelConfigId, updUserSelectedCampaigns);
  };

  useEffect(() => {
    if (!loading) {
      if (!campaignRowData.campaign_start_date) {
        setError(config.campaignMinErrorNote);
      } else if (!campaignRowData.campaign_end_date) {
        setError(config.campaignMaxErrorNote);
      } else if (
        !endAfterStart(
          campaignRowData.campaign_start_date,
          campaignRowData.campaign_end_date
        )
      ) {
        setError(config.campaignStartEndDateError);
      }
    }
  }, [campaignRowData]);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setCampaignRowData({
      ...campaignRowData,
      pre_campaign_end_date: getDaysDiff(
        campaignRowData.campaign_start_date,
        campaignRowData.campaign_end_date,
        campaignRowData.pre_campaign_start_date
      ),
    });
  }, [
    campaignRowData.campaign_end_date,
    campaignRowData.campaign_start_date,
    campaignRowData.pre_campaign_start_date,
  ]);

  return (
    <>
      <div className="campaign">
        <div className="input-item-label-less-campaign">
          <Select
            defaultValue={campaign}
            disabled={!campaignRowData.campaignEnabledUi}
            className={classes.multiSelect}
            MenuProps={MenuProps}
          >
            <MenuItem value={campaign} className={classes.singleMenuItem}>
              {campaign}
            </MenuItem>
          </Select>
        </div>
        <div className="campaign-dates-container">
          <div className="campaign-dates-row">
            <div className="input-item-label-less">
              <label>Campaign Start Date</label>
              {/* <input
                type="date"
                min={historicWeekDate.min}
                value={campaignRowData.campaign_start_date}
                max={historicWeekDate.max}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => {
                  setError(false);
                  setCampaignRowData({
                    ...campaignRowData,
                    campaign_start_date: e.target.value,
                  });
                }}
                disabled={!campaignRowData.campaignEnabledUi}
              /> */}
              <DatePicker
                min={historicWeekDate.min}
                value={campaignRowData.campaign_start_date}
                max={historicWeekDate.max}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => {
                  setError(false);
                  setCampaignRowData({
                    ...campaignRowData,
                    campaign_start_date: e.target.value,
                  });
                }}
                disabled={!campaignRowData.campaignEnabledUi}
              />
            </div>
            <div className="input-item-label-less">
              <label>Campaign End Date</label>
              {/* <input
                type="date"
                min={historicWeekDate.min}
                value={campaignRowData.campaign_end_date}
                max={historicWeekDate.max}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => {
                  setError(false);
                  setCampaignRowData({
                    ...campaignRowData,
                    campaign_end_date: e.target.value,
                  });
                }}
                disabled={!campaignRowData.campaignEnabledUi}
              /> */}
              <DatePicker
                min={historicWeekDate.min}
                value={campaignRowData.campaign_end_date}
                max={historicWeekDate.max}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => {
                  setError(false);
                  setCampaignRowData({
                    ...campaignRowData,
                    campaign_end_date: e.target.value,
                  });
                }}
                disabled={!campaignRowData.campaignEnabledUi}
              />
            </div>
            <div className="input-item-label-less">
              <label>Pre-Campaign Start Date</label>
              {/* <input
                type="date"
                value={campaignRowData.pre_campaign_start_date}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => {
                  setError(false);
                  setCampaignRowData({
                    ...campaignRowData,
                    pre_campaign_start_date: e.target.value,
                  });
                }}
                disabled={!campaignRowData.campaignEnabledUi}
              /> */}
              <DatePicker
                value={campaignRowData.pre_campaign_start_date}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => {
                  setError(false);
                  setCampaignRowData({
                    ...campaignRowData,
                    pre_campaign_start_date: e.target.value,
                  });
                }}
                disabled={!campaignRowData.campaignEnabledUi}
              />
            </div>
            <div className="input-item-label-less">
              <label>Pre-Campaign End Date</label>
              {/* <input
                type="date"
                value={campaignRowData.pre_campaign_end_date}
                disabled={true}
              /> */}
              <DatePicker
                value={campaignRowData.pre_campaign_end_date}
                disabled={true}
              />
            </div>
          </div>
          <div className="campaign-add-button">
            <i
              className="material-icons-outlined delete-icon"
              onClick={() => {
                deleteCampaign();
              }}
            >
              delete
            </i>
            {campaignRowData.campaignEnabledUi ? (
              <i
                className="material-icons-outlined done-icon"
                onClick={() => {
                  updateCampaign(campaignRowData);
                }}
              >
                done
              </i>
            ) : (
              <i
                className="material-icons-outlined edit-icon"
                onClick={() => {
                  setCampaignRowData({
                    ...campaignRowData,
                    campaignEnabledUi: true,
                  });
                }}
              >
                edit
              </i>
            )}
          </div>
        </div>
      </div>
      {error && <p className="error">{error}</p>}
    </>
  );
}

function Campaign(props) {
  let {
    allCampaigns,
    userSelectedCampaigns,
    onChange,
    modelConfigId,
    userInputs,
  } = props;

  const [minMaxDate, setMinMaxDate] = useState({ min: null, max: null });
  const [campaign, setCampaign] = useState({
    campaign: "",
    campaign_start_date: "",
    campaign_end_date: "",
    pre_campaign_start_date: "",
    pre_campaign_end_date: "",
  });
  const [error, setError] = useState(false);
  const classes = useStyles();
  const autoC = useRef(null);

  let setData = (e, value) => {
    setError(false);
    if (value) {
      allCampaigns.map((campaign) => {
        if (campaign.campaign === value.campaign) {
          setMinMaxDate({
            min: campaign.campaign_start_date,
            max: campaign.campaign_end_date,
          });
          setCampaign({
            ...campaign,
            campaign_start_date: "",
            campaign_end_date: "",
            pre_campaign_start_date: "",
            pre_campaign_end_date: "",
            campaign_min_date: campaign.campaign_start_date,
            campaign_max_date: campaign.campaign_end_date,
          });
        }
      });
    } else {
      setCampaign({
        campaign: "",
        campaign_start_date: "",
        campaign_end_date: "",
        pre_campaign_start_date: "",
        pre_campaign_end_date: "",
      });
    }
  };

  useEffect(() => {
    setCampaign({
      ...campaign,
      pre_campaign_end_date: getDaysDiff(
        campaign.campaign_start_date,
        campaign.campaign_end_date,
        campaign.pre_campaign_start_date
      ),
    });
  }, [
    campaign.campaign_start_date,
    campaign.campaign_end_date,
    campaign.pre_campaign_start_date,
  ]);

  let addCampaign = () => {
    const ele = autoC.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
    let duplicate = userSelectedCampaigns.filter(
      (camp) => camp.campaign === campaign.campaign
    );
    if (campaign.campaign === "") {
      setError(config.campaignSelectErrorNote);
    } else if (duplicate.length) {
      setError(config.campaignDupErrorNote);
    } else if (!campaign.campaign_start_date) {
      setError(config.campaignMinErrorNote);
    } else if (!campaign.campaign_end_date) {
      setError(config.campaignMaxErrorNote);
    } else if (
      !endAfterStart(campaign.campaign_start_date, campaign.campaign_end_date)
    ) {
      setError(config.campaignStartEndDateError);
    } else {
      onChange(modelConfigId, [...userSelectedCampaigns, campaign]);
      setMinMaxDate({ min: "", max: "" });
      setCampaign({
        campaign: "",
        campaign_start_date: "",
        campaign_end_date: "",
        pre_campaign_start_date: "",
        pre_campaign_end_date: "",
      });
    }
  };
  let historicWeekDate = { min: userInputs.startDate, max: userInputs.endDate };

  if (userInputs.global_week_date_selection === config.hardCoded.historicWeek)
    historicWeekDate = getWeekBasedOnHistoricWeek(
      userInputs.historic_week_data
    );
  if (userInputs.global_week_date_selection === config.hardCoded.dateRange) {
    historicWeekDate = { min: userInputs.startDate, max: userInputs.endDate };
  }

  return (
    <>
      {allCampaigns.length ? (
        <>
          {userSelectedCampaigns.map((value) => (
            <CampaignRow
              key={v4()}
              {...value}
              modelConfigId={modelConfigId}
              userSelectedCampaigns={userSelectedCampaigns}
              onChange={onChange}
              minMaxDate={minMaxDate}
              historicWeekDate={historicWeekDate}
            />
          ))}
          <div className="campaign">
            <div className="input-item-label-less-campaign">
              <StyledAutoComplete
                id="combo-box-demo"
                options={allCampaigns}
                getOptionLabel={(option) => option.campaign}
                getOptionSelected={(option, value) => option === value}
                style={{
                  maxWidth: "100%",
                  width: "580px",
                  minWidth: 220,
                  border: "1px solid #dedede",
                  borderRadius: 4,
                  marginRight: 15,
                  paddingLeft: 5,
                  marginLeft: -5,
                }}
                size="small"
                className={classes.typographyStyle}
                onChange={(e, value) => {
                  setData(e, value);
                }}
                ref={autoC}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    placeholder="Choose Campaign..."
                    variant="outlined"
                    size="small"
                    className={classes.typographyStyle}
                  />
                )}
              />
            </div>
            <div className="campaign-dates-container">
              <div className="campaign-dates-row">
                <div className="input-item-label-less">
                  <label>Campaign Start Date</label>
                  {/* <input
                    type="date"
                    min={historicWeekDate.min}
                    value={campaign.campaign_start_date}
                    max={historicWeekDate.max}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => {
                      setError(false);
                      setCampaign({
                        ...campaign,
                        campaign_start_date: e.target.value,
                      });
                    }}
                  /> */}
                  <DatePicker
                    min={historicWeekDate.min}
                    value={campaign.campaign_start_date}
                    max={historicWeekDate.max}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => {
                      setError(false);
                      setCampaign({
                        ...campaign,
                        campaign_start_date: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="input-item-label-less">
                  <label>Campaign End Date</label>
                  {/* <input
                    type="date"
                    min={historicWeekDate.min}
                    value={campaign.campaign_end_date}
                    max={historicWeekDate.max}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => {
                      setError(false);
                      setCampaign({
                        ...campaign,
                        campaign_end_date: e.target.value,
                      });
                    }}
                  /> */}
                  <DatePicker
                    min={historicWeekDate.min}
                    value={campaign.campaign_end_date}
                    max={historicWeekDate.max}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => {
                      setError(false);
                      setCampaign({
                        ...campaign,
                        campaign_end_date: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="input-item-label-less">
                  <label>Pre-Campaign Start Date</label>
                  {/* <input
                    type="date"
                    value={campaign.pre_campaign_start_date}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => {
                      setError(false);
                      setCampaign({
                        ...campaign,
                        pre_campaign_start_date: e.target.value,
                      });
                    }}
                  /> */}
                  <DatePicker
                    value={campaign.pre_campaign_start_date}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => {
                      setError(false);
                      setCampaign({
                        ...campaign,
                        pre_campaign_start_date: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="input-item-label-less">
                  <label>Pre-Campaign End Date</label>
                  {/* <input
                    type="date"
                    value={campaign.pre_campaign_end_date}
                    disabled={true}
                  /> */}
                  <DatePicker
                    value={campaign.pre_campaign_end_date}
                    disabled={true}
                  />
                </div>
              </div>
              {/* <i
                className="material-icons done-icon"
                onClick={() => {
                  addCampaign();
                }}
              >
                check_circle
              </i> */}
              <div className="campaign-add-button">
                <Button
                  variant="contained"
                  className={classes.contained}
                  onClick={() => {
                    addCampaign();
                  }}
                >
                  Add Set
                </Button>
              </div>
            </div>
          </div>
          {error && <p className="error">{error}</p>}
        </>
      ) : (
        <div className="input-item-container campaign">
          <div className="input-item-label-less">
            <Select value="No campaigns" disabled>
              <MenuItem disabled>No campaigns</MenuItem>
            </Select>
          </div>
          <div className="input-item-label-less">
            {/* <input type="date" disabled={true} /> */}
            <DatePicker disabled={true} />
          </div>
          <div className="input-item-label-less">
            <DatePicker disabled={true} />
            {/* <input type="date" disabled={true} /> */}
          </div>
        </div>
      )}
    </>
  );
}

export default Campaign;
