//Import required libraies
import React, { useState, useEffect, useRef } from "react";
import { v4 } from "uuid";
import { DatePicker } from "../../../../uiTheme";

//Import utils
import { config } from "../../../../config/config";
import {
  endAfterStart,
  pickMinMaxDate,
  getWeekBasedOnHistoricWeek,
  getFutureDate,
  getFutureDateFromGivenDate,
  checkDatesInRange,
  getDaysDiff,
} from "../../../../utils/utils";

// Import custom hooks
import { useNonInitialEffect } from "../../../../hooks/useNonInitialEffect";

//Import styles
import "./PilotDates.scss";

function AutoMlBudgetPilotDates(props) {
  const {
    allCampaigns,
    userSelectedCampaigns,
    onChange,
    modelConfigId,
    userInputs,
    activeModel,
  } = props;

  let activeModelConfig = userInputs.models.find(
    (model) => model.id === activeModel.id
  ).config;

  const pilotDates = {
    pilot_start_date: activeModelConfig.pilot_start_date,
    pilot_end_date: activeModelConfig.pilot_end_date,
  };

  let historicWeekDate = { min: userInputs.startDate, max: userInputs.endDate };
  if (userInputs.is_config_manual) {
    historicWeekDate = { min: "", max: "" };
  } else {
    if (userInputs.global_week_date_selection === config.hardCoded.historicWeek)
      historicWeekDate = { min: getFutureDate(1), max: getFutureDate(1) };
    if (userInputs.global_week_date_selection === config.hardCoded.dateRange) {
      historicWeekDate = { min: userInputs.startDate, max: userInputs.endDate };
    }
  }

  useNonInitialEffect(() => {
    onChange(
      "pilot_start_date",
      getFutureDateFromGivenDate(userInputs.endDate, 1)
    );
    onChange(
      "pilot_end_date",
      getFutureDateFromGivenDate(userInputs.endDate, 2)
    );
  }, [userInputs.endDate]);

  // console.groupCollapsed("PilotDates Console");
  // console.log("historicWeekDate", historicWeekDate);
  // console.log("pilotDates", pilotDates);
  // console.groupEnd();

  return (
    <>
      <div className="pilot-dates-container">
        <div className="input-item-label-less">
          <label>Pilot Start Date:</label>
          <DatePicker
            min={historicWeekDate.min}
            value={pilotDates.pilot_start_date}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => {
              onChange("pilot_start_date", e.target.value);
            }}
          />
          {/* <input
            type="date"
            min={historicWeekDate.min}
            value={pilotDates.pilot_start_date}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => {
              onChange("pilot_start_date", e.target.value);
            }}
          /> */}
        </div>

        <div className="input-item-label-less">
          <label>Pilot End Date:</label>
          <DatePicker
            min={historicWeekDate.max}
            value={pilotDates.pilot_end_date}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => {
              onChange("pilot_end_date", e.target.value);
            }}
          />
          {/* <input
            type="date"
            min={historicWeekDate.max}
            value={pilotDates.pilot_end_date}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => {
              onChange("pilot_end_date", e.target.value);
            }}
          /> */}
        </div>
      </div>
    </>
  );
}

export default AutoMlBudgetPilotDates;
