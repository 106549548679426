import C from "../../constants";
import {
  manipulateDataset,
  removeDupFromArray,
  convertArrToLowerCase,
  toggleDatasetReducer,
  toggleKpiReducer,
  isEqualArray,
  mandatoryKpiSelectionHandler,
  mandatoryKpiMergeDatasetHandler,
} from "../../../utils/utils";

import { config } from "../../../config/config";

export default function models(state = [], payload, is_schedule_config) {
  let { type, data } = payload;
  let {
    modelId,
    kpi,
    key,
    kpiList,
    value,
    modelRunFlag,
    dataset,
    selectedDataset,
    activeModel,
    categoryData,
    activeDatasetForKpi,
    activeDatasetDates,
    kpi_variables_list,
    dataset_list,
    typeOfDataset,
    intersectedMandatoryKpi,
  } = data;

  let newState;
  switch (type) {
    case C.TOGGLE_MODEL:
      newState = state.map((model) =>
        model.id !== modelId ? model : { ...model, run: modelRunFlag }
      );
      return newState;
    case C.TOGGLE_DATASET:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: toggleDatasetReducer(model.data_sources, data),
            }
      );
      return newState;
    case C.ADD_TOGGLE_KPI:
      //hardCoded stuff
      let addToggleDatasetList = config.hardCoded.radioDatasetList;
      // console.log(dataset.type);
      // console.log(selectedDataset);
      let addToggleDatasetFlag = addToggleDatasetList.includes(dataset.type);
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) =>
                    data_source.id !== dataset.id
                      ? { ...data_source }
                      : {
                          ...dataset,
                          top_n_competitor: data_source.top_n_competitor,
                          kpi_list: kpiList,
                          kpi_variables_list: kpi_variables_list,
                        }
                  )
                : addToggleDatasetFlag
                ? [
                    ...toggleKpiReducer(model.data_sources, {
                      ...data,
                      activeModel,
                      flag: false,
                    }),
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      kpiList,
                      kpiListFlag: true,
                      activeDatasetDates,
                      kpi_variables_list,
                      modelId,
                    }),
                  ]
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      kpiList,
                      kpiListFlag: true,
                      activeDatasetDates,
                      kpi_variables_list,
                      modelId,
                    }),
                  ],
            }
      );
      return newState;
    case C.TOGGLE_KPI:
      //hardCoded stuff
      let toggleDatasetList = config.hardCoded.radioDatasetList;
      let toggleDatasetFlag = toggleDatasetList.includes(dataset.type);
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) =>
                    data_source.id !== dataset.id
                      ? { ...data_source }
                      : {
                          ...dataset,
                          kpi_list: [kpi],
                          // removeDupFromArray([
                          //   ...dataset.kpi_list,
                          //   kpi,
                          // ]),
                        }
                  )
                : toggleDatasetFlag
                ? [
                    ...toggleKpiReducer(model.data_sources, {
                      ...data,
                      activeModel,
                      flag: false,
                    }),
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      singleKpiFlag: true,
                      activeDatasetDates,
                      modelId,
                    }),
                  ]
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      singleKpiFlag: true,
                      activeDatasetDates,
                      modelId,
                    }),
                  ],
            }
      );
      return newState;
    case C.ADD_DATASET:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: [
                ...model.data_sources,
                manipulateDataset({ dataset, activeModel, modelId }),
              ],
            }
      );
      return newState;
    case C.REMOVE_DATASET:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: model.data_sources.filter(
                (obj) => dataset.id !== obj.id
              ),
            }
      );
      return newState;
    case C.ADD_KPI:
      //hardCoded stuff
      let radioDatasetList = config.hardCoded.radioDatasetList;
      let toggleFlag = radioDatasetList.includes(dataset?.type);
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) => {
                    if (data_source.id !== dataset.id) {
                      return { ...data_source };
                    } else {
                      if (
                        activeModel &&
                        activeModel.id === config.hardCoded.autoMlBudgetId &&
                        dataset.type === "MEDIA"
                      ) {
                        let newDataset = { ...dataset };
                        newDataset = mandatoryKpiSelectionHandler(
                          { ...newDataset, kpi_variables_list: [] },
                          activeModel,
                          kpi
                        );
                        return {
                          ...newDataset,
                          kpi_variables_list: [
                            ...kpi_variables_list,
                            ...newDataset.kpi_variables_list,
                          ],
                          kpi_list: removeDupFromArray([
                            ...newDataset.kpi_list,
                            kpi,
                          ]),
                        };
                      } else {
                        return {
                          ...dataset,
                          kpi_variables_list: kpi_variables_list,
                          kpi_list: removeDupFromArray([
                            ...dataset.kpi_list,
                            kpi,
                          ]),
                        };
                      }
                    }
                  })
                : toggleFlag
                ? [
                    ...toggleKpiReducer(model.data_sources, {
                      ...data,
                      activeModel,
                      flag: false,
                    }),
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      singleKpiFlag: true,
                      activeDatasetDates,
                      kpi_variables_list,
                      modelId,
                    }),
                  ]
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      singleKpiFlag: true,
                      activeDatasetDates,
                      kpi_variables_list,
                      modelId,
                    }),
                  ],
            }
      );
      return newState;
    case C.REMOVE_KPI:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources:
                dataset.kpi_list.length === 1 //user unchecks all KPIs
                  ? model.data_sources.filter((obj) => dataset.id !== obj.id)
                  : model.data_sources.map((data_source) =>
                      data_source.id !== dataset.id
                        ? { ...data_source }
                        : {
                            ...data_source,
                            kpi_list: data_source.kpi_list.filter(
                              (val) => val.toLowerCase() !== kpi.toLowerCase()
                            ),
                          }
                    ),
            }
      );
      return newState;
    case C.ADD_MERGE_KPI:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) => {
                    if (data_source.id !== dataset.id) {
                      return { ...data_source };
                    } else {
                      if (
                        activeModel &&
                        activeModel.id === config.hardCoded.autoMlBudgetId
                      ) {
                        let newDataset = { ...dataset };
                        newDataset = mandatoryKpiMergeDatasetHandler(
                          { ...newDataset },
                          kpi,
                          intersectedMandatoryKpi
                        );
                        return {
                          ...newDataset,
                          kpi_variables_list: kpi_variables_list,
                          kpi_list: removeDupFromArray([
                            ...newDataset.kpi_list,
                            kpi,
                          ]),
                        };
                      } else {
                        return {
                          ...dataset,
                          kpi_variables_list: kpi_variables_list,
                          kpi_list: removeDupFromArray([
                            ...dataset.kpi_list,
                            kpi,
                          ]),
                        };
                      }
                    }
                  })
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      singleKpiFlag: true,
                      activeDatasetDates,
                      kpi_variables_list,
                      modelId,
                      is_merge_dataset: true,
                      dataset_list,
                      typeOfDataset,
                      intersectedMandatoryKpi,
                    }),
                  ],
            }
      );
      return newState;
    case C.REMOVE_MERGE_KPI:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources:
                dataset.kpi_list.length === 1 //user unchecks all KPIs
                  ? model.data_sources.filter((obj) => dataset.id !== obj.id)
                  : model.data_sources.map((data_source) =>
                      data_source.id !== dataset.id
                        ? { ...data_source }
                        : {
                            ...data_source,
                            kpi_list: data_source.kpi_list.filter(
                              (val) => val.toLowerCase() !== kpi.toLowerCase()
                            ),
                          }
                    ),
            }
      );
      return newState;
    case C.UPDATE_MERGE_DATASET_LOCAL_PARAMETERS:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) =>
                    data_source.id !== dataset.id
                      ? { ...data_source }
                      : {
                          ...dataset,
                          [key]: value,
                        }
                  )
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      kpi,
                      singleKpiFlag: true,
                      key,
                      value,
                      activeDatasetForKpi,
                      activeDatasetDates,
                      modelId,
                      is_merge_dataset: true,
                      dataset_list,
                      typeOfDataset,
                    }),
                  ],
            }
      );
      return newState;
    case C.UPDATE_DATASET_LOCAL_PARAMETERS:
      //hardCoded stuff
      let toggleDatasetParameterFlag =
        config.hardCoded.radioDatasetList.includes(dataset.type);
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) =>
                    data_source.id !== dataset.id
                      ? { ...data_source }
                      : {
                          ...dataset,
                          [key]: value,
                        }
                  )
                : toggleDatasetParameterFlag
                ? [
                    ...toggleKpiReducer(model.data_sources, {
                      ...data,
                      activeModel,
                      flag: false,
                    }),
                    manipulateDataset({
                      dataset,
                      kpi,
                      singleKpiFlag: true,
                      key,
                      value,
                      activeDatasetForKpi,
                      activeDatasetDates,
                      modelId,
                    }),
                  ]
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      kpi,
                      singleKpiFlag: true,
                      key,
                      value,
                      activeDatasetForKpi,
                      activeDatasetDates,
                      modelId,
                    }),
                  ],
            }
      );
      return newState;
    case C.UPDATE_MODEL_LOCAL_PARAMETERS:
      newState = state.map((model) => {
        if (model.id !== modelId) {
          return { ...model };
        } else if (
          modelId === config.hardCoded.autoMlId &&
          (value === config.hardCoded.pilotRunId ||
            value === config.hardCoded.adstockRunId) &&
          config.hardCoded.nestedRunId === model.config?.run_type &&
          model?.pilot_saved_selections
        ) {
          return {
            ...model,
            config: {
              ...model.config,
              [key]: value,
              pilot_start_date: model?.pilot_saved_selections?.pilot_start_date,
              pilot_end_date: model?.pilot_saved_selections?.pilot_end_date,
              retail_margin_price: model?.pilot_saved_selections
                ?.retail_margin_price
                ? model?.pilot_saved_selections?.retail_margin_price
                : 0,
              sales_coverage: model?.pilot_saved_selections?.sales_coverage
                ? model?.pilot_saved_selections?.sales_coverage
                : 1,
              seasonality: model?.pilot_saved_selections?.seasonality,
            },
            data_sources: [...model?.pilot_saved_selections?.data_sources],
            merged_data_sources:
              model?.pilot_saved_selections?.merged_data_sources,
            mergeChannels: model?.pilot_saved_selections?.mergeChannels
              ? model?.pilot_saved_selections?.mergeChannels
              : model.mergeChannels,
          };
        } else if (
          modelId === config.hardCoded.autoMlId &&
          value === config.hardCoded.nestedRunId
        ) {
          return {
            ...model,
            config: {
              ...model.config,
              [key]: value,
            },
            pilot_saved_selections: {
              pilot_start_date: model.config?.pilot_start_date,
              pilot_end_date: model.config?.pilot_end_date,
              retail_margin_price: model?.config?.retail_margin_price,
              sales_coverage: model?.pilot_saved_selections?.sales_coverage
                ? model?.pilot_saved_selections?.sales_coverage
                : model?.config?.sales_coverage
                ? model?.config?.sales_coverage
                : 1,
              seasonality: model?.config?.seasonality,
              data_sources: model?.data_sources,
              merged_data_sources: model?.merged_data_sources,
              mergeChannels: model?.mergeChannels,
            },
          };
        } else if (
          modelId === config.hardCoded.regBudId &&
          value === config.hardCoded.rbtRunId &&
          model.rbt_saved_selections
        ) {
          return {
            ...model,
            config: {
              ...model.config,
              [key]: value,
            },
            data_sources: [...model.rbt_saved_selections.data_sources],
          };
        } else if (
          modelId === config.hardCoded.regBudId &&
          value === config.hardCoded.budgetId
        ) {
          return {
            ...model,
            config: { ...model.config, [key]: value },
            rbt_saved_selections: {
              data_sources: model.data_sources,
            },
          };
        } else {
          return {
            ...model,
            config: {
              ...model.config,
              [key]:
                key === "nested_run_regions" &&
                value?.length === 0 &&
                model.config.pilot_run_id
                  ? {
                      test: {
                        0: {
                          regions: [],
                        },
                      },
                    }
                  : value,
            },
          };
        }
      });
      return newState;
    case C.UPDATE_CATEGORY:
      newState = state.map((model) => ({
        ...model,
        data_sources: model.data_sources.map((data_source) => {
          if (data_source?.id === dataset?.id) {
            if (data_source.hasOwnProperty("category")) {
              return {
                ...data_source,
                category: categoryData.length
                  ? data_source.category
                    ? categoryData.find((item) =>
                        data_source.category.includes(item.id)
                      )
                      ? data_source.category
                      : [categoryData[0].id]
                    : [categoryData[0].id]
                  : null,
              };
            } else {
              return { ...data_source };
            }
          } else {
            return { ...data_source };
          }
        }),
      }));
      return newState;
    case C.UPDATE_CATEGORY_DATASET:
      newState = state.map((model) => ({
        ...model,
        // data_sources: model.data_sources.filter(
        //   (data_source) => !data_source.hasOwnProperty("category")
        // ),
        data_sources: [], //on chnage of country, brand empty data_sources for category api
      }));
      return newState;
    case C.UPDATE_DATASET_ON_COUNTRY_CHANGE:
      // newState = state.map((model) => ({
      //   ...model,
      //   data_sources: model.data_sources.filter((dataset) => {
      //     return data.find((filteredDataset) => {
      //       return filteredDataset.id === dataset.id;
      //     });
      //   }),
      // }));
      newState = state.map((model) => ({
        ...model,
        data_sources: [], //on chnage of country empty data_sources
      }));
      return newState;
    case C.SELECT_ALL:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) =>
                    data_source.id !== dataset.id
                      ? { ...data_source }
                      : {
                          ...data_source,
                          kpi_list: kpiList,
                          kpi_variables_list,
                        }
                  )
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      activeModel,
                      addAllKpi: true,
                      kpiList,
                      kpi_variables_list,
                      modelId,
                    }),
                  ],
            }
      );
      return newState;
    case C.CLEAR_ALL:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: model.data_sources.filter(
                (obj) => dataset.id !== obj.id
              ),
            }
      );
      return newState;
    case C.UPDATE_KPI_VARIABLES:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: model.data_sources.map((data_source) =>
                data_source.id !== dataset.id
                  ? { ...data_source }
                  : {
                      ...dataset,
                      kpi_variables_list: kpi_variables_list.map((elem) =>
                        elem.id === kpi
                          ? { ...elem, [key]: value }
                          : { ...elem }
                      ),
                    }
              ),
            }
      );
      return newState;
    case C.RESET_NEW_KPI:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: model.data_sources.map((data_source) => {
                if (data_source.id !== dataset.id) {
                  return { ...data_source };
                } else {
                  const kpi_list = dataset.kpi_list.filter((e) =>
                    config.hardCoded.rbtAndSuIds.includes(model.id)
                      ? !config.hardCoded.avgPriceDependentKpiListForSuAndRbt.includes(
                          e
                        )
                      : !config.hardCoded.avgPriceDependentKpiList.includes(e)
                  );
                  return {
                    ...dataset,
                    kpi_list,
                    kpi_variables_list: kpi_variables_list
                      .map((elem) =>
                        elem.id === kpi
                          ? { ...elem, [key]: value }
                          : { ...elem }
                      )
                      .filter((e) =>
                        config.hardCoded.rbtAndSuIds.includes(model.id)
                          ? !config.hardCoded.avgPriceDependentKpiListForSuAndRbt.includes(
                              e.id
                            )
                          : !config.hardCoded.avgPriceDependentKpiList.includes(
                              e.id
                            )
                      ),
                  };
                }
              }),
            }
      );
      return newState;
    case C.ON_CHANGE_CATEGORY:
      // When users changes the category, this will update the selected category + resets the KPIs selection
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? !value?.length
                  ? model.data_sources.filter(
                      (data_source) => data_source.id !== dataset.id
                    )
                  : model.data_sources.map((data_source) =>
                      data_source.id !== dataset.id
                        ? { ...data_source }
                        : {
                            ...dataset,
                            [key]: value,
                          }
                    )
                : [
                    ...toggleKpiReducer(model.data_sources, {
                      ...data,
                      activeModel,
                      flag: false,
                    }),
                    manipulateDataset({
                      dataset,
                      activeModel,
                      kpi,
                      kpiList: [],
                      activeDatasetDates,
                      kpi_variables_list: [],
                      key,
                      value,
                      modelId,
                    }),
                  ],
            }
      );
      return newState;
    case C.UPDATE_CATEGORY_KPI:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              ...(selectedDataset && {
                data_sources: model.data_sources.map((data_source) =>
                  data_source.id !== dataset.id
                    ? { ...data_source }
                    : dataset.type === config.hardCoded.datasetNeilsenType
                    ? {
                        ...dataset,
                        kpi_list: dataset.kpi_list.filter((e) =>
                          activeDatasetForKpi.kpi_list.includes(e)
                        ),
                      }
                    : {
                        ...dataset,
                        // kpi_list: [],
                        // kpi_variables_list: [],
                        [key]: value,
                      }
                ),
              }),
            }
      );
      return newState;
    case C.ADD_MERGE_DATASET:
      newState = state.map((model) => {
        if (model.id !== modelId) {
          return { ...model };
        } else {
          return {
            ...model,
            data_sources: model.data_sources.filter(
              (dataset) => !value.dataset_list.includes(dataset.id)
            ),
            merged_data_sources: model.merged_data_sources
              ? [...model.merged_data_sources, value]
              : [value],
          };
          // return { ...model };
        }
      });
      return newState;
    case C.EDIT_MERGE_DATASET:
      newState = state.map((model) => {
        if (model.id !== modelId) {
          return { ...model };
        } else {
          return {
            ...model,
            data_sources: model.data_sources
              .filter((elem) => elem.id !== value.id)
              .filter((dataset) => !value.dataset_list.includes(dataset.id)),
            merged_data_sources: model.merged_data_sources.map((dataset) =>
              dataset.id === value.id ? value : dataset
            ),
          };
          // return { ...model };
        }
      });
      return newState;
    case C.DELETE_MERGE_DATASET:
      newState = state.map((model) => {
        if (model.id !== modelId) {
          return { ...model };
        } else {
          let merged_data_sources = model.merged_data_sources.filter(
            (dataset) => dataset.id !== value
          );
          merged_data_sources = merged_data_sources.map((dataset, i) => {
            return {
              ...dataset,
              id: `MERGED_DATASET_${i < 10 ? 0 : ""}${i + 1}`,
              pipeline_dataset_id: `MERGED_DATASET_${i < 10 ? 0 : ""}${i + 1}`,
            };
          });
          let data_sources = model.data_sources.filter(
            (dataset) => dataset.id !== value
          );
          data_sources = data_sources
            .map((dataset) => {
              if (dataset?.merge_dataset) {
                return merged_data_sources
                  .map((values) => {
                    if (values.name === dataset.name) {
                      return {
                        ...dataset,
                        id: values.id,
                        pipeline_dataset_id: values.pipeline_dataset_id,
                      };
                    }
                  })
                  .flat();
              } else {
                return { ...dataset };
              }
            })
            .flat();
          data_sources = data_sources.filter((elem) => elem !== undefined);
          return {
            ...model,
            merged_data_sources: merged_data_sources,
            data_sources: data_sources,
          };
          // return { ...model };
        }
      });
      return newState;
    case C.SELECT_ALL_FOR_MERGED:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.map((data_source) =>
                    data_source.id !== dataset.id
                      ? { ...data_source }
                      : {
                          ...dataset,
                          kpi_list: kpiList,
                          kpi_variables_list: kpi_variables_list,
                        }
                  )
                : [
                    ...model.data_sources,
                    manipulateDataset({
                      dataset,
                      activeModel,
                      addAllKpi: true,
                      kpiList,
                      kpi_variables_list,
                      modelId,
                      is_merge_dataset: true,
                      dataset_list,
                      typeOfDataset,
                    }),
                  ],
            }
      );
      return newState;
    case C.CLEAR_ALL_FOR_MERGED:
      newState = state.map((model) =>
        model.id !== modelId
          ? { ...model }
          : {
              ...model,
              data_sources: selectedDataset
                ? model.data_sources.filter(
                    (data_source) => data_source.id !== dataset.id
                  )
                : [...model.data_sources],
            }
      );
      return newState;
    case C.RESET_MERGED_DATASET:
      newState = state.map((model) => {
        return {
          ...model,
          merged_data_sources: [],
        };
      });
      return newState;
    case C.FILTER_SKU:
      const skuOnlyData = value.map((info) => info.sku);
      newState = state.map((model) => {
        if (modelId !== model.id) {
          return model;
        } else {
          if (
            model.id === config.hardCoded.regBudId &&
            model.config.rbt_run_type === config.hardCoded.budgetId
          ) {
            return {
              ...model,
              rbt_saved_selections: {
                ...model.rbt_saved_selections,
                data_sources: model.rbt_saved_selections.data_sources.map(
                  (dataset) => {
                    if (
                      config.hardCoded.radioDatasetList.includes(
                        dataset.type
                      ) &&
                      dataset.id === activeDatasetForKpi
                    ) {
                      let skuFilter = dataset.sku.filter(({ sku }) => {
                        return skuOnlyData.includes(sku);
                      });
                      skuFilter = skuFilter.length
                        ? skuFilter
                        : [{ sku: "ALL" }];
                      return {
                        ...dataset,
                        sku: skuFilter,
                      };
                    } else {
                      return dataset;
                    }
                  }
                ),
              },
            };
          } else if (
            model.id === config.hardCoded.autoMlId &&
            model.config.run_type === config.hardCoded.nestedRunId
          ) {
            return {
              ...model,
              pilot_saved_selections: {
                ...model.pilot_saved_selections,
                data_sources: model.pilot_saved_selections.data_sources.map(
                  (dataset) => {
                    if (
                      config.hardCoded.radioDatasetList.includes(
                        dataset.type
                      ) &&
                      dataset.id === activeDatasetForKpi
                    ) {
                      let skuFilter = dataset.sku.filter(({ sku }) => {
                        return skuOnlyData.includes(sku);
                      });
                      skuFilter = skuFilter.length
                        ? skuFilter
                        : [{ sku: "ALL" }];
                      return {
                        ...dataset,
                        sku: skuFilter,
                      };
                    } else {
                      return dataset;
                    }
                  }
                ),
              },
            };
          } else {
            return {
              ...model,
              data_sources: model.data_sources.map((dataset) => {
                if (
                  config.hardCoded.radioDatasetList.includes(dataset.type) &&
                  dataset.id === activeDatasetForKpi
                ) {
                  let skuFilter = dataset.sku.filter(({ sku }) => {
                    return skuOnlyData.includes(sku);
                  });
                  skuFilter = skuFilter.length ? skuFilter : [{ sku: "ALL" }];
                  return {
                    ...dataset,
                    sku: skuFilter,
                  };
                } else {
                  return dataset;
                }
              }),
            };
          }
        }
      });
      return newState;
    case C.FILTER_KPI:
      newState = state.map((model) => {
        if (modelId !== model.id) {
          return model;
        } else {
          if (
            model.id === config.hardCoded.regBudId &&
            model.config.rbt_run_type === config.hardCoded.budgetId
          ) {
            return {
              ...model,
              rbt_saved_selections: {
                ...model.rbt_saved_selections,
                data_sources: model.rbt_saved_selections.data_sources.map(
                  (dataset) => {
                    if (
                      config.hardCoded.datasetNeilsenType === dataset.type &&
                      activeDatasetForKpi === dataset.id
                    ) {
                      // now we have an array of categories, and now the filtration will be for multiple categories.
                      // kpi will be removed from json if it's not present in any category's kpi_list
                      let kpiListForSelectedCategory = categoryData.filter(
                        (category) => dataset.category.includes(category.id)
                      );
                      kpiListForSelectedCategory =
                        kpiListForSelectedCategory.reduce((acc, curr) => {
                          curr.kpi_list.forEach((element) => {
                            if (!acc.includes(element)) {
                              acc.push(element);
                            }
                          });
                          return acc;
                        }, []);
                      const filteredKpi = dataset.kpi_list.filter((kpi) =>
                        kpiListForSelectedCategory.includes(kpi)
                      );
                      return {
                        ...dataset,
                        kpi_list: filteredKpi,
                      };
                    } else {
                      return dataset;
                    }
                  }
                ),
              },
            };
          } else if (
            model.id === config.hardCoded.autoMlId &&
            model.config.run_type === config.hardCoded.nestedRunId
          ) {
            return {
              ...model,
              pilot_saved_selections: {
                ...model.pilot_saved_selections,
                data_sources: model.pilot_saved_selections.data_sources.map(
                  (dataset) => {
                    if (
                      config.hardCoded.datasetNeilsenType === dataset.type &&
                      activeDatasetForKpi === dataset.id
                    ) {
                      // now we have an array of categories, and now the filtration will be for multiple categories.
                      // kpi will be removed from json if it's not present in any category's kpi_list
                      let kpiListForSelectedCategory = categoryData.filter(
                        (category) => dataset.category.includes(category.id)
                      );
                      kpiListForSelectedCategory =
                        kpiListForSelectedCategory.reduce((acc, curr) => {
                          curr.kpi_list.forEach((element) => {
                            if (!acc.includes(element)) {
                              acc.push(element);
                            }
                          });
                          return acc;
                        }, []);
                      const filteredKpi = dataset.kpi_list.filter((kpi) =>
                        kpiListForSelectedCategory.includes(kpi)
                      );
                      return {
                        ...dataset,
                        kpi_list: filteredKpi,
                      };
                    } else {
                      return dataset;
                    }
                  }
                ),
              },
            };
          } else {
            return {
              ...model,
              data_sources: model.data_sources.map((dataset) => {
                if (
                  config.hardCoded.datasetNeilsenType === dataset.type &&
                  activeDatasetForKpi === dataset.id
                ) {
                  // now we have an array of categories, and now the filtration will be for multiple categories.
                  // kpi will be removed from json if it's not present in any category's kpi_list
                  let kpiListForSelectedCategory = categoryData.filter(
                    (category) => dataset.category.includes(category.id)
                  );
                  kpiListForSelectedCategory =
                    kpiListForSelectedCategory.reduce((acc, curr) => {
                      curr.kpi_list.forEach((element) => {
                        if (!acc.includes(element)) {
                          acc.push(element);
                        }
                      });
                      return acc;
                    }, []);
                  const filteredKpi = dataset.kpi_list.filter((kpi) =>
                    kpiListForSelectedCategory.includes(kpi)
                  );
                  return {
                    ...dataset,
                    kpi_list: filteredKpi,
                  };
                } else {
                  return dataset;
                }
              }),
            };
          }
        }
      });
      return newState;
    case C.ADD_CONFIG_MANUAL_KPI:
      const { kpi_name } = data;
      newState = state.map((model) => {
        if (model.id === modelId) {
          return {
            ...model,
            data_sources: [
              {
                id: "MANUAL_RUN",
                name: "MANUAL_RUN",
                type: "MANUAL_RUN",
                kpi_list: {
                  [kpi_name]: {},
                },
                pipeline_dataset_id: "MANUAL_RUN",
              },
            ],
          };
        } else {
          return model;
        }
      });
      return newState;
    case C.ADD_MANUAL_KPI:
      const { manualKpi, kpi_type } = data;
      newState = state.map((model) => {
        if (model.id === config.hardCoded.mmtId) {
          let dataSource = [];
          if (model.data_sources.length) {
            let datasetManual = model.data_sources[0];
            // delete previous primary kpi if new primary kpi is selected
            if (kpi_type === config.hardCoded.kpiTypePrimary) {
              for (const key in datasetManual.kpi_list) {
                if (datasetManual.kpi_list.hasOwnProperty(key)) {
                  //Now, object[key] is the current value
                  if (datasetManual.kpi_list[key].kpi_type === "primary") {
                    delete datasetManual.kpi_list[key];
                  }
                }
              }
            }
            datasetManual = {
              ...datasetManual,
              kpi_list: {
                ...datasetManual.kpi_list,
                [manualKpi]: {
                  kpi_type: kpi_type,
                },
              },
            };
            dataSource = [datasetManual];
          } else {
            dataSource = [
              {
                id: "MANUAL_RUN",
                name: "MANUAL_RUN",
                type: "MANUAL_RUN",
                kpi_list: {
                  [manualKpi]: {
                    kpi_type: kpi_type,
                  },
                },
                pipeline_dataset_id: "MANUAL_RUN",
              },
            ];
          }
          return {
            ...model,
            data_sources: dataSource,
          };
        } else {
          return model;
        }
      });
      return newState;
    case C.DELETE_MANUAL_KPI:
      newState = state.map((model) => {
        if (model.id === config.hardCoded.mmtId) {
          const { manualKpi } = data;
          let datasetManual = model.data_sources[0];
          for (const key in datasetManual.kpi_list) {
            if (key === manualKpi) {
              delete datasetManual.kpi_list[key];
            }
          }
          return {
            ...model,
            data_sources: [datasetManual],
          };
        } else {
          return model;
        }
      });
      return newState;
    case C.RESET_MANUAL_KPI:
      newState = state.map((model) => {
        if (model.id === config.hardCoded.mmtId) {
          let datasetManual = model.data_sources[0];
          if (datasetManual) {
            return {
              ...model,
              config: { ...model.config, minimum_datapoint_column: "" },
              data_sources: [{ ...datasetManual, kpi_list: {} }],
            };
          } else {
            return model;
          }
        } else {
          return model;
        }
      });
      return newState;
    case C.FILTER_SU_MEDIA_DATASETS:
      // const mediaDatasetsIds = data.datasets.map((el) => el.id);
      newState = state.map((model) => {
        if (model.id === config.hardCoded.salesUpliftId) {
          return {
            ...model,
            config: {
              ...model.config,
              region_granularity: {
                ...model.config.region_granularity,
                test_dark_regions: {
                  ...model.config.region_granularity.test_dark_regions,
                  test: model.config.region_granularity.test_dark_regions.test.map(
                    (e) => {
                      const filterDatasets = e.dataset_id?.filter((datasetID) =>
                        data.datasets?.find((elm) => {
                          if (datasetID?.id) {
                            return elm.id === datasetID.id;
                          } else {
                            return elm.id === datasetID;
                          }
                        })
                      );
                      let mapDatasetsToName = filterDatasets?.reduce(
                        (acc, curr) => {
                          // if (curr?.id) {
                          //   const filterDataset = data.datasets.filter(
                          //     (elemn) => elemn.id === curr.id
                          //   );
                          //   acc.push(filterDataset[0]);
                          // } else {
                          const filterDataset = data.datasets.filter(
                            (elemn) => elemn.id === curr
                          );
                          if (filterDataset.length) {
                            acc.push(curr);
                          }
                          // }
                          return acc;
                        },
                        []
                      );
                      if (mapDatasetsToName?.length === 0) {
                        delete e["target_campaigns"];
                      }
                      return { ...e, dataset_id: mapDatasetsToName };
                    }
                  ),
                },
              },
            },
          };
        } else {
          return model;
        }
      });

      //     const filterDatasets = datasets.filter((e) =>
      //       filterMediaDataset?.find((elm) => elm.id === e)
      //     );
      //     console.log("filterDatasets", filterDatasets);
      //     const mapDatasetsToName = filterDatasets.reduce((acc, curr) => {
      //       const filterDataset = filterMediaDataset.filter((e) => e.id === curr);
      //       console.log("filterDataset", filterDataset);
      //       acc.push(filterDataset[0]);
      //       return acc;
      //     }, []);
      return newState;
    case C.UPDATE_VALIDATION_STATE:
      const { selectedModel } = data;
      newState = state.map((model) => {
        if (selectedModel.id === model.id) {
          return { ...model, validate_state: "New" };
        } else {
          return model;
        }
      });
      return newState;
    default:
      return state;
  }
}
