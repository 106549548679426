// Import required libraries
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { Breadcrumb } from "../uiTheme";
import { Breadcrumb } from "../../uiTheme";

// Import styles
import "./Breadcrumbs.scss";

// Import Utils
import {
  copyValueToClipboard,
  splitUrlToBreadcrumbData,
} from "../../utils/utils";
import {
  updateAlertInfo,
  updateSelections,
  updateDialogInfo,
  updateConfigUserInputs,
  updateRunCancelStatus,
} from "../../redux/actions";

import { config } from "../../config/config";

function Breadcrumbs(props) {
  let {
    match,
    user,
    updateAlertInfo,
    updateSelections,
    updateDialogInfo,
    configUserInputs,
    allData,
    updateRunCancelStatus,
  } = props;
  let data = splitUrlToBreadcrumbData(match.url);
  let runId = "";
  // const [cancel, setCancel] = useState(false);

  // this if block will set run id only if run name is there
  if (match?.params?.runName) {
    runId = user?.selections?.activeRunPayload?.id;
  }

  const handleCancel = (runId) => {
    // setCancel(true);
    updateDialogInfo({ ...user.dialogInfo, open: false });
    updateAlertInfo({
      open: true,
      message: config.messages.runCancelRequest,
      severity: "info",
    });
    if (runId) {
      let url = config.api.cancelDag;
      let status;
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ run_id: runId }),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateRunCancelStatus("status", "Cancelled");
            updateAlertInfo({
              open: true,
              message: config.messages.runCancelSuccess,
              severity: "success",
            });
            updateRunCancelStatus("status_message", json.statusMessage);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
            // setCancel(false);
          }
        });
    }
  };

  const handleNo = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  return (
    <>
      <div className={`${user.theme} breadcrumb`}>
        <Breadcrumb
          children={data.map((obj, index) => {
            return (
              <Link className="breadcrumb-item" key={index} to={obj.path}>
                {obj.label}
              </Link>
            );
          })}
          style={{ lineHeight: "26px" }}
        />
        {runId && configUserInputs.configType === "run" && (
          <div className="copy-run-id">
            <span
              className="material-icons-outlined"
              style={{ fontSize: "16px" }}
              title={`Click to copy AIDE/RUN ID "${runId}"`}
              onClick={() => {
                copyValueToClipboard(runId);
                updateAlertInfo({
                  open: true,
                  message: `AIDE/RUN ID "${runId}" is copied to clipboard.`,
                  severity: "success",
                });
              }}
            >
              content_copy
            </span>
          </div>
        )}
        {runId &&
          configUserInputs.configType === "run" &&
          (user.selections.activeRunPayload.status === "Running" ||
            user.selections.activeRunPayload.status === "Scheduled") &&
          user.preferred_username ===
            user.selections.activeRunPayload.created_by && (
            <div className="copy-run-id">
              <span
                className="material-icons-outlined"
                style={{
                  fontSize: "16px",
                  // pointerEvents: `${
                  //   user.selections.activeRunPayload.status === "Cancelled" ||
                  //   cancel
                  //     ? "none"
                  //     : "auto"
                  // }`,
                }}
                title={`Click to Cancel "${runId}"`}
                onClick={() => {
                  updateDialogInfo({
                    message: config.messages.confirmCancel,
                    open: true,
                    handleYes: () => {
                      handleCancel(runId);
                    },
                    handleNo: handleNo,
                  });
                }}
              >
                cancel
              </span>
            </div>
          )}
      </div>
    </>
  );
}

Breadcrumbs.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  configUserInputs: state.configUserInputs,
  allData: state.data,
});
const mapDispatchToProps = {
  updateAlertInfo,
  updateSelections,
  updateDialogInfo,
  updateConfigUserInputs,
  updateRunCancelStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
