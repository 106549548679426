//Import required libraies
import React, { useState } from "react";

//Import Custom Component
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { Chip, Tooltip } from "../../../../uiTheme";
import {
  StyledAutoComplete,
  StyledCheckbox,
} from "../../../../../styles/styles";

// Import utils & data
import { config } from "../../../../config/config";

export const CssTextField = withStyles({
  root: {
    width: "97%",
    padding: "2px 5px",
    borderRadius: "4px",
    // border: "1px solid #dedede",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:hover": {
      borderBottomColor: "red",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    pointerEvents: "auto",
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
      maxWidth: "185px !important",
      pointerEvents: ({ configUserInputs }) =>
        configUserInputs.configType === "run" ? "none" : "auto",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFormControl-fullWidth": {
      width: ({ width }) => width || 225,
      minWidth: "250px",
      // background: "white",
      boxSizing: "border-box",
      borderRadius: "5px",
      marginTop: 5,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri !important",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-option": {
      backgrounColor: "#46596a3b",
      fontFamily: "Hind Siliguri !important",
    },
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
      pointerEvents: "none",
    },
    "& .MuiAutocomplete-inputRoot": {
      minHeight: "48px",
    },
    "& .MuiAutocomplete-endAdornment": {
      pointerEvents: ({ configUserInputs }) =>
        configUserInputs.configType === "run" ? "none" : "auto",
    },
  },
  noDataSelect: {
    height: "48px",
  },
  popover: {
    pointerEvents: "none",
    fontFamily: "Hind Siliguri",
    fontSize: 12,
    width: "800px",
    margin: "10px",
  },
  root: {
    marginTop: "10px",
    fontFamily: "Hind Siliguri !important",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));
function CollinearVariable(props) {
  let {
    // value,
    onChange,
    row,
    updateConfig,
    selRegionGranularity,
    regionGranularityValue,
    activeModel,
    configUserInputs,
  } = props;
  const classes = useStyles(props);
  return (
    <div className="collinear-container">
      <>
        <label
          className="bold header-text"
          style={{ display: "flex", alignItems: "center" }}
        >
          Select Collinear Variables To Drop:{" "}
          <Tooltip
            title={config.messages.collinearVariableSelect}
            placement="right-start"
          >
            <i
              className="material-icons-outlined info-icon"
              style={{
                position: "relative",
                top: "3px",
                pointerEvents: "all",
              }}
            >
              info
            </i>
          </Tooltip>
        </label>

        {Object.entries(row).map((el) => el).length ? (
          <StyledAutoComplete
            multiple
            getOptionSelected={(option, value) => {
              return option[0] === value;
            }}
            id="fixed-tags-demo"
            limitTags={2}
            // disableClearable
            value={row?.files?.collinear_variables_to_drop || []}
            disableCloseOnSelect
            disabled={
              configUserInputs.configType === "run"
                ? !row?.files?.collinear_variables_to_drop?.length
                : false
            }
            onChange={(e, value) => {
              if (configUserInputs.configType !== "run") {
                if (selRegionGranularity?.toLowerCase() === "national") {
                  updateConfig("region_granularity", {
                    ...regionGranularityValue,
                    ...(config.hardCoded.autoMlIds.includes(activeModel.id) && {
                      test_regions: {
                        ...regionGranularityValue.test_regions,
                        files: {
                          ...row?.files,
                          collinear_variables_to_drop: value.map((el) =>
                            typeof el === "object" ? el[0] : el
                          ),
                          // row?.files?.collinear_variables_to_drop?.includes(
                          //   value
                          // )
                          //   ? row?.files?.collinear_variables_to_drop?.filter(
                          //       (el) => el !== value
                          //     )
                          //   : [
                          //       ...(row?.files?.collinear_variables_to_drop ||
                          //         []),
                          //       value,
                          //     ],
                        },
                      },
                    }),
                  });
                } else {
                  onChange("files", {
                    ...row?.files,
                    collinear_variables_to_drop: value.map((el) =>
                      typeof el === "object" ? el[0] : el
                    ),
                    // row?.files?.collinear_variables_to_drop?.includes(value)
                    //   ? row?.files?.collinear_variables_to_drop?.filter(
                    //       (el) => el !== value
                    //     )
                    //   : [
                    //       ...(row?.files?.collinear_variables_to_drop || []),
                    //       value,
                    //     ],
                  });
                }
              }
            }}
            // options={campaignsData}
            options={
              configUserInputs.configType === "run"
                ? row?.files?.collinear_variables_to_drop || []
                : Object.entries(row?.files?.collinear_drivers || {}).map(
                    (el) => el
                  )
            }
            getOptionLabel={(option) => {
              // console.log("second", option);
              return configUserInputs.configType === "run"
                ? option
                : // `${option[0]} | VIF Score : ${option[1]}` || "";
                  option[0];
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const {
                  // onDelete,
                  ...remaining
                } = getTagProps({ index });
                // console.log("third", option);
                return (
                  <div key={option}>
                    <Chip label={option} {...remaining} />
                  </div>
                );
              })
            }
            style={{
              maxWidth: "100%",
              width: "546px",
              minWidth: 220,
              border: "1px solid #dedede",
              borderRadius: 4,
              // marginRight: 15,
              // paddingLeft: 5,
              marginLeft: -5,
            }}
            size="small"
            className={classes.typographyStyle}
            renderOption={(option, { selected }) => (
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                // title={`Campaign Name: ${option.campaign} | Campaign ID: ${
                //   option.campaignid
                // } | Start Date: ${formatDateForMerged(
                //   option.campaign_start_date
                // )} | End Date: ${formatDateForMerged(
                //   option.campaign_end_date
                // )}`}
              >
                {configUserInputs.configType !== "run" && (
                  <StyledCheckbox checked={selected} />
                )}
                {configUserInputs.configType === "run"
                  ? option
                  : `${option[0]} | VIF Score : ${option[1]}`}
              </div>
            )}
            renderInput={(params) => (
              <CssTextField
                {...params}
                placeholder=""
                variant="outlined"
                size="small"
                className={classes.typographyStyle}
                style={{
                  pointerEvents:
                    configUserInputs.configType === "run" ? "none" : "all",
                }}
                onKeyPress={(e) => {
                  if (configUserInputs.configType === "run") {
                    e.preventDefault();
                  }
                }}
              />
            )}
          />
        ) : (
          <div className="input-item-label-less">
            <Select value="No Collinear Drivers" disabled={true}>
              <MenuItem value="No Collinear Drivers">
                No Collinear Drivers
              </MenuItem>
            </Select>
          </div>
        )}
      </>
    </div>
  );
}

export default CollinearVariable;
