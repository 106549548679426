//Import required libraies
import React, { useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data
import { config } from "../../../../config/config";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
}));

function InputNumberValidation(props) {
  let {
    id,
    name,
    enable,
    value,
    minValue,
    maxValue,
    onChange,
    customClassName,
    step,
    onKeyPress,
    showTooltip,
  } = props;
  const [error, setError] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  useEffect(() => {
    let maxVal = maxValue == "null" ? Number.MAX_VALUE : parseFloat(maxValue);
    let minVal = minValue == "null" ? Number.MIN_VALUE : parseFloat(minValue);
    if (
      parseFloat(value) < parseFloat(minVal) ||
      parseFloat(value) > parseFloat(maxVal) ||
      isNaN(value) ||
      value === ""
    ) {
      if (
        id === "warm" ||
        id === "cold" ||
        id === "no_of_similar_regions" ||
        id === "corr_upper_limit" ||
        id === "number_of_cells"
      )
        setError(false);
      else setError(true);
    } else {
      setError(false);
    }
  }, [value]);

  const handleDecimalChange = (e) => {
    if (id === "no_of_similar_regions" || id === "number_of_cells") {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode === 8 ||
          (id === "no_of_similar_regions" && e.keyCode === 190)
        )
      ) {
        e.preventDefault();
      }
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={`input-item ${customClassName ? customClassName : ""} ${id}`}
    >
      {showTooltip ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <label title={name}>{name}:</label>
          <i
            className="material-icons-outlined info-icon"
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            style={{ marginBottom: "5px" }}
          >
            {" "}
            info{" "}
          </i>
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            disableRestoreFocus
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <p className={classes.typography}>
              {config.messages.salesCoverageMessage}
            </p>
          </Popover>
        </div>
      ) : (
        <label title={name}>{name}:</label>
      )}
      <input
        type="number"
        onWheel={() => document.activeElement.blur()}
        disabled={!enable}
        value={isNaN(value) ? "" : value}
        min={minValue}
        max={maxValue}
        onChange={(e) => {
          onChange(id, parseFloat(e.target.value));
        }}
        onKeyPress={onKeyPress}
        onKeyDown={(e) => handleDecimalChange(e)}
        step={
          id === "no_of_similar_regions" || id === "number_of_cells"
            ? 1
            : id === "min_ci"
            ? 0.01
            : config.hardCoded.stepperInRbt.includes(id)
            ? 0.1
            : step
            ? step
            : "any"
        }
        className={
          id === "no_of_similar_regions" || id === "number_of_cells"
            ? "similar_regions"
            : ""
        }
      />
      {enable && (
        <span>
          <label className="input_info">min: {minValue}</label>
          {!(id === "no_of_similar_regions" || id === "number_of_cells") && (
            <label className="input_info"> | Max: {maxValue}</label>
          )}
        </span>
      )}
      {error && <p className="error">{config.inputNumberValidationError}</p>}
    </div>
  );
}

export default InputNumberValidation;
