// Import required libraries
import React from "react";
import { Route } from "react-router-dom";

function UnauthorizedRoute(props) {
  const { path, component: Component } = props;

  return (
    <Route
      path={path}
      render={(ownProps) => (
        <Component location={props.location} match={ownProps.match} />
      )}
    />
  );
}

export default UnauthorizedRoute;
