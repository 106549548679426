// Import required libraries
import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

//Import custom component
import ShareConfigModelContent from "../ShareConfiguration/ShareConfigModelContent";
import CloneConfiguration from "../CloneConfiguration/CloneConfiguration";
import Modal from "../Modal/Modal";
import ScheduleConfigContainer from "../../components/ScheduleConfig/ScheduleConfigContainer";
import AddRunName from "../AddRunName/AddRunName";

// Import utils
import { config as appConfig } from "../../config/config";
import { makeDefaultResponseJson } from "../../utils/utils";

// Import action creators
import {
  updateAlertInfo,
  updateUserInfo,
  updateSelections,
  updateDialogInfo,
  reloadRunData,
  refreshData,
  reloadConfigs,
} from "../../redux/actions";

// Import styles
import "./PopOver.scss";

const useStyles = makeStyles((theme) => ({
  popover: {
    fontSize: "12px",
    color: "#46596a",
    fontFamily: "Hind Siliguri",
    fontWeight: 500,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "white",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "white",
      },
    },
  },
  disable: {
    fontSize: "12px",
    color: "#46596a",
    // textTransform: "uppercase",
    fontFamily: "Hind Siliguri",
    pointerEvents: "none",
    "&:hover": { backgroundColor: "transparent" },
  },
  toastTheme: {
    // backgroundColor: "#f0f2f4",
    color: "#46596a",
  },
  contained: {
    color: "white",
    backgroundColor: "#46596a",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
  },
  outlined: {
    borderColor: "#46596a",
    color: "#46596a",
  },
  paperWidthSm: {
    width: "200px",
  },
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

function PopOver(props) {
  let {
    user,
    match,
    name,
    configName,
    updateAlertInfo,
    updateUserInfo,
    updateSelections,
    updateDialogInfo,
    reloadRunData,
    refreshData,
    reloadConfigs,
    config,
    allData,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  let app = appConfig.hardCoded.mroiAppId.toLowerCase();
  let activeApp =
    allData.apps.data.length > 0
      ? allData.apps.data.find(
          (obj) => obj.name.toLowerCase() === app.toLowerCase()
        )
      : null;
  const [modalComponent, setModalComponent] = useState({
    component: null,
    openModal: false,
    handleCancel: () => {
      console.log("handleCancel");
    },
    handleDone: () => {
      console.log("handleDone");
    },
  });

  const handleDialogOpen = (component) => {
    setModalComponent((prevState) => {
      return {
        ...prevState,
        openModal: true,
        component: component,
      };
    });
  };

  const handleDialogClose = () => {
    setModalComponent((prevState) => {
      return { ...prevState, openModal: false };
    });
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNo = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const handleRun = (configUserInputs, allData) => {
    handleClose();
    updateAlertInfo({
      open: true,
      message: "Requesting run...",
      severity: "info",
    });
    let url = appConfig.api.configRunUrl;
    let status;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: config.id,
        app_id: appConfig.hardCoded.mroiAppId,
        run_name: `${configUserInputs?.configFixedRunName}${configUserInputs?.run_name}`,
      }),
    })
      .then((response) => {
        console.groupCollapsed("REQUESTING RUN...", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "success",
          });
          refreshData();
          reloadRunData();
          reloadConfigs();
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  const handleDelete = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
    updateAlertInfo({
      open: true,
      message: appConfig.configDeleteRequestMessage,
      severity: "info",
    });
    let url = `${appConfig.api.configUrl}?config_id=${config.id}&app_id=${activeApp?.id}`;
    let status;
    fetch(url, {
      method: "DELETE",
    })
      .then((response) => {
        console.groupCollapsed("DELETE REQUEST SENT...", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          updateAlertInfo({
            open: true,
            message: appConfig.configSuccessDeleteMessage,
            severity: "success",
          });
          history.push(`/home/${match.params.tab}/`);
          reloadConfigs();
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };

  // useEffect(() => {
  //   console.log("popover mount/update");
  //   return () => {
  //     console.log("popover unmount");
  //   };
  // }, []);

  // console.log("modalComponent", modalComponent);

  const navigateToDetails = ({ openRecordError, name }) => {
    if (openRecordError && openRecordError.trim()) {
      updateAlertInfo({
        open: true,
        message: openRecordError,
        severity: "info",
      });
      return "failed";
    }
  };

  const navigateToDetailsForLink = ({ openRecordError, name }) => {
    if (openRecordError && openRecordError.trim()) {
      return "failed";
    }
  };

  return (
    <>
      <i
        className="material-icons more-vert-icon-style"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        more_vert
      </i>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <Link
          className={`disabled-style menu-item`}
          to={
            navigateToDetailsForLink(config) === "failed"
              ? "#"
              : user.selections.appView == "table"
              ? `/home/${match.params.tab}/${configName}`
              : `/home/${match.params.tab}/${name}`
          }
        >
          <MenuItem
            className={classes.popover}
            onClick={() => {
              if (navigateToDetails(config) === "failed") {
                return;
              }
              handleClose();
            }}
          >
            Edit
          </MenuItem>
        </Link>
        <MenuItem
          className={classes.popover}
          onClick={(e) => {
            if (navigateToDetails(config) === "failed") {
              return;
            }
            handleClose();
            handleDialogOpen(
              <CloneConfiguration
                {...props}
                handleDialogClose={handleDialogClose}
              />
            );
          }}
        >
          Make a Copy
        </MenuItem>
        {/* <MenuItem
          className={classes.popover}
          onClick={() => {
            if (navigateToDetails(config) === "failed") {
              return;
            }
            handleClose();
            handleDialogOpen(
              <AddRunName
                handleDialogClose={handleDialogClose}
                total_runs={config?.total_runs}
                handleSaveRun={handleRun}
              />
            );
          }}
        >
          Run
        </MenuItem> */}
        <MenuItem
          className={classes.popover}
          onClick={() => {
            if (navigateToDetails(config) === "failed") {
              return;
            }
            handleClose();
            updateDialogInfo({
              message: appConfig.messages.confirmDelete,
              open: true,
              handleYes: handleDelete,
              handleNo: handleNo,
            });
          }}
        >
          Delete
        </MenuItem>
        {allData.appAccessDetails?.feature_list?.schedule_run &&
          !config.selections.config.is_config_manual &&
          (match.params.tab === "myconfigurations" ||
            match.params.tab === "starred") &&
          config?.owned_by === user?.preferred_username && (
            <MenuItem
              className={classes.popover}
              onClick={(e) => {
                if (navigateToDetails(config) === "failed") {
                  return;
                }
                handleClose();
                handleDialogOpen(
                  <ScheduleConfigContainer
                    activeApp={activeApp}
                    handleDialogClose={handleDialogClose}
                    config={config}
                    match={match}
                  />
                );
              }}
            >
              {config?.schedule_details ? "Schedule Details" : "Schedule"}
            </MenuItem>
          )}
        <MenuItem
          className={classes.popover}
          onClick={() => {
            if (navigateToDetails(config) === "failed") {
              return;
            }
            handleClose();
            updateSelections("runFilters", {
              ...user.selections.runFilters,
              searchText: config.id,
              dayCat: "all",
            });
            //TO DO
            //Can find a better alternative to setTimeout
            //Place the depen on a mounted useEffect
            //Perhaps in AIDEAppContainer
            setTimeout(() => history.push(`/home/viewruns`), 0);
          }}
        >
          View Runs
        </MenuItem>
        {!config.selections.config.is_config_manual && (
          <MenuItem
            className={classes.popover}
            onClick={(e) => {
              if (navigateToDetails(config) === "failed") {
                return;
              }
              handleClose();
              handleDialogOpen(
                <ShareConfigModelContent
                  handleDialogClose={handleDialogClose}
                  config={config}
                />
              );
            }}
          >
            Share
          </MenuItem>
        )}
        <Modal
          modalComponent={modalComponent}
          setModalComponent={setModalComponent}
          handleDialogClose={handleDialogClose}
        />
      </Menu>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  saved_configurations: state.data.saved_configurations,
  allData: state.data,
});

const mapDispatchToProps = {
  updateAlertInfo,
  updateUserInfo,
  updateSelections,
  updateDialogInfo,
  reloadRunData,
  refreshData,
  reloadConfigs,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopOver);
