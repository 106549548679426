import breadcrumbsMapping from "../../assets/data/breadcrumbsMapping.json";
import sidebarFunction from "../../assets/data/sidebarFunction.json";
import sidebarApp from "../../assets/data/sidebarApp.json";
import functionIconMapping from "../../assets/data/functionIconMapping.json";
import datasetCategories from "../../assets/data/datasetCategories.json";
import { v4 } from "uuid";
import moment from "moment";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import isEqual from "lodash/isEqual";
import { config } from "../config/config";
import momentTZ from "moment-timezone";
import { isEmpty } from "lodash";

// client side timezone
export const defaultTimeZone = () => momentTZ.tz.guess();

// Get current year
export const getCurrentYear = () => moment().year();

// get timezone offset
export const getTimezoneOffset = (timeZone) =>
  "UTC" + moment().tz(timeZone).format("Z") + " " + timeZone;

// get all TZ data
export const getTZData = () => momentTZ.tz.names();

export const splitStringInArray = (input) => input.split`,`.map((x) => x);

export const validAudienceName = (input) => {
  const regex = new RegExp("^[A-Za-z0-9_]+$");
  return regex.test(input);
};

export const splitUrlToBreadcrumbData = (url) => {
  let routes = url.replace(/^\//g, "").replace(/\/$/g, "").split("/");
  let data = routes.map((name, index) => ({
    name,
    label: breadcrumbsMapping[name] ? breadcrumbsMapping[name] : name,
    path: routes
      .slice(0, index + 1)
      .reduce((finalPath, route) => finalPath + route + "/", "/"),
  }));
  return data;
};

export const addIconDetails = (obj) => ({
  name: obj.name,
  label: breadcrumbsMapping[obj.id.toLowerCase()],
  specialStyling: false,
  ...functionIconMapping[obj.id.toLowerCase()],
});

export const filterSalesDatasetRegions = (
  globalData,
  selectedRegionGranType,
  configUserInputsValue,
  activeModelId,
  is_config_manual
) => {
  const globalDataFilter =
    !is_config_manual &&
    globalData !== -1 &&
    globalData?.find((e) => e.id === selectedRegionGranType)?.regions;
  if (
    configUserInputsValue?.region_granularity?.test_control_regions
      ?.control instanceof Array
  ) {
    configUserInputsValue?.region_granularity?.test_control_regions?.control?.map(
      (obj) => {
        if (
          obj?.regions &&
          obj?.regions instanceof Array &&
          globalData !== -1
        ) {
          obj.regions = obj?.regions?.filter((value) =>
            is_config_manual
              ? globalData[0][selectedRegionGranType]?.includes(value)
              : globalDataFilter?.includes(value)
          );
        }
      }
    );
  }
  if (
    configUserInputsValue?.region_granularity?.test_dark_regions
      ?.dark instanceof Array
  ) {
    configUserInputsValue?.region_granularity?.test_dark_regions?.dark?.map(
      (obj) => {
        if (
          obj?.regions &&
          obj?.regions instanceof Array &&
          globalData !== -1
        ) {
          obj.regions = obj?.regions?.filter((value) =>
            is_config_manual
              ? globalData[0][selectedRegionGranType]?.includes(value)
              : globalDataFilter?.includes(value)
          );
        }
      }
    );
  }
  // if (config.hardCoded.autoMlIds.includes(activeModelId)) {
  if (
    configUserInputsValue?.region_granularity?.test_regions?.test instanceof
    Array
  ) {
    configUserInputsValue?.region_granularity?.test_regions?.test?.map(
      (testObj) => {
        if (
          testObj?.regions &&
          testObj?.regions instanceof Array &&
          globalData !== -1
        ) {
          testObj.regions = testObj?.regions?.filter((val) =>
            is_config_manual
              ? globalData[0][selectedRegionGranType]?.includes(val)
              : globalDataFilter?.includes(val)
          );
        }
      }
    );
  }
  // } else {
  if (
    configUserInputsValue?.region_granularity?.test_control_regions
      ?.test instanceof Array
  ) {
    configUserInputsValue?.region_granularity?.test_control_regions?.test?.map(
      (testObj) => {
        if (
          testObj?.regions &&
          testObj?.regions instanceof Array &&
          globalData !== -1
        ) {
          testObj.regions = testObj?.regions?.filter((val) =>
            is_config_manual
              ? globalData[0][selectedRegionGranType]?.includes(val)
              : globalDataFilter?.includes(val)
          );
        }
      }
    );
  }
  if (
    configUserInputsValue?.region_granularity?.test_dark_regions
      ?.test instanceof Array
  ) {
    configUserInputsValue?.region_granularity?.test_dark_regions?.test?.map(
      (testObj) => {
        if (
          testObj?.regions &&
          testObj?.regions instanceof Array &&
          globalData !== -1
        ) {
          testObj.regions = testObj?.regions?.filter((val) =>
            is_config_manual
              ? globalData[0][selectedRegionGranType]?.includes(val)
              : globalDataFilter?.includes(val)
          );
        }
      }
    );
  }
  // }
  return configUserInputsValue?.region_granularity;
};

export const makeSidebarData = () => {
  let sidebarData = [...sidebarApp];
  sidebarData = sidebarData.map((obj) => ({ ...obj, key: v4() }));
  return sidebarData;
};

export const convertEpochToDate = (date, format = "MMM DD, YYYY") =>
  moment(+date).format(format);

export const getCurrentDate = () => moment().format("YYYY-MM-DD");

export const getYearsAgoDate = () =>
  moment().subtract(1, "years").format("YYYY-MM-DD");

export const populateFiltersData = (fitlersData, data) => {
  let updFiltersData = fitlersData.map((obj) => ({
    ...obj,
    key: v4(),
    data: data[obj.name] ? data[obj.name] : null,
  }));
  return updFiltersData;
};

//filter saved_configuraions based on user selections
export const filterMyConfigs = (
  arr = [],
  searchKey,
  selections,
  configFlag
) => {
  let filteredArr,
    filtersObj,
    config = {};
  let validFilters = ["country", "brand"];

  for (let key in selections) {
    if (selections[key] !== "ALL" && validFilters.includes(key)) {
      config[key] = selections[key];
    }
  }

  if (configFlag) {
    filtersObj = {
      ...filtersObj,
      selections: {
        config,
      },
    };
  } else {
    filtersObj = {};
  }

  filteredArr = filter(arr, filtersObj);
  if (selections.segment !== "ALL") {
    filteredArr = filteredArr.filter((obj) =>
      obj.selections.config.segment.includes(selections.segment)
    );
  }
  // Tags are filtered as lodash is not supporting array filtering
  if (selections.configTags.length) {
    filteredArr = filteredArr.filter((obj) =>
      obj.metadata?.tags?.find((elem) =>
        selections.configTags.some((data) => data === elem)
      )
    );
  }

  if (selections.searchText !== "") {
    filteredArr = filteredArr.filter((obj) => {
      return obj[searchKey]
        .toLowerCase()
        .includes(selections.searchText.toLowerCase());
    });
  }
  return filteredArr;
};
export const salesMediaFilterPayload = (
  configUserInputs,
  allData,
  regionFlag
) => {
  // const filterLatestData = (userInputKey, allDataKey) =>
  //   removeAllInData(configUserInputs[userInputKey]).filter((el) =>
  //     removeAllInData(createFilterData(allData[allDataKey])).includes(el)
  //   );
  const getFilterData = (userInputKey, allDataKey) => {
    return configUserInputs.is_config_manual ||
      (configUserInputs.configType === "add" &&
        !configUserInputs.datasetDetailsFlag &&
        !regionFlag)
      ? []
      : configUserInputs[`${userInputKey}_select_all`]
      ? removeAllInData(createFilterData(allData[allDataKey]))
      : userInputKey.includes("media")
      ? !removeAllInData(configUserInputs[userInputKey]).length &&
        !configUserInputs[`${userInputKey}_select_all`]
        ? removeAllInData(createFilterData(allData[allDataKey]))
        : configUserInputs[userInputKey]
      : configUserInputs[userInputKey];
  };
  const filterObj = {
    sub_brand: getFilterData("sub_brand", "subBrand"),
    segment: getFilterData("segment", "segments"),
    sub_segment: getFilterData("sub_segment", "subSegment"),
    media_sub_brand: getFilterData("media_sub_brand", "mediaSubBrand"),
    media_segment: getFilterData("media_segment", "mediaSegments"),
    media_sub_segment: getFilterData("media_sub_segment", "mediaSubSegment"),
  };
  return filterObj;
};
export const addAllInData = (arr) => [
  { id: "Select ALL", name: "Select ALL" },
  ...arr,
];
export const salesMediaFilterFlag = (allData) =>
  allData.subBrand.status === "success" &&
  allData.segments.status === "success" &&
  allData.subSegment.status === "success" &&
  allData.mediaSubBrand.status === "success" &&
  allData.mediaSegments.status === "success" &&
  allData.mediaSubSegment.status === "success";
export const removeAllInData = (arr) =>
  arr.filter((el) => el !== config.hardCoded.allSelection);
export const getRegionsData = (
  globalRegions,
  countrySelected,
  regionGranSelected,
  is_config_manual
) => {
  if (is_config_manual) {
    let regionsData;
    let regionsDataFiltered = globalRegions.filter(
      (obj) => obj.country?.toLowerCase() === countrySelected?.toLowerCase()
    );
    if (regionsDataFiltered.length) {
      regionsData = regionsDataFiltered[0][regionGranSelected.toLowerCase()];
    }
    return regionsData;
  } else {
    let regionsDataFiltered = globalRegions?.filter(
      (obj) => obj?.id?.toLowerCase() === regionGranSelected?.toLowerCase()
    );
    return regionsDataFiltered[0]?.regions;
  }
};

export const addAllNode = (data, config) => {
  let key = data[0].labelCat;

  //Add "All" node
  if (config[key]) {
    data = [{ name: "ALL", labelCat: key, children: null }, ...data];
  }

  data = data.map((obj) => ({
    ...obj,
    children: obj.children
      ? obj.children.length !== 0
        ? addAllNode(obj.children, config)
        : null
      : null,
  }));
  return data;
};

// to get dataset id when in "<dataset_id>-<country>-<brand>" format
export const getDatasetId = (datasetId) => {
  datasetId = datasetId.split("-");
  let resString =
    datasetId.length > 3 ? datasetId[0] + "-" + datasetId[1] : datasetId[0];
  return resString;
};

//removes duplicate objects based on id
export const removeDupFromArrayOfObjects = (arr) => {
  const ids = arr.map((obj) => obj.id);
  const filteredArr = arr.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  );
  return filteredArr;
};

export const removeDupFromArray = (arr) => {
  let newArr = [];
  arr.forEach((val) => {
    newArr.indexOf(val) === -1 && newArr.push(val);
  });
  return newArr;
};

export const convertArrToLowerCase = (arr) =>
  arr.map((val) => val.toLowerCase());

export const getConfigName = (configName) =>
  configName
    ? configName.replace(/^[A-z ]+\-[A-z ]+\-[A-z ]+\-/g, "")
    : undefined;

export const getManualConfigName = (configName, brandAvailable) =>
  brandAvailable
    ? configName
      ? configName.replace(/^[A-z ]+\-[A-z ]+\-[A-z ]+\-/g, "")
      : undefined
    : configName
    ? configName.replace(/^[A-z ]+\-[A-z ]+\-/g, "")
    : undefined;

export const checkDatesOverlapping = (
  global_start_date,
  global_end_date,
  local_start_date,
  local_end_date,
  dateFormate = "DDMMYYYY"
) => {
  const completeOverlap =
    moment(global_start_date).isSameOrAfter(
      moment(local_start_date, dateFormate)
    ) &&
    moment(global_end_date).isSameOrBefore(moment(local_end_date, dateFormate));

  const datasetPartialOverlap1 =
    moment(global_start_date).isBefore(moment(local_start_date, dateFormate)) &&
    moment(global_end_date).isSameOrAfter(
      moment(local_start_date, dateFormate)
    ) &&
    moment(global_end_date).isSameOrBefore(moment(local_end_date, dateFormate));

  const datasetPartialOverlap2 =
    moment(global_end_date).isAfter(moment(local_end_date, dateFormate)) &&
    moment(global_start_date).isSameOrAfter(
      moment(local_start_date, dateFormate)
    ) &&
    moment(global_start_date).isSameOrBefore(
      moment(local_end_date, dateFormate)
    );

  const globalPartialOverlap1 =
    moment(global_start_date).isSameOrBefore(
      moment(local_start_date, dateFormate)
    ) &&
    moment(global_end_date).isSameOrAfter(moment(local_end_date, dateFormate));

  if (!local_start_date && !local_end_date) {
    return {
      type: null,
      status: true,
    };
  } else if (completeOverlap) {
    return {
      type: "complete overlap",
      status: true,
    };
  } else if (datasetPartialOverlap1) {
    return {
      type: "partial overlap",
      status: true,
    };
  } else if (datasetPartialOverlap2) {
    return {
      type: "partial overlap",
      status: true,
    };
  } else if (globalPartialOverlap1) {
    return {
      type: "partial overlap",
      status: true,
    };
  } else {
    return {
      type: null,
      status: false,
    };
  }
};

export const mandatoryKpiSelectionHandler = (dataset, activeModel, kpi) => {
  let newDataset = { ...dataset };
  const findDataset = activeModel.dataset_ids.find((e) => e.id === dataset.id);
  const filterMandatoryKpiParam = findDataset.mandatory_kpi_list.reduce(
    (acc, curr) => {
      acc.push({
        id: curr,
        kpi_parameters: Object.entries(
          findDataset.kpi_variables_list[curr]
        ).map(([key, value]) => {
          return {
            id: key,
            ...value,
          };
        }),
      });
      return acc;
    },
    []
  );
  newDataset = {
    ...newDataset,
    kpi_list: findDataset?.mandatory_kpi_list.includes(kpi)
      ? [
          ...newDataset?.kpi_list,
          ...findDataset.mandatory_kpi_list.filter((elem) => elem !== kpi),
        ]
      : [...newDataset?.kpi_list, ...findDataset.mandatory_kpi_list],
    kpi_variables_list: findDataset?.mandatory_kpi_list.includes(kpi)
      ? [
          ...newDataset?.kpi_variables_list,
          ...filterMandatoryKpiParam?.filter((elem) => elem.id !== kpi),
        ]
      : [...newDataset?.kpi_variables_list, ...filterMandatoryKpiParam],
  };
  return newDataset;
};

export const mandatoryKpiMergeDatasetHandler = (
  dataset,
  kpi,
  intersectedMandatoryKpi
) => {
  let newDataset = { ...dataset };
  const intersectedMandatoryKpiArr = intersectedMandatoryKpi || [];
  newDataset = {
    ...newDataset,
    kpi_list: intersectedMandatoryKpiArr.includes(kpi)
      ? [
          ...newDataset?.kpi_list,
          ...intersectedMandatoryKpiArr.filter((elem) => elem !== kpi),
        ]
      : [...newDataset?.kpi_list, ...intersectedMandatoryKpiArr],
  };
  return newDataset;
};

export const manipulateDataset = ({
  dataset,
  kpi,
  singleKpiFlag,
  key,
  value,
  activeModel,
  kpiListFlag,
  kpiList,
  addAllKpi,
  activeDatasetForKpi,
  activeDatasetDates,
  kpi_variables_list,
  modelId,
  dataset_list,
  is_merge_dataset,
  typeOfDataset,
  intersectedMandatoryKpi,
}) => {
  let newDataset = {
    id: dataset?.id,
    name: dataset?.name,
    kpi_list: [],
    kpi_variables_list: kpi_variables_list,
    type: dataset?.type,
    source: dataset?.source,
    pipeline_dataset_id: dataset?.pipeline_dataset_id,
  };
  // If merge datasets are manipulated
  if (is_merge_dataset) {
    newDataset = {
      merge_dataset: true,
      id: dataset?.id,
      name: dataset?.name,
      channel: dataset?.channel || "",
      dataset_list: dataset_list,
      kpi_list: [],
      kpi_variables_list: kpi_variables_list,
      pipeline_dataset_id: dataset?.pipeline_dataset_id,
      type: typeOfDataset,
    };
  }

  let mandatoryKpi = [],
    defaultKpi = [];
  if (activeDatasetForKpi) {
    mandatoryKpi = [...activeDatasetForKpi?.mandatory_kpi_list] || [];
    defaultKpi = [...activeDatasetForKpi?.default_value] || [];
  }

  if (config.hardCoded.radioDatasetList.includes(dataset?.type)) {
    newDataset = { ...newDataset, sku: [{ sku: "ALL" }] };
  }

  if (
    activeDatasetDates &&
    dataset?.type === config.hardCoded.datasetSalesType
  ) {
    newDataset = { ...newDataset, periodicity: activeDatasetDates.periodicity };
  } else {
    newDataset = { ...newDataset };
  }

  //Add local parameters
  dataset?.local_parameters?.forEach((obj) => {
    if (obj.id === "adstock_parameters") {
      newDataset[obj.id] = {};
      if (obj.data) {
        if (dataset.pipeline_dataset_id == config.hardCoded.tvId) {
          newDataset.enable_adstock = newDataset.enable_adstock
            ? newDataset.enable_adstock
            : true;
          newDataset.sl_limit = 2;
          newDataset.su_limit = 4;
          newDataset.limit = 1;
          newDataset.saturation_flag = "false";
        } else {
          obj.data.forEach((item) => {
            if (
              config.hardCoded.googleAdsIds.some(
                (elem) => elem.toLowerCase() === dataset.id.toLowerCase()
              )
            ) {
              newDataset.enable_adstock = newDataset.enable_adstock
                ? newDataset.enable_adstock
                : false;
              if (item.ui_element_type === "inputbox_number") {
                newDataset[item.id] = item.value ? item.value : 0;
              } else if (item.ui_element_type === "radio_button") {
                newDataset[item.id] = item.data ? item.data[0].id : null;
              }
            } else {
              newDataset.enable_adstock = newDataset.enable_adstock
                ? newDataset.enable_adstock
                : true;
              if (item.ui_element_type === "inputbox_number") {
                newDataset[item.id] = item.value ? item.value : 0;
              } else if (item.ui_element_type === "radio_button") {
                newDataset[item.id] = item.data ? item.data[0].id : null;
              }
            }
          });
        }
      }
    } else if (
      obj.id === "top_n_competitor" &&
      config.hardCoded.autoMlIds.some(
        (elem) => elem.toLowerCase() !== modelId.toLowerCase()
      )
    ) {
      return null;
    } else {
      newDataset[obj.id] = obj.data ? obj.data[0].id : null;
    }
  });

  //If key present, update the key/value pair, else update the kpi list
  if (key) {
    newDataset = { ...newDataset, [key]: value };
  }
  newDataset["kpi_list"] = kpi
    ? singleKpiFlag
      ? removeDupFromArray([...mandatoryKpi, kpi])
      : removeDupFromArray([...dataset.kpi_list, ...mandatoryKpi, kpi])
    : removeDupFromArray([...mandatoryKpi, ...defaultKpi]);

  if (kpiListFlag) {
    return { ...newDataset, kpi_list: kpiList };
  }

  if (addAllKpi) {
    newDataset["kpi_list"] = kpiList;
  }
  if (
    activeModel &&
    activeModel.id === config.hardCoded.autoMlBudgetId &&
    dataset.type === "MEDIA"
  ) {
    newDataset = mandatoryKpiSelectionHandler(newDataset, activeModel, kpi);
  }
  if (intersectedMandatoryKpi && intersectedMandatoryKpi.length > 0) {
    newDataset = mandatoryKpiMergeDatasetHandler(
      { ...newDataset },
      kpi,
      intersectedMandatoryKpi
    );
  }
  return newDataset;
};

export const addAllNodeAndSortSingleTree = (
  data,
  config,
  orderType = "asc"
) => {
  let key = data.length ? data[0].labelCat : "";

  //Add "All" node
  if (key && config[key]) {
    data = [
      { name: "ALL", labelCat: key, children: null },
      ...orderBy(data, ["name"], [orderType]),
    ];
  } else {
    data = orderBy(data, ["name"], [orderType]);
  }

  data = data.map((obj) => ({
    ...obj,
    children: obj.children
      ? obj.children.length !== 0
        ? addAllNodeAndSortSingleTree(obj.children, config)
        : null
      : null,
  }));
  return data;
};

export const addAllNodeAndSort = (props) => {
  let { data, settings, orderType } = props;
  let key = data[0].labelCat;

  if (settings[key]?.addAllNodeAndSortFlag) {
    data = [
      {
        name: "ALL",
        labelCat: key,
        children: null,
      },
      ...orderBy(
        data.filter((item) => item.name !== "ALL"),
        ["name"],
        [orderType]
      ),
    ];
  } else {
    data = orderBy(data, ["name"], [orderType]);
  }

  data = data.map((obj) => {
    //brand (airwick)
    //If children is null
    let newChildren;
    if (!settings[key]?.child) {
      //if this is sub-segment which does not have any child
      newChildren = null;
    } else if (obj.children) {
      //if we have atleat one child
      newChildren = addAllNodeAndSort({
        data: obj.children,
        settings: settings,
        orderType: "asc",
      });
    } else {
      //if we do not have any child, we add ALL as the child
      newChildren = addAllNodeAndSort({
        data: [
          {
            name: "ALL",
            labelCat: settings[key]?.child,
            children: null,
            visible: settings[settings[key]?.child].visible,
            type: settings[settings[key]?.child].type,
          },
        ],
        settings: settings,
        orderType: "asc",
      });
    }

    let newObj = {
      ...obj,
      visible: settings[key]?.visible,
      type: settings[key]?.type,
      children: newChildren,
    };
    return newObj;
  });
  return data;
};

export const flattenObject = (obj) => {
  const flattened = {};
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && key === "metadata") {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });
  return flattened;
};

export const splitAppsByCategory = ({ category, arr }) => {
  let updArr = [{ key: v4(), title: null, data: [] }];
  let mapping = {
    recent: "recent",
    starred: "starred",
  };
  let newCat = mapping[category.toLowerCase()]
    ? category.toLowerCase()
    : "function";
  switch (newCat) {
    case "recent":
      updArr = [{ key: v4(), title: "TODAY", data: arr }];
      return updArr;
    case "function":
      arr = arr.filter(
        (obj) => obj.function.toLowerCase() === category.toLowerCase()
      );
      updArr = [{ key: v4(), title: null, data: arr }];
      return updArr;
    default:
      return updArr;
  }
};

export const splitConfigsByCategory = ({ category, arr }) => {
  let updArr = [{ key: v4(), title: null, data: arr }];
  switch (category.toLowerCase()) {
    case "recent":
      let intArr = arr.map((item) => ({
        ...item,
        recCategory: getConfigForCategory(item.metadata.modified_on),
      }));
      updArr = [
        {
          key: v4(),
          title: "TODAY",
          data: intArr.filter((item) => item.recCategory === "TODAY"),
        },
        {
          key: v4(),
          title: "YESTERDAY",
          data: intArr.filter((item) => item.recCategory === "YESTERDAY"),
        },
        {
          key: v4(),
          title: "THIS WEEK",
          data: intArr.filter((item) => item.recCategory === "THIS WEEK"),
        },
        {
          key: v4(),
          title: "LAST WEEK",
          data: intArr.filter((item) => item.recCategory === "LAST WEEK"),
        },
        {
          key: v4(),
          title: "LAST 30 DAYS",
          data: intArr.filter((item) => item.recCategory === "LAST 30 DAYS"),
        },
      ];
      //filter out elems with no data
      updArr = updArr.filter((item) => item.data.length);
      return updArr;
    default:
      return updArr;
  }
};

export const getConfigForCategory = (date) => {
  if (
    moment().startOf("date")._d.valueOf() < date &&
    date < moment().endOf("date")._d.valueOf()
  ) {
    return "TODAY";
  } else if (
    moment().startOf("date")._d.valueOf() - 24 * 60 * 60 * 1000 < date &&
    date < moment().endOf("date")._d.valueOf() - 24 * 60 * 60 * 1000
  ) {
    return "YESTERDAY";
  } else if (
    moment().startOf("isoWeek")._d.valueOf() < date &&
    date < moment().endOf("isoWeek")._d.valueOf()
  ) {
    return "THIS WEEK";
  } else if (
    moment().startOf("isoWeek")._d.valueOf() - 7 * 24 * 60 * 60 * 1000 < date &&
    date < moment().endOf("isoWeek")._d.valueOf() - 7 * 24 * 60 * 60 * 1000
  ) {
    return "LAST WEEK";
  } else if (
    moment().startOf("day")._d.valueOf() - 30 * 24 * 60 * 60 * 1000 < date &&
    date < moment()._d.valueOf()
  ) {
    return "LAST 30 DAYS";
  }
};

export const getWeekCount = (start_date, end_date) => {
  let weekCount =
    (moment(end_date, "DDMMYYYY")._d.getTime() -
      moment(start_date, "DDMMYYYY")._d.getTime()) /
    (1000 * 60 * 60 * 24) /
    7;
  return weekCount;
};

export const getDaysFromDates = (start_date_passed, end_date_passed) => {
  let daysDiff = moment(end_date_passed, "YYYY-MM-DD").diff(
    moment(start_date_passed, "YYYY-MM-DD"),
    "days"
  );
  return daysDiff;
};
export const getWeeksFromDates = (start_date_passed, end_date_passed) => {
  let d1 = moment(start_date_passed, "YYYY-MM-DD");
  let d2 = moment(end_date_passed, "YYYY-MM-DD");
  let isoWeekday = 1;
  // figure out how many days to advance to get to the next
  // specified weekday (might be 0 if d1 is already the
  // specified weekday).
  var daysToAdd = (7 + isoWeekday - d1.isoWeekday()) % 7;
  var nextMonday = d1.clone().add(daysToAdd, "days");
  // if we are already passed the end date, there must not be any of that day in the given period.
  if (nextMonday.isAfter(d2)) {
    return 0;
  }
  // otherwise, just return the whole number of weeks
  // difference plus one for the day we already advanced to
  var weeksBetween = d2.diff(nextMonday, "weeks");
  if (d1.isoWeekday() === 1) {
    return weeksBetween + 1;
  }
  return weeksBetween + 2;
};
export const getMonthsFromDates = (start_date_passed, end_date_passed) => {
  let monthDiff = 0;
  let dateStart = moment(start_date_passed, "YYYY-MM-DD");
  let dateEnd = moment(end_date_passed, "YYYY-MM-DD").endOf("month");
  while (dateStart.isBefore(dateEnd)) {
    monthDiff++;
    dateStart = dateStart.add(1, "month");
  }
  return monthDiff;
};
export const getYearsFromDates = (start_date_passed, end_date_passed) => {
  let start_date_year = moment(start_date_passed, "YYYY-MM-DD").format("YYYY");
  let end_date_year = moment(end_date_passed, "YYYY-MM-DD").format("YYYY");
  return end_date_year - start_date_year + 1;
};
export const createFilterData = (data) => {
  return data.data.map((el) => el.name);
};
export const validateDatasetWeeksSync = (
  selectedModels,
  allDatasets,
  historic_week_data
) => {
  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);
  let selectedDatasets = selectedModels
    .map((model) => model.data_sources)
    .flat();
  let selectedDatasetsIds = selectedDatasets.map((dataset) => dataset.id);
  allDatasets = allDatasets.filter((dataset) =>
    selectedDatasetsIds.includes(dataset.id)
  );

  //calculate weeks
  allDatasets = allDatasets.map((obj) => ({
    ...obj,
    weekCount: getWeekCount(obj.start_date, obj.end_date),
  }));

  //filter datasets which are out of range
  allDatasets = allDatasets.filter(
    (dataset) => dataset.weekCount < historic_week_data
  );

  if (allDatasets.length)
    return {
      statusWeek: false,
      messageWeek: `Weeks selected is greater than weeks in ${allDatasets[0].name}. Do you want to continue?`,
    };
  return { statusWeek: true, messageWeek: "Pass" };
};

export const validateDatasetHasKpiSelected = (selectedModels, allModels) => {
  let invalidArr = [];
  //filter models that enabled
  selectedModels = selectedModels.filter((model) => model.run);

  selectedModels.forEach((model) => {
    let selectedDatasets = model.data_sources;
    selectedDatasets.forEach((dataset) => {
      if (dataset.kpi_list.length <= 0) {
        invalidArr.push({ ...dataset, modelId: model.id });
      }
    });
  });

  if (invalidArr.length) {
    return {
      status: false,
      message: ` ${invalidArr[0].id} in ${invalidArr[0].modelId} should have at least 1 KPI selected.`,
    };
  } else {
    return { status: true, message: "Pass" };
  }
};

export const validateDatasetHasTargetVariableSelected = (
  selectedModels,
  allModels
) => {
  let invalidArr = [];
  //filter models that enabled
  selectedModels = selectedModels
    .filter((model) => model.run)
    .filter((model) => config.hardCoded.autoMlRbtAndSUIds.includes(model.id));

  selectedModels.forEach((model) => {
    let selectedDatasets = model.data_sources.filter((dataset) =>
      config.hardCoded.radioDatasetList.includes(dataset.type)
    );
    selectedDatasets.forEach((dataset) => {
      if (
        !config.hardCoded.mandatoryTargetVariable.filter((el) =>
          dataset.kpi_list.find((e) => e === el)
        ).length
      ) {
        invalidArr.push({ ...dataset, modelId: model.id });
      }
    });
  });

  if (invalidArr.length) {
    return {
      status: false,
      message: `Please select at least one Target variable for ${invalidArr[0].id} in ${invalidArr[0].modelId}.`,
    };
  } else {
    return { status: true, message: "Pass" };
  }
};

export const validateMergedDatasetHasMandatoryKpiSelected = (
  selectedModels,
  allModels
) => {
  let mandatoryKpiNotSelectedArr = [];
  //filter models that enabled
  selectedModels = selectedModels.filter((model) => model.run);
  //get auto ml budget model info if it is enabled
  const selectedAutoMlBudgetModel = selectedModels.find(
    (model) => model.id === config.hardCoded.autoMlBudgetId
  );

  if (selectedAutoMlBudgetModel) {
    const autoMlBudgetModel = allModels.find(
      (model) => model.id === config.hardCoded.autoMlBudgetId
    );
    const selectedDatasets = selectedAutoMlBudgetModel.data_sources.filter(
      (dataset) => dataset.merge_dataset
    );
    // here addMandatoryKpiArr will contain the selected merge datasets with mandatory_kpi_list detail
    const addMandatoryKpiArr = selectedDatasets.map((upperDataset) => {
      const selectedDatasetList = upperDataset?.dataset_list?.map(
        (data) => data.id
      );
      const mergedMandatoryKpi = autoMlBudgetModel?.dataset_ids
        ?.filter((dataset) => selectedDatasetList?.includes(dataset.id))
        .map((el) => el.mandatory_kpi_list);
      const intersectedMandatoryKpi = intersect(...mergedMandatoryKpi);
      return { ...upperDataset, mandatory_kpi_list: intersectedMandatoryKpi };
    });
    // here we are checking if there is any mandatory kpi unselected in auto ml budget merge datasets
    // if there is a unselected kpi raise an alert
    for (const dataset of addMandatoryKpiArr) {
      const leftMandatoryKpi = dataset.mandatory_kpi_list.filter(
        (x) => !dataset.kpi_list.includes(x)
      );
      if (leftMandatoryKpi.length) {
        mandatoryKpiNotSelectedArr.push({
          name: dataset.name,
        });
      }
      if (
        mandatoryKpiNotSelectedArr.length &&
        mandatoryKpiNotSelectedArr.length !== dataset.mandatory_kpi_list.length
      ) {
        return {
          status: false,
          message: `Please select all mandatory KPIs for ${mandatoryKpiNotSelectedArr[0].name} Merge Dataset in AutoML Budget Model.`,
        };
      }
    }
  }
  return { status: true, message: "Pass" };
};

export const validateTopNCompetitor = (selectedModels, allModels) => {
  let invalidArrForTopN = [];
  //filter models that enabled
  selectedModels = selectedModels.filter((model) => model.run);
  selectedModels.map((model) => {
    let selectedDatasets = model.data_sources;
    selectedDatasets.map((dataset) => {
      if (
        config.hardCoded.autoMlIds.some(
          (elem) => elem.toLowerCase() === model.id.toLowerCase()
        ) &&
        config.hardCoded.competitorArr.includes(dataset.pipeline_dataset_id) &&
        dataset?.top_n_competitor < dataset?.minValue &&
        (dataset?.competitor_available || dataset?.nielsen_comp_available)
      ) {
        invalidArrForTopN.push({ ...dataset, modelName: model.name });
      }
    });
  });
  if (invalidArrForTopN.length) {
    return {
      status: false,
      message: `Please enter correct value in Top Competitors for ${invalidArrForTopN[0].name} in ${invalidArrForTopN[0].modelName}.`,
    };
  } else {
    return { status: true, message: "Pass" };
  }
};

export const validateModelsHasDatasetSelected = (
  selectedModels,
  allModels,
  configUserInputs,
  globalData
) => {
  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);

  //filter global datasets available for a country provided by user
  let filteredDatasets = globalData.datasets.filter((dataset) =>
    dataset.countries.includes(configUserInputs.country)
  );

  selectedModels = selectedModels.filter((model) => {
    let selectedDatasetsLen = model.data_sources.length;
    let modelInfo = allModels.find((currModel) => currModel.id === model.id);
    if (modelInfo) {
      if (modelInfo.dataset_ids.length) {
        if (selectedDatasetsLen) {
          return false;
        } else {
          //hard coded for autoMl
          if (
            model.id === config.hardCoded.autoMlId &&
            model.config.run_type === config.hardCoded.nestedRunId
          ) {
            return false;
          }
          //hard coded for Regression budget
          if (
            model.id === config.hardCoded.regBudId &&
            model.config.rbt_run_type === config.hardCoded.budgetId
          ) {
            return false;
          }
          return true;
        }
      } else {
        return false;
      }
    }
  });

  if (!filteredDatasets.length) {
    return {
      status: false,
      message: `No datasets available for ${configUserInputs.country}.`,
    };
  } else if (selectedModels.length)
    return {
      status: false,
      message: `${selectedModels[0].name} should have at least 1 dataset selected.`,
    };
  else return { status: true, message: "Pass" };
};

const validateValidCategorySelected = (
  selectedModels,
  configUserInputs,
  allModels
) => {
  let validCategories,
    invalidCategories = [],
    partialCategories = [],
    invalidCategoriesPartial = [],
    inValidDatasetsForCategoriesPartial = [];
  let user_selected_start_date = configUserInputs.startDate;
  let user_selected_end_date = configUserInputs.endDate;
  if (
    configUserInputs.global_week_date_selection ===
    config.hardCoded.historicWeek
  ) {
    let result = getDatesFromWeek(configUserInputs.historic_week_data);
    user_selected_start_date = result.min;
    user_selected_end_date = result.max;
  }
  // filtering models that are disabled and checking for selected category
  selectedModels = selectedModels.filter((model) => model.run);

  selectedModels.forEach((model) => {
    if (configUserInputs?.categoryData !== -1) {
      let categoriesArray = configUserInputs.categoryData.map((elem) => ({
        ...elem,
        categorySelected: getDatasetId(elem.name),
      }));
      let minimum_data_points_for_selectedModel = allModels?.find(
        (modelFetched) => modelFetched.id === model.id
      )?.minimum_data_points;
      let selectedCategoriesArray = categoriesArray.filter(
        (categoryFromArray) =>
          model.data_sources.some((elem) =>
            elem.category?.includes(categoryFromArray.categorySelected)
          )
      );
      selectedCategoriesArray.forEach((singleCategory) => {
        let overlap_start_date, overlap_end_date;
        let dataset_selected_start_date, dataset_selected_end_date;
        let userGlobalRangeSelected = false;
        let datasetName = "";
        if (singleCategory.start_date && singleCategory.end_date) {
          validCategories = checkDatesOverlapping(
            user_selected_start_date,
            user_selected_end_date,
            singleCategory.start_date,
            singleCategory.end_date
          );
          const { type, status } = validCategories;
          if (!type || !status) {
            invalidCategories.push({
              name: singleCategory.name,
              startDate: convertDateToFormat(
                singleCategory.start_date,
                "DD MMMM, YYYY"
              ),
              endDate: convertDateToFormat(
                singleCategory.end_date,
                "DD MMMM, YYYY"
              ),
            });
          }
          if (type === "partial overlap") {
            overlap_start_date = moment(
              user_selected_start_date,
              "YYYY-MM-DD"
            ).isBefore(moment(singleCategory.start_date, "DDMMYYYY"))
              ? convertDateToFormat(singleCategory.start_date)
              : user_selected_start_date;
            overlap_end_date = moment(
              user_selected_end_date,
              "YYYY-MM-DD"
            ).isBefore(moment(singleCategory.end_date, "DDMMYYYY"))
              ? user_selected_end_date
              : convertDateToFormat(singleCategory.end_date);
            partialCategories.push({
              message:
                `The global dates selected are ` +
                convertDateToFormat(
                  moment(user_selected_start_date),
                  "DD MMMM, YYYY"
                ) +
                " to " +
                convertDateToFormat(
                  moment(user_selected_end_date),
                  "DD MMMM, YYYY"
                ) +
                " but data is available from " +
                convertDateToFormat(
                  singleCategory.start_date,
                  "DD MMMM, YYYY"
                ) +
                " to " +
                convertDateToFormat(singleCategory.end_date, "DD MMMM, YYYY") +
                " for " +
                singleCategory.name +
                ".",
            });
          }
          if (type === "complete overlap") {
            if (
              moment(user_selected_start_date, "YYYY-MM-DD").isBefore(
                moment(singleCategory.start_date, "DDMMYYYY")
              )
            ) {
              overlap_start_date = convertDateToFormat(
                singleCategory.start_date
              );
              overlap_end_date = convertDateToFormat(singleCategory.end_date);
            } else {
              overlap_start_date = user_selected_start_date;
              overlap_end_date = user_selected_end_date;
            }
          }
        } else {
          // let datasetsDatesArray =
          //   configUserInputs?.datasetDetailsData?.data?.map((elem) => ({
          //     ...elem,
          //     dataset_new_id: getDatasetId(elem.dataset_id),
          //   }));
          let selectedDatasetForCategory =
            configUserInputs?.datasetDetailsData?.data?.filter((dataset) =>
              model.data_sources.some(
                (elem) =>
                  elem.id === dataset.dataset_id &&
                  config.hardCoded.radioDatasetList.includes(elem?.type) &&
                  elem.category === singleCategory.categorySelected
              )
            );
          if (
            selectedDatasetForCategory[0] &&
            selectedDatasetForCategory[0].start_date &&
            selectedDatasetForCategory[0].end_date
          ) {
            // get that dateset startDate and endDate
            datasetName = selectedDatasetForCategory[0].dataset_name;
            let validCategoryDateset;
            validCategoryDateset = checkDatesOverlapping(
              user_selected_start_date,
              user_selected_end_date,
              selectedDatasetForCategory[0].start_date,
              selectedDatasetForCategory[0].end_date
            );
            const { type, status } = validCategoryDateset;
            if (!type || !status) {
              invalidCategories.push({
                name: selectedDatasetForCategory[0].dataset_name,
                startDate: convertDateToFormat(
                  selectedDatasetForCategory[0].start_date,
                  "DD MMMM, YYYY"
                ),
                endDate: convertDateToFormat(
                  selectedDatasetForCategory[0].end_date,
                  "DD MMMM, YYYY"
                ),
              });
            }

            if (type === "partial overlap") {
              overlap_start_date = moment(
                user_selected_start_date,
                "YYYY-MM-DD"
              ).isBefore(
                moment(selectedDatasetForCategory[0].start_date, "DDMMYYYY")
              )
                ? convertDateToFormat(selectedDatasetForCategory[0].start_date)
                : user_selected_start_date;
              overlap_end_date = moment(
                user_selected_end_date,
                "YYYY-MM-DD"
              ).isBefore(
                moment(selectedDatasetForCategory[0].end_date, "DDMMYYYY")
              )
                ? user_selected_end_date
                : convertDateToFormat(selectedDatasetForCategory[0].end_date);
              dataset_selected_start_date =
                selectedDatasetForCategory[0].start_date;
              dataset_selected_end_date =
                selectedDatasetForCategory[0].end_date;
            }
            if (type === "complete overlap") {
              if (
                moment(user_selected_start_date, "YYYY-MM-DD").isBefore(
                  moment(selectedDatasetForCategory[0].start_date, "DDMMYYYY")
                )
              ) {
                overlap_start_date = convertDateToFormat(
                  selectedDatasetForCategory[0].start_date
                );
                overlap_end_date = convertDateToFormat(
                  selectedDatasetForCategory[0].end_date
                );
                dataset_selected_start_date =
                  selectedDatasetForCategory[0].start_date;
                dataset_selected_end_date =
                  selectedDatasetForCategory[0].end_date;
              } else {
                userGlobalRangeSelected = true;
                overlap_start_date = user_selected_start_date;
                overlap_end_date = user_selected_end_date;
              }
            }
          } else {
            // user_selected startDate and endDate
            userGlobalRangeSelected = true;
            overlap_start_date = user_selected_start_date;
            overlap_end_date = user_selected_end_date;
          }
        }
        switch (singleCategory?.periodicity) {
          case "DAILY":
            // get days from date and check if days between these dates match the minimum data points if days < minimum_data_points => invalid dataset
            let noOfdays = getDaysFromDates(
              overlap_start_date,
              overlap_end_date
            );
            if (noOfdays < minimum_data_points_for_selectedModel) {
              if (singleCategory?.start_date && singleCategory.end_date) {
                invalidCategoriesPartial.push({
                  name: singleCategory.name,
                  startDate: convertDateToFormat(
                    singleCategory.start_date || overlap_start_date,
                    "DD MMMM, YYYY"
                  ),
                  endDate: convertDateToFormat(
                    singleCategory.end_date || overlap_end_date,
                    "DD MMMM, YYYY"
                  ),
                  modelName: model.name,
                  modelMinPoints: minimum_data_points_for_selectedModel,
                  selectedPeriodicity: "days",
                });
              } else {
                inValidDatasetsForCategoriesPartial.push({
                  name: datasetName,
                  startDate: !userGlobalRangeSelected
                    ? convertDateToFormat(
                        moment(dataset_selected_start_date),
                        "DD MMMM, YYYY"
                      )
                    : "",
                  endDate: !userGlobalRangeSelected
                    ? convertDateToFormat(
                        moment(dataset_selected_end_date),
                        "DD MMMM, YYYY"
                      )
                    : "",
                  modelName: model.name,
                  modelMinPoints: minimum_data_points_for_selectedModel,
                  selectedPeriodicity: "days",
                });
              }
            }
            break;
          case "WEEKLY":
            let noOfWeeks = getWeeksFromDates(
              overlap_start_date,
              overlap_end_date
            );
            if (noOfWeeks < minimum_data_points_for_selectedModel) {
              if (singleCategory?.start_date && singleCategory.end_date) {
                invalidCategoriesPartial.push({
                  name: singleCategory.name,
                  startDate: convertDateToFormat(
                    singleCategory.start_date,
                    "DD MMMM, YYYY"
                  ),
                  endDate: convertDateToFormat(
                    singleCategory.end_date,
                    "DD MMMM, YYYY"
                  ),
                  modelName: model.name,
                  modelMinPoints: minimum_data_points_for_selectedModel,
                  selectedPeriodicity: "weeks",
                });
              } else {
                inValidDatasetsForCategoriesPartial.push({
                  name: datasetName,
                  startDate: !userGlobalRangeSelected
                    ? convertDateToFormat(
                        moment(dataset_selected_start_date),
                        "DD MMMM, YYYY"
                      )
                    : "",
                  endDate: !userGlobalRangeSelected
                    ? convertDateToFormat(
                        moment(dataset_selected_end_date),
                        "DD MMMM, YYYY"
                      )
                    : "",
                  modelName: model.name,
                  modelMinPoints: minimum_data_points_for_selectedModel,
                  selectedPeriodicity: "weeks",
                });
              }
            }
            // get weeks from date and check if weeks between these dates match the minimum data points if weeks < minimum_data_points => invalid dataset
            break;
          case "MONTHLY":
            let noOfMonths = getMonthsFromDates(
              overlap_start_date,
              overlap_end_date
            );
            if (noOfMonths < minimum_data_points_for_selectedModel) {
              if (singleCategory?.start_date && singleCategory.end_date) {
                invalidCategoriesPartial.push({
                  name: singleCategory.name,
                  startDate: convertDateToFormat(
                    singleCategory.start_date,
                    "DD MMMM, YYYY"
                  ),
                  endDate: convertDateToFormat(
                    singleCategory.end_date,
                    "DD MMMM, YYYY"
                  ),
                  modelName: model.name,
                  modelMinPoints: minimum_data_points_for_selectedModel,
                  selectedPeriodicity: "months",
                });
              } else {
                inValidDatasetsForCategoriesPartial.push({
                  name: datasetName,
                  startDate: !userGlobalRangeSelected
                    ? convertDateToFormat(
                        moment(dataset_selected_start_date),
                        "DD MMMM, YYYY"
                      )
                    : "",
                  endDate: !userGlobalRangeSelected
                    ? convertDateToFormat(
                        moment(dataset_selected_end_date),
                        "DD MMMM, YYYY"
                      )
                    : "",
                  modelName: model.name,
                  modelMinPoints: minimum_data_points_for_selectedModel,
                  selectedPeriodicity: "months",
                });
              }
            }
            // get months from date and check if months between these dates match the minimum data points if months < minimum_data_points => invalid singleCategory
            break;
          case "YEARLY":
            let noOfYears = getYearsFromDates(
              overlap_start_date,
              overlap_end_date
            );
            if (noOfYears < minimum_data_points_for_selectedModel) {
              if (singleCategory?.start_date && singleCategory.end_date) {
                invalidCategoriesPartial.push({
                  name: singleCategory.name,
                  startDate: convertDateToFormat(
                    singleCategory.start_date,
                    "DD MMMM, YYYY"
                  ),
                  endDate: convertDateToFormat(
                    singleCategory.end_date,
                    "DD MMMM, YYYY"
                  ),
                  modelName: model.name,
                  modelMinPoints: minimum_data_points_for_selectedModel,
                  selectedPeriodicity: "years",
                });
              } else {
                inValidDatasetsForCategoriesPartial.push({
                  name: datasetName,
                  startDate: !userGlobalRangeSelected
                    ? convertDateToFormat(
                        moment(dataset_selected_start_date),
                        "DD MMMM, YYYY"
                      )
                    : "",
                  endDate: !userGlobalRangeSelected
                    ? convertDateToFormat(
                        moment(dataset_selected_end_date),
                        "DD MMMM, YYYY"
                      )
                    : "",
                  modelName: model.name,
                  modelMinPoints: minimum_data_points_for_selectedModel,
                  selectedPeriodicity: "years",
                });
              }
            }
            // get years from date and check if years between these dates match the minimum data points if years < minimum_data_points => invalid singleCategory
            break;
          default:
            let noOfWeeksDefault = getWeeksFromDates(
              overlap_start_date,
              overlap_end_date
            );
            if (noOfWeeksDefault < minimum_data_points_for_selectedModel) {
              if (singleCategory?.start_date && singleCategory.end_date) {
                invalidCategoriesPartial.push({
                  name: singleCategory.name,
                  startDate: convertDateToFormat(
                    singleCategory.start_date,
                    "DD MMMM, YYYY"
                  ),
                  endDate: convertDateToFormat(
                    singleCategory.end_date,
                    "DD MMMM, YYYY"
                  ),
                  modelName: model.name,
                  modelMinPoints: minimum_data_points_for_selectedModel,
                  selectedPeriodicity: "weeks",
                });
              } else {
                inValidDatasetsForCategoriesPartial.push({
                  name: datasetName,
                  startDate: !userGlobalRangeSelected
                    ? convertDateToFormat(
                        moment(dataset_selected_start_date),
                        "DD MMMM, YYYY"
                      )
                    : "",
                  endDate: !userGlobalRangeSelected
                    ? convertDateToFormat(
                        moment(dataset_selected_end_date),
                        "DD MMMM, YYYY"
                      )
                    : "",
                  modelName: model.name,
                  modelMinPoints: minimum_data_points_for_selectedModel,
                  selectedPeriodicity: "weeks",
                });
              }
            }
            break;
        }
      });
    }
  });
  if (invalidCategories.length) {
    let displayMessage = "";
    invalidCategories.forEach(
      (elem, index) =>
        (displayMessage +=
          index +
          1 +
          ": " +
          `The global dates selected are ` +
          convertDateToFormat(
            moment(user_selected_start_date),
            "DD MMMM, YYYY"
          ) +
          " to " +
          convertDateToFormat(moment(user_selected_end_date), "DD MMMM, YYYY") +
          " but data is available from " +
          elem.startDate +
          " to " +
          elem.endDate +
          " for " +
          elem.name +
          "." +
          "\n")
    );
    return {
      status: false,
      message: displayMessage,
    };
  } else if (invalidCategoriesPartial.length) {
    let displayMessage = "";
    invalidCategoriesPartial.forEach(
      (elem, index) =>
        (displayMessage +=
          index +
          1 +
          ": " +
          `The global dates selected are ` +
          convertDateToFormat(
            moment(user_selected_start_date),
            "DD MMMM, YYYY"
          ) +
          " to " +
          convertDateToFormat(moment(user_selected_end_date), "DD MMMM, YYYY") +
          " but data is available from " +
          elem.startDate +
          " to " +
          elem.endDate +
          " for " +
          elem.name +
          "." +
          "\n" +
          "Overlap dates between selected global dates and " +
          elem.name +
          " dates for " +
          elem.modelName +
          " should contain at least " +
          elem.modelMinPoints +
          " " +
          elem.selectedPeriodicity +
          "\n")
    );
    return {
      status: false,
      message: displayMessage,
    };
  } else if (inValidDatasetsForCategoriesPartial.length) {
    let displayMessage = "";
    inValidDatasetsForCategoriesPartial.forEach((elem, index) => {
      if (elem.startDate && elem.endDate) {
        displayMessage +=
          index +
          1 +
          ": " +
          `The global dates selected are ` +
          convertDateToFormat(
            moment(user_selected_start_date),
            "DD MMMM, YYYY"
          ) +
          " to " +
          convertDateToFormat(moment(user_selected_end_date), "DD MMMM, YYYY") +
          " but data is available from " +
          elem.startDate +
          " to " +
          elem.endDate +
          " for " +
          elem.name +
          "." +
          "\n" +
          "Overlap dates between selected global dates and " +
          elem.name +
          " dates for " +
          elem.modelName +
          " should contain at least " +
          elem.modelMinPoints +
          " " +
          elem.selectedPeriodicity +
          "\n";
      } else {
        displayMessage +=
          index +
          1 +
          ": " +
          `The global dates selected are ` +
          convertDateToFormat(
            moment(user_selected_start_date),
            "DD MMMM, YYYY"
          ) +
          " to " +
          convertDateToFormat(moment(user_selected_end_date), "DD MMMM, YYYY") +
          ". Selected dates for " +
          elem.modelName +
          " should contain at least " +
          elem.modelMinPoints +
          " " +
          elem.selectedPeriodicity +
          "\n";
      }
    });
    return {
      status: false,
      message: displayMessage,
    };
  } else
    return {
      status: true,
      message: "Pass",
    };
};

const validateValidSalesDatasetBasedOnPeriodicity = (
  selectedModels,
  configUserInputs,
  allModels
) => {
  let validSalesDatasets,
    inValidSalesDatasets = [],
    partialSalesDataset = [],
    invalidSalesPeriodicityDatasets = [];
  let user_selected_start_date = configUserInputs.startDate;
  let user_selected_end_date = configUserInputs.endDate;
  if (
    configUserInputs.global_week_date_selection ===
    config.hardCoded.historicWeek
  ) {
    let result = getDatesFromWeek(configUserInputs.historic_week_data);
    user_selected_start_date = result.min;
    user_selected_end_date = result.max;
  }
  // filtering models that are disabled and checking for sales or category datasets
  selectedModels = selectedModels.filter((model) => model.run);
  selectedModels.forEach((model) => {
    // let datasetsDatesArray = configUserInputs?.datasetDetailsData?.data?.map(
    //   (elem) => ({ ...elem, dataset_new_id: getDatasetId(elem.dataset_id) })
    // );
    let minimum_data_points_for_selectedModel = allModels.find(
      (modelFetched) => modelFetched.id === model.id
    )?.minimum_data_points;
    let selectedDatasets =
      configUserInputs?.datasetDetailsData?.data?.filter((dataset) =>
        model.data_sources.some(
          (elem) =>
            elem.id === dataset.dataset_id &&
            config.hardCoded.radioDatasetList.includes(elem?.type) &&
            (!elem.category || elem.category.length === 0)
        )
      ) || [];
    selectedDatasets.forEach((dataset) => {
      let overlap_start_date, overlap_end_date;
      if (dataset.start_date && dataset.end_date) {
        validSalesDatasets = checkDatesOverlapping(
          user_selected_start_date,
          user_selected_end_date,
          dataset.start_date,
          dataset.end_date
        );
        const { type, status } = validSalesDatasets;
        if (!type || !status) {
          inValidSalesDatasets.push({
            name: dataset.dataset_name,
            startDate: convertDateToFormat(dataset.start_date, "DD MMMM, YYYY"),
            endDate: convertDateToFormat(dataset.end_date, "DD MMMM, YYYY"),
          });
        }

        if (type === "partial overlap") {
          overlap_start_date = moment(
            user_selected_start_date,
            "YYYY-MM-DD"
          ).isBefore(moment(dataset.start_date, "DDMMYYYY"))
            ? convertDateToFormat(dataset.start_date)
            : user_selected_start_date;
          overlap_end_date = moment(
            user_selected_end_date,
            "YYYY-MM-DD"
          ).isBefore(moment(dataset.end_date, "DDMMYYYY"))
            ? user_selected_end_date
            : convertDateToFormat(dataset.end_date);
          partialSalesDataset.push({
            message:
              `The global dates selected are ` +
              convertDateToFormat(
                moment(user_selected_start_date),
                "DD MMMM, YYYY"
              ) +
              " to " +
              convertDateToFormat(
                moment(user_selected_end_date),
                "DD MMMM, YYYY"
              ) +
              " but data is available from " +
              convertDateToFormat(dataset.start_date, "DD MMMM, YYYY") +
              " to " +
              convertDateToFormat(dataset.end_date, "DD MMMM, YYYY") +
              " for " +
              dataset.dataset_name +
              ".",
          });
        }
        if (type === "complete overlap") {
          if (
            moment(user_selected_start_date, "YYYY-MM-DD").isBefore(
              moment(dataset.start_date, "DDMMYYYY")
            )
          ) {
            overlap_start_date = convertDateToFormat(dataset.start_date);
            overlap_end_date = convertDateToFormat(dataset.end_date);
          } else {
            overlap_start_date = user_selected_start_date;
            overlap_end_date = user_selected_end_date;
          }
        }
      } else {
        overlap_start_date = user_selected_start_date;
        overlap_end_date = user_selected_end_date;
      }
      switch (dataset?.periodicity) {
        case "DAILY":
          // get days from date and check if days between these dates match the minimum data points if days < minimum_data_points => invalid dataset
          let noOfdays = getDaysFromDates(overlap_start_date, overlap_end_date);
          if (noOfdays < minimum_data_points_for_selectedModel) {
            invalidSalesPeriodicityDatasets.push({
              name: dataset.dataset_name,
              startDate: dataset.start_date
                ? convertDateToFormat(dataset.start_date, "DD MMMM, YYYY")
                : "",
              endDate: dataset.end_date
                ? convertDateToFormat(dataset.end_date, "DD MMMM, YYYY")
                : "",
              modelName: model.name,
              modelMinPoints: minimum_data_points_for_selectedModel,
              selectedPeriodicity: "days",
            });
          }
          break;
        case "WEEKLY":
          let noOfWeeks = getWeeksFromDates(
            overlap_start_date,
            overlap_end_date
          );
          if (noOfWeeks < minimum_data_points_for_selectedModel) {
            invalidSalesPeriodicityDatasets.push({
              name: dataset.dataset_name,
              startDate: dataset.start_date
                ? convertDateToFormat(dataset.start_date, "DD MMMM, YYYY")
                : "",
              endDate: dataset.end_date
                ? convertDateToFormat(dataset.end_date, "DD MMMM, YYYY")
                : "",
              modelName: model.name,
              modelMinPoints: minimum_data_points_for_selectedModel,
              selectedPeriodicity: "weeks",
            });
          }
          // get weeks from date and check if weeks between these dates match the minimum data points if weeks < minimum_data_points => invalid dataset
          break;
        case "MONTHLY":
          let noOfMonths = getMonthsFromDates(
            overlap_start_date,
            overlap_end_date
          );
          if (noOfMonths < minimum_data_points_for_selectedModel) {
            invalidSalesPeriodicityDatasets.push({
              name: dataset.dataset_name,
              startDate: dataset.start_date
                ? convertDateToFormat(dataset.start_date, "DD MMMM, YYYY")
                : "",
              endDate: dataset.end_date
                ? convertDateToFormat(dataset.end_date, "DD MMMM, YYYY")
                : "",
              modelName: model.name,
              modelMinPoints: minimum_data_points_for_selectedModel,
              selectedPeriodicity: "months",
            });
          }
          // get months from date and check if months between these dates match the minimum data points if months < minimum_data_points => invalid dataset
          break;
        case "YEARLY":
          let noOfYears = getYearsFromDates(
            overlap_start_date,
            overlap_end_date
          );
          if (noOfYears < minimum_data_points_for_selectedModel) {
            invalidSalesPeriodicityDatasets.push({
              name: dataset.dataset_name,
              startDate: dataset.start_date
                ? convertDateToFormat(dataset.start_date, "DD MMMM, YYYY")
                : "",
              endDate: dataset.end_date
                ? convertDateToFormat(dataset.end_date, "DD MMMM, YYYY")
                : "",
              modelName: model.name,
              modelMinPoints: minimum_data_points_for_selectedModel,
              selectedPeriodicity: "years",
            });
          }
          // get years from date and check if years between these dates match the minimum data points if years < minimum_data_points => invalid dataset
          break;
        default:
          let noOfWeeksDefault = getWeeksFromDates(
            overlap_start_date,
            overlap_end_date
          );
          if (noOfWeeksDefault < minimum_data_points_for_selectedModel) {
            invalidSalesPeriodicityDatasets.push({
              name: dataset.dataset_name,
              startDate: dataset.start_date
                ? convertDateToFormat(dataset.start_date, "DD MMMM, YYYY")
                : "",
              endDate: dataset.end_date
                ? convertDateToFormat(dataset.end_date, "DD MMMM, YYYY")
                : "",
              modelName: model.name,
              modelMinPoints: minimum_data_points_for_selectedModel,
              selectedPeriodicity: "weeks",
            });
          }
          break;
      }
    });
  });
  // return { status: false, message: "Wait for sales dataset validation" };
  if (inValidSalesDatasets.length) {
    let displayMessage = "";
    inValidSalesDatasets.forEach(
      (elem, index) =>
        (displayMessage +=
          index +
          1 +
          ": " +
          `The global dates selected are ` +
          convertDateToFormat(
            moment(user_selected_start_date),
            "DD MMMM, YYYY"
          ) +
          " to " +
          convertDateToFormat(moment(user_selected_end_date), "DD MMMM, YYYY") +
          " but data is available from " +
          elem.startDate +
          " to " +
          elem.endDate +
          " for " +
          elem.name +
          "." +
          "\n")
    );
    return {
      status: false,
      message: displayMessage,
    };
  } else if (invalidSalesPeriodicityDatasets.length) {
    let displayMessage = "";
    invalidSalesPeriodicityDatasets.forEach((elem, index) => {
      if (elem.startDate && elem.endDate) {
        displayMessage +=
          index +
          1 +
          ": " +
          `The global dates selected are ` +
          convertDateToFormat(
            moment(user_selected_start_date),
            "DD MMMM, YYYY"
          ) +
          " to " +
          convertDateToFormat(moment(user_selected_end_date), "DD MMMM, YYYY") +
          " but data is available from " +
          elem.startDate +
          " to " +
          elem.endDate +
          " for " +
          elem.name +
          "." +
          "\n" +
          "Overlap dates between selected global dates and " +
          elem.name +
          " dates for " +
          elem.modelName +
          " should contain at least " +
          elem.modelMinPoints +
          " " +
          elem.selectedPeriodicity +
          "\n";
      } else {
        displayMessage +=
          index +
          1 +
          ": " +
          `The global dates selected are ` +
          convertDateToFormat(
            moment(user_selected_start_date),
            "DD MMMM, YYYY"
          ) +
          " to " +
          convertDateToFormat(moment(user_selected_end_date), "DD MMMM, YYYY") +
          ". Selected dates for " +
          elem.modelName +
          " should contain at least " +
          elem.modelMinPoints +
          " " +
          elem.selectedPeriodicity +
          "\n";
      }
    });
    return {
      status: false,
      message: displayMessage,
    };
  } else
    return {
      status: true,
      message: "Pass",
    };
};

export const validateValidDatasetSelected = (
  selectedModels,
  configUserInputs
) => {
  let validDataset,
    invalidDataset = [],
    partialDataset = [];
  let global_start_date = configUserInputs.startDate,
    global_end_date = configUserInputs.endDate;

  //filter models that disabled
  selectedModels = selectedModels.find((model) => model.run);
  // let datasetsDatesArray = configUserInputs.datasetDetailsData.data.map(
  //   (elem) => ({ ...elem, dataset_new_id: getDatasetId(elem.dataset_id) })
  // );
  let selectedDatasets = configUserInputs?.datasetDetailsData?.data?.filter(
    (dataset) =>
      selectedModels?.data_sources.some(
        (elem) =>
          elem.id === dataset.dataset_id &&
          !config.hardCoded.radioDatasetList.includes(elem?.type)
      )
  );

  if (
    configUserInputs.global_week_date_selection ===
    config.hardCoded.historicWeek
  ) {
    let result = getDatesFromWeek(configUserInputs.historic_week_data);
    global_start_date = result.min;
    global_end_date = result.max;
  }

  selectedDatasets.forEach((dataset) => {
    validDataset = checkDatesOverlapping(
      global_start_date,
      global_end_date,
      dataset.start_date,
      dataset.end_date
    );
    // console.log("validDataset", validDataset);
    const { type, status } = validDataset;
    if (!type || !status) {
      invalidDataset.push({
        name: dataset.dataset_name,
        startDate: convertDateToFormat(dataset.start_date, "DD MMMM, YYYY"),
        endDate: convertDateToFormat(dataset.end_date, "DD MMMM, YYYY"),
      });
    }
    if (type === "partial overlap") {
      partialDataset.push({
        message:
          `The global dates selected are ` +
          convertDateToFormat(moment(global_start_date), "DD MMMM, YYYY") +
          " to " +
          convertDateToFormat(moment(global_end_date), "DD MMMM, YYYY") +
          " but data is available from " +
          convertDateToFormat(dataset.start_date, "DD MMMM, YYYY") +
          " to " +
          convertDateToFormat(dataset.end_date, "DD MMMM, YYYY") +
          " for " +
          dataset.dataset_name +
          ".",
      });
    }
  });

  if (invalidDataset.length) {
    let displayMessage = "";
    invalidDataset.forEach(
      (elem, index) =>
        (displayMessage +=
          index +
          1 +
          ": " +
          `The global dates selected are ` +
          convertDateToFormat(moment(global_start_date), "DD MMMM, YYYY") +
          " to " +
          convertDateToFormat(moment(global_end_date), "DD MMMM, YYYY") +
          " but data is available from " +
          elem.startDate +
          " to " +
          elem.endDate +
          " for " +
          elem.name +
          "." +
          "\n")
    );
    return {
      status: false,
      message: displayMessage,
    };
  } else
    return { status: true, message: "Pass", partialDataset: partialDataset };
};

export const validateNeilsenComp = (selectedModels, allModels) => {
  let invalidArr = [];
  let selectedNeilsenCompDatasetsLen, selectedNeilsenNonCompDatasetsLen;
  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);

  selectedModels.forEach((model) => {
    selectedNeilsenCompDatasetsLen = model.data_sources.find(
      (neilsenData) =>
        neilsenData.pipeline_dataset_id === config.hardCoded.neilsenNonCompId
    );
    selectedNeilsenNonCompDatasetsLen = model.data_sources.find(
      (neilsenData) =>
        neilsenData.pipeline_dataset_id === config.hardCoded.neilsenNonCompId
    );
    if (selectedNeilsenCompDatasetsLen) {
      if (selectedNeilsenNonCompDatasetsLen) {
        return false;
      } else {
        invalidArr.push({ selectedNeilsenCompDatasetsLen, modelId: model.id });
      }
    }
  });

  if (invalidArr.length)
    return {
      status: false,
      message: `Neilsen Comp in ${invalidArr[0].modelId} should have Neilsen Non Comp selected.`,
    };
  return { status: true, message: "Pass" };
};

export const validSalesNielsenDataset = (selectedModels, allModels) => {
  let invalidArr = [];
  let selectedSalesDatasetsLen, selectedNeilsenDatasetsLen;

  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);
  selectedModels.forEach((model) => {
    selectedNeilsenDatasetsLen = model.data_sources.find(
      (neilsenData) => neilsenData.type === config.hardCoded.datasetNeilsenType
    );
    selectedSalesDatasetsLen = model.data_sources.find(
      (neilsenData) => neilsenData.type === config.hardCoded.datasetSalesType
    );
    if (selectedSalesDatasetsLen && selectedNeilsenDatasetsLen) {
      invalidArr.push({ modelId: model.id });
    }
  });

  if (invalidArr.length)
    return {
      status: false,
      message: `Cannot add neilsen datasets with sales datasets in ${invalidArr[0].modelId}`,
    };
  return { status: true, message: "Pass" };
};

export const validAdstockParameterDataset = (selectedModels, allModels) => {
  let invalidArr = [];
  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);
  selectedModels.forEach((model) => {
    model.data_sources.forEach((dataset) => {
      if (dataset.type === config.hardCoded.datasetMediaType) {
        if (dataset.saturation_flag === "true" && dataset.limit === 1)
          invalidArr.push({ modelId: model.id, dataset: dataset });
      }
    });
  });

  if (invalidArr.length)
    return {
      status: false,
      message: `Please select limit between 0 to 0.99 as you have selected saturation flag as false in ${invalidArr[0].dataset.name}`,
    };
  return { status: true, message: "Pass" };
};

export const validateDataCorrectness = (modelData, globalData) => {
  let autoMlModel = modelData.find(
    (model) => model.id === config.hardCoded.autoMlId
  );
  let nestedFlag =
    autoMlModel?.config?.run_type === config.hardCoded.nestedRunId;
  let invalidArr = [];
  let invalidArrMinMax = [];

  if (modelData?.length) {
    globalData.forEach((data) => {
      modelData.forEach((model) => {
        if (model.id === data.id) {
          data.local_parameters.forEach((lp) => {
            if (lp["id"] === "sales_coverage") {
              let maxVal =
                lp.maxValue == "null" ? 1000000.0 : parseFloat(lp.maxValue);
              let minVal =
                lp.minValue == "null"
                  ? Number.MIN_VALUE
                  : parseFloat(lp.minValue);
              let value = model?.config[lp.id];
              if (!nestedFlag && autoMlModel?.run) {
                if (!value || isNaN(value) || value == "") {
                  invalidArr.push(model);
                }
                if (
                  parseFloat(value) < parseFloat(minVal) ||
                  parseFloat(value) > parseFloat(maxVal)
                ) {
                  invalidArrMinMax.push(model);
                }
              } else {
                // handle nested run validations
                if (!value || isNaN(value) || value == "") {
                  invalidArr.push(model);
                }
                if (value !== "-1") {
                  if (
                    parseFloat(value) < parseFloat(minVal) ||
                    parseFloat(value) > parseFloat(maxVal)
                  ) {
                    invalidArrMinMax.push(model);
                  }
                }
              }
            }
          });
        }
      });
    });
  }
  return { invalidArr, invalidArrMinMax };
};
export const datasetIds = (configUserInputs, datasets, type, activeModelId) => {
  const data_sources = configUserInputs.models
    .find((model) => model.id === activeModelId)
    .data_sources.filter((dataset) =>
      type === "media"
        ? dataset.type === config.hardCoded.datasetMediaType
        : dataset.type === config.hardCoded.datasetSalesType ||
          dataset.type === config.hardCoded.datasetNeilsenType
    );
  const validDatasets = data_sources.length
    ? type === "media"
      ? data_sources.filter(
          (data) => data.type === config.hardCoded.datasetMediaType
        )
      : data_sources.filter(
          (data) =>
            data.type === config.hardCoded.datasetSalesType ||
            data.type === config.hardCoded.datasetNeilsenType
        )
    : datasets.filter((dataset) => {
        return type === "media"
          ? dataset.type === config.hardCoded.datasetMediaType &&
              configUserInputs?.datasetDetailsData?.data.find(
                (el) => el.dataset_id === dataset.id
              )
          : (dataset.type === config.hardCoded.datasetSalesType ||
              dataset.type === config.hardCoded.datasetNeilsenType) &&
              configUserInputs?.datasetDetailsData?.data.find(
                (el) => el.dataset_id === dataset.id
              );
      });
  const datasetIds = validDatasets.map((dataset) =>
    dataset.merge_dataset
      ? dataset.dataset_list.map((item) => item.id)
      : [dataset.id]
  );
  return datasetIds.flat(1);
};
export const validateSalesCoverage = (selectedModels, allModels) => {
  let allModelsData = allModels?.filter(
    (model) =>
      model.id === config.hardCoded.salesUpliftId ||
      model.id === config.hardCoded.autoMlId ||
      model.id === config.hardCoded.autoMlBudgetId
  );
  let selectedModelsData = selectedModels
    ?.filter(
      (model) =>
        model.id === config.hardCoded.salesUpliftId ||
        model.id === config.hardCoded.autoMlId ||
        model.id === config.hardCoded.autoMlBudgetId
    )
    .filter((elem) => elem.run);
  let { invalidArr, invalidArrMinMax } = validateDataCorrectness(
    selectedModelsData,
    allModelsData
  );
  if (invalidArr.length)
    return {
      status: false,
      message: `Please input correct value in sales covearge in ${invalidArr[0]?.name}`,
    };
  if (invalidArrMinMax.length) {
    return {
      status: false,
      message: `Please input sales coverage within the specified range in ${invalidArrMinMax[0]?.name}`,
    };
  }
  return { status: true, message: "Pass" };
};

export const validateMMTSimilarRegionsInput = (
  selectedModels,
  allModels,
  checkDERunConfig,
  configUserInputs
) => {
  let invalidArr = [];
  let invalidArrMinMax = [];
  let maxVal;
  let minVal;
  let allModelsFilteredMMT = allModels?.find(
    (model) => model.id === config.hardCoded.mmtId
  );
  let mmtModelConfigUserInputs = selectedModels?.find(
    (model) => model.id === config.hardCoded.mmtId
  );

  const regionTypeData = configUserInputs?.globalRegions?.find(
    (e) => e.id === mmtModelConfigUserInputs.config.regionType
  );

  const uniqueGlobalRegions = [...new Set(regionTypeData?.regions)];

  if (
    mmtModelConfigUserInputs?.run &&
    (checkDERunConfig ||
      configUserInputs?.is_config_manual ||
      typeof mmtModelConfigUserInputs.config.run_model === "undefined")
  ) {
    allModelsFilteredMMT.local_parameters.forEach((lp) => {
      if (lp["id"] === "no_of_similar_regions") {
        //  max value for similar region count is determined by length of available dma
        //  if dma is less than 100 and Region Granularity is selected as all then the max value will be dma.length
        //  if not then max value will be 100
        //  now if  Region Granularity is selected as Selected dma then
        //  in include case the max value will be length of selected dataset
        //  and in exclude case the max value will be length of 100 or dma.length  - selected dma (which ever is less)
        maxVal = lp.maxValue == "null" ? 1000000.0 : parseFloat(lp.maxValue);
        if (!configUserInputs?.is_config_manual) {
          maxVal = uniqueGlobalRegions?.length
            ? maxVal > uniqueGlobalRegions?.length
              ? uniqueGlobalRegions?.length
              : maxVal
            : maxVal;
          if (
            mmtModelConfigUserInputs.config.regionSelectedType !== "all" &&
            mmtModelConfigUserInputs.config.regionFilterType === "exclude"
          ) {
            maxVal =
              uniqueGlobalRegions?.length -
                mmtModelConfigUserInputs.config.regions.length <=
              maxVal
                ? uniqueGlobalRegions?.length -
                  mmtModelConfigUserInputs.config.regions.length
                : maxVal;
          } else if (
            mmtModelConfigUserInputs.config.regionSelectedType !== "all" &&
            mmtModelConfigUserInputs.config.regionFilterType === "include"
          ) {
            maxVal =
              mmtModelConfigUserInputs.config.regions.length === 0
                ? uniqueGlobalRegions?.length <= maxVal
                  ? uniqueGlobalRegions?.length
                  : maxVal
                : mmtModelConfigUserInputs.config.regions.length <= 2
                ? 2
                : mmtModelConfigUserInputs.config.regions.length <= maxVal
                ? mmtModelConfigUserInputs.config.regions.length
                : maxVal;
            console.log(
              maxVal,
              mmtModelConfigUserInputs.config.regions,
              uniqueGlobalRegions
            );
          }
        } else if (configUserInputs?.is_config_manual) {
          maxVal =
            configUserInputs?.region_count >= maxVal
              ? maxVal
              : configUserInputs?.region_count;
        }
        minVal =
          lp.minValue == "null" ? Number.MIN_VALUE : parseFloat(lp.minValue);
        let value = mmtModelConfigUserInputs?.config[lp.id];
        if (!value || isNaN(value) || value == "") {
          invalidArr.push({
            ...mmtModelConfigUserInputs,
            message: `Please input correct value in ${lp.name} in MMT`,
          });
        }
        if (value > 100) {
          invalidArrMinMax.push({
            ...mmtModelConfigUserInputs,
            message: `${lp.name} cannot be greater than 100`,
          });
        } else if (maxVal <= 1 && configUserInputs?.is_config_manual) {
          invalidArrMinMax.push({
            ...mmtModelConfigUserInputs,
            message:
              "File uploaded has only 1 region. Please upload file with for more than 1 region for the model to succeed",
          });
        } else if (
          mmtModelConfigUserInputs.config.regionFilterType === "include" &&
          mmtModelConfigUserInputs.config.regions.length < 2 &&
          mmtModelConfigUserInputs.config.regions.length !== 0
        ) {
          invalidArrMinMax.push({
            ...mmtModelConfigUserInputs,
            message: `Applicable number of regions with your selections is ${
              mmtModelConfigUserInputs.config.regions.length
            }. Please include ${
              2 - mmtModelConfigUserInputs.config.regions.length
            } more region for the model to succeed`,
          });
        } else if (
          maxVal >= 100
            ? parseFloat(value) > parseFloat(maxVal)
            : parseFloat(value) >= parseFloat(maxVal)
        ) {
          invalidArrMinMax.push({
            ...mmtModelConfigUserInputs,
            message: `${lp.name} cannot be greater than or equal to applicable number of regions : ${maxVal}`,
          });
        } else if (parseFloat(value) < parseFloat(minVal)) {
          invalidArrMinMax.push({
            ...mmtModelConfigUserInputs,
            message: `${lp.name} cannot be less than ${minVal}`,
          });
        }
      }
    });
    if (invalidArr.length)
      return {
        status: false,
        message: invalidArr[0].message,
      };
    if (invalidArrMinMax.length) {
      return {
        status: false,
        message: invalidArrMinMax[0].message,
      };
    }
  }
  return { status: true, message: "Pass" };
};

export const validateMMTNumberOfCellsInput = (
  selectedModels,
  allModels,
  checkDERunConfig,
  configUserInputs
) => {
  let invalidArr = [];
  let invalidArrMinMax = [];
  let maxVal;
  let minVal;

  let allModelsFilteredMMT = allModels?.find(
    (model) => model.id === config.hardCoded.mmtId
  );
  let mmtModelConfigUserInputs = selectedModels?.find(
    (model) => model.id === config.hardCoded.mmtId
  );

  if (
    mmtModelConfigUserInputs?.run &&
    (checkDERunConfig ||
      configUserInputs?.is_config_manual ||
      typeof mmtModelConfigUserInputs.config.run_model === "undefined")
  ) {
    allModelsFilteredMMT.local_parameters.forEach((lp) => {
      if (lp["id"] === "number_of_cells") {
        maxVal = lp.maxValue == "null" ? 1000000.0 : parseFloat(lp.maxValue);
        minVal =
          lp.minValue == "null" ? Number.MIN_VALUE : parseFloat(lp.minValue);
        let value = mmtModelConfigUserInputs?.config[lp.id];
        if (!value || isNaN(value) || value == "") {
          invalidArr.push({
            ...mmtModelConfigUserInputs,
            message: `Please input correct value in ${lp.name} in MMT`,
          });
        }
        if (value > maxVal) {
          invalidArrMinMax.push({
            ...mmtModelConfigUserInputs,
            message: `${lp.name} cannot be greater than 100`,
          });
        } else if (parseFloat(value) < parseFloat(minVal)) {
          invalidArrMinMax.push({
            ...mmtModelConfigUserInputs,
            message: `${lp.name} cannot be less than ${minVal}`,
          });
        }
      }
    });
    if (invalidArr.length)
      return {
        status: false,
        message: invalidArr[0].message,
      };
    if (invalidArrMinMax.length) {
      return {
        status: false,
        message: invalidArrMinMax[0].message,
      };
    }
  }
  return { status: true, message: "Pass" };
};

export const validateMinMaxNumberInputs = (
  selectedModels,
  allModels,
  checkDERunConfig
) => {
  let invalidArr = [];

  let selectedModelsId = selectedModels
    .filter((model) => model.run)
    .map((model) => model.id);
  let allModelsFiltered = allModels.filter((model) =>
    selectedModelsId.includes(model.id)
  );

  allModelsFiltered.forEach((model) => {
    model.local_parameters.forEach((lp) => {
      if (
        lp["ui_element_type"] === "inputbox_number" &&
        checkDERunConfig &&
        (lp.id !== "no_of_similar_regions" || lp.id !== "number_of_cells")
      ) {
        let maxVal =
          lp.maxValue == "null" ? 1000000.0 : parseFloat(lp.maxValue);
        let minVal =
          lp.minValue == "null" ? Number.MIN_VALUE : parseFloat(lp.minValue);
        let value = selectedModels.filter(
          (currModel) => currModel.id === model.id
        )[0].config[lp.id];
        if (
          parseFloat(value) < parseFloat(minVal) ||
          parseFloat(value) > parseFloat(maxVal) ||
          isNaN(value) ||
          value === ""
        ) {
          invalidArr.push(model);
        }
      }
    });
  });

  if (invalidArr.length)
    return {
      status: false,
      message: `Invalid input in ${invalidArr[0].name}`,
    };
  return { status: true, message: "Pass" };
};

export const validateMMTCorrelationThreshold = (selectedModels, allModels) => {
  let invalidArrMinMax = [];
  let allModelsFilteredMMT = allModels?.find(
    (model) => model.id === config.hardCoded.mmtId
  );
  let mmtModelConfigUserInputs = selectedModels?.find(
    (model) => model.id === config.hardCoded.mmtId
  );
  if (
    mmtModelConfigUserInputs?.run &&
    JSON.stringify(mmtModelConfigUserInputs.config.corr_upper_limit) !== `[]` &&
    typeof mmtModelConfigUserInputs.config.corr_upper_limit === "number"
  ) {
    allModelsFilteredMMT.local_parameters.forEach((lp) => {
      if (lp["ui_element_type"] === "custom") {
        let maxVal =
          lp.maxValue == "null" ? 1000000.0 : parseFloat(lp.maxValue);
        let minVal =
          lp.minValue == "null" ? Number.MIN_VALUE : parseFloat(lp.minValue);
        let value = mmtModelConfigUserInputs?.config[lp.id];
        if (
          parseFloat(value) < parseFloat(minVal) ||
          parseFloat(value) > parseFloat(maxVal)
        ) {
          invalidArrMinMax.push(mmtModelConfigUserInputs);
        }
      }
    });
    if (invalidArrMinMax.length) {
      return {
        status: false,
        message:
          "Please input Correlation Threshold within the specified range in MMT",
      };
    }
  }
  return { status: true, message: "Pass" };
};

export const validateBudgetOnlyRun = (selectedModels) => {
  let response = { status: true, message: "Pass" };
  let rbtModel = selectedModels.find(
    (model) => model.id === config.hardCoded.regBudId
  );
  if (rbtModel) {
    let rbtFlag = rbtModel.config.rbt_run_type === config.hardCoded.budgetId;
    if (rbtFlag && rbtModel.run) {
      let modelConfig = rbtModel.config;
      let budgetRunReqFieldsList = config.hardCoded.budgetOnlyRunReqFieldsList;
      for (let item of budgetRunReqFieldsList) {
        for (let key in item) {
          if (modelConfig[key] === "") {
            return {
              status: false,
              message: `${config.messages.invalidBudgetOnlyRun} ${item[key]}`,
            };
          }
        }
      }
      return response; //return pass if the model run is false
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const validateNestedRun = (selectedModels) => {
  let response = { status: true, message: "Pass" };
  let autoMlModel = selectedModels.find(
    (model) => model.id === config.hardCoded.autoMlId
  );
  if (autoMlModel) {
    let enabledModels = selectedModels.filter((model) => model.run);
    let nestedFlag =
      autoMlModel.config.run_type === config.hardCoded.nestedRunId;
    if (nestedFlag && autoMlModel.run) {
      let modelConfig = autoMlModel.config;
      let nestedRunReqFieldsList = config.hardCoded.nestedRunReqFieldsList;
      for (let item of nestedRunReqFieldsList) {
        for (let key in item) {
          if (modelConfig[key] === "") {
            return {
              status: false,
              message: `${config.messages.invalidNestedRun} ${item[key]}`,
            };
          }
        }
      }
      // if (enabledModels.length !== 1) {
      //   return {
      //     status: false,
      //     message: `${config.messages.nestedRunOnly}`,
      //   };
      // }
      return response; //return pass if the model run is false
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const validateNestedRunV2 = (selectedModels) => {
  let response = { status: true, message: "Pass" };
  const autoMlModel = selectedModels.find(
    (model) => model.id === config.hardCoded.autoMlId
  );
  if (autoMlModel) {
    const enabledModels = selectedModels.filter((model) => model.run);
    const nestedFlag =
      autoMlModel.config.run_type === config.hardCoded.nestedRunId;
    if (nestedFlag && autoMlModel.run) {
      const modelConfig = autoMlModel.config;
      const nestedRunReqFieldsList = config.hardCoded.nestedRunReqFieldsList;
      //Checking if required fields are present or not
      for (let item of nestedRunReqFieldsList) {
        for (let key in item) {
          if (modelConfig[key] === "") {
            return {
              status: false,
              message: `${config.messages.invalidNestedRun} ${item[key]}`,
            };
          }
        }
      }
      //Checking if nested_run_regions allRows are valid or not
      const nestedRunRegions = modelConfig.nested_run_regions;
      //nested json change when the nesteddriver api call given []
      if (nestedRunRegions?.length >= 1) {
        for (let item of nestedRunRegions) {
          const keysToBeChecked = config.hardCoded.nestedRunRegionsKeysList;
          for (let key of keysToBeChecked) {
            if (
              item[key] === "" ||
              item[key] === undefined ||
              item[key] === null
            ) {
              return {
                status: false,
                message: `${config.messages.invalidNestedRun} ${key}`,
              };
            }
          }
        }
      }
      if (enabledModels.length !== 1) {
        return {
          status: false,
          message: `${config.messages.nestedRunOnly}`,
        };
      }
      return response; //return pass if the model run is false
    } else {
      return response;
    }
  } else {
    return response;
  }
};

export const validateOneModelSelected = (selectedModels) => {
  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);

  if (selectedModels.length) {
    return {
      status: true,
      message: "Pass",
    };
  } else {
    return {
      status: false,
      message: config.messages.oneModelSelectedError,
    };
  }
};

export const validateCombinations = (selectedModels, allModels) => {
  let invalidArr = [];
  selectedModels.forEach((model) => {
    let modelConfig = model.config;
    let modelId = model.id;
    for (let key in Object.keys(modelConfig)) {
      if (key === "combinations") {
        let globalModel = allModels.find(
          (currModel) => currModel.id === modelId
        );
        let globalCombinationsData = globalModel.local_parameters.find(
          (lp) => lp.id === "combinations"
        ).data;
        let userCombinationsData = modelConfig[key];
        userCombinationsData.forEach((combinationRow) => {
          for (let key2 in Object.keys(combinationRow)) {
            let value = combinationRow[key2];
            let minVal = globalCombinationsData.find(
              (row) => row.id === key2
            ).minValue;
            let maxVal = globalCombinationsData.find(
              (row) => row.id === key2
            ).maxValue;
            if (
              parseFloat(value) < parseFloat(minVal) ||
              parseFloat(value) > parseFloat(maxVal) ||
              isNaN(value) ||
              value === ""
            ) {
              invalidArr.push(model);
            }
          }
        });
      }
    }
  });
  if (invalidArr.length) {
    return {
      status: false,
      message: `Invalid input in ${invalidArr[0].name}`,
    };
  } else {
    return { status: true, message: "Pass" };
  }
};

export const validateDatasetWithLocal = (configUserInputs, selectedModels) => {
  selectedModels = selectedModels.filter((model) => model.run);
  let differenceInDatasets = [];
  let invalidDatasetArr = [];
  let uniqueDataDources = [];

  selectedModels.forEach((model) => {
    //find unique data_sources based on pipeline_dataset_id
    uniqueDataDources = new Set(
      model.data_sources.map((item) => item.pipeline_dataset_id)
    );
    //Datasets with duplicate pipeline_dataset_id
    differenceInDatasets = model.data_sources.filter(
      (x) => ![...uniqueDataDources].includes(x.id)
    );

    if (model.data_sources.length === [...uniqueDataDources].length) {
      invalidDatasetArr = invalidDatasetArr;
    } else {
      if (differenceInDatasets.length) {
        invalidDatasetArr.push({ model: model.name });
      }
    }
  });

  if (invalidDatasetArr.length) {
    return {
      status: false,
      message: `Can't add config with dataset local and platform selected for ${invalidDatasetArr[0].model}`,
    };
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateRegionGranModelEnable = (
  configUserInputs,
  selectedModels
) => {
  let unallowedModelsList = [
    config.hardCoded.diffDiffId,
    config.hardCoded.regBudId,
    config.hardCoded.salesUpliftId,
  ];
  let selectedModelsIds = selectedModels
    .filter((model) => model.run)
    .map((model) => model.id);

  let regionType = configUserInputs.regionGranType.toLowerCase();
  if (regionType === "national") {
    let intersection = selectedModelsIds.filter((x) =>
      unallowedModelsList.includes(x)
    );
    if (intersection.length) {
      return {
        status: false,
        message: config.messages.validateRegionGranModelEnable,
      };
    } else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateRegionGranModelEnableForMMT = (
  configUserInputs,
  selectedModels
) => {
  let unallowedModelsList = [config.hardCoded.mmtId];
  let selectedModelsIds = selectedModels
    .filter((model) => model.run)
    .map((model) => model.id);

  let regionType = configUserInputs.regionGranType.toLowerCase();
  if (regionType !== "national") {
    let intersection = selectedModelsIds.filter((x) =>
      unallowedModelsList.includes(x)
    );
    if (intersection.length) {
      return {
        status: false,
        message: config.messages.validateRegionGranModelEnableForMMT,
      };
    } else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateSalesMinMaxForMMT = (configUserInputs, selectedModels) => {
  let mmtModel = selectedModels.find(
    (model) => model.id === config.hardCoded.mmtId
  );
  if (mmtModel?.run) {
    let sales_filter_max = mmtModel.config.sales_filter_max;
    let sales_filter_min = mmtModel.config.sales_filter_min;
    if (Math.abs(sales_filter_max - sales_filter_min) > 50) {
      return {
        status: false,
        message: `${config.salesRangeError} in MMT model`,
      };
    } else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateDiffnDiffCampaign = (selectedModels, checkDERunConfig) => {
  let diffNDiffModel = selectedModels.find(
    (model) => model.id === config.hardCoded.diffDiffId
  );

  if (diffNDiffModel?.run) {
    let testRegionsLength =
      diffNDiffModel.config.region_granularity.test_control_regions.test[0]
        ?.regions.length; // To check if atlease 1 test row selected

    let controlRegionsLength =
      diffNDiffModel.config.region_granularity.test_control_regions.control[0]
        ?.regions.length; // To check if atlease 1 controlrow selected

    let testCampaignDates =
      diffNDiffModel.config.region_granularity.test_control_regions.test;
    let checkForDates = [
      "campaign_start_date",
      "campaign_end_date",
      "pre_campaign_end_date",
      "pre_campaign_start_date",
    ];
    let invalidData = [];

    testCampaignDates.forEach((elem) => {
      let selectedCampaign = elem.campaigns[0]; // object
      for (let key in selectedCampaign) {
        if (
          elem.regions.length &&
          checkForDates.includes(key) &&
          selectedCampaign[key] === ""
        ) {
          //Regions available with no dates selected
          invalidData.push({ id: key });
        }
      }
    });

    if (!testRegionsLength) {
      return {
        status: false,
        message:
          "Please select at least 1 test group parameter in Diff in Diff Model",
      };
    } else if (!controlRegionsLength) {
      return {
        status: false,
        message:
          "Please select at least 1 control group parameter in Diff in Diff Model",
      };
    } else if (invalidData.length && checkDERunConfig) {
      return {
        status: false,
        message: `Please select ${invalidData[0].id
          .split("_")
          .map((e) => e[0].toUpperCase() + e.slice(1))
          .join(" ")} in Diff in Diff Model`,
      };
    } else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateRegressionBudgetCombiations = (
  configUserInputs,
  checkDERunConfig
) => {
  let regBudgetModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.regBudId
  );
  if (
    regBudgetModel?.run &&
    regBudgetModel?.config.rbt_run_type === config.hardCoded.rbtRunId
  ) {
    let testRegionsLength =
      regBudgetModel.config.region_granularity.test_dark_regions.test[0]
        ?.regions.length; // To check if atlease 1 test row selected

    let controlRegionsLength =
      regBudgetModel.config.region_granularity.test_dark_regions.dark[0]
        ?.regions.length; // To check if atlease 1 controlrow selected

    if (!regBudgetModel.config.combinations.length && checkDERunConfig) {
      return {
        status: false,
        message: config.messages.regressionBudgetCombinations,
      };
    }
    if (!testRegionsLength) {
      return {
        status: false,
        message:
          "Please select at least 1 test group parameter in Regression budget Model",
      };
    } else if (!controlRegionsLength) {
      return {
        status: false,
        message:
          "Please select at least 1 dark group parameter in Regression budget Model",
      };
    }
    return {
      status: true,
      message: "Pass",
    };
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateAutoMlTestGroupParameteres = (selectedModels) => {
  let automlModel = selectedModels.find(
    (model) => model.id === config.hardCoded.autoMlId
  );

  if (
    automlModel?.run &&
    automlModel?.config.region_granularity.type.toLowerCase() !== "national" &&
    (automlModel?.config.run_type === config.hardCoded.pilotRunId ||
      automlModel?.config.run_type === config.hardCoded.adstockRunId)
  ) {
    let testRegionsLength =
      automlModel.config.region_granularity.test_regions?.test[0]?.regions
        .length; // To check if atlease 1 test row selected

    // let controlRegionsLength =
    //   automlModel.config.region_granularity.test_control_regions?.control[0]
    //     ?.regions.length; // To check if atlease 1 controlrow selected

    if (!testRegionsLength) {
      return {
        status: false,
        message:
          "Please select at least 1 test group parameter in AutoML Model",
      };
    }
    // else if (!controlRegionsLength) {
    //   return {
    //     status: false,
    //     message:
    //       "Please select at least 1 control group parameter in AutoML Model",
    //   };
    // }
    else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateAutoMlBudgetTestGroupParameteres = (selectedModels) => {
  let autoMlBudgetModel = selectedModels.find(
    (model) => model.id === config.hardCoded.autoMlBudgetId
  );

  if (autoMlBudgetModel?.run) {
    let testRegionsLength =
      autoMlBudgetModel.config.region_granularity.test_regions?.test[0]?.regions
        .length; // To check if atlease 1 test row selected

    // let controlRegionsLength =
    //   autoMlBudgetModel.config.region_granularity.test_control_regions
    //     ?.control[0]?.regions.length; // To check if atlease 1 controlrow selected

    if (!testRegionsLength) {
      return {
        status: false,
        message:
          "Please select at least 1 test group parameter in AutoML Budget Model",
      };
    }
    // else if (!controlRegionsLength) {
    //   return {
    //     status: false,
    //     message:
    //       "Please select at least 1 control group parameter in AutoML Budget Model",
    //   };
    // }
    else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateSalesUpliftTestGroupParameteres = (selectedModels) => {
  let salesUpliftModel = selectedModels.find(
    (model) => model.id === config.hardCoded.salesUpliftId
  );

  if (salesUpliftModel?.run) {
    let testRegionsLength =
      salesUpliftModel.config.region_granularity.test_dark_regions?.test[0]
        ?.regions.length; // To check if atlease 1 test row selected

    let controlRegionsLength =
      salesUpliftModel.config.region_granularity.test_dark_regions?.dark[0]
        ?.regions.length; // To check if atlease 1 controlrow selected

    if (!testRegionsLength) {
      return {
        status: false,
        message:
          "Please select at least 1 test group parameter in Sales uplift Model",
      };
    } else if (!controlRegionsLength) {
      return {
        status: false,
        message:
          "Please select at least 1 dark group parameter in Sales uplift Model",
      };
    } else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateManualFileUploaded = (selectedModels) => {
  let mmtModel = selectedModels.find(
    (model) => model.id === config.hardCoded.mmtId
  );
  let fileRequiredRBTModel = selectedModels.find(
    (model) => model.id === config.hardCoded.regBudId
  );
  let fileRequiredSULiftModel = selectedModels.find(
    (model) => model.id === config.hardCoded.salesUpliftId
  );
  let fileRequiredDiffModel = selectedModels.find(
    (model) => model.id === config.hardCoded.diffDiffId
  );
  let fileRequiredAutoMLModel = selectedModels.find(
    (model) => model.id === config.hardCoded.autoMlId
  );
  let fileRequiredAutoMLBudModel = selectedModels.find(
    (model) => model.id === config.hardCoded.autoMlBudgetId
  );

  if (mmtModel?.run) {
    let inputAvailable =
      mmtModel.config?.files && mmtModel.config.files.hasOwnProperty("input"); // To check if at least 1 input file uploaded
    if (!inputAvailable) {
      return {
        status: false,
        message: "Please select at least 1 input file in MMT Model",
      };
    }
  }
  if (
    fileRequiredRBTModel?.run &&
    fileRequiredRBTModel?.config.rbt_run_type === config.hardCoded.rbtRunId
  ) {
    let testInputAvailable =
      fileRequiredRBTModel.config.region_granularity.test_dark_regions?.test[0]?.files.hasOwnProperty(
        "input"
      ); // To check if atlease 1 test row selected
    if (!testInputAvailable) {
      return {
        status: false,
        message:
          "Please select at least 1 test input file in Regression budget model",
      };
    }
  }
  if (fileRequiredSULiftModel?.run) {
    let testInputAvailable =
      fileRequiredSULiftModel.config.region_granularity.test_dark_regions?.test[0]?.files.hasOwnProperty(
        "input"
      ); // To check if atlease 1 test row selected
    if (!testInputAvailable) {
      return {
        status: false,
        message:
          "Please select at least 1 test input file in Sales uplift Model",
      };
    }
  }
  if (fileRequiredDiffModel?.run) {
    let testInputAvailable =
      fileRequiredDiffModel.config.region_granularity.test_control_regions?.test[0]?.files.hasOwnProperty(
        "input"
      ); // To check if atlease 1 test row selected
    let controlRegionsLength =
      fileRequiredDiffModel.config.region_granularity.test_control_regions
        ?.control[0]?.regions.length; // To check if atlease 1 controlrow selected
    if (!testInputAvailable) {
      return {
        status: false,
        message:
          "Please select at least 1 test input file in Diff in Diff Model",
      };
    }
    if (!controlRegionsLength) {
      return {
        status: false,
        message:
          "Please select at least 1 control group parameter in Diff in Diff Model",
      };
    }
  }
  if (
    fileRequiredAutoMLModel?.run &&
    fileRequiredAutoMLModel?.config.region_granularity.type.toLowerCase() ===
      "national" &&
    (fileRequiredAutoMLModel?.config.run_type === config.hardCoded.pilotRunId ||
      fileRequiredAutoMLModel?.config.run_type ===
        config.hardCoded.adstockRunId)
  ) {
    let nationalInputAvailable =
      fileRequiredAutoMLModel.config.region_granularity.test_regions?.files?.hasOwnProperty(
        "input"
      ); // To check if atlease 1 file row selected
    if (!nationalInputAvailable) {
      return {
        status: false,
        message: "Please select at least 1 input file in AutoML Model",
      };
    }
  }
  if (
    fileRequiredAutoMLModel?.run &&
    fileRequiredAutoMLModel?.config.region_granularity.type.toLowerCase() !==
      "national" &&
    (fileRequiredAutoMLModel?.config.run_type === config.hardCoded.pilotRunId ||
      fileRequiredAutoMLModel?.config.run_type ===
        config.hardCoded.adstockRunId)
  ) {
    let testInputAvailable =
      fileRequiredAutoMLModel.config.region_granularity.test_regions?.test[0]?.files.hasOwnProperty(
        "input"
      ); // To check if atlease 1 test row selected
    // let controlInputAvailable =
    //   fileRequiredAutoMLModel.config.region_granularity.test_control_regions?.control[0]?.files.hasOwnProperty(
    //     "input"
    //   );
    // To check if atlease 1 test row selected
    if (!testInputAvailable) {
      return {
        status: false,
        message: "Please select at least 1 test input file in AutoML Model",
      };
    }
    // else if (!controlInputAvailable) {
    //   return {
    //     status: false,
    //     message: "Please select at least 1 control input file in AutoML Model",
    //   };
    // }
  }
  if (fileRequiredAutoMLBudModel?.run) {
    let testInputAvailable =
      fileRequiredAutoMLBudModel.config.region_granularity.test_regions?.test[0]?.files.hasOwnProperty(
        "input"
      ); // To check if atlease 1 test row selected
    // let controlInputAvailable =
    //   fileRequiredAutoMLBudModel.config.region_granularity.test_control_regions?.control[0]?.files.hasOwnProperty(
    //     "input"
    //   ); // To check if atlease 1 test row selected
    if (!testInputAvailable) {
      return {
        status: false,
        message:
          "Please select at least 1 test input file in AutoML Budget Model",
      };
    }
    // else if (!controlInputAvailable) {
    //   return {
    //     status: false,
    //     message:
    //       "Please select at least 1 control input file in AutoML Budget Model",
    //   };
    // }
  }
  return {
    status: true,
    message: "Pass",
  };
};

const validateDetails = (inputData) => {
  let invalidArr = [];
  Object.entries(inputData).forEach(([key, value]) => {
    if (
      value.sl_limit < config.hardCoded.adStockMinLimit ||
      value.sl_limit > config.hardCoded.adStockMaxLimit ||
      value.sl_limit === "" ||
      isNaN(value.sl_limit)
    ) {
      invalidArr.push({ id: "sl_limit", key: key });
    }
    if (
      value.su_limit < config.hardCoded.adStockMinLimit ||
      value.su_limit > config.hardCoded.adStockMaxLimit ||
      value.su_limit === "" ||
      isNaN(value.su_limit)
    ) {
      invalidArr.push({ id: "su_limit", key: key });
    }
  });
  return invalidArr;
};

export const validateManualKpiDetails = (selectedModels) => {
  let fileRequiredAutoMLModel = selectedModels.find(
    (model) => model.id === config.hardCoded.autoMlId
  );
  let fileRequiredAutoMLBudModel = selectedModels.find(
    (model) => model.id === config.hardCoded.autoMlBudgetId
  );

  if (
    fileRequiredAutoMLModel?.run &&
    fileRequiredAutoMLModel?.config.region_granularity.type.toLowerCase() ===
      "national" &&
    (fileRequiredAutoMLModel?.config.run_type === config.hardCoded.pilotRunId ||
      fileRequiredAutoMLModel?.config.run_type ===
        config.hardCoded.adstockRunId)
  ) {
    let nationalKpiDetails = {};

    if (
      fileRequiredAutoMLModel.config.region_granularity.test_regions?.files
        ?.kpi_details instanceof Array
    ) {
      nationalKpiDetails =
        fileRequiredAutoMLModel.config.region_granularity.test_regions?.files
          ?.kpi_details[0];
    } else {
      nationalKpiDetails =
        fileRequiredAutoMLModel.config.region_granularity.test_regions?.files
          ?.kpi_details;
    }
    let invalidArr = validateDetails(nationalKpiDetails);
    if (invalidArr.length) {
      return {
        status: false,
        message: `Please select correct values in ${invalidArr[0].key} KPI, ${invalidArr[0].id} in AutoML model`,
      };
    }
  }
  if (
    fileRequiredAutoMLModel?.run &&
    fileRequiredAutoMLModel?.config.region_granularity.type.toLowerCase() !==
      "national" &&
    (fileRequiredAutoMLModel?.config.run_type === config.hardCoded.pilotRunId ||
      fileRequiredAutoMLModel?.config.run_type ===
        config.hardCoded.adstockRunId)
  ) {
    let invalidTestArr = [],
      invalidControlArr = [];
    fileRequiredAutoMLModel.config.region_granularity.test_regions?.test.forEach(
      (elem) => {
        let testKpiDetails = {};
        if (!isEmpty(elem?.files)) {
          if (elem?.files?.kpi_details instanceof Array) {
            testKpiDetails = elem?.files?.kpi_details?.[0];
          } else {
            testKpiDetails = elem?.files?.kpi_details;
          }
          invalidTestArr = validateDetails(testKpiDetails);
        }
      }
    );
    // fileRequiredAutoMLModel.config.region_granularity.test_control_regions?.control.forEach(
    //   (elem) => {
    //     let testKpiDetails = {};
    //     if (elem?.files?.kpi_details instanceof Array) {
    //       testKpiDetails = elem?.files?.kpi_details?.[0];
    //     } else {
    //       testKpiDetails = elem?.files?.kpi_details;
    //     }
    //     invalidControlArr = validateDetails(testKpiDetails);
    //   }
    // );
    if (invalidTestArr.length) {
      return {
        status: false,
        message: `Please select correct values in test cell with ${invalidTestArr[0].key} KPI, ${invalidTestArr[0].id} selection in AutoML model`,
      };
    }
    // else if (invalidControlArr.length) {
    //   return {
    //     status: false,
    //     message: `Please select correct values in control cell with ${invalidControlArr[0].key} KPI, ${invalidControlArr[0].id} selection in AutoML model`,
    //   };
    // }
  }
  if (fileRequiredAutoMLBudModel?.run) {
    let invalidTestArr = [],
      invalidControlArr = [];
    fileRequiredAutoMLBudModel.config.region_granularity.test_regions?.test.forEach(
      (elem) => {
        let testKpiDetails = {};
        if (!isEmpty(elem?.files)) {
          if (elem?.files?.kpi_details instanceof Array) {
            testKpiDetails = elem?.files?.kpi_details?.[0];
          } else {
            testKpiDetails = elem?.files?.kpi_details;
          }
        }
        invalidTestArr = validateDetails(testKpiDetails);
      }
    );
    // fileRequiredAutoMLBudModel.config.region_granularity.test_control_regions?.control.forEach(
    //   (elem) => {
    //     let testKpiDetails = {};
    //     if (elem?.files?.kpi_details instanceof Array) {
    //       testKpiDetails = elem?.files?.kpi_details?.[0];
    //     } else {
    //       testKpiDetails = elem?.files?.kpi_details;
    //     }
    //     invalidControlArr = validateDetails(testKpiDetails);
    //   }
    // );
    if (invalidTestArr.length) {
      return {
        status: false,
        message: `Please select correct values in test cell with ${invalidTestArr[0].key} KPI, ${invalidTestArr[0].id} selection in AutoML Budget model`,
      };
    }
    // else if (invalidControlArr.length) {
    //   return {
    //     status: false,
    //     message: `Please select correct values in control cell with ${invalidControlArr[0].key} KPI, ${invalidControlArr[0].id} selection in AutoML Budget model`,
    //   };
    // }
  }
  return {
    status: true,
    message: "Pass",
  };
};

export const validateRegressionBudgetCombiationsOnly = (configUserInputs) => {
  let regBudgetModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.regBudId
  );
  if (
    regBudgetModel?.run &&
    regBudgetModel?.config.rbt_run_type === config.hardCoded.rbtRunId
  ) {
    if (!regBudgetModel.config.combinations.length) {
      return {
        status: false,
        message: config.messages.regressionBudgetCombinations,
      };
    } else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateVolumeFieldAvailable = (configUserInputs) => {
  let diffInDiffModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.diffDiffId
  );
  if (diffInDiffModel?.run) {
    let isVolumeDetailsCorrect = true;
    let testRegions =
      diffInDiffModel.config.region_granularity.test_control_regions?.test;
    for (let i = 0; i < testRegions.length - 1; i++) {
      if (testRegions[i + 1]?.files.hasOwnProperty("volume_details")) {
        isVolumeDetailsCorrect = isEqualArray(
          testRegions[i]?.files?.volume_details?.sort(),
          testRegions[i + 1]?.files?.volume_details?.sort()
        );
      }
      if (!isVolumeDetailsCorrect) {
        break;
      }
    }

    if (!isVolumeDetailsCorrect) {
      return {
        status: false,
        message: "Found mismatch in volume details for Diff in Diff model",
      };
    } else if (!diffInDiffModel.config.volume_field) {
      return {
        status: false,
        message: config.messages.volumeFieldAvailableMessageDiffAndDiff,
      };
    } else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateColumnDetailAvailable = (configUserInputs) => {
  let isErrorArr = [];
  configUserInputs.forEach((model) => {
    if (config.hardCoded.rbtAndSuIds.includes(model.id)) {
      if (model?.run) {
        let isColumnDetailsCorrect = true;
        let testRegions =
          model.config.region_granularity.test_dark_regions?.test;
        for (let i = 0; i < testRegions.length - 1; i++) {
          if (testRegions[i + 1]?.files.hasOwnProperty("column_details")) {
            isColumnDetailsCorrect = isEqualArray(
              testRegions[i]?.files?.column_details?.sort(),
              testRegions[i + 1]?.files?.column_details?.sort()
            );
          }
          if (!isColumnDetailsCorrect) {
            break;
          }
        }

        if (!isColumnDetailsCorrect) {
          isErrorArr.push({
            modelID: model.id,
            message: `Found mismatch in column details for ${model.name} model`,
          });
        } else if (model.config.cols_to_force_in.length === 0) {
          isErrorArr.push({
            modelID: model.id,
            message: `${config.messages.volumeFieldAvailableMessage} in ${model.name}`,
          });
        }
      }
    }
  });
  if (isErrorArr.length) {
    return {
      status: false,
      message: isErrorArr[0].message,
    };
  }
  return {
    status: true,
    message: "Pass",
  };
};

export const validateMinimumDatapointColumnSelected = (configUserInputs) => {
  let mmtModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.mmtId
  );
  if (mmtModel?.run) {
    if (!mmtModel.config.minimum_datapoint_column) {
      return {
        status: false,
        message: "Please select a Primary Kpi in MMT",
      };
    } else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateAutoMlCampaign = (configUserInputs) => {
  let autoMlModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.autoMlId
  );
  if (autoMlModel) {
    if (
      autoMlModel.run &&
      !autoMlModel.config.campaigns.length &&
      autoMlModel.config.run_type === config.hardCoded.pilotRunId
    ) {
      return {
        status: false,
        message: config.messages.autoMlCampaign,
      };
    }
    return {
      status: true,
      message: "Pass",
    };
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateAutoMLMandatoryKpi = (selectedModels, allModels) => {
  let neilsenKpi, salesValueKpi;

  //filter models that disabled
  selectedModels = selectedModels.filter(
    (model) => model.run && model.id === config.hardCoded.autoMlId
  );

  selectedModels.forEach((model) => {
    if (model.id === config.hardCoded.autoMlId) {
      neilsenKpi = model.data_sources.find((neilsenData) =>
        config.hardCoded.radioDatasetList.some(
          (dataset) => dataset.toLowerCase() === neilsenData.type.toLowerCase()
        )
      );
      if (neilsenKpi) {
        salesValueKpi = neilsenKpi.kpi_list.find((item) =>
          config.hardCoded.mandatoryTargetVariable.some(
            (kpi) => kpi.toLowerCase() === item.toLocaleLowerCase()
          )
        );
      }
    }
  });

  if (selectedModels.length && neilsenKpi) {
    if (!salesValueKpi) {
      return {
        status: false,
        message: `Please select at least one target variable in ${selectedModels[0]?.name}`,
      };
    } else {
      return { status: true, message: "Pass" };
    }
  } else {
    return { status: true, message: "Pass" };
  }
};

export const validateAutoMLBudgetMandatoryKpi = (selectedModels, allModels) => {
  let neilsenKpi, salesValueKpi;
  const findAutoMlBudgetModel = allModels.find(
    (model) => model.id === config.hardCoded.autoMlBudgetId
  );
  //filter models that disabled
  selectedModels = selectedModels.filter(
    (model) => model.run && model.id === config.hardCoded.autoMlBudgetId
  );

  selectedModels.forEach((model) => {
    if (model.id === config.hardCoded.autoMlBudgetId) {
      neilsenKpi = model.data_sources.find((neilsenData) =>
        config.hardCoded.radioDatasetList.some(
          (dataset) => dataset.toLowerCase() === neilsenData.type.toLowerCase()
        )
      );
      if (neilsenKpi) {
        salesValueKpi = neilsenKpi.kpi_list.find((item) =>
          config.hardCoded.mandatoryTargetVariable.some(
            (kpi) => kpi.toLowerCase() === item.toLocaleLowerCase()
          )
        );
      }
    }
  });

  let checkMandatoryKPI;
  selectedModels.forEach((model) => {
    if (model.id === config.hardCoded.autoMlBudgetId) {
      let filterMediaDatasets = model.data_sources.filter(
        (dataset) => dataset.type === "MEDIA"
      );
      checkMandatoryKPI = filterMediaDatasets?.reduce((acc, curr) => {
        const findMandatory_kpi_list = findAutoMlBudgetModel.dataset_ids.find(
          (dataset) => dataset.id === curr.id
        )?.mandatory_kpi_list;
        const filterMandatoryKpiList = findMandatory_kpi_list?.filter(
          (kpi) => !curr.kpi_list.includes(kpi)
        );
        if (filterMandatoryKpiList && filterMandatoryKpiList.length > 0) {
          acc.push({
            id: curr.id,
            name: curr.name,
            kpiList: filterMandatoryKpiList,
          });
        }
        return acc;
      }, []);
    }
  });

  if (checkMandatoryKPI && checkMandatoryKPI.length > 0) {
    return {
      status: false,
      message: `Please select all mandatory KPIS for ${checkMandatoryKPI[0]?.name} Dataset in AutoML Budget Model`,
    };
  }

  if (selectedModels.length && neilsenKpi) {
    if (!salesValueKpi) {
      return {
        status: false,
        message: `Please select at least one target variable in ${selectedModels[0]?.name}`,
      };
    } else {
      return { status: true, message: "Pass" };
    }
  } else {
    return { status: true, message: "Pass" };
  }
};

export const validateAutoMLMediaDataset = (selectedModels, allModels) => {
  let mediaDataset,
    runType = true;

  //filter models that disabled
  selectedModels = selectedModels.filter(
    (model) => model.run && model.id === config.hardCoded.autoMlId
  );

  selectedModels.forEach((model) => {
    if (
      model.id === config.hardCoded.autoMlId &&
      (model.config.run_type === config.hardCoded.pilotRunId ||
        model.config.run_type === config.hardCoded.adstockRunId)
    ) {
      mediaDataset = model.data_sources.find(
        (mediaData) =>
          mediaData.type.toLowerCase() ===
            config.hardCoded.datasetMediaType.toLocaleLowerCase() &&
          mediaData.enable_adstock === true
      );
      if (!mediaDataset) {
        runType = false;
      }
    }
  });

  if (selectedModels.length && !runType) {
    return {
      status: false,
      message: `Please enable adstock parameters for at least one media dataset in ${selectedModels[0]?.id}`,
    };
  } else {
    return { status: true, message: "Pass" };
  }
};

export const validateAutoMLBudgetMediaDataset = (selectedModels, allModels) => {
  let mediaDataset,
    runType = true;

  //filter models that disabled
  selectedModels = selectedModels.filter(
    (model) => model.run && model.id === config.hardCoded.autoMlBudgetId
  );

  selectedModels.forEach((model) => {
    if (model.id === config.hardCoded.autoMlBudgetId) {
      mediaDataset = model.data_sources.find(
        (mediaData) =>
          mediaData.type.toLowerCase() ===
            config.hardCoded.datasetMediaType.toLocaleLowerCase() &&
          mediaData.enable_adstock === true
      );
      if (!mediaDataset) {
        runType = false;
      }
    }
  });

  if (selectedModels.length && !runType) {
    return {
      status: false,
      message: `Please enable adstock parameters for at least one media dataset in ${selectedModels[0]?.id}`,
    };
  } else {
    return { status: true, message: "Pass" };
  }
};

export const validateAutoMlPilotDatesSelected = (
  configUserInputs,
  selectedModels
) => {
  let autoMlModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.autoMlId
  );

  let historicWeekDate = {
    min: configUserInputs.startDate,
    max: configUserInputs.endDate,
  };
  if (
    configUserInputs.global_week_date_selection ===
    config.hardCoded.historicWeek
  ) {
    historicWeekDate = getDatesFromWeek(configUserInputs.historic_week_data);
  }

  if (
    autoMlModel &&
    autoMlModel.run &&
    autoMlModel.config.run_type !== config.hardCoded.nestedRunId
  ) {
    if (
      autoMlModel.config?.pilot_start_date === "" ||
      autoMlModel.config?.pilot_end_date === ""
    ) {
      return {
        status: false,
        message: "Please select Pilot Start Date and Pilot End Date.",
      };
    }
    // else if (
    //   autoMlModel.config?.pilot_start_date === "" ||
    //   autoMlModel.config?.pilot_end_date === ""
    // ) {
    //   if (
    //     autoMlModel.config.pilot_start_date &&
    //     autoMlModel.config.pilot_end_date === ""
    //   ) {
    //     return {
    //       status: false,
    //       message:
    //         "Pilot end date can't be empty if pilot start date is selected",
    //     };
    //   } else if (
    //     autoMlModel.config.pilot_start_date === "" &&
    //     autoMlModel.config.pilot_end_date
    //   ) {
    //     return {
    //       status: false,
    //       message:
    //         "Pilot start date can't be empty if pilot end date is selected",
    //     };
    //   } else {
    //     return {
    //       status: true,
    //       message: "Pass",
    //     };
    //   }
    // }
    else if (
      autoMlModel.config?.pilot_start_date &&
      autoMlModel.config?.pilot_end_date
    ) {
      if (
        !configUserInputs.is_config_manual &&
        (!checkDatesInRange(
          "start_date",
          historicWeekDate.min,
          autoMlModel.config.pilot_start_date
        ) ||
          !checkDatesInRange(
            "end_date",
            historicWeekDate.max,
            autoMlModel.config.pilot_end_date
          ))
      ) {
        return {
          status: false,
          message:
            config.messages.pilotDatesInRangevalidation +
            "\n Global Start Date: " +
            convertDateToFormat(moment(historicWeekDate.min), "DD MMMM, YYYY") +
            " | " +
            " Global End Date: " +
            convertDateToFormat(moment(historicWeekDate.max), "DD MMMM, YYYY") +
            "\n Pilot Start Date: " +
            convertDateToFormat(
              moment(autoMlModel.config.pilot_start_date),
              "DD MMMM, YYYY"
            ) +
            " | " +
            " Pilot End Date: " +
            convertDateToFormat(
              moment(autoMlModel.config.pilot_end_date),
              "DD MMMM, YYYY"
            ),
        };
      } else if (
        !checkIsAfterDate(
          autoMlModel.config.pilot_start_date,
          autoMlModel.config.pilot_end_date
        )
      ) {
        return {
          status: false,
          message: config.messages.pilotStartDateEndDateValidation,
        };
      } else {
        return {
          status: true,
          message: "Pass",
        };
      }
    } else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateAutoMlBudgetPilotDatesSelected = (
  configUserInputs,
  selectedModels
) => {
  let autoMlModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.autoMlBudgetId
  );

  let historicWeekDate = {
    min: configUserInputs.startDate,
    max: configUserInputs.endDate,
  };
  if (
    configUserInputs.global_week_date_selection ===
    config.hardCoded.historicWeek
  )
    historicWeekDate = getDatesFromWeek(configUserInputs.historic_week_data);

  if (autoMlModel && autoMlModel.run) {
    if (
      autoMlModel.config?.pilot_start_date === "" ||
      autoMlModel.config?.pilot_end_date === ""
    ) {
      return {
        status: false,
        message: config.messages.pilotDateValidationAutoMlBudget,
      };
    } else if (
      !checkDatesAfterGlobalDates(
        historicWeekDate.max,
        autoMlModel.config.pilot_start_date
      ) ||
      !checkDatesAfterGlobalDates(
        historicWeekDate.max,
        autoMlModel.config.pilot_end_date
      )
    ) {
      return {
        status: false,
        message:
          config.messages.pilotDatesInRangevalidationAutoMlBudget +
          "\n Global Start Date: " +
          convertDateToFormat(moment(historicWeekDate.min), "DD MMMM, YYYY") +
          " | " +
          " Global End Date: " +
          convertDateToFormat(moment(historicWeekDate.max), "DD MMMM, YYYY") +
          "\n Pilot Start Date: " +
          convertDateToFormat(
            moment(autoMlModel.config.pilot_start_date),
            "DD MMMM, YYYY"
          ) +
          " | " +
          " Pilot End Date: " +
          convertDateToFormat(
            moment(autoMlModel.config.pilot_end_date),
            "DD MMMM, YYYY"
          ),
      };
    } else if (
      !checkIsAfterDate(
        autoMlModel.config.pilot_start_date,
        autoMlModel.config.pilot_end_date
      )
    ) {
      return {
        status: false,
        message: config.messages.pilotStartDateEndDateValidationAutoMlBudget,
      };
    } else {
      return {
        status: true,
        message: "Pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateSalesUpliftTestGroupParameteresPilotStartDate = (
  selectedModels,
  configUserInputs,
  checkDERunConfig
) => {
  let salesUpliftModel = selectedModels.find(
    (model) => model.id === config.hardCoded.salesUpliftId
  );
  let global_start_date = configUserInputs.startDate,
    global_end_date = configUserInputs.endDate;

  let validationArr = [];

  if (
    configUserInputs.global_week_date_selection ===
    config.hardCoded.historicWeek
  ) {
    let result = getDatesFromWeek(configUserInputs.historic_week_data);
    global_start_date = result.min;
    global_end_date = result.max;
  }

  if (
    salesUpliftModel?.run &&
    checkDERunConfig &&
    !configUserInputs.is_config_manual
  ) {
    let data =
      salesUpliftModel.config.region_granularity.test_dark_regions?.test;

    for (const testData of data) {
      for (const dataSet of testData.test_group_parameters) {
        const { pilot_start, weeks_pilot } = dataSet;
        var pilot_end = new Date(pilot_start);
        pilot_end.setDate(pilot_end.getDate() + weeks_pilot * 7 - 1);
        const pilot_end_Date = new Date(pilot_end);
        if (checkDatesAfterGlobalDates(global_end_date, pilot_end_Date)) {
          validationArr.push({ pilot_start, pilot_end_Date });
        }
      }
    }
    if (validationArr.length) {
      return {
        status: false,
        message:
          config.messages.pilotDatesInRangevalidationSalesUplift +
          "\n Global Start Date: " +
          convertDateToFormat(moment(global_start_date), "DD MMMM, YYYY") +
          " | " +
          " Global End Date: " +
          convertDateToFormat(moment(global_end_date), "DD MMMM, YYYY") +
          "\n Pilot Start Date: " +
          convertDateToFormat(
            moment(validationArr[0].pilot_start),
            "DD MMMM, YYYY"
          ) +
          " | " +
          " Pilot End Date: " +
          convertDateToFormat(
            moment(validationArr[0].pilot_end_Date),
            "DD MMMM, YYYY"
          ),
      };
    } else {
      return {
        status: true,
        message: "pass",
      };
    }
  } else {
    return {
      status: true,
      message: "Pass",
    };
  }
};

export const validateAutoMlGranularitySelection = (configUserInputs) => {
  const autoMlModel = configUserInputs.models.find(
    (model) => model.id === config.hardCoded.autoMlId && model.run
  );

  if (!autoMlModel) {
    //Auto ML not selected - validation not required
    return {
      status: true,
      message: "Pass",
    };
  }

  const { data_sources: dataSources = [] } = autoMlModel || {};
  const tvSources = dataSources.filter(
    (dataset) =>
      dataset.pipeline_dataset_id === config.hardCoded.tvId ||
      dataset.name.toLowerCase().includes("tv") ||
      dataset.name.toLowerCase().includes("television")
  );
  const mediaSources = dataSources.filter(
    (dataset) => dataset.type === "MEDIA"
  );

  // For now allowing TV or Television substrings in name - Need to be changed
  if (tvSources.length === mediaSources.length) {
    // For TV - there is no target campaig
    return {
      status: true,
      message: "Pass",
    };
  }

  const emptyCampaignNames = dataSources
    .filter((dataset) => {
      const {
        channel_granularity,
        target_campaigns,
        pipeline_dataset_id,
        name,
      } = dataset;
      if (
        pipeline_dataset_id === config.hardCoded.tvId ||
        name.toLowerCase().includes("tv") ||
        name.toLowerCase().includes("television")
      ) {
        return false;
      }
      if (channel_granularity === "campaign" && !target_campaigns.length) {
        return true;
      }
      return false;
    })
    .map((dataset) => dataset.name);

  if (emptyCampaignNames.length) {
    return {
      status: false,
      message:
        "AutoML: There are one or more datasets (" +
        emptyCampaignNames.join(", ") +
        ") with no target campaign. Please add at least one target campaign to proceed.",
    };
  }

  return {
    status: true,
    message: "Pass",
  };
};

export const validateCategoryDataset = (configUserInputs, selectedModels) => {
  let invalidArr = [];
  let selectedNielsenDataset;
  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);

  selectedModels.forEach((model) => {
    selectedNielsenDataset = model.data_sources.filter(
      (neilsenData) =>
        neilsenData.pipeline_dataset_id === config.hardCoded.neilsenNonCompId
    );
    if (selectedNielsenDataset) return false;
    else {
      invalidArr.push({ selectedNielsenDataset, modelId: model.id });
    }
  });

  if (configUserInputs.categoryData !== -1 && selectedNielsenDataset.length > 0)
    configUserInputs.categoryData.forEach((obj) => {
      if (obj.id === "400") {
        invalidArr.push(obj);
      }
    });

  if (invalidArr.length)
    return {
      status: false,
      message: invalidArr[0].displayText,
    };
  return { status: true, message: "Pass" };
};

export const validateMMTKpi = (selectedModels, allModels) => {
  let invalidArr = [];
  let selectedSalesDatasetsLen, selectedNeilsenDatasetsLen;

  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);
  selectedModels = selectedModels.filter(
    (model) => model.id === config.hardCoded.mmtId
  );

  selectedModels.forEach((model) => {
    if (model.id === config.hardCoded.mmtId) {
      selectedSalesDatasetsLen = model.data_sources.find(
        (neilsenData) => neilsenData.type === config.hardCoded.datasetSalesType
      );
      selectedNeilsenDatasetsLen = model.data_sources.find(
        (neilsenData) =>
          neilsenData.type === config.hardCoded.datasetNeilsenType
      );
    }
    if (selectedSalesDatasetsLen || selectedNeilsenDatasetsLen) {
      invalidArr = invalidArr;
    } else {
      invalidArr.push({ modelId: model.id });
    }
  });

  if (invalidArr.length)
    return {
      status: false,
      message: `Please select at least one sales dataset in ${invalidArr[0].modelId}`,
    };
  return { status: true, message: "Pass" };
};

export const validateMMTMandatoryKpi = (selectedModels, allModels) => {
  let neilsenKpi, salesValueKpi;

  //filter models that disabled
  selectedModels = selectedModels.filter(
    (model) => model.run && model.id === config.hardCoded.mmtId
  );

  selectedModels.forEach((model) => {
    if (model.id === config.hardCoded.mmtId) {
      neilsenKpi = model.data_sources.find((neilsenData) =>
        config.hardCoded.radioDatasetList.some(
          (dataset) => dataset.toLowerCase() === neilsenData.type.toLowerCase()
        )
      );
      if (neilsenKpi) {
        salesValueKpi = neilsenKpi.kpi_list.find((item) =>
          config.hardCoded.mandatoryTargetVariable.some(
            (kpi) => kpi.toLowerCase() === item.toLocaleLowerCase()
          )
        );
      }
    }
  });

  if (selectedModels.length && neilsenKpi) {
    if (!salesValueKpi) {
      return {
        status: false,
        message: `Please select at least one Sales variable in ${selectedModels[0]?.name}`,
      };
    } else {
      return { status: true, message: "Pass" };
    }
  } else {
    return { status: true, message: "Pass" };
  }
};

export const validateAutoMlKpi = (selectedModels, allModels) => {
  let invalidArr = [];
  let selectedSalesDatasetsLen,
    selectedMediaDatasetsLen,
    selectedNeilsenDatasetsLen;

  //filter models that disabled
  selectedModels = selectedModels.filter((model) => model.run);
  selectedModels = selectedModels.filter(
    (model) =>
      config.hardCoded.autoMlIds.some(
        (elem) => elem.toLowerCase() === model.id.toLowerCase()
      ) && model.config.run_type !== config.hardCoded.nestedRunId
  );

  selectedModels.forEach((model) => {
    selectedMediaDatasetsLen = model.data_sources.find(
      (neilsenData) => neilsenData.type === config.hardCoded.datasetMediaType
    );
    selectedSalesDatasetsLen = model.data_sources.find(
      (neilsenData) => neilsenData.type === config.hardCoded.datasetSalesType
    );
    selectedNeilsenDatasetsLen = model.data_sources.find(
      (neilsenData) => neilsenData.type === config.hardCoded.datasetNeilsenType
    );
    if (
      (selectedSalesDatasetsLen || selectedNeilsenDatasetsLen) &&
      selectedMediaDatasetsLen
    ) {
      invalidArr = invalidArr;
    } else {
      invalidArr.push({ modelId: model.id, modelName: model.name });
    }
  });

  if (invalidArr.length)
    return {
      status: false,
      message: `Please select at least one sales and media dataset in ${invalidArr[0].modelName}`,
    };
  return { status: true, message: "Pass" };
};

export const validateHistoricWeek = (value) => {
  if (value.global_week_date_selection === config.hardCoded.historicWeek) {
    if (value.historic_week_data <= 0.0) return false;
    if (value.historic_week_data % 1 != 0) return false;
    if (value.historic_week_data > 300) return false;
    if (isNaN(value.historic_week_data)) return false;
    if (value.historic_week_data === "") return false;
  }
  return true;
};

export const validateDatesSelected = (value) => {
  if (value.global_week_date_selection === config.hardCoded.dateRange) {
    if (value.startDate == "")
      return { status: false, message: config.messages.dateSelectedValidation };
    if (value.endDate == "")
      return { status: false, message: config.messages.dateSelectedValidation };
    if (!checkIsAfterDate(value.startDate, value.endDate))
      return {
        status: false,
        message:
          config.messages.invalidDateSelection +
          "\n Available Global Start Date: " +
          convertDateToFormat(moment(value.startDate), "DD MMMM, YYYY") +
          "\n Available Global End Date: " +
          convertDateToFormat(moment(value.endDate), "DD MMMM, YYYY"),
      };
  }
  return { status: true, message: "Pass" };
};

export const validateEmptyConfigName = (name) => {
  if (name === "") return false;
  return true;
};

export const validConfigName = (name) => {
  if (name?.replace(/[^A-Za-z0-9_-]/gi, "") !== name) return false;
  return true;
};

export const validateTestControl = (type, controls, tests) => {
  if (
    type.toLowerCase() !== "national" &&
    (controls.length === 0 || tests.length === 0)
  )
    return false;
  return true;
};

export const transformKpiVariable = (kpi_list, kpi_variable_list) => {
  // console.log(`kpi_list`, kpi_list); // ["value","volume","distrubution"]
  // console.log(`kpi_variable_list`, kpi_variable_list);
  // const notAllowedKpi = kpi_list.filter(
  //   (elem) => !config.hardCoded.mandatoryTargetVariable.includes(elem)
  // );
  // console.log(`notAllowedKpi`, notAllowedKpi);
  let resultData = {};
  kpi_list.forEach((elem) => {
    let newKpiVariables = {};
    let datasetKpi = kpi_variable_list.find((value) => value.id === elem);
    datasetKpi = datasetKpi ? datasetKpi : [];
    if (datasetKpi?.kpi_parameters?.length) {
      let updatedKpiDetails = datasetKpi?.kpi_parameters.map((item) => item);
      updatedKpiDetails.forEach((elem) => {
        newKpiVariables = {
          ...newKpiVariables,
          [elem.id]:
            typeof datasetKpi?.[elem.id] !== "undefined"
              ? datasetKpi?.[elem.id]
              : datasetKpi?.kpi_parameters.find((item) => item.id === elem.id)
                  .default,
        };
      });
    } else {
      const { id, kpi_parameters, ...remaining } = datasetKpi;
      newKpiVariables = {
        ...newKpiVariables,
        ...remaining,
      };
    }
    resultData = { ...resultData, [elem]: { ...newKpiVariables } };
  });
  return resultData;
};

export const validateSelectKpiTypeForManualRun = (selectedModels) => {
  let validateArr = [];

  selectedModels.forEach((model) => {
    if (
      ((model.id === config.hardCoded.regBudId &&
        model.config.rbt_run_type !== config.hardCoded.budgetId) ||
        model.id === config.hardCoded.salesUpliftId) &&
      !model.data_sources.length &&
      model.run
    ) {
      validateArr.push({ id: model.id, name: model.name });
    }
  });
  if (validateArr.length) {
    return {
      status: false,
      message: `Please select at least one sales KPI in ${validateArr[0].name}`,
    };
  }
  return { status: true, message: "Pass" };
};

const validateRegionGranularity = (selectedModels) => {
  let validateArr = [];

  selectedModels.forEach((model) => {
    if (model.run && model.id === config.hardCoded.mmtId) {
      if (!model.config.regionType) {
        validateArr.push({ id: model.id, name: model.name });
      }
    } else if (model.run && !model?.config?.region_granularity?.region_type) {
      validateArr.push({ id: model.id, name: model.name });
    }
  });
  if (validateArr.length) {
    return {
      status: false,
      message: `Please select Region Granularity in ${validateArr[0].name}`,
    };
  }
  return { status: true, message: "Pass" };
};
const validateSeasonality = (selectedModels) => {
  let validateArr = [];
  selectedModels.map((model) => {
    if (model.run) {
      if (config.hardCoded.autoMlIds.includes(model.id)) {
        if (model.config.seasonality) {
          const test_regions = model.config.region_granularity.test_regions;
          if (model.config.region_granularity.region_type === "dma") {
            const testCellDma = test_regions.test.find(
              (el) => el.files.seasonality_flag
            );
            if (testCellDma) {
              let fileName = testCellDma.files.input[0].split("/");
              fileName = fileName[fileName.length - 1];
              validateArr.push({
                id: model.id,
                name: model.name,
                fileName: fileName,
              });
            }
          }
          if (model.config.region_granularity.region_type === "national") {
            if (test_regions.files.seasonality_flag) {
              let fileName = test_regions.files.input[0].split("/");
              fileName = fileName[fileName.length - 1];
              validateArr.push({
                id: model.id,
                name: model.name,
                fileName: fileName,
              });
            }
          }
        }
      }
    }
    return 0;
  });
  // The driver 'seasonality' is present under Driver column in the {filename.csv} and 'Seasonality' is also checked in the Configuration tab.
  //  Please remove or rename the driver "seasonality" in the uploaded file OR uncheck the Seasonality checkbox to proceed with the run
  if (validateArr.length) {
    return {
      status: false,
      message: `
      In ${validateArr[0].name} Model, The driver 'seasonality' is present under Driver column in the ${validateArr[0].fileName} file
      and 'Seasonality' is also checked in the Configuration tab.
      Please remove or rename the driver "seasonality" in the uploaded file OR uncheck the Seasonality checkbox to proceed with the run
      `,
    };
  }
  return { status: true, message: "Pass" };
};
const validateMediaFilter = (configUserInputs, models) => {
  let commonString = "Please select at least one";
  let string = "";
  if (
    models.find(
      (model) => config.hardCoded.autoMlIds.includes(model.id) && model.run
    )
  ) {
    if (!configUserInputs.media_sub_brand.length) {
      string = string.length
        ? string + ", Media Sub Brand"
        : string + " Media Sub Brand";
    }
    if (!configUserInputs.media_segment.length) {
      string = string.length
        ? string + ", Media Segment"
        : string + " Media Segment";
    }
    if (!configUserInputs.media_sub_segment.length) {
      string = string.length
        ? string + ", Media Sub Segment"
        : string + " Media Sub Segment";
    }
  }
  if (string.length) {
    return { status: false, message: commonString + string };
  } else {
    return { status: true, message: "" };
  }
};
const getValidStartOrEndDate = (endDate, weeks) => {
  const startDate = moment(endDate, "DDMMYYYY").subtract(
    weeks === 0 ? 0 : weeks * 7,
    "days"
  );
  return {
    start_date: startDate,
    end_date: endDate,
  };
};
const createMessage = (inValidArr) => {
  const mediaInvalidArr = inValidArr.filter((el) => el.regionType === "media");
  const salesInvalidArr = inValidArr.filter((el) => el.regionType === "sales");
  const textMessageArr = [];
  if (salesInvalidArr.length) {
    const salesUpliftArr = salesInvalidArr.filter(
      (obj) => obj.model === config.hardCoded.salesUpliftId
    );
    const autoMlArr = salesInvalidArr.filter(
      (obj) => obj.model === config.hardCoded.autoMlId
    );
    let salesUpliftTestCellArr = {};
    let salesUpliftTestCellStr = "";
    let automlTestCellArr = {};
    let automlTestCellStr = "";
    if (salesUpliftArr.length) {
      for (const element of salesUpliftArr) {
        salesUpliftTestCellStr = salesUpliftTestCellStr + element.testCellName;
      }
      salesUpliftTestCellArr = salesUpliftTestCellArr = {
        testCells: salesUpliftTestCellStr,
        model: config.hardCoded.salesUpliftId,
        filterType: salesUpliftArr[0].filterType,
      };
    }
    if (autoMlArr.length) {
      for (const element of autoMlArr) {
        automlTestCellStr = automlTestCellStr + element.testCellName;
      }
      automlTestCellArr = automlTestCellArr = {
        testCells: automlTestCellStr,
        model: config.hardCoded.autoMlId,
        filterType: autoMlArr[0].filterType,
      };
    }

    if (!isEmpty(automlTestCellArr)) {
      if (automlTestCellArr.testCells.length) {
        const testCells = automlTestCellArr.testCells.split("").join(",");
        if (automlTestCellArr.filterType === "overlap") {
          textMessageArr.push(
            `${
              textMessageArr.length + 1
            }. None of the regions in TestCell ${testCells} have minimum 20 weeks of datapoints in ${
              automlTestCellArr.model
            }`
          );
        }
        if (automlTestCellArr.filterType === "intersection") {
          textMessageArr.push(
            `${
              textMessageArr.length + 1
            }. None of the regions in TestCell ${testCells} have data for selected product filters in ${
              automlTestCellArr.model
            }`
          );
        }
      }
    }
    if (!isEmpty(salesUpliftTestCellArr)) {
      if (salesUpliftTestCellArr.testCells.length) {
        const testCells = salesUpliftTestCellArr.testCells.split("").join(",");
        if (salesUpliftTestCellArr.filterType === "overlap") {
          textMessageArr.push(
            `${
              textMessageArr.length + 1
            }. None of the regions in TestCell ${testCells} have minimum 20 weeks of datapoints in ${
              salesUpliftTestCellArr.model
            }`
          );
        }
        if (salesUpliftTestCellArr.filterType === "intersection") {
          textMessageArr.push(
            `${
              textMessageArr.length + 1
            }. None of the regions in TestCell ${testCells} have data for selected product filters in ${
              salesUpliftTestCellArr.model
            }`
          );
        }
      }
    }
  }

  if (mediaInvalidArr.length) {
    const automlBudgetArr = mediaInvalidArr.filter(
      (obj) => obj.model === config.hardCoded.autoMlBudgetId
    );
    let automlBudgetTestCellArr = {};
    let automlBudgetTestCellStr = "";
    let automlTestCellArr = {};
    let automlTestCellStr = "";
    if (automlBudgetArr.length) {
      for (const element of automlBudgetArr) {
        automlBudgetTestCellStr =
          automlBudgetTestCellStr + element.testCellName;
      }
      automlBudgetTestCellArr = {
        testCells: automlBudgetTestCellStr,
        model: config.hardCoded.autoMlBudgetId,
        filterType: automlBudgetArr[0].filterType,
        globalDates: automlBudgetArr[0].globalDates,
      };
    }
    const autoMlArr = mediaInvalidArr.filter(
      (obj) => obj.model === config.hardCoded.autoMlId
    );
    if (autoMlArr.length) {
      for (const element of autoMlArr) {
        automlTestCellStr = automlTestCellStr + element.testCellName;
      }
      automlTestCellArr = automlTestCellArr = {
        testCells: automlTestCellStr,
        model: config.hardCoded.autoMlId,
        filterType: autoMlArr[0].filterType,
        globalDates: autoMlArr[0].globalDates,
      };
    }
    if (!isEmpty(automlTestCellArr)) {
      if (automlTestCellArr.testCells.length) {
        const testCells = automlTestCellArr.testCells.split("").join(",");
        textMessageArr.push(
          `${
            textMessageArr.length + 1
          }. None of the regions in TestCell ${testCells} have data for selected media datasets for ${automlTestCellArr.globalDates[0].format(
            "DD-MM-YYYY"
          )} - ${automlTestCellArr.globalDates[1].format("DD-MM-YYYY")} in ${
            automlTestCellArr.model
          }`
        );
      }
    }
    if (!isEmpty(automlBudgetTestCellArr)) {
      if (automlBudgetTestCellArr.testCells.length) {
        const testCells = automlBudgetTestCellArr.testCells.split("").join(",");
        textMessageArr.push(
          `${
            textMessageArr.length + 1
          }. None of the regions in TestCell ${testCells} have data for selected media datasets for ${automlBudgetTestCellArr.globalDates[0].format(
            "DD-MM-YYYY"
          )} - ${automlBudgetTestCellArr.globalDates[1].format(
            "DD-MM-YYYY"
          )} in ${automlBudgetTestCellArr.model}`
        );
      }
    }
  }
  return textMessageArr.join("\n");
};
const validateSalesRegions = (models, configUserInputs) => {
  const inValidArr = [];
  // const salesRegions = configUserInputs?.validSalesRegions?.data || [];
  models.map((model) => {
    if (
      model.config.run_type !== config.hardCoded.nestedRunId &&
      ((model.run &&
        [config.hardCoded.autoMlId, config.hardCoded.salesUpliftId].includes(
          model.id
        ) &&
        (model.config.run_model ||
          typeof model.config.run_model === "undefined")) ||
        (model.run &&
          model.id === config.hardCoded.autoMlId &&
          !model.config.run_model))
    ) {
      const salesRegions =
        configUserInputs?.validSalesRegions[model.id]?.data || [];
      const regionGranularity = model.config.region_granularity;
      const regionType = regionGranularity.region_type;
      const regions =
        model.id === config.hardCoded.autoMlId
          ? regionGranularity.test_regions.test
          : regionGranularity.test_dark_regions.test;
      const validSalesRegion = salesRegions[regionType] || [];
      // console.log(regionGranularity, salesRegions[regionType],regions);
      for (const [key, value] of Object.entries(regions)) {
        const validCheck = validSalesRegion?.region_info?.find((el) =>
          value.regions.includes(el.region_name)
        );
        if (validCheck) {
        } else {
          inValidArr.push({
            testCellName: key,
            regionType: "sales",
            filterType: "intersection",
            model: model.id,
          });
        }
      }
    }
  });
  if (!inValidArr.length) {
    models.map((model) => {
      if (
        model.config.run_type !== config.hardCoded.nestedRunId &&
        ((model.run &&
          [config.hardCoded.autoMlId, config.hardCoded.salesUpliftId].includes(
            model.id
          ) &&
          (model.config.run_model ||
            typeof model.config.run_model === "undefined")) ||
          (model.run &&
            model.id === config.hardCoded.autoMlId &&
            !model.config.run_model))
      ) {
        const salesRegions =
          configUserInputs?.validSalesRegions[model.id]?.data || [];
        const regionGranularity = model.config.region_granularity;
        const regionType = regionGranularity.region_type;
        const regions =
          model.id === config.hardCoded.autoMlId
            ? regionGranularity.test_regions.test
            : regionGranularity.test_dark_regions.test;
        const validSalesRegion = salesRegions[regionType] || [];

        // console.log(regionGranularity, salesRegions[regionType],regions);
        for (const [key, value] of Object.entries(regions)) {
          if (model.id === config.hardCoded.autoMlId) {
            const pilot_start_date = moment(
              model.config.pilot_start_date,
              "YYYY-MM-DD"
            );
            const pilot_end_date = moment(
              model.config.pilot_end_date,
              "YYYY-MM-DD"
            );
            const validCheck =
              validSalesRegion?.region_info?.filter((el) => {
                return value.regions.includes(el.region_name);
              }) || [];
            // console.log(validCheck);

            // console.log(diffInDate,validCheck);

            const validOverLapCheck = validCheck.find((region) => {
              const diffInDate = pilot_start_date.diff(
                moment(region.start_date, "DDMMYYYY"),
                "weeks"
              );
              if (
                diffInDate >= 20 &&
                moment(region.end_date, "DDMMYYYY") >= pilot_end_date
              ) {
                return true;
              } else {
                return false;
              }
            });

            if (validOverLapCheck) {
            } else {
              inValidArr.push({
                testCellName: key,
                regionType: "sales",
                filterType: "overlap",
                model: model.id,
              });
            }
          } else {
            const validCheck = validSalesRegion.region_info.filter((el) =>
              value.regions.includes(el.region_name)
            );
            const pilot_start_date = moment(
              value.test_group_parameters[0]?.pilot_start
            );
            const pilot_end_date = moment(
              value.test_group_parameters[0]?.pilot_start
            ).add(value.test_group_parameters[0]?.weeks_pilot - 1, "weeks");
            const validOverLapCheck = validCheck.find((region) => {
              const diffInDate = pilot_start_date.diff(
                moment(region.start_date, "DDMMYYYY"),
                "weeks"
              );
              if (
                diffInDate >= 20 &&
                moment(region.end_date, "DDMMYYYY") >= pilot_end_date
              ) {
                return true;
              } else {
                return false;
              }
            });
            if (validOverLapCheck) {
            } else {
              inValidArr.push({
                testCellName: key,
                regionType: "sales",
                filterType: "overlap",
                model: model.id,
              });
            }
          }
        }
      }
    });
  }
  return inValidArr;
};
const validateMediaRegion = (models, configUserInputs) => {
  const inValidArr = [];
  const dateFormat = "DDMMYYYY";
  let globalDates;
  if (
    configUserInputs.global_week_date_selection ===
    config.hardCoded.historicWeek
  ) {
    globalDates = getValidStartOrEndDate(
      moment(),
      configUserInputs.historic_week_data
    );
  } else {
    globalDates = {
      start_date: moment(configUserInputs.startDate, "YYYY-MM-DD"),
      end_date: moment(configUserInputs.endDate, "YYYY-MM-DD"),
    };
  }

  models.map((model) => {
    if (
      model.config.run_type !== config.hardCoded.nestedRunId &&
      model.run &&
      config.hardCoded.autoMlIds.includes(model.id)
    ) {
      const mediaRegions = configUserInputs?.validMediaRegions[model.id] || [];
      const region_granularity =
        model.config.region_granularity.test_regions.test;
      const validMediaRegions = mediaRegions?.data?.region_info || [];

      for (const [key, value] of Object.entries(region_granularity)) {
        const validCheck = validMediaRegions?.find((el) => {
          return value.regions.includes(el.region_name);
        });

        if (validCheck) {
        } else {
          inValidArr.push({
            testCellName: key,
            regionType: "media",
            filterType: "intersection",
            model: model.id,
            globalDates: [globalDates.start_date, globalDates.end_date],
          });
        }
      }
    }
  });
  if (!inValidArr.length) {
    models.map((model) => {
      if (
        model.config.run_type !== config.hardCoded.nestedRunId &&
        model.run &&
        config.hardCoded.autoMlIds.includes(model.id)
      ) {
        const mediaRegions =
          configUserInputs?.validMediaRegions[model.id] || [];
        const region_granularity =
          model.config.region_granularity.test_regions.test;
        const validMediaRegions = mediaRegions?.data?.region_info || [];
        for (const [key, value] of Object.entries(region_granularity)) {
          const validRegionsDates = validMediaRegions.filter((el) => {
            return value.regions.includes(el.region_name);
          });
          // console.log(validRegionsDates);
          const validMediaRegionObj = validRegionsDates.find((region) => {
            // console.log(region.end_date, region.start_date);
            const regionEndDate = region.end_date
              ? moment(region.end_date, dateFormat)
              : moment();
            const regionStartDate = region.start_date
              ? moment(region.start_date, dateFormat)
              : moment();
            // (GlobalA <= RegionB) and (RegionA <= GlobalB)
            // RS RE
            // GS GE
            const diffDate = () => {
              if (
                regionEndDate >= globalDates.end_date &&
                regionStartDate <= globalDates.start_date
              ) {
                return globalDates.end_date.diff(
                  globalDates.start_date,
                  "days"
                );
              } else if (
                globalDates.end_date >= regionEndDate &&
                globalDates.start_date <= regionStartDate
              ) {
                return regionEndDate.diff(regionStartDate, "days");
              } else if (
                regionEndDate <= globalDates.end_date &&
                regionStartDate <= globalDates.start_date
              ) {
                return regionEndDate.diff(globalDates.start_date, "days");
              } else if (
                regionEndDate >= globalDates.end_date &&
                regionStartDate >= globalDates.start_date
              ) {
                return globalDates.end_date.diff(regionStartDate, "days");
              }
            };
            if (
              regionEndDate >= globalDates.start_date &&
              regionStartDate <= globalDates.end_date
            ) {
              if (diffDate() + 1 >= 7) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
          if (validMediaRegionObj) {
          } else {
            inValidArr.push({
              testCellName: key,
              regionType: "media",
              filterType: "overlap",
              model: model.id,
              globalDates: [globalDates.start_date, globalDates.end_date],
            });
          }
        }
      }
    });
  }
  return inValidArr;
};
const validateSalesMediaRegions = (models, configUserInputs) => {
  const invalidArr = [
    ...validateSalesRegions(models, configUserInputs),
    ...validateMediaRegion(models, configUserInputs),
  ];

  if (invalidArr.length) {
    return { status: false, message: createMessage(invalidArr) };
  }
  return { status: true, message: "" };
};
export const allValidationFunc = {
  validateHistoricWeek,
  validateDatesSelected,
  validateEmptyConfigName,
  validConfigName,
  validSalesNielsenDataset,
  validateNeilsenComp,
  validateTestControl,
  validateSalesCoverage,
  validateMMTSimilarRegionsInput,
  validateMMTNumberOfCellsInput,
  validateMinMaxNumberInputs,
  validateModelsHasDatasetSelected,
  validateValidDatasetSelected,
  validateValidCategorySelected,
  validateDatasetHasKpiSelected,
  validateDatasetHasTargetVariableSelected,
  validateMergedDatasetHasMandatoryKpiSelected,
  validateTopNCompetitor,
  validateNestedRun, //model specific
  validateNestedRunV2, //model specific
  validateBudgetOnlyRun, //modelspecific
  validateOneModelSelected,
  validateRegionGranModelEnable, //model specific
  validateDiffnDiffCampaign, //model specific
  validateRegressionBudgetCombiations, //model specific
  validateSalesUpliftTestGroupParameteres, //model specific
  validateAutoMlTestGroupParameteres, //model specific
  validateAutoMlBudgetTestGroupParameteres, //model specific
  validateAutoMlCampaign, //model specific
  validateAutoMlKpi, //model specific
  validateMMTKpi, //model specific
  validateAutoMLMandatoryKpi, //Model specific
  validateAutoMLBudgetMandatoryKpi, //model specific
  validateAutoMLMediaDataset, //Model specific
  validateAutoMLBudgetMediaDataset, //model specific
  validAdstockParameterDataset, //Model specific
  validateRegionGranModelEnableForMMT, //Model specific
  validateSalesMinMaxForMMT, //Model specific
  validateDatasetWithLocal,
  validateCategoryDataset, //Category dataset duplicate
  validateAutoMlPilotDatesSelected,
  validateAutoMlBudgetPilotDatesSelected,
  validateSalesUpliftTestGroupParameteresPilotStartDate,
  validateAutoMlGranularitySelection,
  validateMMTMandatoryKpi,
  validateValidSalesDatasetBasedOnPeriodicity,
  validateManualFileUploaded, //Manual run
  validateManualKpiDetails, // Manual run
  validateRegressionBudgetCombiationsOnly, //Manual run
  validateVolumeFieldAvailable, //Manual run
  validateMinimumDatapointColumnSelected, //Manual run
  validateMMTCorrelationThreshold,
  validateSelectKpiTypeForManualRun,
  validateColumnDetailAvailable,
  validateRegionGranularity,
  validateSeasonality,
  validateMediaFilter,
  validateSalesMediaRegions,
};

export const validateConfig = (configUserInputs, allData) => {
  //checking the  De mode
  const checkDERunConfig = configUserInputs.models.every(
    (e) => e.config.run_model === true
  );

  //validateEmptyConfigName
  var status = allValidationFunc.validateEmptyConfigName(
    configUserInputs.configName
  );
  if (!status)
    return { status: "fail", message: config.messages.emptyConfigName };

  //validConfigName
  var status = allValidationFunc.validConfigName(configUserInputs.configName);
  if (!status)
    return { status: "fail", message: config.messages.invalidConfigName };

  //validateHistoricWeek
  var status = allValidationFunc.validateHistoricWeek(configUserInputs);
  if (!status)
    return { status: "fail", message: config.messages.historicWeekValidation };

  //validateDatesSelected
  var { status, message } =
    allValidationFunc.validateDatesSelected(configUserInputs);
  if (!status) return { status: "fail", message: message };

  //validateTestControl
  // var status = allValidationFunc.validateTestControl(
  //   configUserInputs.regionGranType,
  //   configUserInputs.controlRegions,
  //   configUserInputs.testRegions
  // );
  // if (!status)
  //   return { status: "fail", message: config.messages.testControlNonEmpty };

  //validate media filters
  var { status, message } = allValidationFunc.validateMediaFilter(
    configUserInputs,
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };
  //validateSalesCoverage
  var { status, message } = allValidationFunc.validateSalesCoverage(
    configUserInputs.models,
    allData.models,
    checkDERunConfig,
    configUserInputs
  );
  if (!status) return { status: "fail", message };

  // validateMMTSimilarRegionsInput
  var { status, message } = allValidationFunc.validateMMTSimilarRegionsInput(
    configUserInputs.models,
    allData.models,
    checkDERunConfig,
    configUserInputs
  );
  if (!status) return { status: "fail", message };

  // validateMMTNumberOfCellsInput
  var { status, message } = allValidationFunc.validateMMTNumberOfCellsInput(
    configUserInputs.models,
    allData.models,
    checkDERunConfig,
    configUserInputs
  );
  if (!status) return { status: "fail", message };

  //validate region granularity
  var { status, message } = allValidationFunc.validateRegionGranularity(
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  if (!status) return { status: "fail", message };
  // validateMMTCorrelationThreshold:
  var { status, message } = allValidationFunc.validateMMTCorrelationThreshold(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };

  //validateMinMaxNumberInputs
  var { status, message } = allValidationFunc.validateMinMaxNumberInputs(
    configUserInputs.models,
    allData.models,
    checkDERunConfig
  );
  if (!status) return { status: "fail", message };

  //validateModelsHasDatasetSelected
  var { status, message } = allValidationFunc.validateModelsHasDatasetSelected(
    configUserInputs.models,
    allData.models,
    configUserInputs,
    allData
  );
  if (!status) return { status: "fail", message };

  var { status, message, partialDataset } =
    allValidationFunc.validateValidCategorySelected(
      configUserInputs.models,
      configUserInputs,
      allData.models
    );
  if (!status) return { status: "fail", message, partialDataset };

  // validateValidSalesDatasetBasedOnPeriodicity
  var { status, message, partialDataset } =
    allValidationFunc.validateValidSalesDatasetBasedOnPeriodicity(
      configUserInputs.models,
      configUserInputs,
      allData.models
    );
  if (!status) return { status: "fail", message, partialDataset };

  //validateValidDatasetSelected
  var { status, message, partialDataset } =
    allValidationFunc.validateValidDatasetSelected(
      configUserInputs.models,
      configUserInputs
    );
  if (!status) return { status: "fail", message, partialDataset };

  // //validateNeilsenComp
  // var { status, message } = allValidationFunc.validateNeilsenComp(
  //   configUserInputs.models,
  //   allData.models
  // );
  // if (!status) return { status: "fail", message };

  //validSalesNielsenDataset
  var { status, message } = allValidationFunc.validSalesNielsenDataset(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };

  //validAdstockParameterDataset
  var { status, message } = allValidationFunc.validAdstockParameterDataset(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };

  //validateDatasetHasKpiSelected
  var { status, message } = allValidationFunc.validateDatasetHasKpiSelected(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };

  //validateDatasetHasTargetVariableSelected
  var { status, message } =
    allValidationFunc.validateDatasetHasTargetVariableSelected(
      configUserInputs.models,
      allData.models
    );
  if (!status) return { status: "fail", message };
  //validateTopNCompetitor
  var { status, message } = allValidationFunc.validateTopNCompetitor(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };
  //validateNestedRunV2
  var { status, message } = allValidationFunc.validateNestedRunV2(
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  //validate mandatory kpi in merge dataset
  var { status, message } =
    allValidationFunc.validateMergedDatasetHasMandatoryKpiSelected(
      configUserInputs.models,
      allData.models
    );
  if (!status) return { status: "fail", message };

  //validateBudgetOnlyRun
  var { status, message } = allValidationFunc.validateBudgetOnlyRun(
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  //validateOneModelSelected
  var { status, message } = allValidationFunc.validateOneModelSelected(
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  //validateDatasetWithLocal
  var { status, message } = allValidationFunc.validateDatasetWithLocal(
    configUserInputs,
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  // //validateDiffnDiffCampaign
  var { status, message } = allValidationFunc.validateDiffnDiffCampaign(
    configUserInputs.models,
    checkDERunConfig
  );
  if (!status) return { status: "fail", message };

  //validateRegressionBudgetCombiations
  var { status, message } =
    allValidationFunc.validateRegressionBudgetCombiations(
      configUserInputs,
      checkDERunConfig
    );
  if (!status) return { status: "fail", message };

  //validateSalesUpliftTestGroupParameteres
  var { status, message } =
    allValidationFunc.validateSalesUpliftTestGroupParameteres(
      configUserInputs.models
    );
  if (!status) return { status: "fail", message };

  var { status, message } =
    allValidationFunc.validateSalesUpliftTestGroupParameteresPilotStartDate(
      configUserInputs.models,
      configUserInputs,
      checkDERunConfig
    );
  if (!status) return { status: "fail", message };

  //validateAutoMlTestGroupParameteres
  var { status, message } =
    allValidationFunc.validateAutoMlTestGroupParameteres(
      configUserInputs.models
    );
  if (!status) return { status: "fail", message };

  //validateAutoMlBudgetTestGroupParameteres
  var { status, message } =
    allValidationFunc.validateAutoMlBudgetTestGroupParameteres(
      configUserInputs.models
    );
  if (!status) return { status: "fail", message };

  //validateAutoMlCampaign
  // var { status, message } = allValidationFunc.validateAutoMlCampaign(
  //   configUserInputs
  // );
  // if (!status) return { status: "fail", message };

  //validateAutoMlKpi
  var { status, message } = allValidationFunc.validateAutoMlKpi(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };

  //validateMMTKpi
  var { status, message } = allValidationFunc.validateMMTKpi(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };

  //validateMMTMandatoryKpi
  var { status, message } = allValidationFunc.validateMMTMandatoryKpi(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };

  //validateAutoMLMandatoryKpi
  var { status, message } = allValidationFunc.validateAutoMLMandatoryKpi(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };

  //validateAutoMLBudgetMandatoryKpi
  var { status, message } = allValidationFunc.validateAutoMLBudgetMandatoryKpi(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };

  //validateAutoMLMediaDataset
  var { status, message } = allValidationFunc.validateAutoMLMediaDataset(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };

  //validateAutoMLBudgetMediaDataset
  var { status, message } = allValidationFunc.validateAutoMLBudgetMediaDataset(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };

  //validateCategoryDataset
  var { status, message } = allValidationFunc.validateCategoryDataset(
    configUserInputs,
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  //validateAutoMlPilotDatesSelected
  var { status, message } = allValidationFunc.validateAutoMlPilotDatesSelected(
    configUserInputs,
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  //validateAutoMlBudgetPilotDatesSelected
  var { status, message } =
    allValidationFunc.validateAutoMlBudgetPilotDatesSelected(
      configUserInputs,
      configUserInputs.models
    );
  if (!status) return { status: "fail", message };

  //validateAutoMlGranularitySelection
  var { status, message } =
    allValidationFunc.validateAutoMlGranularitySelection(
      configUserInputs,
      configUserInputs.models
    );
  if (!status) return { status: "fail", message };
  //validate sales/media region
  var { status, message } = allValidationFunc.validateSalesMediaRegions(
    configUserInputs.models,
    configUserInputs
  );
  if (!status) return { status: "fail", message };

  return {
    status: "pass",
    message: configUserInputs.configId
      ? config.messages.validConfigUpdate
      : config.messages.validConfigAdd,
    partialDataset,
  };
};

export const validateManualConfig = (configUserInputs, allData) => {
  //checking the  De mode
  const checkDERunConfig = configUserInputs.models.every(
    (e) => e?.config?.run_model === true
  );
  //validateEmptyConfigName
  var status = allValidationFunc.validateEmptyConfigName(
    configUserInputs.configName
  );
  if (!status)
    return { status: "fail", message: config.messages.emptyConfigName };

  //validConfigName
  var status = allValidationFunc.validConfigName(configUserInputs.configName);
  if (!status)
    return { status: "fail", message: config.messages.invalidConfigName };

  //validateOneModelSelected
  var status = allValidationFunc.validateEmptyConfigName(
    configUserInputs.brand
  );
  if (!status)
    return { status: "fail", message: config.messages.emptyBrandName };

  //validateOneModelSelected
  var { status, message } = allValidationFunc.validateOneModelSelected(
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  // validateSelectKpiTypeForManualRun
  var { status, message } = allValidationFunc.validateSelectKpiTypeForManualRun(
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  //validateManualFileUploaded
  var { status, message } = allValidationFunc.validateManualFileUploaded(
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  //validateBudgetOnlyRun
  var { status, message } = allValidationFunc.validateBudgetOnlyRun(
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };
  //validateManualKpiDetails
  var { status, message } = allValidationFunc.validateManualKpiDetails(
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  // //validateManualKpiDetails
  // var { status, message } = allValidationFunc.validateColumnDetailAvailable(
  //   configUserInputs.models
  // );
  // if (!status) return { status: "fail", message };

  //validateRegressionBudgetCombiationsOnly
  var { status, message } =
    allValidationFunc.validateRegressionBudgetCombiationsOnly(configUserInputs);
  if (!status) return { status: "fail", message };

  //validateVolumeFieldAvailable
  var { status, message } =
    allValidationFunc.validateVolumeFieldAvailable(configUserInputs);
  if (!status) return { status: "fail", message };

  //validateMinimumDatapointColumnSelected
  var { status, message } =
    allValidationFunc.validateMinimumDatapointColumnSelected(configUserInputs);
  if (!status) return { status: "fail", message };

  //validateSalesCoverage
  var { status, message } = allValidationFunc.validateSalesCoverage(
    configUserInputs.models,
    allData.models,
    checkDERunConfig,
    configUserInputs
  );
  if (!status) return { status: "fail", message };

  // validateMMTSimilarRegionsInput
  var { status, message } = allValidationFunc.validateMMTSimilarRegionsInput(
    configUserInputs.models,
    allData.models,
    checkDERunConfig,
    configUserInputs
  );
  if (!status) return { status: "fail", message };

  // validateMMTNumberOfCellsInput
  var { status, message } = allValidationFunc.validateMMTNumberOfCellsInput(
    configUserInputs.models,
    allData.models,
    checkDERunConfig,
    configUserInputs
  );
  if (!status) return { status: "fail", message };

  // validateMMTCorrelationThreshold:
  var { status, message } = allValidationFunc.validateMMTCorrelationThreshold(
    configUserInputs.models,
    allData.models
  );
  if (!status) return { status: "fail", message };
  //validateAutoMlPilotDatesSelected
  var { status, message } = allValidationFunc.validateAutoMlPilotDatesSelected(
    configUserInputs,
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  //validate seasonality
  var { status, message } = allValidationFunc.validateSeasonality(
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };

  //validate nested run in manual
  var { status, message } = allValidationFunc.validateNestedRun(
    configUserInputs.models
  );
  if (!status) return { status: "fail", message };
  return {
    status: "pass",
    message: configUserInputs.configId
      ? config.messages.validConfigUpdate
      : config.messages.validConfigAdd,
  };
};

export const transformArrayToObj = (config) => {
  var result = {};
  for (var i = 0; i < config.length; i++) {
    result[i] = config[i];
  }
  return result;
};

export const transformArrayToObjBasedOnPosition = (array) => {
  var object = {};
  for (let i = 0; i < array.length; i++) {
    let { position, ...value } = array[i];
    let key = parseInt(position);
    object[key] = value;
  }
  return object;
};

export const transformManualConfigUserModels = (
  state,
  configUserInputs,
  allData
) => {
  //transform datasets for all models
  let newState = state.map((model) => {
    // for mmt,rbt(not budget only) & su manual configs data_sources are required
    // also removing manual_kpi key from config
    if (
      config.hardCoded.manualKpiARR.includes(model.id) &&
      model.config.rbt_run_type !== config.hardCoded.budgetId
    ) {
      return model;
    } else {
      return {
        ...model,
        data_sources: [],
      };
    }
  });

  //remove manual_kpi from models
  newState = newState.map((model) => {
    if (config.hardCoded.manualKpiARR.includes(model.id)) {
      const { manual_kpi, ...remainingConfig } = model.config;
      return { ...model, config: remainingConfig };
    } else {
      return model;
    }
  });

  //remove run_model key from the each model
  if (configUserInputs?.is_config_manual) {
    newState = newState.map((model) => {
      let { run_model, ...remainingConfig } = model.config;
      return {
        ...model,
        config: remainingConfig,
      };
    });
  }

  //transform MMT model's config and data_sources
  newState = newState.map((model) => {
    let modelDataSource;
    if (model.id !== config.hardCoded.mmtId) {
      return model;
    } else {
      modelDataSource = model.data_sources.map((elem) => {
        let {
          adstock_parameters,
          enable_adstock,
          limit,
          saturation_flag,
          sl_limit,
          su_limit,
          ...remainingElems
        } = elem;
        return {
          ...remainingElems,
        };
      });
      let {
        sales_filter_min,
        sales_filter_max,
        regionFilterType,
        regionType,
        regions,
        files,
        ...remaining
      } = model.config;
      let configObj = {
        ...remaining,
        region_filters: {
          regionFilterType: model.config.regionFilterType,
          regionType: model.config.regionType,
          regions:
            model.config.regionSelectedType === "all"
              ? []
              : model.config.regions,
          files: model.config.files || {},
        },
        sales_filter: {
          sales_filter_min: model.config.sales_filter_min,
          sales_filter_max: model.config.sales_filter_max,
        },
      };
      let newModel = {
        ...model,
        config: configObj,
        data_sources: modelDataSource,
      };
      return newModel;
    }
  });
  // remove empty dataset key and campaigns key fron the su model
  newState = newState.map((model) => {
    if (model.id === config.hardCoded.salesUpliftId && model.run) {
      const { config } = model;
      return {
        ...model,
        config: {
          ...config,
          region_granularity: {
            ...config.region_granularity,
            test_dark_regions: {
              ...config.region_granularity.test_dark_regions,
              test: config.region_granularity.test_dark_regions.test.map(
                (e) => {
                  let updateTest = e;
                  if (!updateTest?.dataset_id?.length) {
                    delete updateTest["dataset_id"];
                  }
                  if (!updateTest?.target_campaigns?.length) {
                    delete updateTest["target_campaigns"];
                  }
                  return {
                    ...updateTest,
                    ...(updateTest?.target_campaigns && {
                      target_campaigns: updateTest?.target_campaigns.map(
                        (cam) => {
                          let {
                            brand,
                            segment,
                            sub_brand,
                            sub_segment,
                            id,
                            ...remaining
                          } = cam;
                          return { ...remaining };
                        }
                      ),
                    }),
                  };
                }
              ),
            },
          },
        },
      };
    } else {
      return model;
    }
  });

  //transform config for RBT model
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.regBudId) {
      return model;
    } else {
      let regionGranularityObj = model.config.region_granularity;
      let newTest = [],
        newControl = [];
      regionGranularityObj.test_dark_regions.test.forEach((item) => {
        let { id, type, ...remaining } = item;
        if (item.files.hasOwnProperty("input"))
          newTest.push({
            ...remaining,
          });
      });
      regionGranularityObj.test_dark_regions.dark.forEach((item) => {
        let { id, type, files, ...remaining } = item;
        newControl.push({
          ...remaining,
        });
      });
      let configObj = {
        ...model.config,
        region_granularity: {
          region_type: regionGranularityObj.region_type,
          type: regionGranularityObj.type,
          test_dark_regions: {
            test: { ...newTest },
            dark: { ...newControl },
          },
        },
      };
      let newModel = { ...model, config: configObj };
      return newModel;
    }
  });

  //transform nested_run_regions for AutoML model
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.autoMlId) {
      return model;
    } else {
      //nested json change when the nesteddriver api call given []
      let newNestedFields =
        model.config.run_type === config.hardCoded.nestedRunId &&
        model.config?.nested_run_regions?.test?.hasOwnProperty("0") &&
        model.config?.nested_run_regions?.test["0"]?.regions?.length === 0
          ? model.config.nested_run_regions
          : transformNestedRunRegionsToFirestoreStructure(
              model.config.nested_run_regions
            );
      let configObj = {
        ...model.config,
        nested_run_regions: newNestedFields,
      };
      let newModel = { ...model, config: configObj };
      return newModel;
    }
  });

  //transform config for sales_uplift model
  newState = newState.map((model) => {
    if (model?.id !== config.hardCoded.salesUpliftId) {
      return model;
    } else {
      let regionGranularityObj = model.config.region_granularity;
      let newTest = [],
        newControl = [];
      regionGranularityObj.test_dark_regions.test.forEach((item) => {
        let updCombinations = item.test_group_parameters.map(
          (combinationRow) => {
            let { id, ...remaining } = combinationRow;
            return remaining;
          }
        );
        let { id, type, ...remaining } = item;
        if (item.files.hasOwnProperty("input"))
          newTest.push({
            ...remaining,
            test_group_parameters: updCombinations[0],
          });
      });
      regionGranularityObj.test_dark_regions.dark.forEach((item) => {
        let { id, type, files, ...remaining } = item;
        newControl.push({
          ...remaining,
        });
      });
      let configObj = {
        ...model.config,
        region_granularity: {
          region_type: regionGranularityObj.region_type,
          type: regionGranularityObj.type,
          test_dark_regions: {
            test: { ...newTest },
            dark: { ...newControl },
          },
        },
      };
      let newModel = { ...model, config: configObj };
      return newModel;
    }
  });

  //transform config for diff&diff model
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.diffDiffId) {
      return model;
    } else {
      let regionGranularityObj = model.config.region_granularity;
      let newTest = [],
        newControl = [];
      regionGranularityObj.test_control_regions.test.forEach((item) => {
        let updCombinations = item.campaigns.map((combinationRow) => {
          let { id, ...remaining } = combinationRow;
          return remaining;
        });
        let { id, type, ...remaining } = item;
        let { isVolumeDetailsCorrect, ...remainingFileElems } = item.files;
        if (item.files.hasOwnProperty("input"))
          newTest.push({
            ...remaining,
            files: { ...remainingFileElems },
            campaigns: [updCombinations[0]],
          });
      });
      regionGranularityObj.test_control_regions.control.forEach((item) => {
        let { id, type, files, ...remaining } = item;
        newControl.push({
          ...remaining,
        });
      });
      let configObj = {
        ...model.config,
        region_granularity: {
          region_type: regionGranularityObj.region_type,
          type: regionGranularityObj.type,
          test_control_regions: {
            test: { ...newTest },
            control: { ...newControl },
          },
        },
      };
      let newModel = { ...model, config: configObj };
      return newModel;
    }
  });

  // remove seasonality_flag from automl and automl budget manual models
  // if (configUserInputs.is_config_manual) {
  //   newState = newState.map((model) => {
  //     if (config.hardCoded.autoMlIds.includes(model.id)) {
  //       let test_regions = model.config.region_granularity.test_regions;
  //       if (model.config.region_granularity.region_type === "dma") {
  //         const testCellDma = test_regions.test.map((el) => {
  //           const { seasonality_flag, ...remaining } = el.files;
  //           return {
  //             ...el,
  //             files: remaining,
  //             seasonality_flag: seasonality_flag,
  //           };
  //         });
  //         test_regions = { ...test_regions, test: testCellDma };
  //       }
  //       if (model.config.region_granularity.region_type === "national") {
  //         if (test_regions.files) {
  //           const { seasonality_flag, ...remaining } = test_regions.files;
  //           test_regions = {
  //             ...test_regions,
  //             files: remaining,
  //             seasonality_flag: seasonality_flag,
  //           };
  //         }
  //       }
  //       return {
  //         ...model,
  //         config: {
  //           ...model.config,
  //           region_granularity: {
  //             ...model.config.region_granularity,
  //             test_regions: test_regions,
  //           },
  //         },
  //       };
  //     } else {
  //       return model;
  //     }
  //   });
  // }

  //transform config for AutoML Budget model
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.autoMlBudgetId) {
      return model;
    } else {
      let regionGranularityObj = model.config.region_granularity;
      if (regionGranularityObj.test_regions.control) {
        delete regionGranularityObj.test_regions["control"];
      }
      let newTest = [],
        newControl = [];
      let configObj = model.config;
      if (
        regionGranularityObj.test_regions.test instanceof Array
        // &&
        // regionGranularityObj.test_control_regions.control instanceof Array
      ) {
        regionGranularityObj.test_regions.test.forEach((item) => {
          let { id, type, ...remaining } = item;
          let {
            kpi_details,
            collinear_drivers,
            collinear_variables_to_drop,
            ...remainingFileElems
          } = item.files;
          if (item.files.hasOwnProperty("input"))
            newTest.push({
              ...remaining,
              files: { ...remainingFileElems },
              kpi_details: {
                ...(item?.files?.kpi_details?.[0] ||
                  item?.files?.kpi_details ||
                  item?.kpi_details ||
                  {}),
              },
              collinear_drivers: collinear_drivers || {},
              collinear_variables_to_drop: collinear_variables_to_drop || [],
            });
        });
        // regionGranularityObj.test_control_regions.control.forEach((item) => {
        //   let { id, type, ...remaining } = item;
        //   let { kpi_details, ...remainingFileElems } = item.files;
        //   if (item.files.hasOwnProperty("input"))
        //     newControl.push({
        //       ...remaining,
        //       files: { ...remainingFileElems },
        //       kpi_details: {
        //         ...(item?.files?.kpi_details?.[0] ||
        //           item?.files?.kpi_details ||
        //           item?.kpi_details ||
        //           {}),
        //       },
        //     });
        // });
        configObj = {
          ...model.config,
          retail_margin_price:
            model.config.retail_margin_price === ""
              ? 0
              : model.config.retail_margin_price,
          region_granularity: {
            region_type: regionGranularityObj.region_type,
            type: regionGranularityObj.type,
            test_regions: {
              test: { ...newTest },
              // control: { ...newControl },
            },
          },
        };
      }

      let newModel = { ...model, config: configObj };
      return newModel;
    }
  });

  //transform config for AutoML model
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.autoMlId) {
      return model;
    } else {
      let regionGranularityObj = model.config.region_granularity;
      let newTest = [],
        newControl = [];
      if (regionGranularityObj.test_regions.control) {
        delete regionGranularityObj.test_regions["control"];
      }
      let configObj = model.config;
      if (
        regionGranularityObj.region_type.toLowerCase() === "national" &&
        regionGranularityObj.test_regions?.files
      ) {
        let {
          kpi_details,
          collinear_drivers,
          collinear_variables_to_drop,
          ...remNationalValues
        } = regionGranularityObj.test_regions?.files;
        let kpiDetails = regionGranularityObj.test_regions?.files
          ?.kpi_details?.[0]
          ? regionGranularityObj.test_regions?.files?.kpi_details?.[0]
          : regionGranularityObj.test_regions?.files?.kpi_details;
        configObj = {
          ...model.config,
          region_granularity: {
            region_type: regionGranularityObj.region_type,
            type: regionGranularityObj.type,
            test_regions: {
              test: {},
              // control: {},
              files: { ...remNationalValues },
              kpi_details: { ...kpiDetails },
              collinear_drivers: collinear_drivers || {},
              collinear_variables_to_drop: collinear_variables_to_drop || [],
            },
          },
        };
      } else if (
        regionGranularityObj.test_regions.test instanceof Array
        // &&
        // regionGranularityObj.test_regions.control instanceof Array
      ) {
        regionGranularityObj.test_regions.test.forEach((item) => {
          let { id, type, ...remaining } = item;
          let {
            kpi_details,
            collinear_drivers,
            collinear_variables_to_drop,
            ...remainingFileElems
          } = item.files;
          if (item.files.hasOwnProperty("input"))
            newTest.push({
              ...remaining,
              files: { ...remainingFileElems },
              kpi_details: {
                ...(item?.files?.kpi_details?.[0] ||
                  item?.files?.kpi_details ||
                  item?.kpi_details ||
                  {}),
              },
              collinear_drivers: collinear_drivers || {},
              collinear_variables_to_drop: collinear_variables_to_drop || [],
            });
        });
        // regionGranularityObj.test_regions.control.forEach((item) => {
        //   let { id, type, ...remaining } = item;
        //   let { kpi_details, ...remainingFileElems } = item.files;
        //   if (item.files.hasOwnProperty("input"))
        //     newControl.push({
        //       ...remaining,
        //       files: { ...remainingFileElems },
        //       kpi_details: {
        //         ...(item?.files?.kpi_details?.[0] ||
        //           item?.files?.kpi_details ||
        //           item?.kpi_details ||
        //           {}),
        //       },
        //     });
        // });
        configObj = {
          ...model.config,
          retail_margin_price:
            model.config.retail_margin_price === ""
              ? 0
              : model.config.retail_margin_price,
          region_granularity: {
            region_type: regionGranularityObj.region_type,
            type: regionGranularityObj.type,
            test_regions: {
              test: { ...newTest },
              // control: { ...newControl },
            },
          },
        };
      }
      let newModel = { ...model, config: configObj };
      return newModel;
    }
  });

  //transform su model
  newState = newState.map((model) => {
    if (
      model.id === config.hardCoded.salesUpliftId ||
      config.hardCoded.autoMlIds.includes(model.id)
    ) {
      return {
        ...model,
        config: {
          ...model.config,
          retail_margin_price:
            model?.config?.retail_margin_price === ""
              ? 0
              : model?.config?.retail_margin_price,
        },
      };
    } else {
      return { ...model };
    }
  });

  //Remove ID from combinations array
  newState = newState.map((model) => {
    //if model.config included combinations, remove id from all objects
    if (model.config[config.hardCoded.combinationsId]) {
      let updCombinations = model.config.combinations.map((combinationRow) => {
        let { id, ...remaining } = combinationRow;
        return remaining;
      });
      let updModel = {
        ...model,
        config: {
          ...model.config,
          [config.hardCoded.combinationsId]: updCombinations,
        },
      };
      return updModel;
    } else {
      return model;
    }
  });

  //remove the keys from the config for Automl and AutomlBudget for  and manual run
  newState = newState.map((model) => {
    if (
      model.id === config.hardCoded.autoMlId &&
      model.config.run_type === config.hardCoded.nestedRunId
    ) {
      let { seasonality, ...remainingConfig } = model.config;
      return {
        ...model,
        config: remainingConfig,
      };
    } else {
      return model;
    }
  });
  // remove corr_upper_limit from the mmt model
  newState = newState.map((model) => {
    if (
      model.id === config.hardCoded.mmtId &&
      (!model.config?.corr_upper_limit ||
        JSON.stringify(model.config?.corr_upper_limit) === `[]`) &&
      model.config?.corr_upper_limit !== 0
    ) {
      let { corr_upper_limit, ...remaningConfig } = model.config;
      return {
        ...model,
        config: remaningConfig,
      };
    } else {
      return model;
    }
  });

  //correlation thresold remove key
  newState = newState.map((model) => {
    if (model.id === config.hardCoded.mmtId && model?.run) {
      let filterModel = allData.models.find((elm) => elm.id === model.id);
      let correlationLocalCountries = filterModel?.local_parameters.find(
        (e) => e.id === "corr_upper_limit"
      ).countries;
      if (!correlationLocalCountries.includes(configUserInputs.country)) {
        const { corr_upper_limit, ...remaining } = model.config;
        return { ...model, config: { ...remaining } };
      } else {
        return model;
      }
    } else {
      return model;
    }
  });
  //removing regions key from eacvh model
  newState = newState.map((model) => {
    const { regions, ...remaining } = model.config;
    return {
      ...model,
      config: { ...remaining },
    };
  });

  return newState;
};

export const transformConfigUserModels = (state, configUserInputs, allData) => {
  //check DE mode
  const isConfigDEOption =
    configUserInputs.app_id === config.hardCoded.mroiAppId &&
    configUserInputs.models.every((e) => e.config.hasOwnProperty("run_model"))
      ? configUserInputs?.models?.every((e) => e.config.run_model === true)
      : true;

  //transform sales dataset sku parameter
  let newState = state.map((model) => ({
    ...model,
    data_sources: model.data_sources.map((elem) =>
      config.hardCoded.radioDatasetList.includes(elem.type)
        ? {
            ...elem,
            sku: elem?.sku?.map((data) => data?.sku),
          }
        : { ...elem }
    ),
  }));
  newState = newState.map((model) => {
    // also removing manual_kpi key from config from su and rbt model
    if (config.hardCoded.manualKpiARR.includes(model.id)) {
      const { manual_kpi, ...remainingConfig } = model.config;
      return { ...model, config: remainingConfig };
    } else {
      return model;
    }
  });

  //delete the algorithm key in de mode
  if (!isConfigDEOption) {
    newState = newState.map((model) => {
      if (model.id === config.hardCoded.salesUpliftId && model.run) {
        delete model?.config["algorithm"];
        return {
          ...model,
          config: {
            ...model?.config,
          },
        };
      } else {
        return model;
      }
    });
  }

  //adding combination in regression budget model in DE only mode width default values
  if (!isConfigDEOption) {
    newState = newState.map((model) => {
      if (model.id === "REGRESSION_BUDGET" && model.run) {
        let regressionModelCombinationData = allData.models
          .find((e) => e.id === model.id)
          ?.local_parameters.find(
            (e) => e.id === config.hardCoded.combinationsId
          )?.data;

        let combinations = regressionModelCombinationData.reduce(
          (acc, curr) => {
            acc[curr.id] = curr.minValue;
            return acc;
          },
          {}
        );
        return {
          ...model,
          config: {
            ...model.config,
            combinations: model.config.combinations.length
              ? model.config.combinations
              : [combinations],
          },
        };
      } else {
        return model;
      }
    });
  }
  if (isConfigDEOption) {
    newState = newState.map((model) => {
      if (model.id === config.hardCoded.salesUpliftId && model.run) {
        const { config } = model;
        return {
          ...model,
          config: {
            ...config,
            region_granularity: {
              ...config.region_granularity,
              test_dark_regions: {
                ...config.region_granularity.test_dark_regions,
                test: config.region_granularity.test_dark_regions.test.map(
                  (e) => {
                    let updateTest = e;
                    if (!updateTest.dataset_id?.length) {
                      delete updateTest["dataset_id"];
                    }
                    if (!updateTest.target_campaigns?.length) {
                      delete updateTest["target_campaigns"];
                    }
                    return {
                      ...updateTest,
                      ...(updateTest?.target_campaigns && {
                        target_campaigns: updateTest?.target_campaigns.map(
                          (cam) => {
                            let {
                              brand,
                              segment,
                              sub_brand,
                              sub_segment,
                              id,
                              ...remaining
                            } = cam;
                            return { ...remaining };
                          }
                        ),
                      }),
                    };
                  }
                ),
              },
            },
          },
        };
      } else {
        return model;
      }
    });
  }

  //Empty data_sources if autoMl has nested run selected
  newState = newState.map((model) => {
    let updateData = {};
    if (model.id !== config.hardCoded.autoMlId) {
      updateData = { ...model };
    } else {
      if (model.config.run_type === config.hardCoded.nestedRunId) {
        let updatedConfig = { ...model.config };
        if (
          model?.config?.sales_coverage &&
          model?.config?.sales_coverage === "-1"
        ) {
          const { sales_coverage, ...remaining } = model.config;
          updatedConfig = remaining;
        }
        updateData = {
          ...model,
          data_sources: [],
          config: { ...updatedConfig },
        };
      } else {
        updateData = {
          ...model,
          data_sources: model.data_sources,
        };
        delete updateData?.pilot_saved_selections;
      }
    }
    return updateData;
  });

  //Transform config if autoMl nested run is selected
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.autoMlId) {
      return model;
    } else {
      let updatedConfig = {};

      if (model.config.run_type === config.hardCoded.nestedRunId) {
        let {
          custom_nested_run,
          pilot_dates,
          pilot_end_date,
          pilot_start_date,
          ...remaining
        } = model.config;
        updatedConfig = { ...remaining };
      } else {
        updatedConfig = { ...model.config };
      }

      let newModel = { ...model, config: updatedConfig };
      return newModel;
    }
  });

  //Transform data_sources if autoMl has nielsen_comp_available true
  newState = newState.map((model) => {
    if (
      !config.hardCoded.autoMlIds.some(
        (elem) => elem.toLowerCase() === model?.id?.toLowerCase()
      )
    ) {
      let updatedDataSources = model?.data_sources?.map((dataset) => {
        let { top_n_competitor, ...remaining } = dataset;
        return { ...remaining };
      });
      let newModel = { ...model, data_sources: updatedDataSources };

      return newModel;
    } else {
      let updatedDatasources = [],
        updNielsenDatasources = [],
        nielsenFlag = true;
      model.data_sources.forEach((dataset) => {
        if (dataset.pipeline_dataset_id === config.hardCoded.neilsenNonCompId) {
          let {
            nielsen_comp_available,
            top_n_competitor,
            minValue,
            ...remaining
          } = dataset;
          if (dataset.nielsen_comp_available) {
            let nielsen = {
              ...remaining,
              id: config.hardCoded.neilsenCompId,
              name: config.hardCoded.neilsenCompId,
              pipeline_dataset_id: config.hardCoded.neilsenCompId,
              top_n_competitor: top_n_competitor,
            };
            updNielsenDatasources = [{ ...remaining }, nielsen];
          } else {
            nielsenFlag = false;
            updNielsenDatasources = [{ ...remaining }];
          }
        } else {
          updatedDatasources = [...updatedDatasources, dataset];
        }
      });
      //nielsen_comp is not checked
      if (!nielsenFlag) {
        updatedDatasources = updatedDatasources.filter(
          (dataset) =>
            dataset.pipeline_dataset_id !== config.hardCoded.neilsenCompId
        );
      }
      updatedDatasources = removeDupFromArrayOfObjects([
        ...updatedDatasources,
        ...updNielsenDatasources,
      ]); //removing duplicate entries
      let newModel = { ...model, data_sources: updatedDatasources };
      return newModel;
    }
  });

  //transform config for sales_uplift model
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.salesUpliftId) {
      return model;
    } else {
      let regionGranularityObj = model.config.region_granularity;
      let newTest = [],
        newControl = [];
      regionGranularityObj.test_dark_regions.test.forEach((item) => {
        let { id, type, ...remaining } = item;
        if (item.regions.length) {
          newTest.push({
            ...remaining,
            test_group_parameters: item.test_group_parameters[0],
          });
        }
      });
      regionGranularityObj.test_dark_regions.dark.forEach((item) => {
        let { id, type, ...remaining } = item;
        newControl.push({
          ...remaining,
        });
      });
      let configObj = {
        ...model.config,
        region_granularity: {
          region_type: regionGranularityObj.region_type,
          type: regionGranularityObj.type,
          test_dark_regions: {
            test: { ...newTest },
            dark: { ...newControl },
          },
        },
      };
      let newModel = { ...model, config: configObj };
      return newModel;
    }
  });

  //transform config for diff&diff model
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.diffDiffId) {
      return model;
    } else {
      let regionGranularityObj = model.config.region_granularity;
      let newTest = [],
        newControl = [];
      regionGranularityObj.test_control_regions.test.forEach((item) => {
        let { id, type, ...remaining } = item;
        if (item.regions.length) {
          newTest.push({
            ...remaining,
            campaigns: [item.campaigns[0]],
          });
        }
      });
      regionGranularityObj.test_control_regions.control.forEach((item) => {
        let { id, type, ...remaining } = item;
        newControl.push({
          ...remaining,
        });
      });
      let configObj = {
        ...model.config,
        region_granularity: {
          region_type: regionGranularityObj.region_type,
          type: regionGranularityObj.type,
          test_control_regions: {
            test: { ...newTest },
            control: { ...newControl },
          },
        },
      };
      let newModel = { ...model, config: configObj };
      return newModel;
    }
  });

  //transform config for RBT model
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.regBudId) {
      return model;
    } else {
      let regionGranularityObj = model.config.region_granularity;
      let newTest = [],
        newControl = [];
      regionGranularityObj.test_dark_regions.test.forEach((item) => {
        let { id, type, ...remaining } = item;
        if (item.regions.length) {
          newTest.push({
            ...remaining,
          });
        }
      });
      regionGranularityObj.test_dark_regions.dark.forEach((item) => {
        let { id, type, ...remaining } = item;
        newControl.push({
          ...remaining,
        });
      });
      let configObj = {
        ...model.config,
        region_granularity: {
          region_type: regionGranularityObj.region_type,
          type: regionGranularityObj.type,
          test_dark_regions: {
            test: { ...newTest },
            dark: { ...newControl },
          },
        },
      };
      let newModel = { ...model, config: configObj };
      return newModel;
    }
  });

  //transform nested_run_regions for AutoML model
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.autoMlId) {
      return model;
    } else {
      //nested json change when the nesteddriver api call given []
      let newNestedFields =
        model.config.run_type === config.hardCoded.nestedRunId &&
        model.config?.nested_run_regions?.test?.hasOwnProperty("0") &&
        model.config?.nested_run_regions?.test["0"]?.regions?.length === 0
          ? model.config.nested_run_regions
          : transformNestedRunRegionsToFirestoreStructure(
              model.config.nested_run_regions
            );
      let configObj = {
        ...model.config,
        nested_run_regions: newNestedFields,
      };
      let newModel = { ...model, config: configObj };
      return newModel;
    }
  });

  //transform config for AutoML model and AutoML budget model
  newState = newState.map((model) => {
    if (
      !config.hardCoded.autoMlIds.some(
        (elem) => elem.toLowerCase() === model.id.toLowerCase()
      )
    ) {
      return model;
    } else {
      let regionGranularityObj = model.config.region_granularity;
      if (regionGranularityObj.test_regions.control) {
        delete regionGranularityObj.test_regions["control"];
      }
      let newTest = [],
        newControl = [];
      let configObj = model.config;

      if (
        regionGranularityObj.test_regions.test instanceof Array
        // &&
        // regionGranularityObj.test_control_regions.control instanceof Array
      ) {
        regionGranularityObj.test_regions.test.forEach((item) => {
          let { id, type, files, ...remaining } = item;
          if (item.regions.length) {
            newTest.push({
              ...remaining,
            });
          }
        });
        // regionGranularityObj.test_control_regions.control.forEach((item) => {
        //   let { id, type, files, ...remaining } = item;
        //   newControl.push({
        //     ...remaining,
        //   });
        // });
        configObj = {
          ...model.config,
          retail_margin_price:
            model.config.retail_margin_price === ""
              ? 0
              : model.config.retail_margin_price,
          region_granularity: {
            region_type: regionGranularityObj.region_type,
            type: regionGranularityObj.type,
            test_regions: {
              test: { ...newTest },
              // control: { ...newControl },
            },
          },
        };
      }
      let newModel = { ...model, config: configObj };
      return newModel;
    }
  });

  //transform MMT model's config and data_sources
  newState = newState.map((model) => {
    let modelDataSource;
    if (model.id !== config.hardCoded.mmtId) {
      return model;
    } else {
      modelDataSource = model.data_sources.map((elem) => {
        let {
          adstock_parameters,
          enable_adstock,
          limit,
          saturation_flag,
          sl_limit,
          su_limit,
          ...remainingElems
        } = elem;
        return {
          ...remainingElems,
        };
      });
      let {
        sales_filter_min,
        sales_filter_max,
        regionFilterType,
        regionType,
        regions,
        files,
        ...remaining
      } = model.config;
      let configObj = {
        ...remaining,
        region_filters: {
          regionFilterType: model.config.regionFilterType,
          regionType: model.config.regionType,
          regions:
            model.config.regionSelectedType === "all"
              ? []
              : model.config.regions,
        },
        sales_filter: {
          sales_filter_min: model.config.sales_filter_min,
          sales_filter_max: model.config.sales_filter_max,
        },
      };
      let newModel = {
        ...model,
        config: configObj,
        data_sources: modelDataSource,
      };
      return newModel;
    }
  });

  //transform adstock parameters in AutoML
  newState = newState.map((model) => {
    let modelDataSource, updatedConfig;
    if (model.id !== config.hardCoded.autoMlId) {
      return model;
    } else {
      if (
        model.config.run_type === config.hardCoded.pilotRunId ||
        model.config.run_type === config.hardCoded.adstockRunId
      ) {
        modelDataSource = model.data_sources.map((elem) => {
          if (elem.type === config.hardCoded.datasetMediaType) {
            let { su_limit, sl_limit, limit, saturation_flag, ...remaining } =
              elem;
            if (elem?.enable_adstock) {
              return {
                ...remaining,
                adstock_parameters: {
                  su_limit: elem["su_limit"],
                  sl_limit: elem["sl_limit"],
                  limit: elem["limit"],
                  saturation_flag:
                    elem["saturation_flag"] === "true" ? true : false,
                },
              };
            } else {
              let {
                adstock_parameters,
                su_limit,
                sl_limit,
                limit,
                saturation_flag,
                ...remaining
              } = elem;
              return { ...remaining, enable_adstock: false };
            }
          } else {
            return elem;
          }
        });
        let { custom_nested_run, pilot_dates, ...remaining } = model.config;
        updatedConfig = { ...remaining };
      } else {
        modelDataSource = model.data_sources;
        updatedConfig = { ...model.config };
      }
      let newModel = {
        ...model,
        data_sources: modelDataSource,
        config: updatedConfig,
      };
      return newModel;
    }
  });

  //transform adstock parameters in autoMlBudget
  newState = newState.map((model) => {
    let modelDataSource, updatedConfig;
    if (model.id !== config.hardCoded.autoMlBudgetId) {
      return model;
    } else {
      modelDataSource = model.data_sources.map((elem) => {
        if (elem.type === config.hardCoded.datasetMediaType) {
          let { su_limit, sl_limit, limit, saturation_flag, ...remaining } =
            elem;
          if (elem?.enable_adstock) {
            return {
              ...remaining,
              adstock_parameters: {
                su_limit: elem["su_limit"],
                sl_limit: elem["sl_limit"],
                limit: elem["limit"],
                saturation_flag:
                  elem["saturation_flag"] === "true" ? true : false,
              },
            };
          } else {
            let {
              adstock_parameters,
              su_limit,
              sl_limit,
              limit,
              saturation_flag,
              ...remaining
            } = elem;
            return { ...remaining, enable_adstock: false };
          }
        } else {
          return elem;
        }
      });
      let { custom_nested_run, pilot_dates, ...remaining } = model.config;
      updatedConfig = { ...remaining };

      let newModel = {
        ...model,
        data_sources: modelDataSource,
        config: updatedConfig,
      };
      return newModel;
    }
  });

  //transform channel/ campaign in autoMlBudget
  newState = newState.map((model) => {
    let modelDataSource;
    if (model.id !== config.hardCoded.autoMlBudgetId) {
      return model;
    } else {
      modelDataSource = model.data_sources.map((elem) => {
        if (elem.type === config.hardCoded.datasetMediaType) {
          if (elem.channel_granularity === "channel") {
            let { group_non_target_as_others, target_campaigns, ...remaining } =
              elem;
            return {
              ...remaining,
            };
          } else if (elem.channel_granularity === "campaign") {
            let { group_as_others, ...remaining } = elem;
            return {
              ...remaining,
            };
          } else {
            return { ...elem };
          }
        } else {
          return elem;
        }
      });
      let newModel = { ...model, data_sources: modelDataSource };
      return newModel;
    }
  });

  //transform channel/ campaign in AutoML
  newState = newState.map((model) => {
    let modelDataSource;
    if (model.id !== config.hardCoded.autoMlId) {
      return model;
    } else {
      if (
        model.config.run_type === config.hardCoded.pilotRunId ||
        model.config.run_type === config.hardCoded.adstockRunId
      ) {
        modelDataSource = model.data_sources.map((elem) => {
          if (elem.type === config.hardCoded.datasetMediaType) {
            if (elem.channel_granularity === "channel") {
              let {
                group_non_target_as_others,
                target_campaigns,
                ...remaining
              } = elem;
              return {
                ...remaining,
              };
            } else if (elem.channel_granularity === "campaign") {
              let { group_as_others, ...remaining } = elem;
              return {
                ...remaining,
              };
            } else {
              return { ...elem };
            }
          } else {
            return elem;
          }
        });
      } else {
        modelDataSource = model.data_sources;
      }
      let newModel = { ...model, data_sources: modelDataSource };
      return newModel;
    }
  });

  //remove keys from Regression budget model
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.regBudId) {
      return model;
    } else {
      let updateData = {};
      if (model.config.rbt_run_type === config.hardCoded.budgetId) {
        updateData = {
          ...model,
          data_sources: [],
        };
      } else {
        updateData = {
          ...model,
          data_sources: model.data_sources,
        };
        delete updateData?.rbt_saved_selections;
      }
      let { custom_budget_run, ...remaining } = updateData.config;
      let configObj = {
        ...remaining,
      };
      let newModel = { ...updateData, config: { ...configObj } };
      return newModel;
    }
  });

  //Remove ID from combinations array
  newState = newState.map((model) => {
    //if model.config included combinations, remove id from all objects
    if (model.config[config.hardCoded.combinationsId]) {
      let updCombinations = model.config.combinations.map((combinationRow) => {
        let { id, ...remaining } = combinationRow;
        return remaining;
      });
      let updModel = {
        ...model,
        config: {
          ...model.config,
          [config.hardCoded.combinationsId]: updCombinations,
        },
      };
      return updModel;
    } else {
      return model;
    }
  });

  // KPI mandatory or monotonic or base wrapper only for MROI app
  newState = newState.map((model) => {
    if (configUserInputs?.app_id === config.hardCoded.mroiAppId) {
      if (
        config.hardCoded.autoMlIds.includes(model.id) ||
        config.hardCoded.mmtId
      ) {
        let modelDataSource = model.data_sources.map((elem) => {
          let defaultValue = {};
          elem.kpi_list.forEach(
            (kpi) => (defaultValue = { ...defaultValue, [kpi]: {} })
          );
          let { kpi_variables_list, ...remaining } = elem;
          return {
            ...remaining,
            kpi_list: elem?.kpi_variables_list
              ? transformKpiVariable(elem.kpi_list, elem.kpi_variables_list)
              : defaultValue,
          };
        });
        let newModel = { ...model, data_sources: modelDataSource };
        return newModel;
      } else {
        let modelDataSource = model.data_sources.map((elem) => {
          let defaultValue = {};
          elem.kpi_list.forEach(
            (kpi) => (defaultValue = { ...defaultValue, [kpi]: {} })
          );
          let { kpi_variables_list, ...remaining } = elem;
          return {
            ...remaining,
            kpi_list: defaultValue,
          };
        });
        let newModel = { ...model, data_sources: modelDataSource };
        return newModel;
      }
    } else {
      return model;
    }
  });
  //Add competitor data_source for TV dataset in autoML & autoML Budget
  newState = newState.map((model) => {
    if (
      !config.hardCoded.autoMlIds.some(
        (elem) => elem.toLowerCase() === model?.id?.toLowerCase()
      )
    ) {
      let updatedDatasources = model?.data_sources?.map((dataset) => {
        let { top_n_competitor, ...remaining } = dataset;
        return { ...remaining };
      });
      let newModel = { ...model, data_sources: updatedDatasources };
      return newModel;
    } else {
      let updatedDatasources = [],
        updCompetitorDatasources = [],
        competitorFlag = true;
      model.data_sources.forEach((dataset) => {
        if (
          config.hardCoded.topNCompetitor.includes(dataset.pipeline_dataset_id)
        ) {
          let {
            competitor_available,
            top_n_competitor,
            minValue,
            ...remaining
          } = dataset;
          if (dataset.competitor_available) {
            let competitorObj = {
              ...remaining,
              id: `${dataset.pipeline_dataset_id}_COMPETITOR`,
              name: `${dataset.pipeline_dataset_id}_COMPETITOR`,
              pipeline_dataset_id: `${dataset.pipeline_dataset_id}_COMPETITOR`,
              top_n_competitor: top_n_competitor,
            };
            updCompetitorDatasources = [{ ...remaining }, competitorObj];
          } else {
            competitorFlag = false;
            updCompetitorDatasources = [{ ...remaining }];
          }
        } else {
          updatedDatasources = [...updatedDatasources, dataset];
        }
      });
      //competitor is not checked
      if (!competitorFlag) {
        updatedDatasources = updatedDatasources.filter(
          (dataset) => dataset.pipeline_dataset_id !== "TV_COMPETITOR"
        );
      }
      updatedDatasources = removeDupFromArrayOfObjects([
        ...updatedDatasources,
        ...updCompetitorDatasources,
      ]); //removing duplicate entries
      let newModel = { ...model, data_sources: updatedDatasources };
      return newModel;
    }
  });

  //remove the keys from the config for Automl and AutomlBudget for  and manual run
  newState = newState.map((model) => {
    if (
      model.id === config.hardCoded.autoMlId &&
      model.config.run_type === config.hardCoded.nestedRunId
    ) {
      let { seasonality, ...remainingConfig } = model.config;
      return {
        ...model,
        config: remainingConfig,
      };
    } else {
      return model;
    }
  });

  // merge dataset
  newState = newState.map((model) => {
    if (configUserInputs?.app_id === config.hardCoded.mroiAppId) {
      let modelDataSource = model.data_sources
        .map((elem) => {
          if (elem.merge_dataset) {
            let mergeDataSourceFound = model?.merged_data_sources?.find(
              (dt) => dt.id === elem.id
            );
            let newDataset = elem?.dataset_list?.map((item) => {
              if (mergeDataSourceFound?.dataset_list?.includes(item.id)) {
                const { dataset_list, merge_dataset, ...remaining } = elem;
                return {
                  ...remaining,
                  ...item,
                  channel: elem?.channel,
                  kpi_list: elem?.kpi_list,
                };
              }
            });
            newDataset = newDataset.filter((elem) => elem !== undefined);
            return newDataset;
          } else {
            return elem;
          }
        })
        .filter((elem) => !elem.merge_dataset)
        .flat();

      // removing duplicate merged dataset from data_sources
      modelDataSource = modelDataSource.filter(
        (v, i, a) => a.findIndex((v2) => v2?.id === v?.id) === i
      );
      let mergedDataSource = model?.merged_data_sources?.map((elem) => {
        // const { channel, ...remaining } = elem;
        return elem;
      });
      let newModel = {
        ...model,
        data_sources: modelDataSource,
        ...(mergedDataSource && { merged_data_sources: mergedDataSource }),
      };
      return newModel;
    } else {
      return model;
    }
  });

  //add the default value in sales uplift
  newState = newState.map((model) => {
    if (
      model.id === config.hardCoded.salesUpliftId ||
      config.hardCoded.autoMlIds.includes(model.id)
    ) {
      return {
        ...model,
        config: {
          ...model.config,
          retail_margin_price:
            model?.config?.retail_margin_price === ""
              ? 0
              : model?.config?.retail_margin_price,
        },
      };
    } else {
      return { ...model };
    }
  });

  //remove key from the mmt and automl and automl budget and su
  newState = newState.map((model) => {
    if (!isConfigDEOption) {
      if (model.id === config.hardCoded.mmtId) {
        const { no_of_similar_regions, ...remainingConfig } = model.config;
        return { ...model, config: remainingConfig };
      } else if (
        config.hardCoded.autoMlIds.includes(model.id) ||
        model.id === config.hardCoded.salesUpliftId
      ) {
        const { retail_margin_price, ...remainingConfig } = model.config;
        return { ...model, config: remainingConfig };
      } else {
        return model;
      }
    } else {
      return model;
    }
  });

  //remove key(sales_coverage) from automl, automl budget, SU model
  newState = newState.map((model) => {
    if (!isConfigDEOption) {
      if (
        model.id === config.hardCoded.salesUpliftId ||
        model.id === config.hardCoded.autoMlId ||
        model.id === config.hardCoded.autoMlBudgetId
      ) {
        const { sales_coverage, ...remainingConfig } = model.config;
        return { ...model, config: remainingConfig };
      } else {
        return model;
      }
    } else {
      return model;
    }
  });

  // remove corr_upper_limit from the mmt model
  newState = newState.map((model) => {
    if (
      model.id === config.hardCoded.mmtId &&
      (!model.config?.corr_upper_limit ||
        JSON.stringify(model.config?.corr_upper_limit) === `[]`) &&
      model.config?.corr_upper_limit !== 0
    ) {
      let { corr_upper_limit, ...remaningConfig } = model.config;
      return {
        ...model,
        config: remaningConfig,
      };
    } else {
      return model;
    }
  });

  // add and remove
  newState = newState.map((model) => {
    // !curr.dataset_list.every((elem) =>
    //   model.data_sources.find((dataset) => dataset.id === elem))
    const data = model?.merged_data_sources?.reduce((acc, curr) => {
      if (
        !model.data_sources.find((dataset) =>
          curr.dataset_list.includes(dataset.id)
        )
      ) {
        acc.push({
          ...curr,
          kpi_variable_list: [],
          kpi_list: [],
          type: "MEDIA",
        });
      }
      return acc;
    }, []);
    return {
      ...model,
      ...(data && { data_sources: [...model.data_sources, ...data] }),
    };
  });

  newState = newState.map((model) => {
    let arr = [];
    const mergeDataset = model?.merged_data_sources?.map((e) => {
      const { channel, id, ...remaining } = e;
      arr.push({ channel, id });
      return { ...remaining, id };
    });
    return { ...model, merged_data_sources: mergeDataset, mergeChannels: arr };
  });

  //remove merged_data_sources,mergeChannels if autoMl has nested run selected
  newState = newState.map((model) => {
    if (model.id !== config.hardCoded.autoMlId) {
      return model;
    } else {
      if (model.config.run_type === config.hardCoded.nestedRunId) {
        const { mergeChannels, merged_data_sources, ...remaining } = model;
        return { ...remaining, data_sources: [] };
      } else {
        return model;
      }
    }
  });

  //remove the cols_to_force_in key from the rbt and su model config

  newState = newState.map((model) => {
    if (config.hardCoded.rbtAndSuIds.includes(model.id)) {
      const { cols_to_force_in, ...remaining } = model.config;
      return { ...model, config: { ...remaining } };
    }
    return model;
  });

  //remove corilation thresold key
  newState = newState.map((model) => {
    if (model.id === config.hardCoded.mmtId && model?.run) {
      let filterModel = allData.models.find((elm) => elm.id === model.id);
      let corilationLocalCountries = filterModel?.local_parameters.find(
        (e) => e.id === "corr_upper_limit"
      ).countries;
      if (!corilationLocalCountries.includes(configUserInputs.country)) {
        const { corr_upper_limit, ...remaining } = model.config;
        return { ...model, config: { ...remaining } };
      } else {
        return model;
      }
    } else {
      return model;
    }
  });
  //removing regions key from each model
  newState = newState.map((model) => {
    const { regions, ...remaining } = model.config;
    return {
      ...model,
      config: { ...remaining },
    };
  });

  // //Remove ID from test_group_parameters array
  // newState = newState.map((model) => {
  //   //if model.config included test_group_parameters, remove id from all objects
  //   if (model.config[config.hardCoded.combinationsSalesId]) {
  //     let updCombinations = model.config.test_group_parameters; // array to obj
  //     updCombinations = updCombinations.map((combinationRow) => {
  //       let { id, ...remaining } = combinationRow;
  //       return remaining;
  //     });
  //     updCombinations = transformArrayToObj(updCombinations); // array to obj
  //     let updModel = {
  //       ...model,
  //       config: {
  //         ...model.config,
  //         [config.hardCoded.combinationsSalesId]: updCombinations,
  //       },
  //     };
  //     return updModel;
  //   } else {
  //     return model;
  //   }
  // });

  return newState;
};

export const makeManualConfigTemplate = (configUserInputs, allData) => {
  let finalConfig = {
    name: `${
      configUserInputs.configNameFixed
    }${configUserInputs.configName.trim()}`,
    app_id: configUserInputs.app_id,
    app_name: configUserInputs.appName,
    metadata: {
      ...configUserInputs.metadata,
      tags: configUserInputs?.tags || [],
      run_type: "manual",
    },
    temp_uuid: configUserInputs?.temp_uuid || "",
    mode: configUserInputs?.configId ? "edit" : "add", // api team need this key to manipulate temp folders in MR
    selections: {
      config: {
        app_id: configUserInputs.app_id,
        app_name: configUserInputs.appName,
        country: configUserInputs.country,
        brand: configUserInputs.brand,
        segment: [],
        historic_week_data: 0,
        start_date: "",
        end_date: "",
        is_config_manual: configUserInputs?.is_config_manual,
      },
      models: transformManualConfigUserModels(
        configUserInputs.models,
        configUserInputs,
        allData
      ),
    },
  };

  //add sub_brand and sub_segment if App is MROI
  if (configUserInputs.app_id === config.hardCoded.mroiAppId) {
    finalConfig = {
      ...finalConfig,
      selections: {
        ...finalConfig.selections,
        config: {
          ...finalConfig.selections.config,
          sub_brand: [],
          sub_segment: [],
          segment_select_all: false,
          sub_brand_select_all: false,
          sub_segment_select_all: false,
          media_sub_brand: [],
          media_segment: [],
          media_sub_segment: [],
          media_sub_brand_select_all: false,
          media_sub_segment_select_all: false,
          media_segment_select_all: false,
        },
      },
    };
  }

  if (configUserInputs.configId) {
    finalConfig = { ...finalConfig, id: configUserInputs.configId };
  }
  console.log("final config--->", finalConfig);
  return finalConfig;
};

export const makeConfigTemplate = (configUserInputs, allData) => {
  const checkDERunConfig = configUserInputs.models.every(
    (e) => e.config.run_model === true
  );
  let finalConfig = {
    name: `${
      configUserInputs.configNameFixed
    }${configUserInputs.configName.trim()}`,
    app_id: configUserInputs.app_id,
    app_name: configUserInputs.appName,
    metadata: {
      ...configUserInputs.metadata,
      tags: configUserInputs?.tags || [],
      run_type: configUserInputs.metadata.run_type
        ? configUserInputs.metadata.run_type
        : !checkDERunConfig
        ? "de_only"
        : "automated",
    },
    selections: {
      config: {
        app_id: configUserInputs.app_id,
        app_name: configUserInputs.appName,
        country: configUserInputs.country,
        brand: configUserInputs.brand,
        segment: removeAllInData(configUserInputs.segment),
        historic_week_data: parseFloat(configUserInputs.historic_week_data),
        start_date: configUserInputs.startDate,
        end_date: configUserInputs.endDate,
        ...(configUserInputs?.is_schedule_config && {
          is_schedule_config: configUserInputs?.is_schedule_config,
        }),
        segment_select_all: configUserInputs?.segment_select_all,
        sub_brand_select_all: configUserInputs?.sub_brand_select_all,
        sub_segment_select_all: configUserInputs?.sub_segment_select_all,
        media_sub_brand:
          removeAllInData(configUserInputs?.media_sub_brand) || [],
        media_segment: removeAllInData(configUserInputs?.media_segment) || [],
        media_sub_segment:
          removeAllInData(configUserInputs?.media_sub_segment) || [],
        media_sub_brand_select_all:
          configUserInputs?.media_sub_brand_select_all,
        media_sub_segment_select_all:
          configUserInputs?.media_sub_segment_select_all,
        media_segment_select_all: configUserInputs?.media_segment_select_all,
      },
      models: transformConfigUserModels(
        configUserInputs.models,
        configUserInputs,
        allData
      ),
    },
  };

  //add sub_brand and sub_segment if App is MROI
  if (configUserInputs.app_id === config.hardCoded.mroiAppId) {
    finalConfig = {
      ...finalConfig,
      selections: {
        ...finalConfig.selections,
        config: {
          ...finalConfig.selections.config,
          sub_brand: removeAllInData(configUserInputs.sub_brand),
          sub_segment: removeAllInData(configUserInputs.sub_segment),
        },
      },
    };
  }

  if (configUserInputs.configId) {
    finalConfig = { ...finalConfig, id: configUserInputs.configId };
  }
  console.log("final config--->", finalConfig);
  return finalConfig;
};

export const transformObjToArray = (config) => {
  let arrayResult = Object.values(config);
  return arrayResult;
};

//Helps to transform config so that UI can work
export const makeTestControlConfigForModels = (
  data,
  key,
  local_parameters_key,
  manualFiles
) => {
  let res = [];
  if (data) {
    res = Object.entries(data)?.map((e) => e[1]); //test:[]
  }
  res = res?.map((item) => {
    return {
      ...item,
      ...(key && {
        type: key,
      }),
      ...(manualFiles && {
        files: {
          ...item.files,
          kpi_details: item.kpi_details,
          collinear_drivers: item.collinear_drivers || {},
          collinear_variables_to_drop: item.collinear_variables_to_drop || [],
        },
      }),
      id: v4(),
      ...(local_parameters_key &&
        (local_parameters_key === "campaigns"
          ? {
              [local_parameters_key]: [
                { ...item[local_parameters_key][0], id: v4() },
              ],
            }
          : {
              [local_parameters_key]: [
                { ...item[local_parameters_key], id: v4() },
              ],
            })),
    };
  });
  return res;
};

//Transform testControl from object to array
export const transformTestControlFromObjectToArray = (
  data,
  key,
  local_parameters_key
) => {
  // let res = Object.entries(data).map((e) => ({ key: e[0], value: e[1] })); //test:[]
  let res = Object.entries(data).map(([currKey, currValue]) => {
    return {
      ...currValue,
      position: parseInt(currKey),
      ...(key && {
        type: key,
      }),
      id: v4(),
      ...(local_parameters_key &&
        (local_parameters_key === "campaigns"
          ? {
              [local_parameters_key]: [
                { ...currValue[local_parameters_key][0], id: v4() },
              ],
            }
          : {
              [local_parameters_key]: [
                { ...currValue[local_parameters_key], id: v4() },
              ],
            })),
    };
  });
  return res;
};

//adding values based on selesction in dataset, Currently for AutoML & autoMlBudget
const transformDatasets = (datasets) => {
  let checkFlagForComp = datasets.find(
    (dataset) => dataset.pipeline_dataset_id === config.hardCoded.neilsenCompId
  );
  let checkFlagForComp1 = datasets.find(
    (dataset) => dataset.pipeline_dataset_id === "TV_COMPETITOR"
  );
  //nielsen_comp_available
  let transformedDatasets = datasets.map((dataset) =>
    dataset.pipeline_dataset_id === config.hardCoded.neilsenNonCompId
      ? {
          ...dataset,
          nielsen_comp_available: checkFlagForComp ? true : false,
          top_n_competitor: checkFlagForComp
            ? checkFlagForComp.top_n_competitor
            : 5,
        }
      : config.hardCoded.topNCompetitor.includes(dataset.pipeline_dataset_id) //for other competitor datasets (right now only TV)
      ? {
          ...dataset,
          competitor_available: checkFlagForComp1 ? true : false,
          top_n_competitor: checkFlagForComp1
            ? checkFlagForComp1.top_n_competitor
            : 5,
        }
      : dataset
  );
  //enable_adstock
  transformedDatasets = transformedDatasets.map((dataset) =>
    dataset.type === config.hardCoded.datasetMediaType
      ? Object.keys(dataset?.adstock_parameters || {}).length
        ? {
            ...dataset,
            enable_adstock: true,
          }
        : {
            ...dataset,
            enable_adstock: false,
          }
      : dataset
  );
  //granularity
  transformedDatasets = transformedDatasets.map((dataset) =>
    dataset.type === config.hardCoded.datasetMediaType
      ? {
          ...dataset,
          group_as_others:
            dataset.channel_granularity === "channel"
              ? dataset?.group_as_others
                ? dataset.group_as_others
                : false
              : false,
          group_non_target_as_others:
            dataset.channel_granularity === "campaign"
              ? dataset?.group_non_target_as_others
                ? dataset.group_non_target_as_others
                : false
              : false,
          target_campaigns:
            dataset.channel_granularity === "campaign"
              ? dataset.target_campaigns
                ? dataset.target_campaigns
                : []
              : [],
        }
      : dataset
  );
  return transformedDatasets;
};

export const makeConfigUserInputs = (props) => {
  let {
    initialConfigData,
    match,
    disableFlag,
    activeApp,
    initialConfigDataFallback,
    allData,
    configType,
    sub_brand_select_all,
    sub_segment_select_all,
    segment_select_all,
    media_sub_brand_select_all,
    media_sub_segment_select_all,
    media_segment_select_all,
  } = props;
  let selections = initialConfigData.selections;
  let selectionModels = selections.models;
  let globalModels = allData.models;
  console.log("SELECTION MODELS BEFORE -> ", selectionModels);

  //check if configUserInputs.models has all the models that allData.models has; if not populate it from default_config_template
  //do this only if it's not a run model
  if (!disableFlag) {
    let selectionModelsIds = selectionModels.map((model) => model.id);
    let absentModels = globalModels?.filter(
      (model) => !selectionModelsIds.includes(model.id)
    );
    if (absentModels?.length) {
      absentModels.forEach((absentModel) => {
        let initialModelSelections =
          initialConfigDataFallback.selections.models.find(
            (model) => model.id === absentModel.id
          );
        if (initialModelSelections) {
          selectionModels = [
            ...selectionModels,
            { ...initialModelSelections, run: false },
          ];
        }
      });
    }
  }

  //check if configUserInputs.models.data_sources has all the latest local_parameters; if not populate it from allData.datasets
  //do this only if it's not a run model
  if (!disableFlag) {
    selectionModels = selectionModels.map((model) => {
      let data_sources = model.data_sources.map((dataSource) => {
        let filteredDataset = allData?.datasets?.find(
          (dataset) => dataset.id === dataSource.id
        );
        filteredDataset?.local_parameters.forEach((lp) => {
          if (!dataSource[lp.id]) {
            let value;
            switch (lp.ui_element_type) {
              case "radio_button":
                value = lp.data[0].id;
                break;
              case "dropdown":
                value = lp.data[0].id;
                break;
              case "inputbox_number":
                value = 1;
                break;
              case "inputbox_text":
                value = "";
                break;
              case "custom":
                value = [];
                break;
              default:
                value = null;
                break;
            }
            dataSource[lp.id] = value;
          }
        });
        return { ...dataSource };
      });

      let filteredModel = allData?.models?.find(
        (currModel) => currModel.id === model.id
      );
      let modelConfig = model.config;
      filteredModel?.local_parameters.forEach((lp) => {
        if (modelConfig[lp.id] === undefined) {
          let value;
          switch (lp.ui_element_type) {
            case "radio_button":
              value = lp.data[0].id;
              break;
            case "dropdown":
              value = lp.data[0].id;
              break;
            case "inputbox_number":
              value = lp.defaultValue;
              break;
            case "inputbox_text":
              value = "";
              break;
            case "custom":
              switch (lp.id) {
                case "adstock_preview":
                  value = lp.data[0].value;
                  break;
                case "group_all_targets":
                  value = lp.data[0].value;
                  break;
                case "upload_audience":
                  value = lp.data[0].value;
                  break;
                case "sales_filter":
                  value = {};
                  break;
                case "retail_margin_price":
                  value = parseFloat(+lp.defaultValue.toFixed(2));
                  break;
                case "sales_coverage":
                  value = parseFloat(+lp.defaultValue.toFixed(2));
                  break;
                case "seasonality":
                  value = lp.data[0].value;
                  break;
                case "algorithm":
                  value = lp.data[0].value;
                  break;
                default:
                  value = [];
                  break;
              }
              break;
            default:
              value = null;
              break;
          }
          modelConfig[lp.id] = value;
        }
      });
      return { ...model, data_sources, config: modelConfig };
    });
  }

  // remove datasets from configUserInputs that are not present now
  if (!disableFlag && initialConfigData.app_id === config.hardCoded.mroiAppId) {
    let start_date, end_date;
    if (selections?.config?.historic_week_data) {
      let getDate = getDatesFromWeek(selections.config.historic_week_data);
      start_date = getDate?.min;
      end_date = getDate?.max;
    } else {
      start_date = selections.config.start_date;
      end_date = selections.config.end_date;
    }
    selectionModels = selectionModels.map((model) => {
      let updModel;
      // for mmt manual config, filteration in else if block is not required
      // thats why this if block is written
      if (
        selections?.config?.is_config_manual &&
        config.hardCoded.manualKpiARR.includes(model.id)
      ) {
        updModel = { ...model };
      } else {
        updModel = {
          ...model,
          data_sources: model.data_sources.filter((elem) =>
            allData?.datasetDetails?.some((datasetOfActiveModel) => {
              if (
                datasetOfActiveModel.dataset_id === elem.id &&
                datasetOfActiveModel.country_name ===
                  selections?.config?.country &&
                (datasetOfActiveModel.brand_name ===
                  selections?.config?.brand ||
                  !datasetOfActiveModel?.brand_name)
              ) {
                if (
                  datasetOfActiveModel?.start_date &&
                  datasetOfActiveModel.end_date
                ) {
                  let checkForValidDataset = checkDatesOverlapping(
                    start_date,
                    end_date,
                    datasetOfActiveModel.start_date,
                    datasetOfActiveModel.end_date
                  );
                  const { type, status } = checkForValidDataset;
                  if (type || status) {
                    return true;
                  }
                  return false;
                } else {
                  return true;
                }
              } else if (
                config.hardCoded.competitorTVNielsenArr.includes(elem.id)
              ) {
                let neilsenNonCompFound = model.data_sources.find((elem) =>
                  config.hardCoded.competitorArr.includes(
                    elem.pipeline_dataset_id
                  )
                );
                if (neilsenNonCompFound) {
                  return true;
                }
                return false;
              }
              return false;
            })
          ),
        };
      }
      return updModel;
    });
  }

  selectionModels = selectionModels.map((model) => {
    let updModel = {
      ...model,
      data_sources: model.data_sources.map((elem) => {
        return config.hardCoded.radioDatasetList.includes(elem.type)
          ? !elem?.hasOwnProperty("sku")
            ? configType === "add"
              ? { ...elem, sku: [{ sku: "ALL" }] }
              : {
                  ...elem,
                  sku: elem?.sku
                    ? elem?.sku?.map((str) => ({ sku: str }))
                    : [{ sku: "ALL" }],
                }
            : {
                ...elem,
                sku: elem?.sku?.map((str) =>
                  typeof str === "object"
                    ? {
                        sku: str.sku,
                      }
                    : { sku: str }
                ),
              }
          : {
              ...elem,
            };
      }),
    };
    return updModel;
  });

  //add data_sources and id in AutoMl model
  selectionModels = selectionModels.map((model) => {
    if (
      config.hardCoded.autoMlIds.some(
        (elem) => elem.toLowerCase() === model.id.toLowerCase()
      )
    ) {
      let updModel = {
        ...model,
        data_sources: transformDatasets(model.data_sources),
      };
      return updModel;
    } else {
      return model;
    }
  });

  //add region_granularity fields and id in AutoMl model
  selectionModels = selectionModels.map((model) => {
    if (
      config.hardCoded.autoMlIds.some(
        (elem) => elem.toLowerCase() === model.id.toLowerCase()
      ) &&
      model.run
    ) {
      let updModel = {
        ...model,
        config: {
          ...model.config,
          region_granularity: {
            ...model.config.region_granularity,
            test_regions: {
              test: makeTestControlConfigForModels(
                model?.config?.region_granularity?.test_regions?.test,
                "test",
                null,
                selections.config.is_config_manual,
                null
              ),
              // control: makeTestControlConfigForModels(
              //   model?.config?.region_granularity?.test_control_regions
              //     ?.control,
              //   "control",
              //   null,
              //   selections.config.is_config_manual,
              //   null
              // ),
              files: {
                ...model?.config?.region_granularity?.test_regions?.files,
                kpi_details:
                  model?.config?.region_granularity?.test_regions?.kpi_details,
                collinear_drivers:
                  model?.config?.region_granularity?.test_regions
                    ?.collinear_drivers || {},
                collinear_variables_to_drop:
                  model?.config?.region_granularity?.test_regions
                    ?.collinear_variables_to_drop || [],
              },
            },
          },
        },
      };
      return updModel;
    } else {
      return model;
    }
  });

  //add region_granularity fields and id in test_group_parameters in SALES_UPLIFT model
  selectionModels = selectionModels.map((model) => {
    if (model.id === config.hardCoded.salesUpliftId) {
      let updModel = {
        ...model,
        config: {
          ...model.config,
          region_granularity: {
            ...model.config.region_granularity,
            test_dark_regions: {
              test: makeTestControlConfigForModels(
                model?.config?.region_granularity?.test_dark_regions?.test,
                "test",
                "test_group_parameters",
                null,
                null
              ),
              dark: makeTestControlConfigForModels(
                model?.config?.region_granularity?.test_dark_regions?.dark,
                "dark",
                null,
                null,
                null
              ),
            },
          },
        },
      };
      return updModel;
    } else {
      return model;
    }
  });

  //add region_granularity fields and id in test_group_parameters in Diff&Diff model
  selectionModels = selectionModels.map((model) => {
    if (model.id === config.hardCoded.diffDiffId) {
      let updModel = {
        ...model,
        config: {
          ...model.config,
          region_granularity: {
            ...model.config.region_granularity,
            test_control_regions: {
              test: makeTestControlConfigForModels(
                model.config.region_granularity.test_control_regions.test,
                "test",
                "campaigns",
                null,
                true
              ),
              control: makeTestControlConfigForModels(
                model.config.region_granularity.test_control_regions.control,
                "control",
                null,
                null,
                null
              ),
            },
          },
        },
      };
      return updModel;
    } else {
      return model;
    }
  });

  //add region_granularity fields and id in RBT model
  selectionModels = selectionModels.map((model) => {
    if (model.id === config.hardCoded.regBudId) {
      let updModel = {
        ...model,
        config: {
          ...model.config,
          region_granularity: {
            ...model.config.region_granularity,
            test_dark_regions: {
              test: makeTestControlConfigForModels(
                model.config.region_granularity.test_dark_regions.test,
                "test",
                null,
                null,
                null
              ),
              dark: makeTestControlConfigForModels(
                model.config.region_granularity.test_dark_regions.dark,
                "dark",
                null,
                null,
                null
              ),
            },
          },
        },
      };
      return updModel;
    } else {
      return model;
    }
  });

  //add regionFilterType in MMT model
  selectionModels = selectionModels.map((model) => {
    if (model.id === config.hardCoded.mmtId) {
      let updModel = {
        ...model,
        config: {
          ...model.config,
          regionSelectedType: model.config.regionSelectedType,
          region_filters: model.config.region_filters
            ? model.config.region_filters
            : {},
          ...(selections.config.is_config_manual && {
            files:
              {
                ...model.config.region_filters?.files,
              } || {},
          }),
          regionFilterType: model.config.region_filters?.regionFilterType
            ? model.config.region_filters?.regionFilterType
            : "exclude",
          regionType: model.config.region_filters?.regionType
            ? model.config.region_filters?.regionType
            : "dma",
          regions: model.config.region_filters?.regions
            ? model.config.region_filters?.regions
            : [],
          sales_filter: model.config.sales_filter
            ? model.config.sales_filter
            : {},
          sales_filter_min: model.config.sales_filter
            ? Object.keys(model.config.sales_filter).length
              ? model.config.sales_filter.sales_filter_min
              : 0
            : 0,
          sales_filter_max: model.config.sales_filter
            ? Object.keys(model.config.sales_filter).length
              ? model.config.sales_filter.sales_filter_max
              : 50
            : 50,
        },
      };
      return updModel;
    } else {
      return model;
    }
  });

  //add autoML parameters and pilot_start_date and pilot_end_date in AutoML
  selectionModels = selectionModels.map((model) => {
    let modelDataSource, modelConfig;
    if (model.id === config.hardCoded.autoMlId) {
      if (
        model.config.run_type === config.hardCoded.pilotRunId ||
        model.config.run_type === config.hardCoded.adstockRunId
      ) {
        modelDataSource = model.data_sources.map((elem) => {
          if (
            elem.type === config.hardCoded.datasetMediaType &&
            elem.enable_adstock
          ) {
            return {
              ...elem,
              sl_limit: elem.adstock_parameters
                ? Object.keys(elem.adstock_parameters).length
                  ? elem.adstock_parameters.sl_limit
                  : elem.sl_limit
                  ? elem.sl_limit
                  : 0.1
                : 0.1,
              su_limit: elem.adstock_parameters
                ? Object.keys(elem.adstock_parameters).length
                  ? elem.adstock_parameters.su_limit
                  : elem.su_limit
                  ? elem.su_limit
                  : 1
                : 1,
              limit: elem.adstock_parameters
                ? Object.keys(elem.adstock_parameters).length
                  ? elem.adstock_parameters.limit
                  : elem.limit
                  ? elem.limit
                  : 1
                : 1,
              saturation_flag: elem.adstock_parameters
                ? Object.keys(elem.adstock_parameters).length
                  ? elem.adstock_parameters.saturation_flag.toString()
                  : elem.saturation_flag
                  ? elem.saturation_flag.toString()
                  : "false"
                : "false",
            };
          } else {
            return elem;
          }
        });
        modelConfig = {
          ...model.config,
          pilot_start_date: model.config?.pilot_start_date || "",
          pilot_end_date: model.config?.pilot_end_date || "",
        };
      } else {
        modelDataSource = model.data_sources;
        modelConfig = {
          ...model.config,
          pilot_start_date: model.config?.pilot_start_date || "",
          pilot_end_date: model.config?.pilot_end_date || "",
        };
      }
      let newModel = {
        ...model,
        data_sources: modelDataSource,
        config: modelConfig,
      };
      return newModel;
    } else {
      return model;
    }
  });

  //add autoML parameters and pilot_start_date and pilot_end_date in AutoML Budget
  selectionModels = selectionModels.map((model) => {
    let modelDataSource, modelConfig;
    if (model.id === config.hardCoded.autoMlBudgetId) {
      modelDataSource = model.data_sources.map((elem) => {
        if (
          elem.type === config.hardCoded.datasetMediaType &&
          elem.enable_adstock
        ) {
          return {
            ...elem,
            sl_limit: elem.adstock_parameters
              ? Object.keys(elem.adstock_parameters).length
                ? elem.adstock_parameters.sl_limit
                : elem.sl_limit
                ? elem.sl_limit
                : 0.1
              : 0.1,
            su_limit: elem.adstock_parameters
              ? Object.keys(elem.adstock_parameters).length
                ? elem.adstock_parameters.su_limit
                : elem.su_limit
                ? elem.su_limit
                : 1
              : 1,
            limit: elem.adstock_parameters
              ? Object.keys(elem.adstock_parameters).length
                ? elem.adstock_parameters.limit
                : elem.limit
                ? elem.limit
                : 1
              : 1,
            saturation_flag: elem.adstock_parameters
              ? Object.keys(elem.adstock_parameters).length
                ? elem.adstock_parameters.saturation_flag.toString()
                : elem.saturation_flag
                ? elem.saturation_flag.toString()
                : "false"
              : "false",
          };
        } else {
          return elem;
        }
      });
      modelConfig = {
        ...model.config,
        pilot_start_date: model.config?.pilot_start_date
          ? model.config?.pilot_start_date
          : selections.config.historic_week_data
          ? getFutureDate(1)
          : selections.config.start_date,
        pilot_end_date: model.config?.pilot_end_date
          ? model.config?.pilot_end_date
          : selections.config.historic_week_data
          ? getFutureDate(2)
          : selections.config.end_date,
      };
      let newModel = {
        ...model,
        data_sources: modelDataSource,
        config: modelConfig,
      };
      return newModel;
    } else {
      return model;
    }
  });

  //Transform nested_run_regions to UI friendly-structure
  selectionModels = selectionModels.map((model) => {
    if (model.id === config.hardCoded.autoMlId) {
      let newModel = {
        ...model,
        config: {
          ...model.config,
          nested_run_regions: transformNestedRunRegionsToUiStructure(
            model.config.nested_run_regions
          ),
        },
      };
      return newModel;
    } else {
      return model;
    }
  });

  //Add ID in combinations array
  selectionModels = selectionModels.map((model) => {
    //if model.config includes combinations, add id for all objects
    if (model.config[config.hardCoded.combinationsId]) {
      let updCombinations = model.config.combinations.map((combinationRow) => ({
        ...combinationRow,
        id: v4(),
      }));
      let updModel = {
        ...model,
        config: {
          ...model.config,
          [config.hardCoded.combinationsId]: updCombinations,
        },
      };
      return updModel;
    } else {
      return model;
    }
  });

  selectionModels = selectionModels.map((model) => {
    // for mmt manual config, conversion in else if block is not required
    // thats why this if block is written
    if (
      selections?.config?.is_config_manual &&
      config.hardCoded.manualKpiARR.includes(model.id)
    ) {
      return model;
    }
    //if model.config includes combinations, add id for all objects
    else {
      let modelDataSource = model.data_sources.map((elem) => {
        let kpiList, kpiVariablesList, kpiParameter;
        let globalDataset = globalModels.find(
          (value) => value.id === model.id
        ).dataset_ids;
        kpiParameter = globalDataset.find(
          (item) => item.id === elem.id
        )?.kpi_variables_list;
        if (elem.kpi_list instanceof Array) {
          kpiList = elem.kpi_list;
          if (
            model.id === config.hardCoded.mmtId ||
            config.hardCoded.rbtAndSuIds.includes(model.id)
          ) {
            kpiVariablesList = elem?.kpi_variables_list
              ? elem.kpi_variables_list
              : [];
          } else {
            kpiVariablesList = [];
          }
        } else {
          kpiList = Object.keys(elem.kpi_list);
          kpiVariablesList = Object.entries(elem.kpi_list).map((value) => ({
            id: value[0],
            ...value[1],
            kpi_parameters: kpiParameter ? kpiParameter[value[0]] : [],
          }));
        }
        return {
          ...elem,
          kpi_list: kpiList,
          kpi_variables_list: kpiVariablesList,
        };
      });
      let newModel = {
        ...model,
        data_sources: modelDataSource,
      };
      return newModel;
    }
  });

  //transformation for unselected merged datasets as they should be removed
  // from data_sources and the channel should be added into merged_data_sources
  selectionModels = selectionModels.map((model) => {
    if (initialConfigData.app_id === config.hardCoded.mroiAppId) {
      let findMergeDataset;
      model?.data_sources?.forEach((elem) => {
        findMergeDataset = model?.merged_data_sources?.map((dataset) => {
          if (dataset.id === elem.id) {
            const { kpi_list, kpi_variable_list, type, ...remaining } = elem;
            return remaining;
          } else {
            return dataset;
          }
        });
      });
      if (findMergeDataset) {
        return { ...model, merged_data_sources: findMergeDataset };
      } else {
        return model;
      }
    } else {
      return model;
    }
  });

  // merge dataset
  selectionModels = selectionModels.map((model) => {
    if (initialConfigData.app_id === config.hardCoded.mroiAppId) {
      if (model?.merged_data_sources?.length) {
        let updatedDatasetList = [];
        let modelDataSource = model.data_sources
          ?.map((elem) => {
            return model?.merged_data_sources
              ?.map((item) => {
                if (item.dataset_list?.includes(elem.id)) {
                  updatedDatasetList.push({
                    pipeline_dataset_id: elem.pipeline_dataset_id,
                    name: elem.name,
                    id: elem.id,
                    source: elem.source,
                    type: elem.type,
                  });
                  return {
                    ...elem,
                    ...item,
                    merge_dataset: true,
                    dataset_list: updatedDatasetList,
                  };
                } else {
                  return elem;
                }
              })
              .flat();
          })
          .flat();
        // removing duplicate merged dataset from data_sources
        modelDataSource = modelDataSource.filter(
          (v, i, a) => a.findIndex((v2) => v2?.id === v?.id) === i
        );

        const newMergeDatasetList = model?.merged_data_sources
          ?.map((elem) => [...elem.dataset_list])
          .flat();

        if (model?.merged_data_sources) {
          modelDataSource = modelDataSource
            .filter((elem) => !newMergeDatasetList?.includes(elem.id))
            .filter((elem) => elem !== undefined);
        } else {
          modelDataSource = modelDataSource.filter(
            (elem) => elem !== undefined
          );
        }
        let mergedDataSource = model.data_sources
          ?.map((elem) => {
            return model?.merged_data_sources
              ?.map((item) => {
                if (item.dataset_list?.includes(elem.id)) {
                  return {
                    ...item,
                    channel: elem?.channel,
                  };
                } else {
                  return item;
                }
              })
              .flat();
          })
          .flat();

        // removing duplicate and undefined merged dataset from merged_data_sources
        mergedDataSource = mergedDataSource
          .filter((v, i, a) => a.findIndex((v2) => v2?.id === v?.id) === i)
          .filter((elem) => elem !== undefined);
        let newModel = {
          ...model,
          data_sources: modelDataSource,
          ...(mergedDataSource && { merged_data_sources: mergedDataSource }),
        };
        return newModel;
      } else {
        return model;
      }
    } else {
      return model;
    }
  });
  //this will modify the selected merged Datasources and add channels in it
  selectionModels = selectionModels.map((model) => {
    if (initialConfigData.app_id === config.hardCoded.mroiAppId) {
      return {
        ...model,
        merged_data_sources: model?.merged_data_sources?.map((dataset) => {
          let updatedDataset = { ...dataset };

          model?.data_sources?.map((dataSource) => {
            if (dataSource.id === dataset.id) {
              updatedDataset = {
                ...updatedDataset,
                channel: dataSource.channel,
              };
            }
          });

          return updatedDataset;
        }),
      };
    } else {
      return model;
    }
  });

  //this will modify the unselected merged Datasources and add channels in it
  selectionModels = selectionModels.map((model) => {
    if (initialConfigData.app_id === config.hardCoded.mroiAppId) {
      return {
        ...model,
        merged_data_sources: model?.merged_data_sources?.map((dataset) => {
          let updatedDataset = { ...dataset };

          model?.mergeChannels?.map((channelObj) => {
            if (channelObj.id === dataset.id) {
              updatedDataset = {
                ...updatedDataset,
                ...(channelObj.channel && { channel: channelObj.channel }),
              };
            }
          });
          return updatedDataset;
        }),
      };
    } else {
      return model;
    }
  });

  // create a datasource obj if mmt manual config's data_sources is empty
  selectionModels = selectionModels.map((model) => {
    if (
      selections?.config?.is_config_manual &&
      model.id === config.hardCoded.mmtId &&
      model.data_sources.length === 0 &&
      model.config.minimum_datapoint_column
    ) {
      const manualDataSourceObj = {
        id: "MANUAL_RUN",
        name: "MANUAL_RUN",
        type: "MANUAL_RUN",
        kpi_list: {
          [model.config.minimum_datapoint_column]: {
            kpi_type: config.hardCoded.kpiTypePrimary,
          },
        },
        pipeline_dataset_id: "MANUAL_RUN",
      };
      return {
        ...model,
        data_sources: [manualDataSourceObj],
      };
    } else {
      return model;
    }
  });
  //Add ID in test_group_parameters array
  // selectionModels = selectionModels.map((model) => {
  //   //if model.config includes test_group_parameters, add id for all objects
  //   if (model.config[config.hardCoded.combinationsSalesId]) {
  //     let updCombinations = model.config.test_group_parameters; // obj to array
  //     updCombinations = transformObjToArray(model.config.test_group_parameters); // obj to array
  //     updCombinations = updCombinations.map((combinationRow) => ({
  //       ...combinationRow,
  //       id: v4(),
  //     }));
  //     let updModel = {
  //       ...model,
  //       config: {
  //         ...model.config,
  //         [config.hardCoded.combinationsSalesId]: updCombinations,
  //       },
  //     };
  //     return updModel;
  //   } else {
  //     return model;
  // //   }
  // });

  // const checkDE = selectionModels?.every((model) => model?.config?.run_model);
  // if (match.params.configName && configType === "edit" && checkDE) {
  //   selectionModels = selectionModels.map((model) => {
  //     if (model.id === config.hardCoded.salesUpliftId && model.run) {
  //       return {
  //         ...model,
  //         config: {
  //           ...model.config,
  //           ...(!model?.config?.algorithm &&
  //             !model?.config?.algorithm?.length && { algorithm: "default" }),
  //         },
  //       };
  //     }
  //     return model;
  //   });
  // }
  console.log("SELECTION MODELS AFTER -> ", selectionModels);

  let configUserInputs = {
    app_id: initialConfigData.app_id,
    appName: match.params.configName
      ? selections.config.app_name
      : activeApp.name,
    configId: match.params.configName ? initialConfigData.id : null,
    configNameFixed: `${config.hardCoded.mroiAppId}-${selections.config.country}-${selections.config.brand}-`,
    configName: disableFlag
      ? selections.config.is_config_manual
        ? getManualConfigName(initialConfigData.name, selections.config?.brand)
        : getConfigName(initialConfigData.name)
      : match.params.configName
      ? selections.config.is_config_manual
        ? getManualConfigName(match.params.configName, selections.config?.brand)
        : getConfigName(match.params.configName)
      : "",
    configNameOriginal: disableFlag
      ? initialConfigData.name
      : match.params.configName
      ? match.params.configName
      : "",
    configType: configType,
    country: selections.config.country,
    brand: selections.config.brand,
    category: selections.config.category,
    historic_week_data:
      match.params?.configName && selections.config?.is_config_manual
        ? 52
        : selections.config.historic_week_data,
    global_week_date_selection: selections.config.historic_week_data
      ? config.hardCoded.historicWeek
      : match.params?.configName && selections.config?.is_config_manual
      ? config.hardCoded.historicWeek
      : config.hardCoded.dateRange,
    tags: initialConfigData?.metadata?.tags || [],
    models: selectionModels,
    categoryData: -1,
    campaignsData: [],
    metadata:
      match.params.configName || match.params.runName
        ? initialConfigData.metadata
        : { isStarred: false },
    loading: false,
    startDate: selections.config.start_date,
    endDate: selections.config.end_date,
    is_config_manual: selections.config.is_config_manual || false,
    is_schedule_config: selections.config.is_schedule_config || false,
    sub_brand_select_all: sub_brand_select_all,
    sub_segment_select_all: sub_segment_select_all,
    segment_select_all: segment_select_all,
    segment: selections.config.is_config_manual
      ? []
      : selections.config.segment,
    media_sub_brand: selections.config.media_sub_brand || [],
    media_segment: selections.config.media_segment || [],
    media_sub_segment: selections.config.media_sub_segment || [],
    media_sub_brand_select_all: media_sub_brand_select_all,
    media_sub_segment_select_all: media_sub_segment_select_all,
    media_segment_select_all: media_segment_select_all,
    datasetDetailsFlag: false,
  };

  //make configUserInputs for sub_brand and sub_segment in MROI
  if (initialConfigData.app_id === config.hardCoded.mroiAppId) {
    configUserInputs = {
      ...configUserInputs,
      sub_brand: selections.config.is_config_manual
        ? []
        : selections.config.sub_brand,
      sub_segment: selections.config.is_config_manual
        ? []
        : selections.config.sub_segment,
    };
  }
  return configUserInputs;
};

export const pickMinMaxDate = (date) => {
  if (date == null) {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    return today;
  } else {
    return date;
  }
};

export const makeDefaultResponseJson = () => ({ data: [], error: "" });

//Feb-12-2021 important
export const formatDate = (date, format = "MMM-DD-YYYY H:mm:ss") =>
  moment(date).format(format);

export const formatDateForMerged = (date, format = "MMMM DD,YYYY") =>
  moment(date).format(format);

export const formatDateForMTADataRange = (date, format = "MMM DD,YYYY") =>
  moment(date).format(format);

export const formatDateToMoment = (date, format = "YYYY-MM-DD") =>
  moment(date, "DD-MM-YYYY").format(format);

//hh:mm:ss 17:39:07
export const formatTime = (date) => moment(date).format("H:mm:ss");

export const convertMinutesIntoHrMins = (time) => {
  var d = moment.duration(time, "minutes");
  let displayString = "";
  if (d._data.hours > 1) {
    displayString += d._data.hours + " hours ";
  } else if (d._data.hours > 0) {
    displayString += d._data.hours + " hour ";
  } else {
    displayString = displayString;
  }
  if (d._data.minutes > 1) {
    displayString += d._data.minutes + " minutes ";
  } else {
    displayString += d._data.minutes + " minute ";
  }

  return displayString;
};

//Calculate Duration false-->00:06 true--> 0 hr 6 mins
export const timeDiffCalc = (start_time, end_time) => {
  const diffInDate = moment.duration(moment(start_time).diff(moment(end_time)));
  return `${diffInDate._data.hours}hr ${diffInDate._data.minutes}min`;
};

export const timeDifference = (end_time) => {
  const diffInDate = moment.duration(moment().diff(moment(end_time)));
  return diffInDate?._data?.months > 1
    ? 8
    : diffInDate._data.days + diffInDate._data.months;
};

export const getDuration = (start_time, end_time) => {
  const diffInDate = moment.duration(moment(start_time).diff(moment(end_time)));
  return `${diffInDate._data.hours}hr ${diffInDate._data.minutes}min`;
};

//February 12, 2021 5:46 PM | Duration: 0 hr 6 mins
export const pilotRunTimestamp = (created_on, start_date, end_date, format) =>
  formatDate(created_on) +
  " | " +
  "Duration: " +
  getDuration(start_date, end_date);

//MROI-Australia-Vanish-Test-20-Jan-21-2021-13:08:04
export const makeRunNameFromRun = (run) =>
  run.metadata.config_name + "-" + formatDate(run.created_on);

export const makeRunNameFromRunNew = (run) =>
  run.run_name + "-" + formatDate(run.created_on);

export const getRunIdFromRunName = (runData, runName) => {
  let runId = "";
  runData.forEach((elem) => {
    elem.runs.forEach((run) => {
      if (
        (run.run_name + "-" + formatDate(run.created_on)).toLowerCase() ==
        runName.toLowerCase()
      ) {
        runId = run.id;
      }
    });
  });
  return runId;
};

export const generateMessageNotification = (obj) => {
  const { config_name, completed_at, triggered_at, notification_type } = obj;
  let message = "";

  switch (notification_type) {
    case "run-success":
      message =
        config_name +
        " run successfully completed at " +
        formatTime(completed_at);
      break;
    case "run-failure":
      message = config_name + " run failed at " + formatTime(completed_at);
      break;
    case "run-triggered":
      message = config_name + " run triggered at " + formatTime(triggered_at);
      break;
    case "run-cancelled":
      message = config_name + " run cancelled at " + formatTime(completed_at);
      break;
    default:
      message = "No notifications";
  }

  return message;
};

export const convertDateToEpoch = (date, format = "YYYY-MM-DD") =>
  moment(date, format)._d.valueOf();

export const convertDateToFormat = (date, format = "YYYY-MM-DD") =>
  moment(date, "DDMMYYYY").format(format);

export const getWeeksForModels = (start_date, end_date) => {
  let startDate = convertDateToFormat(start_date, "DDMMYYYY");
  let endDate = convertDateToFormat(end_date, "DDMMYYYY");
  return Math.floor(getWeekCount(startDate, endDate)) > 0
    ? Math.floor(getWeekCount(startDate, endDate))
    : 0;
};

export const getStartOrEndDate = (startDate, weeks) => {
  const end = moment(startDate, "YYYY-MM-DD")
    .add(weeks === 0 ? 0 : weeks * 7 - 1, "days")
    .format("YYYY-MM-DD");
  return {
    start_date: startDate,
    end_date: end,
  };
};

export const sortDataset = (datasets, selectedDatasetsIds, orderByArray) => {
  let checked = [],
    nonChecked = [];
  datasets.forEach((dataset) => {
    selectedDatasetsIds.includes(dataset.id)
      ? checked.push(dataset)
      : nonChecked.push(dataset);
  });
  checked = orderBy(checked, orderByArray);
  nonChecked = orderBy(nonChecked, orderByArray);
  let orederedDataset = [...checked, ...nonChecked];
  return orederedDataset;
};

export const getStartEndByDayCat = (dayCat) => {
  let startDate, endDate;
  switch (dayCat) {
    case "today":
      startDate = moment().startOf("date")._d.valueOf();
      endDate = moment()._d.valueOf();
      break;
    case "yesterday":
      startDate = moment().startOf("date")._d.valueOf() - 24 * 60 * 60 * 1000;
      endDate = moment().endOf("date")._d.valueOf() - 24 * 60 * 60 * 1000;
      break;
    case "this_week":
      startDate = moment().startOf("isoWeek")._d.valueOf();
      endDate = moment()._d.valueOf();
      break;
    case "last_week":
      startDate =
        moment().startOf("isoWeek")._d.valueOf() - 7 * 24 * 60 * 60 * 1000;
      endDate =
        moment().endOf("isoWeek")._d.valueOf() - 7 * 24 * 60 * 60 * 1000;
      break;
    case "last_30_days":
      startDate =
        moment().startOf("day")._d.valueOf() - 30 * 24 * 60 * 60 * 1000;
      endDate = moment()._d.valueOf();
      break;
    case "all":
      startDate = 0; //1970
      endDate = 4099680000000; //2100
      break;
    default:
      startDate = 0;
      endDate = moment()._d.valueOf();
  }
  return { start_time: startDate, end_time: endDate };
};

export const endAfterStart = (start, end) => {
  var startDate = new Date(start);
  var endDate = new Date(end);
  return endDate.getTime() >= startDate.getTime();
};

export const toggleDatasetReducer = (state, payload) => {
  let { flag, dataset, activeModel } = payload;

  //state is data_sources
  let newState;
  //remove all data_sources with the same type
  newState = state.filter((currDataset) => {
    return currDataset.type === dataset.type ? false : true;
  });

  //add that dataset if flag is true
  if (flag)
    newState = [...newState, manipulateDataset({ dataset, activeModel })];
  return newState;
};

export const toggleKpiReducer = (state, payload) => {
  let { flag, dataset, activeModel } = payload;

  let availableDatasetType = [
    config.hardCoded.datasetSalesType,
    config.hardCoded.datasetNeilsenType,
  ];

  //state is data_sources
  let newState;
  //remove all data_sources with the same type
  newState = state.filter((currDataset) => {
    return availableDatasetType.includes(currDataset.type) ? false : true;
  });

  //add that dataset if flag is true
  if (flag)
    newState = [...newState, manipulateDataset({ dataset, activeModel })];
  return newState;
};

export const getActiveModel = (configUserInputs) =>
  configUserInputs.models.filter((model) => model.run).length
    ? configUserInputs.models.find((model) => model.run).id
    : configUserInputs.models[0].id;

export const getActiveDataset = (configUserInputs, allData) => {
  let activeModel = configUserInputs.models.filter((model) => model.run).length
    ? configUserInputs.models.find((model) => model.run)
    : configUserInputs.models[0];
  let activeDataset;
  if (activeModel.data_sources.length) {
    activeDataset = activeModel.data_sources[0];
  } else {
    let globalActiveModel = allData?.models?.find(
      (model) => model.id === activeModel.id
    );
    activeDataset = allData?.datasets?.find((dataset) =>
      globalActiveModel.dataset_ids.includes(dataset.id)
    );
  }
  let activeDatasetId = activeDataset
    ? activeDataset.id
    : allData.datasets[0]?.id;
  return activeDatasetId;
};

//getActiveDatasetIds
export function getActiveDatasetId(configUserInputs, id) {
  let activeModel = configUserInputs.models.filter((model) => model.run).length
    ? configUserInputs.models.find((model) => model.run && model.id === id)
    : configUserInputs.models[0];
  let activeDataset = activeModel.data_sources[0];
  return activeDataset?.id;
}

// Accepts the array and key
const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

export const splitDatasetsIntoTypes = (datasets, mergedDatasets) => {
  let availableDatasetTypes = [
    config.hardCoded.datasetSalesType,
    config.hardCoded.datasetNeilsenType,
    config.hardCoded.datasetMediaType,
  ];

  let newState = datasetCategories.map((datasetCat) => ({
    ...datasetCat,
    data: datasets.filter((dataset) => datasetCat.id.includes(dataset.type)),
  }));

  let notAvailableDataset = datasets.filter(
    (dataset) => !availableDatasetTypes.includes(dataset.type)
  );

  let newObj = groupBy(notAvailableDataset, "type");

  let newDatasetList = [];
  for (let key in newObj) {
    newDatasetList.push({
      id: key,
      name: key,
      ui_element_type: "checkbox",
      checkedSortFlag: false,
      data: newObj[key],
    });
  }
  const mergedDataTypeObj = {
    id: "merged",
    name: "merged",
    ui_element_type: "checkbox",
    checkedSortFlag: false,
    data: mergedDatasets,
  };
  newDatasetList.push(mergedDataTypeObj);
  newState = [...newState, ...newDatasetList];
  return newState;
};

export const modifyDriversToAddDriverName = (drivers) =>
  drivers.map((driver) => ({
    ...driver,
    name: `${driver.name} | Regions: ${driver.regions} | Sales Attribution: ${driver.pct_Contribution_to_actual}`,
  }));

export const modifyNestedDriversToAddDriverName = (drivers, regions) =>
  drivers.map((driver) => ({
    ...driver,
    name: `${driver.name} | Regions: ${
      regions.length ? regions : ["-"]
    } | Sales Attribution: ${driver.pct_Contribution_to_actual}`,
  }));

export const modifyTestControlNameToAddLabel = (data, label) =>
  data.map((elem, index) => ({
    ...elem,
    name: `${label} ${index + 1} - Regions: ${
      elem.regions.length ? elem.regions : ["-"]
    }`,
    type: label.split(" ")[0],
  }));

export const addLabelToTestControlArr = (data, label, showPosition, type) =>
  data.map((elem) => ({
    ...elem,
    type: type,
    name: showPosition
      ? `${
          type === "test" && elem.position === 0
            ? `${label} ${elem.position}/ Control Cell - Regions: ${
                elem.regions.length ? elem.regions : ["-"]
              }`
            : `${label} ${elem.position} - Regions: ${
                elem.regions.length ? elem.regions : ["-"]
              }`
        }`
      : `${label} - Regions: ${elem.regions.length ? elem.regions : ["-"]}`, //hard coded
  }));

export const makeDetailsLabelFromDetails = (details) => {
  details = details.filter((detail) => detail.id !== "");
  return details.reduce(
    (finalDetailsLabel, detail, index) =>
      (index === 0 ? " | " : "") +
      finalDetailsLabel +
      detail.name +
      (index !== details.length - 1 ? ", " : ""),
    ""
  );
};

export const modifyLevelsToAddDetails = (levels) =>
  levels.map((level) => ({
    ...level,
    name: `${level.name}${makeDetailsLabelFromDetails(level.details)}`,
  }));

export const addRunNameToRunData = (data) =>
  data.map((run) => ({ ...run, name: makeRunNameFromRun(run) }));

export const getSortedData = (data, orderField = "name", order = "asc") =>
  orderBy(data, [orderField], [order]);

// Works for Sunday to Saturday week
// If week is 2 and today's day is not sunday then start date, end date will be returned in Sunday to Saturday format
export const getWeekBasedOnHistoricWeek = (week) => {
  let currDay = moment().day();
  let startDate, endDate;
  if (currDay === 6) {
    startDate = moment()
      .subtract(week - 1, "weeks")
      .startOf("week")
      .format("YYYY-MM-DD");
    endDate = moment().endOf("week").format("YYYY-MM-DD");
  } else {
    startDate = moment()
      .subtract(week, "weeks")
      .startOf("week")
      .format("YYYY-MM-DD");
    endDate = moment().endOf("week").subtract(7, "days").format("YYYY-MM-DD");
  }
  return { min: startDate, max: endDate };
};

// Works to get end date as today and start date from provided week
// If week is 2 then start date will be current date - no of week's date and end date will be today's date
export const getDatesFromWeek = (week) => {
  let startDate = moment().subtract(week, "weeks").format("YYYY-MM-DD");
  let endDate = moment().format("YYYY-MM-DD");
  return { min: startDate, max: endDate };
};

//adds no. of day to current date
export const getFutureDate = (days) => {
  return moment().add(days, "days").format("YYYY-MM-DD");
};

//adds no. of days provided to provided date
export const getFutureDateFromGivenDate = (
  date,
  days,
  format = "YYYY-MM-DD"
) => {
  return moment(date).add(days, "days").format(format);
};

export const checkDatesInRange = (type, availableDate, selectedDate) => {
  let result = true;
  switch (type) {
    case "start_date":
      if (!moment(selectedDate).isSameOrAfter(availableDate)) result = false;
      break;
    case "end_date":
      if (!moment(selectedDate).isSameOrBefore(availableDate)) result = false;
      break;
    default:
      result = result;
  }
  return result;
};

export const checkDatesAfterGlobalDates = (availableDate, selectedDate) =>
  moment(selectedDate).isSameOrAfter(availableDate);

export const checkIsAfterDate = (start_date, end_date) => {
  return moment(end_date).isAfter(start_date);
};

export const getInBetweenDate = (week, date) => {
  let weekRange = getWeekBasedOnHistoricWeek(week);
  let res = moment(date).isBetween(weekRange.min, weekRange.max);
  return res;
};

export const getMandatoryKPIToTop = (kpi_list, mandatory_kpi) =>
  removeDupFromArray([...orderBy(mandatory_kpi), ...orderBy(kpi_list)]);

export const getDaysDiff = (start_date, end_date, pre_campaign_start_date) => {
  let pre_campaign_end_date = "";
  if (start_date && end_date && pre_campaign_start_date) {
    let daysDiff = moment(end_date, "YYYY-MM-DD").diff(
      moment(start_date, "YYYY-MM-DD"),
      "days"
    );
    pre_campaign_end_date = moment(pre_campaign_start_date)
      .add(daysDiff, "days")
      .format("YYYY-MM-DD");
  }
  return pre_campaign_end_date;
};

export const checkForAllData = (
  active_app,
  saved_config,
  global_params,
  models,
  datasets,
  runData,
  appAccessDetails,
  shared_configurations
) => {
  let flag = true;
  if (saved_config) {
    saved_config.forEach((config) => {
      if (config.app_id.toLowerCase() !== active_app?.id.toLowerCase()) {
        flag = false;
      }
    });
  } else {
    return false;
  }
  if (shared_configurations) {
    shared_configurations.forEach((config) => {
      if (config.app_id.toLowerCase() !== active_app?.id.toLowerCase()) {
        flag = false;
      }
    });
  } else {
    return false;
  }
  if (!appAccessDetails) {
    return false;
  }
  if (!global_params) {
    return false;
  }
  if (!runData) {
    return false;
  }
  if (models) {
    models.forEach((model) => {
      if (model.app_id.toLowerCase() != active_app?.id.toLowerCase()) {
        flag = false;
      }
    });
  } else {
    return false;
  }
  if (datasets) {
    datasets.forEach((dataset) => {
      if (dataset.app_id.toLowerCase() != active_app?.id.toLowerCase())
        flag = false;
    });
  } else {
    return false;
  }
  return flag;
};

export const transformTestControlDataInArray = (objTest, type) => {
  let arrTest = [];
  objTest = objTest ? objTest : {};
  Object.values(objTest).forEach((i) => {
    arrTest.push({
      id: v4(),
      type,
      data: i,
    });
  });
  return arrTest;
};

export const transformTestControlDataInObj = (arrTest, type) => {
  let objTest = {};
  arrTest.forEach((val, index) => {
    objTest = { ...objTest, [index]: val.data };
  });
  return objTest;
};

export const transformKpi = (dataset, activeDataset, activeModel) => {
  let newState = dataset
    .map((datasetCat) => {
      if (
        (datasetCat.type === config.hardCoded.datasetSalesType ||
          datasetCat.type === config.hardCoded.datasetNeilsenType) &&
        datasetCat.id === activeDataset.id
      ) {
        if (
          !config.hardCoded.autoMlIds.some(
            (elem) => elem.toLowerCase() === activeModel.toLowerCase()
          ) &&
          !config.hardCoded.mmlIds.some(
            (elem) => elem.toLowerCase() === activeModel.toLowerCase()
          )
        ) {
          return {
            id: v4(),
            type: "radio",
            kpi_list: activeDataset.kpi_list,
            mandatory_kpi: activeDataset.mandatory_kpi_list,
            default_value: activeDataset.default_value,
            kpi_variables_list: activeDataset.kpi_variables_list,
          };
        } else {
          if (
            datasetCat.type === config.hardCoded.datasetSalesType &&
            datasetCat.id === activeDataset.id
          ) {
            return {
              id: v4(),
              type: "radio",
              kpi_list: activeDataset.kpi_list,
              mandatory_kpi: activeDataset.mandatory_kpi_list,
              default_value: activeDataset.default_value,
              kpi_variables_list: activeDataset.kpi_variables_list,
            };
          } else if (
            datasetCat.type === config.hardCoded.datasetNeilsenType &&
            datasetCat.id === activeDataset.id
          ) {
            return {
              id: v4(),
              type: "multi",
              kpi_list: activeDataset.kpi_list,
              mandatory_kpi: activeDataset.mandatory_kpi_list,
              default_value: activeDataset.default_value,
              kpi_variables_list: activeDataset.kpi_variables_list,
            };
          }
        }
      } else if (
        datasetCat.type === config.hardCoded.datasetMediaType &&
        datasetCat.id === activeDataset.id
      ) {
        return {
          id: v4(),
          type: "multi",
          kpi_list: activeDataset.kpi_list,
          mandatory_kpi: activeDataset.mandatory_kpi_list,
          default_value: activeDataset.default_value,
          kpi_variables_list: activeDataset.kpi_variables_list,
        };
      } else if (datasetCat.type && datasetCat.id === activeDataset.id) {
        return {
          id: v4(),
          type: "multi",
          kpi_list: activeDataset.kpi_list,
          mandatory_kpi: activeDataset.mandatory_kpi_list,
          default_value: activeDataset.default_value,
          kpi_variables_list: activeDataset.kpi_variables_list,
        };
      }
    })
    .filter((item) => item);

  return newState;
};

export const convertLevelsToNestedFieldsOld = (levelsData, level) => {
  let nested_fields_old = [];
  levelsData.forEach((levelData) => {
    levelData.details.forEach((detail) => {
      if (levelData.id == level) nested_fields_old.push(detail.id);
    });
  });
  return nested_fields_old;
};

//filter drivers dropdown data based on previous level selected
export const filterNestedDrivers = (driverData, levelsData, levelId) => {
  let levelSelected = levelsData?.find((level) => "" + level.id === levelId);
  let driversToBeFiltered = levelSelected?.details
    ?.map((detail) => detail.id)
    .filter((item) => item !== "");
  let driverDataFiltered = driverData?.filter(
    (driver) => !driversToBeFiltered?.includes(driver.id)
  );
  return driverDataFiltered || driverData;
};

const makeNestedRunRegionsUniform = (nestedRunRegionObject, key) => {
  //This function converts:
  //INPUT:
  // nestedRunRegions = {
  //   national: {
  //     nested_field_parameters: {
  //       level_name: "",
  //       nested_fields: [],
  //     },
  //   },
  // };
  //OUTPUT
  // nestedRunRegions = {
  //   national: {
  //     0: {
  //       nested_field_parameters: {
  //         level_name: "",
  //         nested_fields: [],
  //       },
  //     },
  //   },
  // };
  switch (key) {
    case "national":
      nestedRunRegionObject = {
        0: { ...nestedRunRegionObject, regions: ["ALL"] },
      };
      return nestedRunRegionObject;
    case "test":
      return nestedRunRegionObject;
    case "control":
      return nestedRunRegionObject;
  }
};

//unwrapper all the required fields so that UI can work
export const transformNestedRunRegionsToUiStructure = (nestedRunRegions) => {
  //If model.config.nested_run_regions is undefined, it means either it's an old config
  //So, default it as an empty array which is the reset version for UI structure
  // console.log("nestedRunRegions", nestedRunRegions);
  let nestedRunRegionsUi = [];
  if (nestedRunRegions) {
    //SAMPLE DATA STRUCTURES
    // //National Sample Structure
    // nestedRunRegions = {
    //   national: {
    //     nested_field_parameters: {
    //       level_name: "",
    //       nested_fields: [],
    //     },
    //   },
    // };
    // //Regional Sample Structure
    // nestedRunRegions = {
    //   control: {
    //     0: {
    //       nested_field_parameters: {
    //         level_name: "0",
    //         nested_fields: ["Others"],
    //       },
    //       regions: ["YORKSHIRE", "CENTRAL"],
    //     },
    //   },
    //   test: {
    //     0: {
    //       nested_field_parameters: {
    //         level_name: "0",
    //         nested_fields: ["Others"],
    //       },
    //       regions: ["EAST OF ENGLAND", "NORTH EAST"],
    //     },
    //     1: {
    //       nested_field_parameters: {
    //         level_name: "0",
    //         nested_fields: ["Others"],
    //       },
    //       regions: ["SOUTH & SOUTH EAST", "LONDON"],
    //     },
    //   },
    // };
    //Convert national object same as test/control and add regions as ["ALL"] for same component usage

    // console.log(
    //   "Object.entries(nestedRunRegions)",
    //   Object.entries(nestedRunRegions)
    // );
    Object.entries(nestedRunRegions).forEach(([key, nestedRunRegionObject]) => {
      // console.log("key", key);
      // console.log("nestedRunRegionObject", nestedRunRegionObject);
      let nestedRunRegionObjectUniform = makeNestedRunRegionsUniform(
        nestedRunRegionObject,
        key
      );
      // console.log("nestedRunRegionObjectUniform", nestedRunRegionObjectUniform);
      //Check if the object has any entries
      //Proceed only when it has any keys
      //Catering to below case:
      //test: {} OR control: {}
      // console.log(
      //   "Object.entries(nestedRunRegionObjectUniform)",
      //   Object.entries(nestedRunRegionObjectUniform)
      // );
      if (
        nestedRunRegionObjectUniform &&
        !(Object.entries(nestedRunRegionObjectUniform).length === 0)
      ) {
        let allKeysNestedRunRegionObjectUniform = Object.keys(
          nestedRunRegionObjectUniform
        );
        // console.log(
        //   "allKeysNestedRunRegionObjectUniform",
        //   allKeysNestedRunRegionObjectUniform
        // );
        Object.entries(nestedRunRegionObjectUniform).map(
          ([cellIndex, currItem]) => {
            // console.log("currItem", currItem);
            //Current Object Nested Fields Params
            let currItemNFParams = currItem.nested_field_parameters;
            //If nested_field_parameters exist, only then add the row
            //BugFix: AddNewConfig failing citing 'nested_fields' of undefined
            if (currItemNFParams) {
              let currItemNFLength = currItemNFParams.nested_fields.length;
              let newRow = {
                id: v4(),
                type: key,
                regions: currItem.regions,
                level_name: currItemNFParams.level_name || "",
                nested_fields: currItemNFLength
                  ? currItemNFParams.nested_fields[currItemNFLength - 1]
                  : "",
                nested_fields_old: currItemNFParams.nested_fields.slice(
                  0,
                  currItemNFLength - 1
                ),
              };
              // console.log("newRow", newRow);
              nestedRunRegionsUi.push(newRow);
            }
          }
        );
      }
    });
  }
  //Filter all rows where level_name === "" or nested_fields is an empty array
  //Because they are invalid rows
  //TO DO
  nestedRunRegionsUi = nestedRunRegionsUi.filter(
    (row) => !(row.level_name === "" || row.nested_fields === "")
  );
  // console.log("nestedRunRegionsUi", nestedRunRegionsUi);
  return nestedRunRegionsUi;
};

//wrap all the required fields so that config can work
export const transformNestedRunRegionsToFirestoreStructure = (
  nestedRunRegions
) => {
  let data;
  //Identify is selectedPilot run is national or regional
  //One way is to store that information in configUserInputs.model<AUTOMAL>.config
  //Other way is to check the configUserInputs.model<AUTOMAL>.config.nested_run_regions
  //We will go with the 2nd way:
  //If nestedRunRegions has length 1 and it's regions === ["ALL"], it is national, else regional
  let nestedRunType =
    nestedRunRegions.length === 1 &&
    arrSameMembers(nestedRunRegions[0].regions, ["ALL"])
      ? "national"
      : "regional";

  switch (nestedRunType) {
    case "national":
      let nestedRunRegionsNationalObject = nestedRunRegions[0];
      data = {
        national: {
          nested_field_parameters: {
            level_name: nestedRunRegionsNationalObject.level_name,
            nested_fields:
              nestedRunRegionsNationalObject.nested_fields !== ""
                ? [
                    ...nestedRunRegionsNationalObject.nested_fields_old,
                    nestedRunRegionsNationalObject.nested_fields,
                  ]
                : [],
          },
        },
      };
      break;
    case "regional":
      data = {
        test: Array.isArray(nestedRunRegions)
          ? transformArrayToObjBasedOnPosition(
              nestedRunRegions
                ?.filter((elem) => elem?.type.toLowerCase() === "test")
                .map((elem) => {
                  return {
                    regions: elem.regions,
                    position: elem.position,
                    nested_field_parameters: {
                      level_name: "" + elem.level_name,
                      nested_fields:
                        elem.nested_fields !== ""
                          ? [...elem.nested_fields_old, elem.nested_fields]
                          : [],
                    },
                  };
                })
            )
          : {},
        // control: Array.isArray(nestedRunRegions)
        //   ? transformArrayToObjBasedOnPosition(
        //       nestedRunRegions
        //         ?.filter((elem) => elem?.type.toLowerCase() === "control")
        //         .map((elem) => {
        //           return {
        //             regions: elem.regions,
        //             position: elem.position,
        //             nested_field_parameters: {
        //               level_name: "" + elem.level_name,
        //               nested_fields:
        //                 elem.nested_fields !== ""
        //                   ? [...elem.nested_fields_old, elem.nested_fields]
        //                   : [],
        //             },
        //           };
        //         })
        //     )
        //   : {},
      };
      break;
    default:
      console.log("ERROR -> Unhandled case");
      console.log("nestedRunRegions", nestedRunRegions);
  }
  return data;
};

export const FilterRegionsData = (globalData, filterData) => {
  return globalData.filter((o1) =>
    filterData.some((o2) => o1.regions.join(",") !== o2.regions.join(","))
  );
};

export const isEqualArray = (arrayVal, otherVal) => {
  return isEqual(arrayVal, otherVal);
};

export const isEqualObject = (objVal, otherVal) => {
  return isEqual(objVal, otherVal);
};

export const differenceInArray = (globalArr, otherVal) => {
  const ids = otherVal.map((o) => o.regions.join(","));
  const filtered = globalArr.filter(
    ({ regions }) => !ids.includes(regions.join(","))
  );
  return filtered;
};

export const filterRegionsBasedOnTestControlData = (
  allRegions,
  testControlData
) => {
  const allSelectedRegions = testControlData.map(
    (row) => row.type + "_" + row.position
  );
  const filteredRegions = allRegions.filter(({ type, position }) => {
    return !allSelectedRegions.includes(type + "_" + position);
  });
  return filteredRegions;
};

const arrContainsAll = (arr1, arr2) =>
  arr2?.every((arr2Item) => arr1?.includes(arr2Item));

export const arrSameMembers = (arr1, arr2) =>
  arrContainsAll(arr1, arr2) && arrContainsAll(arr2, arr1);

export const getValidNewRowFromRegionAndLevelDriver = (
  selectedRegionObject,
  levelDriver
) => {
  const levelObjectForSelectedRegion = levelDriver.find((row) =>
    arrSameMembers(row.regions, selectedRegionObject.regions)
  );
  const allLevelsForSelectedRegion = modifyLevelsToAddDetails(
    levelObjectForSelectedRegion.levels
      .map((levelRow) => {
        //Level 0 will always have an empty array
        //Should have been empty array of array from backend
        //Discuss with backend team if that's the right solution
        //To avoid this if else on UI
        if (levelRow.details.length) {
          return levelRow.details.map((detailRow) => ({
            id: v4(),
            levelId: levelRow.id.toString(),
            name: levelRow.name,
            drivers: levelRow.drivers,
            details: detailRow,
            allDriversList: detailRow.map((row) => row.id),
          }));
        } else {
          return [
            {
              id: v4(),
              levelId: levelRow.id.toString(),
              name: levelRow.name,
              drivers: levelRow.drivers,
              details: [],
              allDriversList: [].map((row) => row.id),
            },
          ];
        }
      })
      .flat()
  );
  //Reset to first level and it's first driver
  const firstLevelObject = allLevelsForSelectedRegion[0];
  const validLevelName = firstLevelObject.levelId;
  const allAvailableDriverIdsForSelectedLevel = firstLevelObject.drivers.map(
    (row) => row.id
  );
  const validNestedFields = allAvailableDriverIdsForSelectedLevel[0];
  const validNestedFieldsOld = firstLevelObject.details.map((row) => row.id);
  const newRow = {
    id: v4(),
    name: selectedRegionObject.name,
    level_name: validLevelName,
    nested_fields: validNestedFields,
    nested_fields_old: validNestedFieldsOld,
    regions: selectedRegionObject.regions,
    type: selectedRegionObject.type,
    position: selectedRegionObject.position,
  };
  return newRow;
};

export const addRegionNamePositionToTestControlData = (
  testControlData,
  regionsData
) =>
  testControlData.map((testControlRow) => {
    const currRegionObject = regionsData.find((row) =>
      arrSameMembers(row.regions, testControlRow.regions)
    );
    return {
      ...testControlRow,
      name: currRegionObject.name,
      position: currRegionObject.position,
    };
  });

const convertStringRegionsToArr = (regions) => regions?.[0]?.split(", ") || [];
export const modifyLevelDriverData = (levelDriverDataObj) => {
  const finalObj = {};
  for (const [key, levelDriverData] of Object.entries(levelDriverDataObj)) {
    finalObj[key] = levelDriverData.map((row) => ({
      ...row,
      regions: convertStringRegionsToArr(row.regions),
    }));
  }
  return finalObj;
};

export const getAllValidCombinationsForLevelDriverData = (levelDriver) => {
  let allValidCombinations = [];

  //For each row in regions data
  levelDriver.forEach((regionRow) => {
    const allLevels = modifyLevelsToAddDetails(
      regionRow.levels
        .map((levelRow) => {
          //Level 0 will always have an empty array
          //Should have been empty array of array from backend
          //Discuss with backend team if that's the right solution
          //To avoid this if else on UI
          if (levelRow.details.length) {
            return levelRow.details.map((detailRow) => ({
              id: "",
              levelId: levelRow.id.toString(),
              name: levelRow.name,
              drivers: levelRow.drivers,
              details: detailRow,
              allDriversList: detailRow.map((row) => row.id),
            }));
          } else {
            return [
              {
                id: "",
                levelId: levelRow.id.toString(),
                name: levelRow.name,
                drivers: levelRow.drivers,
                details: [],
                allDriversList: [].map((row) => row.id),
              },
            ];
          }
        })
        .flat()
    );
    allLevels.forEach((levelRow) => {
      const nested_fields_old = levelRow.details.map(
        (detailRow) => detailRow.id
      );
      const availableDrivers = levelRow.drivers.filter(
        (driverRow) => !nested_fields_old.includes(driverRow.id)
      );
      availableDrivers.forEach((availableDriverRow) => {
        const validRow = {
          regions: regionRow.regions,
          level_name: levelRow.levelId,
          nested_fields: [...nested_fields_old, availableDriverRow.id],
        };
        allValidCombinations.push(validRow);
      });
    });
  });

  return allValidCombinations;
};

export const isNestedRunRegionsValid = (
  allValidCombinations,
  nestedRunRegions
) => {
  const isNestedRunRowValid = (allValidCombinations, rowSelection) => {
    for (let validRow of allValidCombinations) {
      const isRowValid = isEqual(validRow, rowSelection);
      //If even one is a match, row is valid
      if (isRowValid) return true;
    }
    return false;
  };
  //nested json change when the nesteddriver api call given []
  if (nestedRunRegions?.length >= 1) {
    for (let row of nestedRunRegions) {
      const rowSelection = {
        regions: row.regions,
        level_name: row.level_name,
        nested_fields: [...row.nested_fields_old, row.nested_fields],
      };
      const isRowValid = isNestedRunRowValid(
        allValidCombinations,
        rowSelection
      );
      //If even one is non-match, selections are invalid
      if (!isRowValid) return false;
    }
  }
  return true;
};

export const splitToChunks = (array, parts) => {
  let mainArr = [],
    tempArr = [];
  for (let i = 0; i < parts; i++) {
    tempArr = [];
    for (let j = i; j < array.length; j = j + parts) {
      tempArr.push(array[j]);
    }
    mainArr.push({ id: i, data: tempArr });
  }
  return mainArr;
};

export const checkForAccess = (allData = {}, key, accessKey = "write") => {
  // check for specific access else will return true/false
  if (accessKey) {
    return allData[key]?.hasOwnProperty(accessKey);
  } else {
    return allData[key];
  }
};

export const findNameForShareEmail = (usersArray, name) => {
  let userName = "";
  if (usersArray && usersArray.length && name) {
    userName = usersArray.find((item) => item.preferred_username === name).name;
  }
  return userName;
};

export const convertDateForDataSetFormat = (dateString) => {
  let date = dateString.substring(0, 2);
  let month = dateString.substring(2, 4);
  let year = dateString.substring(4);
  let monthString = moment(month, "MM").format("MMMM");
  return monthString + " " + date + ", " + year;
};
export const numberOfWeeks = (datasetDates) => {
  return moment().diff(moment(datasetDates, "DDMMYYYY"), "weeks");
};
export const getInitialsFromEmail = (nameString, separator) => {
  const emailName = nameString.split(separator);
  const fullName = emailName[0].split(".");
  const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  return initials.toUpperCase();
};

export const getInitialsFromEmailFromGlobalUsers = (nameString, separator) => {
  const emailName = nameString.split(separator);
  const fullName = emailName[0].split(".");
  let initials = "--";
  if (fullName && fullName.length) {
    initials = fullName?.shift()?.charAt(0) + fullName?.pop()?.charAt(0);
  }
  return initials.toUpperCase();
};

export const getInitials = (nameString, separator) => {
  const fullName = nameString.split(separator);
  const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  return initials.toUpperCase();
};

export const checkConfigurationCountryAndBrand = (allData, obj) => {
  const { globalParams = [] } = allData || {};
  const manualAccess =
    allData?.appAccessDetails?.feature_list?.manual_configuration;
  const countryInfo = globalParams.find(
    (a) => a.id === "country_brand_segment_hierarchy"
  );

  obj.data = obj.data.map((configObj) => {
    const { selections: { config } = {} } = configObj || {};
    const { country, brand, is_config_manual } = config || {};

    if (configObj?.selections?.config?.is_config_manual && !manualAccess) {
      configObj.openRecordError =
        "Permission denied to access manual cofiguration";
    }

    if (!countryInfo) {
      configObj.openRecordError =
        "Permission denied to access this country (" + country + ")";
    } else {
      const countryObj = (countryInfo.data || []).find(
        (a) => a.name === country && a.labelCat === "country"
      );
      if (!countryObj) {
        configObj.openRecordError =
          "Permission denied to access this country (" + country + ")";
      } else {
        const brandObj = (countryObj.children || []).find(
          (a) => a.name === brand && a.labelCat === "brand"
        );
        if (
          !brandObj
          // && brand
        ) {
          configObj.openRecordError =
            "Permission denied to access this brand (" + brand + ")";
        }
      }
    }
    return configObj;
  });
  return obj;
};

//download file from given path
export const downloadFile = (path, filename) => {
  // Create a new link
  const anchor = document.createElement("a");
  anchor.href = path;
  anchor.download = filename;
  anchor.target = "_blank";

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
};

export const getTriggerTime = () => {
  const maxTime = 23;
  const timeArray = [];
  let time = "";
  for (let index = 0; index <= maxTime; index++) {
    if (index < 10) {
      time = `0${index}`;
    } else {
      time = `${index}`;
    }
    timeArray.push(`${time}:00`);
    for (let min = 15; min <= 45; min += 15) {
      timeArray.push(`${time}:${min}`);
    }
  }
  return timeArray;
};

// current year's end date i.e 31 Dec 20XX
export const getEndDayOfYear = (format = "YYYY-MM-DD") =>
  moment().endOf("year").format(format);

// get current Hour + 1's epoch in H:mm format
export const getCurrentEpochHour = (timeZone) => {
  let getAvailableHour = moment().tz(timeZone).format("H:mm");
  getAvailableHour = getAvailableHour.split(":");
  let getMinutes = +getAvailableHour[1];
  let getHour = +getAvailableHour[0];
  if (getMinutes < 15) {
    getMinutes = "15";
  } else if (getMinutes > 15 && getMinutes < 30) {
    getMinutes = "30";
  } else if (getMinutes < 45 && getMinutes > 30) {
    getMinutes = "45";
  } else if (getMinutes < 60 && getMinutes > 45) {
    getMinutes = "00";
    getHour += 1;
  }
  if (getHour < 10) {
    return `0${getHour}:${getMinutes}`;
  } else {
    return `${getHour}:${getMinutes}`;
  }
};

export const getTodayDate = (timeZone, format = "YYYY-MM-DD") => {
  return moment().tz(timeZone).format(format);
};

export const getPastHrInfo = (date, timeZone = "Asia/Chita") => {
  var dateObject = moment.tz(timeZone).toObject();
  const currentEpochHour = new Date(
    dateObject.years,
    dateObject.months,
    dateObject.date,
    dateObject.hours,
    dateObject.minutes,
    dateObject.minutes,
    dateObject.milliseconds
  ).getTime();
  const getEpochHourFromDate = moment(date, "DD/MM/YYYY H:mm")
    .tz(timeZone)
    .valueOf();
  return moment(currentEpochHour).isBefore(moment(getEpochHourFromDate));
};

export const epochFromTimezone = (timeZone) =>
  moment().tz(timeZone).format("H:mm");

// While stiching between models check SKU data and selections are valid or not
export const checkForValidSKUDatasetParameters = (data, selections) => {
  const validData = data.filter((elem) =>
    selections.some((record) =>
      record?.sku?.some((obj) => obj?.sku === elem?.sku)
    )
  );
  return validData;
};

// Transformation for KPI variables_list (object -> array)
export const transformKpiVariableList = (data) => {
  let result = [];
  if (data) {
    result = Object.entries(data).map((elem) => ({
      id: elem[0],
      kpi_parameters: Object.entries(elem[1]).map((item) => ({
        id: item[0],
        possible_values: item[1].possible_values,
        default: item[1].default,
      })),
    }));
  }
  return result;
};

export const copyValueToClipboard = (value) => {
  const el = document.createElement("input");
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

//sorting data based the files name
export const sortCallBackFunc = (x, y) =>
  x.name.toLowerCase() < y.name.toLowerCase()
    ? -1
    : x.name.toLowerCase() > y.name.toLowerCase()
    ? 1
    : 0;
//arranged the folder structure and sort the folder and files

export function arrangeFolderAndFiles(filteredData) {
  let folder = filteredData.filter((e) => e.data).sort(sortCallBackFunc);
  let files = filteredData.filter((e) => !e.data).sort(sortCallBackFunc);
  return [...folder, ...files];
}
export function intersect(first = [], ...rest) {
  rest = rest.map((array) => new Set(array));
  return first.filter((e) => rest.every((set) => set.has(e)));
}
