//Import required libraries
import React from "react";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

//Import Custom Component

//Import Data
import { config } from "../../../config/config";
import noDataImage from "../../../../assets/images/undraw_empty.svg";

//Import styles
import "../../../screens/AIDEApp/AddNewConfig/components/ModelSelections.scss";
import "./GroupsInfo.scss";

const StyledCheckbox = withStyles(() => ({
  root: {
    color: "#4eafb3 !important",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function GroupsInfo(props) {
  const { featureList, featureData, setFeatureData } = props;

  const addFeatureToList = (key, newVal) => {
    const updatedFeatureData = featureData.map((elem) =>
      elem.id === key ? { ...elem, value: newVal } : { ...elem }
    );
    setFeatureData(updatedFeatureData);
  };

  const displayCountriesList = () => {
    const list = featureData;
    return (
      <div>
        {list?.map((item) => (
          <div className="data-container" key={item.id}>
            <div className="group-row">
              <StyledCheckbox
                checked={item.value}
                onChange={() => addFeatureToList(item.id, !item.value)}
              />
              <label>{item.name}</label>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const className = `users-selection-content ${
    !featureList?.data?.length ? "no-data" : ""
  }`;

  return (
    <div className={className}>
      {featureList?.status === "success" && featureList?.data?.length === 0 && (
        <div className="no-data-container">
          <img src={noDataImage} />
          {config.messages.noDatasets}
        </div>
      )}
      {featureList?.status === "success" && (
        <div className="users-selections-column" style={{ width: "100%" }}>
          <div className="users-parameter-div">
            <p className="users-parameter-text">Feature Access</p>
          </div>
          <div className="groups-info-container">{displayCountriesList()}</div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  featureList: state.adminSetting.featureList,
});

export default connect(mapStateToProps)(GroupsInfo);
