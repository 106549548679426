//Import required libraies
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data

const StyledCheckbox = withStyles(() => ({
  root: {
    color: "#4eafb3 !important",
    paddingLeft: "0px",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function CustomCheckbox(props) {
  let { id, name, value, onChange } = props;

  return (
    <div>
      <StyledCheckbox
        disableRipple
        checked={value}
        onChange={(e) => {
          onChange(id, !value);
        }}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      <label className="bold">{name}</label>
    </div>
  );
}

export default CustomCheckbox;
