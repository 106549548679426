//Import required libraies
import React, { useState } from "react";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data
import { config } from "../../../../config/config";

const BlueRadio = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: "#4eafb3",
    "&$checked": {
      color: "#4eafb3",
    },
    "&$disabled": {
      color: "#4eafb3",
      opacity: 0.5,
    },
    "& .MuiSvgIcon-root": {
      height: 15,
      weight: 15,
    },
  },
  checked: {},
  disabled: {
    color: "#4eafb3",
    opacity: 0.5,
  },
})((props) => <Radio color="default" {...props} />);

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
  },
});

function KpiDetails(props) {
  let {
    value,
    onChange,
    row,
    updateConfig,
    selRegionGranularity,
    regionGranularityValue,
    activeModel,
  } = props;
  const initialData = value instanceof Array ? value : [value];
  const [kpiDetail, setKpiDetail] = useState(initialData);
  const [error, setError] = useState({ key: "", isError: false, id: "" });

  const updateKpiDetail = (kpi, newKey, value) => {
    let maxVal = config.hardCoded.adStockMaxLimit;
    let minVal = config.hardCoded.adStockMinLimit;
    if (
      (parseFloat(value) < parseFloat(minVal) ||
        parseFloat(value) > parseFloat(maxVal) ||
        isNaN(value) ||
        value === "") &&
      (newKey === "sl_limit" || newKey === "su_limit")
    ) {
      setError({ key: newKey, isError: true, id: kpi });
    } else {
      setError({ key: "", isError: false, id: "" });
    }

    let resultObject = {};
    let newKpiDetail = Object.entries(kpiDetail[0]).map(([key, entry]) => {
      {
        return key === kpi
          ? { [kpi]: { ...entry, [newKey]: value } }
          : { [key]: entry };
      }
    });
    newKpiDetail.forEach((elem) => {
      Object.entries(elem).map(([key, value]) => {
        resultObject[key] = value;
      });
    });
    setKpiDetail([resultObject]);
    if (selRegionGranularity?.toLowerCase() === "national") {
      updateConfig("region_granularity", {
        ...regionGranularityValue,
        ...(config.hardCoded.autoMlIds.includes(activeModel.id)
          ? {
              test_regions: {
                ...regionGranularityValue.test_regions,
                files: { ...row?.files, kpi_details: [resultObject] },
              },
            }
          : config.hardCoded.rbtAndSuIds.includes(activeModel.id)
          ? {
              test_dark_regions: {
                ...regionGranularityValue.test_dark_regions,
                files: { ...row?.files, kpi_details: [resultObject] },
              },
            }
          : {
              test_control_regions: {
                ...regionGranularityValue.test_control_regions,
                files: { ...row?.files, kpi_details: [resultObject] },
              },
            }),
        // test_control_regions: {
        //   ...regionGranularityValue.test_control_regions,
        //   files: { ...row?.files, kpi_details: [resultObject] },
        // },
      });
    } else onChange("files", { ...row?.files, kpi_details: [resultObject] });
  };

  const data = [
    { id: "false", name: "False" },
    { id: "true", name: "True" },
  ];

  return (
    <div className="kpi-details-container">
      {Object.entries(kpiDetail[0]).map(([key, value]) => {
        const kpiId = key;
        const sl_limit = value?.sl_limit;
        const su_limit = value?.su_limit;
        const factor = value?.factor;
        const limit = value?.limit;
        const saturation_flag = value?.saturation_flag;
        return (
          <div className="kpi-detail-row" key={kpiId}>
            <div className="kpi-name">
              <label title={"KPI"}>KPI</label>
              <input
                type="text"
                value={kpiId}
                disabled
                className="input-label"
              />
            </div>
            <div className="sl-limit-info">
              <label title={"SL_Limit"}>SL_Limit</label>
              <input
                type="number"
                onWheel={() => document.activeElement.blur()}
                value={isNaN(sl_limit) ? "" : sl_limit}
                min={0}
                max={6}
                step={0.1}
                onChange={(e) => {
                  updateKpiDetail(
                    kpiId,
                    "sl_limit",
                    parseFloat(e.target.value)
                  );
                }}
                className="input-label"
              />
              <span>
                <label className="input-info">
                  Min: {config.hardCoded.adStockMinLimit} |{" "}
                </label>
                <label className="input-info">
                  Max: {config.hardCoded.adStockMaxLimit}
                </label>
              </span>
            </div>
            <div className="su-limit-info">
              <label title={"SU_Limit"}>SU_Limit</label>
              <input
                type="number"
                onWheel={() => document.activeElement.blur()}
                value={isNaN(su_limit) ? "" : su_limit}
                min={0}
                max={6}
                step={1}
                onChange={(e) => {
                  updateKpiDetail(
                    kpiId,
                    "su_limit",
                    parseFloat(e.target.value)
                  );
                }}
                className="input-label"
              />
              <span>
                <label className="input-info">
                  Min: {config.hardCoded.adStockMinLimit} |{" "}
                </label>
                <label className="input-info">
                  Max: {config.hardCoded.adStockMaxLimit}
                </label>
              </span>
            </div>
            <div className="factor-info">
              <label title={"Factor"}>Factor</label>
              <input
                type="number"
                onWheel={() => document.activeElement.blur()}
                value={isNaN(factor) ? "" : factor}
                onChange={(e) => {
                  updateKpiDetail(kpiId, "factor", parseFloat(e.target.value));
                }}
                className="input-label"
              />
            </div>
            <div className="limit-info">
              <label title={"Limit"}>Limit</label>
              <input
                type="number"
                onWheel={() => document.activeElement.blur()}
                value={isNaN(limit) ? "" : limit}
                onChange={(e) => {
                  updateKpiDetail(kpiId, "limit", parseFloat(e.target.value));
                }}
                className="input-label"
              />
            </div>
            <div className="saturation-flag-info">
              <label title={"Saturation Flag"}>Saturation Flag</label>
              <div className="radio-container">
                {data.map((obj) => {
                  return (
                    <div className="radio-icon" key={obj.id}>
                      <ThemeProvider theme={theme}>
                        <BlueRadio
                          value={obj.id}
                          checked={
                            obj.id.toLowerCase() ===
                            saturation_flag.toLowerCase()
                          }
                          onChange={(e) => {
                            updateKpiDetail(
                              kpiId,
                              "saturation_flag",
                              e.target.value
                            );
                          }}
                          name={"saturation_flag"}
                          size="small"
                          disableRipple
                        />
                      </ThemeProvider>
                      <label className="radio-label">{obj.name}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      {error.isError && (
        <p className="error">
          {config.inputNumberValidationError +
            " for KPI: " +
            error.id +
            ", " +
            error.key}
        </p>
      )}
    </div>
  );
}

export default KpiDetails;
