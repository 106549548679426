// Import required libraies
import React, { useState } from "react";
// import { Popover } from "../uiTheme";
import { Popover } from "../../uiTheme";

import { makeStyles } from "@material-ui/core/styles";

// Import custom components
import AppRow from "../AppRow/AppRow";

//Import utils/data
import AllAppsData from "../../../assets/data/AllApps.json";

// Import action creators

// Import config
import { config } from "../../config/config";

//Import styles

function AllApps(props) {
  let { match } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="all-apps-container">
      <i
        className="material-icons-outlined header-menu-icon"
        onClick={handleClick}
      >
        apps
      </i>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <AppRow
          match={match}
          data={AllAppsData}
          popOverFlag={false}
          allAppsFlag={true}
          customClassName="all-apps"
        />
      </Popover>
    </div>
  );
}

export default AllApps;
