//Import required libraies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Import custom components
import Loader from "../../../../components/Loader/Loader";
import LocalParameterItem from "./LocalParameterItem";

//Import utils
import { config } from "../../../../config/config";
import {
  makeDefaultResponseJson,
  addRunNameToRunData,
  getStartEndByDayCat,
  removeAllInData,
  salesMediaFilterPayload,
} from "../../../../utils/utils";
import dayCat from "../../../../../assets/data/dayCat.json";

//Import actions
import { updateUserInfo, updateAlertInfo } from "../../../../redux/actions";

//Import styles
import "./BudgetOnly.scss";

const initialTargetData = {
  runs: { status: "loading", message: "", data: [] },
};

let initialSelections = {
  rbt_run_id: "",
  rbt_run_type: "budget_only",
  config_id: "",
  dayCat: "all",
};

function BudgetOnly(props) {
  let {
    match,
    onChange,
    userInputs,
    updateUserInfo,
    updateAlertInfo,
    disableFlag,
    allData,
  } = props;
  let initialSelectionsFromConfigUserInputs = userInputs.models.find(
    (model) => model.id === config.hardCoded.regBudId
  );
  const [loading, setLoading] = useState(true);
  const [budgetData, setBudgetData] = useState(initialTargetData);
  const [selections, setSelections] = useState({
    ...initialSelections,
    ...initialSelectionsFromConfigUserInputs.config,
    dayCat: initialSelections.dayCat,
    configId: userInputs.configId,
  });

  //Defining required variables
  const runIdFinal = budgetData.runs.data.length
    ? selections["rbt_run_id"]
    : config.messages.noRuns;
  const runsDataFinal = budgetData.runs.data.length
    ? budgetData.runs.data
    : [
        {
          id: config.messages.noRuns,
          name: config.messages.noRuns,
        },
      ];
  const errorFlagFinal = budgetData.runs.status === "error";
  const errorMessageFinal =
    budgetData.runs.status === "error" ? budgetData.runs.message : "";

  //updates user selections
  const onChangeCurr = (key, value) => {
    setSelections({ ...selections, [key]: value });
    onChange(key, value);
  };

  //updated data coming from api
  const updateTargetData = (key, value) => {
    setBudgetData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  //Changing loading to false when component mounts
  useEffect(() => {
    setLoading(false);
  }, []);

  //Fetch runs data
  useEffect(() => {
    if (userInputs.configType === "add") {
      let newRunData = {
        status: "success",
        message: "",
        data: addRunNameToRunData([]),
      };
      updateTargetData("runs", newRunData);
    } else {
      const { segment } = salesMediaFilterPayload(userInputs, allData);
      const url = config.api.runUrl;
      const body = {
        app_id: userInputs.app_id,
        country: userInputs.country,
        brand: userInputs.brand.toUpperCase(),
        // segment:
        //   userInputs.segment.toString() === "ALL" ? null : userInputs.segment,
        // segment: removeAllInData(userInputs.segment).length
        //   ? removeAllInData(userInputs.segment)
        //   : null,
        segment:
          userInputs.segment_select_all || userInputs.is_config_manual
            ? null
            : segment,
        search_text: selections.configId,
        offset: 0,
        limit: 100,
        order_by: "created_on",
        category: ["Success"],
        models: [config.hardCoded.regBudId],
        run_type_key: config.hardCoded.rbtRunKey, //key to get initial run details
        run_type_value: config.hardCoded.rbtRunId, //key to get pilot run details
        ...getStartEndByDayCat(disableFlag ? "" : selections.dayCat),
        is_shared: true,
      };
      let status;
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            let newRunData = {
              status: "success",
              message: "",
              data: addRunNameToRunData(json.data),
            };
            updateTargetData("runs", newRunData);
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
            updateUserInfo({
              error: json.error,
              errorCode: json.errorCode,
            });
            let newRunData = {
              status: "error",
              message: json.error,
              data: [],
            };
            updateTargetData("runs", newRunData);
          }
        });
    }
    return () => {
      updateTargetData("runs", { status: "loading", message: "", data: [] });
    };
    // } else {
    //   updateTargetData("runs", { status: "success", message: "", data: [] });
    // }
  }, [selections.dayCat]);

  //Update rbt_run_id if runs changes
  useEffect(() => {
    if (budgetData.runs.status === "success") {
      let rbt_run_id = selections.rbt_run_id;
      let isBudgetRunIdValid = true;
      if (budgetData.runs.data.length) {
        let runIds = budgetData.runs.data.map((run) => run.id);
        if (!runIds.includes(selections.rbt_run_id)) {
          rbt_run_id = budgetData.runs.data[0].id;
          isBudgetRunIdValid = false;
        }
      } else {
        rbt_run_id = "";
        isBudgetRunIdValid = false;
      }
      if (!isBudgetRunIdValid) {
        onChangeCurr("rbt_run_id", rbt_run_id);
      }
    }
  }, [budgetData.runs]);

  return (
    <div className="budget-runs-conatiner">
      {budgetData.runs.status === "loading" && <Loader />}

      {budgetData.runs.status === "success" && (
        <div className="configs-container">
          <LocalParameterItem
            ui_element_type="dropdown"
            value={selections["dayCat"]}
            onChange={onChangeCurr}
            enable={true}
            show={true}
            name="Select Runs For"
            id="dayCat"
            data={dayCat}
            userInputs={userInputs}
          />
          <LocalParameterItem
            ui_element_type="dropdown"
            value={runIdFinal}
            onChange={onChangeCurr}
            enable={budgetData.runs.data.length ? true : false}
            show={true}
            name="Select Regression Budget run"
            id="rbt_run_id"
            data={runsDataFinal}
            userInputs={userInputs}
          />
        </div>
      )}
      {errorFlagFinal && <p className="info-message">{errorMessageFinal}</p>}
    </div>
  );
}

BudgetOnly.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
});

const mapDispatchToProps = { updateUserInfo, updateAlertInfo };

export default connect(mapStateToProps, mapDispatchToProps)(BudgetOnly);
