//Import required libraies
import React, { useState } from "react";
import Radio from "@material-ui/core/Radio";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles
import "./RadioButtons.scss";

const BlueRadio = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: "#4eafb3",
    "&$checked": {
      color: "#4eafb3",
    },
    "&$disabled": {
      color: "#4eafb3",
      opacity: 0.5,
    },
    "& .MuiSvgIcon-root": {
      height: 15,
      weight: 15,
    },
  },
  checked: {},
  disabled: {
    color: "#4eafb3",
    opacity: 0.5,
  },
})((props) => <Radio color="default" {...props} />);

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
  },
});

function RadioButtons(props) {
  let {
    data,
    name,
    id,
    customClassName,
    value,
    onChange,
    enable: parentEnable,
  } = props;

  return (
    <div
      className={`input-item row ${
        customClassName ? customClassName : ""
      } ${id}`}
    >
      <label>{name}:</label>
      <div className="radio-container">
        {data.map((obj) => {
          const disableChild = obj?.disabled ? obj.disabled : false,
            disabledParent = !parentEnable;
          const disabledRadio = disableChild || disabledParent;
          return (
            <div className="radio-icon" key={obj.id}>
              <ThemeProvider theme={theme}>
                <BlueRadio
                  value={obj.id}
                  checked={
                    typeof value === "string"
                      ? obj.id.toLowerCase() === value?.toLowerCase()
                      : false
                  }
                  onChange={(e) => {
                    onChange(id, e.target.value);
                  }}
                  name={id}
                  size="small"
                  disabled={disabledRadio}
                  disableRipple
                />
              </ThemeProvider>
              <label className="radio-label">{obj.name}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RadioButtons;
