// Import required libraries
import React from "react";

// Import data
import { config } from "../../config/config";

// Import images
import failed_run from "../../../assets/images/failed_run.svg";
import successful_run from "../../../assets/images/successful_run.svg";
import queued_run from "../../../assets/images/currently_running.svg";
import cancel_run from "../../../assets/images/cancel_run.svg";

//Import styles
import "./Message.scss";

function Message(props) {
  let { status, dagMessage } = props;

  const statusImageMapping = {
    [config.hardCoded.runFailed]: failed_run,
    [config.hardCoded.runQueued]: queued_run,
    [config.hardCoded.runRunning]: queued_run,
    [config.hardCoded.runSuccess]: successful_run,
    [config.hardCoded.runCancel]: cancel_run,
    [config.hardCoded.runSchedule]: queued_run,
  };

  const statusMessageMapping = {
    [config.hardCoded.runFailed]: config.messages.runFailed,
    [config.hardCoded.runQueued]: config.messages.runQueued,
    [config.hardCoded.runRunning]: config.messages.runRunning,
    [config.hardCoded.runSuccess]: config.messages.runSuccess,
    [config.hardCoded.runCancel]: config.messages.runCancel,
    [config.hardCoded.runSchedule]: config.messages.runRunning,
  };

  return (
    <div className="message-container">
      <img src={statusImageMapping[status]} />
      <span className="message-text">
        <b style={{ textAlign: "center" }}>
          {dagMessage
            ? status === "Running"
              ? `${statusMessageMapping[status]} /n ${dagMessage}`
                  .split("/n")
                  .map((e) => <b style={{ display: "block" }}>{e}</b>)
              : dagMessage
            : statusMessageMapping[status]}
        </b>
      </span>
      {status === "Success" && (
        <span className="success-info-message">
          {config.messages.runSuccessInfo}
        </span>
      )}
    </div>
  );
}

export default Message;
