//Import required libraies
import React, { useEffect, useState } from "react";
import { DatePicker } from "../../../../uiTheme";

//Import Custom Component

// Import custom hooks

// Import action creators

// Import styles

// Import utils & data
import { config } from "../../../../config/config";
import { getWeekBasedOnHistoricWeek } from "../../../../utils/utils";

function CalendarPickerValidation(props) {
  let { id, name, onChange, userInputs, customClassName, enable, value } =
    props;
  const [error, setError] = useState(false);
  let historicWeekDate = { min: userInputs.startDate, max: userInputs.endDate };

  if (userInputs.is_config_manual) {
    historicWeekDate = { min: "", max: "" };
  } else {
    if (userInputs.global_week_date_selection === config.hardCoded.historicWeek)
      historicWeekDate = getWeekBasedOnHistoricWeek(
        userInputs.historic_week_data
      );
    if (userInputs.global_week_date_selection === config.hardCoded.dateRange) {
      historicWeekDate = { min: userInputs.startDate, max: userInputs.endDate };
    }

    if (value === "null") {
      value = historicWeekDate.max;
    }
  }

  return (
    <div
      className={`input-item ${customClassName ? customClassName : ""} ${id}`}
    >
      <label title={name}>{name}:</label>
      {/* <input
        type="date"
        min={historicWeekDate.min}
        disabled={!enable}
        max={historicWeekDate.max}
        value={value}
        onKeyDown={(e) => e.preventDefault()}
        onChange={(e) => {
          onChange(id, e.target.value);
        }}
      /> */}
      <DatePicker
        min={historicWeekDate.min}
        disabled={!enable}
        max={historicWeekDate.max}
        value={value}
        onKeyDown={(e) => e.preventDefault()}
        onChange={(e) => {
          onChange(id, e.target.value);
        }}
      />
      {error && <p className="error">{config.inputNumberValidationError}</p>}
    </div>
  );
}

export default CalendarPickerValidation;
