//Import required libraies
import React, { useEffect, useMemo, useRef } from "react";
import useState from "react-usestateref";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import { MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";

//Import Custom Component
import ModelConfig from "./ModelConfig";
import Dataset from "./Dataset";
import Loader from "../../../../components/Loader/Loader";
import LocalParameterItem from "./LocalParameterItem";
import Message from "../../../../components/Message/Message";

// Import custom hooks
import { useNonInitialEffect } from "../../../../hooks/useNonInitialEffect";

// Import action creators
import {
  updateAlertInfo,
  updateConfigUserInputs,
  updateUserInfo,
  toggleModel,
  updateSelections,
  updateCategory,
  updateDatasetOnCountryChange,
  updateCategoryDataset,
  updateModelLocalParameters,
  updateDatasetLocalParameters,
  addKpi,
  addToggleKpi,
  onChangeCategory,
  filterDataset,
  resetMergedDataset,
  filterMergeDatasetForWeeks,
  updateWholeConfigUserInputs,
  filterSku,
  filterKpi,
  filterMergeDataset,
  updatePeriodicity,
  updateModelCampaignData,
  updateData,
  updateSalesMediaRegions,
} from "../../../../redux/actions";

// Import styles
import "./ModelSelections.scss";

// Import utils
import { config } from "../../../../config/config";
import {
  sortDataset,
  getActiveModel,
  getActiveDataset,
  convertMinutesIntoHrMins,
  getDatasetId,
  getRegionsData,
  filterSalesDatasetRegions,
  getDatesFromWeek,
  checkDatesOverlapping,
  transformKpiVariableList,
  makeConfigUserInputs,
  copyValueToClipboard,
  splitDatasetsIntoTypes,
  removeAllInData,
  salesMediaFilterPayload,
  datasetIds,
} from "../../../../utils/utils";
import PowerBiReportForRun from "../../../../components/PowerBiReportForRun/PowerBiReportForRun";
import moment from "moment";
import { Button } from "react-bootstrap";
import Modal from "../../../../components/Modal/Modal";
import { CssTextField } from "../../../../../styles/styles";
import { Badge } from "@mui/material";
import RequestValidation from "./RequestValidation";

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "115px",
    height: "35px",
    borderRadius: "3px",
    color: "#46596a",
    backgroundColor: "white",
    marginLeft: "20px",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    color: "#46596a",
    width: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "#FFF",
    },
  },
  outlined: {
    backgroundColor: "#46596a",
    color: "white",
    marginRight: 10,
    fontSize: 14,
    padding: 10,
    height: 35,
    minWidth: 15,
    width: "15%",
    textTransform: "capitalize",
    float: "right",
    fontFamily: "Hind Siliguri",
    borderRadius: "20px",
    border: "1px solid #46596a",
    "&:hover": {
      backgroundColor: "#e9f7fc",
      color: "#46596a",
      boxShadow: "none",
    },
  },
  outlinedButton: {
    // height: "35px",
    // padding: "4px",
    fontFamily: "Hind Siliguri",
    backgroundColor: "#e9f7fc",
    border: "1px solid #46596a",
    "&:hover": {
      border: "1px solid #4EAFB3",
    },
  },
  filledButton: {
    cursor: "pointer",
    // height: "35px",
    // padding: "4px",
    fontFamily: "Hind Siliguri",
    color: "#e9f7fc",
    backgroundColor: "#46596a",
    // border: "1px solid #46596a",
    "&:hover": {
      border: "1px solid #e9f7fc",
    },
  },
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      border: "1px solid #dedede",
      background: "white",
      maxWidth: "250px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Hind Siliguri",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Hind Siliguri",
      fontSize: 12,
      color: "#46596a",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    "& .MuiInputBase-root": {
      height: "100%",
      display: "flex",
      alignItems: "start",
    },
  },
  formControl: {
    minWidth: 120,
  },
  bellIconStyle: {
    "& .MuiBadge-dot": {
      backgroundColor: "#F10000 !important",
      // top: 3,
      // right: 6,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      // top: 3,
      // right: 6,
    },
  },
  root: {
    padding: 0,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function ModelSelections(props) {
  let {
    match,
    user,
    allData,
    configUserInputs,
    updateUserInfo,
    updateCategory,
    toggleModel,
    updateConfigUserInputs,
    filterSku,
    loading,
    disableFlag,
    updateSelections,
    updateAlertInfo,
    updateDatasetOnCountryChange,
    updateCategoryDataset,
    addKpi,
    updateModelLocalParameters,
    updateDatasetLocalParameters,
    setActiveSelection,
    activeSelection,
    updateCheckDE,
    filterDataset,
    resetMergedDataset,
    filterMergeDatasetForWeeks,
    changeFlag,
    updateWholeConfigUserInputs,
    filterKpi,
    filterMergeDataset,
    updatePeriodicity,
    updateModelCampaignData,
    updateData,
    updateSalesMediaRegions,
  } = props;

  const classes = useStyles();

  const [activeDatasetId, setActiveDatasetId] = useState(
    getActiveDataset(configUserInputs, allData)
  );
  let [activeModelId, setActiveModelId] = useState(
    getActiveModel(configUserInputs)
  );
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [inputDownloadLoading, setInputDownloadLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [didLoad, setDidLoad] = useState(false);
  const [datasetsLoading, setDatasetsLoading] = useState(false);
  let [expanded, setExpanded] = useState(false); //powerbi report
  // copy url
  const [copyUrl, setCopyUrl] = useState("");
  const [pilot_start_date, set_pilot_start_date] = useState("");
  const fetchSalesMediaRegions = (url, body, controller, key, modelId) => {
    let status;
    if (key === "validMediaRegions") {
      updateConfigUserInputs("mediaRegions", "loading");
    } else {
      updateConfigUserInputs("salesRegions", "loading");
    }
    fetch(url, {
      method: "POST", //!need to be changed to POST
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      //!need to uncommented
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("RESPONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.groupEnd();
        if (status === 200) {
          if (key === "validMediaRegions") {
            updateSalesMediaRegions({ regionKey: key, modelId, json });
            updateConfigUserInputs("mediaRegions", "success");
          } else {
            // updateConfigUserInputs(key, json);
            updateSalesMediaRegions({ regionKey: key, modelId, json });
            updateConfigUserInputs("salesRegions", "success");
          }
        } else {
          if (key === "validMediaRegions") {
            updateConfigUserInputs("mediaRegions", "failed");
          } else {
            updateConfigUserInputs("salesRegions", "failed");
          }
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };
  let app = config.hardCoded.mroiAppId.toLowerCase();
  let activeApp = allData.apps.data
    ? allData.apps.data.find(
        (obj) => obj.name.toLowerCase() === app.toLowerCase()
      )
    : null;
  const [modalComponent, setModalComponent] = useState({
    component: null,
    openModal: false,
  });
  const handleModalDialogClose = () => {
    setModalComponent((prevState) => {
      return { ...prevState, openModal: false };
    });
  };
  const handleModalDialogOpen = (component) => {
    setModalComponent((prevState) => {
      return {
        ...prevState,
        openModal: true,
        component: component,
      };
    });
  };
  let activeModel = allData?.models?.find(
    (model) => model.id === activeModelId
  );
  let modelDataSource = configUserInputs.models.find(
    (model) => model.id === activeModelId
  )?.data_sources;
  const initialDatasets = allData?.datasets
    .filter((dataset) => dataset.countries.includes(configUserInputs.country))
    .filter((activeDatasetnew) =>
      activeModel?.dataset_ids?.some(
        (datasetOfActiveModel) =>
          datasetOfActiveModel.id === activeDatasetnew.id
      )
    );
  let [datasets, setDatasets] = useState(initialDatasets);

  useEffect(() => {
    setActiveModelId(getActiveModel(configUserInputs));
  }, [
    configUserInputs.is_config_manual,
    configUserInputs.is_schedule_config,
    updateCheckDE,
  ]);

  const [runLoading, setRunLoading, runLoadingRef] = useState([]);
  const setStarredValues = (runId) => {
    setRunLoading((prevValue) => {
      return [...prevValue, runId];
    });
  };

  const removeStarredValues = (runId) => {
    let sttValues = [...runLoadingRef.current];
    sttValues = sttValues.filter((item) => item !== runId); // remove
    setRunLoading([...sttValues]);
  };

  let activeDataset = datasets.find(
    (dataset) => dataset.id === activeDatasetId
  );

  let activeModelsDatasetFromModelsData = activeModel?.dataset_ids?.map(
    (elem) => ({
      ...elem,
      kpi_variables_list: transformKpiVariableList(elem.kpi_variables_list),
    })
  );

  let activeDatasetDates;
  if (configUserInputs?.datasetDetailsData?.status === "success") {
    // let datasetsDatesArray = configUserInputs.datasetDetailsData.data.map(
    //   (elem) => ({ ...elem, dataset_new_id: getDatasetId(elem.dataset_id) })
    // );
    activeDatasetDates = configUserInputs.datasetDetailsData.data?.find(
      (dataset) => dataset.dataset_id === activeDatasetId
    );
  }

  let selectedModel = configUserInputs.models.find(
    (model) => model.id === activeModelId
  );

  let selectedDatasetsIds = selectedModel?.data_sources.filter((el) =>
    configUserInputs?.datasetDetailsData?.data.find(
      (e) => e.dataset_id === el.id
    )
  );
  selectedDatasetsIds = selectedDatasetsIds.map((dataset) => dataset.id);

  let selectedModelDatasets = selectedModel?.data_sources.filter((el) =>
    configUserInputs?.datasetDetailsData?.data.find(
      (e) => e.dataset_id === el.id
    )
  );
  let selectedDataset = selectedModel?.data_sources.find(
    (dataset) => dataset?.id === activeDatasetId
  );
  let userSelectedModelsIds = configUserInputs.models
    .filter((model) => model.run)
    .map((model) => model.id);
  let filteredModels = disableFlag
    ? allData.models?.filter((model) =>
        userSelectedModelsIds.includes(model.id)
      )
    : allData.models;

  const datasetDisabled = configUserInputs?.is_config_manual ? "disabled" : "";

  let onChange = (key, value) => {
    let data = {
      modelId: activeModelId,
      key,
      value,
    };
    updateModelLocalParameters(data);
  };

  let modelMediaDataset = useMemo(() => {
    return selectedModelDatasets?.reduce((acc, curr) => {
      if (curr.type === "MEDIA") {
        acc.push(curr.id);
      }
      return acc;
    }, []);
  }, [selectedModelDatasets.length, activeModelId]);

  useEffect(() => {
    setPageLoading(false);
  }, []);

  useEffect(() => {
    if (!didLoad) {
      const index = configUserInputs.models.findIndex(
        (model) => model.id === activeModelId
      );
      if (
        index !== -1 &&
        configUserInputs.models[index].config.upload_audience
      ) {
        setDidLoad(true);
        updateConfigUserInputs("models", configUserInputs.models);
      }
    }
  }, [didLoad, configUserInputs.models, activeModelId]);

  //Make the first selected dataset active if model changes
  useEffect(() => {
    if (!pageLoading) {
      let activeDataset;
      if (selectedModelDatasets.length) {
        activeDataset = selectedModelDatasets[0];
      }
      if (!activeDataset) {
        if (datasets.length) {
          const filterDataset =
            activeModelId === config.hardCoded.salesUpliftId
              ? datasets.filter((e) => e.type !== "MEDIA")
              : datasets;
          setActiveDatasetId(
            sortDataset(filterDataset, selectedDatasetsIds, ["name"])[0]?.id
          );
        } else {
          setActiveDatasetId(null);
        }
      } else {
        setActiveDatasetId(activeDataset.id);
      }
    }
  }, [activeModelId, datasets]);

  //Change active dataset to the dataset selected by the user
  useEffect(() => {
    if (!pageLoading) {
      let datasetsIds = datasets.map((dataset) => dataset.id);
      if (datasetsIds.includes(changeFlag.datasetAddedId)) {
        setActiveDatasetId(changeFlag.datasetAddedId);
      }
    }
  }, [changeFlag.datasetAddedId]);

  // Update campaigns data if user changes country,brand, datasets, activeModel
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    if (
      (modelMediaDataset.length ||
        activeDataset?.type === config.hardCoded.datasetMediaType) &&
      configUserInputs?.datasetDetailsData?.status === "success" &&
      !disableFlag &&
      activeModelId === config.hardCoded.autoMlId &&
      selectedModel.config.pilot_start_date &&
      selectedModel.config.pilot_end_date
    ) {
      updateConfigUserInputs("campaignsData", { data: [], status: "loading" });
      const { media_segment, media_sub_brand, media_sub_segment } =
        salesMediaFilterPayload(configUserInputs, allData);
      let body = {
        dataset_ids: [
          ...(modelMediaDataset?.includes(activeDatasetId) ||
          activeDataset?.type !== config.hardCoded.datasetMediaType
            ? [...modelMediaDataset]
            : [...modelMediaDataset, activeDatasetId] || activeDataset.id),
        ],
        country: configUserInputs.country,
        app_id: activeApp?.id,
        brand: configUserInputs.brand,
        media_segment: media_segment,
        media_sub_brand: media_sub_brand,
        media_sub_segment: media_sub_segment,
        pilot_start_date: selectedModel.config.pilot_start_date,
        pilot_end_date: selectedModel.config.pilot_end_date,
      };
      let url = config.api.campaignsUrl;
      let status;
      fetch(url, {
        signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateConfigUserInputs("campaignsData", {
              data: json.data,
              status: json.statusMessage,
            });
            if (modelMediaDataset.length) {
              updateModelCampaignData();
            }
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
            updateConfigUserInputs("campaignsData", {
              data: [],
              status: "failed",
            });
          }
        })
        .catch((e) => {
          console.warn(`Fetch 2 error: ${e.message}`);
        });
    }
    return () => {
      controller.abort();
    };
  }, [
    configUserInputs.country,
    configUserInputs.brand,
    configUserInputs.segment,
    configUserInputs.sub_segment,
    configUserInputs.sub_brand,
    configUserInputs?.datasetDetailsData,
    activeDatasetId,
    activeModelId,
    selectedModel.config.pilot_start_date,
    selectedModel.config.pilot_end_date,
  ]);

  //Update sku data if user changes country, brand, segment, sub_brand, sub_segment, sales dataset
  useEffect(() => {
    if (!disableFlag) {
      const controller = new AbortController();
      const { signal } = controller;
      if (activeDataset?.type === config.hardCoded.datasetSalesType) {
        updateConfigUserInputs("skuData", {
          status: "loading",
          message: "",
          data: [],
        });
        const {
          sub_brand: sales_sub_brand,
          segment: sales_segment,
          sub_segment: sales_sub_segment,
        } = salesMediaFilterPayload(configUserInputs, allData);
        let body = {
          dataset_id: activeDatasetId,
          brand: configUserInputs.brand,
          country: configUserInputs.country,
          app_id: activeApp?.id,
          sub_brand: sales_sub_brand,
          // removeAllInData(configUserInputs.sub_brand)
          segment: sales_segment,
          // removeAllInData(configUserInputs.segment),
          sub_segment: sales_sub_segment,
          // removeAllInData(configUserInputs.sub_segment),
        };
        let url = config.api.skuDetailsUrl;
        let status;
        fetch(url, {
          signal,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              updateConfigUserInputs("skuData", {
                status: "success",
                data: [{ sku: "ALL" }, ...json.data],
                message: "",
              });
              filterSku({
                value: [...json.data],
                modelId: activeModelId,
                activeDatasetForKpi: activeDatasetId,
              });
            } else {
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "success",
              });
            }
          })
          .catch((e) => {
            console.warn(`Fetch 2 error: ${e.message}`);
          });
      }
      return () => {
        controller.abort();
      };
    }
  }, [
    configUserInputs.country,
    configUserInputs.brand,
    configUserInputs.segment,
    configUserInputs.sub_brand,
    configUserInputs.sub_segment,
    activeDatasetId,
    // activeModelId,
  ]);

  // update sku data if there is sales or category dataset selected and user changes the sub-brand, segment, sub-segment
  useEffect(() => {
    if (!disableFlag) {
      const controller = new AbortController();
      const { signal } = controller;
      const selectedDatasetForSku = selectedModel.data_sources.find((dataset) =>
        config.hardCoded.radioDatasetList.includes(dataset.type)
      );
      if (selectedDatasetForSku) {
        updateConfigUserInputs("skuData", {
          status: "loading",
          message: "",
          data: [],
        });
        const {
          sub_brand: sales_sub_brand,
          segment: sales_segment,
          sub_segment: sales_sub_segment,
        } = salesMediaFilterPayload(configUserInputs, allData);
        let body = {
          dataset_id: activeDatasetId,
          brand: configUserInputs.brand,
          country: configUserInputs.country,
          app_id: activeApp?.id,
          sub_brand: sales_sub_brand,
          // removeAllInData(configUserInputs.sub_brand)
          segment: sales_segment,
          // removeAllInData(configUserInputs.segment),
          sub_segment: sales_sub_segment,
          // removeAllInData(configUserInputs.sub_segment),
          category: selectedDataset?.category
            ? [...selectedDataset?.category]
            : [],
        };
        let url = config.api.skuDetailsUrl;
        let status;
        fetch(url, {
          signal,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              updateConfigUserInputs("skuData", {
                status: "success",
                data: [{ sku: "ALL" }, ...json.data],
                message: "",
              });
              filterSku({
                value: [...json.data],
                modelId: activeModelId,
                activeDatasetForKpi: activeDatasetId,
              });
            } else {
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "success",
              });
            }
          })
          .catch((e) => {
            console.warn(`Fetch 2 error: ${e.message}`);
          });
      }
      return () => {
        controller.abort();
      };
    }
  }, [
    configUserInputs.sub_brand,
    configUserInputs.segment,
    configUserInputs.sub_segment,
    activeModelId,
  ]);

  //Update sku data if user changes country, brand, segment, sub_brand, sub_segment, category dataset
  useEffect(() => {
    if (!disableFlag) {
      const controller = new AbortController();
      const { signal } = controller;
      if (
        activeDataset?.type === config.hardCoded.datasetNeilsenType &&
        configUserInputs.categoryData !== -1
      ) {
        if (selectedDataset?.category) {
          updateConfigUserInputs("skuData", {
            status: "loading",
            message: "",
            data: [],
          });
          const {
            sub_brand: sales_sub_brand,
            segment: sales_segment,
            sub_segment: sales_sub_segment,
          } = salesMediaFilterPayload(configUserInputs, allData);
          let body = {
            dataset_id: activeDatasetId,
            brand: configUserInputs.brand,
            country: configUserInputs.country,
            app_id: activeApp?.id,
            sub_brand: sales_sub_brand,
            // removeAllInData(configUserInputs.sub_brand)
            segment: sales_segment,
            // removeAllInData(configUserInputs.segment),
            sub_segment: sales_sub_segment,
            // removeAllInData(configUserInputs.sub_segment),
            category: selectedDataset?.category
              ? [...selectedDataset?.category]
              : [],
          };
          let url = config.api.skuDetailsUrl;
          let status;
          fetch(url, {
            signal,
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          })
            .then((response) => {
              console.groupCollapsed("requesting", url);
              console.log("REPSONSE -> ", response);
              status = response.status;
              return response.clone().json();
            })
            .then((json) => {
              console.log("JSON -> ", json);
              console.groupEnd();
              if (status === 200) {
                updateConfigUserInputs("skuData", {
                  status: "success",
                  data: [{ sku: "ALL" }, ...json.data],
                  message: "",
                });
                filterSku({
                  value: [...json.data],
                  modelId: activeModelId,
                  activeDatasetForKpi: activeDatasetId,
                });
              } else {
                updateAlertInfo({
                  open: true,
                  message: json.statusMessage,
                  severity: "success",
                });
              }
            })
            .catch((e) => {
              console.warn(`Fetch 2 error: ${e.message}`);
            });
        } else {
          updateConfigUserInputs("skuData", {
            status: "success",
            message: "",
            data: [],
          });
        }
      }
      return () => {
        controller.abort();
      };
    }
  }, [selectedDataset?.category, configUserInputs?.categoryData]);

  //Update categories data if user changes country, brand, segment, sub-segment,sub-brand
  // this will also filter the selected kpi by comparing it with category available kpi s
  useEffect(() => {
    if (
      !loading &&
      !pageLoading &&
      !disableFlag &&
      allData.salesMediaFilterFlag
    ) {
      const controller = new AbortController();
      const { signal } = controller;
      const selectedDatasetForKpi = selectedModel.data_sources.find(
        (dataset) => dataset.type === config.hardCoded.datasetNeilsenType
      );
      if (activeDataset?.type === config.hardCoded.datasetNeilsenType) {
        const datasetId =
          selectedDatasetForKpi?.id === activeDatasetId
            ? selectedDatasetForKpi.id
            : activeDatasetId;
        updateConfigUserInputs("categoryData", -1);
        const {
          sub_brand: sales_sub_brand,
          segment: sales_segment,
          sub_segment: sales_sub_segment,
        } = salesMediaFilterPayload(configUserInputs, allData);
        let body = {
          dataset_id: datasetId,
          brand: configUserInputs.brand,
          country: configUserInputs.country,
          sub_brand: sales_sub_brand,
          // removeAllInData(configUserInputs.sub_brand),
          segment: sales_segment,
          // removeAllInData(configUserInputs.segment),
          sub_segment: sales_sub_segment,
          // removeAllInData(configUserInputs.sub_segment),
          app_id: activeApp?.id,
        };
        let url = config.api.categoryUrl;
        let status;
        fetch(url, {
          signal,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              //If the response has categories data, update userInputs
              updateConfigUserInputs("categoryData", json.data);
              filterKpi({
                categoryData: json.data,
                modelId: activeModelId,
                activeDatasetForKpi: activeDataset.id,
              });
            } else if (status === 400) {
              //If the response has duplicate categories data
              updateConfigUserInputs("categoryData", [
                {
                  id: status,
                  name: config.messages.noCategories,
                  displayText: json.statusMessage,
                },
              ]);
            } else {
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "error",
              });
            }
          })
          .catch((e) => {
            console.warn(`Fetch 2 error: ${e.message}`);
          });
      }
      return () => {
        controller.abort();
      };
    }
  }, [
    configUserInputs.brand,
    configUserInputs.sub_brand,
    configUserInputs.segment,
    configUserInputs.sub_segment,
    activeDatasetId,
    changeFlag.categoryDataset,
    pageLoading,
    activeModelId,
    allData.salesMediaFilterFlag,
  ]);

  // !Adding extra category call for nielsen type dataset
  useEffect(() => {
    if (
      !loading &&
      !pageLoading &&
      !disableFlag &&
      allData.salesMediaFilterFlag
    ) {
      const controller = new AbortController();
      const { signal } = controller;
      const nielsenDatasetSelected = configUserInputs.models
        .find(
          (model) =>
            model.run &&
            model.data_sources.find(
              (dataset) => dataset.type === config.hardCoded.datasetNeilsenType
            )
        )
        ?.data_sources.find(
          (dataset) => dataset.type === config.hardCoded.datasetNeilsenType
        );
      // const selectedDatasetForKpi = selectedModel.data_sources.find(
      //   (dataset) => dataset.type === config.hardCoded.datasetNeilsenType
      // );
      if (configUserInputs?.categoryData === -1 && nielsenDatasetSelected) {
        const datasetId = nielsenDatasetSelected.id;
        updateConfigUserInputs("categoryData", -1);
        const {
          sub_brand: sales_sub_brand,
          segment: sales_segment,
          sub_segment: sales_sub_segment,
        } = salesMediaFilterPayload(configUserInputs, allData);
        let body = {
          dataset_id: datasetId,
          brand: configUserInputs.brand,
          country: configUserInputs.country,
          sub_brand: sales_sub_brand,
          segment: sales_segment,
          sub_segment: sales_sub_segment,
          app_id: activeApp?.id,
        };
        let url = config.api.categoryUrl;
        let status;
        fetch(url, {
          signal,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              //If the response has categories data, update userInputs
              updateConfigUserInputs("categoryData", json.data);
              filterKpi({
                categoryData: json.data,
                modelId: activeModelId,
                activeDatasetForKpi: activeDataset.id,
              });
            } else if (status === 400) {
              //If the response has duplicate categories data
              updateConfigUserInputs("categoryData", [
                {
                  id: status,
                  name: config.messages.noCategories,
                  displayText: json.statusMessage,
                },
              ]);
            } else {
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "error",
              });
            }
          })
          .catch((e) => {
            console.warn(`Fetch 2 error: ${e.message}`);
          });
      }
      return () => {
        controller.abort();
      };
    }
  }, [pageLoading, allData.salesMediaFilterFlag, selectedModel.run]);
  //Update start date, end date, periodicity of a dataset
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (
      !loading &&
      !pageLoading &&
      configUserInputs?.brand &&
      (allData.salesMediaFilterFlag || configUserInputs.is_config_manual)
      // && !configUserInputs.is_config_manual
    ) {
      updateConfigUserInputs("datasetDetailsData", {
        status: "loading",
        message: "",
        data: [],
      });
      let body = {
        brand: configUserInputs.brand,
        country: configUserInputs.country,
        app_id: activeApp?.id,
      };
      body = {
        ...body,
        ...salesMediaFilterPayload(configUserInputs, allData),
      };
      let url = config.api.datasetDetailsUrl;
      let status;
      fetch(url, {
        signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            updateConfigUserInputs("datasetDetailsData", {
              status: "success",
              message: "",
              data: json.data,
            });
            updatePeriodicity();
            if (
              configUserInputs.app_id === config.hardCoded.mroiAppId &&
              configUserInputs.configType !== "run"
            ) {
              filterMergeDataset();
            }
          } else {
            updateAlertInfo({
              open: true,
              message: json.statusMessage,
              severity: "error",
            });
            updateConfigUserInputs("datasetDetailsData", {
              status: "error",
              message: "",
              data: [],
            });
          }
        })
        .catch((e) => {
          console.warn(`Fetch 2 error: ${e.message}`);
        });
    }
    return () => {
      controller.abort();
    };
  }, [
    configUserInputs.country,
    configUserInputs.brand,
    configUserInputs.sub_brand,
    configUserInputs.segment,
    configUserInputs.sub_segment,
    pageLoading,
    configUserInputs.is_schedule_config,
    updateCheckDE,
    configUserInputs?.is_config_manual,
    configUserInputs.media_sub_brand,
    configUserInputs.media_segment,
    configUserInputs.media_sub_segment,
    allData.salesMediaFilterFlag,
  ]);

  useEffect(() => {
    if (configUserInputs?.configType !== "run") {
      setDatasetsLoading(true);
      if (
        configUserInputs.global_week_date_selection ===
        config.hardCoded.historicWeek
      ) {
        if (configUserInputs.historic_week_data > 0) {
          setDatasetsLoading(true);
          let dateRange = getDatesFromWeek(configUserInputs.historic_week_data);
          let startDateSelected = dateRange?.min;
          let endDateSelected = dateRange?.max;
          let allDatasetsDetailsData = allData.datasetDetails;
          let filteredDatasets = allData.datasets.filter((dataset) =>
            dataset.countries.includes(configUserInputs.country)
          );
          let datasetsNew = filteredDatasets.filter((dataset) =>
            activeModel?.dataset_ids?.some(
              (datasetOfActiveModel) => datasetOfActiveModel.id === dataset.id
            )
          );
          let datasetsApplied = datasetsNew.filter((dataset) =>
            allDatasetsDetailsData?.some((datasetOfActiveModel) => {
              if (
                datasetOfActiveModel.dataset_id === dataset.id &&
                datasetOfActiveModel.country_name ===
                  configUserInputs.country &&
                (datasetOfActiveModel.brand_name === configUserInputs.brand ||
                  !datasetOfActiveModel.brand_name)
              ) {
                if (!configUserInputs.is_config_manual) {
                  if (
                    datasetOfActiveModel?.start_date &&
                    datasetOfActiveModel.end_date
                  ) {
                    let checkForValidDataset = checkDatesOverlapping(
                      startDateSelected,
                      endDateSelected,
                      datasetOfActiveModel.start_date,
                      datasetOfActiveModel.end_date
                    );
                    const { type, status } = checkForValidDataset;
                    if (type || status) {
                      return true;
                    }
                    return false;
                  } else {
                    return true;
                  }
                } else {
                  return true;
                }
              }
              return false;
            })
          );
          setDatasets(datasetsApplied);
          setDatasetsLoading(false);
        } else {
          setDatasetsLoading(true);
          setDatasets([]);
          setDatasetsLoading(false);
        }
      } else {
        if (
          !configUserInputs.startDate ||
          !configUserInputs.endDate ||
          moment(configUserInputs.endDate, "YYYY-MM-DD").isBefore(
            moment(configUserInputs.startDate, "YYYY-MM-DD")
          )
        ) {
          setDatasetsLoading(true);
          setDatasets([]);
          setDatasetsLoading(false);
        } else {
          setDatasetsLoading(true);
          let allDatasetsDetailsData = allData.datasetDetails;
          let filteredDatasets = allData.datasets.filter((dataset) =>
            dataset.countries.includes(configUserInputs.country)
          );
          let datasetsNew = filteredDatasets.filter((dataset) =>
            activeModel?.dataset_ids?.some(
              (datasetOfActiveModel) => datasetOfActiveModel.id === dataset.id
            )
          );
          let datasetsApplied = datasetsNew.filter((dataset) =>
            allDatasetsDetailsData?.some((datasetOfActiveModel) => {
              if (
                datasetOfActiveModel.dataset_id === dataset.id &&
                datasetOfActiveModel.country_name ===
                  configUserInputs.country &&
                (datasetOfActiveModel.brand_name === configUserInputs.brand ||
                  !datasetOfActiveModel.brand_name)
              ) {
                if (!configUserInputs.is_config_manual) {
                  if (
                    datasetOfActiveModel.start_date &&
                    datasetOfActiveModel.end_date
                  ) {
                    let checkForValidDataset = checkDatesOverlapping(
                      configUserInputs.startDate,
                      configUserInputs.endDate,
                      datasetOfActiveModel.start_date,
                      datasetOfActiveModel.end_date
                    );
                    const { type, status } = checkForValidDataset;
                    if (type || status) {
                      return true;
                    }
                    return false;
                  } else {
                    return true;
                  }
                } else {
                  return true;
                }
              }
              return false;
            })
          );
          setDatasets(datasetsApplied);
          setDatasetsLoading(false);
        }
      }
    } else {
      //it is for updating the datasets when the model is change of MROI App in view run case
      let filterDatasets = allData?.datasets
        .filter((dataset) =>
          dataset.countries.includes(configUserInputs.country)
        )
        .filter((activeDatasetnew) =>
          activeModel?.dataset_ids?.some(
            (datasetOfActiveModel) =>
              datasetOfActiveModel.id === activeDatasetnew.id
          )
        )
        .filter((dataset) =>
          allData?.datasetDetails?.some(
            (datasetDetailActive) =>
              datasetDetailActive.dataset_id === dataset.id &&
              datasetDetailActive.country_name === configUserInputs.country &&
              (datasetDetailActive.brand_name === configUserInputs.brand ||
                datasetDetailActive.brand_name === null)
          )
        );
      setDatasets(filterDatasets);
    }
    return () => {};
  }, [
    configUserInputs.country,
    configUserInputs.brand,
    configUserInputs.sub_brand,
    configUserInputs.segment,
    configUserInputs.sub_segment,
    configUserInputs.startDate,
    configUserInputs.endDate,
    configUserInputs.historic_week_data,
    activeModelId,
    configUserInputs.global_week_date_selection,
    configUserInputs.media_sub_brand,
    configUserInputs.media_segment,
    configUserInputs.media_sub_segment,
  ]);
  // sales regions calls for edit config
  useEffect(() => {
    if (
      !configUserInputs.is_config_manual &&
      !configUserInputs.validSalesRegions &&
      configUserInputs.configType === "edit" &&
      configUserInputs?.datasetDetailsData?.status === "success"
    ) {
      const controller = new AbortController();
      const {
        sub_segment: sales_sub_segment,
        sub_brand: sales_sub_brand,
        segment: sales_segment,
      } = salesMediaFilterPayload(configUserInputs, allData, true);

      const body = {
        app_id: config.hardCoded.mroiAppId,
        brand: [configUserInputs.brand],
        countries: [configUserInputs.country],
      };
      configUserInputs.models.map((model) => {
        if (
          [config.hardCoded.autoMlId, config.hardCoded.salesUpliftId].includes(
            model.id
          ) &&
          model.run
        ) {
          let salesDatasetSelected = model.data_sources.find((item) =>
            config.hardCoded.radioDatasetList.includes(item?.type)
          );
          if (salesDatasetSelected) {
            fetchSalesMediaRegions(
              config.api.salesRegionsUrl,
              {
                ...body,
                category: salesDatasetSelected?.category || [],
                dataset_id: [salesDatasetSelected?.id],
                sales_sub_brand: sales_sub_brand,
                sales_segment: sales_segment,
                sales_sub_segment: sales_sub_segment,
              },
              controller,
              "validSalesRegions",
              model.id
            );
          }
        }
      });
    }
  }, [
    configUserInputs.datasetDetailsFlag,
    configUserInputs?.datasetDetailsData,
  ]);
  // media regions calls for edit config
  useEffect(() => {
    if (
      !configUserInputs.is_config_manual &&
      !configUserInputs.validMediaRegions &&
      configUserInputs.configType === "edit" &&
      configUserInputs?.datasetDetailsData?.status === "success"
    ) {
      const controller = new AbortController();
      const { media_segment, media_sub_brand, media_sub_segment } =
        salesMediaFilterPayload(configUserInputs, allData, true);
      const body = {
        app_id: config.hardCoded.mroiAppId,
        brand: [configUserInputs.brand],
        countries: [configUserInputs.country],
      };
      configUserInputs.models.map((model) => {
        if (config.hardCoded.autoMlIds.includes(model.id) && model.run) {
          fetchSalesMediaRegions(
            config.api.mediaRegionsUrl,
            {
              ...body,
              category: [],
              media_segment: media_segment,
              media_sub_brand: media_sub_brand,
              media_sub_segment: media_sub_segment,
              dataset_id: datasetIds(
                configUserInputs,
                datasets,
                "media",
                model.id
              ),
            },
            controller,
            "validMediaRegions",
            model.id
          );
        }
      });
    }
  }, [
    configUserInputs.datasetDetailsFlag,
    configUserInputs?.datasetDetailsData,
  ]);
  useEffect(() => {
    if (configUserInputs?.datasetDetailsData?.status === "success") {
      const controller = new AbortController();
      const { media_segment, media_sub_brand, media_sub_segment } =
        salesMediaFilterPayload(configUserInputs, allData, true);
      const body = {
        app_id: config.hardCoded.mroiAppId,
        brand: [configUserInputs.brand],
        countries: [configUserInputs.country],
      };
      configUserInputs.models.find((model) => {
        if (
          config.hardCoded.autoMlIds.includes(model.id) &&
          model.run &&
          model.id === activeModel.id &&
          modelDataSource?.length
        ) {
          fetchSalesMediaRegions(
            config.api.mediaRegionsUrl,
            {
              ...body,
              category: [],
              media_segment: media_segment,
              media_sub_brand: media_sub_brand,
              media_sub_segment: media_sub_segment,
              dataset_id: datasetIds(
                configUserInputs,
                datasets,
                "media",
                model.id
              ),
            },
            controller,
            "validMediaRegions",
            model.id
          );
        }
      });
    }
  }, [modelDataSource?.length]);
  //Update selection if categoriesData changes
  useEffect(() => {
    let flag = selectedDataset ? true : activeDataset ? true : false;
    let categoryDataExist = configUserInputs.categoryData === -1 ? false : true;
    if (
      !loading &&
      flag &&
      configUserInputs.categoryData?.length &&
      categoryDataExist
    ) {
      let data = {
        dataset: activeDataset,
        categoryData: configUserInputs.categoryData,
      };
      updateCategory(data);
    }
  }, [configUserInputs.categoryData, changeFlag.selectedDatasets]);

  //preferred_sales_dataset MMT model
  useEffect(() => {
    if (activeModelId === config.hardCoded.mmtId) {
      let selectedDatasets = configUserInputs.models
        .find((model) => model.id === config.hardCoded.mmtId)
        ?.data_sources.filter((item) =>
          config.hardCoded.radioDatasetList.includes(item?.type)
        )
        .map((value) => ({ id: value.pipeline_dataset_id, name: value.name }));
      if (selectedDatasets?.length) {
        selectedDatasets.forEach((kpi) => {
          onChange("preferred_sales_dataset", kpi.id);
        });
      } else {
        onChange("preferred_sales_dataset", "");
      }
    }
  }, [changeFlag.selectedDatasets]);

  useEffect(() => {
    const controller = new AbortController();
    let regionValue = configUserInputs.models.find(
      (model) => model.id === activeModelId
    ).config;
    let selectedDatasets = configUserInputs.models
      .find((model) => model.id === activeModelId)
      ?.data_sources.filter((item) =>
        config.hardCoded.radioDatasetList.includes(item?.type)
      );
    if (
      configUserInputs?.configType !== "run" &&
      !configUserInputs?.is_config_manual &&
      configUserInputs?.datasetDetailsData?.status === "success"
    ) {
      if (activeModelId === config.hardCoded.mmtId) {
        if (regionValue.regionSelectedType === "selected") {
          let body = {};
          body.countries = [configUserInputs.country];
          body.app_id = activeModel.app_id;
          let salesDatasetSelected = selectedDatasets.find((item) =>
            config.hardCoded.radioDatasetList.includes(item?.type)
          );
          if (salesDatasetSelected) {
            let datasetArray = [];
            datasetArray.push(salesDatasetSelected.id);
            body.dataset_id = datasetArray;
            body.brand = [configUserInputs.brand];
            body.category = [];
            if (
              salesDatasetSelected.category &&
              salesDatasetSelected.category.length &&
              salesDatasetSelected.category instanceof Array
            ) {
              body.category = salesDatasetSelected.category;
            } else if (typeof salesDatasetSelected.category === "string") {
              body.category.push(salesDatasetSelected.category);
            }
          } else {
            let datasetIdsArray = datasetIds(
              configUserInputs,
              datasets,
              "sales",
              activeModel.id
            );
            body.dataset_id = [...datasetIdsArray];
            body.category = [];
            body.brand = [configUserInputs.brand];
          }
          let url = config.api.globalRegionsUrl;
          let status;
          updateConfigUserInputs("globalRegionsStatus", "loading");
          fetch(url, {
            signal: controller.signal,
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          })
            .then((response) => {
              console.groupCollapsed("requesting", url);
              console.log("REPSONSE -> ", response);
              status = response.status;
              return response.clone().json();
            })
            .then((json) => {
              console.log("JSON -> ", json);
              console.groupEnd();
              if (status === 200) {
                let modifiedData = Object.entries(json.data)
                  .filter(([key, value]) => key !== "country")
                  .map(([key, value]) => {
                    return { id: key, ...value };
                  });
                updateConfigUserInputs("globalRegions", modifiedData);
                let regionsData = getRegionsData(
                  modifiedData,
                  configUserInputs.country,
                  regionValue.regionType,
                  configUserInputs.is_config_manual
                );
                let regionsValues = regionValue.regions.filter((value) =>
                  regionsData.includes(value)
                );
                onChange("regions", regionsValues);
                updateConfigUserInputs("globalRegionsStatus", "success");
              } else {
                updateConfigUserInputs("globalRegionsStatus", "failed");
                updateAlertInfo({
                  open: true,
                  message: json.statusMessage,
                  severity: "error",
                });
              }
            });
        } else {
          // for Select Region Granularity selected to all
          let body = {};
          body.countries = [configUserInputs.country];
          body.app_id = activeModel.app_id;
          let salesDatasetSelected = selectedDatasets.find((item) =>
            config.hardCoded.radioDatasetList.includes(item?.type)
          );
          if (salesDatasetSelected) {
            let datasetArray = [];
            datasetArray.push(salesDatasetSelected.id);
            body.dataset_id = datasetArray;
            body.brand = [configUserInputs.brand];
            body.category = [];
            if (
              salesDatasetSelected.category &&
              salesDatasetSelected.category.length &&
              salesDatasetSelected.category instanceof Array
            ) {
              body.category = salesDatasetSelected.category;
            } else if (typeof salesDatasetSelected.category === "string") {
              body.category.push(salesDatasetSelected.category);
            }
          } else {
            let datasetIdsArray = datasetIds(
              configUserInputs,
              datasets,
              "sales",
              activeModel.id
            );
            body.dataset_id = [...datasetIdsArray];
            body.category = [];
            body.brand = [configUserInputs.brand];
          }
          let url = config.api.globalRegionsUrl;
          let status;
          updateConfigUserInputs("globalRegionsStatus", "loading");
          fetch(url, {
            signal: controller.signal,
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          })
            .then((response) => {
              console.groupCollapsed("requesting", url);
              console.log("REPSONSE -> ", response);
              status = response.status;
              return response.clone().json();
            })
            .then((json) => {
              console.log("JSON -> ", json);
              console.groupEnd();
              if (status === 200) {
                let modifiedData = Object.entries(json.data)
                  .filter(([key, value]) => key !== "country")
                  .map(([key, value]) => {
                    return { id: key, ...value };
                  });
                updateConfigUserInputs("globalRegions", modifiedData);
                let regionsData = getRegionsData(
                  modifiedData,
                  configUserInputs.country,
                  regionValue.regionType,
                  configUserInputs.is_config_manual
                );
                let regionsValues = regionValue.regions.filter((value) =>
                  regionsData.includes(value)
                );
                onChange("regions", regionsValues);
                // if (!regionsValues.length) {
                //   onChange("regionType", "");
                // }
                updateConfigUserInputs("globalRegionsStatus", "success");
              } else {
                updateConfigUserInputs("globalRegionsStatus", "failed");
                updateAlertInfo({
                  open: true,
                  message: json.statusMessage,
                  severity: "error",
                });
              }
            });
        }
      } else {
        let body = {};
        body.countries = [configUserInputs.country];
        body.app_id = activeModel.app_id;
        let salesDatasetSelected = selectedDatasets.find((item) =>
          config.hardCoded.radioDatasetList.includes(item?.type)
        );
        if (salesDatasetSelected) {
          let datasetArray = [];
          datasetArray.push(salesDatasetSelected.id);
          body.dataset_id = datasetArray;
          body.category = [];
          body.brand = [configUserInputs.brand];
          if (
            salesDatasetSelected.category &&
            salesDatasetSelected.category.length &&
            salesDatasetSelected.category instanceof Array
          ) {
            body.category = salesDatasetSelected.category;
          } else if (typeof salesDatasetSelected.category === "string") {
            body.category.push(salesDatasetSelected.category);
          }
        } else {
          let datasetIdsArray = datasetIds(
            configUserInputs,
            datasets,
            "sales",
            activeModel.id
          );
          body.dataset_id = [...datasetIdsArray];
          body.category = [];
          body.brand = [configUserInputs.brand];
        }
        let url = config.api.globalRegionsUrl;
        let status;
        updateConfigUserInputs("globalRegionsStatus", "loading");
        fetch(url, {
          signal: controller.signal,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              let modifiedData = Object.entries(json.data)
                .filter(([key, value]) => key !== "country")
                .map(([key, value]) => {
                  return { id: key, ...value };
                });
              updateConfigUserInputs("globalRegions", modifiedData);
              let filteredRegionsForOtherModels = filterSalesDatasetRegions(
                modifiedData,
                regionValue?.region_granularity?.region_type,
                regionValue,
                activeModelId,
                configUserInputs.is_config_manual
              );
              onChange("region_granularity", filteredRegionsForOtherModels);
              const {
                sub_segment: sales_sub_segment,
                sub_brand: sales_sub_brand,
                segment: sales_segment,
                media_segment,
                media_sub_brand,
                media_sub_segment,
              } = salesMediaFilterPayload(configUserInputs, allData, true);
              // const url = config.api.salesRegionsUrl;
              // "https://run.mocky.io/v3/8fed61f2-9946-47bb-8e41-5efc1ff06862";
              if (
                [
                  ...config.hardCoded.autoMlIds,
                  config.hardCoded.salesUpliftId,
                ].includes(activeModel.id) ||
                configUserInputs.models.find(
                  (model) =>
                    model.run &&
                    [
                      ...config.hardCoded.autoMlIds,
                      config.hardCoded.salesUpliftId,
                    ].includes(model.id)
                )
              ) {
                fetchSalesMediaRegions(
                  config.api.salesRegionsUrl,
                  {
                    ...body,
                    sales_sub_brand: sales_sub_brand,
                    sales_segment: sales_segment,
                    sales_sub_segment: sales_sub_segment,
                  },
                  controller,
                  "validSalesRegions",
                  activeModel.id
                );
                fetchSalesMediaRegions(
                  config.api.mediaRegionsUrl,
                  {
                    ...body,
                    media_segment: media_segment,
                    media_sub_brand: media_sub_brand,
                    media_sub_segment: media_sub_segment,
                    dataset_id: datasetIds(
                      configUserInputs,
                      datasets,
                      "media",
                      activeModel.id
                    ),
                  },
                  controller,
                  "validMediaRegions",
                  activeModel.id
                );
              }
              updateConfigUserInputs("globalRegionsStatus", "success");
            } else {
              updateConfigUserInputs("globalRegionsStatus", "failed");
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "error",
              });
            }
          });
      }
    }
    return () => {
      controller.abort();
      updateConfigUserInputs("globalRegionsStatus", "success");
    };
  }, [
    changeFlag.selectedDatasets,
    configUserInputs?.datasetDetailsData?.status,
  ]);

  // for mmt global regions
  useEffect(() => {
    const controller = new AbortController();
    let regionValue = configUserInputs.models.find(
      (model) => model.id === config.hardCoded.mmtId
    ).config;
    let selectedDatasets = configUserInputs.models
      .find((model) => model.id === config.hardCoded.mmtId)
      ?.data_sources.filter((item) =>
        config.hardCoded.radioDatasetList.includes(item?.type)
      );
    if (
      configUserInputs.models.find(
        (model) => model.id === config.hardCoded.mmtId
      ).run &&
      configUserInputs?.datasetDetailsData?.status === "success"
    ) {
      if (regionValue.regionSelectedType === "selected") {
        let body = {};
        body.countries = [configUserInputs.country];
        body.app_id = activeModel.app_id;
        let salesDatasetSelected = selectedDatasets.find((item) =>
          config.hardCoded.radioDatasetList.includes(item?.type)
        );
        if (salesDatasetSelected) {
          let datasetArray = [];
          datasetArray.push(salesDatasetSelected.id);
          body.dataset_id = datasetArray;
          body.brand = [configUserInputs.brand];
          body.category = [];
          if (
            salesDatasetSelected.category &&
            salesDatasetSelected.category.length &&
            salesDatasetSelected.category instanceof Array
          ) {
            body.category = salesDatasetSelected.category;
          } else if (typeof salesDatasetSelected.category === "string") {
            body.category.push(salesDatasetSelected.category);
          }
        } else {
          let datasetIdsArray = datasetIds(
            configUserInputs,
            datasets,
            "sales",
            activeModel.id
          );
          body.dataset_id = [...datasetIdsArray];
          body.category = [];
          body.brand = [configUserInputs.brand];
        }
        let url = config.api.globalRegionsUrl;
        let status;
        updateConfigUserInputs("globalRegionsStatus", "loading");
        fetch(url, {
          signal: controller.signal,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              let modifiedData = Object.entries(json.data)
                .filter(([key, value]) => key !== "country")
                .map(([key, value]) => {
                  return { id: key, ...value };
                });
              updateConfigUserInputs("mmtGlobalRegions", modifiedData);
              let regionsData = getRegionsData(
                modifiedData,
                configUserInputs.country,
                regionValue.regionType,
                configUserInputs.is_config_manual
              );
              let regionsValues = regionValue.regions.filter((value) =>
                regionsData.includes(value)
              );
              onChange("regions", regionsValues);
              if (
                [
                  ...config.hardCoded.autoMlIds,
                  config.hardCoded.salesUpliftId,
                ].includes(activeModel.id) ||
                configUserInputs.models.find(
                  (model) =>
                    model.run &&
                    [
                      ...config.hardCoded.autoMlIds,
                      config.hardCoded.salesUpliftId,
                    ].includes(model.id)
                )
              ) {
                const {
                  sub_segment: sales_sub_segment,
                  sub_brand: sales_sub_brand,
                  segment: sales_segment,
                  media_segment,
                  media_sub_brand,
                  media_sub_segment,
                } = salesMediaFilterPayload(configUserInputs, allData, true);
                fetchSalesMediaRegions(
                  config.api.salesRegionsUrl,
                  {
                    ...body,
                    sales_sub_brand: sales_sub_brand,
                    sales_segment: sales_segment,
                    sales_sub_segment: sales_sub_segment,
                  },
                  controller,
                  "validSalesRegions",
                  activeModel.id
                );
                fetchSalesMediaRegions(
                  config.api.mediaRegionsUrl,
                  {
                    ...body,
                    media_segment: media_segment,
                    media_sub_brand: media_sub_brand,
                    media_sub_segment: media_sub_segment,
                    dataset_id: datasetIds(
                      configUserInputs,
                      datasets,
                      "media",
                      activeModel.id
                    ),
                  },
                  controller,
                  "validMediaRegions",
                  activeModel.id
                );
              }
              updateConfigUserInputs("globalRegionsStatus", "success");
            } else {
              updateConfigUserInputs("globalRegionsStatus", "failed");

              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "error",
              });
            }
          });
      } else {
        // for Select Region Granularity selected to all
        let body = {};
        body.countries = [configUserInputs.country];
        body.app_id = activeModel.app_id;
        let salesDatasetSelected = selectedDatasets.find((item) =>
          config.hardCoded.radioDatasetList.includes(item?.type)
        );
        if (salesDatasetSelected) {
          let datasetArray = [];
          datasetArray.push(salesDatasetSelected.id);
          body.dataset_id = datasetArray;
          body.brand = [configUserInputs.brand];
          body.category = [];
          if (
            salesDatasetSelected.category &&
            salesDatasetSelected.category.length &&
            salesDatasetSelected.category instanceof Array
          ) {
            body.category = salesDatasetSelected.category;
          } else if (typeof salesDatasetSelected.category === "string") {
            body.category.push(salesDatasetSelected.category);
          }
        } else {
          let datasetIdsArray = datasetIds(
            configUserInputs,
            datasets,
            "sales",
            activeModel.id
          );
          body.dataset_id = [...datasetIdsArray];
          body.category = [];
          body.brand = [configUserInputs.brand];
        }
        let url = config.api.globalRegionsUrl;
        let status;
        updateConfigUserInputs("globalRegionsStatus", "loading");
        fetch(url, {
          signal: controller.signal,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            console.groupCollapsed("requesting", url);
            console.log("REPSONSE -> ", response);
            status = response.status;
            return response.clone().json();
          })
          .then((json) => {
            console.log("JSON -> ", json);
            console.groupEnd();
            if (status === 200) {
              let modifiedData = Object.entries(json.data)
                .filter(([key, value]) => key !== "country")
                .map(([key, value]) => {
                  return { id: key, ...value };
                });
              updateConfigUserInputs("mmtGlobalRegions", modifiedData);
              let regionsData = getRegionsData(
                modifiedData,
                configUserInputs.country,
                regionValue.regionType,
                configUserInputs.is_config_manual
              );
              let regionsValues = regionValue.regions.filter((value) =>
                regionsData.includes(value)
              );
              onChange("regions", regionsValues);
              updateConfigUserInputs("globalRegionsStatus", "success");
              if (
                config.hardCoded.autoMlIds.includes(activeModel.id)
                // ||
                // configUserInputs.models.find(
                //   (model) =>
                //     model.run &&
                //     [
                //       ...config.hardCoded.autoMlIds,
                //       config.hardCoded.salesUpliftId,
                //     ].includes(model.id)
                // )
              ) {
                const { media_segment, media_sub_brand, media_sub_segment } =
                  salesMediaFilterPayload(configUserInputs, allData, true);
                fetchSalesMediaRegions(
                  config.api.mediaRegionsUrl,
                  {
                    ...body,
                    media_segment: media_segment,
                    media_sub_brand: media_sub_brand,
                    media_sub_segment: media_sub_segment,
                    dataset_id: datasetIds(
                      configUserInputs,
                      datasets,
                      "media",
                      activeModel.id
                    ),
                  },
                  controller,
                  "validMediaRegions",
                  activeModel.id
                );
              }
            } else {
              updateConfigUserInputs("globalRegionsStatus", "failed");
              updateAlertInfo({
                open: true,
                message: json.statusMessage,
                severity: "error",
              });
            }
          });
      }
    }
    return () => {
      controller.abort();
      updateConfigUserInputs("globalRegionsStatus", "success");
    };
  }, [changeFlag.selectedDatasets, selectedModel.run]);

  // Update datasets if country or brand changes
  useEffect(() => {
    if (!loading && !pageLoading) {
      let data = {
        dataset: activeDataset,
        categoryData: configUserInputs.categoryData,
      };
      if (!configUserInputs.is_config_manual) {
        updateCategoryDataset(data);
      }
    }
  }, [configUserInputs.country, configUserInputs.brand]);

  // Update datasets if country or brand changes
  useEffect(() => {
    if (!loading && !pageLoading) {
      let data = {
        dataset: activeDataset,
        categoryData: configUserInputs.categoryData,
      };
      if (configUserInputs.is_config_manual) {
        updateCategoryDataset(data);
        let configData = {
          modelId: activeModelId,
          key: "manual_kpi",
          value: "",
        };
        updateModelLocalParameters(configData);
      }
    }
  }, [configUserInputs.is_config_manual]);

  useEffect(() => {
    if (
      configUserInputs.app_id === config.hardCoded.mroiAppId &&
      configUserInputs?.datasetDetailsData?.status === "success" &&
      configUserInputs.configType !== "run"
    ) {
      filterMergeDatasetForWeeks();
    }
  }, [
    configUserInputs?.historic_week_data,
    configUserInputs?.startDate,
    configUserInputs?.endDate,
  ]);

  useEffect(() => {
    if (
      configUserInputs.app_id === config.hardCoded.mroiAppId &&
      configUserInputs?.datasetDetailsData?.status === "success" &&
      configUserInputs.configType !== "run" &&
      !configUserInputs.is_config_manual
    ) {
      filterDataset();
    }
  }, [
    configUserInputs?.historic_week_data,
    configUserInputs?.startDate,
    configUserInputs?.endDate,
    configUserInputs?.datasetDetailsData?.status,
  ]);

  useEffect(() => {
    if (
      configUserInputs.app_id === config.hardCoded.mroiAppId &&
      configUserInputs?.datasetDetailsData?.status === "success" &&
      configUserInputs.configType !== "run"
    ) {
      resetMergedDataset({ modelId: activeModelId });
    }
  }, [configUserInputs?.country, configUserInputs?.brand]);

  const downloadRun = (url, body) => {
    let status;
    body.type === config.hardCoded.downloadOutputType
      ? setDownloadLoading(true)
      : setInputDownloadLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (status === 200) {
          setDownloadLoading(false);
          setInputDownloadLoading(false);
          let a = document.createElement("a");
          a.href = json.data.location;
          a.click();
        } else if (status === 400) {
          setDownloadLoading(false);
          setInputDownloadLoading(false);
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        } else {
          updateAlertInfo({
            open: true,
            message: json.statusMessage,
            severity: "error",
          });
        }
      });
  };
  //Change runView to data if run status is not success
  useEffect(() => {
    // if (!disableFlag) {
    updateSelections("runView", "data");
    // }
  }, []);

  useEffect(() => {
    if (configUserInputs?.configType === "run") {
      setActiveSelection("view_dashboard");
      updateSelections("runView", "chart");
    }
  }, []);

  useEffect(() => {
    if (configUserInputs?.configType === "run") {
      setActiveSelection("view_dashboard");
      updateSelections("runView", "chart");
    } else if (configUserInputs?.is_config_manual) {
      setActiveSelection("model config");
      // As in manual config model adstock as run type is not available.
      // Default is pilot
      if (
        activeModelId === config.hardCoded.autoMlId &&
        configUserInputs?.configType === "add"
      )
        onChange("run_type", "pilot");
    }
  }, [configUserInputs?.is_config_manual, activeModelId]);

  //Update datasets if country changes
  useEffect(() => {
    if (!pageLoading) {
      if (!configUserInputs.is_config_manual) {
        updateDatasetOnCountryChange(datasets);
        let checkForActiveDataset = datasets.some(
          (item) => item.id === activeDatasetId
        );
        if (checkForActiveDataset) {
          setActiveDatasetId(activeDatasetId);
        } else {
          const filterDataset =
            activeModelId === config.hardCoded.salesUpliftId
              ? datasets.filter((e) => e.type !== "MEDIA")
              : datasets;
          setActiveDatasetId(filterDataset[0]?.id);
        }
      }
    }
  }, [configUserInputs.country]);

  //save previous merged dataset length of selectedModel
  const preActiveDatasetId = useRef({
    activeDatasetId: activeDatasetId,
    length: selectedModel?.merged_data_sources?.length,
  });

  useEffect(() => {
    preActiveDatasetId.current = {
      activeDatasetId: activeDatasetId,
      length: selectedModel?.merged_data_sources?.length,
    };
  }, [activeDatasetId]);

  //when the merged datasets updated that time activeDataset
  useEffect(() => {
    if (
      selectedModel?.merged_data_sources?.length &&
      selectedModel?.merged_data_sources?.length !==
        preActiveDatasetId?.current?.length
    ) {
      setActiveDatasetId(
        selectedModel?.merged_data_sources[
          selectedModel?.merged_data_sources.length - 1
        ].id
      );
    } else if (
      selectedModel?.merged_data_sources?.length &&
      selectedModel?.merged_data_sources?.length ===
        preActiveDatasetId?.current?.length
    ) {
      setActiveDatasetId(preActiveDatasetId?.current?.activeDatasetId);
    }
  }, [
    selectedModel?.merged_data_sources?.length,
    selectedModel?.merged_data_sources,
  ]);

  //variable to get nested/target/budget runs
  let nestedRunsArray = activeModel?.local_parameters?.filter((obj) =>
    config.hardCoded.mulipleRunType.includes(obj.id)
  );

  if (!updateCheckDE && configUserInputs.configType) {
    nestedRunsArray = nestedRunsArray.map((e) => {
      return {
        ...e,
        data: e.data.filter(
          (elm) => !["budget_only", "nested"].includes(elm.id)
        ),
      };
    });
  }

  let isModelDisable =
    configUserInputs.is_config_manual &&
    activeModel.id === config.hardCoded.diffDiffId;
  useEffect(() => {
    if (
      configUserInputs.app_id === config.hardCoded.mroiAppId &&
      configUserInputs?.datasetDetailsData?.status === "success" &&
      configUserInputs.configType !== "run"
    ) {
      const filteredDatasets = initialDatasets
        ?.filter((el) =>
          configUserInputs?.datasetDetailsData?.data.find(
            (e) => e.dataset_id === el.id
          )
        )
        .filter((elem) => {
          let dateRange;
          if (
            configUserInputs.global_week_date_selection === "historic_week_data"
          ) {
            dateRange = getDatesFromWeek(configUserInputs.historic_week_data);
          } else {
            dateRange = {
              min: configUserInputs.startDate,
              max: configUserInputs.endDate,
            };
          }
          let startDateSelected = dateRange?.min;
          let endDateSelected = dateRange?.max;
          const findDataset = configUserInputs?.datasetDetailsData?.data?.find(
            (elm) => elm.dataset_id === elem.id
          );
          if (!configUserInputs.is_config_manual) {
            let checkForValidDataset = checkDatesOverlapping(
              startDateSelected,
              endDateSelected,
              findDataset?.start_date,
              findDataset?.end_date
            );
            const { type, status } = checkForValidDataset;
            if (status || type) {
              return elem;
            }
          } else {
            if (findDataset) {
              return true;
            } else {
              return false;
            }
          }
        });
      setDatasets(filteredDatasets);
      const filterDataset =
        activeModelId === config.hardCoded.salesUpliftId
          ? filteredDatasets.filter((e) => e.type !== "MEDIA")
          : filteredDatasets;
      setActiveDatasetId(filterDataset[0]?.id);
    } else {
    }
  }, [
    configUserInputs?.datasetDetailsData,
    activeModelId,
    configUserInputs?.historic_week_data,
    configUserInputs?.startDate,
    configUserInputs?.endDate,
  ]);

  const updateRunAsStarred = () => {
    const runId = user?.selections?.activeRunPayload?.id;
    const body = {
      app_id: activeApp?.id,
      model: activeModelId,
      run_id: runId,
    };
    let url = config.api.optimalRunUrl;
    let status;
    setStarredValues(activeModelId);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        status = response.status;
        return response.clone().json();
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (json.errorCode) {
          updateUserInfo({ error: json.error, errorCode: json.errorCode });
        } else {
          if (json.errorCode) {
            updateUserInfo({
              error: json.error,
              errorCode: json.errorCode,
            });
          } else {
            let url = `${config.api.runUrl}?run_id=${runId}`;
            let status;
            fetch(url)
              .then((response) => {
                console.groupCollapsed("requesting", url);
                console.log("REPSONSE -> ", response);
                status = response.status;
                return response.clone().json();
              })
              .then((json) => {
                console.log("JSON -> ", json);
                console.groupEnd();
                if (status === 200) {
                  updateSelections("activeRunPayload", json.data);
                  let runData = json.data;
                  let updatedConfigUserInputs = makeConfigUserInputs({
                    initialConfigData: {
                      selections: runData.payload,
                      id: runData.metadata.config_id,
                      name: runData.metadata.config_name,
                      app_id: runData.app_id,
                      metadata: runData?.metadata,
                    },
                    match,
                    disableFlag,
                    activeApp,
                    allData,
                    configType: "run",
                  });
                  updateWholeConfigUserInputs(updatedConfigUserInputs);
                  removeStarredValues(activeModelId);
                } else {
                  updateAlertInfo({
                    open: true,
                    message: json.statusMessage,
                    severity: "error",
                  });
                  removeStarredValues(activeModelId);
                }
              });
          }
        }
      });
  };

  //for old config define column_detail key in the test region
  useEffect(() => {
    configUserInputs.models.forEach((model) => {
      if (
        config.hardCoded.rbtAndSuIds.includes(model.id) &&
        model.run &&
        configUserInputs.is_config_manual &&
        configUserInputs.configType === "edit"
      ) {
        if (
          !model.config.cols_to_force_in?.includes("dark_sales/store") &&
          !model.config.cols_to_force_in?.includes("dark_sales") &&
          model.config.region_granularity?.test_dark_regions?.test.length &&
          Object.keys(
            model.config.region_granularity?.test_dark_regions?.test[0].files
          ).length
        ) {
          let data = {
            modelId: model.id,
            key: "cols_to_force_in",
            value: ["dark_sales"],
          };
          updateModelLocalParameters(data);
          const updateData = {
            modelId: model.id,
            key: "region_granularity",
            value: {
              ...model.config.region_granularity,
              test_dark_regions: {
                ...model.config.region_granularity.test_dark_regions,
                test: model.config.region_granularity?.test_dark_regions?.test?.map(
                  (e) => ({
                    ...e,
                    files: {
                      ...e.files,
                      // column_details: ["dark_sales", "delta_lag"],
                      column_details: ["dark_sales"],
                    },
                  })
                ),
              },
            },
          };
          updateModelLocalParameters(updateData);
        }
      }
    });
  }, [activeModelId]);

  if (selectedModel?.merged_data_sources) {
    let mergedDatasets = selectedModel.merged_data_sources
      .map((elem) => [...elem.dataset_list])
      .flat();
    mergedDatasets = [...new Set(mergedDatasets)];
    datasets = datasets.filter((elem) => !mergedDatasets.includes(elem.id));
  }

  // merge data source
  useEffect(() => {
    const filterDataset =
      activeModelId === config.hardCoded.salesUpliftId
        ? datasets.filter((e) => e.type !== "MEDIA")
        : datasets;
    setActiveDatasetId(filterDataset[0]?.id);
    return () => {};
  }, [changeFlag.mergeDataset]);

  //filter nielsen_comp from global datasets list
  let unwantedDataset = [config.hardCoded.neilsenCompId];
  let updatedatasets = datasets?.filter(
    (item) => !unwantedDataset.includes(item.pipeline_dataset_id)
  );

  const modelRequired = configUserInputs?.models.find(
    (model) => model.id === activeModelId
  );
  let datasetsTypesData = splitDatasetsIntoTypes(
    updatedatasets,
    modelRequired.merged_data_sources ? modelRequired.merged_data_sources : []
  );
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (selectedModel.config.pilot_run_id && !pilot_start_date) {
      //Fetch run data if pilot_run_id is present
      const url = `${config.api.runUrl}?run_id=${selectedModel.config.pilot_run_id}`;
      let status;
      // updateAlertInfo({
      //   open: true,
      //   message: "Fetching the Pilot start date for this run...",
      //   severity: "success",
      // });
      fetch(url, { signal })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          status = response.status;
          return response.clone().json();
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (status === 200) {
            const activeModel = json.data.payload.models.find(
              (model) => model.id === selectedModel.id
            );
            set_pilot_start_date(activeModel.config.pilot_start_date);
          } else {
            updateAlertInfo({
              open: true,
              message: json.status_message,
              severity: "success",
            });
          }
        });
    }
    return () => {
      controller.abort();
    };
  });
  //  ! not require now
  // useEffect(() => {
  //   if (selectedModel.comment) {
  // updateConfigUserInputs("comment", selectedModel.comment);x
  //     setComment(selectedModel.comment);
  //   }
  // }, [selectedModel]);

  // CONSOLE LOG
  // console.groupCollapsed("MODEL SELECTIONS");
  // console.log("ACTIVE MODEL ID ->", activeModelId);
  // console.log("ACTIVE DATASET ID ->", activeDatasetId);
  // console.log("DATASETS -> ", datasets);
  // console.log("ACTIVE MODEL -> ", activeModel);
  // console.log("SELECTED MODEL DATASETS -> ", selectedModelDatasets);
  // console.log("ACTIVE DATASET -> ", activeDataset);
  // console.log("SELECTED MODEL -> ", selectedModel);
  // console.log("SELECTED DATASET IDS -> ", selectedDatasetsIds);
  // console.log("SELECTED DATASET -> ", selectedDataset);
  // console.log("ACTIVE DATASET DATES ->", activeDatasetDates);
  // console.log(`userSelectedModelsIds ->`, userSelectedModelsIds);
  // console.log(`activeDatasetForKpi`, activeDatasetForKpi);
  // console.groupEnd();
  return (
    <div className="models-container">
      <div className="models-menu">
        {filteredModels &&
          filteredModels?.length &&
          filteredModels?.map((obj) => (
            <div
              key={obj.id}
              className="models-item"
              onClick={() => {
                setActiveModelId(obj.id);
                setActiveSelection("dataset");
              }}
            >
              <div
                className={`models-item-div ${
                  activeModelId === obj.id ? "active" : ""
                }`}
              >
                <p>{obj.name}</p>
                {(configUserInputs.models.find((model) => model.id === obj.id)
                  ? configUserInputs.models.find((model) => model.id === obj.id)
                      .run
                  : null) && (
                  <i className="material-icons selected-model-icon">
                    check_circle
                  </i>
                )}
              </div>
            </div>
          ))}

        {disableFlag && (
          <div className="run-menu-container">
            <Select
              name="Download"
              className={classes.multiSelect}
              MenuProps={MenuProps}
              placeholder={"Download"}
              value={"Download"}
            >
              <MenuItem
                value="Download"
                className={classes.singleMenuItem}
                disabled={true}
                selected={true}
              >
                <i className="material-icons-outlined run-menu-item-icon">
                  file_download
                </i>
                Download
              </MenuItem>
              {
                <MenuItem
                  className={classes.singleMenuItem}
                  disabled={
                    !user.selections.activeRunPayload.is_output_populated
                  }
                  onClick={() => {
                    if (!downloadLoading) {
                      downloadRun(config.api.downloadRunUrl, {
                        run_id: user.selections.activeRunPayload.id,
                        type: config.hardCoded.downloadOutputType,
                        app_id: config.hardCoded.mroiAppId,
                      });
                    }
                  }}
                >
                  {downloadLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <span className="run-menu-item-text">Output Files</span>
                    </>
                  )}
                </MenuItem>
              }
              <MenuItem
                className={classes.singleMenuItem}
                disabled={!user.selections.activeRunPayload.is_input_populated}
                onClick={() => {
                  if (!inputDownloadLoading) {
                    downloadRun(config.api.downloadRunUrl, {
                      run_id: user.selections.activeRunPayload.id,
                      type: config.hardCoded.downloadInputType,
                      app_id: config.hardCoded.mroiAppId,
                    });
                  }
                }}
              >
                {inputDownloadLoading ? (
                  <Loader />
                ) : (
                  <>
                    <span className="run-menu-item-text">
                      Intermediate Files
                    </span>
                  </>
                )}
              </MenuItem>
            </Select>
          </div>
        )}
      </div>

      {nestedRunsArray?.length || !disableFlag ? (
        <div className="model-items-container">
          {!disableFlag && (
            <div className="model-user-input">
              <div className="model-switch-container">
                <p className="model-text bold">Model:</p>
                <i
                  className={`material-icons-outlined selected-model-icon ${
                    selectedModel.run ? "selected" : ""
                  }`}
                  onClick={() => {
                    let data = {
                      modelId: activeModelId,
                      modelRunFlag: !selectedModel.run,
                    };
                    toggleModel(data);
                  }}
                >
                  {selectedModel.run ? "toggle_on" : "toggle_off"}
                </i>
                <p className="model-text">
                  {selectedModel.run ? "Enabled" : "Disabled"}
                </p>
              </div>
            </div>
          )}
          {nestedRunsArray?.map((obj) => {
            let value = selectedModel.config[obj.id];
            return (
              <div
                className={`run-type-local-parameters-row ${
                  selectedModel.run ? "" : "disabled"
                } ${disableFlag ? "disabled-pointer" : ""}`}
                key={obj.id}
              >
                <LocalParameterItem
                  {...obj}
                  value={value}
                  onChange={onChange}
                  userInputs={configUserInputs}
                  allCampaigns={configUserInputs.campaignsData}
                  userSelectedCampaigns={value}
                  activeModel={activeModel}
                  disableFlag={disableFlag}
                  match={match}
                  changeFlag={changeFlag}
                  loading={loading}
                  activeApp={activeApp}
                  userData={allData}
                />
              </div>
            );
          })}
          {!disableFlag && (
            <div className=" model-user-input approximate-time-container">
              <p className="model-text approximate-time bold">
                Approximate Model Run:{" "}
                {convertMinutesIntoHrMins(activeModel.approximate_run_time)}
              </p>
            </div>
          )}
        </div>
      ) : null}

      {
        <div
          className={`models-selection-title-container ${
            selectedModel.run ? "" : "disabled"
          }`}
        >
          <div style={{ display: "flex" }}>
            {" "}
            <div
              className={`models-selection-div ${
                activeSelection === "dataset" ? "active-selection-div" : ""
              } ${datasetDisabled}`}
              onClick={() => {
                setActiveSelection("dataset");
                updateSelections("runView", "data");
              }}
            >
              <i
                className={`material-icons-outlined dataset-icon ${
                  activeSelection === "dataset" ? "active-selection" : ""
                }`}
              >
                storage
              </i>
              <p
                className={`models-selection-title ${
                  activeSelection === "dataset" ? "active-selection" : ""
                }`}
              >
                Datasets
              </p>
            </div>
            <div
              className={`models-selection-div ${
                activeSelection == "model config" ? "active-selection-div" : ""
              }`}
              onClick={() => {
                setActiveSelection("model config");
                updateSelections("runView", "data");
              }}
            >
              <i
                className={`material-icons-outlined dataset-icon ${
                  activeSelection === "model config" ? "active-selection" : ""
                }`}
              >
                settings
              </i>
              <p
                className={`models-selection-title ${
                  activeSelection === "model config" ? "active-selection" : ""
                }`}
              >
                Configurations
              </p>
            </div>
            {disableFlag && (
              <div
                className={`models-selection-div ${
                  activeSelection == "view_dashboard"
                    ? "active-selection-div"
                    : ""
                }`}
                onClick={() => {
                  setActiveSelection("view_dashboard");
                  updateSelections("runView", "chart");
                }}
              >
                <i
                  className={`material-icons-outlined dataset-icon ${
                    activeSelection === "view_dashboard"
                      ? "active-selection"
                      : ""
                  }`}
                >
                  bar_chart
                </i>
                <p
                  className={`models-selection-title ${
                    activeSelection === "view_dashboard"
                      ? "active-selection"
                      : ""
                  }`}
                >
                  View Dashboard
                </p>
              </div>
            )}
            {disableFlag &&
              config.hardCoded.testAndLearnArr.includes(activeModelId) && (
                <div
                  className={`models-selection-div ${
                    activeSelection == "view_test_and_learn"
                      ? "active-selection-div"
                      : ""
                  }`}
                  onClick={() => {
                    setActiveSelection("view_test_and_learn");
                    updateSelections("runView", "test_and_learn_chart");
                  }}
                >
                  <i
                    className={`material-icons-outlined dataset-icon ${
                      activeSelection === "view_test_and_learn"
                        ? "active-selection"
                        : ""
                    }`}
                  >
                    summarize
                  </i>

                  <p
                    className={`models-selection-title ${
                      activeSelection === "view_test_and_learn"
                        ? "active-selection"
                        : ""
                    }`}
                  >
                    Test and Learn
                  </p>
                </div>
              )}
            {/* {disableFlag &&
              //if models have powerBiReportForBusiness key in the FS only then business tab will be shown
              activeModel?.powerBiReportForBusiness?.iframeUrlForRun && (
                <div
                  className={`models-selection-div ${
                    activeSelection == "view_business"
                      ? "active-selection-div"
                      : ""
                  }`}
                  onClick={() => {
                    setActiveSelection("view_business");
                    updateSelections("runView", "business_chart");
                  }}
                >
                  <i
                    className={`material-icons-outlined dataset-icon ${
                      activeSelection === "view_business"
                        ? "active-selection"
                        : ""
                    }`}
                  >
                    business_center
                  </i>

                  <p
                    className={`models-selection-title ${
                      activeSelection === "view_business"
                        ? "active-selection"
                        : ""
                    }`}
                  >
                    Business
                  </p>
                </div>
              )} */}
          </div>
          {disableFlag && activeApp?.id === config.hardCoded.mroiAppId && (
            <div className={`models-selection-div optimal-run-container`}>
              {activeSelection === "view_dashboard" &&
                [
                  config.hardCoded.salesUpliftId,
                  config.hardCoded.autoMlId,
                ].includes(activeModelId) &&
                (selectedModel?.config?.run_model ||
                  typeof selectedModel?.config?.run_model === "undefined") &&
                user?.selections?.activeRunPayload?.status === "Success" &&
                user?.preferred_username ===
                  user?.selections?.activeRunPayload?.created_by && (
                  <RequestValidation
                    selectedModel={selectedModel}
                    classes={classes}
                    handleModalDialogOpen={handleModalDialogOpen}
                    handleModalDialogClose={handleModalDialogClose}
                    configUserInputs={configUserInputs}
                    user={user}
                    setDatasetsLoading={setDatasetsLoading}
                    nested_pilot_start_date={pilot_start_date}
                  />
                )}
              {activeModel?.powerBiReportForBusiness?.iframeUrlForRun &&
                copyUrl &&
                user.selections.runView === "business_chart" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "15px",
                    }}
                  >
                    <span
                      className="material-icons-outlined"
                      onClick={() => {
                        copyValueToClipboard(copyUrl);
                        updateAlertInfo({
                          open: true,
                          message: "Dashboard URL copied to clipboard.",
                          severity: "success",
                        });
                      }}
                      style={{ fontSize: "17px" }}
                      title="Copy Business Dashboard url"
                    >
                      content_copy
                    </span>
                  </div>
                )}
              {["test_and_learn_chart", "chart"].includes(
                user.selections.runView
              ) &&
              (user.selections.runView === "business_chart"
                ? activeModel?.powerBiReportForBusiness?.iframeUrlForRun
                : user.selections.runView === "chart"
                ? activeModel?.powerBiReportForModel?.iframeUrlForRun &&
                  user.selections.activeRunPayload.status ===
                    config.hardCoded.runSuccess
                : activeModel?.powerBiReportForModel?.iframeUrlForRun) ? (
                <i
                  className={`material-icons-outlined ${
                    expanded ? "zoom-icon-full-screen" : "zoom-icon"
                  }`}
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                >
                  {expanded ? "" : "zoom_out_map"}
                </i>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      }

      {user.selections.runView === "chart" ? (
        user.selections.activeRunPayload.status ===
        config.hardCoded.runSuccess ? (
          <PowerBiReportForRun
            configUserInputs={configUserInputs}
            view={user.selections.runView}
            activeModel={activeModel}
            activeRunPayloadEntity={user.selections.activeRunPayload}
            status={user.selections.activeRunPayload.status}
            dagMessage={user.selections.activeRunPayload.status_message}
            expanded={expanded}
            setExpanded={setExpanded}
            setCopyUrl={setCopyUrl}
          />
        ) : (
          <Message
            status={user.selections.activeRunPayload.status}
            dagMessage={user.selections.activeRunPayload.status_message}
          />
        )
      ) : null}
      {user.selections.runView === "test_and_learn_chart" && (
        <PowerBiReportForRun
          configUserInputs={configUserInputs}
          view={user.selections.runView}
          activeModel={activeModel}
          activeRunPayloadEntity={user.selections.activeRunPayload}
          status={user.selections.activeRunPayload.status}
          dagMessage={user.selections.activeRunPayload.status_message}
          expanded={expanded}
          setExpanded={setExpanded}
          setCopyUrl={setCopyUrl}
        />
      )}
      {user.selections.runView === "business_chart" && (
        <PowerBiReportForRun
          configUserInputs={configUserInputs}
          view={user.selections.runView}
          activeModel={activeModel}
          activeRunPayloadEntity={user.selections.activeRunPayload}
          status={user.selections.activeRunPayload.status}
          dagMessage={user.selections.activeRunPayload.status_message}
          expanded={expanded}
          setExpanded={setExpanded}
          setCopyUrl={setCopyUrl}
        />
      )}
      {user.selections.runView === "data" && (
        <div
          className={`models-selections-container ${
            selectedModel.run ? "" : "disabled"
          }`}
        >
          {datasetsLoading ? (
            <Loader />
          ) : (
            <Dataset
              activeModel={activeModel}
              datasets={datasets}
              activeDataset={activeDataset}
              selectedDataset={selectedDataset}
              activeModelId={activeModelId}
              activeDatasetId={activeDatasetId}
              selectedDatasetsIds={selectedDatasetsIds}
              setActiveDatasetId={setActiveDatasetId}
              activeModelsDatasetFromModelsData={
                activeModelsDatasetFromModelsData
              }
              disableFlag={disableFlag}
              match={match}
              activeSelection={activeSelection}
              selectedModelDatasets={selectedModelDatasets}
              activeDatasetDates={activeDatasetDates}
              activeAppID={configUserInputs?.app_id}
              selectedModel={selectedModel}
              datasetsTypesData={datasetsTypesData}
            />
          )}
          <ModelConfig
            allData={allData}
            activeModel={activeModel}
            datasets={datasets}
            activeDataset={activeDataset}
            selectedDataset={selectedDataset}
            activeModelId={activeModelId}
            activeDatasetId={activeDatasetId}
            selectedDatasetsIds={selectedDatasetsIds}
            selectedModel={selectedModel}
            setActiveDatasetId={setActiveDatasetId}
            disableFlag={disableFlag}
            match={match}
            changeFlag={changeFlag}
            activeSelection={activeSelection}
            loading={loading}
            activeApp={activeApp}
            updateUserInfo={updateUserInfo}
            updateCheckDE={updateCheckDE}
            datasetsTypesData={datasetsTypesData}
          />
        </div>
      )}
      <Modal
        modalComponent={modalComponent}
        setModalComponent={setModalComponent}
        handleDialogClose={() => {}}
      />
    </div>
  );
}

ModelSelections.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  //allData: state.data,
  changeFlag: state.changeFlag,
  configUserInputs: state.configUserInputs,
  models: state.configUserInputs.models,
});

const mapDispatchToProps = {
  updateConfigUserInputs,
  updateUserInfo,
  updateCategory,
  toggleModel,
  updateSelections,
  updateAlertInfo,
  updateDatasetOnCountryChange,
  updateCategoryDataset,
  updateModelLocalParameters,
  updateDatasetLocalParameters,
  addKpi,
  addToggleKpi,
  onChangeCategory,
  filterDataset,
  resetMergedDataset,
  filterMergeDatasetForWeeks,
  updateWholeConfigUserInputs,
  filterSku,
  filterKpi,
  filterMergeDataset,
  updatePeriodicity,
  updateModelCampaignData,
  updateData,
  updateSalesMediaRegions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelSelections);
