const constants = {
  CHANGE_THEME: "CHANGE_THEME",
  UPDATE_DATA: "UPDATE_DATA",
  UPDATE_SELECTIONS: "UPDATE_SELECTIONS",
  UPDATE_MULTI_SELECTIONS: "UPDATE_MULTI_SELECTIONS",
  UPDATE_ALERT_INFO: "UPDATE_ALERT_INFO",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  RESET_FILTERS: "RESET_FILTERS",
  UPDATE_CONFIG_USER_INPUTS: "UPDATE_CONFIG_USER_INPUTS",
  UPDATE_WHOLE_CONFIG_USER_INPUTS: "UPDATE_WHOLE_CONFIG_USER_INPUTS",
  UPDATE_COUNTRY_REGIONS: "UPDATE_COUNTRY_REGIONS",
  EMPTY_TEST_CONTROL: "EMPTY_TEST_CONTROL",
  UPDATE_CONTROL_REGIONS: "UPDATE_CONTROL_REGIONS",
  UPDATE_TEST_REGIONS: "UPDATE_TEST_REGIONS",
  UPDATE_REGIONS_DATA: "UPDATE_REGIONS_DATA",
  TOGGLE_MODEL: "TOGGLE_MODEL",
  ADD_DATASET: "ADD_DATASET",
  REMOVE_DATASET: "REMOVE_DATASET",
  ADD_KPI: "ADD_KPI",
  REMOVE_KPI: "REMOVE_KPI",
  ADD_MERGE_KPI: "ADD_MERGE_KPI",
  REMOVE_MERGE_KPI: "REMOVE_MERGE_KPI",
  UPDATE_DATASET_LOCAL_PARAMETERS: "UPDATE_DATASET_LOCAL_PARAMETERS",
  UPDATE_MERGE_DATASET_LOCAL_PARAMETERS:
    "UPDATE_MERGE_DATASET_LOCAL_PARAMETERS",
  UPDATE_MODEL_LOCAL_PARAMETERS: "UPDATE_MODEL_LOCAL_PARAMETERS",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  UPDATE_RUN_DATA: "UPDATE_RUN_DATA",
  // UPDATE_RUN_DATA_V2: "UPDATE_RUN_DATA_V2",
  UPDATE_SHARED_RUN_DATA: "UPDATE_SHARED_RUN_DATA",
  RELOAD_RUN_DATA: "RELOAD_RUN_DATA",
  REFRESH_DATA: "REFRESH_DATA",
  UPDATE_DIALOG_INFO: "UPDATE_DIALOG_INFO",
  RELOAD_CONFIGS: "RELOAD_CONFIGS",
  RELOAD_ADMIN_USER_LIST: "RELOAD_ADMIN_USER_LIST",
  TOGGLE_DATASET: "TOGGLE_DATASET",
  TOGGLE_KPI: "TOGGLE_KPI",
  ADD_TOGGLE_KPI: "ADD_TOGGLE_KPI",
  UPDATE_DATASET_ON_COUNTRY_CHANGE: "UPDATE_DATASET_ON_COUNTRY_CHANGE",
  UPDATE_CATEGORY_DATASET: "UPDATE_CATEGORY_DATASET",
  SELECT_ALL: "SELECT_ALL",
  CLEAR_ALL: "CLEAR_ALL",
  TOGGLE_SIDENAV: "TOGGLE_SIDENAV",
  REFRESH_COMMENTS: "REFRESH_COMMENTS",
  UPDATE_ADMIN_PANEL_USER_DETAILS: "UPDATE_ADMIN_PANEL_USER_DETAILS",

  GET_AD_USERS: "GET_AD_USERS",
  AD_USERS_LIST_RECEIVED: "AD_USERS_LIST_RECEIVED",
  GET_USERS_LIST: "GET_USERS_LIST",
  USERS_LIST_RECEIVED: "USERS_LIST_RECEIVED",
  FEATURE_LIST_RECEIVED: "FEATURE_LIST_RECEIVED",
  GET_USER_INFO: "GET_USER_INFO",
  USER_INFO_RECEIVED: "USER_INFO_RECEIVED",
  ADD_USER: "ADD_USER",
  NEW_USER_ADDED: "NEW_USER_ADDED",
  MODIFY_USER_INFO: "MODIFY_USER_INFO",
  ADD_GROUP: "ADD_GROUP",
  USER_MODIFIED: "USER_MODIFIED",
  UPDATE_USER_LIST_FILTER_STRING: "UPDATE_USER_LIST_FILTER_STRING",

  GET_GROUPS: "GET_GROUPS",
  GET_GROUP_INFO: "GET_GROUP_INFO",
  GROUPS_INFO_RECEIVED: "GROUPS_INFO_RECEIVED",
  UPDATE_USER_INFO_FOR_ADMIN: "UPDATE_USER_INFO_FOR_ADMIN",

  ADD_EDIT_STATUS_RECEIVED: "ADD_EDIT_STATUS_RECEIVED",
  RESET_ADD_EDIT_STATUS_RECEIVED: "RESET_ADD_EDIT_STATUS_RECEIVED",

  GET_APPS: "GET_APPS",
  GET_COUNTRIES: "GET_COUNTRIES",
  GET_BRANDS: "GET_BRANDS",
  GET_ADMIN_GLOBAL_PARAMS: "GET_ADMIN_GLOBAL_PARAMS",
  UPDATE_KPI_VARIABLES: "UPDATE_KPI_VARIABLES",

  GET_GLOBAL_PARAMS: "GET_GLOBAL_PARAMS",
  GLOBAL_PARAMS_LIST_RECEIVED: "GLOBAL_PARAMS_LIST_RECEIVED",

  APP_LIST_RECEIVED: "APP_LIST_RECEIVED",
  COUNTRY_LIST_RECEIVED: "COUNTRY_LIST_RECEIVED",
  BRAND_LIST_RECEIVED: "BRAND_LIST_RECEIVED",
  ADMIN_GLOBAL_PARAMS_RECEIVED: "ADMIN_GLOBAL_PARAMS_RECEIVED",
  RESET_NEW_KPI: "RESET_NEW_KPI",
  ON_CHANGE_CATEGORY: "ON_CHANGE_CATEGORY",
  ON_CHANGE_DE_CONFIG: "ON_CHANGE_DE_CONFIG",
  FILTERING_DATASET: "FILTERING_DATASET",
  ADD_MERGE_DATASET: "ADD_MERGE_DATASET",
  EDIT_MERGE_DATASET: "EDIT_MERGE_DATASET",
  DELETE_MERGE_DATASET: "DELETE_MERGE_DATASET",
  SELECT_ALL_FOR_MERGED: "SELECT_ALL_FOR_MERGED",
  CLEAR_ALL_FOR_MERGED: "CLEAR_ALL_FOR_MERGED",
  RESET_MERGED_DATASET: "RESET_MERGED_DATASET",
  FILTERING_MERGED_DATASET_FOR_WEEKS: "FILTERING_MERGED_DATASET_FOR_WEEKS",
  ADD_GLOBAL_PARAMS: "ADD_GLOBAL_PARAMS",
  FILTER_SKU: "FILTER_SKU",
  FILTER_KPI: "FILTER_KPI",
  FILTERING_MERGED_DATASET: "FILTERING_MERGED_DATASET",
  UPDATE_PERIODICITY: "UPDATE_PERIODICITY",
  ADD_CONFIG_MANUAL_KPI: "ADD_CONFIG_MANUAL_KPI",
  ADD_MANUAL_KPI: "ADD_MANUAL_KPI",
  DELETE_MANUAL_KPI: "DELETE_MANUAL_KPI",
  RESET_MANUAL_KPI: "RESET_MANUAL_KPI",
  UPDATE_CATEGORY_KPI: "UPDATE_CATEGORY_KPI",
  UPDATE_RUN_EXPAND_INFO: "UPDATE_RUN_EXPAND_INFO",
  UPDATE_RUN_CANCEL: "UPDATE_RUN_CANCEL",
  UPDATE_RUN_STATUS: "UPDATE_RUN_STATUS",
  UPDATE_MODEL_CAMPAIGN_DATA: "UPDATE_MODEL_CAMPAIGN_DATA",
  UPDATE_CAMPAIGN_DATASETS_DATA: "UPDATE_CAMPAIGN_DATASETS_DATA",
  FILTER_SU_MEDIA_DATASETS: "FILTER_SU_MEDIA_DATASETS",
  UPDATE_VALIDATION_STATE: "UPDATE_VALIDATION_STATE",
  UPDATE_SALES_MEDIA_REGIONS: "UPDATE_SALES_MEDIA_REGIONS",
};

export default constants;
