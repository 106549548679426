import C from "../../constants";
import {
  getRegionsData,
  getDatesFromWeek,
  checkDatesOverlapping,
} from "../../../utils/utils";
import models from "./models";
import { config } from "../../../config/config";

export default function configUserInputs(state = {}, payload) {
  let { type, key, data } = payload;
  let newState;
  switch (type) {
    case C.UPDATE_WHOLE_CONFIG_USER_INPUTS:
      return data;
    case C.UPDATE_CONFIG_USER_INPUTS:
      return { ...state, [key]: data };
    case C.UPDATE_COUNTRY_REGIONS:
      return {
        ...state,
        countryRegions: data,
      };
    case C.EMPTY_TEST_CONTROL:
      return state.countryRegions !== -1
        ? {
            ...state,
            regionsData: getRegionsData(
              state.countryRegions,
              state.country,
              state.regionGranType
            ),
            controlRegions: [],
            testRegions: [],
          }
        : state;
    case C.UPDATE_CONTROL_REGIONS:
      return state.regionsData
        ? {
            ...state,
            controlRegionsData: state.regionsData.filter(
              (val) => !state.testRegions.includes(val)
            ),
          }
        : state;
    case C.UPDATE_TEST_REGIONS:
      return state.regionsData
        ? {
            ...state,
            testRegionsData: state.regionsData.filter(
              (val) => !state.controlRegions.includes(val)
            ),
          }
        : state;
    case C.UPDATE_REGIONS_DATA:
      let regionsData;
      if (
        state.countryRegions !== -1 &&
        state.regionGranType.toLowerCase() !== "national"
      ) {
        regionsData = getRegionsData(
          state.countryRegions,
          state.country,
          state.regionGranType
        );
      }
      return regionsData
        ? {
            ...state,
            regionsData,
            controlRegionsData: regionsData.filter(
              (val) => !state.testRegions.includes(val)
            ),
            testRegionsData: regionsData.filter(
              (val) => !state.controlRegions.includes(val)
            ),
          }
        : state;
    case C.TOGGLE_MODEL:
      return { ...state, models: models(state.models, payload) };
    case C.TOGGLE_DATASET:
      return { ...state, models: models(state.models, payload) };
    case C.TOGGLE_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.ADD_TOGGLE_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.ADD_DATASET:
      return { ...state, models: models(state.models, payload) };
    case C.REMOVE_DATASET:
      return { ...state, models: models(state.models, payload) };
    case C.ADD_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.REMOVE_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.ADD_MERGE_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.REMOVE_MERGE_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_DATASET_LOCAL_PARAMETERS:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_MERGE_DATASET_LOCAL_PARAMETERS:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_DATASET_LOCAL_PARAMETERS:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_CATEGORY:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_MODEL_LOCAL_PARAMETERS:
      return {
        ...state,
        models: models(state.models, payload, state?.is_schedule_config),
      };
    case C.UPDATE_DATASET_ON_COUNTRY_CHANGE:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_CATEGORY_DATASET:
      return { ...state, models: models(state.models, payload) };
    case C.SELECT_ALL:
      return { ...state, models: models(state.models, payload) };
    case C.CLEAR_ALL:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_KPI_VARIABLES:
      return { ...state, models: models(state.models, payload) };
    case C.RESET_NEW_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.ON_CHANGE_CATEGORY:
      return { ...state, models: models(state.models, payload) };
    case C.ON_CHANGE_DE_CONFIG:
      let modelsData = state.models.map((e) => {
        return { ...e, config: { ...e.config, [key]: data } };
      });
      return {
        ...state,
        is_config_manual: false,
        models: modelsData,
      };
    case C.FILTERING_DATASET:
      let updateModels = state.models.map((model) => {
        if (state?.datasetDetailsData.status === "success") {
          if (
            model.id === config.hardCoded.autoMlId &&
            model.config.run_type === config.hardCoded.nestedRunId
          ) {
            return {
              ...model,
              pilot_saved_selections: {
                ...model.pilot_saved_selections,
                data_sources: model.pilot_saved_selections.data_sources
                  .filter(
                    (el) =>
                      state?.datasetDetailsData?.data.find(
                        (e) => e?.dataset_id === el?.id
                      ) || el?.merge_dataset
                  )
                  .filter((elem) => {
                    let dateRange;
                    if (
                      state.global_week_date_selection === "historic_week_data"
                    ) {
                      dateRange = getDatesFromWeek(state.historic_week_data);
                    } else {
                      dateRange = {
                        min: state.startDate,
                        max: state.endDate,
                      };
                    }
                    let startDateSelected = dateRange?.min;
                    let endDateSelected = dateRange?.max;
                    const findDataset = state?.datasetDetailsData?.data?.find(
                      (elm) => elm.dataset_id === elem.id
                    );
                    let checkForValidDataset = checkDatesOverlapping(
                      startDateSelected,
                      endDateSelected,
                      findDataset?.start_date,
                      findDataset?.end_date
                    );
                    const { type, status } = checkForValidDataset;
                    if (status || type) {
                      return elem;
                    }
                  }),
              },
            };
          } else {
            return {
              ...model,
              data_sources: model.data_sources
                .filter(
                  (el) =>
                    state?.datasetDetailsData?.data.find(
                      (e) => e?.dataset_id === el?.id
                    ) || el?.merge_dataset
                )
                .filter((elem) => {
                  let dateRange;
                  if (
                    state.global_week_date_selection === "historic_week_data"
                  ) {
                    dateRange = getDatesFromWeek(state.historic_week_data);
                  } else {
                    dateRange = {
                      min: state.startDate,
                      max: state.endDate,
                    };
                  }
                  let startDateSelected = dateRange?.min;
                  let endDateSelected = dateRange?.max;
                  const findDataset = state?.datasetDetailsData?.data?.find(
                    (elm) => elm.dataset_id === elem.id
                  );
                  let checkForValidDataset = checkDatesOverlapping(
                    startDateSelected,
                    endDateSelected,
                    findDataset?.start_date,
                    findDataset?.end_date
                  );
                  const { type, status } = checkForValidDataset;
                  if (status || type) {
                    return elem;
                  }
                }),
            };
          }
        } else {
          return model;
        }
      });
      return {
        ...state,
        // is_config_manual: false,
        models: updateModels,
      };
    // in this action we are looking for any changes in due to overlapping of dates , if there's even
    // a single dataset which is not overlapping to the current selected dates then the whole merged
    // dataset will be discarded from merge_data_sources as well as from data_sources
    case C.FILTERING_MERGED_DATASET_FOR_WEEKS:
      let updateModelsForMerged = state.models.map((model) => {
        if (
          model.id === config.hardCoded.autoMlId &&
          model.config.run_type === config.hardCoded.nestedRunId
        ) {
          return {
            ...model,
            pilot_saved_selections: {
              ...model.pilot_saved_selections,
              data_sources: model.pilot_saved_selections.data_sources.filter(
                (el) => {
                  if (el?.merge_dataset) {
                    let notOverLapDataset = el.dataset_list.find((elem) => {
                      let dateRange;
                      if (
                        state.global_week_date_selection ===
                        "historic_week_data"
                      ) {
                        dateRange = getDatesFromWeek(state.historic_week_data);
                      } else {
                        dateRange = {
                          min: state.startDate,
                          max: state.endDate,
                        };
                      }
                      let startDateSelected = dateRange?.min;
                      let endDateSelected = dateRange?.max;
                      const findDataset = state?.datasetDetailsData?.data?.find(
                        (elm) => elm.dataset_id === elem.id
                      );
                      let checkForValidDataset = checkDatesOverlapping(
                        startDateSelected,
                        endDateSelected,
                        findDataset?.start_date,
                        findDataset?.end_date
                      );
                      const { type, status } = checkForValidDataset;
                      if (!(status || type)) {
                        return true;
                      }
                    });
                    // if notOverLapDataset is defined then it means there's a dataset which is not overlapping
                    if (!notOverLapDataset) {
                      return true;
                    }
                  } else {
                    return true;
                  }
                }
              ),
              merged_data_sources:
                model.pilot_saved_selections?.merged_data_sources?.filter(
                  (el) => {
                    let notOverLapMergeDataset = el?.dataset_list.find(
                      (elem) => {
                        let dateRange;
                        if (
                          state.global_week_date_selection ===
                          "historic_week_data"
                        ) {
                          dateRange = getDatesFromWeek(
                            state.historic_week_data
                          );
                        } else {
                          dateRange = {
                            min: state.startDate,
                            max: state.endDate,
                          };
                        }
                        let startDateSelected = dateRange?.min;
                        let endDateSelected = dateRange?.max;
                        const findDataset =
                          state?.datasetDetailsData?.data?.find(
                            (elm) => elm.dataset_id === elem
                          );
                        let checkForValidDataset = checkDatesOverlapping(
                          startDateSelected,
                          endDateSelected,
                          findDataset?.start_date,
                          findDataset?.end_date
                        );
                        const { type, status } = checkForValidDataset;
                        if (!(status || type)) {
                          return true;
                        }
                      }
                    );
                    if (!notOverLapMergeDataset) {
                      return true;
                    }
                  }
                ),
            },
          };
        } else {
          return {
            ...model,
            data_sources: model.data_sources.filter((el) => {
              if (el?.merge_dataset) {
                let notOverLapDataset = el.dataset_list.find((elem) => {
                  let dateRange;
                  if (
                    state.global_week_date_selection === "historic_week_data"
                  ) {
                    dateRange = getDatesFromWeek(state.historic_week_data);
                  } else {
                    dateRange = {
                      min: state.startDate,
                      max: state.endDate,
                    };
                  }
                  let startDateSelected = dateRange?.min;
                  let endDateSelected = dateRange?.max;
                  const findDataset = state?.datasetDetailsData?.data?.find(
                    (elm) => elm.dataset_id === elem.id
                  );
                  let checkForValidDataset = checkDatesOverlapping(
                    startDateSelected,
                    endDateSelected,
                    findDataset?.start_date,
                    findDataset?.end_date
                  );
                  const { type, status } = checkForValidDataset;
                  if (!(status || type)) {
                    return true;
                  }
                });
                // if notOverLapDataset is defined then it means there's a dataset which is not overlapping
                if (!notOverLapDataset) {
                  return true;
                }
              } else {
                return true;
              }
            }),
            merged_data_sources: model?.merged_data_sources?.filter((el) => {
              let notOverLapMergeDataset = el?.dataset_list.find((elem) => {
                let dateRange;
                if (state.global_week_date_selection === "historic_week_data") {
                  dateRange = getDatesFromWeek(state.historic_week_data);
                } else {
                  dateRange = {
                    min: state.startDate,
                    max: state.endDate,
                  };
                }
                let startDateSelected = dateRange?.min;
                let endDateSelected = dateRange?.max;
                const findDataset = state?.datasetDetailsData?.data?.find(
                  (elm) => elm.dataset_id === elem
                );
                let checkForValidDataset = checkDatesOverlapping(
                  startDateSelected,
                  endDateSelected,
                  findDataset?.start_date,
                  findDataset?.end_date
                );
                const { type, status } = checkForValidDataset;
                if (!(status || type)) {
                  return true;
                }
              });
              if (!notOverLapMergeDataset) {
                return true;
              }
            }),
          };
        }
      });
      return {
        ...state,
        is_config_manual: false,
        models: updateModelsForMerged,
      };
    case C.ADD_MERGE_DATASET:
      return { ...state, models: models(state.models, payload) };
    case C.EDIT_MERGE_DATASET:
      return { ...state, models: models(state.models, payload) };
    case C.DELETE_MERGE_DATASET:
      return { ...state, models: models(state.models, payload) };
    case C.ADD_MERGE_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.SELECT_ALL_FOR_MERGED:
      return { ...state, models: models(state.models, payload) };
    case C.CLEAR_ALL_FOR_MERGED:
      return { ...state, models: models(state.models, payload) };
    case C.RESET_MERGED_DATASET:
      return { ...state, models: models(state.models, payload) };
    case C.ADD_GLOBAL_PARAMS:
      const flags = {
        segment_select_all: data.segment.includes(
          config.hardCoded.allSelection
        ),
        sub_brand_select_all: data.sub_brand.includes(
          config.hardCoded.allSelection
        ),
        sub_segment_select_all: data.sub_segment.includes(
          config.hardCoded.allSelection
        ),
        media_segment_select_all: data.media_segment.includes(
          config.hardCoded.allSelection
        ),
        media_sub_brand_select_all: data.media_sub_brand.includes(
          config.hardCoded.allSelection
        ),
        media_sub_segment_select_all: data.media_sub_segment.includes(
          config.hardCoded.allSelection
        ),
      };
      if (data.global_week_date_selection === config.hardCoded.historicWeek) {
        return {
          ...state,
          ...{ ...data, startDate: "", endDate: "" },
          ...flags,
        };
      } else {
        return { ...state, ...{ ...data, historic_week_data: 0 }, ...flags };
      }
    case C.FILTER_SKU:
      return { ...state, models: models(state.models, payload) };
    case C.FILTER_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.FILTERING_MERGED_DATASET:
      //this will find any dataset which is selected in merged dataset but not present in current selections of sub-brand,segment,sub-segment
      //if there is a missing dataset that merged dataset will be removed from data_sources as well as merged_data_sources
      const newState = {
        ...state,
        models: state.models.map((model) => {
          const datasetDetailsIds = state.datasetDetailsData.data.map(
            (dataset) => dataset.dataset_id
          );
          // for auto ml nested run configs
          if (
            model.id === config.hardCoded.autoMlId &&
            model.config.run_type === config.hardCoded.nestedRunId
          ) {
            const filteredMergedDatasetFromDataSources =
              model.pilot_saved_selections?.data_sources?.filter((dataset) => {
                if (dataset.merge_dataset) {
                  const missingDataset = dataset.dataset_list.find(
                    (data) => !datasetDetailsIds.includes(data.id)
                  );
                  if (missingDataset) {
                    return false;
                  } else {
                    return dataset;
                  }
                } else {
                  return dataset;
                }
              });
            const filteredMergedDatasetFromMergedDataSource =
              model.pilot_saved_selections?.merged_data_sources?.filter(
                (dataset) => {
                  const missingDataset = dataset.dataset_list.find(
                    (data) => !datasetDetailsIds.includes(data)
                  );
                  if (missingDataset) {
                    return false;
                  } else {
                    return dataset;
                  }
                }
              );
            return {
              ...model,
              pilot_saved_selections: {
                ...model.pilot_saved_selections,
                data_sources: filteredMergedDatasetFromDataSources,
                merged_data_sources: filteredMergedDatasetFromMergedDataSource,
              },
            };
          } else {
            const filteredMergedDatasetFromDataSources =
              model.data_sources.filter((dataset) => {
                if (dataset.merge_dataset) {
                  const missingDataset = dataset.dataset_list.find(
                    (data) => !datasetDetailsIds.includes(data.id)
                  );
                  if (missingDataset) {
                    return false;
                  } else {
                    return dataset;
                  }
                } else {
                  return dataset;
                }
              });
            const filteredMergedDatasetFromMergedDataSource =
              model?.merged_data_sources?.filter((dataset) => {
                const missingDataset = dataset.dataset_list.find(
                  (data) => !datasetDetailsIds.includes(data)
                );
                if (missingDataset) {
                  return false;
                } else {
                  return dataset;
                }
              });
            return {
              ...model,
              data_sources: filteredMergedDatasetFromDataSources,
              merged_data_sources: filteredMergedDatasetFromMergedDataSource,
            };
          }
        }),
      };
      return newState;
    case C.UPDATE_PERIODICITY:
      return {
        ...state,
        models: state.models.map((model) => {
          return {
            ...model,
            data_sources: model.data_sources.map((dataset) => {
              if (dataset.type === "SALES") {
                const datasetDetails = state.datasetDetailsData.data;
                const salesDataset = datasetDetails.find(
                  (data) => data.dataset_id === dataset.id
                );
                return { ...dataset, periodicity: salesDataset.periodicity };
              } else {
                return dataset;
              }
            }),
          };
        }),
      };
    case C.ADD_CONFIG_MANUAL_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.ADD_MANUAL_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.DELETE_MANUAL_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.RESET_MANUAL_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_CATEGORY_KPI:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_MODEL_CAMPAIGN_DATA:
      const findAutoMlModelDataSources = state?.models?.find(
        (e) => e.id === config.hardCoded.autoMlId
      )?.data_sources;
      const updateSelectedDatasets = findAutoMlModelDataSources.map(
        (dataset) => {
          if (dataset.type === config.hardCoded.datasetMediaType) {
            //filter all campaigns particular dataset
            const filterCampData = state.campaignsData?.data.filter(
              (camp) => camp.dataset_id === dataset.id
            );
            //filter campaigns particular dataset campaigns
            const filterSelectDataCamp = dataset?.target_campaigns.filter((e) =>
              filterCampData.find((cam) => cam.campaignid === e.campaignid)
            );
            return {
              ...dataset,
              target_campaigns: filterSelectDataCamp,
            };
          }
          return dataset;
        }
      );
      return {
        ...state,
        models: state.models.map((model) => {
          if (model.id === config.hardCoded.autoMlId) {
            return {
              ...model,
              data_sources: updateSelectedDatasets,
            };
          }
          return model;
        }),
      };
    case C.FILTER_SU_MEDIA_DATASETS:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_VALIDATION_STATE:
      return { ...state, models: models(state.models, payload) };
    case C.UPDATE_SALES_MEDIA_REGIONS:
      const { regionKey, modelId, json } = data;
      return {
        ...state,
        [regionKey]: { ...state[regionKey], [modelId]: json },
      };
    default:
      return state;
  }
}
