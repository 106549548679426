// Import required libraries
import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";

// Import data

//Import styles

const useStyles = makeStyles({
  multiSelect: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    width: "200px",
    height: "30px",
    borderRadius: "3px",
    color: "#46596a",
    backgroundColor: "white",
    //paddingLeft: "8px",
    border: "1px solid #dedede",
    "&:before": {
      borderBottom: "0px",
    },
    "&:after": {
      borderBottom: "0px",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 6px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: "8px",
    },
  },
  singleMenuItem: {
    fontSize: "12px",
    fontFamily: "Hind Siliguri",
    color: "#46596a",
    width: "auto",
    "&:hover": {
      backgroundColor: "#4EAFB3",
      color: "#FFF",
    },
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width: "auto",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function RegionGranularitySelection(props) {
  const { disableFlag, onChange, value, name, data, activeModel } = props;
  const classes = useStyles();

  return (
    <div
      className={`input-item top-margin ${
        activeModel.id !== "MMT"
          ? "test-control-global-text-no-padding"
          : "test-control-global-text"
      } ${disableFlag ? "disabled-pointer" : ""}`}
    >
      <label>{name}</label>
      <Select
        value={value}
        onChange={(e) => {
          if (data?.length) {
            onChange(e.target.value);
          }
        }}
        className={classes.multiSelect}
        MenuProps={MenuProps}
      >
        {data?.length ? (
          data.map((obj) => (
            <MenuItem
              value={obj.id.toLowerCase()}
              key={obj.id.toLowerCase()}
              className={classes.singleMenuItem}
            >
              {obj.name}
            </MenuItem>
          ))
        ) : (
          <p className="no-data">No options</p>
        )}
      </Select>
    </div>
  );
}

export default RegionGranularitySelection;
