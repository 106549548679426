//Import required libraies
import React, { useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

//Import Custom Component

// Import custom hooks
import { useNonInitialEffect } from "../../../../hooks/useNonInitialEffect";

// Import action creators

// Import styles

// Import utils & data
import { config } from "../../../../config/config";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  typography: {
    fontSize: 11,
    fontFamily: "Hind Siliguri",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "#46596a",
    margin: 0,
    marginTop: 4,
    marginBottom: 4,
    width: "320px",
  },
}));

function SalesCoverage(props) {
  const {
    id,
    name,
    enable,
    value,
    minValue,
    maxValue,
    defaultValue,
    onChange,
    customClassName,
    userInputs,
    disableFlag,
  } = props;
  const [error, setError] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  // If null comes as value then no min/max value
  const maxVal =
    maxValue == "null" ? Number.MAX_VALUE : parseFloat(maxValue.toFixed(2));
  const minVal =
    minValue == "null" ? Number.MIN_VALUE : parseFloat(minValue.toFixed(2));

  const isRunTypeNested =
    userInputs.models
      .find((model) => model.id === config.hardCoded.autoMlId)
      .config.run_type.toLowerCase() === config.hardCoded.nestedRunId;

  useEffect(() => {
    if (
      parseFloat(value) < parseFloat(minVal) ||
      parseFloat(value) > parseFloat(maxVal) ||
      isNaN(value)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [value]);

  useNonInitialEffect(() => {
    if (isRunTypeNested) onChange(id, 0);
    return () => {};
  }, [isRunTypeNested]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={`input-item ${customClassName ? customClassName : ""} ${id}`}
    >
      <div style={{ display: "flex" }}>
        <label title={name}>{name}:</label>
        <i
          className="material-icons-outlined info-icon"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{ pointerEvents: "auto" }}
        >
          {" "}
          info{" "}
        </i>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <p className={classes.typography}>
            {config.messages.salesCoverageMessage}
          </p>
        </Popover>
      </div>
      <input
        type="number"
        onWheel={() => document.activeElement.blur()}
        disabled={!enable}
        value={isNaN(value) ? defaultValue : value}
        min={minVal}
        max={maxVal}
        onChange={(e) => {
          let newNumber = e.target.value === "" ? "" : +e.target.value;
          onChange(id, newNumber);
        }}
        step={0.01}
      />
      {enable && (
        <span>
          <label className="input_info">min: {minValue}</label> |{" "}
          <label className="input_info">max: {maxValue}</label>
        </span>
      )}
      {!disableFlag && error && (
        <p className="error">{config.inputNumberValidationError}</p>
      )}
    </div>
  );
}

export default SalesCoverage;
