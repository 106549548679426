import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableSortLabel,
} from "@material-ui/core";
import { TablePagination } from "../uiTheme";
const useStyles = makeStyles((theme) => ({
  table: {
    // marginTop: theme.spacing(3),
    "& thead th": {
      borderBottom: "none",
      fontFamily: "Hind Siliguri",
      backgroundColor: "#E6E8ED",
      color: "rgba(70, 89, 106, 1)",
      fontWeight: 600,
      fontSize: "13px",
      lineHeight: "24px",
      textTransform: "capitalize",
      padding: "12px",
      "&:first-child": {
        borderTopLeftRadius: "6px",
        borderBottomLeftRadius: "6px",
      },
      "&:last-child": {
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
      },
    },
    // "& tbody": {
    //   border: "1px solid #dedede !important",
    //   borderRadius: "4px !important",
    // },
    "& tbody": {
      // boxShadow: "0 0 0 1px #dedede",
      borderRadius: "4px",
      padding: "10px",
    },
    "& tbody td": {
      fontWeight: "300",
      fontSize: "12px",
      padding: "10px",
    },
    "& tbody tr:hover": {
      cursor: "pointer",
    },
    "& thead": { borderBottom: "none" },
    // "& tbody tr:first-child td:first-child": {
    //   borderTopLeftRadius: "4px",
    // },
    // "& tbody tr:first-child td:last-child": {
    //   borderTopRightRadius: "4px",
    // },
    // "& tbody tr:last-child td:first-child": {
    //   borderBottomLeftRadius: "4px",
    // },
    // "& tbody tr:last-child td:last-child": {
    //   borderBottomRightRadius: "4px",
    // },
    "& tbody td:first-child": {
      borderTopLeftRadius: "6px",
      borderBottomLeftRadius: "6px",
    },
    "& tbody td:last-child": {
      borderTopRightRadius: "6px",
      borderBottomRightRadius: "6px",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "#46596a",
    },
    "& .MuiTypography-colorInherit": {
      color: "#46596a !important",
    },
    "& .MuiTypography-body2": {
      fontFamily: "Hind Siliguri !important",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(70, 89, 106, 0.07);",
    },
  },
}));

function useTable(
  records,
  headCells,
  filterFn,
  user,
  updateMultiSelections,
  validationDashboardCheck
) {
  const classes = useStyles();
  const pages = [10];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);

  const TblContainer = (props) => (
    <Table stickyHeader className={classes.table}>
      {props.children}
    </Table>
  );

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      console.log(user.selections.tableOrder);
      const isAsc =
        user.selections.tableOrderBy === cellId &&
        user.selections.tableOrder === "asc";
      updateMultiSelections({
        tableOrderBy: cellId,
        tableOrder: isAsc ? "desc" : "asc",
      });
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={
                user.selections.tableOrderBy === headCell.id
                  ? user.selections.tableOrder
                  : false
              }
              width={headCell?.width}
              align={headCell?.align}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={user.selections.tableOrderBy === headCell.id}
                  direction={
                    user.selections.tableOrderBy === headCell.id
                      ? user.selections.tableOrder
                      : "asc"
                  }
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (validationDashboardCheck) {
      setPage(0);
    }
  }, [records.length]);
  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onPageChange={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      className={classes.table}
    />
  );

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = () => {
    return stableSort(
      filterFn.fn(records),
      getComparator(user.selections.tableOrder, user.selections.tableOrderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  };
}
export default useTable;
